import React from "react";
import logo from 'assets/img/brand/ch-logo.svg'
import axios from 'axios';
const Logo = props => {  

  
  return (
    
      <div className="head-logo">
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={logo}/>
        </div>              
  );
}

export default Logo;
