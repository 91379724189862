import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { loginschema } from './validation';
import { InputAdornment } from '@material-ui/core';
import { Email, Lock } from '@material-ui/icons';
import { userActions } from '_actions/user.actions';
import { LocalStorageService } from '_services';
import { connect } from 'react-redux';
import { FormInput, FormButton, FormCheckBox } from "_components/FormElements/FormInput";
//import { Multiselect } from 'multiselect-react-dropdown';
//import Select from "react-select";
import logo from 'assets/img/brand/ch-logo.svg'
import { history } from '../../../_helpers';
import SignupDrawer from 'views/pages/SignupPage/SignupDrawer';
import axios from 'axios';
import Moment from 'moment';
const DirectLogin = props => {

  console.log("direct:", props.match.params.id)
  const [tandc, setTandc] = useState(false);
  const [err, setErr] = useState(false);

  const handleClick = (e) => {
    setErr(true);
    e.preventDefault();
  }

  const terms = () => {
    setTandc(!tandc);
    setErr(tandc);
    console.log('tandc', tandc)
  }

  const [showDrawer, setshowDrawer] = useState(false);
  console.log("text", showDrawer);
  const closeDrawer = () => {
    setshowDrawer(false);
  }

  const labelText = <span>By clicking on signup now for free you agree on the ChannelHub's Terms and Conditions</span>;
  const labelErrText = <span style={{ color: 'red' }}>By clicking on signup now for free you agree on the ChannelHub's Terms and Conditions</span>;

  const [apptoken, setApptoken] = useState("");
  // const { loggingIn } = props;      

  const { register, handleSubmit, control, errors } = useForm({
    //    mode:'onBlur',
    resolver: yupResolver(loginschema)
  });
  const onSubmit = data => {
    if (data) {
      let res = props.login(data.email, data.password, apptoken);
      console.log('resp', res);
      //console.log("data:",data);
    }
  }

  useEffect(() => {
    //console.log("props:",props);

    
    
    axios.post('auth/directlogin/' + props.match.params.id, {
      //id: +props.userId,
    }).then(async res => {
      //console.log("directtoken:", res);

      //check profile completion here only to avoid unnecessary call of same api
      let profile_completion_count = await axios.get('/account/checkUserStatus/' + res.data.user.channel.channelId+'/'+res.data.user.channel.channelTypeId);

      let user = {
        channelTypeId: res.data.user.channel.channelTypeId,
        eventRegisterAs: res.data.user.channel.eventRegisterAs,
        channelId: res.data.user.channel.channelId,
        contactId: res.data.user.contactId,
        userId: res.data.user.userId,
        firstName: res.data.user.contact.firstName,
        lastName: res.data.user.contact.lastName,
        isEvent: res.data.user.isEvent,
        hasTravel: +res.data.user.hasTravel,
        businessType: +res.data.user.businessType,
        documentUrl: res.data.user.contact.document?.documentUrl,
        planId: res.data.user.channel.planId,
        email: res.data.user.email,
        subscriptionId: res.data.user.channelSubscription?.subscriptionId,
        roleId: res.data.user.roleId,
        featureList: res.data.user.channelSubscription?.keyFeature,
        isAdmin: false,
        isAlgorithm: +res.data.user.isAlgorithm,
        suggestion_comm: +res.data.user.suggestion_comm,
        lastLogin: res.data.user.lastLogin,
        planExpireAt: res.data.user.channelSubscription?.endAt,
        bofo: '1',
        profile_completion_count:profile_completion_count.data,
        isOtherUser: res.data.user.channel.isOtherUser
      }
      console.log('user', user);
      localStorage.clear();
      localStorage.setItem('CHuser', JSON.stringify(user));
      localStorage.setItem('fo-auth-token', res.data.token);
      let lastLogin = user.lastLogin;
      var lastLogindate = new Date(lastLogin).getDate();
      var lastLoginmonth = new Date(lastLogin).getMonth() + 1;
      var lastLoginyear = new Date(lastLogin).getFullYear();

      var startDate = Moment(lastLogindate + '.' + lastLoginmonth + '.' + lastLoginyear, "DD.MM.YYYY");
      var todate = new Date().getDate();
      var tomonth = new Date().getMonth() + 1;
      var toyear = new Date().getFullYear();
      var endDate = Moment(todate + '.' + tomonth + '.' + toyear, "DD.MM.YYYY");
      let difDay = endDate.diff(startDate, 'days');
      console.log('diss', difDay);
      if (difDay >= 90)
        localStorage.setItem('buyerPopup', '1');
      else
        localStorage.setItem('buyerPopup', '0');
      //console.log("isAlgorithm:", res.data.user.isAlgorithm);
      // if (res.data.user.isAlgorithm == null) {
      //   axios.get('algorithm/channel/' + user.contactId);
      //   if (res.data.user.channel?.channelTypeId != 2) {
      //     await axios.get('algorithm/product/' + user.contactId);
      //     //axios.post('mailerservice/Product_Suggestion_Mail', { contactId: user.data.user.contactId, channelId: user.data.user.contactId });
      //   }
      // }
      history.replace('/admin/dashboard');
    })

    // const loggedIn = localStorage.getItem('user');
    // if(loggedIn){
    //   const user = JSON.parse(loggedIn);
    //   setApptoken(user.token);
    //   history.replace('/admin/dashboard');
    // }

    // const localStorageService = LocalStorageService.getService();
    // axios.post('/app', { 
    //     clientKey:"clientAppFE",      
    //     secretKey:"!$Ch@nnelHub#*&"         
    //     }).then(res =>{
    //       localStorageService.setAppToken(res.data.Authorization);
    //         setApptoken(res.data.Authorization);
    //     });
  }, []);

  if (localStorage.getItem('fo-auth-token')) {
    history.replace('admin/dashboard');
    return null;
  }
  else
    return (
      <div class="main-content">
        <div className="head-logo">
          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={logo} />
        </div>
        <div class="header signup-header-bg py-7 py-lg-8 pt-lg-9">
          <div class="container">
            <div class="header-body text-center mb-7">
              <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-6 col-md-8 px-5">
                  <h1 class="text-white">Welcome!</h1>
                  <p class="text-lead text-white">ChannelHub is the game changer for consumer tech channels. We help retailers and distributors find new products and qualified vendors from around the globe</p>
                </div>
              </div>
            </div>
          </div>
          <div class="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </div>

        <div class="container mt--8 pb-5">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
              <div class="card bg-secondary border-0 mb-0">

                <div class="card-body px-lg-5 py-lg-5">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div class="form-group mb-3" >
                      <div class="input-group input-group-merge input-group-alternative">
                        <FormInput
                          id="email"
                          type="email"
                          name="email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Email />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Email Id"
                          register={register}
                          error={errors.email}
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group input-group-merge input-group-alternative">
                        <FormInput
                          id="password"
                          type="password"
                          name="password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Password"
                          register={register}
                          error={errors.password}
                        />
                      </div>
                    </div>

                    {/* <span className="alert-icon">
                          <i className="ni ni-like-2" />
                        </span>
                        <span className="alert-text ml-1">
                          <strong>Warning!</strong> This is a warning alert—check it
                          out!
                        </span> */}
                    <p style={{ color: "red", textAlign: "center", fontSize: '.9rem', fontWeight: 600, marginTop: '-1rem', display: "none" }}>!Please check your email and password</p>


                    {/* <FormCheckBox 
                    id="customCheckLogin"
                    type="checkbox"
                    label="Remember me"
                    register={register}
                    /> */}
                    {/* <a className="forgot" href="dev-fo.channelhub.tech">Forgot Password</a> */}
                    <a className="forgot" onClick={() => { setshowDrawer(true) }} style={{ cursor: "pointer" }}>Forgot Password</a>

                    <SignupDrawer title="Forgot Password" visible={showDrawer} closeDrawer={closeDrawer} />
                    {/* <Row className=""> */}

                    <FormButton
                      className="btn-sin btn btn-primary"
                      id="loginbutton"
                      name="loginbutton"
                      value="Sign in"
                    />

                    <div class="text-center login-btn">
                      <hr className="login-hr" />

                      <div className="signup-col">
                        <span>Not registered yet? </span><br />
                        <span>create your account in 2 minutes for free</span>

                        <div className="agree">
                          <Link to="/signup" onClick={!tandc ? handleClick : ''} className="btn btn-link"><button class="btn btn-primary" type="button">Signup now for free</button></Link>
                          {/* <SignupDrawer text="Signup now for free" title="Create Your Account"/> */}
                        </div>
                        <div className="chck">
                          <FormCheckBox
                            id="Terms-conditions"
                            type="checkbox"
                            label={err ? labelErrText : labelText}
                            label1={{ textAlign: "justify" }}
                            register={register}
                            onClick={terms}
                          /></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}


function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login
};

const connectedLoginPage = connect(mapState, actionCreators)(DirectLogin);
export { connectedLoginPage as DirectLogin };
