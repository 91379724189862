import React, { useState, useEffect } from 'react';
import { Row } from "reactstrap";
import {FormInput,FormRadio,FormCheckBox} from "_components/FormElements/FormInput";
import { useForm} from "react-hook-form";
import { InputAdornment } from '@material-ui/core';
import { commonService } from '_services/common.service';
import { Spinner } from 'views/Hoc/Spinner';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { factProfileSchema } from '../profile/validation';
import { yupResolver } from '@hookform/resolvers/yup';
const Factedit = (props) => {
  const { changedData, closeDrawer }=props;
    const { register, handleSubmit, control, errors, watch } = useForm({
        mode:'onBlur',
       resolver: yupResolver(factProfileSchema),
      });

      useEffect(() => {
        if(props.visible){
          getFacts();
        }
    },[props.visible]);
    console.log('errors',errors);
    var userData = JSON.parse(localStorage.getItem('CHuser'));
    const [loading, setLoading] = useState(true);
    let typeId=userData?.channelTypeId;

    const [state, setState] = useState({
      areYouStartUp : '0',
      no_of_staffs : '',
      startUpType: '',
      annualTurnOver : '',
      no_of_skus : null,
      storeCount:null,
      no_of_distributors: null,
      no_of_retailers: null,
      startUpType1: false,
      startUpType2: false,
      startUpType3: false,
      isPhysicalSales:false,
    })
    const startUp = [
      {label : 'Prototype', id: 1, value: 'PROTOTYPE'},
      {label : 'Shipping to first backers', id: 2, value: 'S_BACKER'},
      {label : 'Shipping in volume', id: 3, value: 'S_VOLUME'},
    ];
    const getFacts=async()=>{
      setLoading(true);
      await commonService.getServices('/setting/getFacts').then(res =>{
        let data = res.data;
        state.isPhysicalSales=data.isPhysicalSales=='1'?true:false;
        state.storeCount= data.isPhysicalSales=='1'?data.physicalSales:data.storeCount;
        state.no_of_skus= data.no_of_skus;
        state.no_of_staffs= data.empCnt? data.empCnt: data.empCntRange.replace(/\s/g, "").trim();
        state.annualTurnOver= data.turnover? data.turnover: data.turnoverRange.replace(/\s/g, "").trim();
        state.yearOfEstablishment= data.estYear;
        state.areYouStartUp= data.startup;
        if((typeId==='3'||typeId==='4') &&  data.maturitylevel){
           data.maturitylevel.map(x => {
            if(x == 'PROTOTYPE') state.startUpType1 = true;
            if(x == 'S_BACKER') state.startUpType2 = true;
            if(x == 'S_VOLUME') state.startUpType3 = true;
            state.startUpType = 'set';
          })
        }else{
          if(res.data.maturitylevel)
            state.startUpType=res.data.maturitylevel[0];
        }
        setState({...state})
      }).catch(err => {
        commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/common/getSignup/'},'/error')
      });
      setLoading(false);
    }
      const handleChange = input => e => {
        if(input === 'startUpType1' || input === 'startUpType2' || input === 'startUpType3'){
          state[input] = !state[input];
          state.startUpType = state.startUpType1?'set':state.startUpType2?'set':state.startUpType3?'set':'';
          setState({...state, startUpType : state.startUpType, [input]: state[input]});
        }
        else if(input == 'annualTurnOver' || input == 'no_of_staffs'){
          let temp = input=='annualTurnOver' ? 'turnOver' : 'staffCnt';
          document.getElementById(temp).value=null;
          errors[temp]=null;
          setState({...state, [input]: e.target.value});
        }else if(input=='turnOver' || input == 'staffCnt'){
          let temp = input=='turnOver' ? 'annualTurnOver' : 'no_of_staffs';
          setState({...state, [temp]: e.target.value});
        }
        else
        setState({...state, [input] : e.target.value});
      }
      const watchAll = watch(['companyName', 'CRN', 'VATN', 'websiteUrl', 'city', 'insta', 'linkedIn',  'zipCode', 'Yearestablishment','phoneNumber1','phoneNumber2','mobile','email','tradeUrls','no_of_skus','storeCount', 'turnOver', 'staffCnt']);

      //saving tradeinfo form merged fields data
      const save = async() => {
        try{    
        setLoading(true);
        let mLevel=[];
        if(typeId=='2' && state.areYouStartUp === '1'){
          mLevel.push(state.startUpType);
        }      
        else if((typeId=='3' || typeId=='4') && state.areYouStartUp == '1')
          { 
            if(state.startUpType1) mLevel.push(startUp[0].value);
            if(state.startUpType2) mLevel.push(startUp[1].value);
            if(state.startUpType3) mLevel.push(startUp[2].value);
            // listOfServices = state.listOfServiceValue.map(x => ({id: +x.id}));
          }
        let data = {};
          if(typeId == '2'){
            data = {
              no_of_skus : +watchAll.no_of_skus
            }  
          }else if(typeId == '4' || typeId == '5'){
            data = {
              storeCount: +state.storeCount,
            }
          }
          data['year_of_estabilishment']= +watchAll.Yearestablishment;
          if(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(state.no_of_staffs))
            data['employeesRange'] = state.no_of_staffs
          else
            data['employeesCount'] = +state.no_of_staffs

          if(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(state.annualTurnOver))
            data['annualTurnoverRange'] = state.annualTurnOver
          else
            data['annualTurnover'] = +state.annualTurnOver
            data['startup'] = state.areYouStartUp;
            data['maturitylevel'] = state.areYouStartUp=="1"?mLevel:[];
        // finalData = typeId=='2' ? Object.assign(finalData, {tradeInformationVendor: data}) : typeId == '3' ?  Object.assign(finalData, {tradeInformationDistributor: data}) : Object.assign(finalData, {tradeInformationRetailer: data})
          console.log('data',data);
        let result=await commonService.postService(data, '/setting/updateFacts').catch(err=> {
          ToastsStore.error('Something Went Wrong!');
         let res= commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/setting/updateFacts'},'/error');
          setLoading(false)
        });
        //console.log('saveresuult', result);
        if(result) {
          ToastsStore.success('Saved Successfully!');
          changedData(Object.assign(data),2);
          closeDrawer();
          setLoading(false);
        }

        }
        catch(err)
        {        
          ToastsStore.error('Something Went Wrong!');
          setLoading(false);    
          commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'updateFacts'},'/error');       
        }       
      
      }
    return (
        <div>
          <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
          {loading ? <Spinner /> :<form noValidate onSubmit={handleSubmit(save)}>
                          <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12  row ">
            <div className="form-group text-center text-sm mb-0">
            <h4>{typeId=='2' ? 'Are you a startup *' : 'Are you looking for start-ups?' }</h4>
            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="areYouStartUp" name="areYouStartUp" ref={register} value={state.areYouStartUp} /> 
            <FormRadio
                    name="startup"
                    className="text-sm mb-0"
                    options={radioGroupOptions}
                    control={control}
                    row
                    value={state.areYouStartUp} 
                    onChange={handleChange('areYouStartUp')}
                     />
                 </div>           
            </div>
            
            <span style={{color: '#f44336',float: 'right', fontSize:'0.75rem'}}>{!errors.startUpType || (state.areYouStartUp=='1' && state.startUpType) || state.areYouStartUp=='0' ? '' : 'Please Choose Maturity Level' }</span>
         {(state.areYouStartUp == '1' && (typeId == '3'|| typeId == '4')) &&  <div className="col-lg-4 col-md-6 col-sm-12">
           <h4>What type of start-up you would like to see?</h4>
                    <div className="chck">
          <FormCheckBox
                    id="Prototype"
                    type="checkbox"
                    label="Prototype"
                    label1={{textAlign:"justify"}}
                    //register={register}
                    checked={state.startUpType1}
                    onClick={handleChange('startUpType1')}
                    value={state.startUpType1}
                    /></div>
                    <div className="chck">
          <FormCheckBox
                    id="Shipping to first backers"
                    type="checkbox"
                    label="Shipping to first backers"
                    label1={{textAlign:"justify"}}
                    //register={register}
                    checked={state.startUpType2}
                    onClick={handleChange('startUpType2')}
                    value={state.startUpType2}
                    /></div>

      <div className="chck">
          <FormCheckBox
                    id="Shipping in volume"
                    type="checkbox"
                    label="Shipping in volume"
                    label1={{textAlign:"justify"}}
                    //register={register}
                    checked={state.startUpType3}
                    onClick={handleChange('startUpType3')}
                    value={state.startUpType3}
                    /></div>
    </div>}

    {(state.areYouStartUp == '1' && typeId=='2') && <div className="col-lg-4 col-md-6 col-sm-12">
           <h4>What's your maturity level? * </h4>
           <FormRadio
                    name="startup"
                    className="text-sm mb-0"
                    options={startUp}
                    control={control}
                    row
                    value={state.startUpType} 
                    onChange={handleChange('startUpType')}
                     />
            </div>}
    </div>
    <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="startUpType" name="startUpType" ref={register} value={state.startUpType} /> 
             <Row>
             <div className="col-sm-6 mt-2">
                 <FormInput 
                 autoFocus
                 className="comp-name mt-2" 
                 id="Yearestablishment" 
                 name="Yearestablishment" 
                 label="Year of Establishment *"
                 placeholder="Year of Establishment" 
                 type="text" 
                 variant="outlined" 
                 value={watchAll.Yearestablishment} defaultValue={state.yearOfEstablishment} error={errors.Yearestablishment}
                 register={register}
                 />
             </div> 
             </Row>
             {(typeId==='2') && (
            <div className="col-lg-12 mt-4">
            <h4>No of SKU's *</h4>
                  <h5>Enter the number of products that you handle</h5>
            <div className="form-group">
          <FormInput
              id="No of SKU's"
              type="number"
              name="no_of_skus"
              label="No of SKU's *"
              InputProps={{
                inputProps: {
                    max: 100, min: 10
                }
                }}
              value={state.no_of_skus}                
              onChange={handleChange('no_of_skus')}
              register={register}
              defaultValue={state.no_of_skus}
              value={watchAll.no_of_skus}
              error={errors.no_of_skus}
          />
        </div>
        </div>)}
<div className="row" >
 <div className="col-lg-8 mt-4">
 <h4  className="text-sm mb-0" >No of Staffs *{!errors.no_of_staffs || state.no_of_staffs ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select or Enter No Of Employees</span>}</h4>
 <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="no_of_staffs" ref={register} name="no_of_staffs" value={state.no_of_staffs} control={control} />
 <div className="row">
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm" >
           <input className={state.no_of_staffs=='1-10' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" }
                  type="button" id="emprange" name="emprange"  
                  value="1-10" onClick={handleChange('no_of_staffs')} />
    </div>
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm"  >
        <input className={state.no_of_staffs==='11-50' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" } 
                  type="button" id="emprange" name="emprange" 
                  value="11-50" onClick={handleChange('no_of_staffs')} />
    </div>
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm" >
        <input className={state.no_of_staffs==='51-100' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" } 
                  type="button" id="emprange" name="emprange" 
                  value="51-100" onClick={handleChange('no_of_staffs')} />
    </div>
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm" >
         <input className={state.no_of_staffs==='101-Above' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" }
                  type="button" id="emprange" name="emprange" 
                  value="101-Above" onClick={handleChange('no_of_staffs')} />
    </div>
    </div>
      </div>
    <div className="col-lg-4 col-sm-12 mt-5 "> 
    <div className="form-group  mt-2">
                     <FormInput 
                         id="staffCnt"
                         type="number"
                         name="staffCnt"
                         label="Enter Custom Value *"
                         placeholder="Enter Your Value"
                         InputProps={{
                          startAdornment: <InputAdornment position="start"></InputAdornment>
                        }}
                        defaultValue={state.no_of_staffs}
                         value={watchAll.staffCnt}
                         onChange={handleChange('staffCnt')}
                         control={control}
                         register={register}
                         error={errors.staffCnt}
                     />
              </div>
              </div>
          </div>
          <div className="row ">
<div className="col-lg-8 col-md-8 mt-3">
<h4 className="text-sm mb-0">Annual Turnover *{!errors.annualTurnOver || state.annualTurnOver ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select or Enter Annual Turnover</span>}</h4>   
<input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="annualTurnOver" name="annualTurnOver" ref={register} value={state.annualTurnOver} /> 
<div className="row "> 
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
        <input className={state.annualTurnOver==='<1M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}                   type="button" id="emprange" name="emprange"
                  value="<1M€"  onClick={handleChange('annualTurnOver')}/></div>
  <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
        <input className={state.annualTurnOver==='1-10M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} 
                  type="button" id="emprange" name="emprange"
                  value="1-10M€"  onClick={handleChange('annualTurnOver')}/></div>
  <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm  ">
        <input className={state.annualTurnOver==='10-100M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} 
                  type="button" id="emprange" name="emprange"
                  value="10-100M€" onClick={handleChange('annualTurnOver')}/></div>
 <div className="col-lg-3 col-md-3 col-xs-3 mt-3 pl-4 text-center res-sm  ">
        <input className={state.annualTurnOver==='>100M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} 
                  type="button" id="emprange" name="emprange"
                  value=">100M€" onClick={handleChange('annualTurnOver')}/></div>
   </div>
    </div>
                <div className="col-lg-4 col-md-4 col-xs-3 mt-5 ">
         <div className="form-group "> 
                     <FormInput 
                         id="turnOver"
                         type="number"
                         name="turnOver"
                         label="Enter Value in M€ *"
                         InputProps={{
                           startAdornment: <InputAdornment position="start">M€</InputAdornment>
                         }}
                         value={watchAll.turnOver}
                         defaultValue={state.annualTurnOver}
                         onChange={handleChange('turnOver')}
                         error={errors.turnOver}
                         register={register}
                         control={control}
                     />
              </div>
              </div>
              </div>
              {((typeId==='4' || typeId==='5') && state.isPhysicalSales) && 
                <div className="col-lg-4">
              <h4>No. of Stores</h4>
              <FormInput
              id="storeCount"
              type="number"
              name="storeCount"
              label={"No of Store's *"}
              InputProps={{
                inputProps: { 
                    max: 100, min: 10 
                }
                }}
              //value={+state.no_of_skus}
              onChange={handleChange('storeCount')}
              register={register}
              defaultValue={state.storeCount}
              value={watchAll.storeCount}
              error={errors.storeCount}
          /> 
              </div>}
                <div className="text-center mt-4">
                  <button type="submit" className="btn btn-primary" >Save</button>
              </div>
                </form>
        }
        </div>
    );
};
const radioGroupOptions = [
    {
      value: "1",
      label: "Yes",
    },
    {
      value: "0",
      label: "No",
    },
   
  ];
export default Factedit;