import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';



// bootstrap rtl for rtl support page
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded

import "./assets/vendor/select2/dist/css/select2.min.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "./assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
import "assets/vendor/quill/dist/quill.core.css";
import 'react-modal-video/scss/modal-video.scss';
import { store } from './_helpers';
import AdminLayout from "./layouts/Admin.js";
//import {LoginPage} from 'views/pages/dashboards/LoginPage'
import { App } from './App';

// setup fake backend
//import { configureFakeBackend } from './_helpers';
//configureFakeBackend();

ReactDOM.render(
  <Provider store={store}>
    <App />
    {/* <Router history={history}>
<Switch>
<Route exact path="/" component={LoginPage}  />
<Route path="/admin" component={AdminLayout}  />
<Redirect from="*" to="/" />
</Switch>
</Router>  */}
  </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
