import React, { useState, useEffect } from "react";
import { Card,CardBody,Container,Col,Row,Button } from "reactstrap";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { history } from '../../../../_helpers';
import { subscriptionAction } from '_actions/subscription.action';
import { connect, useSelector } from 'react-redux';
import { commonService } from '_services/common.service';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { eventService } from '_services/event.service';
import axios from 'axios';

const WalletConfirm = (props) => {
    const [value, setValue] = useState('Card');
    const [helperText, setHelperText] = useState('By default, Auto-Renewal is set to \'On\', for Card option.    You can change to \'Off\' from your Setting page, to avoid auto-renewal.');
   
    let [isLoading, setLoading] = useState(true);
    let [paymentAmt, setPaymentAmt] = useState({planAmt:0, amountpayable:0, plan:0, period:null , tax:0, coupon_discount:0, periodId:null, planId:null });
    let [userWalletAmount, setuserWalletAmount] = useState(0);
    let userData = JSON.parse(localStorage.getItem('CHuser'));
    let [isWallet, setisWallet] = useState(false);
    let [isEvent, setisEvent] = useState(false);
    let [shortDesc, setShortDesc] = useState(null);
    
    let [rcType, setType] = useState(false);
    let [eventPackage, setEventPackage] = useState(0);
    var paymentType = props.match.params.type  //url_string.split("/");

    
    let event_redux = useSelector(state => state.event);
    console.log('event_redux', event_redux)
    
    useEffect(() => {
        if(paymentType == 'wallet'){
          setisWallet(true);
          setType('wallet')
        } else if(paymentType == 'event'){

          if(!event_redux?.eventDetails){
            history.push('/admin/events');
          }else{
            geteventPackage();
            setType('event')
            setisEvent(true);
          }
        }
        getWalletDetails();
      }, [])

    
    const geteventPackage = async()=> {
     // let packageAmt = userData.event.amount;
     let evntdata = {'id' : event_redux.eventPackage.eventId, 'package':event_redux.eventPrice}; 
     
     localStorage.setItem('event', JSON.stringify(evntdata));
     let packageAmt = event_redux.eventPrice;

     let newAddon = event_redux.additionalMemberNew;

     let desc = <>
                  <Row>
                    <Col>Event: {event_redux.eventDetails.event_name}</Col>
                  </Row>
                  <Row>
                  <Col>Package: {event_redux.eventPackage.packageName}</Col>
                  </Row>
                  {!!event_redux.marketingOptionsPrice && <Row>
                  <Col>Marketing Options: {event_redux.marketingOptionsPrice} $</Col>
                  </Row>}
                  {!!event_redux.packageOptionsPrice && <Row>
                  <Col>{userData.channelTypeId=='2' ? 'Vendor' : 'Buyer'} Options: {event_redux.packageOptionsPrice} $</Col>
                  </Row>}
                  <Row>
                    <Col>Price: {event_redux.eventPackage.price} $</Col>
                  </Row>
                  {(!!event_redux.additionalMember || !!newAddon) &&<Row><Col>Additional Members: {newAddon ? newAddon : event_redux.additionalMember}</Col></Row>}
                    {(!!event_redux.additionalMember || !!newAddon) && <Row><Col>Addon Cost: {(newAddon ? +newAddon : +event_redux.additionalMember) * +event_redux.eventPackage.additionalMembersCosting} $</Col></Row>}
                    
                </>;
     setShortDesc(desc);
     setEventPackage(packageAmt);
    }
    const getWalletDetails = async()  => {
      let res = await commonService.getServices('walletRecharge/getWallet').catch(err =>console.log('err'));
      if(res){
          //let walletAmt = res.data.wallet.amount;
          let walletAmt = 0;
            if(res.data.wallet != null){
                walletAmt = res.data.wallet.amount;
            }
          setuserWalletAmount(walletAmt);
      }
    }
    const handleRadioChange = (event) => {
        event.preventDefault();
        props.subscriptionRedux(paymentAmt.amountpayable,'PAYMENT_AMT');
        setValue(event.target.value);
        // if (event.target.value === 'Card') {
        //   setHelperText('By default, Auto-Renewal is set to \'On\', for Card option.    You can change to \'Off\' from your Setting page, to avoid auto-renewal.');
        // } else if (event.target.value === 'PayPal') {
        //   setHelperText('You have paypal account.');
        // } else {
        //   setHelperText('Wire Transfer details will be sent to you via email, once you click \'Subscribe\'.');
        // }
      };
  const handleSave = () => {
    let rcamt = document.getElementById('rechargeAmt').value;
    if(rcamt >= 1) {
      localStorage.setItem('loc_rc_amount',rcamt);

        if(value=== 'Wire Transfer'){
          offlinePayment('0', 2);
        } else if(value=== 'PayPal') {
          history.push('/admin/paymentconfirm/1/'+rcType);  
        } else if(value=== 'Alipay') {
          history.push('/admin/paymentconfirm/2/'+rcType);  
        } else if(value=== 'Card') {
          history.push('/admin/paymentconfirm/3/'+rcType);  
        }
      }  else {
        if(rcType =='event'){  /// free plan
          offlinePayment('1', null);
        } else {
          ToastsStore.error('Invalid Amount, Amount should be greater than 1');
        }
      } 
  }

  const offlinePayment = (status, method) => {
    let paymentData = {
      paymentStatus : status,
      paymentTransactionId : null,
      paymentMethod : method
    }
    console.log(paymentData);
    eventService.eventRegistration(paymentData);

  }
  const handleChange= (event) => {
    console.log(event.target);
    // props.subscriptionRedux(paymentAmt.amountpayable,'PAYMENT_AMT');
    // setAmountpayable(paymentAmt.amountpayable);
  } 
    
return (
<div className="main-content" id="PaymentConfirm">
<ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
{/* {isLoading&&<Spinner/>} */}
        <Container className="mt-4 col-lg-11 col-md-11 col-sm-12" fluid>
<Row>
    <Col lg="7">

      <Row>
        <Col lg="12"> <Card>
            <CardBody>
            <h1 className="ml-3 mt-3">Payment Method</h1><hr className="m-0 ml-3 paycarhr" style={{    width: "14%"}}/>
            <p className="p-3 mb-0">
                    {/* {helperText} */}
                    </p>
            </CardBody>
        </Card></Col>
      </Row>
      <Row>
    <Col lg="12">
    <Card>
            <CardBody>
            <RadioGroup onChange={handleRadioChange} value={value}  row aria-label="position" name="position">
            <div className="rowBox w-100">
          
          <FormControlLabel value="Card" control={<Radio color="primary" />} label="Card" />
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt="...." className="float-right" src={require("assets/img/common/card.png")} />
          </div>
        
        { isWallet ? '':<div className="rowBox w-100">
          
           <FormControlLabel disabled={parseInt(paymentAmt.planId)===1} value="Wire Transfer" control={<Radio color="primary" />} label="Wire Transfer" />
         <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="float-right" alt="..." src={require("assets/img/common/wire.png")} />
           </div>}

           <div className="rowBox w-100">
          
           <FormControlLabel  value="PayPal" control={<Radio color="primary" />} label="PayPal" />
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="float-right" alt="..." src={require("assets/img/common/paypal.png")} />
          </div>
          <div className="rowBox w-100">
          
          <FormControlLabel value="Alipay" control={<Radio color="primary" />} label="Alipay" />
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="float-right" alt="..." src={require("assets/img/common/alipay.png")} />
          </div>

           </RadioGroup>
            </CardBody>
            </Card>
    </Col>
    </Row>

       
    </Col>
    <Col lg="5" className="pt-3" style={{backgroundColor:"#d9d9d9"}}>
    <Card>
            <CardBody>
              <Row>
              <Col lg="6">
            <h2 className="mt-1 mb-3 d-flex ">Summary</h2></Col>
              <Col lg="6">
            <h4 className=" mt-1 mb-3 d-flex justify-content-end" onClick={()=>history.push('/admin/Subscription')}>
            
            </h4></Col></Row>         
      

{
   isWallet ? <Row>
  <Col lg="8">
  <h4 className="mt-3 mb-3" >Current Wallet Amount</h4>
  </Col>
  <Col lg="4" className="d-flex justify-content-start">
  <h4 className="text-center mt-3 mr-4 mb-3 amt">  {userWalletAmount} €</h4>
  </Col>
  </Row>
  :shortDesc
}
</CardBody>
</Card>

        
      

        <Card>
          <CardBody>
          <Row>
  <Col lg="6">
  <h3 className="mt-1 mb-2" >AMOUNT</h3>
  {/* <h3 style={{fontSize:'24px'}} className="mt-3 mb-3 amt">€ {paymentAmt?.amountpayable}</h3> */}
  
  
  {
   isWallet ?
  <input type="text"  className="comp-name" id="rechargeAmt" />
  :<input type="text"  className="comp-name" value={eventPackage} readOnly id="rechargeAmt" />}
  </Col>
  <Col className="col-lg-6 d-flex align-items-center d-flex justify-content-end">
  <Button onClick={handleSave} className="mt-1 mb-2 btn-apply" variant="outlined" size="m"  color="primary">
  {isWallet ?'Recharge':'Proceed'}
         </Button> 
  </Col>
  </Row>
  </CardBody>
        
        </Card>
    </Col>
   
</Row>


              </Container>
      </div>
    
   
  );
};



// export default Subscription;


function mapState(state) {
    console.log('state', state);
    const { general_data } = state.subscription;
    return { general_data };
}

const actionCreators = {
    subscriptionRedux: subscriptionAction.subscriptionRedux
};

export default connect(mapState,actionCreators)(WalletConfirm); //AddgeneralForm;