import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink, Row, Col, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, } from "reactstrap";
import { commonService } from '_services/common.service';
import { SelectAutocomplete } from '_components/FormElements/FormInput';
import { useForm } from "react-hook-form";
import { channelAction } from '_actions/channel.action';
import { connect, useSelector } from 'react-redux';
import { userData } from "_reducers/userData.reducer";


const ListingTabs = forwardRef((props, ref) => {
  const { userStatus, ActiveTabs, activeTab, listing, ActivePage, handleChangeSearch, from } = props;
  const { control } = useForm({ mode: 'onBlur' });
  const [searchState, setSearch] = useState({ search: null, dropdownList: [] });
  let [activeListTab, setActiveListTab] = useState('1');
  // const [sort, setSort] = useState('asc');
  const redux_data = useSelector(state => state.channelListing);
  let [totalTabCount, setTotalTabCount] = useState(redux_data?.tab_count || null);
  let tabCount = redux_data?.tab_count
  let user = JSON.parse(localStorage.getItem('CHuser'));
  useEffect(() => {
    channelAction.channelRedux('asc', 'SORTING');

    if (from == 'product') {
      setActiveListTab('2');
      ActivePage('2');
    }

  }, [])

  useEffect(() => {

    if (activeTab == 31)
      getProductSearchList();
    else if (activeTab == 32)
      getContactNameList();
    else {
      if (activeTab) {
        if (activeListTab === '1')
          getChannelSearchList(userStatus);
        else if (activeListTab === '2')
          getProductSearchList();
        getCount(userStatus);
      }
    }

  }, [activeTab]);

  const getProductSearchList = async () => {
    let res = await commonService.getServiceWithParams({
      channelId: userStatus.channelId,
      contactId: userStatus.contactId,
      channelTypeId: userStatus.channelTypeId
    }, 'product/productSearch/' + activeTab);
    if (res) {
      searchState.dropdownList = res.data
      setSearch({ ...searchState });
    }
  }



  const getContactNameList = async () => {
    let res = await commonService.getServices('channel/conNameListFilter');
    if (res) {
      searchState.dropdownList = res.data
      setSearch({ ...searchState });
    }
  }

  const getChannelSearchList = async (userStatus) => {
    let res = await commonService.getServiceWithParams({
      channelId: userStatus.channelId,
      contactId: userStatus.contactId,
      channelTypeId: userStatus.channelTypeId
    }, 'channel/getFoCompanyList/' + activeTab + '/' + userStatus.planId);
    if (res) {
      searchState.dropdownList = res.data.results
      setSearch({ ...searchState });
    }
  }
  //1.
  const getCount = async (userStatus) => {
    let dataId;

    //COMPANIES and PRODUCTS MAIN TAB COUNT (For Suggestion)
    if (listing !== '/admin/mynetwork' && listing !== '/admin/advancedsearch') {
      if (userStatus.channelTypeId != 2)
        dataId = 1; // vendor and product(others+suggestion)
      else if (userStatus.channelTypeId == 2 && listing !== '/admin/mynetwork' && listing !== '/admin/advancedsearch') {
        if (userStatus.businessType == 2 || userStatus.businessType == 3)
          dataId = 2 //dist+reseller count
        else if (userStatus.businessType == 1 || userStatus.businessType == 3)
          dataId = 3 //dist+retailer count
      }
    }
    else if (activeListTab === '1' && listing === '/admin/mynetwork') {
      if (userStatus.channelTypeId != 2)
        dataId = 4; //outbound,confirmed & fav count
      else if (userStatus.channelTypeId == 2)
        dataId = 5; //inbound,confirmed & fav count
    }

    let params = {
      dataId: dataId,
      contactId: userStatus.contactId,
      businessType: userStatus.businessType
    }

    await commonService.postService(params, '/channel/tabCountsNew/').then(res => {
      totalTabCount = res.data;
      setTotalTabCount(totalTabCount);
      let counts = {
        confirm_count: totalTabCount.confirm_count,
        outbound_count: totalTabCount.outbound_count,
        inbound_count: totalTabCount.inbound_count,
        // request_count: 0,//totalTabCount.counts.request_count,
        // savelater_count: 0,//totalTabCount.counts.savelater_count,
        //search_count: totalTabCount.counts?.search_count,
        suggestion_vendor_count: totalTabCount.suggestion_vendor_count,
        suggestion_dist_count: totalTabCount.suggestion_dist_count,
        suggestion_retail_count: totalTabCount.suggestion_retail_count,
        suggestion_reseller_count: totalTabCount.suggestion_reseller_count,
        //product_innovation_count: totalTabCount.product_innovation_count,
        product_others_count: totalTabCount.product_others_count,
        all_product_count: totalTabCount.all_product_count,
        product_suggestion_count: totalTabCount.product_suggestion_count,
        //nonmember_count: totalTabCount.nonmember_count,
        //channel_adv_search_count: totalTabCount.channel_adv_search_count,
        favorite_count: totalTabCount.favorite_count
      }
      channelAction.channelRedux(counts, 'TABCOUNT');

    });

  }

  useImperativeHandle(ref, () => ({
    setActiveListTab,
    getCount,
    getChannelSearchList
  }));

  const toggle = (tab) => {
    if (activeTab !== tab)
      setTabs(tab);
  };
  const setTabs = item => {
    resetSearch();
    ActiveTabs(item);
  }
  const listToggle = (tab) => {
    if (activeListTab !== tab) {
      activeListTab = tab;
      setActiveListTab(activeListTab);
      ActivePage(activeListTab);
      resetSearch();
    }
  };



  const resetSearch = () => {
    searchState.dropdownList = [];
    searchState.search = null;
    setSearch({ ...searchState });
    handleChangeSearch('search', null);
  }
  const handleChangeSelect = (input, value) => {
    searchState.search = value;
    setSearch({ ...searchState });
    handleChangeSearch(input, value);
  }
  return (
    <Nav tabs className="list-tab suggest-ven">
      {listing !== '/admin/mynetwork' && listing !== '/admin/advancedsearch' && <NavItem className={"" + (userStatus.channelTypeId === "2" ? 'list-ven ' : 'list-main')}>
        <NavLink className={classnames({ active: true })}>
          <h3>LISTING</h3>
          <Row className="text-uppercase">
            <Col onClick={() => { listToggle("1") }} xs="6" className=" mt-2 sub-a listCompany">
              <div className={`${activeListTab != '1' ? 'position-absolute inactive left-20px' : ''} awards_thumb p-2 mr-2`} >
                <a style={{ cursor: 'pointer' }}>
                  <h4 className={`${activeListTab != '1' ? 'padleft' : ''}`}>COMPANIES</h4>
                  {listing === '/admin/channel' && <span className={`${activeListTab != '1' ? 'padleft' : ''}`}>
                    {(userStatus.channelTypeId === "2" && userStatus.businessType == 2) && <span> {totalTabCount ? parseInt(totalTabCount?.suggestion_dist_count) + parseInt(totalTabCount?.suggestion_reseller_count) : 0} </span>}
                    {(userStatus.channelTypeId === "2" && userStatus.businessType == 1) && <span> {totalTabCount ? parseInt(totalTabCount?.suggestion_dist_count) + parseInt(totalTabCount?.suggestion_retail_count) : 0} </span>}
                    {(userStatus.channelTypeId === "2" && userStatus.businessType == 3) && <span> {totalTabCount ? parseInt(totalTabCount?.suggestion_dist_count) + parseInt(totalTabCount?.suggestion_reseller_count) + parseInt(totalTabCount?.suggestion_retail_count) : 0} </span>}
                    {userStatus.channelTypeId === "3" && <span> {totalTabCount ? parseInt(totalTabCount?.suggestion_vendor_count) : 0} </span>}
                    {userStatus.channelTypeId === "4" && <span> {totalTabCount ? parseInt(totalTabCount?.suggestion_vendor_count) : 0} </span>}
                  </span>
                  }
                </a>
              </div>
            </Col>
            {/* {userStatus.channelTypeId !== "2" && <Col onClick={() => { listToggle("2") }} xs="6" className="icon-tag2 mt-2 sub-a position-relative">
              <div className={`${activeListTab != '2' ? 'position-absolute inactive' : ''} awards_thumb p-2 mr-2`} >
                <a style={{ cursor: 'pointer' }} >
                  <h3 >PRODUCTS</h3><span>{totalTabCount ? parseInt(totalTabCount?.product_others_count) + parseInt(totalTabCount?.product_suggestion_count) : 0}</span>
                </a></div>
            </Col>} */}
          </Row>
        </NavLink>
      </NavItem>}

      {(activeListTab === '1' && listing === '/admin/search/11') && <>
        {userStatus.channelTypeId !== "2" && <NavItem className="list-adtab">
          <NavLink className={classnames({ active: activeTab === "11" })} onClick={() => { toggle("11") }}>
            <h4>VENDOR</h4><span>{tabCount ? tabCount.suggestion_vendor_count : 0} </span>
          </NavLink>
        </NavItem>}
      </>}
      {(activeListTab === '1' && listing === '/admin/search/12') && <>
        {(userStatus.channelTypeId !== "2" && userStatus.channelTypeId !== "4") && <NavItem className="list-adtab">
          <NavLink className={classnames({ active: activeTab === "12" })} onClick={() => { toggle("12") }}>
            <h4>DISTRIBUTOR</h4><span>{tabCount ? tabCount.suggestion_vendor_count : 0} </span>
          </NavLink>
        </NavItem>}
      </>}
      {(activeListTab === '1' && listing === '/admin/search/13') && <>
        {userStatus.channelTypeId !== "2" && <NavItem className="list-adtab">
          <NavLink className={classnames({ active: activeTab === "13" })} onClick={() => { toggle("13") }}>
            <h4>RETAILER</h4><span>{tabCount ? tabCount.suggestion_vendor_count : 0} </span>
          </NavLink>
        </NavItem>}
      </>}

      {(activeListTab === '1' && listing === '/admin/channel') && <>

        {userStatus.channelTypeId !== "2" && <NavItem className="list-adtab">
          <NavLink className={classnames({ active: activeTab === "8" })} onClick={() => { toggle("8") }}>
            <h4>VENDOR</h4><span>{totalTabCount ? totalTabCount.suggestion_vendor_count : 0} </span>
          </NavLink>
        </NavItem>}
        {(userStatus.channelTypeId !== '4' && userStatus.channelTypeId !== '3' && userStatus.channelTypeId !== '5') && <NavItem className="list-adtab">
          <NavLink className={classnames({ active: activeTab === "9" })} onClick={() => { toggle("9") }}>
            <h4>DISTRIBUTOR</h4><span> {totalTabCount ? totalTabCount.suggestion_dist_count : 0} </span>
          </NavLink>
        </NavItem>}
        {(userStatus.channelTypeId !== '4' && userStatus.channelTypeId !== '3' && userStatus.channelTypeId !== '5' && (userStatus.businessType == 3 || userStatus.businessType == 1)) && <NavItem className="list-adtab">
          <NavLink className={classnames({ active: activeTab === "10" })} onClick={() => { toggle("10") }}>
            <h4>RETAILER</h4><span>{totalTabCount ? totalTabCount.suggestion_retail_count : 0}</span>
          </NavLink>
        </NavItem>}

        {/* ||(userStatus.channelTypeId =='3'&&(userStatus.businessType==3||userStatus.businessType==1)) */}
        {((userStatus.channelTypeId == '2' && (userStatus.businessType == 3 || userStatus.businessType == 2))) && <NavItem className="list-adtab">
          <NavLink className={classnames({ active: activeTab === "14" })} onClick={() => { toggle("14") }}>
            <h4>RESELLER</h4><span>{totalTabCount ? totalTabCount.suggestion_reseller_count : 0}</span>
          </NavLink>
        </NavItem>}


        {/* {userStatus.channelTypeId == '2' && <NavItem className="list-adtab">
            <NavLink className={classnames({ active: activeTab === "15" })} onClick={() => { toggle("15") }}>
              <h4>NON MEMBER</h4><span>{tabCount ? tabCount.nonmember_count : 0}</span>
            </NavLink>
          </NavItem>} */}

      </>}
      {(activeListTab === '1' && listing === '/admin/mynetwork') && <>
        {userStatus.channelTypeId != 2 && <NavItem className="list-adtab" style={listing === '/admin/mynetwork' ? { paddingTop: '0rem' } : { paddingTop: '3rem' }}>
          <NavLink className={classnames({ active: activeTab === "2" })} onClick={() => { toggle("2") }}>
            <h4>Outbound</h4><span>{tabCount ? tabCount.outbound_count : 0}</span>
          </NavLink>
        </NavItem>}

        {userStatus.channelTypeId == 2 && <NavItem className="list-adtab" style={listing === '/admin/mynetwork' ? { paddingTop: '0rem' } : { paddingTop: '3rem' }}>
          <NavLink className={classnames({ active: activeTab === "3" })} onClick={() => { toggle("3") }}>
            <h4>Inbound</h4><span>{tabCount ? tabCount.inbound_count : 0}</span>
          </NavLink>
        </NavItem>}

        <NavItem className="list-adtab" style={listing === '/admin/mynetwork' ? { paddingTop: '0rem' } : { paddingTop: '3rem' }}>
          <NavLink className={classnames({ active: activeTab === "1" })} onClick={() => { toggle("1") }}>
            <h4>Confirmed</h4><span>{tabCount ? tabCount.confirm_count : 0}</span>
          </NavLink>
        </NavItem>

        <NavItem className="list-adtab" style={listing === '/admin/mynetwork' ? { paddingTop: '0rem' } : { paddingTop: '3rem' }}>
          <NavLink className={classnames({ active: activeTab === "50" })} onClick={() => { toggle("50") }}>
            <h4>Favourites</h4><span>{tabCount ? tabCount.favorite_count : 0}</span>
          </NavLink>
        </NavItem>

        {/* <NavItem className="list-adtab" style={listing === '/admin/mynetwork' ? { paddingTop: '0rem' } : { paddingTop: '3rem' }}>
          <NavLink className={classnames({ active: activeTab === "4" })} onClick={() => { toggle("4") }}>
            <h4>Request Intro</h4><span>{tabCount ? tabCount.outbound_count : 0}</span>
          </NavLink>
        </NavItem>

        <NavItem className="list-adtab" style={listing === '/admin/mynetwork' ? { paddingTop: '0rem' } : { paddingTop: '3rem' }}>
          <NavLink className={classnames({ active: activeTab === "5" })} onClick={() => { toggle("5") }}>
            <h4>Save For Later</h4><span>{tabCount ? tabCount.inbound_count : 0}</span>
          </NavLink>
        </NavItem> */}
      </>}

      {(activeListTab === '2' && listing === '/admin/channel') && <>

        {/* <NavItem className="list-adtab">
          <NavLink className={classnames({ active: activeTab === "20" })} onClick={() => { toggle("20") }}>
            <h4>INNOVATION</h4><span>{tabCount ? tabCount.product_innovation_count : 0} </span>
          </NavLink>
        </NavItem> */}

        <NavItem className="list-adtab">
          <NavLink className={classnames({ active: activeTab === "22" })} onClick={() => { toggle("22") }}>
            <h4>SUGGESTION</h4><span>{totalTabCount ? totalTabCount.product_suggestion_count : 0} </span>
          </NavLink>
        </NavItem>

        <NavItem className="list-adtab">
          <NavLink className={classnames({ active: activeTab === "21" })} onClick={() => { toggle("21") }}>
            <h4>OTHER PRODUCTS</h4><span>{totalTabCount ? totalTabCount.product_others_count : 0} </span>
          </NavLink>
        </NavItem>
      </>}

      {(activeListTab === '1' && listing === '/admin/advancedsearch') && <>

        <NavItem className="list-ven" style={listing === '/admin/mynetwork' ? { paddingTop: '0rem' } : { paddingTop: '2rem' }}>
          <NavLink className={classnames({ active: activeTab === "30" })} onClick={() => { toggle("30") }}>
            <Col xs="6" className=" mt-2 sub-a">
              <h4>COMPANY</h4><span>{tabCount ? tabCount.channel_adv_search_count : 0}</span>
            </Col>
          </NavLink>
        </NavItem>

        {userStatus.channelTypeId != 2 &&
          <NavItem className="list-ven" style={listing === '/admin/mynetwork' ? { paddingTop: '0rem' } : { paddingTop: '2rem' }}>
            <NavLink className={classnames({ active: activeTab === "31" })} onClick={() => { toggle("31") }}>
              <h4>PRODUCT</h4><span>{tabCount ? tabCount?.all_product_count : 0}</span>
            </NavLink>
          </NavItem>
        }

        {/* <NavItem className="list-ven" style={listing === '/admin/mynetwork' ? { paddingTop: '0rem' } : { paddingTop: '2rem' }}>
          <NavLink className={classnames({ active: activeTab === "32" })} onClick={() => { toggle("32") }}>
            <h4>CONTACT</h4><span>{tabCount ? tabCount.confirm_count : 0}</span>
          </NavLink>
        </NavItem> */}

      </>}
      {((listing === '/admin/mynetwork' || listing === '/admin/advancedsearch') || (listing === '/admin/channel' && user?.planId != '1')) && <>
        <NavItem className="pl-2 right-search" style={listing === '/admin/mynetwork' ? { top: '0.2rem' } : { top: '3.5rem' }}>
          <SelectAutocomplete
            id="search"
            type="text"
            label="search"
            name="search"
            options={searchState.dropdownList}
            placeholder="search..."
            disableCloseOnSelect
            // defaultValue={searchState.search?searchState.search[0]:null}
            control={control}
            input='search'
            value={searchState.search}
            handleChangeSelect={handleChangeSelect}
          />
        </NavItem>
        <NavItem className="right-sort" style={listing === '/admin/mynetwork' ? { top: '0.2rem' } : { top: '3.8rem' }}>
          <UncontrolledDropdown className="p-0" group>
            <DropdownToggle caret className="p-2">
              <h3 style={{ display: "inline-block" }} className="font-weight-bold m-0">
                <i className="fa fa-bars  mr-2" aria-hidden="true"></i>
                {redux_data.sorting === 'asc' ? 'A-Z' : 'Z-A'}
              </h3>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => channelAction.channelRedux('asc', 'SORTING')}>A-Z</DropdownItem>
              <DropdownItem onClick={() => channelAction.channelRedux('desc', 'SORTING')}>Z-A</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </NavItem>
      </>}
    </Nav>
  );
});

// function mapState(state) {
//   const general_data = state.channelListing;
//   return general_data;
// }

// const actionCreators = {
//   channelRedux: channelAction.channelRedux
// };

// export default connect(mapState, actionCreators)(ListingTabs);




export default ListingTabs;