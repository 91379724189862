import React, { useState, useEffect, useRef} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink,  Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Button, Modal } from "reactstrap";
import { Checkbox } from '@material-ui/core';
import MultiUpload from '../MultiUpload';
// import { Aws_service } from '.../../_services/aws-s3.service';
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import {UncontrolledTooltip} from "reactstrap";
import PdfViewer from '../PdfViewer';
import NotificationAlert from "react-notification-alert";
import Dialog from '../Dialog/GlobalDialog';
import PdfView from '../Dialog/PdfView';
import { DocumentName } from './DocumentName';
import axios from 'axios';
export const ImageLibraryMultipleUploads=(props)=> {
  const notificationAlert = useRef(null);
  const { getBrochuresIds, img, aws_folderName, id, isOpen, input, documentIds, limit, acceptedFiles } = props;
  let [loading, setLoading]  = useState(false);
  // let [selectData, setSelectData ] = useState([]);
  let [selectIndex, setSelectIndex] = useState([]);
  let [list, setList] = useState([]);
  const [open, setOpen] = useState(isOpen);
  let [isModalPopup, setModalPopup]  = useState(false);
  let [modalPopValue, setModalPopValue]  = useState({message:null, title:null, value:null, popUpId:null });
  let [isPdf, setPdfOpen]  = useState(false);
  let [pdfUrl, setPdfUrl]  = useState(null);
  let [resImagePath, setResImagePath] = useState(null);
  let [namePopup, setNamePopup]  = useState(false);
  let [docName, setDocName]  = useState(null);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  useEffect(() => {
    getCompanyMediaLibrary('/Product/getBrochurMedialib/');
    setOpen(true);
  }, [])
  const pdfModalClose = () => {
    setPdfOpen(false);
  };
  const pdfPreview =(data)=>{
    pdfUrl = data.document.documentUrl;
    setPdfUrl(pdfUrl);
    setPdfOpen(true);
  }
  const handleClose = () => {
    setActiveTab('1');
    props.handleClose();
    setOpen(false);
  };
  const handleUpload=()=>{
    setOpen(false);
    console.log(selectIndex);
    getBrochuresIds(input, selectIndex, 'dummy');
    handleClose();
  }
  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  //localStorage
  let userData = JSON.parse(localStorage.getItem('CHuser'));

    const getCompanyMediaLibrary = async(url)=>{
      setLoading(true);
      //console.log("MediaLibrary url ==",url+userData?.channelId);
      const MediaLibrary = await commonService.getServices(url+userData?.channelId);
      //console.log("MediaLibrary",MediaLibrary?.data);
      if(MediaLibrary?.data){
          list=MediaLibrary?.data.filter(x=> !documentIds?.includes(parseInt(x.documentId)));
          setList(list);
      }
          setLoading(false);
    }
    const handleToggle = (value) => {
      const currentIndex = selectIndex.indexOf(value);
      const newChecked = [...selectIndex];
      if (currentIndex === -1) {
        let limit=5-documentIds.length;
        if(newChecked.length>=limit){
          notify('warning','Maximum 5 Images');
          return false;
        }
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      selectIndex=newChecked;
      setSelectIndex(selectIndex);
      console.log("selectIndex",selectIndex);
    };

  const deletePdf = async(data)=>{
    console.log('data', data);
    setModalPopup(false);
    loading=true;
    setLoading(loading);
    await commonService.postService({
      libId:parseInt(data.value.data.libId),
      user:{
        channelId:userData?.channelId,
        contactId:userData?.contactId
      }}, '/Product/deleteProductMediaLib');
      selectIndex=[];
      setSelectIndex(selectIndex);
      getCompanyMediaLibrary('/Product/getBrochurMedialib/');
  }

    const selectImg = async(data)=>{
      if(data.length>0){
        loading=true;
        setLoading(loading);
        const formData = new FormData();
        formData.append('file', data[0]);
        const res = await commonService.fileUploadService(formData, '/mediaupload/upload/broucher');
      if(res){
        resImagePath=res.data;
        setResImagePath(resImagePath);
        docName=data[0].name;
        setDocName(docName);
        setNamePopup(true);
      }else{
        setLoading(false);
        }
      }
    }
    const uploadImage= async(docId,name)=>{
      namePopupClose();
      const uploadMediaLibrary = await commonService.postService({
        documentpath:resImagePath.url,
        type:4,
        name:name,
        channelId:userData?.channelId,
        user:{
          channelId:userData?.channelId
        }}, '/Product/productmedialib');
        selectIndex=[];
        setSelectIndex(selectIndex);
      list.unshift(uploadMediaLibrary.data);
      setList(list);
      handleToggle(list[0]);
      toggle('2');
      setLoading(false);
    }
    const notify = (type, message) => {
      let options = {
        place: "tr",
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {/* {" "}
              {type} */}
            </span>
            <span data-notify="message">
              {message}
            </span>
          </div>
        ),
        type: type,
        // icon: "ni ni-bell-55",
        autoDismiss: 5
      };
      notificationAlert.current.notificationAlert(options);
    };
    const openModalPopUp = (data) => {
        modalPopValue.message= 'You want to Delete';
        modalPopValue.title= "Delete Pdf";
        modalPopValue.value= { data };
      modalPopValue.popUpId= 1;
      setModalPopValue({...modalPopValue})
      setModalPopup(true);
    };
    const closePopup = () => {
      setModalPopup(false);
    };
    const namePopupClose = () => {
      setNamePopup(false);
    };
  return (
    <div>
      <PdfView pdfModalClose={pdfModalClose} isOpen={isPdf} url={pdfUrl}/>
      <NotificationAlert ref={notificationAlert}/>
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={deletePdf} handleClose={closePopup}></Dialog>
      <DocumentName isOpen={namePopup} handleClose={namePopupClose} isEdit={false} handleSave={uploadImage} name={docName} docId={null} />
      <Modal scrollable={true}
          className="modal-dialog-centered"
          isOpen={open} size="lg">
          <div className="modal-header">
          <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Upload File
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
          Media library
          </NavLink>
        </NavItem>
     
      </Nav>
     
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={ handleClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          
          </div>
          <div className="modal-body p-0">
       
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
        {loading? <Spinner/> : <Row>
            <Col sm="12">
            <MultiUpload limit={limit} acceptedFiles={acceptedFiles} handleChange={selectImg} input={input}/>

            </Col>
          </Row>}
        </TabPane>
        <TabPane tabId="2">
        {list.length <= 0 && <div className="no-records">
          {/* <span>No Records</span> */}
          <div className="no-img text-center">
          <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")}/></div>
        </div>}
        {loading? <Spinner/> :<>
          <div className="container">
        <div className="dropdown-divider m-0"></div>
        </div>
          <Row>
    {list.map((x, i)=>{
      return(
        <Col sm="2" lg="2" className="mr-3">
        <div className="brandCard" onClick={()=>handleToggle(x)}>
        <GreenCheckbox style={{width:'50px',position:'absolute',top:'-12px',right:'-15px', zIndex:'1000'}}  checked={selectIndex.indexOf(x) !== -1} tabIndex={-1} name='check' />
            <div  className="pdfContiner">
              
            <PdfViewer className="pdf-view" edit={false} pdf={x.document.documentUrl} />
          </div>
          <div className="row medd-view">
          <div className="col-6 text-right">
            <div id='eye' className="editeye text-center" onClick={()=>pdfPreview(x)}>
            <i  class="fa fa-eye"></i> 
          </div> 
          <UncontrolledTooltip content='preview video' target='eye' placement='bottom'>
              preview
            </UncontrolledTooltip></div>
           <div className="col-6 text-left">
             <div  id='delete' className="deleteicon text-center" onClick={()=>openModalPopUp(x)}>
           <i class="fas fa-trash-alt"></i>
          </div>
          <UncontrolledTooltip content='Delete video' target='delete' placement='bottom'>
              delete
            </UncontrolledTooltip></div>
           </div>
           {!!x.name &&<div className="row medd-view">
          <div className="col-12 text-right">
            <div id={'show'+x.documentId} className="namedesc text-center">
           {x.name}
          </div>
          <UncontrolledTooltip content='show content' target={'show'+x.documentId} placement='bottom'>
              {x.name}
            </UncontrolledTooltip>
            </div>
            
           </div>}
        </div>
       </Col>
      )
    })}
          </Row></>}
        </TabPane>
      </TabContent>
          </div>
          <div className="modal-footer">
          {activeTab === '2' &&<>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={handleUpload} disabled={selectIndex.length<=0}>
              Upload
            </Button></>}
          </div>
        </Modal>
       
    </div>
  );
}
export default ImageLibraryMultipleUploads;
