import React, { useState, useEffect } from "react";
import { Card, Button, CardBody, Container, UncontrolledTooltip } from "reactstrap";
import { useForm } from "react-hook-form";
import { FormRadio, } from "_components/FormElements/FormInput";
import { FormAutocompleteGrouping, SingleAutocomplete, FormAutocompleteGroupingRegion } from '../../../../_components/FormElements/FormInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserProfileSchema } from '../validation';
import { useBeforeFirstRender } from '_hooks/index'
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import axios from 'axios';
import { history } from '../../../../_helpers';
import { dashboardActions } from '../../../../_actions';
import { Spinner } from 'views/Hoc/Spinner';
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { FormControl } from "@material-ui/core";
var _ = require('lodash');



const UserProfile = (props) => {

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(UserProfileSchema),
  });

  const [state, setState] = useState({

    sellingCountries: [],
    sellingCountriesOption: [],
    targetCountries: [],
    targetCountriesOption: [],

    productValue: [],
    productOptions: [],
    documentId: '',
    suggestions: '1',
    networkInvitation: '1',
    companyLogo: ''
  });

  //localStorage
  var userData = JSON.parse(localStorage.getItem('CHuser'));

  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    console.log("check:", check, gname);
    if (check == true) {
      state[optionName].map((item) => {
        console.log("groupname:", item.groupname);
        if (item.groupname == gname || item.status == true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check == false) {
      console.log("check:", check);
      state[optionName].map((item) => {
        if (item.groupname == gname && state[optionValue].length > 0)
          item.status = check;
        // if(state.productValue.length==0)
        //     item.status=false;
      });

      opt = state[optionValue].filter((item) => item.groupname != gname);
      opt.map(option => option.status = true);
    }

    state[optionValue] = opt;
    setState({ ...state, [optionValue]: state[optionValue] });
    setState({ ...state, [optionName]: state[optionName] });

    if (optionValue = 'sellingCountries' && state.sellingCountries.length == 0)
      errors.selling = true;
    else if (optionValue = 'targetCountries' && state.targetCountries.length == 0)
      errors.target = true;
    else if (optionValue = 'productValue' && state.productValue.length == 0)
      errors.productCat = true;


  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const apiUrl = '/setting/getInitialProfile';

      await axios.get(apiUrl).then(res => {
        state.productOptions = res.data.productGroup;
        setState({ ...state, productOptions: state.productOptions });
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getInitialProfile' }, '/error')
      })

      await axios.get('setting/getUserProfile/' + userData?.channelId).then(res => {
        state.productValue = res.data.productGroup;
        state.sellingCountriesOption = res.data.regions1;
        state.targetCountriesOption = res.data.regions;

        setState({ ...state, targetCountriesOption: state.targetCountriesOption });
        setState({ ...state, productValue: state.productValue });
        setState({ ...state, sellingCountriesOption: state.sellingCountriesOption });

        state.productOptions = state.productOptions.map(x => {
          res.data.productGroup.map(y => {
            if (x.groupid == y.groupid && x.childid == y.childid) {
              x.status = true;
            }
          })
          return x;
        })
        setState({ ...state, productOptions: state.productOptions });
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getUserProfile' }, '/error')
      })
    })();

  }, []);

  // useEffect(() => {  
  //   if(!_.isEmpty(errors)&&formState.isSubmitted)
  //   {
  //     ToastsStore.error(JSON.stringify(errors));
  //     commonService.postService({err:JSON.stringify(errors),reqUrl:window.location.pathname,service:'validation_err'},'/error')    
  //   }
  // }, [errors])

  const getCompanyLogo = (resImagePath) => {

    setState({ ...state, companyLogo: resImagePath.document.documentUrl, documentId: resImagePath.documentId });

  };

  const handleChange = input => e => {
    setState({ ...state, [input]: e.target.value });
  }

  const handleChangeSelect = (input, value) => {

    if (input == 'product') {
      state.productOptions.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.productValue = value
      setState({ ...state, productOptions: state.productOptions });
      setState({ ...state, productValue: state.productValue });

      if (state.productValue.length == 0)
        errors.productCat = true;
    }
    else if (input == 'sellingCountries') {
      state.sellingCountriesOption.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       

          // });
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.sellingCountries = value
      setState({ ...state, sellingCountries: state.sellingCountriesOption });
      setState({ ...state, sellingCountries: state.sellingCountries });

      if (state.sellingCountries.length == 0)
        errors.selling = true;
    }
    else if (input == 'targetCountries') {
      state.targetCountriesOption.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //   if(v.childname==item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.targetCountries = value
      setState({ ...state, targetCountriesOption: state.targetCountriesOption });
      setState({ ...state, targetCountries: state.targetCountries });

      if (state.targetCountries.length == 0)
        errors.target = true;
    }
    // else if(input=='sellingRegion'){
    //   state.sellingRegion= value
    //   setState({...state, sellingRegion:state.sellingRegion});
    // }
    if (input != 'product' && input != 'sellingCountries' && input != 'targetCountries')
      setState({ ...state, [input]: [value] });
  }


  const save = async () => {
    try {
      setLoading(true);
      let data = {
        // sellingRegion : state.sellingCountries.map(x => +x.regionCountryJCTId),
        // targetRegion : state.targetCountries.map(x => +x.regionCountryJCTId),  
        companyLogo: state.documentId,
        productGroup: state.productValue.map(x => +x.groupid),
        productCat: state.productValue.map(x => +x.childid),
        suggestions: +state.suggestions,
        networkInvitation: +state.networkInvitation,
        ratingId: +props.ratingId,
        contactId: userData?.contactId,
        channelId: userData?.channelId
      };

      if (typeId == 4 || typeId == 3 || typeId == 5)
        Object.assign(data, { sellingRegion: state.sellingCountries.map(x => +x.regionCountryJCTId) });
      if (typeId == 2 || typeId == 3)
        Object.assign(data, { targetRegion: state.targetCountries.map(x => +x.regionCountryJCTId) });

      let result = await dashboardActions.userInfo({ data }).catch(err => {
        ToastsStore.error('Something Went Wrong!');
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'userInfo' }, '/error')
      });
      if (result) {
        ToastsStore.success('Saved Successfully!')

        if (state.companyLogo) {
          let existing = JSON.parse(localStorage.getItem('CHuser'));
          existing['documentUrl'] = state.companyLogo;
          localStorage.setItem('CHuser', JSON.stringify(existing));
        }

        setLoading(false);
      }
      window.location.href = process.env.REACT_APP_LOCALSITE + "admin/dashboard";
      //history.push('admin/dashboard');
    }
    catch (err) {
      ToastsStore.error('Something Went Wrong!');
      setLoading(false);
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'userInfo' }, '/error');
    }
    //console.log('save', data);
  }

  //console.log('state', state);
  //console.log('err', errors)
  const onSubmit = data => {
    if (data) {
      console.log("data:", data);
    }
  }

  let typeId = userData?.channelTypeId;

  return (

    <div className="main-content">
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      {loading ? <Spinner /> :
        <Container className="mt-4 col-xl-9 col-lg-12 col-md-12 col-sm-12" fluid>
          <form noValidate onSubmit={handleSubmit(save)}>
            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="typeId" value={typeId} control={control} />
            <Card>
              <CardBody>

                <h3 className="text-center">User Profile</h3>
                <p className="text-center">Your personal profile and contact details</p>



                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-6">
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} />
                        <h4>Profile Image *{!errors.companyLogo || state.companyLogo ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Profile Image</span>}</h4>
                        <div>
                          <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='account-s3' id={2} size={1}
                            img={state.companyLogo ? state.companyLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />

                        </div>
                      </div>
                      <div className="col-sm-6 mt-4">
                        <span>Accepts Jpeg / Jpg / Png Only</span>
                        <br></br>
                        <span>(Max size allowed 200 Kb)</span>
                      </div>
                    </div>

                  </div>

                </div>


              </CardBody>
            </Card>

            <Card>
              <CardBody>

                <h3 className="text-center mt-4" >Trade Locations</h3>
                <p className="text-center" >Update the list of countries you are in charge of or you are targetting.</p>

                {(typeId == 3 || typeId == 4 || typeId == 5) && (
                  <div className="col-lg-12 ">
                    <div className="form-group">
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="selling" name="selling" ref={register} value={state.sellingCountries.length ? state.sellingCountries[0].childname : ''} />
                      <FormAutocompleteGrouping
                        input="sellingCountries"
                        id="SellingCountries"
                        label='Selling Locations *'
                        name="SellingCountries"
                        Options={state.sellingCountriesOption}
                        placeholder="Search"
                        errMessage='Please choose Selling Locations'
                        error={!errors.selling || state.sellingCountries.length ? false : true}
                        optionName='sellingCountriesOption'
                        optionValue='sellingCountries'
                        defaultValue={state.sellingCountries}
                        value={state.sellingCountries}
                        // loading={true}
                        selectedItem={selectedItem}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}
                      />
                    </div>
                  </div>
                )}

                {(typeId == 3 || typeId == 2) && (

                  <div className="col-lg-12 ">
                    <div className="form-group">
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="target" name="target" ref={register} value={state.targetCountries.length ? state.targetCountries[0].childname : ''} />
                      <FormAutocompleteGroupingRegion
                        input="targetCountries"
                        id="TargetCountries"
                        label="Target Locations *"
                        name="TargetCountries"
                        Options={state.targetCountriesOption}
                        placeholder="Search"
                        errMessage={'Please choose Target Locations'}
                        error={!errors.target || state.targetCountries.length ? false : true}
                        optionName='targetCountriesOption'
                        optionValue='targetCountries'
                        defaultValue={state.targetCountries}
                        value={state.targetCountries}
                        // loading={true}
                        selectedItem={selectedItem}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}
                      />
                    </div>
                  </div>
                )}

              </CardBody>
            </Card>

            <Card>
              <CardBody>


                <h3 className="text-center">{userData.channelTypeId == 2 ? 'Product Responsibilities' : 'Product Interest'}</h3>
                <p className="text-center">{userData.channelTypeId == 2 ? 'Select the product families that you are in charge of' : 'Select the products families that you offer'}</p>
                {/* <h5 style={{color:'green'}}>Download to view List of Product Families and relevant Categories<h5/> */}

                <div className="col-lg-12 ">
                  <div className="form-group">
                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productCat" name="productCat" ref={register} value={state.productValue.length ? state.productValue[0].childname : ''} />
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <FormAutocompleteGrouping
                        id="ProductResponsibilities"
                        name="ProductResponsibilities"
                        label={userData.channelTypeId == 2 ? "Product Responsibilities" : "Product Interest *"}
                        Options={state.productOptions}
                        optionName='productOptions'
                        optionValue='productValue'
                        placeholder={userData.channelTypeId == 2 ? "Product Responsibilities" : "Product Interest *"}
                        errMessage='Please Select Product'
                        error={!errors.productCat || state.productValue.length ? false : true}
                        defaultValue={state.productValue}
                        value={state.productValue}
                        input="product"
                        selectedItem={selectedItem}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}
                      />
                    </FormControl >
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card style={{display:'none'}}>
              <CardBody>

                <h3 className="text-center">Communication Preference <span id='algorithm5'><i class="far fa-handshake didy"></i></span>
                  <UncontrolledTooltip className="my-tooltip11" className="handshak" delay={0} delay={0} placement="bottom" target="algorithm5">This field is included in the matching algorithm</UncontrolledTooltip></h3>
                {/* <h5>	How frequently do you wish to receive suggestions? As they come!</h5> */}
                <div className="row ">
                  <div className="col-sm-12 mt-4">
                    <div className="form-group ">
                      <FormRadio
                        name="CommunicationPreference1"
                        className="text-sm mb-0"
                        label="1.How frequently do you wish to receive suggestions?"
                        options={radioGroupOptions}
                        control={control}
                        register={register}
                        row
                        onChange={handleChange('suggestions')}
                        value={state.suggestions}
                        defaultValue={state.suggestions}
                        error={errors.CommunicationPreference1}
                      />
                    </div>
                  </div>
                </div>


                <div className="row mt-2">
                  <div className="col-sm-12">
                    <div className="">
                      <FormRadio
                        name="CommunicationPreference2"
                        className=""
                        label={`2.	How do you want to manage your network invitation coming from ${userData?.channelTypeId == '2' ? 'buyers' : 'vendors and distributors'} ? `}
                        options={radioGroupOptions}
                        register={register}
                        control={control}
                        row
                        onChange={handleChange('networkInvitation')}
                        value={state.networkInvitation}
                        defaultValue={state.networkInvitation}
                        error={errors.CommunicationPreference2}
                      />
                    </div>
                  </div>
                </div>
               
              </CardBody>

            </Card>
            <div className="col-lg-12 text-center mt-4">
                  <Button color="primary" type="submit" >
                    Save
                  </Button>
                  <Button color="primary" type="button" href="#">
                    Cancel
                  </Button>
                </div>

          </form>
        </Container>
      }
    </div>


  );
};



const radioGroupOptions = [
  {
    value: "0",
    label: "As they come!",
  },
  {
    value: "1",
    label: "One a  week",
  },
  {
    value: "2",
    label: "Once per 2 weeks ",
  },
  {
    value: "3",
    label: "Once per month",
  },
  {
    value: "4",
    label: "Never",
  },

];

export default UserProfile;