import React,{useState,useRef} from "react";
import { Col, Container, Row, Card,CardBody,Button,UncontrolledTooltip } from "reactstrap";
import logo from 'assets/img/brand/ch-logo.svg'
import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextArea,FormCheckBox,FormAutocomplete,FormInput  } from "_components/FormElements/FormInput";
import { useForm} from "react-hook-form";
import { companyProfileSchema } from "./validation"
import {history} from "_helpers/history";
import {commonService} from "_services/common.service";
import { Spinner } from "views/Hoc/Spinner";
import { useDispatch } from 'react-redux';
import { dashboardConstants } from '_constants';
import { dashboardService } from '_services';
import NotificationAlert from "react-notification-alert";


const OrderSummary = (props) => {
 
    const dispatch=useDispatch();
    const notificationAlert = useRef(null)
    const {cart, checkout}=props;
    let [loading, setLoading]  = useState(true);
    const {control, errors  } = useForm({      
        mode:'onBlur',  
        // resolver: yupResolver(companyProfileSchema),
      });
    

    const notify = (type, message) => {
      let options = {
        place: "tr",
        message: (
          <div className="alert-text">
            <span data-notify="message">
              {message}
            </span>
          </div>
        ),
        type: type,
        // icon: "ni ni-bell-55",
        autoDismiss: 5
      };
      notificationAlert.current.notificationAlert(options);
    };

    const save = async() => {
        //console.log('checkout', checkout, cart);

        let data = {
            billingAddressId: +checkout.billingAddressId,
            shippingAddressId: +checkout.shippingAddressId,
            cartId: +cart.cartid,
            requestFreeSample: false
        }

        setLoading(true);
        let res = await commonService.postService(data, 'checkout').catch(err =>console.log('err', err));        
        if(res) {
            setLoading(false);    
            dashboardService.dashboard_count().then(dasboard_count => {               
                //console.log("dashboard_count:",dasboard_count);
                dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
              });
          notify('success','  Order Placed Successfully');          
          history.push({pathname:'admin/ordersuccess', state:{orderId: res.data.order}});                
        }

    }

  return (
      <>
      <NotificationAlert ref={notificationAlert}/>
     <Card className=" col-lg-6 col-md-8 col-sm-6 mt-4 container-fluid numcard">
              <CardBody >
        
                 
                <Row>
                <Col lg="2"></Col>
                <Col lg="8">

                <div className="Yorder">
                        <h3 className="text-center w-100">Your Order Summary</h3>
                    <table>
                    <tr>
                        <td>Total No of Products</td>
                        <td>{cart.items?.length}</td>
                    </tr>
                    <tr>
                        <td>Price</td>
                        <td>${cart.subtotal}</td>
                    </tr>
                    <tr>
                        <td>Tax</td>
                        <td>${cart.taxcost}</td>
                    </tr>
                    <tr>
                        <th>TOTAL</th>
                        <td>${cart.grandtotal}</td>
                    </tr>
                    </table><br/>
   
                   
  
   
                    <div className="text-center mt-3  pad-less">
                    <button type="submit" className="btn btn-warning" onClick={e=>save()} >
                        Send to vendor for approval</button>   
                       </div>
                    </div>
                </Col>
                <Col lg="2"></Col>
                </Row>
           
              </CardBody >
            </Card>

           
           
           
            
    </>
  );
}

export default OrderSummary;