
import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card, CardImg, Nav, NavItem, NavLink } from "reactstrap";
import GuestNavBar from 'views/pages/GuestPage/GuestNavbar/GuestNavbar';
import PubTitle from "views/Hoc/Dialog/PubTitle";
import { history } from '../../../_helpers/history';
import EventChannelListing from "./EventChannelListing";
import EventListing from "./EventListing";
import EventListingFilter from "./EventListingFilter";

export const Vendorbuyerlist = ({ activeTab,...props }) => {

  const [filterValueState, setFilterValue] = useState({ Interested_Product: [], Current_Product: [], Speciality: [], Brand: [], Retailer_Profile: [], Reseller_Profile: [], Company_Origin: [], Target_Location: [], Annual_Turnover: null, typeId: [], Store_Count: null });
  const [sort, setSort] = React.useState(true);
  let [flag, setFlag] = useState(0);
  let [channelFlag, setChannelFlag] = useState(true);
  let [parent, setParent] = useState(null);
  let [searchState, setSearch] = useState(null);

  // useEffect(() => {
  //   if (activeTab)
  //     handleChangeSelect('search', search);
  // }, [search]);

  const handleChangeSelect = (input, value) => {
    console.log('input, value', input, value);
    if (input === 'search') {
      searchState = value;
      setSearch(searchState);
      //console.log('searchState',searchState);
    }
    if (input === 'Speciality') {
      filterValueState.Speciality = value;
      if (parent == null || parent == 'Speciality') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Speciality';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'Brand') {
      filterValueState.Brand = value;
      if (parent == null || parent == 'Brand') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Brand';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'current_product') {
      filterValueState.Current_Product = value;
      if (parent == null || parent == 'current_product') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'current_product';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'interested_product') {
      filterValueState.Interested_Product = value;
      if (parent == null || parent == 'interested_product') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'interested_product';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'Retailer_Profile') {
      filterValueState.Retailer_Profile = value;
      if (parent == null || parent == 'Profile') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Profile';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'Reseller_Profile') {
      filterValueState.Reseller_Profile = value;
      if (parent == null || parent == 'Profile') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Profile';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'Company_Origin') {
      filterValueState.Company_Origin = value;
      if (parent == null || parent == 'Company_Origin') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Company_Origin';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'Target_Location') {
      filterValueState.Target_Location = value;
      if (parent == null || parent == 'Target_Location') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Target_Location';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }

    if (input === 'Annual_Turnover') {
      filterValueState.Annual_Turnover = value;
      if (parent == null || parent == 'Annual_Turnover') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Annual_Turnover';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }

    if (input === 'Store_Count') {
      filterValueState.Store_Count = value;
      if (parent == null || parent == 'Store_Count') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Store_Count';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }

    if (input === 'channel_type') {
      filterValueState.typeId = value;
      if (parent == null || parent == 'typeId') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'typeId';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    setFilterValue({ ...filterValueState })
    console.log('filterValueState', filterValueState);
  }
  const resetFilterValue = () => {
    setFlag(0);
    setParent(null);
    filterValueState.Speciality = [];
    filterValueState.Current_Product = [];
    filterValueState.Interested_Product = [];
    filterValueState.Brand = [];
    filterValueState.Company_Origin = [];
    filterValueState.Target_Location = [];
    filterValueState.Retailer_Profile = [];
    filterValueState.Reseller_Profile = [];
    filterValueState.Reseller_Profile = [];
    filterValueState.Annual_Turnover = null;
    filterValueState.Store_Count = null;
    filterValueState.typeId = [];
    setFilterValue({ ...filterValueState });
    searchState = null;
    setSearch(searchState);
  }

  useEffect(() => {
    if (props.id == 'buyers' || props.id == 'vendors')
      console.log('vendors')
    else
      history.push('');
    console.log('props', props);
  }, [])
  return (
    <>
      <div id='Favourites' className="main-content">
        <Card>
          <CardBody>
            <Row className="">
              <Col lg='3' className='p-0'>
                <Card>
                  <CardBody className='pl-0 pr-0 pt-0'>
                    <h4 className="text-muted mb-0">FILTERS</h4>
                    <EventListingFilter activeTab={activeTab} setChannelFlag={setChannelFlag} parent={parent} flag={flag} setFilterValue={setFilterValue} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} eventId={props.eventId} type={props.id === 'buyers' ? 'vendor' : 'buyer'} setFlag={setFlag}
                    setParent={setParent}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col style={{ backgroundColor: "#f8f9fe" }} lg="9">
               
                <EventChannelListing count={props.count} getCount={props.getCount} type={props.id === 'buyers' ? 'buyer' : 'vendor'} buyerList={props.buyerList} sellerList={props.sellerList} eventId={props.eventId} filterValueState={filterValueState} flag={flag} resetFilterValue={resetFilterValue} setChannelFlag={setChannelFlag} showBuyerMeeting={props.showBuyerMeeting} isScheduler={props.meetId===1?true:false}/>
              
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default Vendorbuyerlist;