import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, Row, Col, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import axios from 'axios';
import { Spinner } from "views/Hoc/Spinner";

const EventTabsDynamic = ({ activeTab, changeTab, channelTypeId, isSeller, isBuyer, eventDetails }) => {

    // let [isBoFo, setIsBoFo] = useState(false);
    // useEffect(() => {
    //   console.log('window.location.hostname', window);
    //   console.log('window.location.hostname', window.location.hostname);
    //   isBoFo = window.location.hostname === 'localhost'; //process.env.REACT_APP_BOFO
    //   setIsBoFo(isBoFo);
    // }, []);

    const [loading, setLoading] = useState(false);
    const [PreTabs, setPreTabs] = useState([]);

    const EventTabFlags = async () => {

        await axios.get('/channelAdmin/EventTabsFlag/' + eventDetails.eventId + '/all').then(res => {
            if (res.data["result"].filter(tab => tab.tabType === "PreEvent").length > 0) {
                let data = res.data["result"].filter(tab => tab.tabType === "PreEvent").sort((a, b) => a.id - b.id);
                setPreTabs(data);
            }
            else
                setPreTabs((tab) => {
                    return res.data["master"].filter(tab => tab.tabType === "PreEvent").sort((a, b) => a.id - b.id).map(x => {
                        x.checked = true;
                        x.customName = null;
                        x.hideorsoon = null;
                        x.url = null;
                        x.template = null;
                        return x;
                    })
                })
        });
    }

    useEffect(() => {

        (async () => {
            setLoading(true);
            await EventTabFlags();
            setLoading(false);
        })();


    }, [])

    return (
        <>
            {(loading && PreTabs.length > 0) ? <Spinner /> :
                <Nav tabs className="list-tab" id='Event'>

                    {PreTabs?.map(tab => {
                        return <>
                            {(tab.hideorsoon === '1' || tab.checked) &&
                                <NavItem className="list-adtab" onClick={() => tab.checked ? tab.url != null ? window.open(tab.url, '_blank').focus() : changeTab(tab.id) : ""}>
                                    <NavLink className={classnames({ active: activeTab == tab.id })}>
                                        <h4 id={"tab" + tab.id}>{tab.customName == null ? tab.label : tab.customName}</h4>
                                        {(tab.checked == false && tab.hideorsoon === '1') &&
                                            <UncontrolledTooltip target={"tab" + tab.id} placement='bottom'> Coming soon </UncontrolledTooltip>}
                                    </NavLink>
                                </NavItem>
                            } </>
                    })}


                </Nav>}
        </>
    );
};

export default EventTabsDynamic;