import React, { useState,useEffect } from "react";
import { Card,Button, CardBody, Container,CardHeader,Row,Col,UncontrolledTooltip} from "reactstrap";
import { useForm } from "react-hook-form";
import {FormInput, FormAutocomplete, FormRadio} from "_components/FormElements/FormInput";
import { FormAutocompleteGrouping, PhoneInput2, SingleAutocomplete } from '../../../_components/FormElements/FormInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { GeneralFormSchema } from './validation';
import {useBeforeFirstRender} from '_hooks/index'
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import axios from 'axios';
import { history } from '_helpers';
import { connect } from 'react-redux';
import {ModalSpinner} from 'views/Hoc/Spinner';
import {InputLabel,Select,TextField,FormControl} from '@material-ui/core';
import { adduserActions } from "_actions/adduser.actions";
import { Spinner} from 'views/Hoc/Spinner';
import { commonService } from "_services/common.service";

const AddgeneralForm = (props) => {
  
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors, watch } = useForm({      
    mode:'onBlur',  
    resolver: yupResolver(GeneralFormSchema),
  });

const addOrEdit = props.addOrEdit;
const contactId = props.contactId;

const [state, setState] = useState({
  
  firstName:'',
  lastName:'',
  jobTitle:'',
  email:'',
  reportingTo:'',
  phone:['', '', ''],  
  mobileNo:['', '', ''],
  empId:'',
  HSContact:'',
  userType:'3',
  companyLogo:'',
  status:true,
  country:'',
  countryList:[],
  linkedinurl:'',
  twitterurl:'',
  isShared:'1',
  documentId:'',
  reportingList:[],

  countryCodemob: '',
  countryCodemob1: ''
});

const watchAll = watch();

  //localStorage
  var userData = JSON.parse(localStorage.getItem('CHuser'));

   const selectedItem=(gname,check,optionName,optionValue) => {    
    let opt=[];    
    console.log("check:",check,gname);
    if(check==true)
    {    
      state[optionName].map((item) => {      
        console.log("groupname:",item.groupname);
            if(item.groupname==gname||item.status==true)
            {
              item.status=check;      
              opt.push(item);
            }     
      });
    }
    if(check==false)
    {  
      console.log("check:",check);
      state[optionName].map((item) => {      
          if(item.groupname==gname&&state[optionValue].length>0)
              item.status=check;                          
          // if(state.productValue.length==0)
          //     item.status=false;
      });

      opt=state[optionValue].filter((item) => item.groupname!=gname);      
      opt.map(option => option.status=true);
    }
    
    
    state[optionValue]=opt;
    setState({...state, [optionValue]:state[optionValue]});    
    setState({...state, [optionName]:state[optionName]});    

    if(optionValue='sellingCountries' && state.sellingCountries.length==0)
        errors.selling=true;
    else if(optionValue='targetCountries' && state.targetCountries.length==0)
        errors.target=true;
    else if(optionValue='productValue' && state.productValue.length==0)
        errors.productCat=true;
    

  }

  const valid = () => {
    if(!errors.mobile || state.mobileNo[2].length >= 4)  
    return true;
    return 'Mobile No Must Have Min 4 Numbers';
}

const valid1 = () => {
  if(!errors.phone || state.phone[2].length >= 4)  
  return true;
  return 'Phone No Must Have Min 4 Numbers';
}

  useEffect(() => {

    setLoading(true);
    (async() =>{  
      await axios.get('common/getSignup/'+userData?.channelTypeId).then(res =>{                  
        state.countryList=res.data.country;
        //setState({...state, countryList:res.data.country});                  
      });          

      await axios.get('channel/channelCountry').then(res => {
        state.countryCodemob = res.data.isoCode;
        state.countryCodemob1 = res.data.isoCode;
        let temp = state.countryList.filter(x => x.id == res.data.countryId);
        state.country = temp.length ? temp[0] : null;
      })

      await axios.get('setting/reporting').then(res =>{        
        state.reportingList=res.data;        
      });          

      if(contactId && contactId!='0'){
        await axios.post('setting/generalInfo', {contactId: contactId}).then(res => {
          console.log('response', res.data);
          let country = state.countryList.filter(x => x.id == res.data.countryId);
          state.country = country[0];
          let report = state.reportingList.filter(x => x.id == res.data.rptContactId);
          state.reportingTo = report[0];
          state.firstName = res.data.firstName;
          state.lastName = res.data.lastName;
          state.empId = res.data.empId;
          state.HSContact = res.data.hubspotContactId;
          state.email = res.data.email;
          state.jobTitle = res.data.jobTitle;
          state.linkedinurl = res.data.sociallink?res.data.sociallink[0]?.contactSocialLink:'';
          state.twitterurl = res.data.sociallink?res.data.sociallink[1]?.contactSocialLink:'';
          state.phone = res.data.phone1;
          state.mobileNo = res.data.mobileNo.replace('-','');
          state.companyLogo = res.data.documentId ? res.data.documentPath : null;
          state.contactId = contactId;
          state.documentId=res.data.documentId;
          state.isShared=res.data.isShared;
          state.userType=res.data.roleId;
          state.status=res.data.isActive == '1' ? true : false;
          props.adduser(contactId, 5);
        })
        setState({...state, country: state.country, firstName: state.firstName, lastName: state.lastName,
          empId: state.empId, HSContact: state.HSContact, email: state.email, jobTitle: state.jobTitle,
          linkedinurl: state.linkedinurl, mobileNo: state.mobileNo, phone: state.phone, contactId: state.contactId
        })
        
      }

      setState({...state, reportingList:state.reportingList,countryList:state.countryList});                  
      setLoading(false);
      

    })();
    
  }, []);


const getCompanyLogo = (resImagePath) => {
  
  setState({...state, companyLogo: resImagePath.document.documentUrl,documentId: resImagePath.documentId});
  
};



const handleChange = input => e => {

  if(input == 'status')
    setState({...state, [input] : e.target.checked});
  else
    setState({...state, [input] : e.target.value});
}

console.log('state', state, watchAll);

const handleChangeSelect = (input,value) => {        
  console.log("country:",input,value);
  if(input=='country'){
    state.countryCodemob = value.countryCode;
    state.countryCodemob1 = value.countryCode;
    setState({...state, [input]: value});
  }else{
    setState({...state, [input]:value});   
  }

}

const [checkMail, setCheckMail] = useState(0);

useEffect(() => {
  setCheckMail(0);
}, [watchAll.email]);


const save = async() => {
  //setLoading(true);
  
  let testMail = await commonService.getServices('account/checkmail/'+state.email).then(res => {
    return res.data.isavialble;
  })

  if(!testMail){
    setCheckMail(1);
  }else{
    console.log("state:",state);
    setState({...state, status: watchAll.status ? '1' : '0'}); 
    
    props.adduser(state,1);
    props.toggleNavs("3");
    console.log("save:",props);
  }
  
  
}

let typeId=userData?.channelTypeId;

return (
     <div className="main-content">
     {loading? <Spinner />:(
        <Container className="mt-4 col-lg-12 col-md-9 col-sm-12" fluid>
        <form noValidate onSubmit={handleSubmit(save)}>        
        {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000',}}><ModalSpinner /></div>}
        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="typeId" ref={register} name="typeId" value={typeId} control={control} />
              <Card>
                
             <CardBody>
             <Row>

             <Col lg="6">
                  <h3>Profile Image {!errors.companyLogo || state.companyLogo ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select Profile Image</span>}</h3>
                    <Row>
                      <Col lg="5" style={{padding:"0px"}}>
                      <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} /> 
                      <div>
                    <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='account-s3' id={2} size={1}
                     img={state.companyLogo ? state.companyLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"}/>
                    </div>
                      </Col>
                      <Col  style={{paddingTop:"2.5rem"}} lg="7">
                      <span>Accepts Jpeg / Jpg / Png Only</span>
                      <br></br>
                      <span>(Max size allowed 200 Kb)</span>
                      </Col>
                    </Row>
                  </Col>
                <Col lg="6">
                <div className="form-group text-sm mb-0"> 
                  <h3>User Type <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                   <UncontrolledTooltip className='didy2 handshak' delay={0}  placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h3>       
                    <FormRadio
                    name="User type"
                    className="text-sm mb-0"
            
                    options={radioGroupOptions}
                    control={control}
                    row
                    
                    value={state.userType} 
                    onChange={handleChange('userType')}
                     />
                 </div> 
                 {/* <span>Account Owner</span> */}
                 <Row>
                    <Col lg="2" style={{padding:"0px"}}><h3>Status</h3></Col>
                    <Col lg="10">
                    <div className="form-group">            
                  <label className="custom-toggle custom-toggle-success">
                    <input defaultChecked checked={state.status} type="checkbox" ref={register} name="status" onChange={handleChange('status')}/>
                    <span className="custom-toggle-slider rounded-circle" data-label-off="Inactive" data-label-on="Active"></span>
                  </label>
                </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

{ addOrEdit=='EDIT' && <div className="row">
  
  <div className="col-lg-6">
  {/* <h4 >Company Legal Name *</h4> */}

  <FormInput 
                         id="empid"
                         type="text"
                         name="empid"
                         label="Employee ID *"
                         onChange={handleChange('empid')}
                        //  register={register}
                         defaultValue={state.empId}
                         value={watchAll.empid}
                        //  error={errors.empid}
                         disabled={true}
                    />
  {/* <FormInput className="comp-name mt-4" id="outlined-search" label="Employee ID *" type="text" variant="outlined" /> */}
  </div>

  <div className="col-lg-6">
  {/* <h4 >Company Legal Name *</h4> */}
  <FormInput 
                         id="hscontact"
                         type="text"
                         name="hscontact"
                         label="HS Contact ID *"
                         onChange={handleChange('hscontact')}
                        //  register={register}
                         defaultValue={state.HSContact}
                         value={watchAll.hscontact}
                        //  error={errors.hscontact}
                         disabled={true}
                    />
  {/* <TextField className="comp-name mt-4" id="outlined-search" label="HS Contact ID *" type="text" variant="outlined" /> */}
  </div>
</div>}

<Row>
<div className="col-lg-6 mt-4">
            <div className="form-group">        
                    <FormInput 
                        id="firstName"
                        type="text"
                        name="firstName"
                        label="First Name *"
                        //placeholder="Password"
                        register={register}
                        defaultValue={state.firstName}
                        error={errors.firstName}
                        value={watchAll.firstName}
                        onChange={handleChange('firstName')}
                    />            
            </div>
            </div>


            <div className="col-lg-6 mt-4">
            <div className="form-group">        
                    <FormInput 
                        id="lastName"
                        type="text"
                        name="lastName"
                        label="Last Name *"
                        //placeholder="Password"
                        register={register}
                        error={errors.lastName}
                        value={watchAll.lastName}
                        defaultValue={state.lastName}
                        onChange={handleChange('lastName')}
                    />            
            </div>
            </div>        
</Row>

<Row>
        <div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="email" 
            type="text" 
            label="Contact Email *" 
            name="email" 
            control={control}
            register={register}
            error={errors.email ? errors.email : checkMail ? {message:"Email Id already taken", required: true} : ''}
            defaultValue={state.email}
            value={watchAll.email}
            onChange={handleChange('email')}
         />
        </div>
        </div>
        <div className="col-lg-6">
        <div className="form-group">               
        {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="country" name="country" ref={register} value={state.country?state.country:''} />  */}
        <FormAutocomplete
                        id="country"
                        name="country"
                        label="Country *"
                        options={state.countryList}
                        placeholder="Select Country"
                        // multiple
                        disableCloseOnSelect
                        blurOnSelect
                        limitTags={1}
                        control={control}                         
                        input='country'
                        value={ state.country }
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Please Select Country'}
                        error={errors.country }
                        defaultValue={state.country}
                        />
</div>
        </div>
</Row>

<Row>
        <div className="col-lg-6">
        <div className="form-group">
        <h3>Phone No</h3>   
        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="phone" name="phone" ref={register} value={state.phone[0]} /> 
        <PhoneInput2
                id="phone"
                label="Phone No"
                name="phone"
                placeholder={'Phone No'}
                mobile={state.phone[0]}
                error={errors.phone}
                register={register}
                control={control}
                // onFocus={(value, country, e, formatted) => setFocused(true)}
                country_code={state.countryCodemob1?state.countryCodemob1:''}
                handleChangeSelect={handleChangeSelect}
                defaultErrorMessage={(!errors.phone || state.phone[2].length >= 4) ? '' : 'Please Enter Valid No.'}
                isValid={valid1}
             />

        {/* <FormInput
            id="phone" 
            type="text" 
            label="Contact Phone Number" 
            name="phone" 
            //control={control}
            register={register}
            error={errors.phone}
            defaultValue={state.phone}
            value={watchAll.phone}
            onChange={handleChange('phone')}
         /> */}
        </div>
        </div>
        <div className="col-lg-6">
        <div className="form-group">
        <h3>Mobile No *</h3>   
        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="mobile" name="mobile" ref={register} value={state.mobileNo[0]} /> 
        <PhoneInput2
                id="switchPhone1"
                label="Mobile No"
                name="mobileNo"
                placeholder={'Mobile No *'}
                specialLabel="Mobile No"
                mobile={state.mobileNo[0]}
                error={errors.mobile}
                register={register}
                control={control}
                // onFocus={(value, country, e, formatted) => setFocused(true)}
                country_code={state.countryCodemob?state.countryCodemob:''}
                handleChangeSelect={handleChangeSelect}
                defaultErrorMessage={(!errors.mobile || state.mobileNo[2].length >= 4) ? '' : 'Please Enter Valid No.'}
                isValid={valid}
             />

        {/* <FormInput
            id="mobile" 
            type="text" 
            label="Mobile Number *" 
            name="mobile" 
            //control={control}
            register={register}
            error={errors.mobile}
            defaultValue={state.mobileNo}
            value={watchAll.mobile}
            onChange={handleChange('mobileNo')}
         /> */}
        </div>
        </div>
</Row>

<Row>
        <div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="jobposition" 
            type="text" 
            label="Job Position *" 
            name="jobposition" 
            control={control}
            register={register}
            error={errors.jobposition}
            defaultValue={state.jobTitle}
            value={watchAll.jobposition}
            onChange={handleChange('jobTitle')}
         />
        </div>
        </div>
        <div className="col-lg-6">
        <div className="form-group">
        <FormAutocomplete
            id="reportingTo" 
            type="text" 
            label="Reporting To" 
            name="reportingTo" 
            options={state.reportingList}            
            disableCloseOnSelect
            blurOnSelect
            limitTags={1}
            control={control}                         
            input='reportingTo'
            value={ state.reportingTo }
            handleChangeSelect={handleChangeSelect}
            errMessage={'Please Select Reporting Id'}
            error={errors.reportingTo }
            defaultValue={state.reportingTo}
            />
        
        </div>
        </div>
</Row>
<Row>
<div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="LinkedinURL" 
            type="text" 
            label="Linkedin URL" 
            name="LinkedinURL" 
            //control={control}
            register={register}
            error={errors.LinkedinURL}
            defaultValue={state.linkedinurl}
            value={watchAll.LinkedinURL}
            onChange={handleChange('LinkedinURL')}
         />
        </div>
        </div>


<div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="TwitterURL" 
            type="text" 
            label="Twitter URL" 
            name="TwitterURL" 
            //control={control}
            register={register}
            error={errors.TwitterURL}
            defaultValue={state.twitterurl}
            value={watchAll.TwitterURL}
            onChange={handleChange('TwitterURL')}
         />
        </div>
        </div>
</Row>
   

<Col lg="6"> 
                  <h3 style={{textAlign:'left'}}> Visibility <span id='algorithm1'><i class="far fa-handshake didy"></i></span>
                   <UncontrolledTooltip className="handshak" delay={0}  placement="bottom" target="algorithm1">This field is included in the matching algorithm</UncontrolledTooltip></h3>
                  <div className="form-group">        
                  <FormRadio
                  name="Visibility"
                  className="text-sm mb-0"
                  options={radioGroupOptions3}
                  control={control}
                  row
                  value={state.isShared} 
                  onChange={handleChange('isShared')}
                     />
                 </div>   
                  </Col>       
           



<div className="text-center mt-4">
            {/* <button type="submit" className="btn btn-primary" >Back</button> */}
            <button type="submit" className="btn btn-primary" >Next</button> 
            </div>
</CardBody>
           </Card>

          </form>
          </Container>
     )}
      </div>
    
 
  );
};



const radioGroupOptions = [
  { value: "3", label: "Primary"},
  { value: "2", label: "Admin "},
  ];

  const radioGroupOptions3 = [
    {value: "1", label: "Shared"},
    {value: "0",label: "Confidential"}
  ];

function mapState(state) {    
    const { general_data } = state.adduser;
    return { general_data };
}

const actionCreators = {
  adduser: adduserActions.adduser
};

// const connectedAddgeneralForm = connect(mapState,actionCreators)(AddgeneralForm);
// export { connectedAddgeneralForm as AddgeneralForm };

 
export default connect(mapState,actionCreators)(AddgeneralForm); //AddgeneralForm;