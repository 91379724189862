
import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody, Card, Nav, NavItem, NavLink } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader"
import Title from "views/Hoc/Dialog/Title";
import Product from "../Channel-Listing/Product-List";
import FavChannelListing from "../Channel-Listing/fav-channel-listing";
import ChannelGroup from "views/Hoc/ChannelGroup";
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
const Favourites = (props) => {
  let [isLoading, setLoading] = useState(true);
  const [isChannel, setChannel] = useState(false);
  const [channelGroupId, setChannelGroupId] = useState(null);
  let [tabCount, setTabCount] = useState({ channel: 0, product: 0, total: 0 });
  let [channelGroupList, setChannelGroupList] = useState([]);
  var userData = JSON.parse(localStorage.getItem('CHuser'));
  const [tabs, setTab] = useState(userData?.channelTypeId === '2' ? 2 : 1);
  // -------------------dynamic contents for notification-------------------
// const[notifications,setNotifications]=useState([
//   {title:'Profile',body:'lorel ipsum.....',id:1},
//   {title:'incoming leads',body:'lorel ipsum.....',id:2},
//   {title:'favourites',body:'lorel ipsum.....',id:3}
//   ]);
  
//   const handleDelete=(id)=>{
//     const newNotifications= notifications.filter(notification=>notification.id!==id);
//     setNotifications(newNotifications);
//   }
  
  // -------------------dynamic contents for notification-------------------
  useEffect(() => {

    (async () => {
      let tot = await getCount();
      if (userData?.channelTypeId === '2') {
        //let ch = await getChannelCount();        
        getChannelGroup();
      } else {
        let ch = await getChannelCount();
        console.log('tot', tot);
        console.log('ch', ch);
        let productCount = parseInt(tot) - parseInt(ch);
        if (productCount === 0 && parseInt(ch) !== 0)
          getChannelGroupList()
      }
    })();
    setLoading(false);
  }, [])
  const getCount = () => {
    return new Promise((resolve, reject) => {
      commonService.getServices('/favorite/count').then(data => {
        if (data) {
          tabCount.total = data.data.count;
          setTabCount({ ...tabCount });
          resolve(data.data.count)
        } else
          reject(false);
      })
    });
  }
  const getChannelCount = () => {
    return new Promise(async (resolve, reject) => {
      let data = await commonService.getServices('/favorite/channelcount');
      if (data) {
        tabCount.channel = data.data.count;
        setTabCount({ ...tabCount });
        resolve(data.data.count)
      } else
        reject(false);
    });
  }
  const setProductCount = async (count) => {
    tabCount.product = count;
    setTabCount({ ...tabCount });
  }
  const getChannelGroup = async () => {
    setLoading(true);
    let data = await commonService.getServices('/favorite/group');
    if (data) {
      channelGroupList = data.data.result;
      tabCount.channel = +data.data.gtotal;
      setTabCount({ ...tabCount });
      setChannelGroupList(channelGroupList);
    }
    setLoading(false);
  }
  const getChannelGroupList = () => {
    setTab(2);
    getChannelGroup();
  }
  const openFavChannelGroup = () => {
    setChannel(true);
  }
  const openChannelGroup = () => {
    console.log('sdsds')
    setChannel(false);
    getChannelGroupList();
  }
  const getProductList = () => {
    setTab(1);
    setChannel(false);
  }
  const getChannelGroupId = (groupId) => {
    setChannelGroupId(groupId);
    setChannel(true);
  }
  return (
    <>
      <SimpleHeader location={props.match} />
      <div id='Favourites' className="main-content">
        <Title title="Favourites" desc={["List of your various favourites groups"]} />
        <Container fluid className="mt-4">
        <Nav tabs className="list-tab">


          {/* <div className="col-xs-12">
            <h2>NOTIFICATIONS</h2>
         
          <div id="example-collapse-text" className="col-xs-12 noti-content" >
   <div className="col-xs-12 notilist">
    
      {notifications.map((notification)=>(
    <div className="content">

    <div class="col-xs-12 " key={notification.id}><i class="ni ni-fat-remove"  onClick={()=>handleDelete(notification.id)}></i>
    <h6>{notification.title}</h6>
    <p>{notification.body}</p></div>
    </div>))}
   </div>
   </div>
   </div> */}
   {/* <div className="col-xs-12"> */}
          {/* <Nav tabs className="list-tab"  style={{float: 'left',
    width: '100%'}}> */}
            <NavItem className="list-main">
              <NavLink className="active">
                <h4>FAVOURITES</h4>
                <Row className="text-uppercase">
                  {userData?.channelTypeId !== '2' && <Col onClick={getProductList} xs="6" className="mt-2 sub-a">
                    <a style={{ cursor: 'pointer' }}>
                      <h3 className="mb-0">PRODUCTS</h3>
                      <p class="count m-0"> {tabCount.product} </p>
                    </a>
                  </Col>}
                  <Col onClick={getChannelGroupList} xs="6" className="icon-tag2 mt-2 sub-a">
                    <a style={{ cursor: 'pointer' }} >
                      <h3 className="mb-0">COMPANIES</h3>
                      <p class="count m-0">{tabCount.channel}</p>
                    </a>
                  </Col>
                </Row>
              </NavLink>
            </NavItem>
          </Nav>
          {/* </div> */}
          {/* <div className="col-xs-12">
        
          <Card style={{float:'left'}}> */}
          <Card>
            <CardBody className='favouritesList'>
              <Row className="pt-4">
                {isLoading ? <Spinner /> :
                  <Col style={{ backgroundColor: "#f8f9fe" }} lg="12">
                    {tabs === 1 ? <Product activeTab='100' setProductCount={setProductCount} from='favourites' /> : isChannel ? <FavChannelListing groupId={channelGroupId} callTabCount={getChannelCount} openFavChannelGroup={openChannelGroup} /> : <ChannelGroup data={channelGroupList} getChannelGroupId={getChannelGroupId} openFavChannelGroup={openFavChannelGroup} />}
                    {/* {tabs===2 &&  } */}
                  </Col>}
              </Row>
            </CardBody>
          </Card>
          {/* </div> */}
        </Container>
      </div>
    </>
  );
}

export default Favourites;