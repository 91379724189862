import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from "react-hook-form";
import { commonService } from '_services/common.service';
import { ListGroupItem, ListGroup } from 'reactstrap';
import { FormAutocompleteGrouping, FormAutocomplete } from "_components/FormElements/FormInput";

const EventListingFilter = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));
  const { control } = useForm({ mode: 'onBlur' });
  const style = { height: '18rem' };
  const [filterOptionState, setFilterOption] = useState({ PositionOption: [{ name: 'MID', id: '1' }, { name: 'HIGH', id: '2' }], Brand_Option: [], Profile_Option: [], Location_Option: [], Family_Option: [], Category_Option: [], Current_Product_Option: [], Company_Origin_Option: [], channelTypeOption: [] });

  const { activeTab, handleChangeSelect, filterValueState, resetFilterValue, flag, parent, setFilterValue, setChannelFlag, eventId,
            setFlag, setParent } = props;

  const classes = useStyles();

  const userData = JSON.parse(localStorage.getItem('CHuser'));

  useEffect(() => {
      getSearchAggregation();
  }, [])

  const getFilterData = () => {
    let filter = {
      specialityId: null,
      brandId: null,
      countryId: null,
      productId: null,
      intProductId: null,
      locationId: null,
      retailerprofileId: null,
      resellerprofileId: null,
      productCategoryId: null,
      turnOver: null,
      connectionStatusId: null,
      typeId: null,
      storeCount: null,
      eventId: eventId
    };
    let flag = 0;

    if (filterValueState.Current_Product && filterValueState.Current_Product.length > 0 && parent == 'current_product') {
      filter.productCategoryId = filterValueState.Current_Product.map(x => parseInt(x.childid));
      flag=1;
    }


    if (filterValueState.Company_Origin && filterValueState.Company_Origin.length > 0 && parent == 'Company_Origin') {
      filter.countryId = filterValueState.Company_Origin.map(x => parseInt(x.id));
      flag = 1;
    }

    if (filterValueState.typeId && filterValueState.typeId.length && parent == 'typeId') {
      filter.typeId = filterValueState.typeId.map(x => parseInt(x.id));
      flag = 1;
    }
    if (flag == 0) {
      resetFilterValue();
      setChannelFlag(true);
    }
    return filter;
  }

  useEffect(() => {
    async function fetchData() {

      let postData = {
        filter: getFilterData()
      }
      // if (filterValueState.typeId) {
      //console.log("rajtypeid:", postData.filter);
      let typeId = 0;
      let channels =  await commonService.postService(postData, '/channel/chFilter/' + 501 + "/" + typeId);;

      if (channels.data.length > 0) {
        let data = channels.data;
        //filterOptionState.SpecialityOption= options.specialIds;
        

        if (parent != 'current_product') {
          filterOptionState.Current_Product_Option = await commonService.filterPostService({
            data
          }, '/channel/productFilter');
          let Ids = filterOptionState.Current_Product_Option.map(x => parseInt(x.childid));
          filterValueState.Current_Product = filterValueState.Current_Product.filter(item => Ids.includes(parseInt(item.childid)));
          console.log('flagids', Ids, filterValueState)
          //setFilterOption({ ...filterOptionState });
        }

        

        
        if (parent != 'Company_Origin') {
          filterOptionState.Company_Origin_Option = await commonService.filterPostService({
            data
          }, '/channel/countryFilter');
          let Ids = filterOptionState.Company_Origin_Option.map(x => parseInt(x.id));
          filterValueState.Company_Origin = filterValueState.Company_Origin.filter(item => Ids.includes(parseInt(item.id)));
          //setFilterOption({ ...filterOptionState });
        }

        if (parent != 'typeId') {
          filterOptionState.channelTypeOption = await commonService.filterGetService('/channel/channeltype');
          let Ids = filterValueState.typeId.map(x => parseInt(x.id));
          filterValueState.typeId = filterValueState.typeId.filter(item => Ids.includes(item.id));
        }

        setChannelFlag(true);
        setFilterOption({ ...filterOptionState });
        setFilterValue({ ...filterValueState })
      }
      else
        setChannelFlag(true);

      // if(parent != 'typeId')
      //   filterOptionState.channelTypeOption = await commonService.filterGetService('/channel/channeltype');
      // setFilterOption({ ...filterOptionState });
    }
    if (flag != 0)
      fetchData();
  }, [flag])

  console.log('filtervaluesstate123', filterValueState);

  const getSearchAggregation = async () => {
    resetFilterValue();
    resetFilterOptions();
    let postData = {
      filter: getFilterData()
    }
    let typeId = 0;
    let channels =  await commonService.postService(postData, '/channel/chFilter/' + 501 + "/" + typeId);;

    if (channels.data.length > 0) {
      let data = channels.data;
      //filterOptionState.SpecialityOption= options.specialIds;

      filterOptionState.Current_Product_Option = await commonService.filterPostService({
        data
      }, '/channel/productFilter');
      setFilterOption({ ...filterOptionState });

      filterOptionState.Company_Origin_Option = await commonService.filterPostService({
        data
      }, '/channel/countryFilter');

      setFilterOption({ ...filterOptionState });
    }

    filterOptionState.channelTypeOption = await commonService.filterGetService('/channel/channeltype');
    setFilterOption({ ...filterOptionState });



  }

  const resetFilterOptions = () => {

    filterOptionState.Current_Product_Option = [];

    filterOptionState.Company_Origin_Option = [];

    filterOptionState.channelTypeOption = channelTypeList.filter(x => x.id !== userData.channelTypeId);
    setFilterOption({ ...filterOptionState });
  }

  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    //console.log("check:",check,gname);
    if (check == true) {
      filterOptionState[optionName].map((item) => {

        if (item.groupname == gname || item.status == true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check == false) {
      console.log("check:", check);
      filterOptionState[optionName].map((item) => {
        if (item.groupname == gname && filterValueState[optionValue].length > 0)
          item.status = check;
        // if(state.productValue.length==0)
        //     item.status=false;
      });

      opt = filterValueState[optionValue].filter((item) => item.groupname != gname);
      opt.map(option => option.status = true);
    }

    filterValueState[optionValue] = opt;
    setFilterValue({ ...filterValueState, [optionValue]: filterValueState[optionValue] });
    setFilterOption({ ...filterOptionState, [optionName]: filterOptionState[optionName] });
    setFlag(1 + Math.random() * (100 - 1));
    
    setParent('current_product');
    setChannelFlag(false);

    // if(optionValue='ChannelProfileValue' && state.ChannelProfileValue.length==0)
    //       errors.relevantChannelProfiles=true;
    // else 
    // if (optionValue = 'productValue' && state.productValue.length == 0)
    //   errors.productCat = true;
  }

    return (
<div className='fav-ser1 p-2'>
      <div className={classes.root}>
    {props.type=='vendor'  &&   <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Type</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                    <FormAutocomplete
                        id="channel_type"
                        name="channel_type"
                        label=""
                        options={filterOptionState.channelTypeOption}
                        placeholder="Type Channel Type"
                        multiple  
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        open={true}
                        input='channel_type'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.typeId}
                        handleChangeSelect={handleChangeSelect}
                        popupIcon=''
                        disablePortal={true}
                        ListboxProps={{ style: { maxHeight: '16rem' } }}
                        size='small'
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography >Country</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem>
                <div className=" text-center mt-2">
                    <div className="form-group">
                    <FormAutocomplete
                        id="Company_Origin"
                        name="Company_Origin"
                        label=""
                        options={filterOptionState.Company_Origin_Option}
                        placeholder="Type Company Origin"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='Company_Origin'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.Company_Origin}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '16rem' } }}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography >Product Category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem >
                <div className=" text-center mt-2">
                    <div className="form-group">
                    <FormAutocompleteGrouping
                        id="current_product"
                        name="current_product"
                        multiple={true}
                        //label="Product Families are you selling *"
                        Options={filterOptionState.Current_Product_Option}
                        placeholder="Select Product Families"
                        input='current_product'
                        //errMessage={'Please Select a Product Family'}
                        //error={!errors.productCat || productFamilies.length ?false:true}
                        defaultValue={filterValueState.Current_Product}
                        value={filterValueState.Current_Product}

                        optionName='Current_Product_Option'
                        optionValue='Current_Product'
                        selectedItem={selectedItem}

                        //getOptionSelected={(options, value) => options.name === value.name}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        control={control}
                      //register={register}

                      />
                    </div>
                  </div> 
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>


      </div>
    </div>
    );
};

export default EventListingFilter;

const channelTypeList = [
  {
    id: '2',
    type: 'Vendor',
    name: "VENDOR"
  },
  {
    id: '3',
    type: 'Distributor',
    name: "DISTRIBUTOR"
  },
  {
    id: '4',
    type: 'Retailer',
    name: "RETAILER"
  },
  {
    id: '5',
    type: 'Reseller',
    name: "RESELLER"
  }
];
