import React, { useState, useEffect } from 'react';
import {Card,CardBody,Row,Col,Container,NavItem,NavLink,Nav,TabContent,TabPane} from "reactstrap";
import { commonService } from '_services/common.service';
import classnames from "classnames";
import Title from "views/Hoc/Dialog/Title";
import BillingAddress from './BillingAddress';
import ShippingAddress from './ShippingAddress';
import OrderSummary from './OrderSummary';
import SimpleHeader from "components/Headers/SimpleHeader"

const CheckoutTab = (props) => {
    let [loading, setLoading]  = useState(true);
    let [productDetails, setChannelDetails ] = useState(null);
    let [productDetailsInfo, setChannelDetailsInfo] = useState([]);
    const [connect, setconnect] = useState(false);
    const [tabs, toggleNav] = useState("1");
    const [active, setState] = useState(0);
    const [prod_show, more] = useState(false);
    const [billingFlag,setBillingFlag] = useState();
    const [shippingFlag,setShippingFlag] = useState();
    const [checkout, setCheckout] = useState({
      shippingAddressId: '',
      billingAddressId: '',
    })

    const [billingAddress, setBillingAddress] = useState({});

    const selectBilling = (input) => {
      delete input.addressid;
      setBillingAddress(input);
    }

const [viewAll, setViewall] = useState(false)
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      vertical: true,
      verticalSwiping: true,
      responsive:[
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        }
      ]   
    };


    useEffect(() => {
   
    }, []);

   const setData = (data) => {
      setCheckout({...checkout, ...data});
   }
   
    const clickKeyRetailer=()=>{
      console.log('clickKeyRetailer');
    }
const viewmore=()=>{
if (viewAll) {
setViewall(false);
} else {
setViewall(true);
}
}
const opneConnect=(val)=>{
if (connect) {
  setconnect(false);
} else {
  setconnect(true);
}
}
  const toggleNavs=(e,index)=>{
      e.preventDefault();
      if(index==1)
        setBillingFlag(Math.random());
      else if(index==2)
        setShippingFlag(Math.random());
      if(index==3){
        if(checkout.billingAddressId && checkout.shippingAddressId)
          toggleNav(index);
      }
      else
      toggleNav(index);
  }

  const toggleNavs1=(index)=>{
    toggleNav(index);
  }

  const type = props.location ? props.location.type:2;
  const cart = props.location.state.cart;

  console.log('cartDetails',cart);
  const addOrEdit = props.location ? props.location.addOrEdit:'ADD';
  const contactId = props.location ? props.location.contactId:'0';
        return (
        <>
          <SimpleHeader location={props.match}/>
           <Title id="checkout" title="Checkout" desc={[""]} />   
            <Container className="mt-2 col-lg-12 col-md-9 col-sm-12   container-fluid" fluid>
           <Row>
          <Col lg="12" className="pad-less">
          <div style={{textAlign:"-webkit-center"}}>
          <div className="nav-wrapper" style={{width:"100%"}}>
          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={tabs === "1"}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: tabs === "1"
                })}
                onClick={e=>toggleNavs(e,"1")}
                href="#pablo"
                role="tab"
              >
                <i class="ni ni-single-copy-04 tat mr-2"/>
                BillingAddress
              </NavLink>
            </NavItem>
            
            <NavItem>
              <NavLink
                aria-selected={tabs === "2"}
                className={classnames("mb-sm-3 mb-md-0 mr-2", {
                  active: tabs === "2"
                })}
                onClick={e=>toggleNavs(e,"2")}
                href="#pablo"
                role="tab"
              >
                <i class="ni ni-square-pin tat"/>
                ShippingAddress
              </NavLink>
            </NavItem>
           
         
          <NavItem>
          <NavLink
            aria-selected={tabs === "3"}
            className={classnames("mb-sm-3 mb-md-0 mr-2", {
              active: tabs === "3"
            })}
            onClick={e=>toggleNavs(e,"3")}
            href="#pablo"
            role="tab"
          >
            <i class="fas fa-info tat mr-2"/>
            OrderSummary
          </NavLink>
        </NavItem>
      
  
    

          </Nav>
        </div>
        </div>
        <Card>
          <CardBody>
            <TabContent activeTab={ tabs}>
              <TabPane tabId="1">
              <BillingAddress addFlag={billingFlag} setData={setData} selectBilling={selectBilling}/>
              </TabPane>
              <TabPane tabId="2">
               <ShippingAddress addFlag={shippingFlag} setData={setData} selectedBilling={billingAddress}/>
              </TabPane>
              <TabPane tabId="3">
              <OrderSummary cart={cart} checkout={checkout}/>  
              </TabPane>
              {/* <TabPane tabId="4">
               
              </TabPane>
              <TabPane tabId="5">
                
              </TabPane> */}
            </TabContent>               
          </CardBody>
        </Card>


        
         </Col>
         </Row>
              </Container>
        
            </>
         );
    }


export default CheckoutTab;