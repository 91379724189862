import React, { useEffect, useState, useMemo } from "react";
import { Card,CardBody,CardImg,CardTitle, Container,Col,Row,CardText,Button } from "reactstrap";
import { commonService } from '_services/common.service';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { Link } from "react-router-dom";
import {history} from "_helpers/history";

const GuestprodSpec = (props) => {

   
   
return (
        <div className="main-content" id="Guestevent">
<div className="container-fluid ">
<div className="mb-4">  
<h2 style={{fontSize:"30px",lineHeight:"40px"}} class="product-detail-subtitle">Product specifications</h2></div>  
<div id="products_create_account" className="borderfull1">
            <h4 class="product-detail-subtitle">Product specifications, prices: create an account or login to view the details of this product</h4>
            <span className="prodspecspan">By logging in, you will be able to view complete information about this product (product specifications, prices and minimum quantities, Conditions and delivery details).</span>
            <div className="mt-4">
            <div className="text-center row justify-content-center">
            <Button color="primary" type="button" onClick={() => history.push('/login')} >Login</Button>
            <Button className='pub-prod' color="primary" type="button" onClick={() => history.push('/signup')}>Create an Account</Button>
            {/* <a class="nav-item nav-link" href="/Login"><i class="fas fa-sign-in-alt mr-3" style={{color:"#2c9db7"}}></i>Login</a> */}
             {/* <span><a class="nav-item nav-link" href=""><i class="fas fa-sign-in-alt mr-3" style={{color:"#2c9db7"}}></i>Create an account</a></span>    */}
            </div></div> 
        </div>
      </div>
      </div>
    );
};



export default GuestprodSpec;