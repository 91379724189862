import React, { useState, useEffect } from 'react';
import Row from 'reactstrap/lib/Row';
import { FormInput, FormButton } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotSchema } from '../../pages/SignupPage/Validation';
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { history } from '../../../_helpers/history';
const ForgetPassword = (props) => {
  const { visible } = props;
  let [mes, setMes] = useState(null);
  let [email_error, setEmail_error] = useState(null);
  let [email, setEmail] = useState(null);
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(forgotSchema),
  });
  useEffect(() => {
    console.log('ForgetPassword', visible);
    setEmail(null);
    setEmail_error(null);
    setMes(null);
  }, [visible])
  const forgotOnSubmit = async (data) => {
    //console.log("forgotOnSubmit:",data);
    let res = await commonService.postService({ email: data.forgotemail }, 'account/forgotPassword').catch(err => setEmail_error('Entered mail id is incorrect.'));

    if (res) {
      // await commonService.postService({type: 'forgot', data: {email: data.forgotemail}}, '/emailer/');
      //setEmail(null);
      setMes(res.data.message);
      commonService.postService(res.data, 'mailerservice/Reset_Password_Mail').catch(err => setEmail_error('Entered Mail id is incorrect.'));
      //history.push('resetpassword/'+res.data.code);
    }
  }
  return (
    <section className="forgotpwd">
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <div className="container ">
        <div className="text-center mt-8">
          <i style={{ fontSize: '48px' }} class="fas fa-lock-open"></i>
        </div>
        <h1 className="text-center mt-4">Forgot Password</h1>
        <div className="text-center" >
          <strong ><h2 className="text-warning">Can't Sign in to your account, Forgot your password?</h2></strong>
          <p>We will send you reset password instructions to your registered email address, please enter your email</p></div>
        {!mes && <form>
          <Row className="">
            <div className="col-sm-4"></div>
            <div className="col-sm-4">
              <div class="form-group mb-3 mt-3" >
                <div class="input-group input-group-merge input-group-alternative">
                  <FormInput
                    id="forgotemail"
                    type="forgotemail"
                    name="forgotemail"
                    label="Enter Registered Email ID *"
                    register={register}
                    value={email}
                    error={errors.forgotemail ? errors.forgotemail : email_error ? { message: email_error, required: true } : ''}
                  // errMessage={email_error?email_error:null}
                  />
                </div>
                {/* {email_error&&<p>{email_error}</p>} */}
              </div>
            </div>

            <div className="col-sm-4"></div>
          </Row>

          <div className="text-center">
            <FormButton className="btn-sin btn btn-primary" onClick={handleSubmit(forgotOnSubmit)} id="Resetbutton" name="Resetbutton" value="Reset Password" />
          </div>
        </form>}
        {mes && <p style={{ color: 'green' }}>{mes}</p>}
        <div className="text-center mt-4">
          <p>Still you can't sign-in or you're experiencing any other issues? <strong className="text-warning pointer" onClick={() => history.push('HelpSupport')} >Contact Support</strong></p>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;