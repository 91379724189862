import React, { useState } from "react";
import '../User-Tour/Usertour.css'
import { Tourstep } from "./Tourstep";

function UserTour({ closeModal,tourData }) {
  const [currentStep, setCurrentStep] = useState(0);
  function nextStep() {
    setCurrentStep(prev=>prev + 1);
  }
  function previousStep(){
    setCurrentStep(currentStep - 1);
  }
  return (
    <div classNameName="tour-modal">
      {currentStep === 0 && (
        
        <Tourstep closeModal={closeModal} tourData={tourData}/>
      )}
     
    </div>
  );
}
export default UserTour;
