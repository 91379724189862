
import React,{useState,useEffect} from 'react';
import { Row, Col, Card, Button,Container } from "reactstrap";
import { commonService } from '_services/common.service';
import GuestNavBar from "./GuestNavbar/GuestNavbar";
import CardBody from 'reactstrap/lib/CardBody';
import GuestFooter from "./GuestNavbar/GuestFooter";
import GuestContact from './GuestContact'
import Newvendash from 'views/pages/dashboards/Newvendash'

const Newslist = () => {
        const [state, setState] = useState({
            news: []
        });
    
    useEffect(()=>{
        getNewsdata();
    },[]);
    const getNewsdata= async ()=>{
        let news = await commonService.getServices('/publicChannel/news').catch(err => console.log('err', err));
    
        if (news) {
          //console.log("news:", news);
          state.news = news.data.items;
          setState({ ...state });
        }
    }
    return (
        <div>
             <GuestNavBar/>
             
    <div class="news-bg">
    <img src="https://static.wixstatic.com/media/4dfc24ea66304932aaacfa4d6db7b069.jpg/v1/fill/w_1903,h_337,al_c,q_85,usm_0.66_1.00_0.01/4dfc24ea66304932aaacfa4d6db7b069.webp" alt="Videogame Console" />
    <div class="news-blue">
        <img src="https://static.wixstatic.com/media/720901_827ed73597664e619a9734035f0b4b02~mv2.png/v1/fill/w_459,h_191,al_c,q_85,usm_0.66_1.00_0.01/RC_element_blue.webp" alt=""/>
        <h6>News</h6>
    </div>
    </div>
    <Row>
    <div class="ch-announce">
        <Container>
        <h5>
        Channel Summit Announcements
        </h5>
        <div class="ch-details">
<span>
17th November 2021</span>
<a href="https://www.channel-summit.com/_files/ugd/720901_1c6cc747667d4f13a34702225d21f447.pdf" target="_blank">Top 3 in-demand product categories announced ahead of Channel Summit EMEA 2022</a>
        </div>
        <div class="ch-details">
<span>
26th November 2021</span>
<a href="https://www.channel-summit.com/_files/ugd/720901_e10a17c23b844f0f92b7e4ea2918789c.pdf" target="_blank">Inaugural Channel Summit EMEA event announced for Q1 2022</a>
        </div>
        <div class="ch-details">
<span>24th January 2022
17th November 2021</span>
<a href="https://www.channel-summit.com/_files/ugd/720901_3548056da2e54e9492df6bb18f1afe44.pdf" target="_blank">Channel Summit EMEA event rescheduled for 19-21 May 2022</a>
        </div>
        </Container>
    </div>
    </Row>
            <Card className='mt-4'>
            <CardBody>
                <h1>Market & Channel News</h1>
            <Container>
            <Row className='pb-4'>
            {state.news.map(x =>
                <Col lg='3' className='pb-4'>
                <a href={`https://www.channel-summit.com/post/${x.slug}`} target='_blank'>
                <Card className='m-0 shade' >
                <img alt="..." src={"https://static.wixstatic.com/media/" + x.coverImage.split("/")[3]} width={292} height={219} class="card-img-top"/>
                <div class="justify-content-center">
                <div class="card-profile-image">

                </div>

                <div class="text-center border-0 pt-8 pt-md-3 pb-0 pb-md-3 card-header">
                    <div class="d-flex justify-content-start">
                        <div>
                    <a href={`https://www.channel-summit.com/post/${x.slug}`} target='_blank'>
                        <img alt="..." class="rounded-circle" src={x.author.profilePhoto} width={40} height={40}/>
                    </a>
                    </div>
                    <div className='text-left'>
                        <p className='mb-0 pl-3' style={{fontSize:'0.9rem'}}>{x.author.nickname}</p>
                        <p  className='mb-0 pl-3' style={{fontSize:'0.7rem'}}>{new Date(x.publishedDate).toString().substring(3, 15) + " - " + x.timeToRead + "min"}</p>
                    </div>
                    </div>
                </div>

                <div class="pt-0 card-body chge-hov">
                             <h2 className='Nwsovr text-left'>{x.title+" by "+x.author.nickname}</h2>
                    <div class="text-center">
                         <p className='Nwsovrp text-left'>{x.plainContent}</p>
                    </div>
                    <hr className='mb-2'/>
                    {x.likeCount==0 ?
                    <i class="far fa-heart" style={{color:'orange'}}></i> :
                    <span>{x.likeCount} <i class="fas fa-heart" style={{color:'orange'}}></i></span>}
                </div>
                </div>
                </Card>
                </a>
                </Col>)}              
            </Row>
            </Container>
            </CardBody>
            </Card>
            <GuestContact />
            <GuestFooter />
            {/* <Newvendash/> */}
        </div>
    );
};

export default Newslist;