import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import {
  Button, Modal
} from "reactstrap";

  const ProfileSetupaccess=(props)=> {
//   const {isOpen, handleClose, gotoWallet, subscriptionTurn, value } = props;
  const [open, Setopen] = React.useState(true)

const closeOpen=(e)=>{
  e.preventDefault();
 Setopen(false);
}

  return (
    <div>
      <Modal size="xs" className="modal-dialog-centered" isOpen={open}>
          
          <div className="modal-header">
           <strong>Warning</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={ closeOpen}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body text-center">
          <h3>"Complete the profile setup to access these features"</h3> 
          </div>
          <div className="modal-footer text-center">
          {/* <Button color="primary" type="button" onClick={subscriptionTurn}>Upgrade</Button> */}
          <Button color="primary" data-dismiss="modal" type="button" onClick={closeOpen}>OK</Button>
          </div>
        </Modal>
    </div>
  );
}
export default  ProfileSetupaccess;