import React, { useState, useEffect } from "react";
import { ListItem } from "_components/Listing/ListItem";
import { Card, CardBody, Row, Col } from "reactstrap";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import CountryFlag from "views/Hoc/countryFlag/countryFlag";
import { history } from '../../../_helpers/history';
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Badge, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";

export const PublicChannelListing = (props) => {
  const { type, eventId, filterValueState } = props;
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [total, setTotal] = useState(0);
  let [isConfidential, setConfidential] = useState(0);
  let [isLoading, setLoading] = useState(true);
  let [data, setData] = useState([]);
  useEffect(() => {
    console.log('type', type);
    if (type)
      callChannelList();
  }, []);

  const getFilterData = () => {
    let filter = {
      productId: null,
      countryId: null,
      typeId: null,
    };

    if (filterValueState.categories && filterValueState.categories.length > 0) {
      filter.productId = filterValueState.categories.map(x => parseInt(x.id));
    }


    if (filterValueState.Company_Origin && filterValueState.Company_Origin.length > 0) {
      filter.countryId = filterValueState.Company_Origin.map(x => parseInt(x.id));
    }


    if (filterValueState.typeId) {
      filter.typeId = filterValueState.typeId.id;

    }
    return filter;

  }

  useEffect(() => {
    callChannelList();
  }, [filterValueState]);


  const callChannelList = () => {
    page = 0;
    setPage(page);
    getChannelList();
  }
  const getChannelList = async () => {
    setLoading(true);
    let postData = {
      filter: getFilterData(),
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      type: type,
      eventId: eventId
    }
    let res = await commonService.postService(postData, 'publicChannel/channelList/').catch(err => setLoading(false));
    if (res) {
      data = res.data.result;
      setData(data);
      total = res.data.total;
      setTotal(total);
      setConfidential(res.data.isConfidential);
      console.log('data', data);
      setLoading(false);
    } else {
      data = [];
      setData(data);
      total = 0;
      setTotal(total);
      setLoading(false);
      ToastsStore.warning('Something went wrong');
    }
  }

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getChannelList();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callChannelList();
  };

  const detailView = async (id) => {
    let tokenData = await commonService.getServices('account/getPublicToken/' + id);
    if (tokenData) {
      // history.push('channelView/'+ id+'?token='+tokenData.data.token);
      const win = window.open(process.env.REACT_APP_LOCALSITE + 'channelView/' + id + '?token=' + tokenData.data.token, '_blank');
      win.focus();
    }
    else
      ToastsStore.warning('Something went wrong');
  }
  return (
    <div id="iner" className="main-content mt-3">
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      {(data.length <= 0 && !isLoading) && <div className="no-records ">
        <div className="no-img text-center">
          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} />
        </div>
      </div>}
      {isLoading ? <Spinner /> : <>
        {data.length > 0 && data.map((item, index) => {
          let logo = "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg";
          return (
            <Row className="justify-content-center">
              <Col className="card-wrapper ct-example" lg="12" md="12" sm="12" >
                <Card>
                  <CardBody>
                    <Row className="align-items-center justify-content-center">
                      <Col className="col-lg-auto col-md-auto col-sm-12 mobi-res">
                        <a
                          style={{ width: "130px", height: "130px", border: "1px solid #b8b8b8", backgroundColor: "white", cursor: isConfidential == 1 ? 'none' : 'pointer' }}
                          className={"avatar avatar-xl mb-2"}
                          href="#pablo"
                          onClick={() => { isConfidential != 1 && detailView(item.cid) }}
                        >
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                            alt="..."
                            src={item.logo ? process.env.REACT_APP_CDN_URL + item.logo : logo}
                          />
                        </a>
                        <div style={{ cursor: isConfidential == 1 ? 'none' : 'pointer' }} onClick={() => { isConfidential != 1 && detailView(item.cid) }}>
                          <Badge style={{ backgroundColor: "#2c9db7", color: "white", fontSize: "0.8000rem", textTransform: "capitalize", width: "100%", }}>
                            +details
                          </Badge>
                        </div>
                        {item.webSiteUrl && <div className="text-center">

                          <a style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.preventDefault();
                              let url = item.webSiteUrl;
                              if (!url.match(/^https?:\/\//i)) {
                                if (!url.match(/^http?:\/\//i))
                                  url = '//' + url;
                              }
                              //if (isConfidential != 1)
                              window.open(url, '_blank')
                            }}
                          >
                            <i className="fas fa-globe-asia mr-1"></i>Website
                          </a>

                        </div>}
                      </Col>
                      <div className="col-lg-10 col-md-10 col-sm-12 mobi-res3">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-sm-12 mobi-res1  res-padr1">
                            <Badge
                              style={{
                                backgroundColor: "#FF6347",
                                color: "white",
                                fontSize: "0.9000rem",
                              }}
                            >
                              {item.channelType}
                            </Badge>
                          </div>
                          {/* flag */}
                          <div className="col-auto col mobi-res2">
                            <a className="avatar avatar-xs rounded-circle">
                              <CountryFlag code={item.isoCode} />
                            </a>
                          </div>

                          <div style={{ paddingLeft: "0px" }} className={"col-lg-4 col-md-3 col-sm-12 mobi-res pad-01"}>
                            <h3 className="mb-0">{item.country}</h3>
                          </div>
                          <div className="col-lg-5 col-md-5 col-sm-12 pr-0 mobi-res4">


                            {!!item.contacts?.length && <UncontrolledDropdown className="dropdownwidthfirst">
                              <DropdownToggle caret color="default" className="btnfirst">
                                <a className={"avatar avatar-xs rounded-circle "}>
                                  <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }} style={{ width: '24px', height: '24px', objectFit: 'cover' }} alt="..." src={item.contacts[0]?.documentPath ? process.env.REACT_APP_CDN_URL + item.contacts[0]?.documentPath : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"} />
                                </a>
                                <span className={"dropdownfirst "}>
                                  {isConfidential == 1 ? 'XXXXX' + "-" + item.contacts[0]?.jobTitle : item.contacts[0]?.firstName + " " + item.contacts[0]?.lastName + "-" + item.contacts[0]?.jobTitle}

                                </span>
                              </DropdownToggle>

                              <DropdownMenu className="dropdownwidthseco">

                                {item.contacts?.map((con, i) => (
                                  <>
                                    <li>
                                      <DropdownItem id={"id-" + i} onClick={e => e.preventDefault()}>
                                        <a className={"avatar avatar-xs rounded-circle "}>
                                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }} style={{ width: '24px', height: '24px', objectFit: 'cover' }}
                                            alt="..."
                                            src={con.documentPath ? process.env.REACT_APP_CDN_URL + con.documentPath : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"}
                                          /></a>
                                        <span
                                          style={{
                                            position: "absolute",
                                            marginLeft: "10px",
                                          }} className={"userelip "}
                                        >
                                          {isConfidential == 1 ? 'XXXXX' + "-" + con.jobTitle : con.firstName + " " + con.lastName + "-" + con.jobTitle}

                                        </span>
                                      </DropdownItem>
                                      <UncontrolledTooltip target={"id-" + i} placement='bottom'>
                                        {isConfidential == 1 ? 'XXXXX' + "-" + con.jobTitle : con.firstName + " " + con.lastName + "-" + con.jobTitle}
                                      </UncontrolledTooltip>
                                    </li>
                                  </>
                                ))}

                              </DropdownMenu>
                            </UncontrolledDropdown>
                            }                          </div>
                        </div>
                        <Row>
                          <Col className="col-lg-auto col-md-auto col-sm-12 mobi-res2">
                            <h3 className={"mb-0 text-uppercase"}>
                              <a style={{ cursor: isConfidential == 1 ? 'none' : 'pointer' }}
                                href="#pablo"
                                onClick={() => { isConfidential != 1 && detailView(item.cid) }}
                              >
                                {item.companyName}
                              </a>
                            </h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-lg-7 col-md-6 col-sm-12">
                            <h4>
                              Specialty in/Categories:
                              <p className="ml-2" style={{ display: "inline" }}>
                                {item.company_cat && <ListItem elements={item.company_cat} index={'cat' + index} limit={4} />}
                              </p>
                            </h4>
                            {item.channelTypeId !== '4' && <h4>
                              Channel Customer Profiles: &nbsp;
                              <p style={{ display: "inline" }}>
                                {item.retail_customer_profile && <ListItem elements={item.retail_customer_profile} index={'pro' + index} limit={4} />}
                              </p>
                            </h4>}
                            {item.channelTypeId === '4' && <h4>
                              Interest In Start-Up : &nbsp;
                              <p style={{ display: "inline" }}>
                                {item.looking_startups && item.looking_startups[0].item_name && item.looking_startups[0].item_name.replace('S_VOLUME', ' Shipping in volume').replace('S_BACKER', ' Shipping to first backers').replace('PROTOTYPE', ' Prototype')}
                              </p>
                            </h4>}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-auto">
                            <h3 className="mb-0 text-uppercase">
                              <span
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                style={{ color: "#80807F" }}

                              >
                                Tweet Pitch
                              </span>
                            </h3>
                            <p>
                              {!!item.detailDesc ? item.detailDesc.replace(/&amp;/g, '&').replace(/<[^>]+>/g, '') : ''}
                            </p>
                          </Col>
                        </Row>
                      </div>

                      {/* <Col className="col-lg-1 col-md-1 col-sm-12 mobi-res5">
                      <div id={'view' + item.channelId} className="pointer" onClick={ ()=>history.push('/admin/mynetwork/channelDetail/' + item.cid) }>
                          <i
                            style={{ fontSize: "2em", cursor: "pointer" }}
                            className="far fa-eye float-right"
                          ></i>
                          <UncontrolledTooltip target={'view' + item.channelId} placement='bottom'>
                            View Channel
                          </UncontrolledTooltip>
                        </div>
                      </Col> */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>)
        })}
        {total > 10 &&
          <TablePagination
            component="div"
            count={total}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 20, 30, 40]}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        }
      </>}
    </div >

  );
};
export default PublicChannelListing;
