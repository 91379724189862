import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader } from "reactstrap";
import { FormAutocomplete } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import { ListGroupItem, ListGroup } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { FormSelect } from '_components/FormElements/FormInput';
import { commonService } from '_services/common.service';

const ListingFilters = (props) => {
  const { activeTab, handleChangeSelect, filterValueState, resetFilterValue } = props;
  const [filterOptionState, setFilterOption] = useState({ PositionOption: [{ name: 'MID', id: '1' }, { name: 'HIGH', id: '2' }], Brand_Option: [], Profile_Option: [], Location_Option: [], Family_Option: [], Category_Option: [], });
  const user = JSON.parse(localStorage.getItem('CHuser'));
  const { control } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (activeTab)
      getSearchAggregation();
  }, [activeTab])
  const getSearchAggregation = async () => {
    //  resetFilterValue();
    //resetFilterOptions();
    let data = await commonService.getServices('/product/productaggr/' + activeTab);
    if (data) {
      let options = data.data;
      if (options) {
        filterOptionState.Brand_Option = options.brand;
        filterOptionState.Family_Option = options.family;
        filterOptionState.Category_Option = options.category;
        filterOptionState.Profile_Option = options.profile;
        filterOptionState.Location_Option = options.location;
        setFilterOption({ ...filterOptionState });
      }
    }
    console.log('filterOptionState', filterOptionState);
  }

  // const toggle = tab => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // }
  //const [activeTab, setActiveTab] = useState('1');
  //const { control } = useForm({ mode:'onBlur' });

  const useStyles = makeStyles({
    root: {
      width: 300,
    },
  });
  function valuetext(value) {
    return `${value}°C`;
  }

  const classes = useStyles();
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const style = { height: '20rem' };

  return (

    <Card>

      <CardBody className='fav-ser'>
        <ListGroup>
          <ListGroupItem style={filterOptionState.PositionOption.length > 0 ? style : {}}>
            <div className=" text-center mt-2">
              <div className="form-group">
                <FormAutocomplete
                  id="Positioning"
                  name="Positioning"
                  label="Positioning"
                  options={filterOptionState.PositionOption}
                  placeholder="Select Positioning"
                  //multiple
                  // disableCloseOnSelect
                  limitTags={1}
                  control={control}
                  input='position'
                  value={filterOptionState.PositionOption}
                  open={true}
                  popupIcon=''
                  disablePortal={true}
                  size='small'
                  ListboxProps={{ style: { maxHeight: '8rem' } }}
                  handleChangeSelect={handleChangeSelect}
                />
              </div>
            </div>
          </ListGroupItem>

          <ListGroupItem style={filterOptionState.Brand_Option.length > 0 ? style : {}}>
            <div className=" text-center mt-2">
              <div className="form-group">
                <FormAutocomplete
                  id="Brand"
                  name="Brand"
                  label="Brand"
                  options={filterOptionState.Brand_Option}
                  placeholder="Select Brand"
                  multiple
                  disableCloseOnSelect
                  limitTags={1}
                  control={control}
                  input='brand'
                  value={filterValueState.Brand}
                  open={true}
                  popupIcon=''
                  disablePortal={true}
                  size='small'
                  ListboxProps={{ style: { maxHeight: '8rem' } }}
                  handleChangeSelect={handleChangeSelect}
                />
              </div>
            </div>
          </ListGroupItem>

          <ListGroupItem style={filterOptionState.Category_Option.length > 0 ? style : {}}>
            <div className=" text-center mt-2">
              <div className="form-group">
                <FormAutocomplete
                  id="Category"
                  name="Category"
                  label="Category"
                  options={filterOptionState.Category_Option}
                  placeholder="Select Category"
                  multiple
                  disableCloseOnSelect
                  limitTags={1}
                  control={control}
                  input='category'
                  value={filterValueState.Category_Option}
                  open={true}
                  popupIcon=''
                  disablePortal={true}
                  size='small'
                  ListboxProps={{ style: { maxHeight: '8rem' } }}
                  handleChangeSelect={handleChangeSelect}
                />
              </div>
            </div>
          </ListGroupItem>


          <ListGroupItem style={filterOptionState.Family_Option.length > 0 ? style : {}}>
            <div className=" text-center mt-2">
              <div className="form-group">
                <FormAutocomplete
                  id="Family"
                  name="Family"
                  label="Family"
                  options={filterOptionState.Family_Option}
                  placeholder="Select Family"
                  multiple
                  disableCloseOnSelect
                  limitTags={1}
                  control={control}
                  input='family'
                  value={filterValueState.Family_Option}
                  open={true}
                  popupIcon=''
                  disablePortal={true}
                  size='small'
                  ListboxProps={{ style: { maxHeight: '8rem' } }}
                  handleChangeSelect={handleChangeSelect}
                />
              </div>
            </div>

          </ListGroupItem>

          {/* <ListGroupItem style={style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Sampleprice"
                        name="Sampleprice"
                        label="Sample price"
                      
                        options={paidoption}
                        placeholder="Sample price"
                        // multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        // input='country'
                        // value={ country }
                        
                        />
                   
                    </div>
                  </div>

                  </ListGroupItem> */}

          <ListGroupItem style={style}>
            <div className=" text-center mt-2">
              <div className="form-group">
                <Typography id="range-slider" gutterBottom>
                  Price Range
                </Typography>
                <Slider
                  value={value}
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={valuetext}
                />
              </div>
            </div>
          </ListGroupItem>

          <ListGroupItem style={filterOptionState.Location_Option.length > 0 ? style : {}}>
            <div className=" text-center mt-2">
              <div className="form-group">
                <FormAutocomplete
                  id="country"
                  name="country"
                  label="Target location"
                  options={filterOptionState.Location_Option}
                  placeholder="Select Target location"
                  multiple
                  disableCloseOnSelect
                  limitTags={1}
                  control={control}
                  input='location'
                  value={filterValueState.Location_Option}
                  open={true}
                  popupIcon=''
                  disablePortal={true}
                  size='small'
                  ListboxProps={{ style: { maxHeight: '8rem' } }}
                  handleChangeSelect={handleChangeSelect}
                />
              </div>
            </div>

          </ListGroupItem>

          <ListGroupItem style={filterOptionState.Profile_Option.length > 0 ? style : {}}>
            <div className=" text-center mt-2">
              <div className="form-group">
                <FormAutocomplete
                  id="country"
                  name="country"
                  label="Customer Profile"
                  options={filterOptionState.Profile_Option}
                  placeholder="Select Customer Profile"
                  multiple
                  disableCloseOnSelect
                  limitTags={1}
                  control={control}
                  input='profile'
                  value={filterValueState.Profile_Option}
                  open={true}
                  popupIcon=''
                  disablePortal={true}
                  size='small'
                  ListboxProps={{ style: { maxHeight: '8rem' } }}
                  handleChangeSelect={handleChangeSelect}
                />
              </div>
            </div>
          </ListGroupItem>
        </ListGroup>
      </CardBody>


    </Card>
  );
};

const countryOption = [{ name: 'gopi', id: '1' }]
const paidoption = [{ name: "Paid", id: '1' }, { name: "free", id: '2' }]
export default ListingFilters;