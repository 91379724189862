import React,{useState,useEffect,useRef} from "react";
import {Container,Row,Button,Col,CardBody,CardHeader,Card,Nav, NavItem, NavLink,DropdownToggle,DropdownMenu,DropdownItem,UncontrolledDropdown,Badge} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader"
import Title from "views/Hoc/Dialog/Title";
import TextField from '@material-ui/core/TextField';
import { FormCheckBox } from  '_components/FormElements/FormInput';
import { useForm} from "react-hook-form";
import {history} from '_helpers';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { useDispatch } from 'react-redux';
import { dashboardConstants } from '_constants';
import { dashboardService } from '_services';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';

const Addcart =(props)=> {
  
  const dispatch=useDispatch();
  let [loading, setLoading]  = useState(false);
  const notificationAlert = useRef(null)
  const [state,setState] = useState([]);
  const [state1, setState1] = useState({});

  const [sort, setSort] = React.useState(true);
  const [cart, setCart] = React.useState({
    no_of_products:0,
    price:0,
    tax:0,
    total:0
  });
  const { control, watch, register } = useForm({ mode:'onBlur' });
  
  let AddedSuccess = props.location.state?.addToCart ? props.location.state?.addToCart : false;

const notify = (type, message) => {
  let options = {
    place: "tr",
    message: (
      <div className="alert-text">
        <span data-notify="message">
          {message}
        </span>
      </div>
    ),
    type: type,
    // icon: "ni ni-bell-55",
    autoDismiss: 5
  };
  notificationAlert.current.notificationAlert(options);
};

useEffect(() => {
    if(AddedSuccess)
        notify('success', ' Product added to the cart successfully');
    getCartDetails();
}, []);

const getCartDetails= async()=>{
    setLoading(true);    
    let res = await commonService.getServices('cart').catch(err =>console.log('err', err));      
    setState(res.data);

    let temp = {};

    res.data.map(x => {
        x.items.map((y,i)=>{
            let val = 'quantity'+x.cartid+''+i;
            temp = Object.assign({...temp, [val] : +y.quantity}) ; //    temp.push({[val] : y.quantity});
        })
    })
    setState1({...state1, ...temp});

    res.data.map(x => {
        cart.no_of_products+= +x.items.length;
        cart.price+=+x.grandtotal;
        cart.tax+=+x.taxcost;    
        cart.cartId=+x.cartid;    
    })
    setCart({...cart,no_of_products:cart.no_of_products,price:cart.price,tax:cart.tax,
    total:cart.price+cart.tax
    });    
    setLoading(false);    
}

const clearCart = async() => {
    setLoading(true);   
    let res=await commonService.deleteService('cart').catch(err =>{console.log('err', err); setLoading(false)});  
    if(res)
    {
    setLoading(false);    
            dashboardService.dashboard_count().then(dasboard_count => {               
                //console.log("dashboard_count:",dasboard_count);
                dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
              });
    notify('success','  Cart Cleared');          
    history.push({pathname:'/admin/dashbaord', state: {type: 2}});
    }
}

const deleteItem = async(productId) => {

    console.log("state:",state);
    setLoading(true);   
    let res=await commonService.deleteService('cart/item/'+productId).catch(err =>console.log('err', err));  
    if(res)
    {
    setLoading(false);    
            dashboardService.dashboard_count().then(dasboard_count => {               
                //console.log("dashboard_count:",dasboard_count);
                dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
              });
    notify('success','  Item Cleared');          
    history.push({pathname:'/admin/dashbaord', state: {type: 2}});
    }
}

const deleteCart = async(cartId) => {
    setLoading(true);
    let res = await commonService.deleteService('cart/'+cartId).catch(err => setLoading(false));
    if(res){
        setLoading(false);    
        getCartDetails();
        dashboardService.dashboard_count().then(dasboard_count => {               
            //console.log("dashboard_count:",dasboard_count);
            dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
          });
          notify('success','Cart Cleared Successfully');     
    }
}

const handleChange = (input, productId) =>async e => {
    let val = e.target.value;
    let data = {id: +productId, action: 'replace', quantity:val>0 ? +val : 0}
    setLoading(true);
    let res = await commonService.postService(data, 'cart/item').then(res => {
        setState1({...state1, [input]:val>0 ? +val : 0});
        return res.data;
    }).catch(err=>{console.log('error1', err);setLoading(false);notify('warning', err?.response?.data?.message)});
    if(res){
        getCartDetails();
    }
}

console.log('state1', state1);


return (


 <>
{loading && <Spinner />}
<SimpleHeader location={props.match}/>

<div id='View Cart' className="main-content">
<NotificationAlert ref={notificationAlert}/>
<Title title="View Cart" desc={[""]} />
<Container fluid className="mt-4">
    <Card>
  <CardBody>
                  <Row>
                    <Col lg="12">
                    <Row>
     <Col lg="8"></Col>
     {state.length ? <Col lg="4"><div style={{textAlign:'-webkit-right', paddingBottom:"2rem"}}><Button color="primary" onClick={clearCart} ><i class="far fa-trash-alt"></i>  Clear Cart</Button></div></Col> : <></>}
    </Row>
                <Card>
                    <CardHeader className="sm-vis" style={{backgroundColor:"#f8f9fe"}}>
                        {state.length > 0 ? <Row>
                        <Col lg="4"><h3>Sample Product Details</h3></Col>
                        <Col lg="8" className="pad-less">
                            <Row>
                            <Col lg="4"><h3>Sample Price Per Unit</h3></Col>
                            <Col lg="3"><h3>Quantity</h3></Col>
                            <Col lg="2"><h3>Final Amount</h3></Col>
                            <Col lg="3"><h3>Action</h3></Col>
                            </Row>
                        </Col>
                        </Row> : <>Cart is Empty</>}
                    </CardHeader>
                    <CardBody className="pad-less">

                    {state.map(item => (
                       <Row>
                           <Col lg="12" className="pt-3" style={{borderTop:"1px Dashed #ebecf1"}}>
                               <Row>
                               <Col lg="10">
                               <Button color="primary">Supplier : {item.seller.companyName}</Button>
                               </Col>
                               
                               <Col lg="2"><Button color="warning" onClick={e=>deleteCart(item.cartid)}>Delete Cart</Button></Col>
                               </Row>
                               
                    {item.items.map((product,i) => (

                        <Row className="cart-r" key={item.cartid+''+i}>
                        <Col lg="4" className="pad-less user-card" >
                        <h3 className="sm-vis1">Sample Product Details</h3>
                            <Row className="pt-4">
                                <Col lg="4" style={{textAlign:"-webkit-center"}}>
                                    <div className="ftl-img12">                                     
                                     <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={product.product.defaultImage.image.documentUrl} />                                  
                                     </div>
                                 </Col>
                                 <Col lg="8" className="pad-less res-i">
                                     <h3>{product.product.product}</h3>
                                     <p style={{marginBottom:"0px"}}>{product.product.brand.brandName}</p>
                                 
                                 <Badge className='outer_badge mr-2' style={{height:"2rem",padding:"0.6rem",backgroundColor:"#2c9db7",color:"#fff",border:"1px solid #2c9db7"}}>
                                 {product.product.isactive==1?'Active':'InActive'}
                                 </Badge>

                                 </Col>
                             </Row>
                         </Col>
                         <Col lg="8" className="pad-less">
                             <Row>
                             <Col lg="4" className="user-card pt4 res-mob-01 res-c">
                             <h3 className="sm-vis1 res-i">Sample Price Per Unit</h3>
                                 <i class="fas fa-euro-sign ml60"></i><h3 className="pri-data">{product.unitprice}</h3></Col>
                             <Col lg="3" className="user-card pinp">
                             <h3 className="sm-vis1">Quantity</h3>
                             <TextField
                                id="outlined-number"
                                label=""
                                name="quantity"
                                type="number"
                                defaultValue={product.quantity}
                                onChange={handleChange(`quantity${item.cartid+''+i}`, product.productid)}
                                value={state1['quantity'+item.cartid+''+i]}
                                register={register}
                                control={control}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                />
                             </Col>
                             <Col lg="2" className="user-card pt4  res-c res-mob-01">
                             <h3 className="sm-vis1 res-i">Final Amount</h3>
                             <i class="fas fa-euro-sign ml60"></i><h3 className="pri-data">{state1['quantity'+item.cartid+''+i]*product.unitprice}</h3>
                             </Col>
                             <Col lg="3" className="user-card pt4 res-mob-01  res-c">
                             <h3 className="sm-vis1">Action</h3>
                             <div>
                                 <a href="#"><i class="far fa-trash-alt ml4i  res-c" onClick={() => deleteItem(product.productid)} style={{color:"#525f7f",fontSize:"22px"}}></i></a>
                             </div>
                             </Col>
                             </Row>
                         </Col>
                        </Row> 
                        ))}

                        <Row>
                        <Col lg="9">
                             <FormCheckBox
                                        id="T/C"
                                        type="checkbox"
                                        checked
                                        //label=""
                                        //label1={{textAlign:"justify"}}
                                        //register={register}
                                        //value='EMEA'
                                        //onClick={handleChange('EMEA')}
                                    />
                                    <p className="pri-rate">I hereby accept to <a href="#">Terms & Conditions</a></p>
                                    <div className="p-top">
                                    <FormCheckBox
                                        id="P/C"
                                        type="checkbox"
                                        //checked
                                        //label=""
                                        //label1={{textAlign:"justify"}}
                                        //register={register}
                                        //value='EMEA'
                                        //onClick={handleChange('EMEA')}
                                    />
                                    <p className="pri-rate">Our company policy doesn't allow us to pay for samples</p>
                                    </div>
                             </Col>
                             <Col lg="3">
                                 <Row>
                                     <Col lg="4 pad-less"><h3 className="text-center">SUB TOTAL</h3></Col>
                                     <Col lg="8" className="text-center"><i class="fas fa-euro-sign ml25"></i><h3 className="pri-rate l">{item.grandtotal}</h3></Col>
                                 </Row>
                                 <Row>
                                 <Button color="primary" className="mb-4" style={{width:"100%"}} onClick={()=>{history.push({pathname:'/admin/checkout', state: {type: 2,cart:cart}})}} >Confirm</Button>
                                 </Row>
                             </Col>
                        </Row> 
                        </Col> 
                        </Row>
                    ))}
                                                                 
                     </CardBody>
                 </Card>
                     </Col>
                 </Row>

                
          </CardBody>
        </Card>
        </Container>
</div>
</>

);
}


export default Addcart;

