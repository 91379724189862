import React, {useEffect } from 'react';
import { Drawer } from 'antd';
import Channelprofedit from 'views/pages/Channel-Listing/Channelprofedit';
import Profileedit from 'views/pages/Channel-Listing/Profileedit';
import Factedit from 'views/pages/Channel-Listing/Factedit';
import ProductVisib from 'views/pages/Channel-Listing/ProductVisib';
import OtherUserProfileEdit from './OtherUserProfileEdit';
const Globaleditdrawer = (props) => {
    const { closeDrawer, visible, title, changedData, type } = props;

    useEffect(()=>{
      console.log('visible', visible);
      console.log('type', type);
    }, [visible])

  const onClose = () => {
    closeDrawer();
  };

  const SwitchPage = () =>{
    switch(type){
      case "1":
        return <Channelprofedit visible={visible} changedData={changedData} closeDrawer={closeDrawer}/>
      //for linked in icon edit
      case "11":
        return <Channelprofedit visible={visible} changedData={changedData} closeDrawer={closeDrawer} linkedIn={true}/>
        case "2":
          return <Factedit visible={visible} changedData={changedData} closeDrawer={closeDrawer}/>
      case "3":
        return <Profileedit  visible={visible} changedData={changedData} closeDrawer={closeDrawer}/>
      case "4":
        return <ProductVisib  visible={visible} changedData={changedData} closeDrawer={closeDrawer}/>
      case "5":
        return <OtherUserProfileEdit visible={visible} changedData={changedData} closeDrawer={closeDrawer}/>
      //for linked in icon edit
        default:
          return <>test</>
    }
  }
    
    return (
        <div className="site-form-in-drawer-wrapper">
        <Drawer
          title={title}
          // width={720}
          onClose={onClose}
          visible={visible}
        >
        {SwitchPage()}
        </Drawer>
        </div>
    );
};

export default Globaleditdrawer;