import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { commonService } from '_services/common.service';
import axios from 'axios';
import { Card, CardBody, Container, CardHeader, ListGroupItem, ListGroup, Row, Col, Button } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import { ConfirmDialog } from 'views/Hoc/Dialog/GlobalDialog';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '_helpers';
import { ToastsStore } from 'react-toasts';
import { Spinner } from "views/Hoc/Spinner";
import { eventService } from '_services';
import { FormCheckBox } from '_components/FormElements/FormInput';

//css end------------------------------
const ManageEventUsers = (props) => {
  const [value, setValue] = useState("");
  const [registeredContact, setRegisteredContact] = useState({
    contact: null, package: null
  });

  let [loggedContact, setLoggedContact] = useState(null);
  let [packageId, setPackageId] = useState(null);

  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem('CHuser'));

  let event_redux = useSelector(state => state.event);
  let eventDetails = useSelector(state => state.event)?.eventDetails;
  let eventPackage = useSelector(state => state.event)?.eventPackage;

  let [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);

  let defaultState = {
    channelId: userData?.channelId,
    eventId: eventDetails?.eventId,
    eventName: eventDetails?.event_name,
    cType: userData?.channelTypeId,
    pType: userData?.channelTypeId == '2' ? '1' : '2',
    channelContacts: [],
    selectedContacts: [],
    additionalMember: 0,
    marketingOptions: [],
    packageOptions: [],
    marketingOptionsPrice: 0,
    packageOptionsPrice: 0,

    //for mail
    participatedContacts: [],

  };

  const [state, setState] = useState(defaultState);


  const proceed = async () => {
    if (state.selectedContacts.length) {
      let users = state.selectedContacts.map(x => x.id);
      let add = +state.additionalMember + (eventPackage?.additionalMembers ? +eventPackage?.additionalMembers : 0);

      let marketingOptions = [];
      let packageOptions = [];

      if (state.marketingOptions) {
        state.marketingOptions.map(x => {
          if (!state.eventChannelDetails.marketingOptions?.includes(x.optionId)) {
            marketingOptions.push(x);
          }
        })
      }

      if (state.packageOptions) {
        state.packageOptions.map(x => {
          if (!state.eventChannelDetails.packageOptions?.includes(x.optionId)) {
            packageOptions.push(x);
          }
        })
      }

      let marketingOptionsPrice = marketingOptions.reduce((a, b) => a + +b.value, 0) ? marketingOptions.reduce((a, b) => a + +b.value, 0) : 0;
      let packageOptionsPrice = packageOptions.reduce((a, b) => a + +b.value, 0) ? packageOptions.reduce((a, b) => a + +b.value, 0) : 0;

      await dispatch({
        type: 'EVENT_USERS', data: {
          contacts: users, additionalMember: add, additionalMemberNew: +state.additionalMember, marketingOptions: state.marketingOptions, packageOptions: state.packageOptions, marketingOptionsPrice: marketingOptionsPrice,
          packageOptionsPrice: packageOptionsPrice
        }
      });
      let overall = (+total) + (+marketingOptionsPrice) + (+packageOptionsPrice);
      await dispatch({ type: 'EVENT_AMT', data: overall });

      // if(state.additionalMember>0 || packageOptions.length || marketingOptions.length){
      //   history.push('/admin/payment/event');
      // }else{
      setLoading(true);

      //for event contacts update mail
      let newParticipants = [];
      let deletedParticipants = [];

      let selectedIds = state.selectedContacts.map(x => x.id);

      state.selectedContacts.map(x => {
        if (!x.eventParticipantId)
          newParticipants.push(x.id);

      });

      state.participatedContacts.map(x => {
        if (!selectedIds.includes(x.id)) {
          deletedParticipants.push(x.id);
        }
      })

      //Event Changes Mail
      if (newParticipants.length || deletedParticipants.length) {
        let eventData = {
          name: userData.firstName + ' ' + userData.lastName,
          newParticipants: newParticipants,
          deletedParticipants: deletedParticipants,
          companyName: userData.companyName
        }

        axios.post('mailerservice/eventChangesMail', eventData);

      }

      await eventService.eventRegistration(event_redux);

      setLoading(false);
      // }


    } else {
      ToastsStore.error('Select Atleast one contact');
    }
  };

  useEffect(() => {

    if (!eventDetails || !eventPackage) {
      history.push('/admin/events');
    }

    (async () => {
      await getContacts();
      await getEventChannelDetails();
    })();

  }, []);

  const getContacts = async () => {
    setLoading(true);
    await commonService.getServices('/channel/getEventChannelDetails/' + state.eventId).then(res => {
      state.channelContacts = res.data.contacts;
      state.selectedContacts = res.data.participants;

      //for sending email on changing participants
      state.participatedContacts = res.data.participants;

      loggedContact = state.selectedContacts.filter(x => x.id == userData?.contactId)[0];

      setLoggedContact({ ...loggedContact });
      setPackageId(loggedContact?.packageId);

      state.channelContacts = state.channelContacts.map(x => {
        if (state.selectedContacts.filter(y => y.id == x.id).length) {

        }
        else return x;
      });

      state.channelContacts = state.channelContacts.filter(x => x);
      setState({ ...state });
    });
    setLoading(false);
  };

  console.log('registered contact', registeredContact);

  const getEventChannelDetails = async () => {
    setLoading(true);
    await commonService.getServices('/channel/getSelectedEventDetails/' + state.eventId).then(res => {
      state.eventChannelDetails = res.data;

      registeredContact.contact = state.channelContacts.filter(x => x.id == state.eventChannelDetails.result.registeredBy)[0];
      if (!registeredContact.contact)
        registeredContact.contact = state.selectedContacts.filter(x => x.id == state.eventChannelDetails.result.registeredBy)[0];
      registeredContact.package = state.eventChannelDetails.result.package;

      state.selectedContacts = state.selectedContacts.filter(x => x.id != state.eventChannelDetails.result.registeredBy);

      setRegisteredContact({ ...registeredContact });

      if (state.eventChannelDetails.result?.registeredBy != userData?.contactId)
        state.selectedContacts = state.selectedContacts.filter(x => x.id != userData?.contactId);



      if (state.eventChannelDetails?.marketingOptions?.length && eventPackage.marketingOptions) {
        eventPackage.marketingOptions.map(x => {
          state.eventChannelDetails.marketingOptions.map(y => {
            if (y == x.optionId) {
              state.marketingOptions.push(x);
            }
          })
        });
      }

      if (state.eventChannelDetails?.packageOptions?.length && eventPackage.packageOptions) {
        eventPackage.packageOptions.map(x => {
          state.eventChannelDetails.packageOptions.map(y => {
            if (y == x.optionId) {
              state.packageOptions.push(x);
            }
          })
        });
      }

      setState({ ...state });
    })
    setLoading(false);
  }

  console.log('state', state);

  const addContact = (value) => {

    if (!state.selectedContacts.filter(x => x.id == value.id).length) {
      // if(state.selectedContacts.length+1 > +eventPackage?.membersAllowed+eventPackage?.additionalMembers){
      //   confirmInactive(value);
      // }else{
      let temp = state.selectedContacts;
      temp.push(value);
      state.channelContacts = state.channelContacts.filter(x => x.id != value.id);
      setState({ ...state, selectedContacts: temp });
      // }

    }

  }

  const removeContact = (value) => {
    state.selectedContacts = state.selectedContacts.filter(x => x.id != value.id);
    state.channelContacts.push(value);
    // if(state.additionalMember>0){
    //     state.additionalMember -= 1;
    //     setTotal(state.additionalMember*eventPackage?.additionalMembersCosting);
    // }

    setState({ ...state });
  }

  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  const [activeProductPop, setActiveProductPop] = useState(false);
  const closePopup = () => {
    setActiveProductPop(false);
  };

  const confirmSavePopup = (data) => {
    let temp = state.selectedContacts;
    temp.push(data.value);
    state.channelContacts = state.channelContacts.filter(x => x.id != data.value.id);
    state.additionalMember = state.additionalMember + 1;
    setTotal(state.additionalMember * eventPackage?.additionalMembersCosting);
    setState({ ...state, selectedContacts: temp });
    closePopup();
  }

  const confirmInactive = (value) => {
    modalPopValue.message = `Note: You can also add additional users @ ${eventPackage?.additionalMembersCosting} /- per user`;
    modalPopValue.title = 'Event Users';
    modalPopValue.value = value;
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setActiveProductPop(true);
  }

  const handleChangeSelect = (input, value) => {
    if (input == 'marketingOptions' || input == "packageOptions") {
      if (state[input].includes(value)) {
        state[input] = state[input].filter(x => x.optionId != value.optionId);
      } else {
        state[input].push(value);
      }
      let temp = [];

      state[input].map(x => {
        if (!state.eventChannelDetails[input]?.includes(x.optionId)) {
          temp.push(x);
        }
      })

      state[input + 'Price'] = temp.reduce((a, b) => a + +b.value, 0);


      setState({ ...state });
    }
  }

  const joinEvent = async () => {
    setLoading(true);
    if (packageId) {

      if (packageId != loggedContact?.packageId) {

        let data = {
          packageId: packageId,
          eventId: state.eventId,
        };

        await axios.post('/channel/joinEvent', data).then(res => ToastsStore.success('successfully registered for Event'));

        let eventData = {
          name: userData.firstName + ' ' + userData.lastName,
          email: userData.email,
          contactId: userData.contactId
        };

        if (!loggedContact?.packageId) {
          axios.post('mailerservice/eventsRegisterMail', eventData);
        }


      }


      history.push('/admin/events');

    } else {
      ToastsStore.error('Please Select Package');
    }
    setLoading(false);




  }

  return (
    <>
      <SimpleHeader location={props.match} />
      {loading ? <Spinner /> : <Container>
        {!!userData.channelTypeId == 2 && <><ConfirmDialog open={activeProductPop} value={modalPopValue} handleSave={confirmSavePopup} handleClose={closePopup} />
          <Card className="mt-4">
            <CardHeader>

              <h4>Based on your plan you can add {eventPackage?.membersAllowed} {eventPackage?.additionalMembers ? ` + ${eventPackage?.additionalMembers} ` : ''} Users. {userData.channelTypeId == 2 && <span style={{ float: 'right' }}>Additional members @{eventPackage?.additionalMembersCosting}$</span>}</h4>

              {/* //for contacts autocomplete */}
              <div>
                <form>
                  <Autocomplete
                    id="country-select-demo"
                    style={{ width: "100%" }}
                    options={state.channelContacts}
                    // onChange={e => setValue(e.target.value)}
                    onChange={(event, newValue) => {
                      addContact(newValue);
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => (
                      <React.Fragment>
                        <span>
                          <a
                            className="avatar rounded-circle listImg"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >

                            <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={option.documentPath ? process.env.REACT_APP_CDN_URL + option.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />

                          </a>
                        </span>
                        {option.name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a Contacts"
                        freeSolo
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                    disableClearable={true}
                  />
                </form>
              </div>
            </CardHeader>
            <CardBody className="overflow-auto" style={{ "maxHeight": "280px" }}>
              <ListGroup className="list my--3" flush>
                {state.selectedContacts.map((task, index) => (
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <a
                          className="avatar rounded-circle"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={task.documentPath ? process.env.REACT_APP_CDN_URL + task.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                        </a>
                      </Col>
                      <div className="col ml--2">
                        <h4 className="mb-0">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            {task.name}
                          </a>
                        </h4>
                      </div>
                      <Col className="col-auto">
                        <Button onClick={() => removeContact(task)} color="primary" size="sm" type="button">
                          <i class="fas fa-trash-alt"></i>
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>))}
              </ListGroup>
            </CardBody>
          </Card></>
        }
        {!!eventPackage?.marketingOptions?.length && userData.channelTypeId == 2 && <Card>
          <CardHeader>
            Marketing Options
          </CardHeader>
          <CardBody>
            {

              eventPackage.marketingOptions.map(x => (
                <FormCheckBox
                  id={x.optionId}
                  type="checkbox"
                  label={x.text + ' - ' + x.value}
                  //label1={{textAlign:"justify"}}
                  //register={register}
                  checked={state.marketingOptions.includes(x)}
                  onChange={() => handleChangeSelect('marketingOptions', x)}
                />

              ))

            }
          </CardBody>
        </Card>}

        {/* {!!eventPackage?.packageOptions?.length && <Card>
              <CardHeader>
                {userData.channelTypeId==2 ? 'Vendor Options' : 'Buyer Options'}
              </CardHeader>
              <CardBody>
                {
                  
                  eventPackage.packageOptions.map(x => (
                    <FormCheckBox
                    id={x.optionId}
                    type="checkbox"
                    label={x.text + ' - ' + x.value}
                    //label1={{textAlign:"justify"}}
                    //register={register}
                    checked={state.packageOptions.includes(x)}
                    onChange={() => handleChangeSelect('packageOptions', x)}
                    />
            
                  ))
                  
                }
              </CardBody>
            </Card>} */}

        <>
          <h1 className='m-2 text-center'>Event Register Details </h1>

          <div className="d-flex justify-content-between">

            <div>
              <h3>Registered By</h3>
              {!!registeredContact.contact && <>
                <a
                  className="avatar rounded-circle"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={registeredContact.contact?.documentPath ? process.env.REACT_APP_CDN_URL + registeredContact.contact?.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} /></a>
                <h4>{registeredContact.contact?.name}</h4>
              </>}
            </div>
            {!!registeredContact.package && <div>
              <h3 className='mb-4 mt-2'>Event Name : {state.eventName}</h3>
              <h4>Selected Package</h4>
              <h5>Package Name - {registeredContact.package.packageName}</h5>
              <h5>Price - {registeredContact.package.price}</h5>
            </div>
            }
          </div>

          <div className="w-80 mt-3">
            {!!state.selectedContacts.length && <h3 className="pt-2 m-3 text-center">Other Delegate Members</h3>}
            {
              state.selectedContacts.map(x =>
              (x.id != registeredContact?.contact?.id && <div className="d-flex justify-content-between mb-2">
                <div>
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={x?.documentPath ? process.env.REACT_APP_CDN_URL + x?.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} /></a>
                  <h4>{x?.name} {x?.status == '1' ? <i class="fa fa-check" style={{ color: 'green' }}></i> : <></>} </h4>
                </div>
                <div>
                  <p>Package - {x.packageName} Price - {x.price}</p>
                </div>
              </div>
              )
              )

            }
          </div>

          {registeredContact?.contact?.id != userData.contactId && <div className='mt-2 mb-2'>

            <h3 className='text-center mb-3'>Choose Your Package</h3>
            <Row className="d-flex justify-content-center">
              <Col lg="3" className={"mb-3 " + (packageId == 204 ? 'active-Plan' : 'active-dis')} key={"delegate1"}>
                <h2 className="mb-3">Extra Delegate Shared Room</h2>
                <button onClick={() => setPackageId(204)} type="button" className={"btn " + (packageId == 204 ? 'bt-pri1' : 'bt-pri')}><h3 className="pre-rate">490<i class="fas fa-euro-sign"></i></h3></button>
              </Col>
              <Col lg="3" className={"mb-3 " + (packageId == 204 ? 'active-Plan' : 'active-dis')} key={"delegate1"}>
                <h2 className="mb-3">Extra Delegate Single Room</h2>
                <button onClick={() => setPackageId(205)} type="button" className={"btn " + (packageId == 205 ? 'bt-pri1' : 'bt-pri')}><h3 className="pre-rate">900<i class="fas fa-euro-sign"></i></h3></button>
              </Col>
            </Row>

          </div>
          }
        </>

        {/* <p>Price {eventPackage?.price} {userData.channelTypeId==2 && <> + Additional Members Cost {state.additionalMember*eventPackage?.additionalMembersCosting}  {state.marketingOptionsPrice ? ` + Marketing Options ${state.marketingOptionsPrice}` : ''}  {state.packageOptionsPrice ? ` + Package Options ${state.packageOptionsPrice}` : ''}</>} = {+total + (+state.marketingOptionsPrice) + (+state.packageOptionsPrice)}</p>
            <Button onClick={proceed}>Proceed</Button> */}

        {registeredContact?.contact?.id != userData.contactId && <Button onClick={joinEvent}>Proceed</Button>}
      </Container>}
    </>

  );
}




export default ManageEventUsers;