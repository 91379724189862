import React, { useState, useEffect } from 'react';
import { Modal } from "reactstrap";

export const VideoPreview = (props) => {
  const { url, videoModalClose, isOpen } = props;
  const [play, setPlay] = useState(true);
  const [mute, setMute] = useState(true);
  const unmute = () => {
    console.log('muted', mute)
    setMute(prev => !prev);
  }
  const playVideo = () => {
    console.log("Playing video:", play);
    var myVideo = document.getElementById('myIntroVideo');
  
    if (myVideo) {
      if (play)
        myVideo.play();
      else
        myVideo.pause();
    }
  }
  useEffect(() => {
    playVideo();
  }, [play])


  const handleModalClose = () => {
    const video = document.getElementById('myIntroVideo');
    video.pause();
    // setShowPlayButton(false);
    videoModalClose();
  };

  return (
    <div className="videoPreviews">
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        size="xl"
        toggle={handleModalClose}
      >
        <div className="modal-header" style={{ padding: "0.1rem" }}>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleModalClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body p-0 d-flex justify-content-center">
          <div className='detailvideo-wrap'>
          {/* {!play && (
  <i
    style={{ fontSize: '3rem', position: 'absolute', top: '35%', left: '48%', color: '#FF6347' }}
    className="fa fa-play-circle"
    aria-hidden="true"
    onClick={() => { 
      console.log("Play button clicked");
      setPlay(true); // Set play state to true
      playVideo(); // Play the video
    }}
  ></i>
)} */}
<video 
  id="myIntroVideo" controls
  onClick={() => {
    // console.log("Video clicked. Play state:", play);
    setPlay(prev => !prev);
  }} 
  src={url} 
  autoPlay={play} 
  style={{width:'100%',height:'100%'}}
></video>
{/* <button id="unmuteBtn" onClick={unmute}>
                        <i class={mute ? 'fas fa-volume-mute mte' : 'fas fa-volume-up unmte'}></i>
                      </button> */}

          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VideoPreview;
