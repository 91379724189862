import React,{useState,useEffect} from "react";

import { Card, CardBody,Row, Col,Button,CardTitle,CardText,CardImg ,UncontrolledTooltip} from "reactstrap";
import { FormAutocomplete } from "_components/FormElements/FormInput";
import { ListGroupItem, ListGroup } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm} from "react-hook-form";
import ChannelFilter from "./ChannelFilter";
import GuestNavbar from "components/Navbars3/GuestNavbar";
import Title from "views/Hoc/Dialog/Title";
import { commonService } from '_services/common.service';
import {Spinner, ModalSpinner} from 'views/Hoc/Spinner';
import TablePagination from '@material-ui/core/TablePagination';
import {useInfiniteScroll} from '_hooks';
import axios from 'axios';
export const ChannelList = props => {  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));
  const { control } = useForm({ mode:'onBlur' });
  const classes = useStyles();
  const unqual={height: '450px'};

  const [filterValueState, setFilterValue] = useState({location:[], brand:[], categories:[]});
  const [compData, setCompData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchMoreItems = async() => {
    
      await getbuyerslist();
      setIsFetching(false);
    
  }

  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreItems);



  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(9);
  let [total, setTotal] = useState(0);

  let viewId =  props.location.state ? props.location.state.viewId : 1;

  let title = viewId == '1' ? 'Buyers' : viewId == '2' ? 'Vendor' : viewId == '3' ? 'Distributor' : viewId == '4' ? 'Retailer' : 'Buyers';

  useEffect(() => {
    setCompData([]);
    setIsFetching(false);
    setLoading(true);
    getbuyerslist();
  }, [filterValueState]);

  

  const getbuyerslist = async() => {
    
    let postData={
      filter:getFilterData(),
      // order:sort?sort:'asc',
      // search:search?search.name:null,
      offset:compData.length,
      // // offset:1,
      limit:rowsPerPage,
    }

    let res = await commonService.postService(postData, 'guest/getBuyerSearchList/'+viewId).catch(err => setLoading(false) );
    if(res){
      console.log('aggre', res.data);
      setCompData(prevState => ([...prevState, ...res.data.data]));
      // setCompData(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    }

  }

  const getFilterData=()=>{
    let filter ={
      brandId:null,
      countryId:null,
      productCategoryId:null,
    };
    if(filterValueState.location && filterValueState.location.length>0){
      filter.countryId=filterValueState.location.map(x=> parseInt(x.id));
    }
    if(filterValueState.brand && filterValueState.brand.length>0){
      filter.brandId=filterValueState.brand.map(x=> parseInt(x.id));
    }
    if(filterValueState.categories && filterValueState.categories.length>0){
      filter.productCategoryId=filterValueState.categories.map(x=> parseInt(x.id));
    }
    console.log('filteredstate', filterValueState);
    return filter;
  }


  const handleChangePage = (event, newPage) => {
    page=newPage;
    setPage(page);
    getbuyerslist();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    getbuyerslist();
  };

  const handleChangeSelect = (input, value)=>{
    setLoading(true);
    console.log('input, value', input, value);
    
    if(input==='location'){
      filterValueState.location= value;
    }
    if(input==='brand'){
      filterValueState.brand= value;
    }
    if(input==='categories'){
      filterValueState.categories= value;
    }
    setFilterValue({ ...filterValueState })
    console.log('filterValueState', filterValueState);
  }

  const resetFilterValue =()=>{
    filterValueState.location=[];
    filterValueState.brand=[];
    filterValueState.categories=[];

    setFilterValue({ ...filterValueState });
  }

  return (

    <div className="main-content">

      
      <Title title={title} desc={['desc']} />
              <Card className="mt-4">
              <CardBody>
              <h4 className="text-muted mb-0">FILTERS</h4>
                <Row className="pt-2">
                  <Col id="leftFilter" lg="3" className="p-0 ml--4">
               <ChannelFilter viewId={viewId} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue}/>
                  </Col>
    
                  <Col className="pt-4" style={{ backgroundColor: "#f8f9fe" }} lg="9">
                  <div id="channelsGuest">

<div className="card-columns h-auto">
  {loading ? <Spinner /> : compData.map((x,i) => 
 
  
  <Card className="" key={x.companyName}>
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                  alt="..."
                  src={x.logo ? process.env.REACT_APP_CDN_URL + x.logo : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//'https://d35w8j22j5uly8.cloudfront.net/document/company/logo/tbiOm5AB_1614760431146_1614760543038.png'
                  className="channeCardlimg p-4"
                />

                <CardBody>
                  <div className="text-center">
                <h5 className="h3 title">
                      <span className="d-block mb-1">{x.contacts.length ? x.contacts[0].firstName+' '+x.contacts[0].lastName : x.companyName}</span>
                      <small className="h4 font-weight-light text-muted">
                      <i class="fas fa-map-marker-alt pr-1"></i>
                      {x.country}
                      </small>
                    </h5>
                    </div>
                  <CardText className="mt-4 channeldesc text-center">
                  {x.detaildesc ? x.detaildesc.replace(/<[^>]*>?/gm, '') : ''}
                  </CardText>
                  <div className="card-profile-stats text-center d-flex  justify-content-center p-0">
                  <div className="col-lg-12">
                  <h3 className={"h3 title text-center text-muted " + (x.lookingfor ? "" : "d-none")}>
                  Looking For :
                  </h3>
                  <CardText data-placement="right" id={"tooltipdesc"+i} className={" channeldesc text-center"+ (x.lookingfor ? "" : "d-none")}>
                  {x.lookingfor ? x.lookingfor.split(',')[0,2] : ''}
                  </CardText>
                  <UncontrolledTooltip delay={0} placement="bottom" target={"tooltipdesc"+i}>{x.lookingfor ? x.lookingfor : '-'}</UncontrolledTooltip>
                  </div>
                  </div>
                  <div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
                  <div className="col-lg-12">
                  <h3 className="h3 title text-center text-muted">
                  Meet With :  <CardText  className="">
                  {x.contacts.length && x.contacts[0].jobTitle}
                  </CardText>
                  </h3>
                  </div>
                  </div>
                 
                </CardBody>
              </Card>

 
  )
  } 
  </div> 

{total > compData.length ? (isFetching && <ModalSpinner />) : <>Nothing to Show...</>}
{/* {total>10 &&
                  <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 20, 30, 40]}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                      } */}
               
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            </div>
              
  );
}

const AnnuvalTurnOver = [
  { id: '<1M€', name: '<1M€' },
  { id: '1-10M€', name: '1-10M€' },
  { id: '10-100M€', name: '10-100M€' },
  { id: '>100M€', name: '>100M€' },
]


export default ChannelList;