import React, {useEffect, useState} from 'react';
import { Col,Row,Container,Card,CardImg,CardHeader,Badge,CardText } from "reactstrap";
import 'assets/scss/custom/guest_styles_v2.scss';
// import AdminNavbar from 'components/Navbars3/GuestNavbar';
import Footer from "./Footer"
import GuestPricing from "./GuestPricing"
import ProductList from "../ProductList"
import Wishlist from "./Wishlist"
import Navbarg from "components/Navbars3/GuestNavbar";
import axios from "axios";
import {history} from "_helpers/history";
import {UncontrolledTooltip} from "reactstrap";
const Channels = () => {
    return (
        <div>
            
        </div>
    );
};

const Vendors = (props) => {

  const [data, setData] = useState([]);
  const [loading, seetLoading] = useState(false);

  let path = props.location.pathname;

  useEffect(() => {
    getBuyers();
  }, []);

  const getBuyers = async() => {
    seetLoading(true);
    await axios.get('guest/buyershighlight/2').then(res => {
      setData(res.data);
    }).catch(err => seetLoading(false));
    seetLoading(false);
  }

    return (
        <div>
            <Navbarg path={path}/>
            {/* <AdminNavbar/> */}
            <div className="vendor">
            <div className='vmain'>
            <Row>
                <Col lg="6" className="pad-less vcolmn"></Col>
                <Col lg="6" className="pad-less vcolmn">
                    <div className='vpart'>
                        <div className='vpart-slide'>
                        <h1 className="text-uppercase text-center ">VENDORS</h1>
                        <p className="text-center mt-2"><strong>Grow your channel with maximum speed and trust.</strong> </p>
                        <div className="text-center vpart-button">
                        <button className="btn btn-primary" size="lg">Schedule a demo</button>
                        <button className="btn btn-default" size="lg">Apply For Free <p>No credit card required</p></button>
                        </div>
                        </div>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="yban bg-clr text-left">
                <Container>
                    <Row>
                        <Col lg='6' className='pt-7'>
                            <h3>Why ?</h3>
                            <p>Most vendors use industry trade shows to showcase new products, and then need lengthy follow-ups, checks and due diligences before they can actually ship to distributors and generate revenue. With innovation accelerating and product lifecycles getting shorter every day, there must be a better way.</p>
                        </Col>
                        <Col lg='6' className='pt-7'>
                            <h3 className='padben'>Benefits</h3>
                            <ul>
                                <li>Cut time to market from months to weeks.</li>
                                <li>Increase your visibility towards global consumer technology channel players.</li>
                                <li>Mitigate the risk of starting business with new channel partners.</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='cyp'>
                <h1 className='text-center'>Create your profile</h1>
                <div className="cypgri">
                    <Container fluid>
                    <Row>
                        <Col lg='4'>
                        <div className='cyptxt'>
                            <p>Add your brands, product categories you are offering, target retail channels, key retailers you are currently serving.</p>
                            <p>Define your geographical scope: countries where you wish to expand and countries where you are currently active.</p>
                        </div>
                        </Col>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-profile-001.png')}/>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>
            <div className="bg-clr">
            <div >

<h1 className="text-center pt-6"style={{fontSize:"2.5rem"}}>VENDORS HIGHLIGHTS</h1>
<h2 className="text-center pt-2">Below is a selection of buyers using ChannelHub to scout for new products and vendors.</h2>
<h2 className='text-center'> More than 500 distributors and retailers from 65 countries with full profile are active on ChannelHub for registered vendors.</h2>
</div> 
<Container className="mt-5">
<div className="card-columns">
{!loading && data.length && data.map((x,i) => (
<Card className=" h-auto">
<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
alt="..."
className="channeCardlimg"
src={x.logo ? process.env.REACT_APP_CDN_URL + x.logo : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/guestpage/fox.jpg")}
/>

<CardHeader className="text-center border-0 pt-md-4 pb-0 ">

<div className="text-center">
<h5 className="h3">
<strong>{x.companyName}</strong>
<span className="font-weight-light"></span>
</h5>
<CardText className="h5 font-weight-300 channeldesc">

<CardText id={'productContent'+i} className="channeldesc h4 font-weight-light text-muted text-center" style={{fontSize: "15px"}}>{x.detaildesc ? x.detaildesc.replace(/<[^>]*>?/gm, '') : '-'}</CardText>
<UncontrolledTooltip content='preview video' target={'productContent'+i} placement='bottom'>
{x.detaildesc ? x.detaildesc.replace(/<[^>]*>?/gm, '') : '-'}
</UncontrolledTooltip>
</CardText>
</div>
</CardHeader>

<div className="card-profile-stats text-center d-flex justify-content-center p-0">
<div className="col-lg-12">
<h5 className="h3 title"an className="heading">Country</h5>
<small className="h4 font-weight-light text-muted">
<i class="fas fa-map-marker-alt pr-1"></i>
{x.country}
</small>
</div>
</div>
<div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
<div className="col-lg-12">
<span className="heading">Meet with</span>
<span id={'productMeet'+i} className="description txtwrp1">{x.contacts.length && x.contacts[0].jobTitle}</span>
<UncontrolledTooltip content='preview video' target={'productMeet'+i} placement='bottom'>
{x.contacts.length && x.contacts[0].jobTitle}
</UncontrolledTooltip>
</div>
</div>


<div className="card-profile-stats justify-content-center pad-tile text-center d-none pb-2" >
<h3 className="h3 text-center text-muted">
Looking For :
</h3>
<span className="description"> {x.lookingfor ? x.lookingfor : '-'}</span>
</div>


</Card>
))
}
</div>
</Container>

<Container className="mt-2">

<div className="text-center">
<button className="btn btn-primary" onClick={e=>history.push({pathname:'channels', state:{viewId: 2}})}>LOAD MORE</button>
</div>
</Container>
</div>

            <div className='cyp bg-clr'>
                <h1 className='text-center'>Showcase your products</h1>
                <div className="cypgri">
                    <Container fluid>
                    <Row>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-product-001.png')}/>
                            </div>
                        </Col>
                        <Col lg='4'>
                        <div className='cyptxt'>
                            <p>Add your most innovative products:</p>
                            <p>Provide business-related information to potential buyers by showing the USP of your products.</p>
                        </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>
            <div className='cyp'>
                <h1 className='text-center'>Manage Samples Requests</h1>
                <div className="cypgri">
                    <Container fluid>
                    <Row>
                        <Col lg='4'>
                        <div className='cyptxt'>
                            <p>Manage sample requests coming from around the world:</p>
                            <ul>
                                <li>Review the order details.</li>
                                <li>Review customer full profile including ratio of sample evaluations.</li>
                                <li>Prepare a pro-forma.</li>
                                <li>Track the shipment untill it’s received and evaluated.</li>
                                <li>View individual ratings.</li>
                                <li>As well as the full analytic report of your product.</li>
                            </ul>
                        </div>
                        </Col>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-profile-001.png')}/>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>
            <div className='cyp bg-clr'>
                <h1 className='text-center'>Get Suggestions</h1>
                <div className="cypgri">
                    <Container fluid>
                    <Row>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-sample-management.png')}/>
                            </div>
                        </Col>
                        <Col lg='4'>
                        <div className='cyptxt'>
                        <p>Start getting suggestions of retailers and distributors matching your criteria:</p>
                            <ul>
                                <li>Get full details of each company.</li>
                                <li>Invite the ones you want to contact for business. Once approved by the other party you can start exchanging info.</li>
                                <li>Get recommendations of relevant distributors from retailers who like your products in case you don’t have an existing distributor in the country.</li>
                            </ul>
                        </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>
            <div className="yban text-left bg-whit">
                <Container>
                <h1 className='text-center pt-5'>Upcoming Features</h1>
                    <Row>
                        <Col lg='6' className='pt-3'>
                            <h3>Cross-rating system:</h3>
                            <ul>
                                <li>Get ratings on vendors you are interested to discuss with.</li>
                                <li>Add ratings of your existing customers and suppliers (rating is anonymous and moderated).</li>
                            </ul>
                        </Col>
                        <Col lg='6' className='pt-3'>
                            <ul className='checkmark'>
                                <li>Integrated and self-scheduling video conferencing solution</li>
                                <li>Meet-up sessions for ChannelHub members during international trade show</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            </div>
            <Footer/>
        </div>
    );
};

const Distributor = (props) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  let path = props.location.pathname;

  useEffect(() => {
    getBuyers();
  }, []);

  const getBuyers = async() => {
    setLoading(true);

    await axios.get('guest/buyershighlight/3').then(res => {
      setData(res.data);
    }).catch(err => setLoading(false));

    setLoading(false);
  }

    return (
        <div>
            <Navbarg path={path}/>
            <div className="vendor">
            <div className='vmain'>
            <Row>
                <Col lg="6" className="pad-less vcolmn"></Col>
                <Col lg="6" className="pad-less vcolmn">
                    <div className='vpart'>
                        <div className='vpart-slide'>
                        <h1 className="text-uppercase text-center ">DISTRIBUTORS</h1>
                        <p className="text-center mt-2"><strong>Source new products and find new channel partners with maximum speed and trust.</strong> </p>
                        <div className="text-center vpart-button">
                        <button className="btn btn-primary" size="lg">Schedule a demo</button>
                        <button className="btn btn-default" size="lg">Apply For Free <p>No credit card required</p></button>
                        </div>
                        </div>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="yban bg-clr text-left">
                <Container>
                    <Row>
                        <Col lg='6' className='pt-5'>
                            <h3>Why ?</h3>
                            <p>Product sourcing is vital for your business. The usual way to discover new products is to attend industry trade shows, and then engage into lengthy follow-ups, checks and due diligences before real business happens. With innovation accelerating and product lifecycles getting shorter every day, there must be a better way. And new channels are opening up every day for consumer tech so there are plenty of opportunities to diversify your customer portfolio locally or on the export markets.</p>
                        </Col>
                        <Col lg='6' className='pt-5'>
                            <h3 className='padben'>Benefits</h3>
                            <ul>
                                <li>Cut sourcing time from months to weeks</li>
                                <li>Be informed of relevant new products with no effort or investment</li>
                                <li>Find new retailers to work with</li>
                                <li>Find other distributors for your export business</li>
                                <li>Minimize the risk of starting business with new vendors</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='cyp'>
                <h1 className='text-center'>Create your profile</h1>
                <div className="cypgri">
                    <Container fluid>
                    <Row>
                        <Col lg='4'>
                        <div className='cyptxt'>
                            <h2>… as a seller</h2>
                            <p>Add brands you distribute, product categories your are offering, key retail customers you are currently serving. Define your geographical scope: countries where you wish to expand and countries where you are currently active.</p>
                        </div>
                        </Col>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-profile-001.png')}/>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-profile-001.png')}/>
                            </div>
                        </Col>
                        <Col lg='4'>
                        <div className='cyptxt'>
                            <h2>… as a buyer</h2>
                            <p>Add product families, categories you are selling and also the ones you are interested to see. The platform is multi-user so each category manager can update his own categories of interest.</p>
                        </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>

            <div className="">

<h1 className="text-center pt-6"style={{fontSize:"2.5rem"}}>DISTRIBUTORS HIGHLIGHTS</h1>
<h2 className="text-center pt-2">Below is a selection of buyers using ChannelHub to scout for new products and vendors.</h2>
<h2 className='text-center'> More than 500 distributors and retailers from 65 countries with full profile are active on ChannelHub for registered vendors.</h2>
</div> 
<Container className="mt-5">
<div className="card-columns">
{!loading && data.length && data.map((x,i) => (
<Card className=" h-auto">
<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
alt="..."
className="channeCardlimg"
src={x.logo ? process.env.REACT_APP_CDN_URL + x.logo : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/guestpage/fox.jpg")}
/>

<CardHeader className="text-center border-0 pt-md-4 pb-0 ">

<div className="text-center">
<h5 className="h3">
<strong>{x.companyName}</strong>
<span className="font-weight-light"></span>
</h5>
<CardText className="h5 font-weight-300 channeldesc">

<CardText id={'productContent'+i} className="channeldesc h4 font-weight-light text-muted text-center" style={{fontSize: "15px"}}>{x.detaildesc ? x.detaildesc.replace(/<[^>]*>?/gm, '') : '-'}</CardText>
<UncontrolledTooltip content='preview video' target={'productContent'+i} placement='bottom'>
{x.detaildesc ? x.detaildesc.replace(/<[^>]*>?/gm, '') : '-'}
</UncontrolledTooltip>
</CardText>
</div>
</CardHeader>

<div className="card-profile-stats text-center d-flex justify-content-center p-0">
<div className="col-lg-12">
<h5 className="h3 title"an className="heading">Country</h5>
<small className="h4 font-weight-light text-muted">
<i class="fas fa-map-marker-alt pr-1"></i>
{x.country}
</small>
</div>
</div>
<div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
<div className="col-lg-12">
<span className="heading">Meet with</span>
<span id={'productMeet'+i} className="description txtwrp1">{x.contacts.length && x.contacts[0].jobTitle}</span>
<UncontrolledTooltip content='preview video' target={'productMeet'+i} placement='bottom'>
{x.contacts.length && x.contacts[0].jobTitle}
</UncontrolledTooltip>
</div>
</div>


<div className="card-profile-stats justify-content-center pad-tile text-center pb-2 d-none" >
<h3 className="h3 text-center text-muted ">
Looking For :
</h3>
<span className="description"> {x.lookingfor ? x.lookingfor : '-'}</span>
</div>


</Card>
))
}
</div>
</Container>
<Container className="mt-2">

<div className="text-center">
<button className="btn btn-primary" onClick={e=>history.push({pathname:'channels', state:{viewId: 3}})}>LOAD MORE</button>
</div>
</Container>

            <div className='cyp bg-clr'>
                <h1 className='text-center'>Discover Product Suggestions</h1>
                <div className="cypgri">
                    <Container fluid>
                    <Row>
                        <Col lg='4'>
                        <div className='cyptxt'>
                        <h2>… as a seller</h2>
                            <ul>
                                <li>Get product suggestions based on your profile.</li>
                                <li>View detailed product page presenting business-related information to allow fast decision making.</li>
                                <li>Order samples seamlessly with your shopping cart.</li>
                            </ul>

                            <h2>… as a buyer</h2>
                            <ul>
                                <li>Manage your product wish list.</li>
                                <li>Get all the technical and logistic info.</li>
                                <li>Locate distributors.</li>
                            </ul>
                        </div>
                        </Col>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-product-001.png')}/>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>
            <div className='cyp'>
                <h1 className='text-center'>Order and Evaluate Samples</h1>
                <div className="cypgri">
                    <Container fluid>
                    <Row>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-profile-001.png')}/>
                            </div>
                        </Col>
                        <Col lg='4'>
                        <div className='cyptxt'>
                            {/* <p>Manage sample requests coming from around the world:</p> */}
                            <ul>
                                <li>Send your sample order to the vendor.</li>
                                <li>You can indicate if your company policy doesn’t allow you to pay for samples.</li>
                                <li>Track the shipment until it’s received.</li>
                                <li>Evaluate the sample and give a feedback to the vendor.</li>
                            </ul>
                        </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>
            <div className='cyp bg-clr'>
                <h1 className='text-center'>Get Suggestions</h1>
                <div className="cypgri">
                    <Container fluid>
                    <Row>
                        <Col lg='4'>
                        <div className='cyptxt'>
                        <p>In addition to product suggestions, you can also discover new Vendors, Retailers and other Distributors matching your criteria.</p>
                            <ul>
                                <li>Get full details of each company.</li>
                                <li>Send them an invite to start communicating with them.</li>
                            </ul>
                        </div>
                        </Col>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-sample-management.png')}/>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>

            <div className="yban text-left bg-whit">
                <Container>
                <h1 className='text-center pt-5'>Upcoming Features</h1>
                    <Row>
                        <Col lg='6' className='pt-3'>
                            <h3>Cross-rating system:</h3>
                            <ul>
                                <li>Get ratings on vendors you are interested to discuss with.</li>
                                <li>Add ratings of your existing customers and suppliers (rating is anonymous and moderated).</li>
                            </ul>
                        </Col>
                        <Col lg='6' className='pt-3'>
                            <ul className='checkmark'>
                                <li>Integrated and auto-scheduling video conferencing solution.</li>
                                <li>Spot deals to buy or sell excess inventories.</li>
                                <li>Meet-up sessions for ChannelHub members during international trade shows.</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            </div>
            <Footer/>
        </div>
    );
};

const Retailers = (props) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  let path = props.location.pathname;

  useEffect(() => {
    getBuyers();
  }, []);

  const getBuyers = async() => {
    setLoading(true);

    await axios.get('guest/buyershighlight/4').then(res => {
      setData(res.data);
    }).catch(err => setLoading(false));
    
    setLoading(false);
  }

    return (
        <div>
            <Navbarg path={path}/>
            <div className="vendor">
            <div className='vmain'>
            <Row>
                <Col lg="6" className="pad-less vcolmn"></Col>
                <Col lg="6" className="pad-less vcolmn">
                    <div className='vpart vpart2'>
                        <div className='vpart-slide'>
                        <h1 className="text-uppercase text-center m-0">RETAILERS</h1>
                        <p className="text-center m-0"><strong>Let ChannelHub work for you:</strong></p>
                        <p className="text-center mt-0 mb-1"><strong>Instead of you scouting the web and trade shows to discover new products,  our matching algorithm will select products and suppliers based on your profile. And you have all the information to make quick and reliable decisions.</strong> </p>
                        <div className="text-center vpart-button">
                        <button className="btn btn-primary" size="lg">Schedule a demo</button>
                        <button className="btn btn-default" size="lg">Apply For Free <p>No credit card required</p></button>
                        </div>
                        </div>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="yban bg-clr text-left">
                <Container>
                    <Row>
                        <Col lg='6' className='pt-7'>
                            <h3>Why ?</h3>
                            <p>Most retail buyers use industry trade shows to source new products, and then need lengthy follow-ups, checks and due diligences before they can fill their shelves. With innovation happening and product lifecycles getting shorter every day, there must be a better way.</p>
                        </Col>
                        <Col lg='6' className='pt-7'>
                            <h3 className='padben'>Benefits</h3>
                            <ul>
                                <li>Cut sourcing time from months to weeks</li>
                                <li>Be informed of relevant new products with no effort or investment</li>
                                <li>Mitigate the risk of starting business with new suppliers</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='cyp'>
                <h1 className='text-center'>Create your profile</h1>
                <div className="cypgri">
                    <Container fluid>
                    <Row>
                        <Col lg='4'>
                        <div className='cyptxt'>
                            <p>Select product families, categories you are selling and also the ones you are interested to see. The platform is multi-user so each category manager can update his own categories of interest.</p>
                            {/* <p>Define your geographical scope: countries where you wish to expand and countries where you are currently active.</p> */}
                        </div>
                        </Col>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-profile-001.png')}/>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>

            <div className="">

<h1 className="text-center pt-6"style={{fontSize:"2.5rem"}}>RETAILERS HIGHLIGHTS</h1>
<h2 className="text-center pt-2">Below is a selection of buyers using ChannelHub to scout for new products and vendors.</h2>
<h2 className='text-center'> More than 500 distributors and retailers from 65 countries with full profile are active on ChannelHub for registered vendors.</h2>
</div> 
<Container className="mt-5">
<div className="card-columns">
{!loading && data.length && data.map((x,i) => (
<Card className=" h-auto">
<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
alt="..."
className="channeCardlimg"
src={x.logo ? process.env.REACT_APP_CDN_URL + x.logo : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/guestpage/fox.jpg")}
/>

<CardHeader className="text-center border-0 pt-md-4 pb-0 ">

<div className="text-center">
<h5 className="h3">
<strong>{x.companyName}</strong>
<span className="font-weight-light"></span>
</h5>
<CardText className="h5 font-weight-300 channeldesc">

<CardText id={'productContent'+i} className="channeldesc h4 font-weight-light text-muted text-center" style={{fontSize: "15px"}}>{x.detaildesc ? x.detaildesc.replace(/<[^>]*>?/gm, '') : '-'}</CardText>
<UncontrolledTooltip content='preview video' target={'productContent'+i} placement='bottom'>
{x.detaildesc ? x.detaildesc.replace(/<[^>]*>?/gm, '') : '-'}
</UncontrolledTooltip>
</CardText>
</div>
</CardHeader>

<div className="card-profile-stats text-center d-flex justify-content-center p-0">
<div className="col-lg-12">
<h5 className="h3 title"an className="heading">Country</h5>
<small className="h4 font-weight-light text-muted">
<i class="fas fa-map-marker-alt pr-1"></i>
{x.country}
</small>
</div>
</div>
<div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
<div className="col-lg-12">
<span className="heading">Meet with</span>
<span id={'productMeet'+i} className="description txtwrp1">{x.contacts.length && x.contacts[0].jobTitle}</span>
<UncontrolledTooltip content='preview video' target={'productMeet'+i} placement='bottom'>
{x.contacts.length && x.contacts[0].jobTitle}
</UncontrolledTooltip>
</div>
</div>


<div className="card-profile-stats justify-content-center pad-tile text-center d-none pb-2" >
<h3 className="h3 text-center text-muted">
Looking For :
</h3>
<span className="description"> {x.lookingfor ? x.lookingfor : '-'}</span>
</div>


</Card>
))
}
</div>
</Container>
<Container className="mt-2">

<div className="text-center">
<button className="btn btn-primary" onClick={e=>history.push({pathname:'channels', state:{viewId: 4}})}>LOAD MORE</button>
</div>
</Container>

            <div className='cyp bg-clr'>
                <h1 className='text-center'>Discover Product Suggestions</h1>
                <div className="cypgri">
                    <Container fluid>
                    <Row>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-product-001.png')}/>
                            </div>
                        </Col>
                        <Col lg='4'>
                        <div className='cyptxt'>
                        <ul>
                             <li>Get product suggestions based on your profile.</li>
                                <li>View product detailed page presenting business-related information to allow you a fast decision making.</li>
                            </ul>

                            <ul>
                            <li>Order samples in a seamless way with your shopping cart.</li>
                                <li>Manage your wish list of products.</li>
                                <li>Get all the technical and logistic info.</li>
                                <li>Locate distributors.</li>
                            </ul>
                        </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>
            <div className='cyp'>
                <h1 className='text-center'>Manage Your Wish list & Order  Samples</h1>
                <div className="cypgri">
                    <Container fluid>
                    <Row>
                        <Col lg='4'>
                        <div className='cyptxt'>
                            <p>For each product: you have 3 action buttons: Dismiss, Save in wish list or Order sample</p>
                            <p>Send your sample request to the vendor and wait for a confirmation</p>
                            <p>Track the shipment progress until it’s received.</p>
                            <p>Evaluate the sample and give a feedback to the vendor.</p>
                        </div>
                        </Col>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-profile-001.png')}/>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>
            <div className='cyp bg-clr'>
                <h1 className='text-center'>Discover New Vendors and Distributors Profiles</h1>
                <div className="cypgri">
                    <Container fluid>
                    <Row>
                        <Col lg='8'>
                            <div className='cypimg'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='img-ban' src={require('assets/img/guestpage/vendors-sample-management.png')}/>
                            </div>
                        </Col>
                        <Col lg='4'>
                        <div className='cyptxt'>
                        <p>In addition to product suggestions, you can also discover new Vendors and Distributors matching your criteria.</p>
                            <ul>
                                <li>Get full details of each company.</li>
                                <li>Send them an invite to start communicating with them.</li>
                                <li>No risk of spam: only confirmed connections can send you messages</li>
                            </ul>
                        </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>

            <div className="yban bg-whit text-left">
                <Container>
                <h1 className='text-center pt-5'>Upcoming Features</h1>
                    <Row>
                        <Col lg='6' className='pt-3'>
                            <h3>Cross-rating system:</h3>
                            <ul>
                                <li>Get ratings on vendors you are interested to discuss with.</li>
                                <li>Add ratings of your existing customers and suppliers (rating is anonymous and moderated).</li>
                            </ul>
                        </Col>
                        <Col lg='6' className='pt-3'>
                            <ul className='checkmark'>
                                <li>Integrated and self-scheduling video conferencing solution</li>
                                <li>Spot deals to buy or sell excess inventories.</li>
                                <li>Meet-up sessions for ChannelHub members during international trade show</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            </div>
            <Footer/>
        </div>
    );
};

const Pricing = (props) => {
    let path = props.location.pathname;
    return (
        <div>
            <Navbarg path={path}/>
            <div className="vendor">
            <div className='vmain'>
            <Row>
                <Col lg="6" className="pad-less vcolmn"></Col>
                <Col lg="6" className="pad-less vcolmn">
                    <div className='vpart'>
                        <div className='vpart-slide'>
                        <h1 className="text-uppercase text-center mt-5 ">VENDOR SUBSCRIPTION PLAN</h1>
                        <p className="text-center mt-2"><strong>Subscription plan can be selected once your application form is approved</strong></p>
                        {/* <p className="text-center mt-2"><strong>Instead of you scouting the web and trade shows to discover new products,  our matching algorithm will select products and suppliers based on your profile. And you have all the information to make quick and reliable decisions.</strong> </p> */}
                        {/* <div className="text-center vpart-button">
                        <button className="btn btn-primary" size="lg">Schedule a demo</button>
                        <button className="btn btn-default" size="lg">Apply For Free <p>No credit card required</p></button>
                        </div> */}
                        </div>
                    </div>
                </Col>
            </Row>
            </div>
            <GuestPricing/>
            </div>
            <Footer/>
        </div>
    );
};

export {Channels,Vendors,Distributor,Retailers,Pricing}