
import React,{useState} from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {Collapse,NavbarToggler,DropdownItem,DropdownMenu,NavbarBrand, UncontrolledDropdown,DropdownToggle,Media,Navbar, NavItem,NavLink, Nav,Container, Row,Col} from "reactstrap";
import { Link } from "react-router-dom";
import axios from 'axios';
import { browserName, browserVersion } from "react-device-detect";

const GuestNavbar =(props)=> {
  // function that on mobile devices makes the search open
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  let style1 = "nav-item nav-link activeLink";
  let style2 = "nav-item nav-link";
  let path = props.path ? props.path : '/';
  
  const updatenavigation= async()=>{
    let navigationData = {}
    navigationData.browser = browserName + ' , Version :'+browserVersion;;
    navigationData.navigatedPage = window.location;
    axios.post('/guest/updateNavigationHistory', navigationData);
}
updatenavigation();
  // openSearch = () => {
  //   document.body.classList.add("g-navbar-search-showing");
  //   setTimeout(function() {
  //     document.body.classList.remove("g-navbar-search-showing");
  //     document.body.classList.add("g-navbar-search-show");
  //   }, 150);
  //   setTimeout(function() {
  //     document.body.classList.add("g-navbar-search-shown");
  //   }, 300);
  // };
  // // function that on mobile devices makes the search close
  // closeSearch = () => {
  //   document.body.classList.remove("g-navbar-search-shown");
  //   setTimeout(function() {
  //     document.body.classList.remove("g-navbar-search-show");
  //     document.body.classList.add("g-navbar-search-hiding");
  //   }, 150);
  //   setTimeout(function() {
  //     document.body.classList.remove("g-navbar-search-hiding");
  //     document.body.classList.add("g-navbar-search-hidden");
  //   }, 300);
  //   setTimeout(function() {
  //     document.body.classList.remove("g-navbar-search-hidden");
  //   }, 500);
  // };
  
    return (
      <>
        {/* <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom fixed-top guest",
            { "navbar-dark bg-info": this.props.theme === "dark" },
            { "navbar-light bg-secondary": this.props.theme === "light" }
          )}
        >
          <Container fluid>
                <div className="log-img" href="https://channelhub.net/" onClick={e => e.preventDefault()}>
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="navImg" alt="ch-logo" src={require('assets/img/brand/ch-logo.png')}/>
                </div>
            <Collapse navbar isOpen={true}>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem><Link className="nav-link nvhv" to="/gabout">About</Link></NavItem>
                <NavItem><Link className="nav-link nvhv" to="/vendor">Vendors</Link></NavItem>
                <NavItem><Link className="nav-link nvhv" to="/distributor">Distributors</Link></NavItem>
                <NavItem><Link className="nav-link nvhv" to="/retailers">Retailers</Link></NavItem>
                <NavItem><Link className="nav-link nvhv" to="/salesrep">SalesReps</Link></NavItem>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0 pl-0 nvhv" color="" tag="a">
                  
                    Pricing
                  
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='drop-img' src={require('assets/img/profile/vendor-180x180.png')}/>
                      <span>Pricing For Vendor</span>
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='drop-img' src={require('assets/img/profile/picto-profiles-distributor.png')}/>
                      <span>Pricing For Distributor</span>
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className='drop-img' src={require('assets/img/profile/picto-profiles-retailers.png')}/>
                      <span>Pricing For retailer</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem><Link className="nav-link nvhv" to="/events">Events</Link></NavItem>
                <NavItem><Link className="nav-link nvhv" to="/faq">F.A.Q</Link></NavItem>
                <NavItem><Link className="nav-link nvhv" to="/blog">Blog</Link></NavItem>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-4" navbar>
              <Link to="/Login"><button className="btn log-bt mr-2">Login</button></Link>
                <Link to="/signup"><button className="btn sign-bt">Signup</button></Link>
              </Nav>
            </Collapse>
          </Container>
        </Navbar> */}

<nav id="guestNav" class="navbar navbar-expand-md navbar-light bg-light bg-nav sticky-top">
          <Link to="/" class="navbar-brand">
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="navImg" alt="ch-logo" src={require('assets/img/brand/ch-logo.svg')}/>
          </Link>
     

        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse isOpen={!collapsed} navbar>
           
            <div class="navbar-nav ml-auto">
               <div class="navbar-nav">
                  <Link to="/gabout" class={path=='/gabout' ? style1 : style2}>
                  About
                  
                    </Link>
                  <Link to="/vendor" class={path=='/vendor' ? style1 : style2}>   Vendors  </Link>
                  <Link to="/distributor" class={path=='/distributor' ? style1 : style2}>  Distributors  </Link>
                   <Link to="/retailers" class={path=='/retailers' ? style1 : style2}>  Retailers  </Link>
                   <Link to="/salesrep" class={path=='/salesrep' ? style1 : style2}> SalesReps  </Link>
                <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
        Pricing  
              </DropdownToggle>
         
              <DropdownMenu right>
                <DropdownItem >
                 Pricing vendors 
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem >
                 Pricing distributors 
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  Pricing retailers
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
                   <Link to="/events" class="nav-item nav-link">  Events  </Link>
                   <Link to="/faq" class={path=='/faq' ? style1 : style2}>  F.A.Q  </Link>
                   <Link to="/blog" class={path=='/blog' ? style1 : style2}>  Blog  </Link>
             
            </div>
            
                  <Link to="/Login" class="nav-item nav-link ml-4 login"><i class="fas fa-sign-in-alt"></i>
  Login  </Link>
                <Link to="/signup" class="nav-item nav-link signup ml-4"><i class="fas fa-user-plus"></i>  Signup  </Link>
            </div>
            
     
        </Collapse>
    </nav>
      </>
    );
  }

// GuestNavbar.defaultProps = {
//   toggleSidenav: () => {},
//   sidenavOpen: false,
//   theme: "dark"
// };
// GuestNavbar.propTypes = {
//   toggleSidenav: PropTypes.func,
//   sidenavOpen: PropTypes.bool,
//   theme: PropTypes.oneOf(["dark", "light"])
// };

export default GuestNavbar;