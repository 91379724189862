import React from 'react';
import {Button, Modal,Row,Col,Card,CardBody} from "reactstrap";

const AwardsProfile =({awards})=>{
    return(
        <div>
            <Row>
                {awards.map(awardImage => 
     <Col lg="2" md="1" sm="1">
     <div className='key-card w-100 m-0 p-3' style={{border:'none', boxShadow:'none'}}>
         <div className='set-image h-100'>
     <img  src={process.env.REACT_APP_CDN_URL+awardImage} />
     </div>
     </div>
     </Col>
                )}
           
       
        </Row>

    </div>
    )
}

export default AwardsProfile;