import React, { useState, Component } from "react";
import { Card,CardBody,CardHeader, Container,Col,Row,Table } from "reactstrap";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import axios from 'axios';
import "../../../assets/scss/custom/subscription.scss"
import { Checkbox, Radio } from "@material-ui/core";

var totalamt = 0;
var plan = '';
var period = '';
var  amountpayable = 0;
class UserSubscription extends Component {
  
  constructor() {
    super();
    this.state = {
      name: "Subscription",
      coupancode:null,
      paymentOption: 'l'
    };
    this.subscriptionId = this.getSubscriptionPrice.bind(this);
    this.validateCoupan = this.validateCoupan.bind(this);
    this.updateCoupancode = this.updateCoupancode.bind(this);
    this.confirm = this.confirm.bind(this);
    this.updatePaymentOption = this.updatePaymentOption.bind(this);
   }
   componentDidMount() {
    this.getSubscriptionPrice();
    console.log( localStorage.getItem('subscriptionId') );
   }

  updateCoupancode(event) { 
    localStorage.setItem('coupancode',event.target.value)
    this.setState({ coupancode: event.target.value });
  }

  updatePaymentOption(event) { 
    this.setState({ paymentOption: event.target.value });
  }

  async getSubscriptionPrice(){
        var subId =  localStorage.getItem('subscriptionId');
        console.log(subId);
        let data = await axios.post('channel/subscription/subscribe_amt_evaluvate',
         {  subscriptionId:+subId,
            paymentMethodId:1,
            transactionId:null
         }).then(function(response) {
            console.log(response);
            return response.data;
         }).catch(function(error) {
           console.log(error.message);
           if (error.response) {
            console.log(error.response);
            console.log(error.response.data.message);
            ToastsStore.error(error.response.data.message)
            // client received an error response (5xx, 4xx)
          } 
         });
         totalamt = data.original_amount;
         amountpayable = data.amount;
         plan = data.plan;
         period = data.period
       this.setState({ subscriptiondata: data });
       this.setState({ amountpayable: data.amount });
   }

    async confirm(){ localStorage.setItem('stripe_payment_secret', null);
      localStorage.setItem('subscriptionAmount', +this.state.amountpayable);

      if(this.state.amountpayable < 1){
        ToastsStore.success('subscription completed successfully');
       // window.location.href = 'http://localhost:3001/';
      }
      if(this.state.paymentOption == 1 || this.state.paymentOption == 3){
        let data = await axios.post('channel/subscription/createPaymentIntend',
         {  amount:+this.state.amountpayable,
         }).then(function(response) {
            console.log(response);
            localStorage.setItem('stripe_payment_secret', response.data.paymentIntentSecret);
            
            return response.data;
         }).catch(function(error) {
           if (error.response) {
            ToastsStore.error(error.response.data.message)
           } 
         });
         if(data){ //alert(data.paymentIntentSecret);
          window.location.href = '../payment/'+this.state.paymentOption;
         }
         
      } else {
        window.location.href = '../payment/'+this.state.paymentOption;
      }
    
    }

    goback(){
      window.location.href="./Subscription"
    }

    async validateCoupan(){
      var finalAmount = this.state; console.log(this.state);
      var coupancode = '';
      var subscriptionPlanId = '';
      var subscriptionPeriodId = '';
      this.setState({ amountpayable:  this.state.subscriptiondata.amount });
      this.setState({ coupan_status: '' });
      let coupanData = await axios.post('channel/subscription/coupan_validate',
         {  finalAmount:+this.state.subscriptiondata.original_amount,
            couponCode:this.state.coupancode,
            subscriptionId:+localStorage.getItem('subscriptionId'),
            subscriptionPlanId:+this.state.subscriptiondata.planId,
            subscriptionPeriodId:+this.state.subscriptiondata.periodId
         }).then(function(response) {
         
            return response.data;
         }).catch(function(error) {
           console.log(error.message);
           if (error.response) {
            console.log(error.response);
            console.log(error.response.data.message);
            ToastsStore.error(error.response.data.message)
            // client received an error response (5xx, 4xx)
          } else if (error.request) {
            console.log(error.request);
            // client never received a response, or request never left
          } else {
            // anything else
          }
         });
         if(coupanData){
         this.setState({ amountpayable:  coupanData.finalAmt.discountedAmount });
         this.setState({ coupan_status: ' Coupan Applied '+ coupanData.finalAmt.couponDiscount });
         }
    }

    

    render() {
        return (
            <div className="main-content">
              <ToastsContainer position="top_right"
            autoClose={5000} store={ToastsStore}/>
            
                <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>
                    <Card>
                        <CardBody>
                            <div className="pre-plan"> 
                                <div class="confim_table">
                                <div class="confim_table_title">
                                  Subscription Plan
                                </div>
                                <div class="confim_table_title">
                                  Subscription plan cost
                                </div>
                                <div class="confim_table_title">
                                  Total 
                                </div>

                                <div class="confim_table_title_row">
                                {plan}
                                </div>
                                <div class="confim_table_title_row">
                                {totalamt} / {period}
                                </div>
                                <div class="confim_table_title_row">
                                  {totalamt}
                                </div>

                                

                                <div class="middle_row">
                                  <div class="coupanDiv">
                                    Coupan
                                    <input type="text" value={this.state.coupancode} onChange={this.updateCoupancode}></input>
                                    <button onClick={this.validateCoupan}>Apply</button>
                                  </div>
                                  <div>
                                    Payment Options
                                    <br></br>
                                    <input type="radio" id="Card" value="1" name="paymentOption" onChange={this.updatePaymentOption}/>
                                    <label for="Card">Card</label>
                                    &nbsp;
                                    <input type="radio" value="3" id="Alipay" name="paymentOption" onChange={this.updatePaymentOption}/>
                                    <label for="Alipay">Alipay</label>
                                    &nbsp;
                                    <input type="radio" value="4" id="Paypal" name="paymentOption" onChange={this.updatePaymentOption}/>
                                    <label for="Paypal">Paypal</label>
                                    &nbsp;
                                    <input type="radio" value="2" id="Wire_Transfer" name="paymentOption" onChange={this.updatePaymentOption}/>
                                    <label for="Wire_Transfer">Wire Transfer</label>
                                    
                                  </div>
                                  <br></br>

                                  by default Auto renewal opion is on for card payment<br></br>
                                  you can change it to off in your settings page
                                  
                                </div>
                                <div class="middle_row col1">

                                  {/* <div>
                                    <div class="middle_row ">Sub Total</div>
                                    <div class="middle_row">{totalamt}</div>
                                  </div> */}
                                  <div class="final_amount">Amount Payable  {this.state.amountpayable}</div>
                                  <span id="coupan_status">{this.state.coupan_status}</span>
                                </div>

                                </div>

                                <div class="bottom_row">

                                  <div>
                                  <Checkbox></Checkbox>I hereby accept to terms & conditions
                                  </div>
                                  <div>
                                  <button class="okbut" onClick={this.confirm}>Subscribe</button>&nbsp;&nbsp;
                                  <button class="alterbut" onClick={this.goback}>Change Plan</button>
                                  </div>

                                </div>
                                
                            </div>           
                        </CardBody>
                    </Card>
                </Container>
            </div>
        );
    }

  
};



export default UserSubscription;