import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Viewer } from '@react-pdf-viewer/core';
import { Document, Page ,pdfjs } from 'react-pdf';
import '@react-pdf-viewer/core/lib/styles/index.css';
import PdfViewer from '../PdfViewer';



const PdfView = (props) => {
    const { url, pdfModalClose, isOpen } = props;
    pdfjs.GlobalWorkerOptions.workerSrc =  
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; 
    const modalBody = () => (
        <div
            style={{
                backgroundColor: '#fff',
                flexDirection: 'column',
                overflow: 'auto',

                /* Fixed position */
                left: 0,
                position: 'fixed',
                top: 0,

                /* Take full size */
                height: '100%',
                width: '100%',

                /* Displayed on top of other elements */
                zIndex: 9999,
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#000',
                    color: '#fff',
                    display: 'flex',
                    padding: '.5rem',
                    position:'sticky',
                }}
            >
                <div style={{ marginRight: 'auto' }}>sample-file-name.pdf</div>
                <button
                    style={{
                        backgroundColor: '#357edd',
                        border: 'none',
                        borderRadius: '4px',
                        color: '#ffffff',
                        cursor: 'pointer',
                        padding: '8px',
                    }}
                    onClick={pdfModalClose}
                >
                    Close
                </button>
            </div>
            <div
                style={{
                    flexGrow: 1,
                    overflow: 'auto',
                }}
            >
                <Viewer fileUrl={url} />
            </div>
        </div>
    );

    return (
        <>
            {isOpen && ReactDOM.createPortal(modalBody(), document.body)}
        </>
    );
};

export default PdfView;