import React ,{useState, useEffect} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {FormAutocomplete} from "_components/FormElements/FormInput";
import {
Button, Modal,Col,
} from "reactstrap";
import { ProfileUploader } from '../ProfileUpload';
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import axios from 'axios';
import { useForm} from "react-hook-form";
import { Row } from 'antd';
import { FormInput } from '_components/FormElements/FormInput';

export const AddKeyPartner=(props)=> {
const { opneadd, closeModalPopup, brandName } = props
const [open, setOpen] = React.useState(false);
const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
useEffect(() => {
setOpen(true);
}, [])
const handleClickOpen = () => {
setOpen(true);
};

const handleClose = () => {
setOpen(false);
};

const { register, handleSubmit, control, errors, watch,setValue  } = useForm({      
  mode:'onBlur',  
});

const [image, setImage] = useState('');
const [imageSrc, setImageSrc] = useState('');
const [brand, setBrand] = useState(brandName);
const [country, setCountry] = useState({id:'', name: ''});
const [loading, setLoading] = useState(false);
const [countryerr, setCountryerr] = useState(false);

console.log('brandaa', brand);


const selectImg = async(data)=>{
    if(data){
      setImage('');
      //converting base64 string to file format.
      var random = Math.random(); 
      var fileName =random +'.png';
      var file = dataURLtoFile(data, fileName);    
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/'+props.aws_folderName);
      console.log('resImagePath', resImagePath);
      if(resImagePath){
        setImageSrc(resImagePath.data.url);
        setLoading(false);
      }
        
    }
    
  }

  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

const handleChange = event => {
    const data = event.target.files[0];
    console.log('files', data)
      setError('');
      if(data && data['type'].split('/')[0] === 'image'){
        console.log('datat', data);
        let file = data;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          console.log('file', file)
          console.log('reader.result' ,reader.result)
          setImage(
            reader.result
          );
          console.log('image', image)
          //setOpen(false);          
        };
        
      }else{
        setError('Jpg | Jpeg | PNG Image Formats Only Accepted');
      }
    
  };

  const handleCropClose = () => {
    setImage('');
  }

  const handleChangeSelect = (input,value) => {
    setCountry(value);
    setCountryerr(false);
  }

  const  [error, setError] = React.useState('');

  const addBrandData = async() => {
    setError('');
    if(brand && country.name ){ //&& imageSrc
        console.log('brand', brand);
        const isAvailable = await axios.get('setting/channelKeyPartnerCheck', {params : {
          partner_type: props.partner_type,
          KeyRetailer: brand,
          CountryId: country.id,
        }});
        if(isAvailable.data == true){
          let random = '10' + Math.floor(Math.random() * 100000);
          if(props.userList){
            let temp = props.userList.filter(x => x.name.toLowerCase()==brand.toLowerCase() && x.countryId == country.id);
            console.log('temp', temp);
            if(temp.length){
              setError(props.input+' Name should be unique.');
            }else{
              props.handleChangeSelect(props.input, {name:brand, id: +random,  documentpath:imageSrc, isNew: '1', countryId: country.id});
              closeModalPopup();
            }
          }
        }else
        {
          let channelTypeId = isAvailable.data;
              channelTypeId = (channelTypeId == 2 ? channelTypeId = 'Vendor':channelTypeId == 3 ? channelTypeId = 'Distributor':channelTypeId == 4 ? channelTypeId = 'Retailer' : channelTypeId="Reseller")
          setError('This company already exists as '+ channelTypeId + " in "+ (country.name).toLowerCase() + " Please add it as key " + channelTypeId)
        }
    }else{
        let err = '';
        err = !brand ? `Please Enter ${props.input} Name` : '';
      
        if(country.name=='')
          setCountryerr(true);
        // err = !imageSrc ? err ? 'Please Enter Brand Name and Select Brand Image' :  'Please Choose an image' : err;
        // setError(err);
        console.log('error', error);
    }
  }

  const handleBrand = e => {
    setBrand(e.target.value);
  }

return (
  <div>
      <Modal className="modal-dialog-centered"
       size="lg" isOpen={opneadd}>
          <div className="modal-header">
           <h2><strong>Add Key {props.input} Details</strong></h2>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={closeModalPopup}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
              <div className="addkey">
                 <Row>
                   <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                     <Row>
                     <FormInput id="outlined-basic"
                      label={`${props.input} Name`} 
                      name="name"
                      variant="outlined" 
                      onChange={handleBrand} 
                      value={brand === true ? '' : brand}
                      error={(brand == '') ? {message: `${props.input} Name can't be empty.`, required: true} : ''}/>
                      
                    </Row>
                    <Row>
                        <div className="mt-4 addkey">
                        <div className="form-group">    
                    
                        <FormAutocomplete
                        id="country"
                        name="country"
                        label="Country"
                        options={props.countryList}
                        placeholder="Select Country"
                        // multiple
                        disableCloseOnSelect
                        blurOnSelect
                        limitTags={1}                       
                        input='country'
                        value={ country }
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Please Select Country'}
                        error={countryerr}
                        defaultValue={country}
                        control={control}
                        />
                </div>
                </div>
                    </Row>
                   </div>
                
                  
                   <div className="col-lg-3 col-md-3 col-5">
                   <ProfileUploader img={imageSrc ? process.env.REACT_APP_CDN_URL_TEMP+imageSrc : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
handleChange={handleChange} selectImg={selectImg} image={image} handleCropClose={handleCropClose}
/>                     </div>
                  
                 
                  <div className="col-lg-3 col-md-4 col-6">
                  <p>Accepts Jpeg / Jpg / Png Only</p>
                  <p>(Max size allowed 200 Kb)</p>
                      </div>
                    
                 
                  
                 </Row>
                 <span style={{color: '#f44336', fontSize:'0.75rem'}}>{error}</span>
              </div>
              {/* <span style={{color: '#f44336', fontSize:'0.75rem'}}>{error}</span> */}
          </div>
                         

          <div className="modal-footer">
           <Col lg="6" className="text-left">
          
          </Col> 
          <Col lg="6" className="text-right">
          <Button
          className='btn btn-primary'
          data-dismiss="modal"
          type="button"
          onClick={addBrandData}
          >
            Add
            </Button>
          <Button color="secondary"  type="button"  isOpen={open}    onClick={closeModalPopup}>Close</Button>
          </Col></div>           



{/* <div className="modal-footer">

  <Col lg="6">
  <span style={{color: '#f44336', fontSize:'0.75rem'}}>{error}</span>
  </Col>
  <Col lg="6">
    <Row>
<Button
className='btn btn-primary'
data-dismiss="modal"
type="button"
onClick={addBrandData}
>
Add
</Button>
<Button
color="secondary"
data-dismiss="modal"
type="button"
onClick={closeModalPopup}
>
Close
</Button>
</Row>
</Col>




</div> */}
</Modal>
</div>

);
}
export default AddKeyPartner;