
import React, { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)

// javascipt plugin for creating charts

// react plugin used to create charts

// reactstrap components
import {

  Container,
  Row,
  Col, CardBody, Card, Button, ButtonGroup,
  ListGroup, Table, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import { Link } from 'react-router-dom';
import SimpleHeader from "components/Headers/SimpleHeader"
import ProggressBar from "./progress";
import Chart from "chart.js";
import {


  CardHeader,

  CardFooter,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  ListGroupItem,

} from "reactstrap";

import {
  chartOptions,
  parseOptions,
  chartExample3
} from "./charts";
import { Line } from "react-chartjs-2";
import { commonService } from '_services/common.service';
import { useSelector } from "react-redux";
import { ProfileViewConnect } from "views/Hoc/Dialog/ProfileViewConnect";
import { history } from "_helpers";
import { PlanFeatureDialog } from "views/Hoc/Dialog/PlanFeatureDialog";
import { userConstants } from '../../../_constants';
import axios from 'axios';
import Helmet from 'react-helmet';
// Colors
var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529"
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340"
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent"
};

const VendorDashboard = (props) => {
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }


  var randomScalingFactor = function () {
    return Math.round(Math.random() * 100);
  };

  let [connect, setconnect] = useState(false);
  let [title, setTitle] = useState('');
  const [planUpgrade, setplanUpgrade] = useState(false);
  const closePopup = () => {
    setconnect(false);
  };

  const openView = (id) => {
    setTitle(id == '1' ? 'Profile Views' : 'Product Views');
    setconnect(true);
  }
  const [upgradeType, setUpgradeType] = useState('dashboard');


  var config = {
    type: 'line',
    data: {
      labels: [['June', '2015'], 'July', 'August', 'September', 'October', 'November', 'December', ['January', '2016'], 'February', 'March', 'April', 'May'],
      datasets: [{
        label: 'New Visits',
        fill: false,
        backgroundColor: 'red',
        borderColor: 'red',
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor()
        ]
      }, {
        label: 'Unique Visits',
        fill: false,
        backgroundColor: 'blue',
        borderColor: 'blue',
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor()
        ],
      },
      {
        label: 'Average Visits',
        fill: false,
        backgroundColor: 'orange',
        borderColor: 'orange',
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor()
        ],
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Chart with Multiline Labels',

      },
    }
  };

  let userData = JSON.parse(localStorage.getItem('CHuser'));

  let typeId = userData.channelTypeId;

  let profileViewAccess;

  // plan expiry check 
  var cuurentDate = new Date();
  var planExpireAt = new Date(userData.planExpireAt);
  console.log(cuurentDate, '+++++++', planExpireAt);
  // console.log(+cuurentDate.getTime() > +planExpireAt.getTime());
  if ((+cuurentDate.getTime() > +planExpireAt.getTime()) && userData.channelTypeId == 2 && userData.planId != '1') {
    history.push('/admin/Subscription');
  }
  let fl = "featureList" in userData ? true : false;
  let status = userData?.featureList ? (fl && userConstants.DASHBOARD_PROFILE_PRODUCT in userData.featureList) ? true : false : false;
  if (status)
    profileViewAccess = userData.featureList[userConstants.DASHBOARD_PROFILE_PRODUCT];

  // let profileViewAccess = userData.featureList[userConstants.DASHBOARD_PROFILE_PRODUCT];

  const dashboard = useSelector(state => state.dashboard);


  const [state, setState] = useState({
    leads: '0',
    inbound: '0',
    outbound: '0',
    favorites: '0',
    views: '0',
    profileViews: '0',
    productViews: '0',
    balance: '0',
    profileStrength: '0',
    planType: '',
    start: '',
    end: '',
    featureList: [],
    profileList: [],
    productList: [],
    profile_fav_List: [],
    product_fav_List: [],
    graphData: [],
    isPlanExpired: false
  });

  let chartExample3 = {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[200],
              zeroLineColor: colors.gray[200]
            },
            ticks: {}
          }
        ]
      },

    },
    data: {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: state.graphData
      // datasets: [
      //   {
      //     label: "New Visits",
      //     data: [0, 20, 10, 30, 15, 40, 20, 60, 80, 10, 11, 12],
      //     borderColor: '#fb6340'
      //   },
      //   {
      //     label: "Unique Visits",
      //     data: [0, 30, 20, 20, 10, 40, 30, 50, 70, 20, 21, 25],
      //     borderColor: '#11cdef',
      //   },
      //   // {
      //   //   label: "Average Visits",
      //   //   data: [0, 40, 30, 10, 20, 30, 10, 30, 50, 12, 30, 30],
      //   //   fill:true,
      //   //   borderColor: '#2dce89'
      //   // }
      // ]
    }
  };
  const [planDetails, setPlanDetails] = useState([]);
  const [featureArr, setFeatureArr] = useState([]);


  useEffect(() => {
    getDashboardData();
    getSubscriptionPlanDetails();
  }, []);


  const getSubscriptionPlanDetails = async () => {
    await commonService.getServices('/channel/subscription/getPlansDetails').then(res => {
      //  console.log('subscriptionExtraFeature');
      //  console.log(res.data.category);
      //  console.log('=========================================');
      setFeatureArr(res.data.category);
      let planDetails = {}; let resu = res.data.result;
      planDetails.planType = resu.subscription.subscriptionPlan.subscriptionPlanType ? resu.subscription.subscriptionPlan.subscriptionPlanType : 'Free';

      planDetails.start = resu.startedAt ? new Date(resu.startedAt) : '';
      planDetails.startedAt = planDetails.start ? new Date(planDetails.start).getDate() + ' ' + planDetails.start.toLocaleString('default', { month: 'long' }) + ' ' + planDetails.start.getFullYear() : '';

      planDetails.endAt = resu.endAt ? new Date(resu.endAt) : '';
      planDetails.end = planDetails.endAt ? planDetails.endAt.getDate() + ' ' + planDetails.endAt.toLocaleString('default', { month: 'long' }) + ' ' + planDetails.endAt.getFullYear() : '';
      setPlanDetails(planDetails);
      console.log('=========================================');
      console.log(planDetails);
      console.log('=========================================');
    }).catch(err => {

      //commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/channel/subscription/getPlansDetails'},'/error');                     
    });
  }

  const [activity, setActivity] = useState('Today');
  const handleChange = async (filter) => {
    setActivity(filter);
    await axios.post('/channel/dashboardActivity', { all: filter == 'All' ? '1' : '0' }).then(res => {
      state.profileList = res.data.profile_activity;
      state.productList = res.data.product_activity;
      state.product_fav_List = res.data.product_fav_activity;
      state.profile_fav_List = res.data.profile_fav_activity;
      setState({ ...state });
    }).catch(err => console.log('err', err));
  }

  const getDashboardData = async () => {
    //get New ProfileStrength
    await axios.get('/channel/profileStrength').then(res => {
      state.profileStrength = res.data;
      setState({ ...state });
    });

    let res = await commonService.getServices('/channel/getDashboardcount').catch(err => console.log('err', err));
    //let subs = await commonService.getServices('/channel/subscription/getPlansDetails');
    if (res) {
      state.inbound = res.data.inbound_count;
      state.outbound = res.data.outbound_count;
      state.favorites = res.data.favo;
      state.productViews = res.data.product_visit;
      state.profileViews = res.data.profile_visit;
      state.profileList = res.data.profile_activity;
      state.productList = res.data.product_activity;
      state.product_fav_List = res.data.product_fav_activity;
      state.profile_fav_List = res.data.profile_fav_activity;
      state.feature_list_view = res.data.featureList;

      state.leads = res.data.suggestion_vendor_count + res.data.suggestion_dist_count + res.data.suggestion_retail_count + res.data.productsugg;
      state.balance = res.data.walletamt ? res.data.walletamt : 0;
      //state.profileStrength = res.data.profile_strength ? res.data.profile_strength : 0;
      state.planType = res.data.subscriptionPlanType ? res.data.subscriptionPlanType : 'Free';
      state.start = res.data.startedAt ? new Date(res.data.startedAt) : '';
      state.start = state.start ? state.start.getDate() + ' ' + state.start.toLocaleString('default', { month: 'long' }) + ' ' + state.start.getFullYear() : '';
      state.end = res.data.endAt ? new Date(res.data.endAt) : '';
      state.end = state.end ? state.end.getDate() + ' ' + state.end.toLocaleString('default', { month: 'long' }) + ' ' + state.end.getFullYear() : '';
      // if(Object.keys(res).length !== 0 && res.constructor !== Object)
      //   state.featureList = subs?.data?.result?.subscriptionExtraFeature
      state.graphData = res.data.graphData;

      var curentDate = new Date();
      var planExpireAt = new Date(res.data.endAt);
      state.isPlanExpired = (+curentDate.getTime() > +planExpireAt.getTime()) ? true : false;
      setState({ ...state });
      console.log('ers', res.data);
    }

    handleChange('Today');
  }

  const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
  ];


  const openFeaturedUrls = async (data) => {

    if (profileViewAccess == 'yes') {
      if (data == 'profileView') {
        history.push({ pathname: '/admin/profileView/', state: { viewId: '1' } })
      } else if (data == 'productViews') {
        history.push({ pathname: '/admin/profileView/', state: { viewId: '2' } })
      } else if (data == 'fav') {
        history.push({ pathname: '/admin/profileView/', state: { viewId: '3' } })
      }
    } else {
      setplanUpgrade(true);
    }
  }
  const closeplanUpgrade = () => {
    setplanUpgrade(false);
  }
  const openplanUpgrade = () => {
    setplanUpgrade(true);
  }
  const openLinkedInShare = () => {
    window.open('https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fchannelhub.net%3FchannelId%3D' + userData?.channelId, 'Share Profile', 'width=600,height=400');
  }
  return (
    <>
      {planUpgrade && <PlanFeatureDialog closeUserView={closeplanUpgrade} UpgradeType={upgradeType} />}
      <SimpleHeader location={props.match} />
      <div id='bodashboard' className="main-content mt-4">
        <Helmet>
          <meta name="title" content={userData?.companyName} />
          <meta property="og:title" content={userData?.companyName} />
          {/* <meta name="description" content={userData?.productDetail?.usp.replace(/<[^>]+>/g, '')} />
                <meta property="og:description" content={userData?.productDetail?.usp.replace(/<[^>]+>/g, '')} /> */}

        </Helmet>
        <Row>
          <Col className="card-wrapper" lg="5" md="12" sm="12" >
            <Card>
              <CardBody style={{ padding: '1rem' }} >
                <Row>
                  <Col lg="4">
                    <a>
                      <img
                        onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }}
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={userData?.documentUrl ? userData?.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png'} //{require("assets/img/theme/team-1.jpg")}
                        style={{ width: "140px", maxHeight: "140px" }}
                      />
                    </a>
                  </Col>
                  <Col lg="6">
                    <h3 className="mb-2 mt-1 text-muted">Welcome</h3>
                    <h1 className="mb-2">{userData?.firstName + ' ' + userData?.lastName}</h1>
                  </Col>
                  <Col lg="2">
                    {/* <ButtonGroup className='res-link' aria-label="Basic example" role="group">
                      <Button style={{ padding: "0.7rem" }} className="linkedin" type="button">
                        <span className="btn-inner--icon mr-1">
                          <i style={{ color: "white" }} class="fab fa-linkedin-in" aria-hidden="true"></i>
                        </span>
                      </Button>
                      <Button className="active linkedin" type="button" onClick = {openLinkedInShare} >
                        <span className="btn-inner--text">SHARE</span>
                      </Button>
                    </ButtonGroup>  */}
                    {/* <Button
                    className="btn-icon my-2 linkedin"
                   
                    type="button"
                  >
                    <span className="btn-inner--icon mr-1">
                    <i style={{color:"white"}} class="fab fa-linkedin-in" aria-hidden="true"></i>
                    </span>
                    <span className="btn-inner--text">Share</span>
                  </Button> */}
                  </Col>
                </Row>
                <div className="d-flex justify-content-between w-50 mt-3 ml-4">
                  <div className="pointer" onClick={() => history.push('/admin/channel')}>
                    <h2 className="font-weight-600 borders pl-2">{state.leads}</h2>
                    <p className="mt-1 countName">Suggestion</p>
                  </div>

                  <div className="pointer" onClick={() => history.push({ pathname: '/admin/mynetwork', state: { activeTab: "3" } })}>
                    <h2 className="font-weight-600 borders pl-2"> {state.inbound}
                      <i class={state.inbound == 0 ? "" : "fas fa-fire ml-1 mt--2 fire blink"}></i>
                    </h2>
                    <p className="mt-1 countName">Inbound</p>
                  </div>

                  <div className="pointer" onClick={() => openFeaturedUrls('fav')}>
                    <h2 className="font-weight-600 borders pl-2"> {state.favorites} </h2>
                    <p className="mt-1 countName">Favourites</p>
                  </div>
                </div>
                {/* <Row>

                  <Col lg="2" className="mt-3 resm6 pointer" onClick={() => history.push('/admin/channel')}>
                    <h2 className="font-weight-600 borders pl-2">{state.leads}</h2>
                    <p className="mt-1 countName">Suggestion</p>
                  </Col>
                  <Col className="mt-3 resm6 pointer" lg="2" onClick={() => history.push({ pathname: '/admin/mynetwork', state: { activeTab: "3" } })}> 
                  <h2 className="font-weight-600 borders pl-2"> {state.inbound} <i class="fas fa-fire ml-1 fire"></i></h2>
                    <p className="mt-1 countName">Inbound</p></Col>
                  <Col className="mt-3 resm6 pointer"  lg="2" onClick={()=>history.push({pathname:'/admin/mynetwork', state:{activeTab: "2"}})}> <h2 className="font-weight-600 borders pl-2"> {state.outbound} </h2> 
<p className="mt-1 countName">Outbound</p></Col>
                  <Col className="mt-3 resm6 pointer" lg="2" onClick={() => openFeaturedUrls('fav')}> 
                  <h2 className="font-weight-600 borders pl-2"> {state.favorites} </h2>
                    <p className="mt-1 countName">Favourites</p></Col>
                  <Col className="mt-3 resm6 pointer"  lg="2"> <h2 className="font-weight-600 borders pl-2">3</h2> 
<p className="mt-1 countName">Views</p></Col>


                </Row> */}
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8" lg="12">
                    <h1 className="mb-0 text-muted tit-all">Company Status</h1>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="CountBox">



                  <div className="mt-3 curs-point" onClick={() => openFeaturedUrls('profileView')} > <h2 className=" mb-3 pl-2 comp_count text-center">{state.profileViews}</h2>

                    <h2 className="mt-1 countName pb-1 res-h2">Profile Views</h2></div>

                  <div className="mt-3 curs-point" onClick={() => openFeaturedUrls('productViews')} > <h2 className=" mb-3 pl-2 comp_count text-center">{state.productViews}</h2>

                    <h2 className="mt-1 countName pb-1 res-h2">Product Views</h2></div>

                  <div className="mt-3 curs-point" onClick={() => history.push({ pathname: '/admin/settings', state: { dashboard: 'balance' } })} > <h2 className=" mb-3 comp_count text-center"><span>€</span>{state.balance}</h2>

                    <h2 className="mt-1 countName pb-1 res-h2">Balance</h2></div></div>
              </CardBody>
              <ProfileViewConnect isOpen={connect} handleClose={closePopup} title={title} />
            </Card>

          </Col>
          <Col lg="4">
            <Card className="">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h1 className="mb-0 text-muted tit-all">Profile Strength</h1>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      className="btn-neutral"
                      color="secondary"
                      onClick={() => history.push('/admin/PublicProfile/' + userData.channelId)}
                      size="sm"
                    >
                      View Your Public Profile
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row className="align-items-center">
                  <Col xs="6">
                    <div style={{ width: '145px' }}>
                      <ProggressBar width="142px" value={state.profileStrength} />
                    </div>
                  </Col>
                  <Col xs="6">
                    <Button color="secondary" outline type="button"
                      onClick={() => history.push('/admin/channel/channelDetail/' + userData.channelId)}
                    >
                      Increase Your Profile Strength
                    </Button>
                  </Col>
                </Row>

                {/*            
                  <Button
                    color="primary"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    Go somewhere
                  </Button> */}

              </CardBody>
              {/* <Card className="rightCard">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h1 className="mb-0 text-muted">Profile Strength</h1>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        className="btn-neutral"
                        color="secondary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        View Your Public Profile
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                <Row className="align-items-center">
                    <Col xs="6">
                    <div style={{width:'145px'}}>
                <ProggressBar width="142px" value={59}/>
                </div>
                      </Col>
                      <Col xs="6">
                      <Button color="secondary" outline type="button">
                    Increase Your Profile Strength
                  </Button>
                      </Col>
</Row>
             
                </CardBody>
              </Card> */}
            </Card>



          </Col>
        </Row>
        <Row>
          <Col lg="5">
            <Card>
              <CardHeader>
                <h1 className="mb-0 text-muted tit-all">Audience</h1>
                <h6 className="surtitle">Overview</h6>

              </CardHeader>
              <CardBody style={{ minHeight: '500px' }}>
                <div className="chart">
                  <Line
                    data={chartExample3.data}
                    options={chartExample3.options}
                    id="chart-sales"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="8">
                    <h1 className="mb-0 text-muted tit-all">Activity </h1>
                  </Col>
                  <Col xs="4">
                    <UncontrolledDropdown className="p-0" group>
                      <DropdownToggle caret className="p-2">
                        <h3 style={{ display: "inline-block" }} className="font-weight-bold m-0">
                          {activity}
                        </h3>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={e => handleChange('Today')} >Today</DropdownItem>
                        <DropdownItem onClick={e => handleChange('All')}>All</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                </Row>

              </CardHeader>

              <CardBody className='act-bdy'>
                <div

                  className="timeline timeline-one-side"
                  data-timeline-axis-style="dashed"
                  data-timeline-content="axis"
                >

                  {state.profileList?.map(x => (
                    <div onClick={() => profileViewAccess == 'yes' ? history.push({ pathname: '/admin/profileView/', state: { viewId: '1' } }) : openplanUpgrade()} style={{ margin: '1em 0' }} className="timeline-block">
                      <span className={"timeline-step badge-secondary " + (profileViewAccess == 'no' ? 'isConfidential' : '')}>
                        {/* <i className="ni ni-bell-55" /> */}
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="rounded-circle" style={{ width: '61px', height: '61px' }} alt="..." src={x.profile_image ? process.env.REACT_APP_CDN_URL + x.profile_image : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                      </span>
                      <div className={"timeline-content"} >
                        {/* <small className="text-muted font-weight-bold">
                          10:30 AM
                        </small> */}
                        <h5 className={"mt-1 mb-0 " + (profileViewAccess == 'no' ? 'isConfidential' : '')}>{x.name}</h5>
                        <span className={"text-sm mt-1 mb-0 " + (profileViewAccess == 'no' ? 'isConfidential' : '')}>
                          "{x.name}" of "{x.companyName}" </span><span className="text-sm mt-1 mb-0"> visited your company profile
                        </span>

                      </div>
                    </div>
                  ))}

                  {state.productList?.map(x => (
                    <div style={{ margin: '1em 0' }} className="timeline-block">
                      <span className={"timeline-step badge-secondary " + (profileViewAccess == 'no' ? 'isConfidential' : '')}>
                        {/* <i class="fas fa-exclamation-circle"></i> */}

                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="rounded-circle" style={{ width: '61px' }} alt="..." src={x.profile_image ? process.env.REACT_APP_CDN_URL + x.profile_image : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                      </span>
                      <div className="timeline-content">
                        {/* <small className="text-muted font-weight-bold">
                          10:30 AM
                        </small> */}
                        <h5 className={"mt-1 mb-0 " + (profileViewAccess == 'no' ? 'isConfidential' : '')}>{x.name}</h5>
                        <span className={"text-sm mt-1 mb-0 " + (profileViewAccess == 'no' ? 'isConfidential' : '')}>
                          "{x.name}" of "{x.companyName}" visited your company product "{x.product}"
                        </span>
                        <span className="text-sm mt-1 mb-0"> visited your company product </span>
                        <span className={"text-sm mt-1 mb-0 " + (profileViewAccess == 'no' ? 'isConfidential' : '')}>
                          "{x.product}"
                        </span>

                      </div>
                    </div>
                  ))}

                  {state.profile_fav_List?.map(x => (
                    <div style={{ margin: '1em 0' }} className="timeline-block">
                      <span className="timeline-step badge-secondary">
                        <i class="fas fa-exclamation-circle"></i>
                      </span>
                      <div className="timeline-content">
                        {/* <small className="text-muted font-weight-bold">
                          10:30 AM
                        </small> */}
                        <h5 className="mt-1 mb-0">{x.name}</h5>
                        <p className="text-sm mt-1 mb-0">
                          This is user of company {x.companyName} added your company as favourite.
                        </p>

                      </div>
                    </div>
                  ))}

                  {state.product_fav_List?.map(x => (
                    <div style={{ margin: '1em 0' }} className="timeline-block">
                      <span className="timeline-step badge-secondary">
                        <i class="fas fa-exclamation-circle"></i>
                      </span>
                      <div className="timeline-content">
                        {/* <small className="text-muted font-weight-bold">
                          10:30 AM
                        </small> */}
                        <h5 className="mt-1 mb-0">{x.name}</h5>
                        <p className="text-sm mt-1 mb-0">
                          This is user of company {x.companyName} added your product {x.product} as favourite.
                        </p>

                      </div>
                    </div>
                  ))}

                  {!state.productList && !state.profileList && !state.profile_fav_List && !state.product_fav_List &&
                    <div style={{ margin: '1em 0' }} className="timeline-block">
                      <span className="timeline-step badge-secondary">
                        <i class="fas fa-exclamation-circle"></i>
                      </span>
                      <div className="timeline-content">
                        {/* <small className="text-muted font-weight-bold">
                      10:30 AM
                    </small> */}

                        <p className="text-sm mt-1 mb-0">
                          No Activities
                        </p>

                      </div>
                    </div>
                  }

                  {/* <div  style={{margin:'1em 0'}} className="timeline-block">
                      <span className="timeline-step badge-secondary">
                      <i class="fas fa-exclamation-circle"></i>
                      </span>
                      <div className="timeline-content">
                        <small className="text-muted font-weight-bold">
                          10:30 AM
                        </small>
                        <h5 className="mt-1 mb-0">John</h5>
                        <p className="text-sm mt-1 mb-0">
                          Nullam id dolor id nibh ultricies vehicula ut id elit.
                          Cum sociis natoque penatibus et magnis dis parturient
                          montes, nascetur ridiculus mus.
                        </p>
                      
                      </div>
                    </div> */}
                  {/* <div  style={{margin:'1em 0'}} className="timeline-block">
                      <span className="timeline-step badge-secondary">
                        <i className="ni ni-bell-55" />
                      </span>
                      <div className="timeline-content">
                        <small className="text-muted font-weight-bold">
                          10:30 AM
                        </small>
                        <h5 className="mt-1 mb-0">John</h5>
                        <p className="text-sm mt-1 mb-0">
                          Nullam id dolor id nibh ultricies vehicula ut id elit.
                          Cum sociis natoque penatibus et magnis dis parturient
                          montes, nascetur ridiculus mus.
                        </p>
                      
                      </div>
                    </div> */}


                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="rightCard">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h1 className="mb-0 text-muted tit-all">Current Plan</h1>
                  </Col>
                  <Col className="text-right" xs="4">

                    {((+userData.channelTypeId == 2 && +userData.planId === 1) || (+userData.channelTypeId == 2 && state.isPlanExpired == true && +userData.planId > 1)) ?
                      <Button color="secondary" outline type="button" onClick={() => history.push('/admin/Subscription')}>
                        Upgrade
                      </Button>
                      : ''}
                  </Col>
                </Row>
              </CardHeader>




              <CardBody className="p-0">
                <h1 className="text-uppercase text-center">{planDetails.planType}</h1>
                <Row className="justify-content-center">

                  <Col lg="9" className='text-center'>

                    <Button color="secondary" type="button" className='mb-4'>
                      <h2 className='res-plan'>
                        <i class="far fa-calendar mr-2"></i>
                        {(+userData.planId === 1) ? 'LifeTime' : planDetails?.startedAt + ' - ' + planDetails?.end}
                      </h2>
                    </Button>
                    {/* <h2 className="mb-0 text-muted"></h2> */}
                  </Col>
                </Row>


                {featureArr.map(x => (
                  <ListGroup className="list my--3" flush>

                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <h4>{x.title}</h4>
                        </Col>

                        <Col className="col-auto">
                          {x.label}
                        </Col>
                      </Row>
                    </ListGroupItem>

                  </ListGroup>
                ))}

              </CardBody>
            </Card>
          </Col>
        </Row>


      </div>
    </>
  );
}


export default VendorDashboard;