import React, { useState, useEffect } from "react";
import { Card,CardBody,CardHeader, Container,Col,Row,Table } from "reactstrap";
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { history } from '../../../../_helpers/history';
import { subscriptionAction } from '_actions/subscription.action';
import { connect, useSelector  } from 'react-redux';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import SimpleHeader from "components/Headers/SimpleHeader";

const Subscription = (props) => {
    const redux_data = useSelector(state => state.subscription);
    let [loading, setLoading] = useState(true);
    let [data, setData] = useState({planList:[], amountList:[]});
    let [featureLabel, setFeatureLabel] = useState([]);
    let [keys, setKeys] = useState([]);
    let user = JSON.parse(localStorage.getItem('CHuser'));
      useEffect(() => {
        getSubscriptionPlans();
        checkPlanExpiry();
        }, [])
      
      const checkPlanExpiry= async()=>{
        var curentDate = new Date();
        var planExpireAt = new Date(user.planExpireAt);
        var same = +curentDate.getTime() > +planExpireAt.getTime();
        if(same == true && user.channelTypeId  == 2 && user.planId != '1'){
            ToastsStore.error('Your current subscription has been expired, kindly reniwal to enjoy the services');
        } 
      }
      const getSubscriptionPlans=async()=> {
        setLoading(true);
        let userData = JSON.parse(localStorage.getItem('CHuser'));
        let channelTypeId=userData?.channelTypeId;
        let res = await commonService.getServices('channel/subscription/subscriptionPlanAllPeriod/'+channelTypeId).catch(err => {
            setLoading(false);
            ToastsStore.error('Something Went Wrong!');
          commonService.postService({err:err.response.data.message,reqUrl:window.location.pathname,service:'channel/subscription/subscriptionPlanAllPeriod'},'/error')
        });
        let data = res.data;
        let plan=[];
        let amount=[];
        let dd=data.subscriptionPlans;
        featureLabel=data.featureLabel;

        //console.log('Your visibility',featureLabel["Your visibility"]);
        let featureArr = [];
        //featureArr['Yourvisibility']=featureLabel["Your visibility"];
        featureArr['YourOnlinePerformancetool']=featureLabel["Your Online Performance tool"];
        featureArr['Otherfeatures']=featureLabel["Other features"];
        featureArr['LeadGeneration']=featureLabel["Lead Generation"];
        //featureArr['OnlineEvents']=featureLabel["Online Events"];
        // console.log('values x',featureArr.Yourvisibility.values)
        // console.log('values y',featureArr.Yourvisibility.values.label)
        // setFeatureLabel(featureArr);

      //  let Yourvisibility=[];
        let LeadGeneration=[];
      //  let OnlineEvents=[];
        let YourOnlinePerformancetool=[];
        let Otherfeatures=[];

        // for(let h in featureArr.Yourvisibility.values){
        //     Yourvisibility.push(featureArr.Yourvisibility.values[h]);
        //     // featureArr.Yourvisibility["new_values"]= featureArr.Yourvisibility.values[h];
        // }
        
        for(let h in featureArr.YourOnlinePerformancetool.values){
            
            YourOnlinePerformancetool.push(featureArr.YourOnlinePerformancetool.values[h]);
            // featureArr.YourOnlinePerformancetool["new_values"]=featureArr.YourOnlinePerformancetool.values[h];

        }
        for(let h in featureArr.Otherfeatures.values){
            Otherfeatures.push(featureArr.Otherfeatures.values[h]);
            // featureArr.Otherfeatures["new_values"]=featureArr.Otherfeatures.values[h];

        }
        for(let h in featureArr.LeadGeneration.values){
            LeadGeneration.push(featureArr.LeadGeneration.values[h]);
            // featureArr.LeadGeneration["new_values"]=featureArr.LeadGeneration.values[h];

        }
        // for(let h in featureArr.OnlineEvents.values){
        //     OnlineEvents.push(featureArr.OnlineEvents.values[h]);
        //     // featureArr.LeadGeneration["new_values"]=featureArr.LeadGeneration.values[h];

        // }
        featureLabel = {LeadGeneration: LeadGeneration,Otherfeatures: Otherfeatures, YourOnlinePerformancetool: YourOnlinePerformancetool};

        setFeatureLabel({...featureLabel});


        

        /***************** */
        // for(let h in featureLabel.header){
        //     let headerArr = [];
        //     headerArr['id'] = h;
        //     headerArr['label'] = featureLabel.header[h];
        //     headerArr['type'] = 'mb-2';
        //     headerArr['values'] = null;
        //     featureArr.push(headerArr)
        //     for(let ks in featureLabel[h]){
        //         let feaArr = [];
        //         feaArr['id'] = ks;
        //         feaArr['label'] = featureLabel[h][ks];
        //         feaArr['type'] = 'text-center';
        //         feaArr['values'] = featureLabel.val[ks];
        //         featureArr.push(feaArr);
        //     }
        // }
        
        // /***************** */
        // // console.log('featureArr ======= ' , featureLabel['values'][19][1]);
        // console.log('featureArr tot ' , featureLabel);
        // setFeatureLabel(featureArr);
        // keys = Object.keys(featureLabel.header)
        // setKeys(keys);
        
        for(let j in dd) {
            let pDetails = dd[j].planDetails;
            for(let i in pDetails) {
            if(pDetails[i].subscriptionId!== undefined){
            plan.push({
                name:pDetails[i].subscriptionPlan.subscriptionPlanType,
                order:dd[j].period.order,
                subscriptionId:pDetails[i].subscriptionId,
                subscriptionPlanId:pDetails[i].subscriptionPlan.subscriptionPlanId, //pDetails[i].subscriptionPlanId,
                amt:pDetails[i].amount,
                order:pDetails[i]?.subscriptionPlan?.rankOrder,
                subscriptionId:pDetails[i]?.subscriptionId,
                month:dd[j]?.period?.periodDesc,
                title:pDetails[i]?.subscriptionPlan?.subscriptionPlanType,
                });
            amount.push({
                amt:pDetails[i].amount,
                order:pDetails[i]?.subscriptionPlan?.rankOrder,
                subscriptionId:pDetails[i]?.subscriptionId,
                month:dd[j]?.period?.periodDesc,
                title:pDetails[i]?.subscriptionPlan?.subscriptionPlanType,
            });
            }
            }
        }
       
       
        data.planList=plan;
        data.amountList=amount;
        setData({...data});
        setLoading(false);
        //console.log(plan);
        props.subscriptionRedux(plan,'PLAN');
        props.subscriptionRedux(amount,'AMOUNT');
      }

      const Freesub = () => {
     
          subscription(1);
      }
      const subscription =async (plan) => {
         let res = await commonService.postService({
           subscriptionId:1,
           paymentMethodId:plan,
           transactionId:null,
        }, 'channel/subscription/subscribe').catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));

        //console.log("ressult of sub",res);
        if(res){
         // userData.subscriptionId=subId.subscriptionId;
         // localStorage.setItem('CHuser', JSON.stringify(userData));
         let userData = JSON.parse(localStorage.getItem('CHuser'));
         userData.paymentMethodId = plan;
         if(plan != 2){
            
             userData.planId = res.data.planId;
             userData.subscriptionId = +res.data.channelSubscription.subscriptionId;
             userData.featureList = res.data.channelSubscription.keyFeature;
             userData.planExpireAt = res.data.channelSubscription.endAt;
             userData.planName = 'Free' +' - '+ 'Month';
             
               
             await commonService.getServices('algorithm/channel').catch(err =>console.log(err.response.data.message,  ToastsStore.error('unable to create suggestion')));
         }
         localStorage.setItem('CHuser', JSON.stringify(userData));
          setLoading(false);
          history.push('/admin/PaymentSuccess');
        }
        
       }


      const selectPlan=(select_plan)=>{ 
        props.subscriptionRedux(select_plan,'SELECT_PLAN');
        history.push('/admin/Subscription/Payment');

      }
return (
<div className="main-content">
{!props.ratingId && <SimpleHeader location={props.match}/>}
<ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
         {/* <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>
    <Card>
        {loading?<Spinner/>:<CardBody>
            <div className="pre-plan"> 
                <div className="pre-001">     
            <Table borderless responsive className="tab-1">
                <tr>
                    <td className="emp-tr1" style={{verticalAlign:"middle",textAlign:"initial"}} rowspan="2"><h1>Select Your Premium <br/> Plan</h1><hr/></td>
                </tr>
                <tr className="unt-hr">
                {data.planList.map(x=>
                    <td style={{verticalAlign:"middle"}}><h3 className={'text-center '+ (x.subscriptionId === user?.subscriptionId?'active-Plan':'active-dis')}>{x.name}</h3>
                    <hr className={'' + (x.subscriptionId=== user?.subscriptionId?'active-Plan-hr':'') }/>
                    </td>)}
                </tr>
                <tr>
                <td className="emp-tr1" style={{verticalAlign:"middle",textAlign:"initial"}}><h3>Lorem ipsum dolor samt, consectu <br/> adscing elit. Cras eco eter viverra las <br/> nullam elit laboratis</h3></td>
                {data.amountList.map(x=><td className="text-center"><button onClick={()=>selectPlan(x)} type="button" className={"btn " + (x.subscriptionId=== user?.subscriptionId?'bt-pri1':'bt-pri')}><h3 className="pre-rate"> {x.amt}<i class="fas fa-euro-sign"></i>/<span>{x.month}</span></h3> {(x.subscriptionId=== user?.subscriptionId)&&<i class="fas fa-check-circle crow"></i>}</button></td>)}
                </tr>
                {featureLabel?.map(i=>{
                    if(featureLabel){
                        return(
                            <tr>
                            <td className="emp-tr">
                                <div>
                                <h3 className={i.type}>{i.label}</h3>
                                </div>
                            </td>
                            {data.planList.map((x)=>{
                                if(i.values != null){
                                return(<td><div>{i.values[x.subscriptionId]}</div></td>)
                                }
                               })
                            }
                            </tr>
                        )
                    }
                })}
            </Table>
                </div>
            </div>           
        </CardBody>}
    </Card>
              </Container> */}
     
    {loading?<Spinner/>: <div className="mt-4 planpading">
  
      
    <Card className="stickyPay">
        <CardBody>
        {/* { user?.planId == 3? 
            <Row className="text-center" style={{'background-color':'#ff9933'}}>
            <Col lg="12" className="text-center">
                <h2 >Trial Plan</h2>
                You are enjoying a free month full access to the Premium version of ChannelHub platform!
            </Col>
            </Row>
            :''} */}
        <Row>
            <Col lg="6">
            <h1 className="text-left planwidth">Select Your <span className="planBorder">Plan</span></h1>
            { user?.planId == 3?
            <div><span>Current Plan : <h2 >Trial Plan <i class="fas fa-fire ml-1 fire"></i> </h2> </span>
            <span>You are enjoying a free month full access to the Premium version of ChannelHub platform!</span></div>
            :''}
            </Col>

            {data.planList.map(x=>
                    
                    <Col lg="3" className="text-center">
                    <h2 className={"mb-3 " + ((x.subscriptionPlanId == user?.planId) || (+x.subscriptionPlanId == 6 && +user?.planId == 3) ?'active-Plan':'active-dis')  }>{x.name}</h2>
                <button onClick={()=>{(x.subscriptionId == 1)? Freesub():selectPlan(x)}} type="button" className={"btn " + ((x.subscriptionPlanId== user?.planId) || (+x.subscriptionPlanId == 6 && +user?.planId == 3)?'bt-pri1':'bt-pri')}><h3 className="pre-rate">{(x.amt)/12}<i class="fas fa-euro-sign"></i>/<span> {(+x.subscriptionId == 1)?'Lifetime': 'Monthly'} </span>
                <span style={{fontSize:'10px'}}>{(+x.subscriptionId == 1)?'':'(* Billed Annually)'}</span></h3></button>
                {/* x.month */}
                {/* <p className="mb-0 mt-3">Get a chance to Meet a</p> */}
                </Col>        

                    )}

            {/* <Col lg="3" className="text-center">
                <h2 className="mb-3">Plan A</h2>
            <button type="button" className="btn bt-pri"><h3 className="pre-rate">0.00<i class="fas fa-euro-sign"></i>/<span>Free</span></h3></button>
            <p className="mb-0 mt-3">Get a chance to Meet a</p>
            </Col>
            <Col lg="3" className="text-center">
            <h2 className="mb-3">Plan B</h2>
            <button type="button" className="btn bt-pri"><h3 className="pre-rate">1500.00<i class="fas fa-euro-sign"></i>/<span>Year</span></h3></button>
            <p className="mb-0 mt-3">Engage with day to</p>
            </Col> */}
        </Row>
        </CardBody>
    </Card>


  <Card className="mb-3">
  <CardHeader className="borderColor">
   
   <Row>
           <Col lg="6">
           <h2 className="text-left mb-0">Lead Generation</h2>
           </Col>
       </Row>
   </CardHeader>
   <CardBody>
   <Row>
            <Col lg="6">
           <div className="planLeft">
<Row>

{!!featureLabel.LeadGeneration?.length && featureLabel.LeadGeneration.map(x => (
    <Col lg="12">
    <p className="mt-2 mb-2 pb-3">{x.label}</p>
    </Col>
   ))

   }

    {/* <Col lg="12">
    <p className="mt-2 mb-2 pb-4">Buyers can send you connection requests
</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">Access to our database of buyers with their company profile
</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">Suggestion of buyers

</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">Save profiles to your favourites

</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">Your favourites buyers invited for our event

</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">Your favourites buyers notified when they connect to their account

</p>
    </Col> */}
</Row>

           </div>
            </Col>
            <Col lg="3" className="text-center padInner">
              <div className="planBox">
              <Row>

              {!!featureLabel.LeadGeneration?.length && featureLabel.LeadGeneration.map(x => (
                    <Col lg="12">
                    <p className="mt-2 mb-2 pb-31">{x.premium}</p>
                    </Col>
                ))

                }

    {/* <Col lg="12">
    <p className="mt-2 mb-2">1 free, rest is paying using your ewallet
</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">no</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">no</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">no</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">no</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2 pb-4">no</p>
    </Col> */}
</Row>
              </div>
            </Col>
            <Col lg="3" className="text-center">
            <div className="planBox">
            <Row>
            {!!featureLabel.LeadGeneration?.length && featureLabel.LeadGeneration.map(x => (
                    <Col lg="12">
                    <p className="mt-2 mb-2 pb-31">{x.free}</p>
                    </Col>
                ))

                }
            

    {/* <Col lg="12">
    <p className="mt-2 mb-2 pb-4">yes</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">yes</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">yes
</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">yes</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">yes
</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2 pb-4">yes
</p>
    </Col> */}
</Row>
            </div>
            </Col>
        </Row>
       
   </CardBody>
   
  </Card>
  {/* <Card className="mb-3">
  <CardHeader className="borderColor">
   
   <Row>
           <Col lg="6">
           <h2 className="text-left mb-0">Online Events</h2>
           </Col>
       </Row>
   </CardHeader>
   <CardBody>
   <Row>
            <Col lg="6">
           <div className="planLeft">
<Row>

{!!featureLabel.OnlineEvents?.length && featureLabel.OnlineEvents.map(x => (
    <Col lg="12">
    <p className="mt-2 mb-2 pb-3">{x.label}</p>
    </Col>
   ))

   }

    
</Row>

           </div>
            </Col>
            <Col lg="3" className="text-center padInner">
              <div className="planBox">
              <Row>

              {!!featureLabel.OnlineEvents?.length && featureLabel.OnlineEvents.map(x => (
                    <Col lg="12">
                    <p className="mt-2 mb-2 pb-31">{x.premium}</p>
                    </Col>
                ))

                }

   
</Row>
              </div>
            </Col>
            <Col lg="3" className="text-center">
            <div className="planBox">
            <Row>
            {!!featureLabel.OnlineEvents?.length && featureLabel.OnlineEvents.map(x => (
                    <Col lg="12">
                    <p className="mt-2 mb-2 pb-31">{x.free}</p>
                    </Col>
                ))

                }
            

    
</Row>
            </div>
            </Col>
        </Row>
       
   </CardBody>
   
  </Card> */}
  <Card className="mb-3">

  <CardHeader className="borderColor">
   
   <Row>
           <Col lg="6">
           <h2 className="text-left mb-0">Your Online Performance tool</h2>
           </Col>
       </Row>
   </CardHeader>
   <CardBody>
   <Row>
            <Col lg="6">
           <div className="planLeft">
<Row>

    {!!featureLabel.YourOnlinePerformancetool?.length && featureLabel.YourOnlinePerformancetool.map(x => (
        <Col lg="12">
        <p className="mt-2 mb-2 pb-3">{x.label}</p>
        </Col>
    ))
    }

    {/* <Col lg="12">
    <p className="mt-2 mb-2 pb-5">dashboard with profile view and product view
</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2 pb-5">Your company added in "Follow this brand"
</p>
    </Col> */}
   
</Row>

           </div>
            </Col>
            <Col lg="3" className="text-center padInner">
              <div className="planBox">
              <Row>
              {!!featureLabel.YourOnlinePerformancetool?.length && featureLabel.YourOnlinePerformancetool.map(x => (
                <Col lg="12">
                <p className="mt-2 mb-2 pb-3">{x.premium}</p>
                </Col>
                ))
                }
              
                  
    {/* <Col lg="12">
    <p className="mt-2 mb-2 pb-4">Stats available but no viewer's details
</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2 pb-4">Stats available but no viewer's details
</p>
    </Col>
    */}
</Row>
              </div>
            </Col>
            <Col lg="3" className="text-center">
            <div className="planBox">
            <Row>

            {!!featureLabel.YourOnlinePerformancetool?.length && featureLabel.YourOnlinePerformancetool.map(x => (
                <Col lg="12">
                <p className="mt-2 mb-2 pb-3">{x.free}</p>
                </Col>
                ))
                }
           


    {/* <Col lg="12">
    <p className="mt-2 mb-2">yes with viewer's details and possible to send a connection request
</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">yes with viewer's details and possible to send a connection request
</p>
    </Col>
    */}
</Row>
            </div>
            </Col>
        </Row>
       
   </CardBody>
  
  </Card>
    <Card className="mb-3">
    <CardHeader className="borderColor">
   
   <Row>
           <Col lg="6">
           <h2 className="text-left mb-0">Other features</h2>
           </Col>
       </Row>
   </CardHeader>
   <CardBody>
   <Row>
            <Col lg="6">
           <div className="planLeft">
<Row>

    {!!featureLabel.Otherfeatures?.length && featureLabel.Otherfeatures.map(x => (
        <Col lg="12">
        <p className="mt-2 mb-2">{x.label}
    
    </p>
        </Col>    
    ))

    }

    {/* <Col lg="12">
    <p className="mt-2 mb-2">Number of users who can receive leads

</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">Dedicated account Manager

</p>
    </Col> */}
   
</Row>

           </div>
            </Col>
            <Col lg="3" className="text-center padInner">
              <div className="planBox">
              <Row>
              {!!featureLabel.Otherfeatures?.length && featureLabel.Otherfeatures.map(x => (
                    
                    <Col lg="12">
                    <p className="mt-2 mb-2">{x.premium}</p>
                    </Col>
                ))

                }

             

    {/* <Col lg="12">
    <p className="mt-2 mb-2">1</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">no</p>
    </Col> */}
   
</Row>
              </div>
            </Col>
            <Col lg="3" className="text-center">
            <div className="planBox">
            <Row>
            {!!featureLabel.Otherfeatures?.length && featureLabel.Otherfeatures.map(x => (
                    
                    <Col lg="12">
                    <p className="mt-2 mb-2">{x.free}</p>
                    </Col>
                ))

                }
           
    {/* <Col lg="12">
    <p className="mt-2 mb-2">10</p>
    </Col>
    <Col lg="12">
    <p className="mt-2 mb-2">yes</p>
    </Col> */}
   
</Row>
            </div>
            </Col>
        </Row>
       
   </CardBody>

    </Card>
    </div>}
      </div>
    
   
  );
};

function mapState(state) {
   // console.log('state', state);
    const general_data= state.subscription;
    return general_data;
}

const actionCreators = {
    subscriptionRedux: subscriptionAction.subscriptionRedux
};

export default connect(mapState,actionCreators)(Subscription); //AddgeneralForm;