import React ,{useEffect, useState} from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody, ListGroupItem, ListGroup, Row, Col } from "reactstrap";
import axios from 'axios';
import { ModalSpinner} from 'views/Hoc/Spinner';
import { commonService } from '_services/common.service';
import { Aws_service } from '.../../_services/aws-s3.service';
import s from 'react-aws-s3';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { userConstants } from '../../../../src/_constants';
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

var FileSaver = require('file-saver');
export  const ImportProductDialog=(props)=> {

  const { closeImprtProductDialog, Open } = props;
  const [state,setState]=useState({
        featureList:[]
  });
  let [downloadProcesTime, setDownloadProcesTime] = useState(1);
  let [downloadingText, setDownloadingText] = useState(null);

  const [dialogLoading,setLoading] = useState(false);
  const [fileBUffer,setFileBUffer] = useState(null);
  const [uploadedPath,setUploadedPath] = useState(null);
  const [uploadedZipFile,setUploadedZipFile] = useState(null);
  const [uploadedXLXSFile,setUploadedXLXSFile] = useState(null);
  const [falureDownloadLink,setFalureDownloadLink] = useState(null);
  const [productLimit, setProductLimit] = useState(true);
  const [csvFileName,setCSVFileName] = useState(null);
  const [zipFileName,setZIPFileName] = useState(null);

  const [importStatus, setImportStatus] = useState(null);
  const [loaderDIv, setLoaderDIv] = useState('none');
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  useEffect(() => {
    setLoaderDIv('none')
    setLoading(false);
    //getProductLimit();
    setImportStatus('');
    (async() => {            
      
    })();
    //document.getElementById('loaderDIv').style.display = 'none';
  }, []);

  const handleClick = event => {
    document.getElementById('xlsxFIle').click();
  };

  const handleZIPClick = event => {
    document.getElementById('zip').click();
  };
  
  const getProductLimit = async() => {
    // await commonService.getServices('product/productlimit').then(res =>{ 
    //   if(res.data.allowed){
    //     setProductLimit(res.data.allowed);
    //   }     
    // });     
    
    let user = JSON.parse(localStorage.getItem('CHuser'));
    let featureList = user.featureList
    
    // if (featureList && featureList[userConstants.NO_OF_PRODUCT]) {
    //   setProductLimit(featureList[userConstants.NO_OF_PRODUCT]);
    // }

  }
  const handleChange = async(input, data, filename) => {
    var extension = filename.substring(filename.lastIndexOf('.')+1);
    let s3Path = null;
    if(input == "xlsx") {
      if(extension === 'xlsx'){
          s3Path = 'productImportCSV';
          setUploadedXLXSFile(filename);
        } else {
          ToastsStore.error('Invalid extentions for xlsx file');
        }
        
      } else {
        if(extension === 'zip'){
          s3Path = 'productImportZIP'
          setUploadedZipFile(filename)
        } else {
          console.log('invalid extentions for  zip');
          ToastsStore.error('Invalid extentions for zip file');
        }
       
      }  
     
    if(data && s3Path){
      //tabLoading(true);
      let res = await handleOnDrop(input, data, filename);
      if(res){
         console.log('res', res);
         // mediaState[input]=value;
         // setMediaState({...mediaState})
      };
    }
  };

  const handleOnDrop = async(input, data, file_name) => {
    if(data){
      //tabLoading(true);
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {  
        selectImg(input, reader.result, file_name)
      };
    }
  }
  
  function GenerateRandomString(len){
    let text = '';
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (let i = 0; i < len; i++) {
      text += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return text;
  }

  const selectImg = async(input, data, filename)=>{
    if(data.length>0){
      var extension = filename.substring(filename.lastIndexOf('.')+1);
      var random = GenerateRandomString(8) + '_' + new Date().getTime(); 
      var fileName =random +'.'+extension;
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      

      let  csvFile = 'productImportCSV'
      if(extension === 'zip'){
        csvFile = 'productImportZIP';
        setZIPFileName(fileName);
      }  else {
        document.getElementById('importButton').style.display = 'block';
        setCSVFileName(fileName);
      }

      if(csvFile){
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/productFile/'+csvFile).catch(err => {
        //commonService.postService({err:err.response.data.message,reqUrl:window.location.pathname,service:'/mediaupload/upload/'},'/error')
        if(resImagePath == 'error'){
          ToastsStore.error('Error in file Upload');
        } else {
          ToastsStore.success(filename +' uploaded successfully ');
        }
      });
      }
      
    }
  }


  function dataURLtoFile(dataurl, fileName) {  
    var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), 
    n = bstr.length, 
    u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type:mime});
  }

  

  const closeOpens=(e)=>{
    e.preventDefault();
    setUploadedXLXSFile('');
    setImportStatus('');
    setZIPFileName('');
    closeImprtProductDialog(false);
  }


  const productImport = async() => {
    let fData = {
      "fileName": csvFileName,
      "filePath": "product-import-csv-s3",
      "newImageFolder": null
    };
   
    setLoaderDIv('block')
    setDownloadProcesTime(0);
    //setLoading(true);
    if(zipFileName){
        setTimeout(function () {
          setDownloadProcesTime(20);
        }, 200);
          let iData = {
            "fileName": zipFileName,
          };
          const imgPath =  await commonService.postService(iData, 'productImport/imageUpload');
              if(imgPath){
                fData = {
                  "fileName": csvFileName,
                  "filePath": "product-import-csv-s3",
                  "newImageFolder": imgPath.data.newPath
                };
                setDownloadProcesTime(60);
                setTimeout(function() {
                  
                  productCSVUpload(fData);
                }.bind(this), 10000)
              }
                
    } else {  
            
          productCSVUpload(fData);
    }
  }

  const productCSVUpload = async(fData) => {
    setTimeout(function () {
      setDownloadProcesTime(10);
    }, 300);
    setImportStatus('import started..');
    await commonService.postService(fData, 'productImport/import').then(resPath => {
      let result = resPath.data;
     
        if(result.response){
          setLoaderDIv('none');
          let importStatus = result.message
          setImportStatus(importStatus);
        }   else {
            const successCnt = result.uploadedCount;
            const failureCnt = result.uninsertedCount;
            const totalCnt = result.totalCount;
            const uploadedProduct = result.uploaded;
            let importStatus = 'File Uploaded, click import button again'
            if(successCnt >= 0){
              
              if(uploadedProduct.length > 0){
                for (let i = 0; i < uploadedProduct.length; i++) {
                  //console.log(uploadedProduct[i]);
                  let maildata = {
                    name: userData?.firstName + ' ' + userData?.lastName,
                    email: userData?.email,
                    approvalType: 1,
                    productName: uploadedProduct[i].product,
                    companyName: userData?.companyName
                  }
                  axios.post('mailerservice/productApprovalRequestMail', maildata);
                } 
              }
              
              setDownloadProcesTime(100);
              importStatus = 'Totally ' + successCnt + ' records successfully processed out of ' + totalCnt + '.';
            } 
            if (failureCnt > 0) {
            // setDownloadProcesTime(0);
              setFalureDownloadLink(result.uninsertedFileName);
              importStatus = importStatus + ' Failure records ' + failureCnt + '. Check downloaded file.';
              downloadFailureFile('/sampleDwn/' , result.uninsertedFileName)
            } 
            // setLoading(false);
            // document.getElementById('loaderDIv').style.display = 'none';
            setLoaderDIv('none')
            setImportStatus(importStatus);
        }
    }).catch( error => {
        let importStatus = 'Something Went Wrong, try again'
        setLoaderDIv('none');
        
        setImportStatus(importStatus);
        //console.log('result === ', error.message);
        return Promise.reject(error.message);
        console.log(error.message);
        if (error.message) {
        }
      });  
     
  }

  const downloadFailureFile = async(sampleDownloadLink, uninsertedFileName) => {
    if (uninsertedFileName) {
        setLoading(false);
        axios.get('productImport'+sampleDownloadLink+ uninsertedFileName, { responseType: 'arraybuffer'})
            .then((response) => {
             let  fileName = uninsertedFileName;
            
              var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              FileSaver.saveAs(blob, uninsertedFileName);
              setLoading(false);
            });
    
    } else {
      console.log('No sample file name, retrying.');
    }

  }

  return (
    <div>
    {dialogLoading? <ModalSpinner />:(
      <Modal
          className="modal-dialog-centered"
          isOpen={Open}
         size="md"
        >
          <div className="modal-header">
          <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
           <h1>Start Your Import</h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={e=> closeOpens(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
          
            <Card className="w-100 mb-0">
            <CardBody>
              {/* <div>
                Products without images will be in inactive status on completion of import. 
                After importing, please edit the product from the product screens and add images to make them active.
              </div> */}
              <Row>
                <Col lg="3"></Col>
                  <Col lg="6" className="text-center">
                      <div onClick={handleClick}>
                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/import/xlsx.png")}/>
                        <br></br>
                        <input type="file"
                              id="xlsxFIle"
                              name="xlsupload"
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              onChange={(e)=>handleChange( 'xlsx',e.target.files[0], e.target.files[0].name)}
                              style={{display:'none'}} 
                        /> 
                      </div>
                      <span>{uploadedXLXSFile}</span>
                  </Col>
                  <Col lg="3"></Col>
                  {/* <Col lg="6">
                      <div onClick={handleZIPClick}>
                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/import/img.png")}/>
                      <input type="file"
                            id="zip"
                            name="zipupload"
                            accept=".zip"
                            onChange={(e)=>handleChange( 'zip',e.target.files[0], e.target.files[0].name)}
                            style={{display:'none'}} 
                      /> 
                    </div>
                    <span>{uploadedZipFile}</span>
                  </Col> */}
              </Row> 
              <Row>
                  <Col lg="12" align="center" id="importButton" style={{display:'none'}} >
                    <button type="submit" onClick={productImport} className="btn btn-primary">Import</button> 
                  </Col>
              </Row> 
              <Row>
              <Col lg="12" align="center" style={{display:loaderDIv}}>
              <ProgressBar
                percent={downloadProcesTime}
                filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
              /> 
              </Col>
              </Row>

              <Row>
              <Col lg="12" align="center" >
                  <h6>{importStatus}</h6>
              </Col>
              </Row>

            </CardBody>
            </Card>
        </div>
       
        
        </Modal>
        
    )}  
    </div>
  );
}
export  default ImportProductDialog;