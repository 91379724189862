import React ,{useEffect, useState} from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody, ListGroupItem, ListGroup, Row, Col } from "reactstrap";
import axios from 'axios';
import { Spinner} from 'views/Hoc/Spinner';


export  const ChangeContact=(props)=> {

  const {   closeContact, Open, setDefaultContact } = props;
  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg';

  const [state,setState]=useState({
        contacts:[],         
        setDefault:'',
  });
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    if(Open){
    setLoading(true);
    
    (async() => {            
      await axios.get('user/allcontacts').then(res =>{
          console.log("allcontact:",res);          
          setState({...state,contacts:res.data});
          setLoading(false);
      });
    })();
  }
  }, [Open]);


  const closeOpens=(e)=>{
    e.preventDefault();
    closeContact(false);
}




  return (
    <div>
    {loading? <Spinner />:(
      <Modal
          className="modal-dialog-centered"
          isOpen={Open}
         size="md"
        >
          <div className="modal-header">
           <h1>Contacts</h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={e=> closeOpens(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
        <Card className="w-100 mb-0">
        <h3 className="text-center">See all Contacts</h3>
        <CardBody>
       
            {state.contacts.map(x => (
                  <ListGroup className="list my--3" flush>
                    
                      <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a className="avatar rounded-circle" href="#pablo" onClick={e => e.preventDefault()}>
                          <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt="..." src={x.contact.document?process.env.REACT_APP_CDN_URL+x.contact.document.documentPath:process.env.REACT_APP_CDN_URL+'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                          </a>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                            {x.contact.firstName+" "+x.contact.lastName}
                              <a className="avatar flag rounded-circle ml-2">
                            {/* <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt="..." src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/gb.svg"/> */}
                            {/* <CountryFlag code={contact.}/> */}
                          </a>
                            </a>
                          </h4>
                          <small>{x.contact.jobTitle}</small>
                        </div>
                        <Col className="col-auto">
                         
                          {x.contact.isDefaultContact != '1' && <Button color="primary" size="sm" type="button"  onClick={() => {setDefaultContact({changedUser:x.userId,changedContact:x.contact.contactId})}} >
                          Set as Default
                          </Button>}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  
                    </ListGroup>
              ))}

        </CardBody>
        </Card>


          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={e=>closeOpens(e)}
            >
              Close
            </Button>
           
          </div>
        </Modal>
    )}
    </div>
  );
}
export  default ChangeContact;