import React, { useState, useEffect } from 'react';
import { FormCheckBox } from "_components/FormElements/FormInput";
import { commonService } from "../../../_services/common.service";
import { Button, Modal } from "reactstrap";
import { FormTextArea } from "_components/FormElements/FormInput";

export const ChannelDismiss = (props) => {
  let [dismissReasons, setDismissReasons] = useState([]);
  let [selectIndex, setSelectIndex] = useState(null);
  let [otherreasons, setOtherreasons] = useState('');
  const { isOpen, handleClose, updateChannel, value, dismissFrom } = props;
  useEffect(() => {

    //getChannelDismiss();
  }, []);
  const getChannelDismiss = async () => {
    let res = await commonService.getServices('/channel/dimissReasons/');
    if (res) {
      dismissReasons = res.data.filter(i => parseInt(i.relationId) === 1);
    }
    setDismissReasons(dismissReasons);
  }
  const handleChange = (index, data) => {
    console.log(index, data);
    selectIndex = index;
    setSelectIndex(selectIndex);
  };
  const conform = () => {
    console.log('value', value);
    value.dismissreasonid = 6;//parseInt(selectIndex);
    //if (value.dismissreasonid === 6 || value.dismissreasonid === 11)
    value.otherreasons = "Default Dismiss";
    updateChannel(value);
  }
  const dismissChange = e => {
    console.log(e.target.value)
    otherreasons = e.target.value;
    setOtherreasons(otherreasons);
    console.log('otherreasons', otherreasons);
  }
  return (
    <div>
      <Modal contentClassName="" className="modal-dialog-centered" isOpen={isOpen} >
        <div className="modal-header chldismiss">
          <h1 >Contact Dismiss</h1>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="modal-body text-left ">
          <div className="text-left mb-2">

            <h3> {'Do you want to dismiss this contact from ' + dismissFrom} ?
            </h3>

          </div>


          {/* {dismissReasons.map((x, i)=>{
                        return(<div className="chck">
                                <FormCheckBox
                                id={x.dismissreasonid}
                                type="checkbox"
                                label={x.dismissreason}
                                label1={{textAlign:"justify"}}
                                value={x.dismissreasonid}
                                checked={selectIndex === x.dismissreasonid }
                                // register={register}
                                onClick={()=>handleChange(x.dismissreasonid,x)}
                                />
                        </div>)
                      })} */}
          {/* <div className="form-group mx-auto text-center">
            {(selectIndex === '6' || selectIndex === '11') && <form>
              <FormTextArea
                className="formtxtarea1"
                name="otherreasons"
                id="otherreasons"
                rows="3"
                type="textarea"
                label=""
                placeholder="Add Your Comments"
                // register={register}
                value={otherreasons}
                onChange={dismissChange}
              />
            </form>}
          </div>  */}
        </div>
        <div className="modal-footer ">
          <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Cancel</Button>
          <Button className='btn btn-primary' color="default" type="button" onClick={conform}>Confirm</Button>
        </div>
      </Modal>
    </div>
  );
}