import React, { useState, useEffect } from "react";
import { Card,CardBody,CardHeader, Container,Col,Row,Table } from "reactstrap";
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { history } from '../../../../_helpers/history';
import { subscriptionAction } from '_actions/subscription.action';
import { connect, useSelector  } from 'react-redux';
const Subscription = (props) => {
    const redux_data = useSelector(state => state.subscription);
    let [loading, setLoading] = useState(true);
    let [data, setData] = useState({planList:[], amountList:[]});
    let [featureLabel, setFeatureLabel] = useState([]);
    let [keys, setKeys] = useState([]);
    let user = JSON.parse(localStorage.getItem('CHuser'));
      useEffect(() => {
        getSubscriptionPlans();
        }, [])

      const getSubscriptionPlans=async()=> {
        setLoading(true);
        let userData = JSON.parse(localStorage.getItem('CHuser'));
        
        let res = await commonService.getServices('auth/subscriptionPlanAllPeriod/2').catch(err => setLoading(false));
        let data = res.data;
        let plan=[];
        let amount=[];
        let dd=data.subscriptionPlans;
        featureLabel=data.featureLabel;

        let featureArr = [];
        /***************** */
        for(let h in featureLabel.header){
            let headerArr = [];
            headerArr['id'] = h;
            headerArr['label'] = featureLabel.header[h];
            headerArr['type'] = 'mb-2';
            headerArr['values'] = null;
            featureArr.push(headerArr)
            for(let ks in featureLabel[h]){
                let feaArr = [];
                feaArr['id'] = ks;
                feaArr['label'] = featureLabel[h][ks];
                feaArr['type'] = 'text-center';
                feaArr['values'] = featureLabel.val[ks];
                featureArr.push(feaArr);
            }
        }

        setFeatureLabel(featureArr);
        keys = Object.keys(featureLabel)
        setKeys(keys);
        for(let j in dd) {
            let pDetails = dd[j].planDetails;
            for(let i in pDetails) {
            if(pDetails[i].subscriptionId!== undefined){
            plan.push({
                name:pDetails[i].subscriptionPlan.subscriptionPlanType,
                order:dd[j].period.order,
                subscriptionId:pDetails[i].subscriptionId,
                subscriptionPlanId:pDetails[i].subscriptionPlanId
                });
            amount.push({
                amt:pDetails[i].amount,
                order:pDetails[i]?.subscriptionPlan?.rankOrder,
                subscriptionId:pDetails[i]?.subscriptionId,
                month:dd[j]?.period?.periodDesc,
                title:pDetails[i]?.subscriptionPlan?.subscriptionPlanType,
            });
            }
            }
        }  
        data.planList=plan;
        data.amountList=amount;
        setData({...data});
        setLoading(false);
        
        props.subscriptionRedux(plan,'PLAN');
        props.subscriptionRedux(amount,'AMOUNT');
      }
      const selectPlan=(select_plan)=>{ 
        props.subscriptionRedux(select_plan,'SELECT_PLAN');
        history.push('/admin/Subscription/Payment');

      }
return (
<div className="main-content">
        <Container className=" col-lg-12 col-md-9 col-sm-12 mt-3">
          
    <Card>
        {loading?<Spinner/>:<CardBody>
            <CardHeader>
    <h1 className='text-uppercase text-center'>Plan And Pricing</h1>
  </CardHeader>
            <div className="pre-plan"> 
                <div className="pre-001">     
            <Table borderless responsive className="tab-1">
                <tr>
                    <td className="emp-tr1" style={{verticalAlign:"middle",textAlign:"initial"}} rowspan="2"><h1>Select Your Premium <br/> Plan</h1><hr/></td>
                </tr>
                <tr className="unt-hr">
                {data.planList.map(x=>
                    <td style={{verticalAlign:"middle"}}><h3 className={'text-center '+ 'active-Plan'}>{x.name}</h3>
                    <hr className={'active-Plan-hr' }/>
                    </td>)}
                </tr>
                <tr>
                <td className="emp-tr1" style={{verticalAlign:"middle",textAlign:"initial"}}><h3>Lorem ipsum dolor samt, consectu <br/> adscing elit. Cras eco eter viverra las <br/> nullam elit laboratis</h3></td>
                {data.amountList.map(x=><td className="text-center"><button  type="button" className={"btn " + 'bt-pri1'}><h3 className="pre-rate"> {x.amt}<i class="fas fa-euro-sign"></i>/<span>{x.month}</span></h3> {}</button></td>)}
                </tr>
               

                {featureLabel?.map(i=>{
                    if(featureLabel){
                        return(
                            <tr>
                            <td className="emp-tr">
                                <div>
                                <h3 className={i.type}>{i.label}</h3>
                                </div>
                            </td>
                            {data.planList.map((x)=>{
                                if(i.values != null){
                                return(<td><div>{i.values[x.subscriptionId]}</div></td>)
                                }
                               })
                            }
                            </tr>
                        )
                    }
                })}
            </Table>
                </div>
            </div>           
        </CardBody>}
    </Card>
              </Container>
      </div>
    
   
  );
};

function mapState(state) {
    console.log('state', state);
    const general_data= state.subscription;
    return general_data;
}

const actionCreators = {
    subscriptionRedux: subscriptionAction.subscriptionRedux
};

export default connect(mapState,actionCreators)(Subscription); //AddgeneralForm;