import React from 'react';
import {FormInput,FormButton, FormCheckBox} from "_components/FormElements/FormInput";
// reactstrap components
import Logo from 'views/pages/LoginPage/login-logo.js';
export const ForgotPassword = () => {
  return (
    <div className="main-content">
      <Logo/>
    <div className="header signup-header-bg py-4 py-lg-6 pt-lg-7">
      <div className="container">
        <div className="header-body text-center mb-7">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-6 col-md-12 px-5">
              <h1 className="text-white">Forgot Password!</h1>
              {/* <p className="text-lead text-white">
              
              </p> */}
              <span style={{color:'#5dfdf6'}}>Can't Signin your account,Forgot your password</span>
            </div>
          </div>
        </div>
      </div>
      <div className="separator separator-bottom separator-skew zindex-100">
        {/* <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg> */}
      </div>
    </div>

    <div className="container mt--8 pb-5">
    <div className="row justify-content-center">
    <div className="col-lg-10 col-md-12">
    <div className="card bg-secondary border-0 mb-0">  
  
    <div className="card-body px-lg-5 py-lg-5">     

    <div className="header-body text-center mb-5">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-10 col-md-12 px-5">
            {/* <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={require("assets/img/brand/verified.png")}/>  */}
            <div className="row">
            <div className="col-lg-2"></div>
              <div className="col-lg-8">
              <h4 className="text-black text-lead text-marg">We will send you reset password instructions to your registered Email address,</h4>
              <h4>Please enter your Email</h4>
              </div>
              <div className="col-lg-2"></div>
              </div>
              <div className="row">
                <div className="col-lg-3"></div>
              <div className="col-lg-6">
              <div className="form-group">
              <FormInput
            id="companyemail" 
            type="email" 
            label="Enter your Email" 
            name="companyemail" 
            // register={register}
            // value={email}
            // onChange={handleChange('email')}
            // error={errState.email!=''?{message:errState.email,type:'required'}:errors.companyemail}
         />
        </div>
        </div>
        <div className="col-lg-3"></div>
        </div>
        <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-6">
        <div className="form-group">
        <FormButton
                  className="btn-sin btn btn-primary"
                 id="Resetbutton"
                 name="Resetbutton"
                 value="Reset Password" 
      />
          </div></div>
          <div className="col-lg-3"></div>
          </div>    
        <h5 className="text-black text-lead">Still you can't signin or you facing any other issues.<span style={{color:'orange'}}>Contact Support</span></h5>
            </div>
          </div>
        </div>
                 </div>
             
              </div>
              </div>
              </div>
              </div>
              </div> 
              
  );
}
export default ForgotPassword;