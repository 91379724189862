import React, {useEffect, useState} from "react";
import {UncontrolledTooltip} from "reactstrap";

import { Button,
Card,
CardHeader,
CardBody,
CardFooter,
CardImg,
CardImgOverlay,
CardTitle,
CardText,
ListGroupItem,
ListGroup,
Badge,
Row,
Col } from "reactstrap";
import Footer from "./Footer"
import "../../../../assets/scss/custom/guestpage.scss"
import { Container } from "@material-ui/core";
import axios from "axios";
import {history} from "_helpers/history";

import Navbarg from "components/Navbars3/GuestNavbar";
const GuestSection1 = () => {

  const unqual={height: '450px'};
  const [data, setData] = useState([]);
  const [prod, setProd] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  useEffect(() => {
    getBuyers();
    getProducts();
  }, []);

  const getBuyers = async() => {
    setLoading(true);
    await axios.get('guest/buyershighlight/1').then(res => {
      setData(res.data);
    }).catch(er => setLoading(false));
    setLoading(false);
  }

  const getProducts = async() => {
    setLoading1(true);
    await axios.get('guest/productshighlight').then(res => {
      let temp = [];
      if(res.data.length){
        res.data.map(x => {
          if(x.images.length){
            x.images.map(y => {
              if(y.documentTypeId == 1)
                x['documentPath'] = y.documentPath;
            })
          }
          if(x.productTag.length > 2){
            x.productTag = x.productTag.slice(0, 2);
          }
          x.brandImg = x.brand?.document?.documentPath;
          temp.push(x);
        });
        let group=[];
        let val=[];
        temp.map((x,i)=>{
          val.push(x);
          console.log((i+1));
          if((i+1)%3===0){
            prod.push({value:val});
            setProd(prod);
            val=[];
          }
        })
        // console.log('prod', prod);
        // prod=group
        // setProd(prod);
        console.log('prod', prod);
      }
      
    }).catch(err => setLoading1(false));
    setLoading1(false);
  }
return (
<>

<div id='guest'>

<div className="gsec">
<Navbarg/>
<Row className="slide">
<Col lg="6" className="gsec2 pad-less">
<div className="gsec1">
<div className="gsec3">
<h1 className="text-uppercase text-center ">The Game Changer for Consumer Tech Channels</h1>
<p className="text-center mt-4"><strong>ChannelHub is Phygital Solution</strong> for the consumer tech channels. We match retailers, distributors and vendors from around the globe,</p>
<p className="text-center mt-4"> <strong>by combining a Digital platform with Physical & Online Events.</strong> </p>
</div>
</div>
</Col>
<Col lg="6"></Col>
</Row>
</div>
<div>


<div className="whatdo">
<Row className="">
<Col className="col-lg-6 justify-content-center ">
<div className="">
<h1 className="text-center ">WHAT WE DO</h1>
<h3 className="text-center" >Why we are unique?</h3>
</div>
</Col>
<Col className="col-lg-6">

<ul>
<li style={{textAlign:"left"}}>
<strong>An online platform </strong>using matching algorithms to connect vendors/distributors/retailers</li>
<li><strong>Online Events:</strong> our new <a href="https://www.retailconnect1to1.com"><em>Retail Connect One-to-One</em></a> is the New launchpad for vendors to present their new products to buyers</li>
<li><strong>Private business network</strong>: membership submitted to approval</li>
<li><strong>Focused</strong> only on Consumer tech products</li>
<li><strong>Freemium Business Model:</strong> free for qualified buyers. Subscription-based solution for vendors</li>
</ul>
</Col>
</Row>
</div>


<Card>

<Row>
<Col lg="6" className="p-2">
<div className="retial-cons text-center">
<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
className="retial-cons"
alt="..."
src={require("../../../../assets/img/guestpage/RETAIL_CONNECT_ONE_TO_ONE_EMEA.png")}
/>
</div>


<h4 className="card-description mt-4 text-center">
The First Edition of the All-Digital Retail Connect One-to-One EMEA was a rousing success with 490 participants across 80 countries.
</h4>
<h4 className="card-description p-2 text-center">Each event combines pre-scheduled one-to-one meetings, Pitch-your-Product session, Conferences and Networking.</h4>
<div className="text-center mt-4">
<button class="btn btn-primary card-category text-center" type="button">Don’t miss Retail Connect One-to-One EMEA 2-4 March 2021</button></div>
</Col>
<Col lg="6 pad-less">
<a href="javascript:;">

<video className="fullscreen-video " width="100%" height="100%" muted controls autoPlay>
<source src="https://channelhub.net/wp-content/uploads/2020/02/RT18112020.mp4" type="video/mp4"/>
</video>

</a>
</Col>
</Row>

</Card>


<section >
<div className="">

<h1 className="text-center pt-6"style={{fontSize:"2.5rem"}}>BUYERS HIGHLIGHTS</h1>
<h2 className="text-center pt-2">Below is a selection of buyers using ChannelHub to scout for new products and vendors.</h2>
<h2 className='text-center'> More than 500 distributors and retailers from 65 countries with full profile are active on ChannelHub for registered vendors.</h2>
</div> 
<Container className="mt-5">
<div className="card-columns">
{!loading && data.length && data.map((x,i) => (
<Card className=" h-auto">
<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
alt="..."
className="channeCardlimg p-3"
src={x.logo ? process.env.REACT_APP_CDN_URL + x.logo : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/guestpage/fox.jpg")}
/>

<CardHeader className="text-center border-0 pt-md-4 pb-0 ">

<div className="text-center">
<h5 className="h3">
<strong>{x.companyName}</strong>
<span className="font-weight-light"></span>
</h5>
<CardText className="h5 font-weight-300 channeldesc">

<CardText id={'productContent'+i} className="channeldesc h4 font-weight-light text-muted text-center" style={{fontSize: "15px"}}>{x.detaildesc ? x.detaildesc.replace(/<[^>]*>?/gm, '') : '-'}</CardText>
<UncontrolledTooltip content='preview video' target={'productContent'+i} placement='bottom'>
{x.detaildesc ? x.detaildesc.replace(/<[^>]*>?/gm, '') : '-'}
</UncontrolledTooltip>
</CardText>
</div>
</CardHeader>

<div className="card-profile-stats text-center d-flex justify-content-center p-0">
<div className="col-lg-12">
<h5 className="h3 title"an className="heading">Country</h5>
<small className="h4 font-weight-light text-muted">
<i class="fas fa-map-marker-alt pr-1"></i>
{x.country}
</small>
</div>
</div>
<div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
<div className="col-lg-12">
<span className="heading">Meet with</span>
<span id={'productMeet'+i} className="description txtwrp1">{x.contacts.length && x.contacts[0].jobTitle}</span>
<UncontrolledTooltip content='preview video' target={'productMeet'+i} placement='bottom'>
{x.contacts.length && x.contacts[0].jobTitle}
</UncontrolledTooltip>
</div>
</div>


<div className={"card-profile-stats justify-content-center pad-tile text-center pb-2 " + (x.lookingfor ? "" : "d-none") } >
<h3 className="h3 text-center text-muted ">
Looking For :
</h3>
<span className="description"> {x.lookingfor ? x.lookingfor : '-'}</span>
</div>


</Card>
))
}
</div>
</Container>

<Container className="mt-2">

<div className="text-center">
<button className="btn btn-primary" onClick={e => history.push({pathname: 'channels', state:{viewId:1}})}>LOAD MORE</button>
</div>
</Container>

<Container>
<div class=" container">
<h1 className='text-center mt-5' style={{fontSize:"2.5rem"}}>TESTIMONIALS</h1>
<Row className="pt-4">
<Col lg="4">
<Card className="cardmonial">
<CardBody>
<Row>

<Col lg="5" >
<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://channelhub.net/wp-content/uploads/2018/12/nilesh-e1545410756927-180x180.jpg" alt="#" class="rounded-circle" width="80" height="80"/>
</Col>
<Col lg="7">
<h4>Nilesh Khalkho</h4>
<span> CEO Sharaf DG - UAE</span>
</Col>

<p className="pt-4">As the 1st big-box retailer in Middle East, Sharaf DG has always been at the leading edge of innovation and customer service. We are an early supporter of ChannelHub as it condenses all the tools to facilitate our
product selection and bring more innovations to our customers.</p>
</Row>
</CardBody>
</Card>
</Col>
<Col lg="4">
<Card className="cardmonial">
<CardBody>
<Row>
<Col lg="5">
<div class="img-icon">
<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://channelhub.net/wp-content/uploads/2018/10/02_Adam_Zwierzyn%CC%81ski_nowe_net_1024-e1539884580563-180x180.jpg" alt="#" class="rounded-circle" width="80" height="80"/></div></Col>
<Col lg="7">
<h4>Adam Zwierzynski</h4>
<span> CEO Alstor </span>
</Col>

</Row>
<div class="detail-box">

<div class="latest-p">
<p className="pt-4">ALSTOR is a leading VAD in Poland, serving all major Retailers since 1995. As a CEO I’ve been attending over the years tons of trade shows of all kinds to scout for new products. But I had also a “secret dream”
of a solution that would really help the market with a better visibility on the quality of vendors and distributors. ChannelHub is making this dream come true.</p>
</div>
</div>

</CardBody>
</Card></Col>
<Col lg="4">
<Card className="cardmonial">
<CardBody>
<Row>
<Col lg="5">

<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://channelhub.net/wp-content/uploads/2019/06/sandra-defant-180x180.jpg" alt="#" class="rounded-circle" width="80" height="80"/>


</Col>
<Col lg="7">

<h4>Sandra Defant </h4>
<span> Head of Sales & Marketing</span></Col>

</Row>
<p className="pt-4">Channel Hub is just the perfect and state-of-the-art way of forming, strengthening and building up your business network. Q2Power is a relatively young company which is however operating worldwide. To find the right
business partner in every country around the globe is fundamental for our business.</p>


</CardBody>
</Card>
</Col>
</Row>
</div>
</Container>
</section>


<section className="profad1 p-4" style={{height:"auto"}}>

<div className="">

<h1 className="text-center pt-2 "style={{fontSize:"2.5rem"}}>PRODUCTS HIGHLIGHTS</h1>
{/* <h2 className="text-center pt-2">Below is a selection of buyers using ChannelHub to scout for new products and vendors.</h2> */}
{/* <h2 className='text-center'> More than 500 distributors and retailers from 65 countries with full profile are active on ChannelHub for registered vendors.</h2> */}
</div> 

<Container className="mt-4">
{/* <Row>

      {!loading1 && prod.length && prod.map((x,i) =>  <Col lg="3" className="pr-0">
       <Card className="firimg">
         <div class="prodlights">
         <CardImg
              className="rprod"
              alt="..."
              src={x.documentPath ? process.env.REACT_APP_CDN_URL+x.documentPath : process.env.REACT_APP_CDN_URL+'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} //require("assets/img/guestpage/prod2.png")}
              top
            />
           <div >
             <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  class="prodhigh1" src={x.brandImg ? process.env.REACT_APP_CDN_URL+x.brandImg : process.env.REACT_APP_CDN_URL+'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
             </div>
             <div class="prodhigh2">
             <i class="fa fa-star" aria-hidden="true"><span className="ml-2 text-dark">8.8</span></i>
             </div>
             </div>
        </Card>
        <div className="p-2 gfl-product-content" style={{marginTop:"-1.5rem",marginBottom:"2rem"}}>
        <h4 id={'productName'+i} className="text-capitalize txtwrp">{x.product}</h4>
        <UncontrolledTooltip content='preview video' target={'productName'+i} placement='bottom'>
              {x.product}
            </UncontrolledTooltip>
        <Row >
         <Col lg="4" className=" pl-0">
        <h6 className="text-dark" style={{marginTop:"-0.5rem", fontSize:"1rem"}}>{x.productPriceDetail.businesscurrencyid == '1' ? '€' : '$' } {x.productPriceDetail?.retailerprice}</h6>
        </Col>
        {x.productTag.length && x.productTag.map((x,i) => 
        <Col lg="4" className="pl-0">
        <Badge className= "badge-one" color={i==1 ? "warning" : "warning1"}>{x.tag}</Badge>
        </Col>)
         }
       
        </Row>
        </div>
      </Col>)
      }

   </Row> */}
   <div id="product" >
   {!loading1 && prod.length>0 && prod.map((x,i) =>
   <div className="column mt-4">
    { x.value.map((item, id)=>
     <div className='cards' style={(i%2==0) ? unqual : {}} key={item.product}>
     <div className='set-image'>
       <div className='brnd-log'>
       <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={item.brandImg ? process.env.REACT_APP_CDN_URL+item.brandImg : process.env.REACT_APP_CDN_URL+'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
       </div>
       <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={item.documentPath ? process.env.REACT_APP_CDN_URL+item.documentPath : process.env.REACT_APP_CDN_URL+'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
     </div>
     <div className='set-headline'>
     <h3 id={'productName'+i+id} className="text-capitalize txtwrp">{item.product}</h3>
         <UncontrolledTooltip content='preview video' target={'productName'+i+id} placement='bottom'>
               {item.product}
             </UncontrolledTooltip>
       <Row >
          <Col lg="6" className=" pl-0">
         <h6 className="text-dark" style={{fontSize:"1rem"}}>{item.productPriceDetail.businesscurrencyid == '1' ? '€' : '$' } {item.productPriceDetail?.retailerprice} </h6>
         </Col> 
         <Col lg="6" className="pl-0">
         <Badge className= "badge-1">{item?.brand?.brandName} </Badge>
         </Col>
         </Row>
     </div>
     </div>
    )
}
    </div>
    )}
    
    </div>
   {/* <Row>

      <Col lg="2" className="pr-0">
       <Card className="firimg">
         <div class="prodlights">
         <CardImg
              className="rprod"
              alt="..."
              src={require("assets/img/guestpage/prod3.jpg")}
              top
            />
           <div class="prodhigh1">
             </div>
             <div class="prodhigh2">
             <i class="fa fa-star" aria-hidden="true"><span className="ml-2">8.8</span></i>
             </div>
             </div>
        </Card>
        <div style={{marginTop:"-1.5rem",marginBottom:"2rem"}}>
        <h4>Product Detail</h4>
        <Row >
         <Col lg="4" className=" pl-0">
        <h6 className="" style={{marginTop:"-0.5rem"}}>Products</h6>
        </Col>
        <Col lg="4" className="pl-0">
        <Badge className="badge-one"  color="warning">Warning</Badge>
        </Col> 
        <Col lg="4" className=" pl-0">
        <h6 className="" style={{marginTop:"-0.5rem"}}>Products</h6>
        </Col>  
        </Row>
        </div>
      </Col>

      <Col lg="2" className="pr-0">
       <Card className="firimg">
         <div class="prodlights">
         <CardImg
              className="rprod"
              alt="..."
              src={require("assets/img/guestpage/prod4.png")}
              top
            />
           <div class="prodhigh1">
             </div>
             <div class="prodhigh2">
             <i class="fa fa-star" aria-hidden="true"><span className="ml-2">8.8</span></i>
             </div>
             </div>
        </Card>
        <div style={{marginTop:"-1.5rem",marginBottom:"2rem"}}>
        <h4>Product Detail</h4>
        <Row >
         <Col lg="4" className=" pl-0">
        <h6 className="" style={{marginTop:"-0.5rem"}}>Products</h6>
        </Col>
        <Col lg="4" className="pl-0">
        <Badge className="badge-one"  color="warning">Warning</Badge>
        </Col> 
        <Col lg="4" className=" pl-0">
        <h6 className="" style={{marginTop:"-0.5rem"}}>Products</h6>
        </Col>  
        </Row>
        </div>
      </Col>

      <Col lg="2" className="pr-0">
       <Card className="firimg">
         <div class="prodlights">
         <CardImg
              className="rprod"
              alt="..."
              src={require("assets/img/guestpage/prod5.jpg")}
              top
            />
           <div class="prodhigh1">
             </div>
             <div class="prodhigh2">
             <i class="fa fa-star" aria-hidden="true"><span className="ml-2">8.8</span></i>
             </div>
             </div>
        </Card>
        <div style={{marginTop:"-1.5rem",marginBottom:"2rem"}}>
        <h4>Product Detail</h4>
        <Row >
         <Col lg="4" className=" pl-0">
        <h6 className="" style={{marginTop:"-0.5rem"}}>Products</h6>
        </Col>
        <Col lg="4" className="pl-0">
        <Badge className="badge-one"  color="warning">Warning</Badge>
        </Col> 
        <Col lg="4" className=" pl-0">
        <h6 className="" style={{marginTop:"-0.5rem"}}>Products</h6>
        </Col>  
        </Row>
        </div>
      </Col>

      <Col lg="2" className="pr-0">
       <Card className="firimg">
         <div class="prodlights">
         <CardImg
              className="rprod"
              alt="..."
              src={require("assets/img/guestpage/prod6.png")}
              top
            />
           <div class="prodhigh1">
             </div>
             <div class="prodhigh2">
             <i class="fa fa-star" aria-hidden="true"><span className="ml-2">8.8</span></i>
             </div>
             </div>
        </Card>
        <div style={{marginTop:"-1.5rem",marginBottom:"2rem"}}>
        <h4>Product Detail</h4>
        <Row >
         <Col lg="4" className=" pl-0">
        <h6 className="" style={{marginTop:"-0.5rem"}}>Products</h6>
        </Col>
        <Col lg="4" className="pl-0">
        <Badge className="badge-one"  color="warning">Warning</Badge>
        </Col> 
        <Col lg="4" className=" pl-0">
        <h6 className="" style={{marginTop:"-0.5rem"}}>Products</h6>
        </Col>  
        </Row>
        </div>
      </Col>

      <Col lg="2" className="pr-0">
       <Card className="firimg">
         <div class="prodlights">
         <CardImg
              className="rprod"
              alt="..."
              src={require("assets/img/guestpage/prod7.jpg")}
              top
            />
           <div class="prodhigh1">
             </div>
             <div class="prodhigh2">
             <i class="fa fa-star" aria-hidden="true"><span className="ml-2">8.8</span></i>
             </div>
             </div>
        </Card>
        <div style={{marginTop:"-1.5rem",marginBottom:"2rem"}}>
        <h4>Product Detail</h4>
        <Row >
         <Col lg="4" className=" pl-0">
        <h6 className="" style={{marginTop:"-0.5rem"}}>Products</h6>
        </Col>
        <Col lg="4" className="pl-0">
        <Badge className="badge-one"  color="warning">Warning</Badge>
        </Col> 
        <Col lg="4" className=" pl-0">
        <h6 className="" style={{marginTop:"-0.5rem"}}>Products</h6>
        </Col>  
        </Row>
        </div>
      </Col>


      <Col lg="2" className="pr-0">
       <Card className="firimg">
         <div class="prodlights">
         <CardImg
              className="rprod"
              alt="..."
              src={require("assets/img/guestpage/prod8.jpg")}
              top
            />
           <div class="prodhigh1">
             </div>
             <div class="prodhigh2">
             <i class="fa fa-star" aria-hidden="true"><span className="ml-2">8.8</span></i>
             </div>
             </div>
        </Card>
        <div style={{marginTop:"-1.5rem",marginBottom:"2rem"}}>
        <h4>Product Detail</h4>
        <Row >
         <Col lg="4" className=" pl-0">
        <h6 className="" style={{marginTop:"-0.5rem"}}>Products</h6>
        </Col>
        <Col lg="4" className="pl-0">
        <Badge className="badge-one"  color="warning">Warning</Badge>
        </Col> 
        <Col lg="4" className=" pl-0">
        <h6 className="" style={{marginTop:"-0.5rem"}}>Products</h6>
        </Col>  
        </Row>
        </div>
      </Col>
   </Row> */}

            </Container>
            <div className="text-center">
<button className="btn btn-primary" onClick={e=>history.push({pathname: 'products', state:{viewId:1}})}>LOAD MORE</button>
</div>
</section>



<section>
<Container> 
<div className="pt-6">
<Row>
<Col lg="3" >
<Card className="channelcard profad">
<div className="text-center iconbg">
<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
className=" channelimg "
alt="..."
src={require("../../../../assets/img/guestpage/building.png")}
/>
</div>
<CardBody>
<CardTitle className="text-center"><h4>I am a Vendor</h4></CardTitle>
<CardText>
<ul class="" >
<li> <span><strong>Expand</strong> your worldwide channel</span></li>
<li><span><strong>Find</strong> reliable channel partners</span></li>
<li><span><strong>Communicate</strong> with existing partners</span></li>
<li><span><strong>Manage</strong> samples orders</span></li>
<li><span><strong>Build</strong> local sales forces</span></li>
</ul>
</CardText>
<div className="text-center">
<button className="btn btn-primary">Read more</button>
</div>
</CardBody>
</Card>

</Col>


<Col lg="3" >

<Card className="channelcard profad" >
<div className="text-center iconbg" >
<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
className="channelimg "
alt="..."
src={require("../../../../assets/img/guestpage/group.png")}
/>
</div>
<CardBody>
<CardTitle className="text-center"><h4>I am a Distributor</h4></CardTitle>
<CardText>
<ul class="">
<li> <span><strong>Source</strong> innovative products</span></li>
<li><span><strong>Access </strong>to vetted products and suppliers</span></li>
<li><span><strong>Connect</strong> with existing vendors</span></li>
<li><span><strong>Expand </strong> your customer base</span></li>
<li><span><strong>Get</strong> product samples</span></li>
</ul>
</CardText>
<div className="text-center">
<button className="btn btn-primary">Read more</button>
</div>
</CardBody>
</Card>

</Col>

<Col lg="3" >

<Card className="channelcard profad">
<div className="text-center iconbg">
<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
className=" channelimg "
alt="..."
src={require("../../../../assets/img/guestpage/shipping.png")}
/>
</div>
<CardBody>
<CardTitle className="text-center"><h4>I am a Retailer</h4></CardTitle>
<CardText>
<ul class="">
<li> <span><strong>Source</strong> innovative products</span></li>
<li><span><strong>Access </strong> to vetted products and suppliers</span></li>
<li><span><strong>Connect </strong> with existing vendors</span></li>
<li><span><strong>Expand </strong> your customer base</span></li>
<li><span><strong>Get</strong> product samples</span></li>
</ul>
</CardText>
<div className="text-center">
<button className="btn btn-primary">Read more</button>
</div>
</CardBody>
</Card>

</Col>


<Col lg="3" >

<Card className="channelcard profad" >
<div className="text-center iconbg">
<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
className="channelimg "
alt="..."
src={require("../../../../assets/img/guestpage/multiple-users-silhouette.png")}
/></div>
<CardBody>
<CardTitle className="text-center"><h4>I am a Business Developer</h4></CardTitle>
<CardText>
<ul class="">
<li> <span><strong>Find</strong> new vendors</span></li>
<li><span><strong>Leverage</strong> your market expertis</span></li>
<li><span><strong>Streamline</strong> your prospection</span></li>
<li><span><strong>Join</strong> an international communits</span> </li>
</ul>
</CardText>
<div className="text-center">
<button className="btn btn-primary">Read more</button>
</div>
</CardBody>
</Card>

</Col>


</Row>
</div>

</Container> 
</section>
<Footer/>
</div>
</div>

</>
);
}

export default GuestSection1;