import React from "react";
import { Button } from "reactstrap";
import { Card, CardBody, Row, Col } from "reactstrap";
import axios from 'axios';
const ChannelGroup = props => {  
  const { data, getChannelGroupId } = props;
  console.log('data', data);
  return (
<div>
{/* <Card className="w-100 mb-0" style={{background:'none'}}>
        <CardBody>
        {data.length>0 && <ListGroup>
        {data.map(x=>{
        return(<ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={e => e.preventDefault()}> {x.name} </a>
                          </h4><small >{x.count}</small>
                        </div>
                        <Col className="col-auto">
                          <Button color="primary" size="sm" type="button" onClick={()=>{getChannelGroupId(x.id) }}>View</Button>
                         
                        </Col>
                      </Row>
                    </ListGroupItem>)
                  })}
        </ListGroup>}
        </CardBody>
        </Card>  */}
        <Row>
        {data.length>0 ? <>
        {data.map(x=>{
        return(<Col lg="2" className='p-4'>
              <div>
              <Card className='m-0'>
        <CardBody className='text-center'>
                  <h4 className="mb-0">
                            <a href="#pablo" onClick={e => e.preventDefault()}> {x.name} </a>
                          </h4><h5>{x.count}</h5>
                          <div className='text-center'>
                  <Button color="primary" size="sm" type="button" onClick={()=>{getChannelGroupId(x.id) }}>View</Button>
                  </div>
                  </CardBody>
                </Card>
              </div>
            </Col>)})}</>:
            <div className="no-records ">
            <div className="no-img text-center">
              <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("./../../assets/img/medialibrary/no-data.png")} /></div>
          </div>
            }
        </Row>
</div>          
  );
}

export default ChannelGroup;