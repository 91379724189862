import React from 'react';
import {Card,CardBody, Row,Col} from "reactstrap";
import PdfViewer from '../PdfViewer';

export const OfficialDocument = (props)=>{
    const { data, title, type,Isprodet } = props;
    console.log('data', data);

    const checkFile=(url)=>{
      console.log('url.split', url.split(/[#?]/)[0].split('.').pop().trim());
      if(url){
        return(
          <>
          {url.split(/[#?]/)[0].split('.').pop().trim()==='pdf'?
                                              <PdfViewer edit={false} pdf={url}/> :
                                              <img className='Brandimg' src={url} />
                                                }
          </>
        )
      }else{
        return(
          <img className='Brandimg' src="http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"/>
        )
      }
    }
    return(
            <Card className=''>
               <CardBody>
                 <h3 className={(Isprodet?'ml-2 h4':'ml-4')}> {title} </h3>
                 <div className={(Isprodet?'pl-4 pr-4':'doc-div')}>
                 <Row>
                 {data?.length>0 && data.map(x=>{
                   return(
                     <>
                    {/* // <div className="Tren"  style={{padding:"0.5rem 0px"}}>  */}
                    {/* <Row>
                    <Col className="col-lg-7 col-md-7 text-left col-4" style={{padding:'0px'}}>
                       <div className="img-wish" style={{float:'left'}}>
                       <i class="fas fa-file-pdf"></i>
                       </div>
                          <h3> {x.name?x.name:'documents'} </h3>
                    </Col>
                    <Col className="col-lg-5 col-md-5 text-center col-4 ">
                        <div className=" ">
                        <a href={type==='1'?x.document.documentUrl:x.image.documentUrl} download>
                           <button className="btn btn-primary bttnn btn-sm ">Download Now</button>
                           </a>
                        </div>
                    </Col>
                           
                   </Row> */}
                   
                    <Col lg='3' className='text-center'>
                        <div className='ofc-doc'>
                            <div className='doc-image'>
                               {type==='2' ? checkFile(x?.image?.documentUrl):checkFile(x.document?.documentUrl)}
                            </div>
                        </div>
                        
                        <div className="text-center">
                        <div className='text-center'>
                            <h4 className="text_elips text-center">{x.name?x.name:'Documents'}</h4>
                            </div>
                        <a href={type==='1'?x.document.documentUrl:x.image.documentUrl} download>
                           <button className="btn btn-primary bttnn btn-sm mb-3">Download Now</button>
                        </a>
                        </div>
                    </Col>
                    
                    
                     {/* </div> */}</>
                   )
                 })}
                 </Row>
                 </div> 
              </CardBody>
            </Card>
    )
}