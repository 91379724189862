import React, { useState, useEffect } from 'react';
import { ListGroupItem, ListGroup, Button } from 'reactstrap';
import { FormAutocomplete, FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from "react-hook-form";
import { commonService } from '_services/common.service';
import Popper from '@material-ui/core/Popper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const OldChannelFilter = (props) => {
  const classes = useStyles();

  const { activeTab, handleChangeSelect, filterValueState, resetFilterValue, flag, parent, setFilterValue, setChannelFlag } = props;
  const [filterOptionState, setFilterOption] = useState({ Interested_Product_Option: [], Current_Product_Option: [], SpecialityOption: [], Brand_Option: [], Retailer_Profile_Option: [], Reseller_Profile_Option: [], Company_Origin_Option: [], Target_Location_Option: [], channelTypeOption: [] });


  const user = JSON.parse(localStorage.getItem('CHuser'));
  const { register, control } = useForm({ mode: 'onBlur' });
  useEffect(() => {
    if (activeTab)
      getSearchAggregation();
  }, [activeTab])

  const getFilterData = () => {
    let filter = {
      //specialityId: null,
      brandId: null,
      countryId: null,
      productId: null,
      //intProductId: null,
      //locationId: null,
      //retailerprofileId: null,
      //resellerprofileId: null,
      productCategoryId: null,
      turnOver: null,
      //connectionStatusId: null,
      typeId: null,
      storeCount: null
    };
    let flag = 0;

    // if (filterValueState.Speciality && filterValueState.Speciality.length > 0 && parent == 'Speciality') {
    //   filter.specialityId = filterValueState.Speciality.map(x => parseInt(x.id));
    //   flag = 1;
    // }
    if (filterValueState.Brand && filterValueState.Brand.length > 0 && parent == 'Brand') {
      filter.brandId = filterValueState.Brand.map(x => parseInt(x.id));
      flag = 1;
    }
    if (filterValueState.Current_Product && filterValueState.Current_Product.length > 0 && parent == 'current_product') {
      filter.productId = filterValueState.Current_Product.map(x => parseInt(x.childid));
      flag = 1;
    }

    // if (filterValueState.Interested_Product && filterValueState.Interested_Product.length > 0 && parent == 'interested_product') {
    //   filter.intProductId = filterValueState.Interested_Product.map(x => parseInt(x.childid));
    //   flag = 1;
    // }

    if (filterValueState.Company_Origin && filterValueState.Company_Origin.length > 0 && parent == 'Company_Origin') {
      filter.countryId = filterValueState.Company_Origin.map(x => parseInt(x.id));
      flag = 1;
    }
    // if (filterValueState.Target_Location && filterValueState.Target_Location.length > 0 && parent == 'Target_Location') {
    //   filter.locationId = filterValueState.Target_Location.map(x => parseInt(x.id));
    //   flag = 1;
    // }
    // if (filterValueState.Retailer_Profile && filterValueState.Retailer_Profile.length > 0 && parent == 'Profile') {
    //   filter.retailerprofileId = filterValueState.Retailer_Profile.map(x => parseInt(x.id));
    //   flag = 1;
    // }
    // if (filterValueState.Reseller_Profile && filterValueState.Reseller_Profile.length > 0 && parent == 'Profile') {
    //   filter.resellerprofileId = filterValueState.Reseller_Profile.map(x => parseInt(x.id));
    //   flag = 1;
    // }
    // if (filterValueState.productCategory && filterValueState.productCategory.length > 0 && parent == 'productCategory') {
    //   filter.productCategoryId = filterValueState.productCategory.map(x => parseInt(x.id));
    //   flag = 1;
    // }
    if (filterValueState.Store_Count && parent == 'Store_Count') {
      filter.storeCount = filterValueState.Store_Count.id;
      flag = 1;
    }
    if (filterValueState.Annual_Turnover && parent == 'Annual_Turnover') {
      filter.turnOver = filterValueState.Annual_Turnover.id;
      flag = 1;
    }
    // if (filterValueState.connectionStatusId && parent == 'connectionStatusId') {
    //   filter.connectionStatusId = filterValueState.connectionStatusId.id;
    //   flag = 1;
    // }
    if (filterValueState.typeId && parent == 'typeId') {
      filter.typeId = filterValueState.typeId.id;
      flag = 1;
    }
    if (flag == 0) {
      resetFilterValue();
      setChannelFlag(true);
    }
    return filter;
  }

  useEffect(() => {
    async function fetchData() {

      let postData = {
        filter: getFilterData()
      }
      // if (filterValueState.typeId) {
      //console.log("rajtypeid:", postData.filter);
      let typeId = postData.filter.typeId ? postData.filter.typeId : 0;
      let channels = await commonService.postService(postData, '/channel/chFilter/' + activeTab + "/" + typeId);

      if (channels.data.length > 0) {
        let data = channels.data;
        //filterOptionState.SpecialityOption= options.specialIds;
        //let url = activeTab == 32 ? 'channel/conSpecialFilter' : 'channel/specialFilter';
        
        if (parent != 'Brand') {
          filterOptionState.Brand_Option = await commonService.filterPostService({
            data
          }, '/channel/brandFilter');
          let Ids = filterOptionState.Brand_Option.map(x => parseInt(x.id));
          filterValueState.Brand = filterValueState.Brand.filter(item => Ids.includes(parseInt(item.id)));
          //setFilterOption({ ...filterOptionState });
        }

        if (parent != 'current_product') {
          filterOptionState.Current_Product_Option = await commonService.filterPostService({
            data
          }, '/channel/productFilter');
          let Ids = filterOptionState.Current_Product_Option.map(x => parseInt(x.childid));
          filterValueState.Current_Product = filterValueState.Current_Product.filter(item => Ids.includes(parseInt(item.childid)));
          //setFilterOption({ ...filterOptionState });
        }       
        

       // url = activeTab == 32 ? 'channel/conCountryFilter' : 'channel/countryFilter';
        if (parent != 'Company_Origin') {
          filterOptionState.Company_Origin_Option = await commonService.filterPostService({
            data
          }, '/channel/countryFilter');
          let Ids = filterOptionState.Company_Origin_Option.map(x => parseInt(x.id));
          filterValueState.Company_Origin = filterValueState.Company_Origin.filter(item => Ids.includes(parseInt(item.id)));
          //setFilterOption({ ...filterOptionState });
        }

                setChannelFlag(true);
        setFilterOption({ ...filterOptionState });
        setFilterValue({ ...filterValueState })
      }
      else
        setChannelFlag(true);

      // if(parent != 'typeId')
      //   filterOptionState.channelTypeOption = await commonService.filterGetService('/channel/channeltype');
      // setFilterOption({ ...filterOptionState });
    }
    if (flag != 0)
      fetchData();
  }, [flag])

  const getSearchAggregation = async () => {
    resetFilterValue();
    resetFilterOptions();
    let postData = {
      filter: getFilterData()
    }
    let typeId = filterValueState.typeId ? filterValueState.typeId : 0;
    let channels =  await commonService.postService(postData, '/channel/chFilter/' + activeTab + "/" + typeId);

    if (channels.data.length > 0) {
      let data = channels.data;
      //filterOptionState.SpecialityOption= options.specialIds;
    //   let url = activeTab == 32 ? 'channel/conSpecialFilter' : 'channel/specialFilter';
    //   filterOptionState.SpecialityOption = await commonService.filterPostService({
    //     data
    //   }, url);
    //   setFilterOption({ ...filterOptionState });

      filterOptionState.Brand_Option = await commonService.filterPostService({
        data
      }, '/channel/brandFilter');
      setFilterOption({ ...filterOptionState });

      filterOptionState.Current_Product_Option = await commonService.filterPostService({
        data
      }, '/channel/productFilter');
      setFilterOption({ ...filterOptionState });

    //   filterOptionState.Interested_Product_Option = await commonService.filterPostService({
    //     data
    //   }, '/channel/interestedProductFilter');
    //   setFilterOption({ ...filterOptionState });

    //   let retailIds = await commonService.filterPostService({
    //     data
    //   }, '/channel/retailFilter');
    //   if (activeTab == '8' || activeTab == '9' || activeTab == 10)
    //     filterOptionState.Retailer_Profile_Option = retailIds.length > 0 ? retailIds.filter(x => x.name.trim() !== '' && x.name && x.channelTypeId == 4) : [];
    //   if (activeTab == '8' || activeTab == '9' || activeTab == 14)
    //     filterOptionState.Reseller_Profile_Option = retailIds.length > 0 ? retailIds.filter(x => x.name.trim() !== '' && x.name && x.channelTypeId == 5) : [];
    //   setFilterOption({ ...filterOptionState });

      //url = activeTab == 32 ? 'channel/conCountryFilter' : 'channel/countryFilter';

      filterOptionState.Company_Origin_Option = await commonService.filterPostService({
        data
      }, '/channel/countryFilter');

    //   filterOptionState.Target_Location_Option = await commonService.filterPostService({
    //     data
    //   }, '/channel/targetFilter');




      setFilterOption({ ...filterOptionState });
    }

    filterOptionState.channelTypeOption = await commonService.filterGetService('/channel/channeltype');
    setFilterOption({ ...filterOptionState });



  }

  const resetFilterOptions = () => {
    filterOptionState.SpecialityOption = [];
    filterOptionState.Brand_Option = [];
    filterOptionState.Current_Product_Option = [];
    filterOptionState.Interested_Product_Option = [];
    filterOptionState.Retailer_Profile_Option = [];
    filterOptionState.Reseller_Profile_Option = [];
    filterOptionState.Company_Origin_Option = [];
    filterOptionState.Target_Location_Option = [];
    filterOptionState.Store_Count_Option = [];
    filterOptionState.channelTypeOption = channelTypeList.filter(x => x.id !== user.channelTypeId);
    setFilterOption({ ...filterOptionState });
  }

  const PopperMy = function (props) {
    return (<Popper {...props} style={{ width: '300px' }} placement='bottom-start' />)
  }

  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    //console.log("check:",check,gname);
    if (check == true) {
      filterOptionState[optionName].map((item) => {

        if (item.groupname == gname || item.status == true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check == false) {
      console.log("check:", check);
      filterOptionState[optionName].map((item) => {
        if (item.groupname == gname && filterValueState[optionValue].length > 0)
          item.status = check;
        // if(state.productValue.length==0)
        //     item.status=false;
      });

      opt = filterValueState[optionValue].filter((item) => item.groupname != gname);
      opt.map(option => option.status = true);
    }

    filterValueState[optionValue] = opt;
    setFilterValue({ ...filterValueState, [optionValue]: filterValueState[optionValue] });
    setFilterOption({ ...filterOptionState, [optionName]: filterOptionState[optionName] });

    // if(optionValue='ChannelProfileValue' && state.ChannelProfileValue.length==0)
    //       errors.relevantChannelProfiles=true;
    // else 
    // if (optionValue = 'productValue' && state.productValue.length == 0)
    //   errors.productCat = true;
  }

  const style = { height: '18rem' };

  return (
    <div className='fav-ser1 p-2' id='ch-list'>
      <h4 data-dismiss="modal" className="clearfilter text-muted text-underline mb-0" type="button" onClick={getSearchAggregation}>Clear all</h4>
      <div className={classes.root}>

        {(user.channelTypeId == 2) && <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Channel Type</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.channelTypeOption.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="channel_type"
                        name="channel_type"
                        label=""
                        options={filterOptionState.channelTypeOption}
                        placeholder="Type Channel Type"
                        // multiple  
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        open={true}
                        input='channel_type'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.typeId}
                        handleChangeSelect={handleChangeSelect}
                        popupIcon=''
                        disablePortal={true}
                        ListboxProps={{ style: { maxHeight: '16rem' } }}
                        size='small'
                      />

                    </div>
                  </div>

                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>}

        {/* Product category' : 'Current product categories' */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Product category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Current_Product_Option?.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocompleteGrouping
                        id="current_product"
                        name="current_product"
                        multiple={true}
                        //label="Product Families are you selling *"
                        Options={filterOptionState.Current_Product_Option}
                        placeholder="Select Product Families"
                        input='current_product'
                        //errMessage={'Please Select a Product Family'}
                        //error={!errors.productCat || productFamilies.length ?false:true}
                        defaultValue={filterValueState.Current_Product}
                        value={filterValueState.Current_Product}

                        optionName='Current_Product_Option'
                        optionValue='Current_Product'
                        selectedItem={selectedItem}

                        //getOptionSelected={(options, value) => options.name === value.name}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        control={control}
                      //register={register}

                      />
                    </div>
                  </div>

                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>


        {/* 'Country of origin' : 'Country' */}
        {/* {(((activeTab == '1' || activeTab == '2' || activeTab == '3' || activeTab == '50') && (user.channelTypeId == 3 || user.channelTypeId == 4 || user.channelTypeId == 5)) || activeTab == 9 || activeTab == '8' || activeTab == '30' || activeTab == '10' || activeTab == '14' || ((filterValueState.typeId?.id == 4 || filterValueState.typeId?.id == 5) && (activeTab == '1' || activeTab == '3' || activeTab == '50'))) &&  */}
        <Accordion> 
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Country of origin</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Company_Origin_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Company_Origin"
                        name="Company_Origin"
                        label=""
                        options={filterOptionState.Company_Origin_Option}
                        placeholder="Type Company Origin"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='Company_Origin'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.Company_Origin}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '16rem' } }}
                        style={{ height: '3rem' }}

                      />
                    </div>
                  </div>

                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>


        {/* 'Physical point of sales' */}
        {(user.channelTypeId != 2) && <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Physical point of sales</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={StoreCount.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Store_Count"
                        name="Store_Count"
                        label=""
                        options={StoreCount}
                        placeholder="Select physical store"
                        //multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='Store_Count'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.Store_Count}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '16rem' } }}
                        style={{ height: '3rem' }}

                      />
                    </div>
                  </div>

                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>}


        {/* Brand */}
        {( user.channelTypeId == 2) && <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Brand</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Brand_Option.length > 0 ? style : {}} >
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Brand"
                        name="Brand"
                        label=""
                        options={filterOptionState.Brand_Option}
                        placeholder="Type Brand"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='Brand'
                        value={filterValueState.Brand}
                        getOptionSelected={(options, value) => options.name === value.name}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '16rem' } }}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>}


        {/* Annual Turnover */}
         <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Annual Turnover</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={AnnuvalTurnOver.length > 0 ? style : {}}>

                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Annual_Turnover"
                        name="Annual_Turnover"
                        label=""

                        options={AnnuvalTurnOver}
                        placeholder="Type Annual Turnover"
                        // multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='Annual_Turnover'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.Annual_Turnover}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '15rem', fontSize: '1rem', } }}
                        PopperComponent={PopperMy}
                        limitTags
                      />

                    </div>
                  </div>

                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>








      </div>
    </div>
  );
}

const StoreCount = [
  { id: '5', name: 'upto 5' },
  { id: '6', name: 'greater than 5' },
  { id: '10', name: 'greater than 10' },
  { id: '20', name: 'greater than 20' },
]


const AnnuvalTurnOver = [
  { id: '<1M€', name: '<1M€' },
  { id: '1-10M€', name: '1-10M€' },
  { id: '10-100M€', name: '10-100M€' },
  { id: '>100M€', name: '>100M€' },
]

const channelTypeList = [
  {
    id: '2',
    type: 'Vendor',
    name: "VENDOR"
  },
  {
    id: '3',
    type: 'Distributor',
    name: "DISTRIBUTOR"
  },
  {
    id: '4',
    type: 'Retailer',
    name: "RETAILER"
  }
];
export default OldChannelFilter;