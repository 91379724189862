import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';

import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from "react-hook-form";
import { commonService } from '_services/common.service';
import { ListGroupItem, ListGroup, Button } from 'reactstrap';
import { FormAutocomplete, FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));



const Prodlistingfilter = (props) => {
  //   const classes = useStyles();

  const [value, setValue] = React.useState([1, 1000]);
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  // let [parent, setParent] = useState(null);
  const { activeTab, handleChangeSelect, filterValueState, resetFilterValue, flag, parent, setFilterValue, setProductFlag } = props;
  const [filterOptionState, setFilterOption] = useState({ PositionOption: [{ name: 'MID', id: '1' }, { name: 'HIGH', id: '2' }], Brand_Option: [], Profile_Option: [], Reseller_Profile_Option: [], Location_Option: [], Family_Option: [], Category_Option: [], Current_Product_Option: [] });
  const user = JSON.parse(localStorage.getItem('CHuser'));
  const { control } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (activeTab)
      getSearchAggregation();
  }, [activeTab])

  const getFilterData = () => {
    let filter = {
      position: null,
      brand: null,
      //family: null,
      country: null,
      location: null,
      profile: null,
      product: null,
      resellerprofile: null,
      category: null,
      fromPrice: null,
      toPrice: null
    };
    let flag = 0;

    if (filterValueState.Position && filterValueState.Position.length > 0 && parent == 'Position') {
      filter.position = filterValueState.Position.map(x => parseInt(x.id));
      flag = 1;
    }
    if (filterValueState.Brand && filterValueState.Brand.length > 0 && parent == 'Brand') {
      filter.brand = filterValueState.Brand.map(x => parseInt(x.id));
      flag = 1;
    }
    if (filterValueState.Location && filterValueState.Location.length > 0 && parent == 'Location') {
      filter.country = filterValueState.Location.map(x => parseInt(x.id));
      flag = 1;
    }
    if (filterValueState.product && filterValueState.product.length > 0 && parent == 'Product') {
      filter.product = filterValueState.Current_Product.map(x => parseInt(x.childid));
      flag = 1;
    }
    // if (filterValueState.Category && filterValueState.Category.length > 0 && parent == 'Category') {
    //   filter.category = filterValueState.Category.map(x => parseInt(x.id));
    //   flag = 1;
    // }
    if (filterValueState.Profile && filterValueState.Profile.length > 0 && parent == 'Profile') {
      filter.profile = filterValueState.Profile.map(x => parseInt(x.id));
      flag = 1;
    }
    if (filterValueState.Reseller_Profile && filterValueState.Reseller_Profile.length > 0 && parent == 'Profile') {
      filter.resellerprofile = filterValueState.Reseller_Profile.map(x => parseInt(x.id));
      flag = 1;
    }
    if (filterValueState.Price && filterValueState.Price.length > 0 && parent == 'Price') {
      filter.fromPrice = filterValueState.Price[0];
      filter.toPrice = filterValueState.Price[1];
    }

    if (flag == 0) {
      resetFilterValue();
      setProductFlag(true);
    }
    //console.log('prices', filter.fromPrice, filter.toPrice);
    return filter;
  }

  useEffect(() => {
    async function fetchData() {

      let postData = {
        filter: getFilterData()
      }

      let products = await commonService.postService(postData, '/product/productaggr/' + activeTab);

      if (products.data.length > 0) {
        let productIds = products.data.map(p => +p.productId);
        let channelIds = products.data.map(p => +p.channelId);
        //filterOptionState.SpecialityOption= options.specialIds;
        if (parent != 'Brand') {
          filterOptionState.Brand_Option = await commonService.filterPostService({
            productIds
          }, '/product/brandFilter');
          //setFilterOption({ ...filterOptionState });
          let Ids = filterOptionState.Brand_Option.map(x => parseInt(x.id));
          filterValueState.Brand = filterValueState.Brand.filter(item => Ids.includes(parseInt(item.id)));
        }

        if (parent != 'Product') {
          filterOptionState.Current_Product_Option = await commonService.filterPostService({
            productIds
          }, '/product/categoryFilter');
          let Ids = filterOptionState.Current_Product_Option.map(x => parseInt(x.childid));
          filterValueState.Current_Product = filterValueState.Current_Product.filter(item => Ids.includes(parseInt(item.childid)));
        }

        // if (parent != 'Category') {
        //   filterOptionState.Category_Option = await commonService.filterPostService({
        //     productIds
        //   }, '/product/categoryFilter');
        //   let Ids = filterOptionState.Category_Option.map(x => parseInt(x.id));
        //   filterValueState.Category = filterValueState.Category.filter(item => Ids.includes(parseInt(item.id)));
        // }

        if (parent != 'Profile') {
          let retailIds = await commonService.filterPostService({
            channelIds
          }, '/product/profileFilter');
          filterOptionState.Profile_Option = retailIds.length > 0 ? retailIds.filter(x => x.name.trim() !== '' && x.name && x.channelTypeId == 4) : [];
          let Ids = filterOptionState.Profile_Option.map(x => parseInt(x.id));
          filterValueState.Profile = filterValueState.Profile.filter(item => Ids.includes(parseInt(item.id)));

          filterOptionState.Reseller_Profile_Option = retailIds.length > 0 ? retailIds.filter(x => x.name.trim() !== '' && x.name && x.channelTypeId == 5) : [];
          let Ids1 = filterOptionState.Reseller_Profile_Option.map(x => parseInt(x.id));
          filterValueState.Reseller_Profile = filterValueState.Reseller_Profile.filter(item => Ids1.includes(parseInt(item.id)));
          // let Ids = filterOptionState.Profile_Option.map(x => parseInt(x.id));
          // filterValueState.Profile = filterValueState.Profile.filter(item => Ids.includes(parseInt(item.id)));
        }

        if (parent != 'Location') {
          filterOptionState.Location_Option = await commonService.filterPostService({
            productIds
          }, '/product/locationFilter');
          let Ids = filterOptionState.Location_Option.map(x => parseInt(x.id));
          filterValueState.Location = filterValueState.Location.filter(item => Ids.includes(parseInt(item.id)));
        }

        if (parent != 'Price') {
          let Price_Option = await commonService.filterPostService({
            productIds
          }, '/product/priceFilter');
          setMinPrice(+Price_Option[0].minPrice);
          setMaxPrice(+Price_Option[0].maxPrice);
          setValue([+Price_Option[0].minPrice, +Price_Option[0].maxPrice]);
        }

        setProductFlag(true);
        setFilterOption({ ...filterOptionState });
        setFilterValue({ ...filterValueState })
      }

    }
    if (flag != 0)
      fetchData();
    console.log("flag:", flag);
  }, [flag])

  const resetFilterOptions = () => {
    filterOptionState.Family_Option = [];
    filterOptionState.Brand_Option = [];
    filterOptionState.Category_Option = [];
    filterOptionState.Current_Product_Option = [];
    filterOptionState.Profile_Option = [];
    filterOptionState.Reseller_Profile_Option = [];
    filterOptionState.Location_Option = [];
    setValue([0, 0]);
    setFilterOption({ ...filterOptionState });
  }

  const getSearchAggregation = async () => {
    resetFilterValue();
    resetFilterOptions();
    let postData = {
      filter: getFilterData()
    }
    let products = await commonService.postService(postData, '/product/productaggr/' + activeTab);

    //console.log("products:", products.data);

    if (products.data.length > 0) {
      let productIds = products.data.map(p => +p.productId);
      let channelIds = products.data.map(p => +p.channelId);
      //filterOptionState.SpecialityOption= options.specialIds;
      filterOptionState.Brand_Option = await commonService.filterPostService({
        productIds
      }, '/product/brandFilter');
      setFilterOption({ ...filterOptionState });

      // filterOptionState.Family_Option = await commonService.filterPostService({
      //   productIds
      // }, '/product/familyFilter');
      // setFilterOption({ ...filterOptionState });

      filterOptionState.Current_Product_Option = await commonService.filterPostService({
        productIds
      }, '/product/categoryFilter');
      setFilterOption({ ...filterOptionState });

      let retailIds = await commonService.filterPostService({
        channelIds
      }, '/product/profileFilter');
      filterOptionState.Profile_Option = retailIds.length > 0 ? retailIds.filter(x => x.name.trim() !== '' && x.name && x.channelTypeId == 4) : [];
      filterOptionState.Reseller_Profile_Option = retailIds.length > 0 ? retailIds.filter(x => x.name.trim() !== '' && x.name && x.channelTypeId == 5) : [];
      setFilterOption({ ...filterOptionState });

      filterOptionState.Location_Option = await commonService.filterPostService({
        productIds
      }, '/product/locationFilter');
      setFilterOption({ ...filterOptionState });

      let Price_Option = await commonService.filterPostService({
        productIds
      }, '/product/priceFilter');

      setMinPrice(+Price_Option[0].minPrice);
      setMaxPrice(+Price_Option[0].maxPrice);
      setValue([+Price_Option[0].minPrice, +Price_Option[0].maxPrice]);

    }



    // if(data){
    //   let options = data.data;
    //   if(options){   
    //  filterOptionState.Brand_Option=options.brand;
    //  filterOptionState.Family_Option=options.family;
    //  filterOptionState.Category_Option=options.category;
    //  filterOptionState.Profile_Option=options.profile;
    //  filterOptionState.Location_Option=options.location;
    //  setFilterOption({...filterOptionState});
    //  }
    //  }
    //console.log('filterOptionState', filterOptionState);
  }

  // const toggle = tab => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // }
  //const [activeTab, setActiveTab] = useState('1');
  //const { control } = useForm({ mode:'onBlur' });

  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
  });
  function valuetext(value) {
    return `${value}°C`;
  }

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    handleChangeSelect('price', newValue);
    console.log("Price_Option:", newValue)
    setValue(newValue);
  };

  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    //console.log("check:",check,gname);
    if (check == true) {
      filterOptionState[optionName].map((item) => {

        if (item.groupname == gname || item.status == true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check == false) {
      console.log("check:", check);
      filterOptionState[optionName].map((item) => {
        if (item.groupname == gname && filterValueState[optionValue].length > 0)
          item.status = check;
        // if(state.productValue.length==0)
        //     item.status=false;
      });

      opt = filterValueState[optionValue].filter((item) => item.groupname != gname);
      opt.map(option => option.status = true);
    }

    filterValueState[optionValue] = opt;
    setFilterValue({ ...filterValueState, [optionValue]: filterValueState[optionValue] });
    setFilterOption({ ...filterOptionState, [optionName]: filterOptionState[optionName] });

    // if(optionValue='ChannelProfileValue' && state.ChannelProfileValue.length==0)
    //       errors.relevantChannelProfiles=true;
    // else 
    // if (optionValue = 'productValue' && state.productValue.length == 0)
    //   errors.productCat = true;
  }

  const style = { height: '18rem' };

  return (
    <div className='fav-ser1 p-2'>
      <h4 data-dismiss="modal" className="clearfilter text-muted text-underline mb-0" type="button" onClick={getSearchAggregation}>Clear all</h4>
      <div className={classes.root}>

        {/* Product category' */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Product category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Current_Product_Option?.length > 0 ? {height: '19rem'} : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocompleteGrouping
                        id="current_product"
                        name="current_product"
                        multiple={true}
                        //label="Product Families are you selling *"
                        Options={filterOptionState.Current_Product_Option}
                        placeholder="Select Product Families"
                        input='current_product'
                        //errMessage={'Please Select a Product Family'}
                        //error={!errors.productCat || productFamilies.length ?false:true}
                        defaultValue={filterValueState.Current_Product}
                        value={filterValueState.Current_Product}

                        optionName='Current_Product_Option'
                        optionValue='Current_Product'
                        selectedItem={selectedItem}

                        //getOptionSelected={(options, value) => options.name === value.name}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        control={control}
                      //register={register}

                      />
                    </div>
                  </div>

                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Brand */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Brand</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Brand_Option.length > 0 ? {height: '14rem'} : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Brand"
                        name="Brand"
                        label=""
                        options={filterOptionState.Brand_Option}
                        placeholder="Type Brand"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='brand'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.Brand}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Positioning */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Positioning</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.PositionOption.length > 0 ? {height: '8rem'} : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Positioning"
                        name="Positioning"
                        label=""
                        options={filterOptionState.PositionOption}
                        placeholder="Type Positioning"
                        //multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='position'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterOptionState.PositionOption}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>


        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Category_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Category"
                        name="Category"
                        label=""
                        options={filterOptionState.Category_Option}
                        placeholder="Type Category"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        getOptionSelected={(options, value) => options.name === value.name}
                        input='category'
                        value={filterValueState.Category}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Family</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Family_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Family"
                        name="Family"
                        label=""
                        options={filterOptionState.Family_Option}
                        placeholder="Type Family"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='family'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.Family}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion> */}

        {/* Target countries */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Target countries</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Location_Option.length > 0 ? {height: '18rem'} : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="country"
                        name="country"
                        label=""
                        options={filterOptionState.Location_Option}
                        placeholder="Type Target location"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='location'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.Location}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>

                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Target retailer profiles */}
        {(user.channelTypeId == 4 || (user.channelTypeId == 3 && (user.businessType == 3 || user.businessType == 1))) && <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Target retailer profiles</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Profile_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="profile"
                        name="profile"
                        label=""
                        options={filterOptionState.Profile_Option}
                        placeholder="Type Retailer Profile"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='profile'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.Profile}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>}

        {/* Target reseller profiles */}
        {(user.channelTypeId == 5 || (user.channelTypeId == 3 && (user.businessType == 3 || user.businessType == 2))) && <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Target reseller profiles</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Reseller_Profile_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="reseller_profile"
                        name="reseller_profile"
                        label=""
                        options={filterOptionState.Reseller_Profile_Option}
                        placeholder="Type Reseller Profile"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='reseller_profile'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.Reseller_Profile}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>}

        <ListGroupItem style={{ height: '6rem' }}>
          <div className=" text-center mt-2">
            <div className="form-group">
              <Typography id="range-slider" gutterBottom>
                Price Range
              </Typography>
              <Slider
                name="price"
                value={value}
                min={minPrice}
                max={maxPrice}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
              />
            </div>
          </div>
        </ListGroupItem>
      </div>
    </div>
  );
}

export default Prodlistingfilter;