import React, { useEffect, useState } from 'react';
import { Button, Modal, UncontrolledTooltip } from "reactstrap";
import { Card, CardBody, ListGroupItem, ListGroup, Row, Col } from "reactstrap";
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
import UserView from "views/Hoc/Dialog/UserView";
import axios from 'axios';

import { ChannelDismiss } from 'views/Hoc/Dialog/ChannelDismiss';
import { ChannelConnect } from "views/Hoc/Dialog/ChannelConnect";
import { AskmeetingPopup } from "views/Hoc/Dialog/AskmeetingPopup";
import { Dialog, UpgradeModal } from "views/Hoc/Dialog/GlobalDialog";
import { history } from '../../../_helpers/history';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import VideoPreview from 'views/Hoc/Dialog/VideoPreview';

export const Contact_list = (props) => {
  const { viewId } = props;
  const { filterValueState, activeTab, search } = props;
  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png';
  let companyLogo = '';
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(12);
  let [total, setTotal] = useState(0);


  const [showMore, setMore] = useState(false);
  let [dismissPopUp, setDismissPopUp] = useState(false);
  let [connectPopUp, setConnectPopUp] = useState(false);
  let [isModalPopup, setModalPopup] = useState(false);
  let [isUpgradePopup, setUpgradePopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  let [UpdateData, setUpdateData] = useState(null);
  let [askMeeting, setAskMeeting] = useState(false);
  let [selectedContact, setSelectedContact] = useState(null);
  let [connectionLimit, setConnectionLimit] = useState({ connectLimit });
  let [suggest_Count, setSuggest_Count] = useState(0);
  let [outboundRemaind, setOutboundRemaind] = useState({ remaining: null, end_date: null });
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  let [dissmissFrom, setDissmissFrom] = useState(null);
  const [open, setOpen] = useState(false);
  let [partnerId, setPartnerId] = useState(props.channelId);
  const [viewUserData, setUserData] = useState();
  const [viewuser, setsviewuser] = useState(false);
  let mynetwork = true;

  useEffect(() => {
    setLoading(true);
    getContactList();
  }, [filterValueState]);
  useEffect(() => {
    getSugOutboundcount();
  }, []);
  let applyBlur = (userData.planId === '1');

  // useEffect(() => { setContactData(contactData);  }, [contactData]);

  const getContactList = async () => {
    setLoading(true);
    let postData = {
      filter: getFilterData(),
      order: filterValueState?.sort ? 'asc' : 'desc',
      search: filterValueState?.search ? filterValueState?.search.id : null,
      offset: page * rowsPerPage,
      // offset:1,
      limit: rowsPerPage,
    }
    let data = postData;

    let res = await commonService.filterPostService(data, '/channel/profileView/' + viewId).catch(err => setLoading(false));
    if (res) {
      setContactData(res.result);
      setTotal(res.total);
      setLoading(false);
    } else {
      console.log('No Data');
      setLoading(false);
    }
  }

  const getFilterData = () => {
    let filter = {
      specialityId: null,
      brandId: null,
      countryId: null,
      locationId: null,
      retailerprofileId: null,
      productCategoryId: null,
      turnOver: null,
      connectionStatusId: null,
      typeId: null
    };
    if (filterValueState?.Speciality && filterValueState?.Speciality.length > 0) {
      filter.specialityId = filterValueState?.Speciality.map(x => parseInt(x.id));
    }
    if (filterValueState?.Brand && filterValueState?.Brand.length > 0) {
      filter.brandId = filterValueState?.Brand.map(x => parseInt(x.id));
    }
    if (filterValueState?.Company_Origin && filterValueState?.Company_Origin.length > 0) {
      filter.countryId = filterValueState?.Company_Origin.map(x => parseInt(x.id));
    }
    if (filterValueState?.Company_Location && filterValueState?.Company_Location.length > 0) {
      filter.locationId = filterValueState?.Company_Location.map(x => parseInt(x.id));
    }
    if (filterValueState?.Retailer_Profile && filterValueState?.Retailer_Profile.length > 0) {
      filter.retailerprofileId = filterValueState?.Retailer_Profile.map(x => parseInt(x.id));
    }
    if (filterValueState?.productCategory && filterValueState?.productCategory.length > 0) {
      filter.productCategoryId = filterValueState?.productCategory.map(x => parseInt(x.id));
    }
    if (filterValueState?.Annual_Turnover) {
      filter.turnOver = filterValueState?.Annual_Turnover.id;
    }
    if (filterValueState?.connectionStatusId) {
      filter.connectionStatusId = filterValueState?.connectionStatusId.id;
    }
    if (filterValueState?.typeId) {
      filter.typeId = filterValueState?.typeId.id;
    }
    return filter;
  }

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getContactList();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    getContactList();
  };


  const getSugOutboundcount = async () => {
    let res = await commonService.getServices('channel/getSugOutboundcount/' + userData.contactId).catch(err => console.log('err', ToastsStore.danger('Soming went wrong')));
    if (res) {
      console.log(res);
      getRemainingCount(res.data);
      setLimit(res.data);
    }
  }

  const more_click = () => {
    if (!showMore) setMore(true);
    else setMore(false);
  }
  const openPopUp = async (contact, status, title) => {

    let typeId = parseInt(contact?.record?.channel?.channelTypeId);
    if (((applyBlur && contact.isConfidential == '1') || (connectionLimit.connectLimit[typeId].limit !== -1 && suggest_Count > connectionLimit.connectLimit[typeId].limit))
      && (parseInt(contact.relationId) === 1 || parseInt(contact.relationId) === null || parseInt(contact.relationId) === 0)
      && (typeId === 4 || typeId === 3)) {
      openUpgradePopup();
      return false;
    }
    UpdateChannelData.connectionStatusTypeId = status;
    UpdateChannelData.channelJCTId = parseInt(contact.channelJCTId);
    UpdateChannelData.contactid = contact.record.contactId;
    UpdateChannelData.receiver_mail = contact.record.email;
    UpdateChannelData.receiver_photo = contact.record.documenturl ? contact.record.documenturl : logo
    UpdateChannelData.receiver_name = contact.record.firstName;// + ' ' + contact.record.lastName;
    UpdateData = UpdateChannelData;
    setUpdateData(UpdateData);
    if (status === 5) {
      if (contact.request == 'OUT')
        dissmissFrom = "Outbound";
      else if (contact.request == 'IN')
        dissmissFrom = "Inbound";
      else if (contact.relationId == 2)
        dissmissFrom = "Confirm";
      else
        dissmissFrom = "Suggestion";
      setDissmissFrom(dissmissFrom);
      if (mynetwork == true) {
        partnerId = contact.record.channelId;
        setPartnerId(partnerId);
      }
      setDismissPopUp(true);
    } else if (status === 7) {
      if (mynetwork == true) {
        partnerId = contact.record.channelId;
        setPartnerId(partnerId);
      }
      setConnectPopUp(true);
    } else if (status === 10) {
      if (mynetwork == true) {
        partnerId = contact.record.channelId;
        setPartnerId(partnerId);
      }
      modalPopValue.message = 'You wish to connect to a retail buyer. The process for this connection is different from contacting a distributor. For retailer, ChannelHub will collect your request and will include it in a summary report compiling all the requests from various suppliers. This presentation will be done every month. Once the presentation is done, we’ll inform you about their decision on the platform.';
      modalPopValue.second_message = 'MAKE SURE THAT YOUR COMPANY PROFILE IS COMPLETE INCLUDING PRODUCT LIST BEFORE PROCEEDING AS THIS WILL BE THE ONLY INFORMATION PRESENTED IN THE SUMMARY REPORT TO THE BUYERS';
      modalPopValue.title = "IMPORTANT";
      modalPopValue.value = UpdateData;
      modalPopValue.popUpId = 1;
      setModalPopValue({ ...modalPopValue });
      setModalPopup(true);
    } else if (status === 11 || status === 2) {
      if (mynetwork == true) {
        partnerId = contact.record.channelId;
        setPartnerId(partnerId);
      }
      modalPopValue.message = status === 11 ? 'Do you wish to move this request for a later decision?' : 'Do you wish to approve this contact?';
      modalPopValue.title = title;
      modalPopValue.value = UpdateData;
      modalPopValue.popUpId = 1;
      setModalPopValue({ ...modalPopValue });
      setModalPopup(true);
    }
  }
  const openDialogAskMeeting = (contact) => {
    console.log(contact);
    selectedContact = contact;
    setSelectedContact(selectedContact);
    if (localStorage.getItem("ask_Meeting_show_Again") !== '1') {
      setAskMeeting(true);
    } else {
      saveAskMeeting(selectedContact);
    }
  }
  const openUpgradePopup = () => {
    setUpgradePopup(true);
  }
  const dismissPopUpClose = () => {
    setDismissPopUp(false);
  }
  const connectPopUpClose = () => {
    setConnectPopUp(false);
  }
  const closePopup = () => {
    setModalPopup(false);
  };
  const askMeetingPopUpClose = () => {
    setAskMeeting(false);
  }
  const closeUpgradePopup = () => {
    setUpgradePopup(false);
  }
  const confirmPopup = (res) => {
    console.log('res', res);
    setModalPopup(false);
    updateChannel(res.value);
  }
  const saveAskMeeting = async (channellist) => {
    setAskMeeting(false);
    let meeting = {
      senderChannelId: parseInt(userData.channelId),
      senderContactId: parseInt(userData.contactId),
      // alt_senderContactId : Number(localStorage.getItem("fo-user-subscription-contact-id")),
      // receiverChannelId: parseInt(channelId),
      receiverContactId: parseInt(channellist.record.contactId),
      // alt_receiverContactId : channellist.default_contact_id,
      // meeting_Scheduled_At : Number(Date()),
      // isMeetingConfirm : 1,
      // isMeetingCompleted : 1,
    }
    let res = await commonService.postService(meeting, 'Meeting').catch(err => console.log('err', ToastsStore.danger('Soming went wrong')));
    if (res) {
      ToastsStore.success('Meeting Request Sent Successfully');
      // getContactList();
      // history.push('/admin/channel');
      console.log(res);
    } else {
      ToastsStore.danger('Soming went wrong');
    }
  }
  const updateChannel = async (value) => {
    console.log('value', value);
    setDismissPopUp(false);
    setConnectPopUp(false);

    let res = await commonService.postService(value, 'channel/updateChannel/' + partnerId).catch(() => ToastsStore.error('Soming went wrong'));
    if (res) {
      // getContactList();
      // history.push('/admin/channel');
      ToastsStore.success(value?.connectionStatusTypeId == 5 ? 'Contact Dismissed Successfully' : value?.connectionStatusTypeId == 2 ? 'Contact Approval Successfully' : 'Contact Update Successfully');
      if (value?.connectionStatusTypeId == 7) {
        Object.assign(value, { req_contact: userData.contactId, req_channel: userData.channelId, typeId: userData.channelTypeId })
        axios.post('mailerservice/Connection_Request_Mail', value);
      }
      else if (value?.connectionStatusTypeId == 5) {
        Object.assign(value, { req_contact: userData.contactId })
        //axios.post('mailerservice/Connection_Dismiss_Mail', value);
      }
      else if (value?.connectionStatusTypeId == 2) {
        Object.assign(value, { req_contact: userData.contactId })
        axios.post('mailerservice/Connection_Approve_Mail', value);
      }
      getContactList();

      console.log(res);
    }

  }
  const getRemainingCount = (count) => {
    let distributor_sug = 0;
    let retailer_sug = 0
    let limit = 0;
    let total_sug = 0
    let remaining = 0;
    let end_date;
    if (count) {
      count.map(x => {
        if (x.suggestionfor === 'DISTRIBUTOR_OTB') {
          distributor_sug = x.suggestioncount ? +x.suggestioncount : 0;
        } else if (x.suggestionfor === 'RETAILER_OTB') {
          retailer_sug = x.suggestioncount ? +x.suggestioncount : 0;
          limit = parseInt(x.limit);
          end_date = x.endat;
        }
      });
      if (limit !== -1) {
        if (userData.channelTypeId === '2') {
          total_sug = distributor_sug + retailer_sug;
          if (total_sug <= limit) {
            remaining = limit - total_sug;
          } else {
            remaining = 0;
          }
        } else if (userData.channelTypeId === '3') {
          if (retailer_sug <= limit) {
            remaining = limit - retailer_sug;
          } else {
            remaining = 0;
          }
        }
      } else {
        remaining = -1;
      }
    }
    outboundRemaind.outbound = remaining;
    outboundRemaind.expireDate = end_date;
    setOutboundRemaind({ ...outboundRemaind });
  }
  const setLimit = (limit) => {
    let outbountLimit = connectionLimit.connectLimit
    limit.map(x => {
      if (x.suggestionfor === 'VENDOR_OTB') {
        outbountLimit[2].endat = x.endat;
        outbountLimit[2].limit = x.limit;
        outbountLimit[2].suggestioncount = x.suggestioncount ? parseInt(x.suggestioncount) : 0;
      } else if (x.suggestionfor === 'DISTRIBUTOR_OTB') {
        outbountLimit[3].endat = x.endat;
        outbountLimit[3].limit = x.limit;
        outbountLimit[3].suggestioncount = x.suggestioncount ? parseInt(x.suggestioncount) : 0;
      } else if (x.suggestionfor === 'RETAILER_OTB') {
        outbountLimit[4].endat = x.endat;
        outbountLimit[4].limit = x.limit;
        outbountLimit[4].suggestioncount = x.suggestioncount ? parseInt(x.suggestioncount) : 0;
      }
    });
    if (userData.channelTypeId === '2') {
      suggest_Count = outbountLimit[3].suggestioncount + outbountLimit[4].suggestioncount;
    } else if (userData.channelTypeId === '3') {
      suggest_Count = outbountLimit[4].suggestioncount;
    } else {
      suggest_Count = outbountLimit[2].suggestioncount + outbountLimit[3].suggestioncount;
    }
    setConnectionLimit({ connectLimit });
    setSuggest_Count(suggest_Count)
  }
  const userview = (data) => {
    console.log('data', data);
    let values = { firstName: data.firstName, lastName: data.lastName, country: data.country.country, documentPath: process.env.REACT_APP_CDN_URL + (data.document ? data.document.documentPath : 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'), jobTitle: data.jobTitle, roleId: data?.user?.role?.roleId, contactId: data.contactId }
    setUserData(values)
    setsviewuser(true);
  }

  const closeUserView = () => {
    setsviewuser(false);
  }
  return (
    <div id="iner" className="main-content mt-3">
      {(contactData.length <= 0 && !loading) && <div className="no-records ">
        {/* <span>No Records</span> */}
        <div className="no-img text-center">
          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} /></div>
      </div>}

      {loading ? <Spinner /> : <>
        <Row className="justify-content-left ">
          {viewuser &&
            <UserView userData={viewUserData} closeUserView={closeUserView} />}
          <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
          <ChannelDismiss isOpen={dismissPopUp} dismissFrom={dissmissFrom} handleClose={dismissPopUpClose} value={UpdateData} updateChannel={updateChannel} />
          <ChannelConnect isOpen={connectPopUp} handleClose={connectPopUpClose} value={UpdateData} updateChannel={updateChannel} outboundRemaind={outboundRemaind} />
          <AskmeetingPopup isOpen={askMeeting} handleClose={askMeetingPopUpClose} value={selectedContact} handleSave={saveAskMeeting} />
          <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} outboundRemaind={outboundRemaind} />
          <UpgradeModal open={isUpgradePopup} value={modalPopValue} subscriptionTurn={() => history.push('/admin/Subscription')} gotoWallet={closeUpgradePopup} handleClose={closeUpgradePopup} />
          {contactData.length > 0 && contactData.map(x => {
            console.log('data', x);
            let channelTypeId = x?.record?.channel?.channelTypeId;
            let isConfidential = (userData.channelType == 2 && x.isConfidential == '1' && userData.planId == 1) ? true : false;
            logo = process.env.REACT_APP_CDN_URL + (x?.record?.document ? x?.record?.document.documentPath : 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg');
            companyLogo = x?.record?.channel?.channelDetail?.channelLogo?.documentPath;
            companyLogo = process.env.REACT_APP_CDN_URL + (companyLogo ? companyLogo : 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg');
            return (

              <Col xl="4" lg="6" sm="6" xs="12">
                <Card>
                  <div className="detv-contact">
                    <div className="row">
                      <div className="conne-img curs-point" onClick={() => isConfidential ? console.log('null') : userview(x.record)}>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="contact img" src={logo} />
                      </div>
                      <div className="conne-comp ml-4 curs-point" onClick={() => history.push('/admin/profileView/channelDetail/' + x?.record?.channelId)}>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="Brandimg" alt="contact img" src={companyLogo} />
                      </div>
                    </div>
                    <div className="conn-cont">
                      <h3 style={{ marginTop: "1rem" }}>{x?.record?.firstName + ' ' + x?.record?.lastName}</h3>
                      <h5 style={{ marginBottom: "1rem" }}> {x?.record?.jobTitle + ', ' + x?.record?.country?.country} </h5>
                      <h4 style={{ color: "#2e9cb7" }} className="curs-point" onClick={() => history.push('/admin/profileView/channelDetail/' + x?.record?.channelId)} > {x?.record?.channel.companyName} </h4>
                      {/* <h4 style={{color:"#2e9cb7"}}> {x.channel.companyName} </h4> */}
                    </div>
                    <div style={{ textAlign: "center" }} className="conn-btn">
                      {x.record?.contactSocialLink?.length &&
                        x.record.contactSocialLink.map(y => (
                          <>
                            {y.contactSocialLink && <a id={'social-id' + x.record.contactId} onClick={() => {
                              let url = y.contactSocialLink;
                              if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                                url = '//' + url;
                              }
                              window.open(url, '_blank')
                            }}>
                              <Button className="btn-icon-only mr-2 i-link" color="twitter" type="button">
                                <span className="btn-inner--icon">
                                  <i className="fab fa-linkedin" />
                                </span>
                              </Button>
                              <UncontrolledTooltip target={"social-id" + x.record.contactId} placement='bottom'>
                                linkedin
                              </UncontrolledTooltip>
                            </a>
                            }</>
                        ))
                      }
                      {x.relationId === 2 && <>
                        <Button className="btn-warning btn-icon-only" type="button" onClick={() => openPopUp(x, 5, 'Dismiss')}>
                          <span className="btn-inner--icon">
                            <i style={{ top: "0px" }} class="fas fa-minus-circle"></i>
                          </span>
                        </Button>

                        <Button className="btn-primary btn-icon-only" color="default" type="button">
                          <a id={"email-id" + x.record.contactId} style={{ color: 'inherit' }} href={'mailto:' + x.record?.email} >
                            <span className="btn-inner--icon">
                              <i style={{ top: "0px" }} className="far fa-envelope">
                              </i>
                            </span>
                          </a>
                          <UncontrolledTooltip target={"email-id" + x.record.contactId} placement='bottom'>
                            Email
                          </UncontrolledTooltip>
                        </Button>

                      </>}

                      {parseInt(x?.relationId) !== 2 && <>
                        {isConfidential ? <>
                          {userData.channelTypeId != 2 && (parseInt(x?.relationId) === 1 || parseInt(x?.relationId) === 0) && parseInt(channelTypeId) === 4 && <Button className="btn-secondary btn-icon-only" color="secondary" type="button" onClick={openUpgradePopup}>
                            <span className="btn-inner--icon">
                              <i class="fas fa-user-plus"></i>
                            </span>
                          </Button>}

                          {(userData.channelTypeId != 2 && (parseInt(x?.relationId) === 1 || parseInt(x?.relationId) === 0) && parseInt(channelTypeId) !== 4) && <Button className="btn-secondary btn-icon-only" color="secondary" type="button" onClick={openUpgradePopup}>
                            <span className="btn-inner--icon">
                              <i class="fas fa-user-plus"></i>
                            </span>
                          </Button>}

                          <Button className="btn-secondary btn-icon-only" color="secondary" type="button" onClick={openUpgradePopup}>
                            <span className="btn-inner--icon">
                              <i class="fas fa-minus-circle"></i>
                            </span>
                          </Button>
                        </> : <>
                          {(parseInt(x?.relationId) === 1 || parseInt(x?.relationId) === 0) && parseInt(channelTypeId) === 4 && userData.channelTypeId != 2 && <Button className="btn-primary btn-icon-only" color="default" type="button" onClick={() => openPopUp(x, 10, 'Request Intro')}>
                            <span className="btn-inner--icon">
                              <i class="fas fa-user-plus"></i>
                            </span>
                          </Button>}
                          {(parseInt(x?.relationId) === 1 || parseInt(x?.relationId) === 0) && parseInt(channelTypeId) !== 4 && userData.channelTypeId != 2 && <Button className="btn-primary btn-icon-only" color="default" type="button" onClick={() => openPopUp(x, 7, 'Connect')}>
                            <span className="btn-inner--icon">
                              <i class="fas fa-user-plus"></i>
                            </span>
                          </Button>}

                          <Button className="btn-warning btn-icon-only" type="button" onClick={() => openPopUp(x, 5, 'Dismiss')}>
                            <span className="btn-inner--icon">
                              {/* <i class="fas fa-thumbs-down"></i> */}
                              <i class="fas fa-minus-circle"></i>
                            </span>
                          </Button>

                          {((parseInt(x?.relationId) === 7 || parseInt(x?.relationId) === 11 || parseInt(x?.relationId) === 10) && x.introVideo !== null) &&
                            <Button className="btn-slack btn-icon-only" color="default" type="button" onClick={() => setOpen(true)}>
                              <span className="btn-inner--icon">
                                <i class="fas fa-video"></i>
                              </span>
                              <VideoPreview videoModalClose={() => setOpen(false)} isOpen={open} url={process.env.REACT_APP_CDN_URL + x.introVideo} />
                            </Button>}

                          {(parseInt(x?.relationId) === 7 || parseInt(x?.relationId) === 11 || parseInt(x?.relationId) === 10) && (x?.request === 'IN' || x?.request === 'SAVELATER') &&
                            <Button className="btn-slack btn-icon-only" color="default" type="button" onClick={() => openPopUp(x, 2, 'Approve')}>
                              <span className="btn-inner--icon">
                                <i className="fas fa-check-circle"></i>
                              </span>
                            </Button>}

                        </>}
                      </>}

                    </div>
                    {/* <div class=""><div class="ribbon-9">User</div></div> */}
                  </div>
                </Card>
              </Col>

            )
          })}
        </Row>
      </>}
      {(contactData.length > 0 && contactData.length > 11) &&
        <TablePagination
          component="div"
          count={total}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[12, 24, 36, 48]}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      }

    </div>
  );
}
export default Contact_list;

const getStatus = (contacts) => {
  let status = '';
  console.log("getstatus:", contacts);
  if (parseInt(contacts.relationId) !== 0 && contacts.request) {
    if (parseInt(contacts.relationId) === 1) {
      status = 'Best Match'
    }
    if (parseInt(contacts.relationId) === 2) {
      status = 'Connected'
    }
    if (contacts.request === 'SAVELATER') {
      status = 'Saved For Later'
    }
    if (contacts.request === 'OUT' && (parseInt(contacts.relationId) === 7 || parseInt(contacts.relationId) === 11)) {
      status = 'Outbound'
    }
    if (contacts.request === 'IN' && (parseInt(contacts.relationId) === 7 || parseInt(contacts.relationId) === 10)) {
      status = 'Inbound'
    }
    if (contacts.request === 'REQUESTINTRO') {
      status = 'Request Intro'
    }
  } else {
    status = 'Non Suggestion'
  }
  return status;
}
const UpdateChannelData = {
  connectionStatusTypeId: null,
  channelJCTId: null,
  dismissreasonid: null,
  otherreasons: null,
  leadManagementTag: null,
  partnerLeadManagementTag: null,
  note: null,
  contactid: null,
  receiver_mail: null,
  receiver_name: null,
  receiver_photo: null,
  videoUrl: null
}

const connectLimit = {
  2: {
    endat: null,
    limit: -1,
    suggestioncount: 0,
    suggestionfor: "VENDOR_OTB"
  },
  3: {
    endat: null,
    limit: 0,
    suggestioncount: 0,
    suggestionfor: "DISTRIBUTOR_OTB"
  },
  4: {
    endat: null,
    limit: 0,
    suggestioncount: 0,
    suggestionfor: "RETAILER_OTB"
  }
}