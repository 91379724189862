import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { Row, Col } from "reactstrap";
import Prodlistingfilter from "./Prodlistingfilter";
import Product from "./Product-List";
import ListingFilters from './ProductListingFilters';
import { Spinner } from "views/Hoc/Spinner";


const ProductWrapper = (props) => {
  const { activeTab, search } = props;
  let [flag, setFlag] = useState(0);
  let [productFlag, setProductFlag] = useState(true);

  let [parent, setParent] = useState(null);
  const [filterValueState, setFilterValue] = useState({ Current_Product: [], Price: [], Position: [], Brand: [], Family: [], Category: [], Profile: [], Reseller_Profile: [], Location: [], search: null, sort: true });
  let [searchState, setSearch] = useState(null);
  useEffect(() => {
    if (activeTab)
      handleChangeSelect('search', search);

    console.log("ini search")
  }, [search, activeTab]);

  const handleChangeSelect = (input, value) => {
    console.log('input, value', input, value);
    if (input === 'search') {
      searchState = value;
      setSearch(searchState);
      filterValueState.search = value;
    }
    if (input === 'sort') {
      filterValueState.sort = value;
    }
    if (input === 'position') {
      filterValueState.Position = value;
      if (parent == null || parent == 'Position') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Position';
        setParent(parent);
        setProductFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'brand') {
      filterValueState.Brand = value;
      if (parent == null || parent == 'Brand') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Brand';
        setParent(parent);
        setProductFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'profile') {
      filterValueState.Profile = value;
      if (parent == null || parent == 'Profile') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Profile';
        setParent(parent);
        setProductFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'reseller_profile') {
      filterValueState.Reseller_Profile = value;
      if (parent == null || parent == 'Profile') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Profile';
        setParent(parent);
        setProductFlag(false);
      } else
        setFlag(0);
    }
    // if (input === 'family') {
    //   filterValueState.Family = value;
    //   if (parent == null || parent == 'Family') {
    //     setFlag(1 + Math.random() * (100 - 1));
    //     parent = 'Family';
    //     setParent(parent);
    //     setProductFlag(false);
    //   } else
    //     setFlag(0);
    // }
    if (input === 'current_product') {
      filterValueState.Current_Product = value;
      if (parent == null || parent == 'Product') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Product';
        setParent(parent);
        setProductFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'location') {
      filterValueState.Location = value;
      if (parent == null || parent == 'Location') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Location';
        setParent(parent);
        setProductFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'price') {
      filterValueState.Price = value;
      if (parent == null || parent == 'Price') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Price';
        setParent(parent);
        setProductFlag(false);
      } else
        setFlag(0);
    }

    setFilterValue({ ...filterValueState })
    //console.log('filterValueState', filterValueState);
  }
  const resetFilterValue = () => {
    setFlag(0);
    setParent(null);
    filterValueState.Position = [];
    filterValueState.Brand = [];
    filterValueState.Product = [];
    filterValueState.Location = [];
    filterValueState.Profile = [];
    filterValueState.Reseller_Profile = [];
    //filterValueState.Category = [];
    filterValueState.search = null;
    filterValueState.sort = null;
    setFilterValue({ ...filterValueState });
    //console.log('filterValueState', filterValueState);
  }
  return (
    <Card>
      <CardBody>
        <Row className="pt-4">
          {/* Left Filter */}
          {/* <Col id="leftFilter" lg="3" className="p-0 ml--4">
            <Prodlistingfilter setProductFlag={setProductFlag} parent={parent} flag={flag} setFilterValue={setFilterValue} activeTab={activeTab} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} />
          </Col> */}


          <Col style={{ backgroundColor: "#f8f9fe" }} lg="12">
            {/* Body */}
            {!productFlag ? <Spinner /> : (
              <Product activeTab={activeTab} productFlag={productFlag} filterValueState={filterValueState} search={searchState} />)}
          </Col>

        </Row>
      </CardBody>
    </Card>)
};
export default ProductWrapper;
