import React from "react";
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone';
import PdfViewer from "./PdfViewer";
import axios from 'axios';
const MultiUpload = props => {
const {handleChange} = props;
const [state, setState] = React.useState({files : []});
const [files, setFiles] = React.useState([]);
  const handlePreviewIcon = (fileObject, classes) => {
      const {type} = fileObject.file
      //console.log('fileObject',fileObject)
      const iconProps = {
        className : classes.image,
      }
    
      if (type.startsWith("video/")) return <video style={{width:'100px',height:'100px'}}><source src={fileObject.data} /></video>
      if (type.startsWith("audio/")) return <video style={{width:'100px',height:'100px'}}><source src={fileObject.data} /></video>
    
      switch (type) {
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return <div></div>
        case "application/pdf":
          return  <PdfViewer edit={true}/>
        default:
          return     <div className="preview-image"><div className="image-Preview"><img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt="..."                
                    src={fileObject.data}/>
            </div>
            <div className="BrandName"  >Image <i  aria-hidden="true" className="fas fa-edit" style={{position: 'absolute',
  right: '0px',zIndex: 9999}}></i></div>
          </div>
      }
    }
    const handleSave =(data)=>{
      console.log('save', data)
    }
    const handleClose =(data)=>{
      console.log('handleClose', data[0])
    }

  
    const handleChange1=(files)=>{  
      setState({files: files});
      console.log('files', state);
    }

    const onAdd = (newFiles) => {
      // let temp = state.files.filter((v,i,a)=>a.findIndex(t=>(t.size === v.size && t.name===v.name))===i)
      // console.log('temp', temp);
      // console.log('add', files, files[0].name);
      // console.log('new', newFiles);
      if(files.length >= props.limit)
        return 1;
      newFiles = newFiles.filter(file => !files.find(f => f.data === file.data));
      
      newFiles.length && newFiles.map( x => props.handleChangeFile(props.input, x.file, x.file.name)); //This is not ordinary handleChange its handleChangeSelect like function
      setFiles([...files, ...newFiles]);
      // console.log('files', files);
    }

    const onDelete = (deletedFiles) => {
    props.handleChangeFileDelete(props.input, deletedFiles.file.name);
    setFiles(files.filter(f => f !== deletedFiles));
      // console.log('delfiles', deletedFiles);
      // console.log('de', files);
    }

  return (
    <div id="dropzone_pdf">
    {
      props.multiple ? 
    <DropzoneAreaBase
    acceptedFiles={props.acceptedFiles}
    fileObjects={files}
    // open={this.state.open}
    getPreviewIcon={handlePreviewIcon}
    onSave={handleSave}
    onClose={handleClose}
    // onChange={handleChange1}
    // onDrop={onAdd}
    // onDelete={onDelete}
    onAdd={onAdd}
    onDelete={onDelete}
    filesLimit={props.limit? props.limit : '1'}
    Icon={files.length ? 'noIcon' : ''}
    dropzoneText={files.length ? '' : 'Drag and Drop a File here or Click.'}
    maxFileSize={5000000}
    />
    :
    <DropzoneArea
    acceptedFiles={props.acceptedFiles}
    // open={this.state.open}
    onSave={handleSave}
    onClose={handleClose}
    onChange={handleChange}
    filesLimit={props.limit? props.limit : 1}
    // maxFileSize={props.size=='1' ? 204800 : props.size=='2' ? 104857600 : 2097152}
    maxFileSize={props.size=='1' ? 5300000 : props.size=='2' ? 207507600 : 5300000}
    />
    } 
    </div>
  );
}

export default MultiUpload;
