import React from 'react';
import { Card,CardBody, Container,Row,Col } from "reactstrap";

const PubTitle = (props) => {
    console.log('desc', props.desc);
    return (
        <div>
            <div className="main-content">
            <Container className="mt-4 col-12" fluid>
            <Row>
          <Col className="card-wrapper" lg="12" md="12" sm="12" >
        <Card className="tit-card">
                <CardBody style={{padding:'2rem'}} >
                    <Row>
                        <Col className="col-lg-12 col-md-12 col-12 text-center text-uppercase"><h1> {props.title}</h1></Col>
                        {/* <Col className="col-lg-10 pg-title ">
                          <p className='text-muted'>
                            {props.desc.map(x=>(
                              <>
                              {x}<br/>
                              </>
                            ))}
                            <br/>
                            </p></Col> */}
                    </Row>
                </CardBody>
        </Card>
        </Col>
        </Row>
        </Container>
      </div>
        </div>
    );
};

export default PubTitle;