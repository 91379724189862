import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { Row, Col } from "reactstrap";
import Channel from "views/pages/Channel-Listing/Channel-List";
import Transaction from "views/pages/Channel-Listing/Transaction";
import OldChannel from "views/pages/Channel-Listing/OldChannel-List";
import CompanyListingFilters from "./CompanyListingFilter";
import ListingFilter from "./Listingfilter";
import OldChannelFilter from "./OldChannelFilter";
import { Spinner } from "views/Hoc/Spinner";

const ChannelWrapper = (props) => {
  const { activeTab, search, toBreadCrumb } = props;
  const [filterValueState, setFilterValue] = useState({ Interested_Product: [], Current_Product: [], Speciality: [], Brand: [], Retailer_Profile: [], Reseller_Profile: [], Company_Origin: [], Target_Location: [], Annual_Turnover: null, typeId: null, Store_Count: null, sourceId:null, event:null });
  const [sort, setSort] = React.useState(true);
  let [flag, setFlag] = useState(0);
  let [channelFlag, setChannelFlag] = useState(true);
  let [parent, setParent] = useState(null);
  let [searchState, setSearch] = useState(null);

  useEffect(() => {
    if (activeTab)
      handleChangeSelect('search', search);
  }, [search]);

  const handleChangeSelect = (input, value) => {
    console.log('input, value hereee', input, value);
    if (input === 'search') {
      searchState = value;
      setSearch(searchState);
      //console.log('searchState',searchState);
    }
    if (input === 'Speciality') {
      filterValueState.Speciality = value;
      if (parent == null || parent == 'Speciality') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Speciality';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'Brand') {
      filterValueState.Brand = value;
      if (parent == null || parent == 'Brand') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Brand';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'current_product') {
      filterValueState.Current_Product = value;
      if (parent == null || parent == 'current_product') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'current_product';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'interested_product') {
      filterValueState.Interested_Product = value;
      if (parent == null || parent == 'interested_product') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'interested_product';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'Retailer_Profile') {
      filterValueState.Retailer_Profile = value;
      if (parent == null || parent == 'Profile') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Profile';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'Reseller_Profile') {
      filterValueState.Reseller_Profile = value;
      if (parent == null || parent == 'Profile') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Profile';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'Company_Origin') {
      filterValueState.Company_Origin = value;
      if (parent == null || parent == 'Company_Origin') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Company_Origin';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    if (input === 'Target_Location') {
      filterValueState.Target_Location = value;
      if (parent == null || parent == 'Target_Location') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Target_Location';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }

    if (input === 'Annual_Turnover') {
      filterValueState.Annual_Turnover = value;
      if (parent == null || parent == 'Annual_Turnover') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Annual_Turnover';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }

    if (input === 'Store_Count') {
      filterValueState.Store_Count = value;
      if (parent == null || parent == 'Store_Count') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'Store_Count';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }

    if (input === 'channel_type') {
      filterValueState.typeId = value;
      if (parent == null || parent == 'typeId') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'typeId';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }
    
    if (input === 'sourceId') {
      filterValueState.sourceId = value;
      if (parent == null || parent == 'sourceId') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'sourceId';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }

    if (input === 'event') {
      filterValueState.event = value;
      if (parent == null || parent == 'event') {
        setFlag(1 + Math.random() * (100 - 1));
        parent = 'event';
        setParent(parent);
        setChannelFlag(false);
      } else
        setFlag(0);
    }

    console.log(' check filterValueState === ' , filterValueState);
    setFilterValue({ ...filterValueState })
    //console.log('filterValueState', filterValueState);
  }
  const resetFilterValue = () => {
    setFlag(0);
    setParent(null);
    filterValueState.Speciality = [];
    filterValueState.Current_Product = [];
    filterValueState.Interested_Product = [];
    filterValueState.Brand = [];
    filterValueState.Company_Origin = [];
    filterValueState.Target_Location = [];
    filterValueState.Retailer_Profile = [];
    filterValueState.Reseller_Profile = [];
    filterValueState.Reseller_Profile = [];
    filterValueState.Annual_Turnover = null;
    filterValueState.Store_Count = null;
    filterValueState.typeId = null;
    setFilterValue({ ...filterValueState });
    searchState = null;
    setSearch(searchState);
  }
  return (
    <Card>
      <CardBody>
        <h4 className="text-muted mb-0">FILTERS</h4>
        <Row className="pt-2">
          {/* Left Filter */}


          {(activeTab == '15') &&
            <Col id="leftFilter" lg="3" className="p-0 ml--4">
              {/* <CompanyListingFilters activeTab={activeTab} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} /> */}
              <OldChannelFilter activeTab={activeTab} setChannelFlag={setChannelFlag} parent={parent} flag={flag} setFilterValue={setFilterValue} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} />
            </Col>}

          {(activeTab != '15') &&
            <Col id="leftFilter" lg="3" className="p-0 ml--4 productList">
              {/* <CompanyListingFilters activeTab={activeTab} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} /> */}
              <ListingFilter activeTab={activeTab} setChannelFlag={setChannelFlag} parent={parent} flag={flag} setFilterValue={setFilterValue} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} />
            </Col>}

          {(activeTab == '3' || activeTab == '1' || activeTab == '2') &&
            <Col style={{ backgroundColor: "#f8f9fe" }} lg="9">
              {!channelFlag ? <Spinner /> : (
                <Transaction activeTab={activeTab} channelFlag={channelFlag} filterValueState={filterValueState} search={searchState} toBreadCrumb={toBreadCrumb} />)}

            </Col>
          }
          {(activeTab !== '3' && activeTab !== '1' && activeTab !== '2' && activeTab !== '15') &&
            <Col style={{ backgroundColor: "#f8f9fe" }} lg="9">
              {!channelFlag ? <Spinner /> : (
                <Channel activeTab={activeTab} channelFlag={channelFlag} filterValueState={filterValueState} search={searchState} toBreadCrumb={toBreadCrumb} />)}

            </Col>
          }

          {(activeTab == '15') &&
            <Col style={{ backgroundColor: "#f8f9fe" }} lg="9">
              {!channelFlag ? <Spinner /> : (
                <OldChannel activeTab={activeTab} channelFlag={channelFlag} filterValueState={filterValueState} search={searchState} toBreadCrumb={toBreadCrumb} />)}

            </Col>
          }



        </Row>
      </CardBody>
    </Card>)
};
export default ChannelWrapper;
