import React,  { useState, useEffect }  from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import axios from 'axios';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { Card,CardBody,FormGroup,Form, Container,Col,Row,Table,Button } from "reactstrap";
import { history } from '../../../../_helpers';
import { connect, useSelector } from 'react-redux';
import { subscriptionAction } from '_actions/subscription.action';

const  EventPaypalPayment=()=> {
    let [isLoading, setLoading] = useState(false);
        const redux_data = useSelector(state => state.subscription);

        
        let userData = JSON.parse(localStorage.getItem('CHuser'));
        useEffect(() => {

            const subId = redux_data.select_plan.subscriptionId;
            if(!subId){
              history.push('/admin/Subscription');
              return;
            }
              }, [])
        let env = 'production'; 
        let currency = 'EUR';
        let total = redux_data.pay_amt;
        const client = {
            //sandbox:process.env.REACT_APP_SANDBOX,
           production: process.env.REACT_APP_SANDBOX,
        }
        const subId = redux_data.select_plan.subscriptionId;  
        
        const onSuccess = (payment) => {
            // now create transaction & invoice for completed transaction
            console.log("The payment was succeeded!", payment);
            setLoading(true);
            // var subId =  JSON.parse(localStorage.getItem('subscriptionId'));
                   
                let data =  axios.post('channel/subscription/subscribe',
                    {  
                        subscriptionId:+subId,
                        paymentMethodId:3,
                        transactionId:null,
                        paymentStatus:1,
                        couponCode:(localStorage.getItem('coupancode'))?localStorage.getItem('coupancode'):null
                    }).then(function(response) {
                        console.log(response);
                        ToastsStore.success("Payment Success....");
                        
                        userData.planId = response.data.planId;
                        userData.subscriptionId = +response.data.channelSubscription.subscriptionId;
                        userData.featureList = response.data.channelSubscription.keyFeature;
                        userData.planExpireAt = response.data.channelSubscription.endAt;
                        userData.planName = redux_data.select_plan.title +' - '+ redux_data.select_plan.month;
                        localStorage.setItem('CHuser', JSON.stringify(userData));
                        axios.get('algorithm/channel').catch(err =>console.log(err.response.data.message,  ToastsStore.error('unable to create suggestion')));
                        history.push('/admin/PaymentSuccess');
                        return response.data;
                    }).catch(function(error) {
                        console.log(error.message);
                        if (error.response) {
                            console.log(error.response);
                            console.log(error.response.data.message);
                        } 
                 });
        }
        const onCancel = (data) => {
            // User pressed "cancel" or close Paypal's popup!
            console.log('The payment was cancelled!', data);
            ToastsStore.error('The payment was cancelled!');
            // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
        }
        const onError = (err) => {
            let data =  axios.post('channel/subscription/subscribe',
            {  
                subscriptionId:+subId,
                paymentMethodId:3,
                transactionId:null,
                paymentStatus:0,
                couponCode:(localStorage.getItem('coupancode'))?localStorage.getItem('coupancode'):null
            }).then(function(response) {
                console.log("Paypal Error!", err);
                ToastsStore.error("Paypal Error!", err);
                axios.post({
                    amount:+total,   //+((redux_data.pay_amt) * 100),
                    currency:'eur',
                    response:err,
                    paymentType:3,
                    transactionId:+localStorage.getItem('lasttransactionId')
                }, 'channel/subscription/failedTransaction').catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));
                history.push('admin/PaymentError');
            }).catch(function(error) {
                console.log(error.message);
                if (error.response) {
                    console.log(error.response);
                    console.log(error.response.data.message);
                } 
            });
        }
 
        return (
            <div className="main-content" id="PaymentConfirm">
            <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>
       
            <Card className="p-3">
            <h1 className="ml-3 mt-3">Pay With Paypal</h1><hr className="m-0 ml-3 paycarhr" style={{ width: "14%"}}/>
                <Row className="justify-content-center pb-5">
            
                    <Col lg="auto" >
                    <CardBody>
<ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
<PaypalExpressBtn env={env} client={client} currency={currency} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
          </CardBody>
          </Col>
          </Row>
          </Card>
          </Container>
          </div>
        );

        
    
}

function mapState(state) {
    console.log('state', state);
    const { general_data } = state.subscription;
    return { general_data };
}

const actionCreators = {
    subscriptionRedux: subscriptionAction.subscriptionRedux
};

export default connect(mapState,actionCreators)(EventPaypalPayment); //AddgeneralForm;