import React, { useState, useEffect } from "react";

import { Card, CardBody, Row, Col, Button, CardTitle, CardText, CardImg, UncontrolledTooltip, DropdownItem, DropdownMenu, UncontrolledDropdown, DropdownToggle } from "reactstrap";
import { FormAutocomplete } from "_components/FormElements/FormInput";
import { ListGroupItem, ListGroup } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from "react-hook-form";
import ChannelFilter from "./ChannelFilter";
import GuestNavbar from "components/Navbars3/GuestNavbar";
import Title from "views/Hoc/Dialog/Title";
import { commonService } from '_services/common.service';
import { Spinner, ModalSpinner } from 'views/Hoc/Spinner';
import TablePagination from '@material-ui/core/TablePagination';
// import {useInfiniteScroll} from '_hooks';
import GuestFooter from "../GuestPage/GuestNavbar/GuestFooter";
import GuestCategorys from "./GuestCategorys";
import GuestNavBar from "./GuestNavbar/GuestNavbar";
import GuestContact from "./GuestContact";
import Footer from "./GuestNavbar/Footer";
import axios from 'axios';
export const GeustVendorList = props => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));
  const { control } = useForm({ mode: 'onBlur' });
  const classes = useStyles();
  const unqual = { height: '450px' };

  const [filterValueState, setFilterValue] = useState({ location: [], brand: [], categories: [], parent: 'null', orderby: 'companyName' });
  let [compData, setCompData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchMoreItems = async () => {

    await getbuyerslist();
    setIsFetching(false);

  }

  const [isFetching, setIsFetching] = useState(false); //useInfiniteScroll(fetchMoreItems);



  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(9);
  let [total, setTotal] = useState(0);
  const [sort, setSort] = useState('desc');
  const [sortValue, setSortValue] = useState('Featured');

  let viewId = 2;

  let title = viewId == '1' ? 'Buyers' : viewId == '2' ? 'Vendor' : viewId == '3' ? 'Distributor' : viewId == '4' ? 'Retailer' : 'Buyers';

  useEffect(() => {
    compData = [];
    setCompData([]);
    setIsFetching(false);
    setLoading(true);
    getbuyerslist();
  }, [filterValueState]);

  const channelDetailView = (id) => {
    const win = window.open('channelDetailView/' + id);
    // history.push('productDetailView/'+x.productSKU)
    win.focus();
  }


  const getbuyerslist = async () => {

    let postData = {
      filter: getFilterData(),
      order: sort ? sort : 'asc',
      orderby: filterValueState.orderby ? filterValueState.orderby : 'companyName',
      // search:search?search.name:null,
      offset: compData.length,
      // // offset:1,
      limit: rowsPerPage,
    }

    let res = await commonService.postService(postData, 'guest/getBuyerSearchList/' + viewId).catch(err => setLoading(false));
    if (res) {
      console.log('aggre', res.data);
      setCompData(prevState => ([...prevState, ...res.data.data]));
      // setCompData(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
      setIsFetching(false);
    }

  }

  const getFilterData = () => {
    let filter = {
      brandId: null,
      countryId: null,
      productCategoryId: null,
    };
    if (filterValueState.location && filterValueState.location.length > 0) {
      filter.countryId = filterValueState.location.map(x => parseInt(x.id));
    }
    if (filterValueState.brand && filterValueState.brand.length > 0) {
      filter.brandId = filterValueState.brand.map(x => parseInt(x.id));
    }
    if (filterValueState.categories && filterValueState.categories.length > 0) {
      filter.productCategoryId = filterValueState.categories.map(x => parseInt(x.id));
    }
    console.log('filteredstate', filterValueState);
    return filter;
  }


  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getbuyerslist();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    getbuyerslist();
  };

  const handleChangeSelect = (input, value) => {
    setLoading(true);
    console.log('input, value', input, value);

    if (input === 'location') {
      filterValueState.location = value;
      if (filterValueState.categories?.length == 0 && filterValueState.brand?.length == 0)
        filterValueState.parent = 'location';
    }
    if (input === 'brand') {
      filterValueState.brand = value;
      if (filterValueState.categories?.length == 0 && filterValueState.location?.length == 0)
        filterValueState.parent = 'brand';
    }
    if (input === 'categories') {
      filterValueState.categories = value;
      if (filterValueState.brand?.length == 0 && filterValueState.location?.length == 0)
        filterValueState.parent = 'categories';
      //filterValueState.brand = [];
    }
    if (input == 'channel1') {
      filterValueState.orderby = value;
      setSortValue('Name (A - Z)');
    }
    if (input == 'channel2') {
      filterValueState.orderby = value;
      setSortValue('Name (Z - A)');
    }
    if (input == 'featured') {
      filterValueState.orderby = value;
      setSortValue('Featured');
      setSort('desc');
    }
    if (input == 'brandName') {
      filterValueState.orderby = value;
      setSortValue('BrandName');
      setSort('asc');
    }
    if (input == 'country') {
      filterValueState.orderby = value;
      setSortValue('Country');
      setSort('asc');
    }
    if (input == 'order') {
      if (sort != value)
        setSort(value);
    }
    setFilterValue({ ...filterValueState })
    console.log('filterValueState', filterValueState);
  }

  const resetFilterValue = () => {
    filterValueState.location = [];
    filterValueState.brand = [];
    filterValueState.categories = [];
    filterValueState.parent = '';

    setFilterValue({ ...filterValueState });
  }

  const loadVendors = async () => {
    setIsFetching(true);
    await getbuyerslist();
  }

  return (

    <>
      <div className="main-content">
        <GuestNavBar />

        {/* <GuestCategorys/> */}

        <div className="p-4">
          <div className="d-flex">
            <div className="mr-auto">
              <h1 className="ProductTitle d-inline-block mr-3">{title ? title : 'New Products'}</h1>
              <h2 className="ProductCount text-muted d-inline-block ml-5">({total})</h2>


            </div>

            <UncontrolledDropdown nav inNavbar className="float-right">
              <DropdownToggle nav caret className="Btnborder">
                Sort by {sortValue}
              </DropdownToggle>

              <DropdownMenu right>
                {/* <DropdownItem onClick={e => handleChangeSelect('brandName','brandName')}>
     Sort by brand 
     </DropdownItem>
     <DropdownItem divider /> */}
                <DropdownItem onClick={e => handleChangeSelect('featured', 'isFeatured')}>
                  Sort by Featured
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={e => handleChangeSelect('country', 'country')}>
                  Sort by Country
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={e => { handleChangeSelect('channel1', 'companyName'); handleChangeSelect('order', 'asc') }}>
                  Sort by Name (A - Z)
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={e => { handleChangeSelect('channel2', 'companyName'); handleChangeSelect('order', 'desc') }}>
                  Sort by Name (Z - A)
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

          </div></div>




        <Row>
          <Col className="leftFilterBg pt-4" lg="2">
            <ChannelFilter viewId={viewId} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} />
          </Col>

          <Col lg="10" className="mt-3">
            <div id="channelsGuest">

              <div className="card-columns h-auto">
                {loading ? <Spinner /> : compData.map((x, i) =>


                  <Card className="mb-3 pointer" key={x.companyName} onClick={() => { channelDetailView(x.channelId) }}>
                    <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                      alt="..."
                      src={x.logo ? process.env.REACT_APP_CDN_URL + x.logo : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//'https://d35w8j22j5uly8.cloudfront.net/document/company/logo/tbiOm5AB_1614760431146_1614760543038.png'
                      className="channeCardlimg p-4"
                    />

                    <CardBody>
                      <div className="text-center">
                        <h5 className="h3 title">
                          {/* <span className="d-block mb-1">{x.contacts.length ? x.contacts[0].firstName+' '+x.contacts[0].lastName : x.companyName}</span> */}
                          <small className="h4 font-weight-light text-muted">
                            <i class="fas fa-map-marker-alt pr-1"></i>
                            {x.country}
                          </small>
                        </h5>
                      </div>
                      <CardText className="mt-4 channeldesc text-center">
                        {x.detaildesc ? x.detaildesc.replace(/<[^>]*>?/gm, '') : ''}
                      </CardText>
                      <div className="card-profile-stats text-center d-flex  justify-content-center p-0">
                        <div className="col-lg-12">
                          <h3 className={"h3 title text-center text-muted " + (x.lookingfor ? "" : "d-none")}>
                            Product Categories :
                          </h3>
                          <CardText data-placement="right" id={"tooltipdesc" + i} className={"text-center" + (x.lookingfor ? "" : "d-none")}>
                            {/* {x.lookingfor ? x.lookingfor.split(',')[0,2] : ''} */}
                            {x.lookingfor ? x.lookingfor : ''}
                          </CardText>
                          <UncontrolledTooltip delay={0} placement="bottom" target={"tooltipdesc" + i}>{x.lookingfor ? x.lookingfor : '-'}</UncontrolledTooltip>
                        </div>
                      </div>
                      {/* <div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
                  <div className="col-lg-12">
                  <h3 className="h3 title text-center text-muted">
                  Meet With :  <CardText  className="">
                  {x.contacts.length && x.contacts[0].jobTitle}
                  </CardText>
                  </h3>
                  </div>
                  </div> */}

                    </CardBody>
                  </Card>


                )
                }
              </div>

              {total - compData.length > 0 && <div className="text-center">

                {isFetching ? <ModalSpinner /> : <button className="btn btn-primary btn-md ml-2" onClick={loadVendors}> More Vendors</button>}

              </div>}

              {/* {total>10 &&
                  <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 20, 30, 40]}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                      } */}

            </div>
          </Col>
        </Row>
        <GuestContact />
        <div className="guestDividcontainer">
          <div className="GuestDivider">
          </div>
        </div>
        <GuestFooter />
      </div>
    </>
  );
}

const AnnuvalTurnOver = [
  { id: '<1M€', name: '<1M€' },
  { id: '1-10M€', name: '1-10M€' },
  { id: '10-100M€', name: '10-100M€' },
  { id: '>100M€', name: '>100M€' },
]


export default GeustVendorList;