import React, { useState, useEffect, useRef,useImperativeHandle } from "react";
import { Container } from "reactstrap";
import Title from "views/Hoc/Dialog/Title";
import ListingTabs from "./ListingTabs";
import ChannelWrapper from './channelWrapper';
import ProductWrapper from './ProductWrapper';
import SimpleHeader from "components/Headers/SimpleHeader";
import ContactWrapper from "./ContactWrapper";
import BuyerInitial from "views/Hoc/Dialog/BuyerInitial";
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import { history } from "_helpers";

const localStorageWrapper =  {
  getItem: (key) => {
    let user = localStorage.getItem(key);

    if(key == "CHuser" && window.location.host.indexOf("inspect.") < 0)  {
      //page
      let selectuser = localStorage.getItem("selectCHUser");
      if(selectuser != "null" && selectuser != null){
        return selectuser;
      }
    }
    return user;

  },
  setItem: (key , value) => {
    localStorage.setItem(key, value)
  }
}

const Listing = (props) => {
  console.log('props', props);
  let [listing, setListing] = useState(props.location.pathname);
  let [activeTab, setActiveTab] = useState(null);
  let [val, setSelection] = useState('1');
  let [desc, setDesc] = useState([]);
  let [searchState, setSearch] = useState(null);
  // let logInUser = JSON.parse(localStorage.getItem('CHuser'));
  // let seletedUser = JSON.parse(localStorage.getItem('selectCHUser'));
  let userStatus = JSON.parse(localStorageWrapper.getItem('CHuser'));
  let [title, setTitle] = useState();
  const [isOpen, setOpen] = useState(false);
  const listingTabsRef = React.useRef();
  //   useEffect(() => { 
  //     listing=props.location.pathname;  
  //     setListing(listing);
  //     console.log('listing', listing);
  //     if(listing === '/admin/search/11')
  //     {
  //       desc=["List of your various search"];
  //       activeTab='11';
  //       title="Search";
  //       console.log('listing', activeTab);
  //     }  
  //     else if(listing === '/admin/search/12')
  //     {
  //       desc=["List of your various search"];
  //       activeTab='12';
  //       title="Search";
  //       console.log('listing', activeTab);
  //     }  
  //     else if(listing === '/admin/search/13')
  //     {

  //       desc=["List of your various search"];
  //       activeTab='13';
  //       title="Search";
  //       console.log('listing', activeTab);
  //     }

  //     setActiveTab(activeTab);
  //     setDesc(desc);
  //     setTitle(title);



  // },[props.location.pathname] )

  let from = props.location.state?.from;

  //redirecting route for decision making
  let to = props.location?.pathname?.split('/')[2] ? props.location?.pathname?.split('/')[2] : 'channel';
  const handleClose = ()=>{
    localStorage.setItem('buyerPopup', '0');
    setOpen(false);
    opendialog();
  }

  useEffect(() => {
    let forVendor = props.location.state?.activeTab;

    if (listing === '/admin/channel') {
      if (userStatus.channelTypeId === "2") {
        activeTab = '9';
      } else {
        if(localStorage.getItem('buyerPopup')=='1')
        setOpen(true);
        activeTab = '8';
      }
      desc = ["The below list are the suggestions based on your profile and preferences that you have set up on your company profile.",
        "<span style='color:red'>Please note that the suggestion list is different from the event participation list available under event menu.</span>","<br/><strong style='color:red'>YOU CANNOT SEND MEETING REQUESTS FROM THIS PAGE.</strong>"];
      title = "Suggestions";
    }
    // else if(listing === '/admin/search/11')
    // {
    //   desc=["List of your various search"];
    //   activeTab='11';
    //   title="Search";
    // }  
    // else if(listing === '/admin/search/12')
    // {
    //   desc=["List of your various search"];
    //   activeTab='12';
    //   title="Search";
    // }  
    // else if(listing === '/admin/search/13')
    // {
    //   desc=["List of your various search"];
    //   activeTab='13';
    //   title="Search";
    // }  
    else if (listing == '/admin/mynetwork') {
      if (userData.channelTypeId == 2) {
        desc = ["Inbound are the requests you have received and need your decision. Confirmed are your confirmed network connections. Favourites are the profile you saved to later review."];
        activeTab = '3';
      }
      else if (userData.channelTypeId == 3 || userData.channelTypeId == 4 || userData.channelTypeId == 5) {
        desc = ["Outbound are the requests you have sent. Confirmed are your confirmed network connections. Favourites are the profile you saved to later review."];
        activeTab = '2';
      } else {        
          desc = ["Outbound are the requests you have sent. Confirmed are your confirmed network connections. Favourites are the profile you saved to later review."];
          activeTab = '2';
      }
      title = "My Network";
    }
    else if (listing == '/admin/advancedsearch') {
      desc = ["Advanced Search Description"]
      activeTab = "30";
      title = "Advanced Search";
    }
    if (forVendor) {
      activeTab = forVendor;
    }

    if (from == 'product') {
      activeTab = '22';
    }

    setActiveTab(activeTab);
    setDesc(desc);
    setTitle(title);
  }, [])
  const ActiveTabs = (id) => {
    console.log('id', id);
    activeTab = id;
    if (id == 32)
      setSelection("3");
    else
      (id == 31 || id == 22 || id == 21 || id == 20) ? setSelection("2") : setSelection("1");

    setActiveTab(activeTab);
  }
  const ActivePage = (id) => {
    console.log('id', id)
    if (id === '1') {
      if (listing === '/admin/channel') {
        if (userStatus.channelTypeId === "2") {
          activeTab = '9';
        } else {
          activeTab = '8';
        }
      }
      setActiveTab(activeTab);
      setSelection("1");
    } else {
      activeTab = '22';
      setActiveTab(activeTab);
      setSelection("2");
    }
  }
  const handleChangeSearch = (input, value) => {
    searchState = value;
    setSearch(searchState);
  }
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  let channelTypeId = userData?.channelTypeId === '4' ? true : false;

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });

  const confirmPopup = (res) => {
    setModalPopup(false);
    history.push('/admin/dashboard');
  }
  const closePopup = () => {
    setModalPopup(false);
    history.push('/admin/dashboard');
  };

  const callTabCount = async() => {
    let userSelect = JSON.parse(localStorage.getItem('selectCHUser'));
      setActiveTab('8')
      setSelection('1')
      listingTabsRef.current.setActiveListTab('1');
      await listingTabsRef.current.getCount(userSelect);
      listingTabsRef.current.getChannelSearchList(userSelect);
  }

  

  const opendialog = (data) => {


  let message = <>
  <p>Congrats your account is now ready to be shared on the platform. <a style={{textDecoration: 'underline'}} onClick={() => history.push('/admin/PublicProfile/'+ userData.channelId)}>Click here</a> to check your public profile. Once completed you can check the suggestion menu to find out matching profiles of {userData?.channelTypeId!=2 ? 'vendors and product' : 'buyers'} . Please note that sugestion list will be visible 5 minutes after profile completion. </p>
  
  </>

    modalPopValue.message = message;
    modalPopValue.title = 'Note : ';
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }

  return (
    <>
    <BuyerInitial isOpen={isOpen} handleClose={handleClose} />
    <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />

      <SimpleHeader Ispublic={true} location={props.match} callTabCount={callTabCount}/>
      <div id="listing" className="main-content">

        <Title title={title} desc={desc} />

        <Container className="mt-4 col-12 list-tab" fluid>
          <ListingTabs ref={listingTabsRef} ActivePage={ActivePage} ActiveTabs={ActiveTabs} activeTab={activeTab} userStatus={userStatus} listing={listing} handleChangeSearch={handleChangeSearch} selection={val} from={from} />
          {val == 1 && <ChannelWrapper activeTab={activeTab} search={searchState} toBreadCrumb={to} />}
          {val == 2 && <ProductWrapper activeTab={activeTab} search={searchState} />}
          {val == 3 && <ContactWrapper activeTab={activeTab} search={searchState} />}
        </Container>
      </div>
    </>
  );
};
export default Listing;
