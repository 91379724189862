import React from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import Magnifier from "react-magnifier";

export  const ProductDetailImg=(props)=> {
  const {  isOpen, handleClose, ProductImgs } = props;
  return (
    <div>
      <Modal
          className="modal-dialog-centered prdimgdia"
          isOpen={isOpen}
         size="lg"
        >
          <div className="modal-header">
           <h3>Product Image</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={ handleClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
        <Card className="w-100 mb-0">
        <CardBody   style={{height: '75vh'}} className="productdetImg">
                  {/* <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={ProductImgs}/>  */}
                
          <Magnifier mgWidth={200} mgHeight={200} src={ProductImgs} />
                </CardBody>
        </Card>


          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
           
          </div>
        </Modal>
    </div>
  );
}
