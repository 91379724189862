
import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card, CardImg, Nav, NavItem, NavLink } from "reactstrap";
import GuestNavBar from 'views/pages/GuestPage/GuestNavbar/GuestNavbar';
import PubTitle from "views/Hoc/Dialog/PubTitle";
import PublicChannelListing from "./public-channel-listing";
import { history } from '../../../_helpers/history';
import Publicchannelfilter from './Publicchannelfilter'
export const PublicChannelListingWrapper = (props) => {

  const [filterValueState, setFilterValue] = useState({ categories: [], Company_Origin: [], typeId: null });

  useEffect(() => {
    if (props.match?.params?.id == 'buyers' || props.match?.params?.id == 'vendors')
      console.log('vendors')
    else
      history.push('');
    console.log('props', props);
  }, [])

  const handleChangeSelect = (input, value) => {
    if (input === 'categories') {
      filterValueState.categories = value;
    }
    if (input === 'Company_Origin') {
      filterValueState.Company_Origin = value;
    }
    if (input === 'channel_type') {
      filterValueState.typeId = value;
    }
    setFilterValue({ ...filterValueState })
  }

  return (
    <>
      <GuestNavBar />
      <div id='Favourites' className="main-content">
        <div>
          <CardImg
            alt="..."
            className={props.location.pathname.includes('/ces-breakfast') ? "event_ban" : "monaco"}
            src={props.location.pathname.includes('/ces-breakfast') ? require("assets/img/guestpage/brake-fast.png") : require("assets/img/guestpage/monacco.png")}
            top
          />

          {/* <img src={require("assets/img/guestpage/monacco.png")}/> */}
        </div>
        <PubTitle title={props.match?.params?.id === 'buyers' ? "List of confirmed Buyers" : "List of confirmed Vendors"} desc={[props.location.pathname === '/ces-buyers' ? "List of confirmed Buyers" : "List of confirmed Vendors"]} />
        <Container fluid className="mt-4">
          <Card>
            <CardBody>
              <Row className="">
                <Col lg='3' className='p-0'>
                  <Card>
                    <CardBody className='pl-0 pr-0 pt-0'>
                      <h4 className="text-muted mb-0">FILTERS</h4>
                      <Publicchannelfilter type={props.match?.params?.id === 'buyers' ? 'buyer' : 'vendor'} eventId={props.match.path === '/ces-breakfast/:id' ? 'ces-breakfast' : 'ces-monaco'} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} />
                    </CardBody>
                  </Card>
                </Col>
                <Col style={{ backgroundColor: "#f8f9fe" }} lg="9">
                  <PublicChannelListing type={props.match?.params?.id === 'buyers' ? 'buyer' : 'vendor'} eventId={props.match.path === '/ces-breakfast/:id' ? 'ces-breakfast' : 'ces-monaco'} filterValueState={filterValueState} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default PublicChannelListingWrapper;