import React, { useEffect, useState } from 'react';
import { FormInput, SelectAutocomplete, FormTextArea } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { signupschema2 } from './Validation';
import { GoogleAddress } from './googleAddress';
import { PhoneInput2 } from "_components/FormElements/FormInput";
import { Spinner } from 'views/Hoc/Spinner';
import parsePhoneNumber from 'libphonenumber-js';
import { commonService } from '_services/common.service';
import { ProfileUploader } from "views/Hoc/ProfileUpload.js";

export default function Step3(props) {




    const { register, handleSubmit, control, watch, errors } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(signupschema2)
    });


    const [error, setError] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const [image, setImage] = useState('');
    const [fieldError, setFieldError] = useState(false)

    const { handleLogo, handleChangeSelect, handleChangeAddress, handleChange } = props;    //,
    let { address, city, state, country, postalCode, tweetPitch, shortDesc, websiteUrl, countryId, switchPhone, mobile } = props.values;
    mobile = mobile[0] ? mobile : props.values.switchPhone
    const { countryList } = props.staticValues;
    let countryCode = '';

    if (Array.isArray(country)) {
        countryCode = countryList.find(x => x.id === country[0]?.id)?.countryCode;
    } else {
        countryCode = countryId ? countryId : '';
    }
    //const [address1, setAddress] = useState(address);
    //    let watchAddress = watch('address', address);
    let watchCity = watch('city', city);
    let watchState = watch('state', state);
    // const watchCountry = watch('country', country);
    let watchPostalCode = watch('postalCode', postalCode);
    let watchWebsiteUrl = watch('websiteurl', websiteUrl);
    let watchShortDesc = watch('shortdesc', shortDesc);
    let watchTweetPitch = watch('tweetpitch', tweetPitch);


    // const watchSwitchPhone = watch('switchPhone', switchPhone);

    const [loading, setLoading] = useState(false);
    const [flag, setFlag] = useState('1');
    const [invalidNumber, setInvalidNumber] = useState(false)
    // const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (flag != '1') {
            setLoading(true);
            setTimeout(() => setLoading(false), 3000);
          //  console.log('use effect for address')
        }
        setFlag('some');
    }, [props.loading])

    let comp = {};
    if (Array.isArray(country)) {
        comp = country[0];
       // console.log(comp);
    }
    else {
        comp = country
    }
    //console.log('comp', comp);
   // console.log('watch', loading);
  //  console.log('errors', errors);
    const onSubmit = async (data) => {
        let isValid = await handleMobile()
        if (isValid)
        props.handleNext();
        else
         return
    }

    const handlePhone = async (event) => {
        let validate = await validatePhoneNumber(switchPhone[1])
        if (!validate) {
            setInvalidNumber(true)
            setTimeout(() => setInvalidNumber(false), 2000)
        }
        return validate
    }

    const handleMobile = async (event) => {
        let validate = await validatePhoneNumber(mobile[1])
        if (!validate) {
            setInvalidNumber(true)
            setTimeout(() => setInvalidNumber(false), 2000)
        }
        return validate
    }

    const validatePhoneNumber = function (phoneNumber) {
        try {
            const parsedNumber = parsePhoneNumber(phoneNumber, null, {
                v2: true,
            });

            if (parsedNumber.isValid()) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const handleChangeImage = event => {
        const data = event.target.files[0];
        setError('');
        if (data && data['type'].split('/')[0] === 'image') {
            let file = data;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(
                    reader.result
                );
            };

        } else {
            setError('Jpg | Jpeg | PNG Image Formats Only Accepted');
        }

    };

    const handleCropClose = () => {
        setImage('');
    }

    const selectImg = async (data) => {
        if (data) {
            setImage('');
            //converting base64 string to file format.
            var random = Math.random();
            var fileName = random + '.png';
            var file = dataURLtoFile(data, fileName);
            const formData = new FormData();
            formData.append('file', file);
            setLoading(true);
            const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + "companyLogo");
            if (resImagePath) {
                handleLogo('companyLogo', resImagePath.data.url);
                setImageSrc(process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url);
                //setState({ ...state, awardlogo: process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url });
                setLoading(false);
            }
        }

    }

    const valid = () => {
        if (!errors.switchPhone || switchPhone[2].length > 4)
            return true;
        return 'Switchboard number must have minimum of 4 characters';
    }
    //setFamily({id:"1",name:"AV"});
    return (
        loading ? <Spinner /> :
            <div className="main-content">
                <div className="container" style={{ padding: '0' }}>
                    <h5 className='mob-signhead' style={{ display: 'none' }}>Company Details</h5>

                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <div >

                            {/* <div className="row">
                                <div className="col-sm-12 ">

                                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="shortDesc" name="shortDesc" ref={register} value={watchShortDesc} maxLength={280} />
                                    {!errors.shortDesc || (watchShortDesc.length > 5 && watchShortDesc.length <= 280) ? <h3 style={{ fontSize: '1rem', color: "rgba(0, 0, 0, 0.54)" }}>Your company’s short presentation (280 characters) *
                                        {watchShortDesc?.length > 0 && <span style={{ color: watchShortDesc?.length >= 280 ? "red" : "#9ea2a5" }}> {watchShortDesc?.length >= 280 ? 'description is too long, maximum size is 280 characters' : `remaining characters  ${280 - watchShortDesc.length}`}</span>}
                                    </h3> :
                                        <h4 style={{ color: "rgba(0, 0, 0, 0.54)" }}> Your company’s short presentation (280 characters) * <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                                            watchShortDesc.length <= 1 ? 'Description can\'t be empty.' : watchShortDesc.length <= 5 ? 'company short presentation must have min 5 characters' : watchShortDesc.length > 280 ? 'company short presentation must have max 280 characters' : ''
                                        }</span></h4>
                                    }

                                    <div className="form-group">

                                        <FormTextArea
                                            className="buytext2 w-100 h-auto"
                                            name="desc"
                                            id="desc"
                                            rows="3"
                                            type="textarea"
                                            maxLength={280}
                                            label=" "
                                            placeholder="Tell us about your company background and vision -max 280 characters *"

                                            value={watchShortDesc}
                                            onChange={handleChange('shortDesc')}

                                        />
                                    </div>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col-sm-12 ">

                                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="tweetPitch" name="tweetPitch" ref={register} value={watchTweetPitch} maxLength={280} />
                                    {!errors.tweetPitch || (watchTweetPitch.length > 5 && watchTweetPitch.length <= 280) ? <h3 style={{ fontSize: '1rem', color: "rgba(0, 0, 0, 0.54)" }}>Your company’s short presentation (280 characters max) *
                                        {watchTweetPitch?.length > 0 && <span style={{ color: watchTweetPitch?.length >= 280 ? "red" : "#9ea2a5" }}> {watchTweetPitch?.length >= 280 ? 'description is too long, maximum size is 280 characters' : `remaining characters  ${280 - watchTweetPitch.length}`}</span>}
                                    </h3> :
                                        <h4 style={{ color: "rgba(0, 0, 0, 0.54)" }}>Your company’s short presentation (280 characters max) * <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                                            watchTweetPitch.length <= 1 ? 'company short presentation cannot be empty.' : watchTweetPitch.length < 5 ? 'company short presentation must have min 5 characters' : ''
                                        }</span></h4>
                                    }

                                    <div className="form-group">
                                        {/* <FormTextArea className="buytext22" placeholder="Tweetpitch *"   value={watchWebsiteUrl} /> */}
                                        <FormInput
                                            className="buytext2 w-100 h-auto"
                                            name="note"
                                            id="note"
                                            // rows="3"
                                            // type="textarea"
                                            // maxLength={280}
                                            label=" "
                                            placeholder="Tweetpitch"
                                            // handleChange={handleChangeQuill1} 
                                            value={watchTweetPitch}
                                            onChange={handleChange('tweetPitch')}
                                        // register={register}
                                        //   value={note}
                                        //   onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-6 ">
                                    <div className="form-group">
                                        <SelectAutocomplete
                                            id="country"
                                            type="text"
                                            label="Country *"
                                            // value={props.signupData.companyname}
                                            name="country"
                                            //options={companyList}
                                            options={countryList}
                                            placeholder="Country"
                                            // register={register}
                                            // error={errors.postalcode}
                                            getOptionSelected={(options, value) => options.name === value.name}
                                            disableCloseOnSelect
                                            limitTags={2}
                                            defaultValue={comp}
                                            control={control}
                                            input='country'
                                            value={comp}
                                            register={register}
                                            errMessage={'Please select country'}
                                            error={errors.country}
                                            handleChangeSelect={handleChangeSelect}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="form-group">
                                        <FormInput
                                            id="websiteurl"
                                            type="text"
                                            name="websiteurl"
                                            label="Website *"
                                            //placeholder="Password"
                                            register={register}
                                            error={errors.websiteurl}
                                            value={watchWebsiteUrl}
                                            defaultValue={websiteUrl}
                                            onChange={handleChange('websiteUrl')}
                                        ></FormInput>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6 ">

                                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="companyLogo" name="companyLogo" ref={register} value={imageSrc} />

                                    <h4 style={{ color: "rgba(0, 0, 0, 0.54)" }}>Logo (Accepts Jpeg / Jpg / Png Only) * <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                                        (errors.companyLogo && imageSrc == null) ? 'Company logo can\'t be empty.' : ''
                                    }</span></h4>

                                    {(imageSrc == (null || '')) ? <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Company Logo</span> : ''}

                                    <ProfileUploader img={(imageSrc != null) ? (imageSrc) : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                                        handleChange={handleChangeImage} selectImg={selectImg} image={image} handleCropClose={handleCropClose}
                                    />
                                </div> */}
                            </div>


                            <div className='row'>
                                <div className="col-lg-6 ">
                                    <div className="form-group">
                                        <PhoneInput2
                                            id="mobile"
                                            label="Mobile number *"
                                            name="mobile"
                                            placeholder={'Mobile number *'}
                                            mobile={mobile[0]}
                                            error={errors.mobile}
                                            register={register}
                                            control={control}
                                            country_code={countryCode}
                                            handleChangeSelect={handleChangeSelect}
                                            regex={/^(\+[1-9]{1,4}[ \\-]*|\([0-9]{2,3}\)\s*[ \\-]*|[0-9]{2,4})[ \\-]*[0-9]{3,4}[ \\-]*[0-9]{3,4}(?!(\d)\1{8})/}
                                            defaultErrorMessage={errors.mobile || mobile[2].length < 4 ? 'Mobile No Must Have Min 4 Numbers' : ''}
                                             isValid={valid}
                                        />  <></>
                                         <div style={{color:'#f44336',fontSize:'0.75rem',minHeight: "30px"}}><span className={`${!invalidNumber?'hidden':''}`}>Please Enter Valid Number</span></div>
                                    </div>
                                </div>
                            </div>        
                            
                        </div>
                        <button type="submit" className="btn btn-primary ch-btn-blue right-but" variant="contained" color="primary" >
                            Next
                        </button>
                    </form>
                </div>
            </div>

    )
}









