import React  from 'react';
import GuestNavBar from "../pages/GuestPage/GuestNavbar/GuestNavbar";
import {  Row, Col} from "reactstrap";
import Title from "views/Hoc/Dialog/Title";

const About = () => {
    return (
        <div className='main-content'>
            <GuestNavBar />
            <Title title="About Us" desc={["ChannelHub is the result of 20 years of experience organizing International Trade Shows for the CE industry"]} />

        <section className="about-wrapper">
            <div className="container">
                <div className="row">
                    <div className="sub-about">
<section className="mission">
    <h3>Our Mission
</h3>
    <p>ChannelHub’s mission is to digitally connect Consumer Technology vendors and retailers to allow a faster and more productive way to bring innovative products to the market.

ChannelHub enables vendors, distributors, retailers and resellers from all over the world to build trusted relationships in a fast and easy way, leveraging cutting edge technology with human expertise.

</p>
</section>
<section className="activity">
    <h3>Our activities
</h3>
    <p>ChannelHub offers a full range of activities to help the tech channel community to grow their interactions and partnership.  

</p>
<Row>
    <Col  lg='6' className="pl-0">
<div className="activity-card" style={{backgroundColor:'#49a6c3'}}>
    <Row>
        <Col lg='6' md='8' sm='8'>
    <aside className="activity-left" >
        <h5>Online  Platform</h5>
        <h3>Channelhub.net</h3>
        <p>ChannelHub platform is the backbone of all our actvitiies. Offering extensive company profiles, product suggestions and matching solutions for both vendors and buyers</p>
    </aside>
    </Col>
    <Col lg='6' md='4' sm='4'>
        <figure className="activity-right">
        <img src="images/online_platform.jpg" alt="online_platform" title="online_platform" />

        </figure>
        </Col>
        </Row>
        <a>Read More  </a>

</div>
</Col>
<Col lg='6 ' className="pr-0">
<div className="activity-card" style={{backgroundColor:'#03055c'}}>
    <Row>
        <Col lg='6' md='8' sm='8'>
    <aside className="activity-left" >
        <h5>In-person summits</h5>
        <h3>Channel Summit EMEA
Channel Summit MENA</h3>
        <p>Channel Summits are stand-alone events organised in 5-star hotels and offering 3 days of one-to-one meetings, conferences and networking in the most friendly and professional environment

</p>
    </aside>
    </Col>
    <Col lg='6' md='4' sm='4'>

        <figure className="activity-right">
        <img src="images/52899241637_98fc0c1c92_h.jpg" alt="52899241637_98fc0c1c92_h" title='52899241637_98fc0c1c92_h'  />
        </figure>
        </Col>
        </Row>
<a>Read More  </a>

</div>
</Col>
<Col lg='6' className="pl-0">
<div className="activity-card" style={{backgroundColor:'#012d8e'}}>
    <Row>
    <Col lg='6' md='8' sm='8'>
    <aside className="activity-left">
        <h5>Side events</h5>
        <h3>Global Retail Connect</h3>
        <p>Global Retail Connect are held in conjunction with major industry trade shows in Berlin and Las vegas. They offer a condensed version of our summits etiher during the  evening or at breakfast times

</p>
    </aside>
    </Col>
    <Col lg='6' md='4' sm='4'>
        <figure className="activity-right">
        <img src="images/Global_Retail_connect.jpg" alt="Global_Retail_connect" title='Global_Retail_connect' />
        </figure>
        </Col>
        </Row>
        <a>Read More  </a>

</div>
</Col>
<Col lg='6' className="pr-0">
<div className="activity-card" style={{backgroundColor:'#0286ea'}}>
    <Row>
        <Col lg='6' md='8' sm='8'>
    <aside className="activity-left">
        <h5>Online Events</h5>
        <h3>Buyer Insider Session</h3>
        <p>Buyer Insider Session is a monthly programme where we twist the traditional roles and put channel buyers front and center to tell an audience of vendors how to do business with their company.

</p>
    </aside>
    </Col>
    <Col lg='6' md='4' sm='4'>
        <figure className="activity-right">
        <img src="images/Sebastian.jpg" alt="Sebastian" title='Sebastian' />
        </figure>
        </Col>
        </Row>
<a>Read More  </a>

</div>
</Col>
</Row>
</section>
<section className="founders">
    <h2>Meet The Founders</h2>
    <Row>
        <Col md='4'>
    <div className="founder-card">
        <figure>
            <img src="images/Farouk Hemraj official picture_edited.png" alt="Farouk Hemraj" title="Farouk Hemraj" />
        </figure>
        <h4>Farouk Hemraj</h4>
        <h5>Founder & CEO</h5>
        <a href="http://linkedin.com/in/faroukhemraj"  target='_blank'>
        <img src="images/linkedin.png" alt="linkedin" title="linkedin" />
            </a>
            <p>
            Farouk Hemraj is a serial entrepreneur with a background in international technology channels</p>
            <p>Farouk was the co-founders of DISTREE Events, an organizer of international executive-level tech channel meetings</p>
            <p>With a wealth of global channel experience and an extensive contact network spanning vendors, distributors and retailers, 
            </p>
    </div>
    </Col>
    <Col md='4'>

    <div className="founder-card">
    <figure>
            <img src="images/alain_edited.png" alt="Alain Godet" title="Alain Godet" />
        </figure>
        <h4>Alain Godet</h4>
        <h5>VP Customer success
</h5>
        <a href="http://linkedin.com/in/algodet"  target='_blank'>
        <img src="images/linkedin.png" alt="linkedin" title="linkedin" />
            </a>
            <p>
            Alain Godet is the founder of ITDistri.</p>
            <p>An experienced IT channel journalist and consultant specialized in distribution, Alain has more than two decades of experience.</p>
            <p>Alain is an authority on technology routes-to-market. With a broad and deep knowledge of the EMEA channel landscape, his expertise spans both volume and value distribution.
           </p>
    </div>
    </Col>
    <Col md='4'>

    <div className="founder-card">
        <figure>
        <img src="images/shuzha_edited.png" alt="Shuza Noirot" title="Shuza Noirot" />
        </figure>
        <h4>Shuza Noirot</h4>
        <h5>Sales Director</h5>
        <a href="http://linkedin.com/in/faroukhemraj"  target='_blank'>
        <img src="images/linkedin.png" alt="linkedin" title="linkedin" />

            </a>
            <p>
            Experienced Entrepreneur with a demonstrated history of working in the event industry both in IT & CE as well as Beauty and cosmetics</p>
            <p>Skilled in Negotiation, Marketing Management, Business Planning, Retail, and Sales.</p>
            <p>Strong business development professional with a MBA focused in Global Strategy from Stockholm University. 
            </p>
    </div>
    </Col>
    </Row>
</section>
                    </div>

                </div>
            </div>
        </section>

        </div>
    )
}
export default About;