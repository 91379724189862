import React, { useState, useEffect } from "react";
import { ListItem } from "_components/Listing/ListItem";
import { Card, CardBody, Row, Col } from "reactstrap";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { Progress } from "reactstrap";
import Favourities from 'views/Hoc/Dialog/Favourities'
import CountryFlag from "views/Hoc/countryFlag/countryFlag";
import { history } from '../../../_helpers/history';
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { FormTextArea, FormInput } from "_components/FormElements/FormInput";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { Badge, Modal, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import { subMinutes } from "date-fns";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { ConfirmDialog } from "views/Hoc/Dialog/GlobalDialog";

const messageList = [{ id: 1, name: 'My agenda is full, I will be pleased to meet you on your stand' },
{ id: 2, name: "Thank you for your request, but I'm not interested at the moment" }];

const senderTimeSlot = ['12.00 - 12.30', '12.30 - 1.00'];

const receiverTimeSlot = ['12.00 - 12.30', '1.30 - 2.00'];

export const EventListing = (props) => {
    const { type, eventId, eventType, status, filterValueState, flag, resetFilterValue, setChannelFlag, getCount, count } = props;
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [total, setTotal] = useState(0);
    let [isConfidential, setConfidential] = useState(0);
    let [isLoading, setLoading] = useState(true);
    let [data, setData] = useState([]);
    const [decline, setDecline] = useState(false);
    const [showSlotAlert, setAlert] = useState(false);
    const [confRequest, setConfirmRequest] = useState(false);
    const [msgAlert, setMessageAlert] = useState({ value: false, message: '' });
    const [limitAlert, setLimitAlert] = useState({ value: false, message: '' });

    const [confUpdateRequest, setConfirmUpdateRequest] = useState(false);
    const [requestId, setRequestId] = useState();
    const [message, setMessage] = useState();
    const [agendaErrMessage, setAgendaErrMessage] = useState({ msg: '', isShow: false, contactId: null });
    const [subject, setSubject] = useState();
    const [messageHistory, setMsgHistory] = useState([]);
    const [company, setCompany] = useState();
    const [participant, setParticipant] = useState({ sender: [], receiver: [] });

    const [selectedReceiverId, setSelectedReceiver] = useState(null);

    const [companyContact, setCompanyContact] = useState([]);
    const userData = JSON.parse(localStorage.getItem('CHuser'));
    const [meetingRequest, setMeetingRequest] = useState({
        subject: '',
        message: '',
        from: `${userData.firstName}' '${userData.lastName}`
    });


    let [reqContact, setReqContact] = useState([]);

    const [receiver, setReceiver] = useState({
        channelId: null,
        contactId: null,
        receiverList: null,
    });
    const [request, setRequest] = useState(false);

    let [conf_or_update, setConfUpdate] = useState(null);

    const [selectedSlot, setSelectedSlot] = useState(null);


    const [isSenderUpdateConfirm, setUpdateConfirm] = useState(false);

    const [noReceipientFlag, setReceipientFlag] = useState(null);


    let [matched, setMatch] = useState(null);

    let [confParticipant, setConfirmedParticipant] = useState({ sender: [], receiver: [] });


    useEffect(() => {
        // console.log("confParticipant.receiver:", confParticipant.receiver);
        // if(conf_or_update==2)
        getMatchedSlot();
        //if (confParticipant.receiver.length)
         


    }, [confParticipant]);


    useEffect(() => {
        console.log('type', type);
        if (type)
            callChannelList();
    }, [flag]);

    useEffect(() => {
        console.log('type', type);
        if (type)
            callChannelList();
    }, [status]);


    const getMatchedSlot = async () => {

        console.log("conf_or_update:", conf_or_update);
        if (confParticipant.sender.length && confParticipant.receiver.length) {
            matched = [];

            let timeslot;

            if(conf_or_update==2){
                timeslot = await axios.post('channel/updatetimeslot', { eventId: eventId, sender: confParticipant.sender, receiver: confParticipant.receiver })
                .then(res => {
                    return res.data;
                }).catch(err => console.log("getsubject:", err));
            }else{
                timeslot = await axios.post('channel/updatetimeslot', { eventId: eventId, sender: confParticipant.sender, receiver: confParticipant.receiver })
                .then(res => {
                    return res.data;
                }).catch(err => console.log("getsubject:", err));
            }
           

            if (conf_or_update == 1) {
                timeslot.senderTimeSlot.map(s => {
                    timeslot.receiverTimeSlot.map(r => {
                        // !timeslot.confirmSlot.includes(r.stime) &&
                        if (!timeslot.confirmSlot.includes(r.stime) && s.stime === r.stime && s.sdate === r.sdate && !matched.map(x => x.slot).includes(r.stime)) {
                            matched.push({ slot: s.stime, checked: timeslot.confirmSlot.includes(s.stime) ? true : false });
                        }
                    })
                })
            } else if (conf_or_update == 2) {
                timeslot.senderTimeSlot.map(s => {
                    timeslot.receiverTimeSlot.map(r => {
                        // !timeslot.confirmSlot.includes(r.stime) &&
                        if (s.stime === r.stime && s.sdate === r.sdate && !matched.map(x => x.slot).includes(r.stime)) {
                            matched.push({ slot: s.stime, checked: timeslot.confirmSlot.includes(s.stime) ? true : false });
                        }
                    })
                })
            }

            // if (matched.length)
                setMatch(matched);
        }

    }



    const callChannelList = () => {
        page = 0;
        setPage(page);
        getChannelList();
    }

    const getFilterData = () => {
        let filter = {
            specialityId: null,
            brandId: null,
            countryId: null,
            productId: null,
            intProductId: null,
            locationId: null,
            retailerprofileId: null,
            resellerprofileId: null,
            productCategoryId: null,
            turnOver: null,
            connectionStatusId: null,
            typeId: null,
            storeCount: null,
            eventId: eventId
        };
        let flag = 0;

        if (filterValueState.Current_Product && filterValueState.Current_Product.length > 0) {
            filter.productCategoryId = filterValueState.Current_Product.map(x => parseInt(x.childid));
            flag = 1;
        }


        if (filterValueState.Company_Origin && filterValueState.Company_Origin.length > 0) {
            filter.countryId = filterValueState.Company_Origin.map(x => parseInt(x.id));
            flag = 1;
        }

        if (filterValueState.typeId && filterValueState.typeId.length > 0) {
            filter.typeId = filterValueState.typeId.map(x => parseInt(x.id));
            flag = 1;
        }
        if (flag == 0) {
            resetFilterValue();
            setChannelFlag(true);
        }
        return filter;
    }

    const getChannelList = async () => {
        setLoading(true);
        let filter = {};//getFilterData();
        let sendData = {
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            filter: filter
        };

        let res = await axios.post('channel/channelList/' + type + '/' + eventId + '/' + status, sendData).catch(err => setLoading(false)); //for public page url => publicChannel/channelList
        if (res) {
            data = res.data.result.filter(x =>x.contacts.length>0);

            setData(data.map(x => {

                Object.assign(x, { receiver: { receiverList: x.contacts, channelId: x.contacts[0].channelId, contactId: x.receiverContactId } })
                return { ...x, showRequest: true }

            }));

            //setData(data);
            total = res.data.total;
            setTotal(total);
            setConfidential(res.data.isConfidential);
            console.log('data', data);
            setLoading(false);
        } else {
            data = [];
            setData(data);
            total = 0;
            setTotal(total);
            setLoading(false);
            ToastsStore.warning('Something went wrong');
        }
    }

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(page);
        getChannelList();
    };

    const handleChangeRowsPerPage = (event) => {
        rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(rowsPerPage);
        callChannelList();
    };

    const detailView = async (id) => {
        //let tokenData = await commonService.getServices('account/getPublicToken/' + id);
        // if (tokenData) {
        //     // history.push('channelView/'+ id+'?token='+tokenData.data.token);
        //const win = window.open(process.env.REACT_APP_LOCALSITE + 'channelView/' + id + '?token=' + tokenData.data.token, '_blank');
        const win = window.open(window.location.origin + '/admin/channel/channelDetail/' + id, '_blank');

        win.focus();
        // }
        // else
        //     ToastsStore.warning('Something went wrong');

    }

    const handleClose = () => {
        setDecline(false);
        setConfirmRequest(false);
        setConfirmUpdateRequest(false);
        setRequest(false);
    };

    const handleChangeMessage = (input, value) => {
        if (value)
            setMessage(value.name)
    }

    // const saveDecline = async () => {
    //     const userData = JSON.parse(localStorage.getItem('CHuser'));
    //     setLoading(true);

    //     let data = {
    //         eventRequestId: requestId,
    //         status: 5,
    //         participants: confParticipant?.sender?.length ? confParticipant.sender.toString() : null,
    //         receivers: confParticipant?.receiver?.length ? confParticipant.receiver.toString() : null,
    //         slot:matched.find(x => x.checked===true).slot,
    //         message: message,
    //         subject: subject,
    //         from: `${userData.firstName}' '${userData.lastName}`
    //     }

    //     await axios.post('channel/udateEventRequest', data)
    //         .then(res => {
    //             setLoading(false);

    //             getChannelList();
    //             getCount();
    //         })
    //         .catch(err => setLoading(false));

    //     setDecline(false);
    // }

    const saveDecline = async () => {
        setLoading(true);
        const userData = JSON.parse(localStorage.getItem('CHuser'));
        let data = {
            status: 5,
            eventRequestId: requestId,
            isAdmin: false,
            message: message,
            subject: subject,
            declinedBy: userData.contactId,
            from: `${userData.firstName}' '${userData.lastName}`
        }
        await axios.post('channel/udateEventRequest', data)
            .then(res => {
                setLoading(false);
                //           let req = state.find(x => x.eventRequestId == eventRequestId);
                //   axios.post('mailerservice/eventRequestConfirmMail', { ...req, status: 5 })
                getChannelList();
                getCount();

            })
            .catch(err => setLoading(false));
        setDecline(false);

        // window.location.reload();
    }


    const saveConfirm = async () => {
        const userData = JSON.parse(localStorage.getItem('CHuser'));

        
        if (matched.filter(x => x.checked === true).length) {
            setLoading(true);

            let data = {
                eventRequestId: requestId,
                status: 4,
                participants: confParticipant.sender.length ? confParticipant.sender.toString() : null,
                receivers: confParticipant.receiver.length ? confParticipant.receiver.toString() : null,
                message: message,
                slot: matched.find(x => x.checked === true).slot,
                subject: subject,
                from: `${userData.firstName}' '${userData.lastName}`,
                isSenderUpdateConfirm:isSenderUpdateConfirm
            }

            await axios.post('channel/udateEventRequest', data)
                .then(res => {
                    setLoading(false);
                    // let obj={
                    //     receiverContact:`${userData.firstName}' '${userData.lastName}`,
                    //     receiverCompany:

                    // }
                    // let req = data.contacts.find(x => x.eventRequestId == eventRequestId);
                    axios.post('mailerservice/eventRequestConfirmVegas', { status: 4, eventRequestId: requestId })

                    getChannelList();
                    getCount();
                })
                .catch(err => setLoading(false));

            // console.log("updateconfirm:",data);
            setLoading(false)
            setConfirmRequest(false);
            setConfirmUpdateRequest(false);
        }
        else {
            setAlert(true);
        }
    }

    const DeclineRequest = () => {


        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={decline} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>MEETING REQUEST CANCELLATION</strong>

                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">


                        <div className="container">

                            {/* <div className="row"> */}

                            <strong>MESSAGE</strong>
                            <br />

                            <strong>Subject of the meeting</strong>
                            <br />
                            {subject}
                            <br />
                            <strong>Message</strong>
                            {/* <div className="row"> */}
                            {/* <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                input='message'
                                multiple={false}
                                options={messageList}
                                getOptionLabel={(option) => option.name}
                                // value={regAddress.country ? regAddress.country[0] : null}
                                onChange={handleChangeMessage}

                                renderInput={(params) => <TextField {...params} label="Use a suggested answer" />}
                            /> */}
                            {/* </div> */}

                            <div className="row">

                                <FormTextArea
                                    className="formtxtarea"
                                    name="message"
                                    id="message"
                                    rows="3"
                                    type="textarea"
                                    label=""
                                    placeholder="Message (max 1000 characters)"
                                    // register={register}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />

                            </div>




                        </div>
                    </div>



                    <div className="modal-footer">
                        <Button className="mr-2" style={{ color: "#24869d" }} type="button" onClick={() => saveDecline()}>Decline Meeting</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }

    const confirmRequest = () => {


        return (
            <div>
                <Modal className="modal-dialog-centered meeting-dialog" fade={false} isOpen={confRequest} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        If you wish to transfer a meeting to another person of your team,please uncheck your name and check the staff concerned then, SAVE.

                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">


                        <div className="container">

                            {/* <div className="row"> */}

                            {noReceipientFlag &&
                                <strong> <p style={{ color: "red" }}>Atleast choose one receipient to confirm/decline request</p>
                                </strong>}

                            {!noReceipientFlag &&
                                <strong>{(matched?.length > 0) ? `Available Time Slots are: ` : (matched != null && matched?.length == 0) ? <p style={{ color: "red" }}>There are no common available time slot</p> : <p style={{ color: "black" }}>Loading...</p>}
                                </strong>}
                            <br />

                            {!noReceipientFlag && matched?.map((t) =>
                                <FormControlLabel control={
                                    <Checkbox onChange={confirmSlot} name={t.slot} checked={t.checked} disabled={t.checked} />}
                                    label={t.slot}

                                />)}

                            <br />

                            <strong>Subject of the meeting</strong>
                            <br />
                            {meetingRequest.subject}
                            <br />

                            <strong>MESSAGE</strong>
                            <br />
                            {meetingRequest.message}
                            <br />
                            <br />

                            <strong>Reply</strong>

                            <div className="row">

                                <FormTextArea
                                    className="formtxtarea"
                                    name="message"
                                    id="message"
                                    rows="3"
                                    type="textarea"
                                    label=""
                                    placeholder="Message (max 1000 characters)"
                                    // register={register}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </div>

                            <br />
                            <strong>PARTICIPANTS</strong>

                            <Row style={{ backgroundColor: "#f8f9fe" }}>
                                <strong>{company}</strong>
                            </Row>
                            <FormGroup>
                                {participant.sender.map((row, index) =>
                                    <FormControlLabel control={
                                        // index == 0 ?
                                        <Checkbox onChange={confirmSender} name={row.contactId} checked={true} disabled={true} />} //:
                                        // <Checkbox onChange={confirmSender} name={row.contactId} checked={row.checked} />} 
                                        label={row.firstName + " " + row.lastName + "," + " " + row.jobTitle}

                                    />
                                    // <FormControlLabel control={<Checkbox onChange={confirmSender} name={row.contactId} checked={index===0 ? true : false} disabled={index===0 ? true : false} />} label={row.firstName + " " + row.lastName + "," + row.jobTitle} />
                                )}

                            </FormGroup>

                            <Row style={{ backgroundColor: "#f8f9fe" }}>
                                <strong>ADD A MEMBER OF MY COMPANY IN THIS MEETING</strong>
                            </Row>
                            <FormGroup>
                                {participant.receiver.map((row, index) =>

                                    <FormControlLabel control={
                                        // row.contactId==userData.contactId ?
                                        //     <Checkbox onChange={confirmReceiver} name={row.contactId} checked={true} disabled={true} /> :
                                        <Checkbox onChange={(e) => confirmReceiver(e)} name={row.contactId} checked={row.checked} />} label={row.firstName + " " + row.lastName + "," + " " + row.jobTitle}
                                    />
                                    // <FormControlLabel control={<Checkbox onChange={confirmReceiver} name={row.contactId} checked={index === 0 ? true : false} disabled={index === 0 ? true : false} />} label={row.firstName + " " + row.lastName + "," + row.jobTitle} />
                                )}

                            </FormGroup>

                        </div>
                    </div>



                    <div className="modal-footer">
                        <Button color="primary" disabled={noReceipientFlag} type="button" onClick={() => saveConfirm()}>Confirm meeting</Button>
                        <Button color="primary" disabled={noReceipientFlag} type="button" onClick={() => saveDecline()}>Decline Meeting</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }

    const updateRequest = () => {


        return (
            <div>
                <Modal className="modal-dialog-centered meeting-dialog" fade={false} isOpen={confUpdateRequest} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        If you wish to transfer a meeting to another person of your team,please uncheck your name and check the staff concerned then, SAVE.

                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">


                        <div className="container">

                            {/* <div className="row"> */}

                            {noReceipientFlag &&
                                <strong> <p style={{ color: "red" }}>Atleast choose one receipient to confirm/decline request</p>
                                </strong>}

                            {!noReceipientFlag &&
                                <strong>{(matched?.length > 0) ? `Available Time Slots are: ` : (matched != null && matched?.length == 0) ? <p style={{ color: "red" }}>There are no common available time slot</p> : <p style={{ color: "blue" }}>Loading...</p>}
                                </strong>}
                            <br />

                            {!noReceipientFlag && matched?.map((t) =>
                                <FormControlLabel  control={
                                    <Checkbox style={{ color: selectedSlot===t.slot ? "green" : "gray" }} onChange={updateConfirmSlot} name={t.slot} checked={t.checked} disabled={t.checked} />}
                                    label={t.slot}

                                />)}

                            <br />

                            <strong>Subject of the meeting</strong>
                            <br />
                            {meetingRequest.subject}
                            <br />

                            <strong>MESSAGE</strong>
                            <br />
                            {meetingRequest.message}
                            <br />
                            <br />

                            <strong>Reply</strong>

                            <div className="row">

                                <FormTextArea
                                    className="formtxtarea"
                                    name="message"
                                    id="message"
                                    rows="3"
                                    type="textarea"
                                    label=""
                                    placeholder="Message (max 1000 characters)"
                                    // register={register}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </div>

                            <br />
                            <strong>PARTICIPANTS</strong>

                            <Row style={{ backgroundColor: "#f8f9fe" }}>
                                <strong>{company}</strong>
                            </Row>
                            <FormGroup>
                                {participant.sender.map((row, index) =>
                                    <FormControlLabel control={
                                        // index == 0 ?
                                        <Checkbox onChange={confirmSender} name={row.contactId} checked={true} disabled={true} />} //:
                                        // <Checkbox onChange={confirmSender} name={row.contactId} checked={row.checked} />} 
                                        label={row.firstName + " " + row.lastName + "," + " " + row.jobTitle}

                                    />
                                    // <FormControlLabel control={<Checkbox onChange={confirmSender} name={row.contactId} checked={index===0 ? true : false} disabled={index===0 ? true : false} />} label={row.firstName + " " + row.lastName + "," + row.jobTitle} />
                                )}

                            </FormGroup>

                            <Row style={{ backgroundColor: "#f8f9fe" }}>
                                <strong>ADD A MEMBER OF MY COMPANY IN THIS MEETING</strong>
                            </Row>
                            <FormGroup>
                                {participant.receiver.map((row, index) =>

                                    <FormControlLabel control={
                                        // row.contactId==userData.contactId ?
                                        //     <Checkbox onChange={confirmReceiver} name={row.contactId} checked={true} disabled={true} /> :
                                        <Checkbox onChange={(e) => confirmReceiver(e)} name={row.contactId} checked={row.checked} />} label={row.firstName + " " + row.lastName + "," + " " + row.jobTitle}
                                    />
                                    // <FormControlLabel control={<Checkbox onChange={confirmReceiver} name={row.contactId} checked={index === 0 ? true : false} disabled={index === 0 ? true : false} />} label={row.firstName + " " + row.lastName + "," + row.jobTitle} />
                                )}

                            </FormGroup>

                        </div>
                    </div>



                    <div className="modal-footer">
                        <Button color="primary" disabled={noReceipientFlag} type="button" onClick={() => saveConfirm()}>Update meeting</Button>
                        <Button color="primary" disabled={noReceipientFlag} type="button" onClick={() => saveDecline()}>Decline Meeting</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }


    const confirmSender = async (e) => {

        if (e.target.checked) {
            let sender = participant.sender.map(x => {
                if (x.contactId == e.target.name) {
                    x.checked = true;
                }
                return x;
            })

            setParticipant({ ...participant, sender: sender });

            confParticipant.sender = [...confParticipant.sender, +e.target.name];
            setConfirmedParticipant({ ...confParticipant, sender: confParticipant.sender });
        }
        else {
            let sender = participant.sender.map(x => {
                if (x.contactId == e.target.name) {
                    x.checked = false;
                }
                return x;
            })

            setParticipant({ ...participant, sender: sender });

            confParticipant.sender = [...confParticipant.sender.filter(x => x != +e.target.name)];
            setConfirmedParticipant({ ...confParticipant, sender: confParticipant.sender });
        }

        getMatchedSlot();


    }

    const confirmSlot = async (e) => {


        // if(eventType==2)
        if (e.target.checked) {

            setMatch((matched) => {
                return matched.map(x => {
                    if (x.slot === e.target.name)
                        x.checked = true;
                    else
                        x.checked = false;
                    return x;
                })
            })


        }
        // else {
        //     setMatch((matched) => {
        //         return matched.map(x => {
        //             if (x.slot === e.target.name)
        //                 x.checked = false;
        //             else
        //                 x.checked = false;
        //             return x;
        //         })
        //     })
        // }


    }

    const updateConfirmSlot = async (e) => {

        

        // if(eventType==2)
        if (e.target.checked) {
           
            setMatch((matched) => {
                return matched.map(x => {
                    if (x.slot === e.target.name)
                        x.checked = true;
                    else if(x.slot===selectedSlot)
                        x.checked = false;
                    return x;
                })
            })

            setSelectedSlot(e.target.name);


        }
        // else {
        //     setMatch((matched) => {
        //         return matched.map(x => {
        //             if (x.slot === e.target.name)
        //                 x.checked = false;
        //             else
        //                 x.checked = false;
        //             return x;
        //         })
        //     })
        // }


    }

    const confirmReceiver = (e) => {
        
        if (e.target.checked) {

           
            let receiver = participant.receiver.map(x => {
                if (x.contactId == e.target.name) {
                    x.checked = true;
                }
                return x;
            })           

            const parti={ ...participant, receiver: receiver };
            setParticipant(parti);

            const conparti={ ...confParticipant, receiver: [...confParticipant.receiver, e.target.name] };
            setConfirmedParticipant(conparti);

            setReceipientFlag(false);
        }
        else {

            let receiver = participant.receiver.map(x => {
                if (x.contactId == e.target.name) {
                    x.checked = false;
                }
                return x;
            })

            

            const parti={ ...participant, receiver: receiver };
            setParticipant(parti);

            const conparti={ ...confParticipant, receiver: [...confParticipant.receiver.filter(x => x != e.target.name)] }
            setConfirmedParticipant(conparti);

            if (confParticipant.receiver.filter(x => x != e.target.name).length == 0) {
                setReceipientFlag(true);
            }

            // receiverTimeSlot.map(s => {
            //     senderTimeSlot.map(r => {
            //         if (s === r) {
            //             matched.push(s);
            //         }
            //     })
            // })

            // setMatch(matched);
        }

        
        // getMatchedSlot();

    }

    const openDecline = async (id) => {

        let sub = await axios.get('channel/getEventSubject/' + id)
            .then(res => {
                return res.data;
            }).catch(err => console.log("getsubject:", err));


        setSubject(sub.eventChat[0].subject);

        setRequestId(id);
        setDecline(true);
    }

    const openConfirm = async (id, companyName) => {

        if (((count.myconfirmed + count.teamconfirmed) >= count.no_of_meeting_allowed || (count.myconfirmed)==10)) {
            setLimitAlert({ value: true, message: 'Your maximum number of meeting is reached' })

        } else {
            confParticipant.sender = [];
            confParticipant.receiver = [];
            conf_or_update=1;
            setConfUpdate(conf_or_update);
            const userData = JSON.parse(localStorage.getItem('CHuser'));

            let sub = await axios.get('channel/getEventSubject/' + id)
                .then(res => {
                    console.log("openConfirm:", res.data);
                    return res.data;
                }).catch(err => console.log("getsubject:", err));


            let data = {
                eventId: eventId,
                participants: sub.participants.includes(',') ? sub.participants.split(",") : [sub.participants]
            }

            let participants = await axios.post('channel/eventParticipants', data)
                .then(res => {
                    let sender = []; let receiver = [];
                    res.data['sender'].map(x => {
                        sender.push({ ...x, checked: false })
                    })

                    res.data['receiver'].map(x => {
                        receiver.push({ ...x, checked: userData.contactId == x.contactId ? true : false })
                    })
                    return { ...res.data, sender: sender, receiver: receiver };
                    // return res.data;
                }).catch(err => console.log("getsubject:", err));


            confParticipant.sender = [...confParticipant.sender, ...data.participants];
            confParticipant.receiver = [...confParticipant.receiver, userData.contactId];
            setConfirmedParticipant({ ...confParticipant, sender: confParticipant.sender, receiver: confParticipant.receiver });

            setParticipant({ ...participant, sender: participants.sender, receiver: participants.receiver });

            //check initially weather users are matched or not
            getMatchedSlot();


            // setSubject(sub.eventChat[0].subject);
            setMeetingRequest({ ...meetingRequest, subject: sub.eventChat[0].subject, message: sub.eventChat[0].message });


            setCompany(companyName);

            setRequestId(id);
            setConfirmRequest(true);
        }

    }

    const updateConfirm = async (id,slot, companyName) => {

        
        confParticipant.sender = [];
        confParticipant.receiver = [];
        conf_or_update=2;

        setSelectedSlot(slot);
        setConfUpdate(conf_or_update);
        const userData = JSON.parse(localStorage.getItem('CHuser'));

        let sub = await axios.get('channel/getEventSubject/' + id)
            .then(res => {
                // console.log("updateConfirm:", res.data);
                return res.data;
            }).catch(err => console.log("getsubject:", err));

        const senders = sub.participants.includes(',') ? sub.participants.split(",") : [sub.participants]
        const receivers = sub.receivers.includes(',') ? sub.receivers.split(",") : [sub.receivers]

        let recvParticipants;
        if(senders.includes(userData.contactId)){
         recvParticipants = senders;//sub.participants.includes(',') ? sub.participants.split(",") : [sub.participants]
         setUpdateConfirm(true);
        }
        else
        recvParticipants = receivers;//sub.receivers.includes(',') ? sub.receivers.split(",") : [sub.receivers]

        //console.log("senders:",senders,recvParticipants,senders.includes(userData.contactId),recvParticipants.includes(userData.contactId));

        let data = {
            eventId: eventId,
            participants: senders.includes(userData.contactId)?receivers:senders,//recvParticipants:sub.participants.includes(',') ? sub.participants.split(",") : [sub.participants]
        }

        let participants = await axios.post('channel/eventParticipants', data)
            .then(res => {
                let sender = []; let receiver = [];
                res.data['sender'].map(x => {
                    sender.push({ ...x, checked: false })
                })

                res.data['receiver'].map(x => {
                    receiver.push({ ...x, checked: recvParticipants.includes(x.contactId) ? true : false })
                })
                return { ...res.data, sender: sender, receiver: receiver };
                // return res.data;
            }).catch(err => console.log("getsubject:", err));


        confParticipant.sender = [...confParticipant.sender, ...data.participants];
        confParticipant.receiver = [...confParticipant.receiver, ...recvParticipants];//userData.contactId];
        setConfirmedParticipant({ ...confParticipant, sender: confParticipant.sender, receiver: confParticipant.receiver });

        setParticipant({ ...participant, sender: participants.sender, receiver: participants.receiver });

        //check initially weather users are matched or not
        getMatchedSlot();


        // setSubject(sub.eventChat[0].subject);
        setMeetingRequest({ ...meetingRequest, subject: sub.eventChat[0].subject, message: sub.eventChat[0].message });


        setCompany(companyName);

        setRequestId(id);
        setConfirmUpdateRequest(true);

    }


    const openMessage = async (eventRequestId, channelId, showMessage) => {

        let subject; let message = [];

        if (!showMessage) {
            await axios.get('channel/getEventMessage/' + eventRequestId)
                .then(res => {
                    subject = res.data[0].subject;
                    res.data.map(x => {
                        let date = new Date(x.createdAt).getDate() + "/" + new Date(x.createdAt).getMonth() + "/" + new Date(x.createdAt).getFullYear();
                        message.push({ From: x.from, date: date, message: x.message });
                    })
                    setMsgHistory([{ subject: subject, message: message }])
                }).catch(err => console.log("getsubject:", err));
        }

        setData((data) => {
            return data.map(x => {
                if (x.cid == channelId && status != null) {
                    x.showMessage = !x.showMessage;
                }
                else
                    x.showRequest = false;
                return x;
            })
        })


    }

    const confirmMsg = (value) => {
        setAlert(false);
    }

    const closeMsg = () => {
        setAlert(false);
    }

    const getCompanyContacts = async () => {
        const userData = JSON.parse(localStorage.getItem('CHuser'));
        await axios.get('channel/getCompanyContacts/' + userData.channelId + '/' + eventId)
            .then(res => {
                if (res)
                    setCompanyContact([res.data.find(x => x.contactId === userData.contactId), ...res.data.filter(x => x.contactId !== userData.contactId)]);

            })
            .catch(err => console.log(err));
    }



    const saveRequest = async () => {

        if (meetingRequest.message.length > 1000 || meetingRequest.subject.length > 250) {
            if (meetingRequest.message.length > 1000 && meetingRequest.subject.length > 250)
                setMessageAlert({ value: true, message: 'Please make your subject of 250 characters shorter & your message of 1000 characters shorter' })
            else if (meetingRequest.message.length > 1000)
                setMessageAlert({ value: true, message: 'Please make your message of 1000 characters shorter' })
            else if (meetingRequest.subject.length > 250)
                setMessageAlert({ value: true, message: 'Please make your subject of 250 characters shorter' })
        } else {

            const userData = JSON.parse(localStorage.getItem('CHuser'));
            setLoading(true);

            reqContact.unshift(companyContact[0].contactId)

            let data = {
                eventRequestId: requestId,
                senderContactId: +userData.contactId,
                senderChannelId: +userData.channelId,
                receiverContactId: selectedReceiverId ? receiver.receiverList.find(x => x.contactId == selectedReceiverId)?.contactId : receiver.contactId,
                receiverChannelId: selectedReceiverId ? receiver.receiverList.find(x => x.contactId == selectedReceiverId)?.channelId : receiver.channelId,
                participants: reqContact.toString(),//reqContact.length?reqContact.toString():companyContact[0].contactId.toString(),
                eventId: eventId,
                status: 3,
                chat: meetingRequest
            }

            await axios.post('channel/eventRequest', data).then(async res => {
                setLoading(false);
                // setData((data) => {
                //   return data.map(x => {
                //     if (x.cid == receiver.channelId) {
                //       x.showRequest = false
                //     }
                //     return x;
                //   })
                // })
                // axios.post('mailerservice/eventRequestMail', { status: 3,senderContactId: data.senderContactId,
                //   receiverContactId: data.receiverContactId})
                getChannelList();
                getCount();

            })
                .catch(err => setLoading(false)); //for public page url => publicChannel/channelList

            setRequest(false);
        }
    }

    const checkAgenda = async (contactId) => {
        await axios.get('/channel/checkAgendaByContact/' + eventId + '/' + contactId).then(res => {
            if (res.data > 0) {
                if (!reqContact.includes(contactId)) {
                    const rcon = [...reqContact, contactId];
                    setReqContact(rcon);
                } else {
                    const rcon = reqContact.filter(x => x != contactId);
                    setReqContact(rcon);
                }

                console.log("reqcon:", reqContact)

            } else {
                setAgendaErrMessage({ msg: 'Your colleague did not yet validate his (her) time slot by saving his (her) agenda', isShow: true, contactId: contactId });
            }

        })
    }

    const selectContacts = (e) => {

        checkAgenda(e.target.name);
        // if (!reqContact.includes(e.target.name))
        //   reqContact.push(e.target.name)
        // setReqContact(reqContact);
    }

    const EventRequest = () => {

        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={request} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>MAKE A MEETING REQUEST</strong>

                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <p>A one-to-one meeting involves finding a common time slot available for all the people involved in the request. To increase your chances of finding a common time slot do not involve too many people in the request.
                        </p>

                        <div className="container">

                            {/* <div className="row"> */}
                            <Row style={{ backgroundColor: "#f8f9fe", Padding: "4px" }}>
                                <strong>ADD A MEMBER OF MY COMPANY IN THIS MEETING</strong>
                            </Row>
                            <Row>
                                <Col className="mt-2 ml-1">
                                    <FormGroup >
                                        {companyContact.map((row, index) =>
                                            <>
                                                <FormControlLabel control={
                                                    index == 0 ?
                                                        <Checkbox onChange={() => checkAgenda(row.contactId)} name={row.contactId} checked={row.contactId === userData.contactId ? true : false} disabled={row.contactId === userData.contactId ? true : false} /> :
                                                        <Checkbox onChange={() => checkAgenda(row.contactId)} name={row.contactId} checked={(agendaErrMessage.isShow && agendaErrMessage.contactId == row.contactId) ? false : (reqContact.includes(row.contactId))} />} label={row.firstName + " " + row.lastName + "," + " " + row.jobTitle}

                                                />
                                                {(agendaErrMessage.isShow && agendaErrMessage.contactId == row.contactId) &&
                                                    <p style={{ margin: "0px 8px 0 0px", 'font-size': '12px', 'color': 'red' }}>{agendaErrMessage.msg}</p>}
                                            </>

                                        )}

                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row style={{ backgroundColor: "#f8f9fe", Padding: "4px" }}>
                                <strong>SELECT A RECEIPIENT TO SEND MEETING REQUEST</strong>
                            </Row>
                            <Row>
                                <Col className="mt-2 ml-1">
                                    <FormGroup >
                                        {receiver.receiverList.map((row, index) =>
                                            <FormControlLabel control={
                                                // index == 0 ?
                                                //   <Checkbox onChange={() => setSelectedReceiver(row.contactId)} name={row.contactId} checked={(selectedReceiverId?row.contactId===selectedReceiverId:row.contactId === receiver.contactId)? true : false}  /> :
                                                <Checkbox onChange={() => setSelectedReceiver(row.contactId)} name={row.contactId} checked={(row.contactId === selectedReceiverId) ? true : false} />} label={row.firstName + " " + row.lastName + "," + " " + row.jobTitle}

                                            />

                                        )}

                                    </FormGroup>
                                </Col>
                            </Row>


                            <strong>NOTE</strong>

                            <Row>
                                <Col className="mt-4">
                                    <FormInput
                                        id="subject"
                                        type="text"
                                        name="subject"
                                        label="Subject of the meeting *"
                                        InputLabelProps={{ shrink: true }}
                                        value={meetingRequest.subject}
                                        defaultValue={meetingRequest.subject}
                                        onChange={(e) => setMeetingRequest({ ...meetingRequest, ['subject']: e.target.value })}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt-2">
                                    <FormTextArea
                                        className="formtxtarea"
                                        name="message"
                                        id="message"
                                        rows="3"
                                        type="textarea"
                                        label=""
                                        placeholder="Add a personal note (max 1000 characters)"
                                        // register={register}
                                        value={meetingRequest.message}
                                        onChange={(e) => setMeetingRequest({ ...meetingRequest, ['message']: e.target.value })}
                                    />
                                </Col>
                            </Row>



                        </div>
                    </div>



                    <div className="modal-footer">
                        <Button className="mr-2" style={{ color: "#24869d" }} type="button" onClick={() => saveRequest()}>Update Request</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }

    const getParticipants = async (eventRequestId) => {
        return await axios.get('channel/getEventSubject/' + eventRequestId)
            .then(res => {
                const participants = res.data.participants.includes(',') ? res.data.participants.split(",") : [res.data.participants];
                setReqContact(participants);
                setMeetingRequest({ ...meetingRequest, subject: res.data.eventChat[0].subject, message: res.data.eventChat[0].message });

            }).catch(err => console.log("getsubject:", err));

    }


    const openRequest = (receiver, eventRequestId) => {

        // console.log("countss:",count)
        // if((count.mysubmitted+count.myconfirmed)>=count.no_of_meeting_request)
        // {
        //   setLimitAlert({value:true,message:'Your maximum number of request is reached'})

        // }else{    
        getCompanyContacts();
        console.log("recv:", receiver);
        setRequestId(eventRequestId);
        const recv = receiver.receiverList.find(x => x.eventRequestId == eventRequestId);
        setSelectedReceiver(recv.contactId);
        setReceiver(receiver);

        reqContact = [];
        setReqContact(reqContact);
        getParticipants(eventRequestId);
        // setRequestId(eventRequestId);
        setRequest(true);
        //}
    }

    return (
        <div id="iner" className="main-content mt-3">


            {request &&
                EventRequest()}

            {decline &&
                DeclineRequest()}

            {confRequest &&
                confirmRequest()}

            {confUpdateRequest &&
                updateRequest()}

            {showSlotAlert &&
                <ConfirmDialog open={showSlotAlert} value={{ message: 'Make sure to select the slot before confirm the meeting', title: 'Confirm Meeting', value: null, popUpId: null, second_message: null }} handleSave={confirmMsg} handleClose={closeMsg} />}

            {limitAlert.value &&
                <ConfirmDialog open={limitAlert.value} value={{ title: 'Event Transaction', message: limitAlert.message, second_message: '' }} handleSave={() => setLimitAlert({ ...limitAlert, value: false, message: '' })} handleClose={() => setLimitAlert({ ...limitAlert, value: false, message: '' })} />}

            {msgAlert.value &&
                <ConfirmDialog open={msgAlert.value} value={{ title: 'Event Transaction', message: msgAlert.message, second_message: '' }} handleSave={() => setMessageAlert({ ...msgAlert, value: false, message: '' })} handleClose={() => setMessageAlert({ ...msgAlert, value: false, message: '' })} />}

            <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
            {(data.length <= 0 && !isLoading) && <div className="no-records ">
                <div className="no-img text-center">
                    <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} />
                </div>
            </div>}
            {isLoading ? <Spinner /> : <>
                {data.length > 0 && data.map((item, index) => {
                    let logo = "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg";
                    return (
                        <Row className="justify-content-center eventBuyerListing">
                            <Col className="card-wrapper ct-example" lg="12" md="12" sm="12" >
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-center justify-content-center">
                                            <Col className="col-lg-2 col-md-12 col-sm-12 mobi-res">
                                                <a
                                                    style={{ width: "110px", height: "110px", border: "1px solid #b8b8b8", backgroundColor: "white", cursor: isConfidential == 1 ? 'none' : 'pointer' }}
                                                    className={"avatar avatar-xl mb-2"}
                                                    href="#pablo"
                                                    onClick={() => { isConfidential != 1 && detailView(item.cid) }}
                                                >
                                                    <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                                                        alt="..."
                                                        src={item.logo ? process.env.REACT_APP_CDN_URL + item.logo : logo}
                                                    />
                                                </a>
                                                <div style={{ cursor: isConfidential == 1 ? 'none' : 'pointer' }} onClick={() => { isConfidential != 1 && detailView(item.cid) }}>
                                                    <Badge style={{ backgroundColor: "#2c9db7", color: "white", fontSize: "0.8000rem", textTransform: "capitalize", width: "100%", }}>
                                                        +details
                                                    </Badge>
                                                </div>
                                                {item.webSiteUrl && <div className="text-center">

                                                    <a style={{ cursor: isConfidential == 1 ? 'none' : 'pointer' }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            let url = item.webSiteUrl;
                                                            if (!url.match(/^https?:\/\//i)) {
                                                                if (!url.match(/^http?:\/\//i))
                                                                    url = '//' + url;
                                                            }
                                                            if (isConfidential != 1)

                                                                window.open(url, '_blank')
                                                        }}
                                                    >
                                                        <i className="fas fa-globe-asia mr-1"></i>Website
                                                    </a>

                                                </div>}
                                            </Col>
                                            <div className="col-lg-9 col-md-12 col-sm-12 mobi-res3">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-12 mobi-res1  res-padr1">
                                                        <Badge
                                                            style={{
                                                                backgroundColor: "#FF6347",
                                                                color: "white",
                                                                fontSize: "0.9000rem",
                                                            }}
                                                        >
                                                            {item.channelType}
                                                        </Badge>
                                                    </div>
                                                    {/* flag */}
                                                    <div className="col-auto col mobi-res2">
                                                        <a className="avatar avatar-xs rounded-circle">
                                                            <CountryFlag code={item.isoCode} />
                                                        </a>
                                                    </div>

                                                    <div style={{ paddingLeft: "0px" }} className={"col-lg-4 col-md-3 col-sm-12 mobi-res pad-01"}>
                                                        <h3 className="mb-0">{item.country}</h3>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-12 pr-0 mobi-res4">


                                                        {!!item.contacts?.length && <>
                                                            <span>
                                                                {isConfidential == 1 ? 'XXXXX' + "-" + item.contacts[0]?.jobTitle : item.contacts.find(x => x.eventRequestId != null)?.firstName + " " + item.contacts.find(x => x.eventRequestId != null)?.lastName}

                                                            </span>
                                                            <br />
                                                            <span>
                                                                {item.contacts.find(x => x.eventRequestId != null)?.jobTitle}

                                                            </span>
                                                        </>}
                                                    </div>
                                                </div>
                                                <Row>
                                                    <Col className="col-lg-auto col-md-auto col-sm-12 mobi-res2">
                                                        <h3 className={"mb-0 text-uppercase"}>
                                                            <a style={{ cursor: isConfidential == 1 ? 'none' : 'pointer' }}
                                                                href="#pablo"
                                                                onClick={() => { isConfidential != 1 && detailView(item.cid) }}
                                                            >
                                                                {item.companyName}
                                                            </a>
                                                        </h3>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-lg-7 col-md-12 col-sm-12">
                                                        <h4>
                                                            Specialty in/Categories:
                                                            <p className="ml-2" style={{ display: "inline" }}>
                                                                {item.company_cat && <ListItem elements={item.company_cat} index={'cat' + index} limit={4} />}
                                                            </p>
                                                        </h4>
                                                        {item.channelTypeId !== '4' && <h4>
                                                            Channel Customer Profiles: &nbsp;
                                                            <p style={{ display: "inline" }}>
                                                                {item.retail_customer_profile && <ListItem elements={item.retail_customer_profile} index={'pro' + index} limit={4} />}
                                                            </p>
                                                        </h4>}
                                                        {item.channelTypeId === '4' && <h4>
                                                            Interest In Start-Up : &nbsp;
                                                            <p style={{ display: "inline" }}>
                                                                {item.looking_startups && item.looking_startups[0].item_name && item.looking_startups[0].item_name.replace('S_VOLUME', ' Shipping in volume').replace('S_BACKER', ' Shipping to first backers').replace('PROTOTYPE', ' Prototype')}
                                                            </p>
                                                        </h4>}
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col className="col-auto">
                                                        <h3 className="mb-0 text-uppercase">
                                                            <span
                                                                href="#pablo"
                                                                onClick={(e) => e.preventDefault()}
                                                                style={{ color: "#80807F" }}

                                                            >
                                                                Tweet Pitch
                                                            </span>
                                                        </h3>
                                                        <p>
                                                            {!!item.detailDesc ? item.detailDesc.replace(/&amp;/g, '&').replace(/<[^>]+>/g, '') : ''}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <Col className="col-lg-1 col-md-1 col-sm-12 mobi-res5">
                                                {/* <i class="far fa-heart heart-o"  aria-hidden="true"></i> */}
                                                {/* <a href="#" onClick={() => openFavourite(!Favorite)} ><i class="fas fa-heart heart"  aria-hidden="true"></i></a>
                      { Favourite && <Favourities/>} */}
                                                {/* {(isConfidential == false || activeTab == '15') ? '' : */}
                                                {(status == 3) && <>
                                                    <i className="fas fa-check" style={{ margin: "0px 5px 0 5px", 'font-size': '28px', 'color': 'green' }} onClick={() => openConfirm(item.contacts.find(x => x.eventRequestId != null).eventRequestId, item.companyName)}></i>
                                                    <i className="fas fa-times" style={{ margin: "0px 5px 0 5px", 'font-size': '28px', 'color': 'red' }} onClick={() => openDecline(item.contacts.find(x => x.eventRequestId != null).eventRequestId)}></i>
                                                </>}

                                                {(status == 4) && <>
                                                    {/* <i className="fas fa-pencil-alt" style={{ margin: "0px 5px 0 5px", 'font-size': '28px', 'color': 'green' }} onClick={() => updateConfirm(item.contacts.find(x => x.eventRequestId != null).eventRequestId,item.contacts.find(x => x.eventRequestId != null).slot, item.companyName)}></i> */}
                                                    <i className="fas fa-times" style={{ margin: "0px 5px 0 5px", 'font-size': '28px', 'color': 'red' }} onClick={() => openDecline(item.contacts[0].eventRequestId)}></i>
                                                </>}

                                                {(status == 2) && <>
                                                    <i className="fas fa-pencil-alt" style={{ margin: "0px 5px 0 5px", 'font-size': '28px', 'color': 'green' }} onClick={() => openRequest(item.receiver, item.contacts.find(x => x.eventRequestId != null).eventRequestId)}></i>
                                                    <i className="fas fa-times" style={{ margin: "0px 5px 0 5px", 'font-size': '28px', 'color': 'red' }} onClick={() => openDecline(item.contacts[0].eventRequestId)}></i>
                                                </>}
                                                {(status == 5) && <>
                                                    <i className={item.showMessage ? "fas fa-times" : "fas fa-envelope"} style={{ 'font-size': '28px' }} onClick={() => openMessage(item.contacts.find(x => x.eventRequestId != null).eventRequestId, item.cid)}></i>
                                                </>}

                                                {/* } */}
                                                {/* <UncontrolledTooltip placement='bottom'>
                        Add Favourite
                      </UncontrolledTooltip> */}
                                                {/* {activeTab != '15' && <div id={'view' + item.channelId} className="pointer" onClick={isConfidential == false ? openUpgradePopup : () => { activeTab > 6 ? history.push('/admin/' + toBreadCrumb + '/channelDetail/' + item.cid) : history.push('/admin/mynetwork/channelDetail/' + item.cid) }}>
                          <i
                            style={{ fontSize: "2em", cursor: "pointer" }}
                            className="far fa-eye float-right"
                          ></i>
                          <UncontrolledTooltip target={'view' + item.channelId} placement='bottom'>
                            View Channel
                          </UncontrolledTooltip>
                        </div>} */}
                                            </Col>

                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                            {item.showMessage &&
                                <Col className="card-wrapper ct-example" lg="12" md="12" sm="12" >

                                    {messageHistory.map((list) => <>
                                        <h4>Subject of the meeting:</h4>
                                        <p>{list.subject}</p>
                                        <br />
                                        <i className="fas fa-envelope"></i><strong>Message History</strong>
                                        <br />
                                        {list.message.map((msg) =>
                                            <Row>
                                                <div className="card-wrapper ct-example col-12">
                                                    {msg.message}
                                                </div>
                                                <div className="card-wrapper ct-example col-12" style={{ float: "right", textAlign: 'right', widt: "100%", borderBottom: "1px solid #eee" }}>
                                                    From: {`${msg.From},${msg.date}`}
                                                </div>

                                            </Row>
                                        )}
                                    </>
                                    )}

                                </Col>
                            }

                        </Row>)
                })}
                {total > 10 &&
                    <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 20, 30, 40]}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                }
            </>}
        </div >

    );
};
export default EventListing;
