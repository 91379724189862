import React, { useState, useEffect } from 'react';

import { history } from "_helpers";
import axios from 'axios';

const MeetRating = (props) => {


    const userData = JSON.parse(localStorage.getItem('CHuser'));

    //console.log("meets:", props)

    useEffect(() => {
        getEventDetails();
    }, [])

    const getEventDetails = async () => {

        await axios.get('/channelAdmin/getEventById/' + props.match['params'].id).then(res => {
            
            if (!userData) {       
                localStorage.setItem('eventDetails', JSON.stringify({...res.data[0],firstLogin:'1',eventId:res.data[0].id}));
                history.push('/Login');
            } 
            else {
                console.log("preevent")
                localStorage.setItem('eventDetails', JSON.stringify({...res.data[0],firstLogin:'0',eventId:res.data[0].id}));
                history.push('admin/events/prevEvent');
            }
        })
    }

    return (
        <>
        </>
    )


}

export default MeetRating;

