import React, { useEffect, useState } from "react";
import {FaStar} from "react-icons/fa";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const StarRating = ({isOpen,handleClose,handleSave,value}) => {
   //const[modalIsOpen,setModalOpen]=useState(false)
    const [rating, setRating] = useState(null);
    const [hover, setHover] = useState(null);

    useEffect(() => {
      setRating(null);
    },[isOpen==true])
  
    

    return (
      <div className="event-starrating">
          
          
<div>

<div>
        {/* <Button color="yellow" onClick={()=>setModalOpen(true)}>Event Ratings<FaStar size={20} color={"#ffc107"}/></Button> */}
        <Modal isOpen={isOpen} 
        shouldCloseOnOverlayClick={false}
        //onRequestClose={()=>handleClose(rating)}
        style={
            {
                overlay:{
                    backgroundColor:'grey'
                }
            }
        }>
         
          <ModalBody>
              <h3>Meeting Rating</h3>
             
          {[...Array(4)].map((star,i)=>{
    const ratingValue = i+1;
return (
   
<label className="str">
    <input type="radio" name="rating" value={ratingValue}
    onClick={()=>setRating(ratingValue)}/>
<FaStar className="star" color={ratingValue <= (hover || rating)?"#ffc107":"#808080a3"}
title={ratingValue <= (hover || rating)?"your meeting rating, click to modify":"click to add your rating for this meeting"}
// onMouseEnter={()=>setHover(ratingValue)}
// onMouseLeave={()=>setHover(null)}
/>

</label>



  
);
})}
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={()=>handleClose()}>close</Button>           
            <Button color="primary" onClick={()=>handleSave(Object.assign(value,{rating:rating}))}>save</Button>           
          </ModalFooter>
        </Modal>
      </div>


</div>

{/* <p>The rating is {rating}</p> */}



      </div>
    );
  };

export default StarRating;