import React, { useState, useEffect } from "react";
import { ListItem } from "_components/Listing/ListItem";
import { Card, CardBody, Row, Col, Modal } from "reactstrap";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { Progress } from "reactstrap";
import Favourities from 'views/Hoc/Dialog/Favourities'
import CountryFlag from "views/Hoc/countryFlag/countryFlag";
import { history } from '../../../_helpers/history';
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { FormTextArea, FormInput } from "_components/FormElements/FormInput";
import { Badge, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { gridColumnsTotalWidthSelector } from "@material-ui/data-grid";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { Padding } from "@mui/icons-material";

export const EventChannelListing = (props) => {
  const { type, isSeller,eventId, filterValueState, flag, resetFilterValue, setChannelFlag, getCount } = props;
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [total, setTotal] = useState(0);
  let [isConfidential, setConfidential] = useState(0);
  let [isLoading, setLoading] = useState(true);
  let [data, setData] = useState([]);
  const [companyContact, setCompanyContact] = useState([]);
  const [request, setRequest] = useState(false);
  const [status, setStatus] = useState(null);
  let [reqContact, setReqContact] = useState([]);

  const [receiver, setReceiver] = useState({
    channelId: null,
    contactId: null
  });

  const userData = JSON.parse(localStorage.getItem('CHuser'));

  const [meetingRequest, setMeetingRequest] = useState({
    subject: '',
    message: '',
    from: `${userData.firstName}' '${userData.lastName}`
  });

  useEffect(() => {
    getCompanyContacts();
  }, []);

  useEffect(() => {
    console.log('type', type);
    if (type)
      callChannelList();
  }, [flag]);

  //console.log('filtervaluepublic', filterValueState)

  const callChannelList = () => {
    page = 0;
    setPage(page);
    getChannelList();
  }

  const getCompanyContacts = async () => {
    const userData = JSON.parse(localStorage.getItem('CHuser'));
    await axios.get('channel/getCompanyContacts/' + userData.channelId+'/'+eventId)
      .then(res => {
        if (res)
          setCompanyContact([res.data.find(x => x.contactId === userData.contactId), ...res.data.filter(x => x.contactId !== userData.contactId)]);

      })
      .catch(err => console.log(err));
  }

  const handleClose = () => {
    setRequest(false);
  };

  const openRequest = (receiver) => {
    setReceiver(receiver)
    reqContact = [];
    setRequest(true);
  }

  const selectContacts = (e) => {
    reqContact.push(e.target.name)
    setReqContact(reqContact);
  }

  const recepient = (channelId, contactId, status) => {

    if (status == 3)
      setStatus('Request Already Sent')
    else if (status == 4)
      setStatus('Already confirmed you')
    else if (status == 5)
      setStatus('You already declined a meeting request from this company')

    setData((data) => {
      return data.map(x => {
        if (x.cid == channelId && status != null) {
          x.showRequest = false;
        }
        else
          x.showRequest = true;
        return x;
      })
    })
    setReceiver({ ...receiver, channelId: channelId, contactId: contactId });
  }

  const saveRequest = async () => {
    const userData = JSON.parse(localStorage.getItem('CHuser'));
    setLoading(true);

    reqContact.unshift(companyContact[0].contactId)

    let data = {
      senderContactId: +userData.contactId,
      senderChannelId: +userData.channelId,
      receiverContactId: receiver.contactId,
      receiverChannelId: receiver.channelId,
      participants: reqContact.toString(),//reqContact.length?reqContact.toString():companyContact[0].contactId.toString(),
      eventId: eventId,
      status: 3,
      chat: meetingRequest
    }

    await axios.post('channel/eventRequest', data).then(async res => {
      setLoading(false);
      // setData((data) => {
      //   return data.map(x => {
      //     if (x.cid == receiver.channelId) {
      //       x.showRequest = false
      //     }
      //     return x;
      //   })
      // })
      getChannelList();
      getCount();

    })
      .catch(err => setLoading(false)); //for public page url => publicChannel/channelList

    setRequest(false);
  }

  const EventRequest = () => {

    return (
      <div>
        <Modal className="modal-dialog-centered" fade={false} isOpen={request} size="xg" style={{ minWidth: 900, width: '30%' }}>
          <div className="modal-header">
            <strong>MAKE A MEETING REQUEST</strong>

            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">
            <p>A one-to-one meeting involves finding a common time slot available for all the people involved in the request. To increase your chances of finding a common time slot do not involve too many people in the request.
            </p>

            <div className="container">

              {/* <div className="row"> */}
              <Row style={{ backgroundColor: "#f8f9fe", Padding: "4px" }}>
                <strong>ADD A MEMBER OF MY COMPANY IN THIS MEETING</strong>
              </Row>
              <Row>
                <Col className="mt-2 ml-1">
                  <FormGroup >
                    {companyContact.map((row, index) =>
                      <FormControlLabel control={
                        index == 0 ?
                          <Checkbox onChange={selectContacts} name={row.contactId} checked={row.contactId === userData.contactId ? true : false} disabled={row.contactId === userData.contactId ? true : false} /> :
                          <Checkbox onChange={selectContacts} name={row.contactId} />} label={row.firstName + " " + row.lastName + "," + " " + row.jobTitle}

                      />

                    )}

                  </FormGroup>
                </Col>
              </Row>


              <strong>NOTE</strong>

              <Row>
                <Col className="mt-4">
                  <FormInput
                    id="subject"
                    type="text"
                    name="subject"
                    label="Subject of the meeting *"
                    InputLabelProps={{ shrink: true }}
                    value={meetingRequest.subject}
                    defaultValue={meetingRequest.subject}
                    onChange={(e) => setMeetingRequest({ ...meetingRequest, ['subject']: e.target.value })}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="mt-2">
                  <FormTextArea
                    className="formtxtarea"
                    name="message"
                    id="message"
                    rows="3"
                    type="textarea"
                    label=""
                    placeholder="Add a personal note (max 1000 characters)"
                    // register={register}
                    value={meetingRequest.messge}
                    onChange={(e) => setMeetingRequest({ ...meetingRequest, ['message']: e.target.value })}
                  />
                </Col>
              </Row>



            </div>
          </div>



          <div className="modal-footer">
            <Button className="mr-2" style={{color: "#24869d"}} type="button" onClick={() => saveRequest()}>Send Request</Button>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
          </div>
        </Modal>
      </div>
    )
  }


  const getFilterData = () => {
    let filter = {
      specialityId: null,
      brandId: null,
      countryId: null,
      productId: null,
      intProductId: null,
      locationId: null,
      retailerprofileId: null,
      resellerprofileId: null,
      productCategoryId: null,
      turnOver: null,
      connectionStatusId: null,
      typeId: null,
      storeCount: null,
      eventId: eventId
    };
    let flag = 0;

    if (filterValueState.Current_Product && filterValueState.Current_Product.length > 0) {
      filter.productCategoryId = filterValueState.Current_Product.map(x => parseInt(x.childid));
      flag = 1;
    }


    if (filterValueState.Company_Origin && filterValueState.Company_Origin.length > 0) {
      filter.countryId = filterValueState.Company_Origin.map(x => parseInt(x.id));
      flag = 1;
    }

    if (filterValueState.typeId && filterValueState.typeId.length > 0) {
      filter.typeId = filterValueState.typeId.map(x => parseInt(x.id));
      flag = 1;
    }
    if (flag == 0) {
      resetFilterValue();
      setChannelFlag(true);
    }
    return filter;
  }

  const getChannelList = async () => {
    setLoading(true);
    let filter = getFilterData();
    
    let sendData = {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      filter: filter,
      isSeller:isSeller
    };

    let res = await axios.post('channel/prev_event_channelList/' + type + '/' + eventId + '/0', sendData).catch(err => setLoading(false)); //for public page url => publicChannel/channelList
    if (res) {
      data = res.data.result;

      setData(data.map(x => {
        if (x.contacts[0].status == 4){
          Object.assign(x,{statusText: 'Already confirmed you'})
          return x;
        }
        else if (x.contacts[0].status == 3){
          Object.assign(x,{statusText: 'Request Already Sent'})
          return x;
        }
        else if (x.contacts[0].status == 5){
          Object.assign(x,{statusText: 'You already declined a meeting request from this company'})
          return x;
        }
        else{
          Object.assign(x,{receiver:{channelId: x.contacts[0].channelId, contactId: x.contacts[0].contactId}})
          return {...x,showRequest:true}
        }
      }));


      total = res.data.total;
      setTotal(total);
      setConfidential(res.data.isConfidential);
      console.log('data::', data);
      setLoading(false);
    } else {
      data = [];
      setData(data);
      total = 0;
      setTotal(total);
      setLoading(false);
      ToastsStore.warning('Something went wrong');
    }
  }

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getChannelList();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callChannelList();
  };

  const detailView = async (id) => {
    let tokenData = await commonService.getServices('account/getPublicToken/' + id);
    if (tokenData) {
      // history.push('channelView/'+ id+'?token='+tokenData.data.token);
      const win = window.open(process.env.REACT_APP_LOCALSITE + 'channelView/' + id + '?token=' + tokenData.data.token, '_blank');
      win.focus();
    }
    else
      ToastsStore.warning('Something went wrong');
  }
  return (
    <div id="iner" className="main-content mt-3">
      {request &&
        EventRequest()}
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      {(data.length <= 0 && !isLoading) && <div className="no-records ">
        <div className="no-img text-center">
          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} />
        </div>
      </div>}
      {isLoading ? <Spinner /> : <>
        {data.length > 0 && data.map((item, index) => {
          let logo = "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg";
          return (
            <Row className="justify-content-center">
              <Col className="card-wrapper ct-example" lg="12" md="12" sm="12" >
                <Card>
                  <CardBody>
                    <Row className="align-items-center justify-content-center">
                      <Col className="col-lg-2 col-md-2 col-sm-12 mobi-res">
                        <a
                          style={{ width: "110px", height: "110px", border: "1px solid #b8b8b8", backgroundColor: "white", cursor: isConfidential == 1 ? 'none' : 'pointer' }}
                          className={"avatar avatar-xl mb-2"}
                          href="#pablo"
                          onClick={() => { isConfidential != 1 && detailView(item.cid) }}
                        >
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                            alt="..."
                            src={item.logo ? process.env.REACT_APP_CDN_URL + item.logo : logo}
                          />
                        </a>
                        <div style={{ cursor: isConfidential == 1 ? 'none' : 'pointer' }} onClick={() => { isConfidential != 1 && detailView(item.cid) }}>
                          <Badge style={{ backgroundColor: "#2c9db7", color: "white", fontSize: "0.8000rem", textTransform: "capitalize", width: "100%", }}>
                            +details
                          </Badge>
                        </div>
                        {item.webSiteUrl && <div className="text-center">

                          <a style={{ cursor: isConfidential == 1 ? 'none' : 'pointer' }}
                            onClick={(e) => {
                              e.preventDefault();
                              let url = item.webSiteUrl;
                              if (!url.match(/^https?:\/\//i)) {
                                if (!url.match(/^http?:\/\//i))
                                  url = '//' + url;
                              }
                              if (isConfidential != 1)

                                window.open(url, '_blank')
                            }}
                          >
                            <i className="fas fa-globe-asia mr-1"></i>Website
                          </a>

                        </div>}
                      </Col>
                      <div className="col-lg-9 col-md-9 col-sm-12 mobi-res3">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-sm-12 mobi-res1  res-padr1">
                            <Badge
                              style={{
                                backgroundColor: "#FF6347",
                                color: "white",
                                fontSize: "0.9000rem",
                              }}
                            >
                              {item.channelType}
                            </Badge>
                          </div>
                          {/* flag */}
                          <div className="col-auto col mobi-res2">
                            <a className="avatar avatar-xs rounded-circle">
                              <CountryFlag code={item.isoCode} />
                            </a>
                          </div>

                          <div style={{ paddingLeft: "0px" }} className={"col-lg-4 col-md-3 col-sm-12 mobi-res pad-01"}>
                            <h3 className="mb-0">{item.country}</h3>
                          </div>
                          


                        </div>
                        <Row>
                          <Col className="col-lg-auto col-md-auto col-sm-12 mobi-res2">
                            <h3 className={"mb-0 text-uppercase"}>
                              <a style={{ cursor: isConfidential == 1 ? 'none' : 'pointer' }}
                                href="#pablo"
                                onClick={() => { isConfidential != 1 && detailView(item.cid) }}
                              >
                                {item.companyName}
                              </a>
                            </h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-lg-7 col-md-7 col-sm-12">
                            <h4>
                              Specialty in/Categories:
                              <p className="ml-2" style={{ display: "inline" }}>
                                {item.company_cat && <ListItem elements={item.company_cat} index={'cat' + index} limit={4} />}
                              </p>
                            </h4>
                            {item.channelTypeId !== '4' && <h4>
                              Channel Customer Profiles: &nbsp;
                              <p style={{ display: "inline" }}>
                                {item.retail_customer_profile && <ListItem elements={item.retail_customer_profile} index={'pro' + index} limit={4} />}
                              </p>
                            </h4>}
                            {item.channelTypeId === '4' && <h4>
                              Interest In Start-Up : &nbsp;
                              <p style={{ display: "inline" }}>
                                {item.looking_startups && item.looking_startups[0].item_name && item.looking_startups[0].item_name.replace('S_VOLUME', ' Shipping in volume').replace('S_BACKER', ' Shipping to first backers').replace('PROTOTYPE', ' Prototype')}
                              </p>
                            </h4>}
                          </Col>
                          {/* {item.matchingscore && */}
                          <Col className="col-lg-5 col-md-5 col-sm-12 pl-0 pr-0 mobi-res4">
                            <Progress style={{ height: "2rem" }} value={item.matchingScore}>
                              <h4 style={{ color: "white" }} className="mb-0">
                                Matching Score: {item.matchingScore + '%'}
                              </h4>
                            </Progress>
                          </Col>

                          {/* } */}
                        </Row>
                        <Row>
                          <Col className="col-auto">
                            <h3 className="mb-0 text-uppercase">
                              <span
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                style={{ color: "#80807F" }}

                              >
                                Tweet Pitch
                              </span>
                            </h3>
                            <p>
                              {!!item.detailDesc ? item.detailDesc.replace(/&amp;/g, '&').replace(/<[^>]+>/g, '') : ''}
                            </p>
                          </Col>
                        </Row>
                      </div>


                    

                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>)
        })}
        {total > 10 &&
          <TablePagination
            component="div"
            count={total}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 20, 30, 40]}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        }
      </>}
    </div >

  );
};
export default EventChannelListing;
