import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, CardHeader, UncontrolledTooltip } from "reactstrap";
import { FormAutocomplete, AddFieldWithPopUpAutocomplete } from "_components/FormElements/FormInput";
import { TradeInformationSchema } from './validation';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { BrandCard } from "views/Hoc/BrandCard";
import { TextField } from '@material-ui/core';
import AddKeyPartner from "views/Hoc/Dialog/AddKeyPartner";
import { commonService } from '_services/common.service';
import { Drawer } from 'antd';
import { channelAction } from '_actions/channel.action';
import { connect } from 'react-redux';
import axios from 'axios';
const DistributionChannel = (props) => {
  const { closeDrawer, visible, title, isAdd, params, productDetail, productType } = props;
  var _ = require('lodash');
  const { register, control, errors, watch } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(TradeInformationSchema),
  });
  const [isdata, setData] = useState(true);
  const [openDist, setOpenDist] = useState(false);
  const [openRetailer, setOpenRetailer] = useState(false);
  const [openReseller, setOpenReseller] = useState(false);
  let [country, setCountry] = useState([]);
  let [options, setOptions] = useState({ keyRetailerOptions: [], keyDistributorOptions: [], keyResellerOptions: [] });
  let [keyData, setKeyData] = useState({ keyRetailer: [], keyDistributor: [], keyReseller: [] });
  const [opneadd, opneAddnew] = useState(false);
  const [brandName, setBrandName] = useState('');

  var userData = JSON.parse(localStorage.getItem('CHuser'));

  //businesstype
  let businessType = productType; //productDetail?.isOEM ? productDetail?.isOEM : userData.businessType;


  useEffect(() => {
    getCountry();
    getPartnerChannelSearch(3);
    getPartnerChannelSearch(4);
    getPartnerChannelSearch(5);
    getChannelLocation();
    if (!isAdd) {
      setKeys(3);
      setKeys(4);
      setKeys(5);
    } else {
      getKeyPartners(3);
      getKeyPartners(4);
      getKeyPartners(5);
    }
    getChannelInfos();
  }, []);
  const setKeys = (type) => {
    let keyRetailerValues = [];
    let data = [];
    if (type === 4) {
      data = productDetail?.productKeyRetailer ? productDetail?.productKeyRetailer : [];
      state.RetailerDesc = productDetail?.productDetail?.keyretailers;
    } else if (type === 3) {
      data = productDetail?.productKeyDistributor ? productDetail?.productKeyDistributor : [];
      state.distributorDesc = productDetail?.productDetail?.keydistributors;
    } else if (type === 5) {
      data = productDetail?.productKeyReseller ? productDetail?.productKeyReseller : [];
      state.ResellerDesc = productDetail?.productDetail?.keyresellers;
    }
    if (data?.length) {
      data.map(x => {
        let logo = x.active ? x.active?.logo?.documentPath : x.old ? x.old.logo?.documentPath : x.new ? x.new.logo?.logo_name : '';
        let imageid = x.active ? x.active?.logo?.logo_id : x.old ? x.old.logo?.logo_id : x.new ? x.new.logo?.logo_id : '';
        let companyName = x.active ? x.active.company_name : x.old ? x.old.company_name : x.new ? x.new.company_name : '';
        let id = x.active ? x.active.id : x.old ? x.old.id : x.new ? x.new.id : x.channelkeypartnerid ? x.channelkeypartnerid : '';
        let brand = {
          id: id,
          name: companyName,
          channelId: x.active ? x.active.id : null,
          oldchannelid: x.old ? x.old.id : null,
          newChannelId: x.new ? x.new.id : null,
          documentpath: logo ? process.env.REACT_APP_CDN_URL + logo : null,
          image: logo ? logo : null,
          imageid: imageid ? imageid : null,
          countryid: x.active ? x.active.country.country_id : x.old ? x.old.country.country_id : x.new ? x.new.country.country_id : '',
          isNew: 0
        }
        keyRetailerValues.push(Object.assign(x, brand));
      });
      if (type === 4) {
        state.RetailerList = keyRetailerValues;
        state.RetailerDesc = productDetail?.productDetail?.keyretailers;
      }
      if (type === 3) {
        state.DistributorList = keyRetailerValues;
        state.distributorDesc = productDetail?.productDetail?.keydistributors;
      }
      if (type === 5) {
        state.ResellerList = keyRetailerValues;
        state.ResellerDesc = productDetail?.productDetail?.keyresellers;
      }
      setState({ ...state });
      setStateRedux();
    }
  }
  const [state, setState] = useState({
    Distributor: [],
    DistributorList: [],
    Retailer: [],
    RetailerList: [],
    Reseller: [],
    ResellerList: [],
    RetailerListUser: [],
    DistributorListUser: [],
    ResellerListUser: [],
    distributorDesc: null,
    RetailerDesc: null,
    ResellerDesc: null,
    RetailerOptions: [],
    DistributorOptions: [],
    ResellerOptions: [],
    relevantRetailOptions: [],
    relevantRetail: [],
    relevantDistributorOptions: [],
    relevantDistributor: [],
    relevantResellerOptions: [],
    relevantReseller: [],
    target: [],
    selling: [],
    retail: [],
    deletedkeydistributor: [],
    deletedkeyretailer: [],
    deletedkeyreseller: []
  });
  const [location, setLocation] = useState({
    targetZoneOption: [],
    targetZone: [],
    zone: [],
    targetRegionOption: [],
    targetRegion: [],
    region: [],
    targetCountryOption: [],
    targetCountry: [],
    country: []
  });
  const setRelavent = async () => {
    setData(true);
    console.log('productDetail', productDetail);
    state.relevantRetail = productDetail?.RETAILER;
    state.relevantDistributor = productDetail?.DISTRIBUTOR;
    state.relevantReseller = productDetail?.RESELLER;
    setState({ ...state });
    setStateRedux();
    setData(false);
    console.log('relevantRetailOptions', state);
  };
  const getChannelInfos = async () => {
    const res = await commonService.getServices('channel-infos');
    if (res) {
      state.relevantRetailOptions = res.data.RETAILER;
      state.relevantRetail = res.data.RETAILER;
      state.relevantDistributorOptions = res.data.DISTRIBUTOR;
      state.relevantDistributor = res.data.DISTRIBUTOR;
      state.relevantResellerOptions = res.data.RESELLER;
      state.relevantReseller = res.data.RESELLER;
      setState({ ...state });
      if (!isAdd) {
        setRelavent();
      }
      setStateRedux();
      console.log('relevantRetailOptions', state);
    }
  };
  const setLocationRedux = () => {
    props.distributionChannelRedux(location.targetCountry, 'LOCATION');
  }
  const setStateRedux = () => {
    let data = {
      DistributorList: state.DistributorList,
      distributorDesc: state.distributorDesc,
      RetailerDesc: state.RetailerDesc,
      RetailerList: state.RetailerList,
      ResellerDesc: state.ResellerDesc,
      ResellerList: state.ResellerList,
      relevantDistributor: state.relevantDistributor,
      relevantRetail: state.relevantRetail,
      relevantReseller: state.relevantReseller,
      deletedkeydistributor: state.deletedkeydistributor,
      deletedkeyretailer: state.deletedkeyretailer,
      deletedkeyreseller: state.deletedkeyreseller
    }
    props.distributionChannelRedux(data, 'STATE');
  }
  const handleChangeSelect = (input, value) => {
    console.log('input', input, 'value', value);
    if (input === 'targetZone' || input === 'targetRegion' || input === 'targetCountry') {
      location[input] = value;
      let zoneIds = [];
      location.targetZone.map(x => zoneIds.push(x.id));
      location.targetRegion = location.targetRegion.filter(item => zoneIds.includes(item.parentId));
      location.targetRegionOption = location.region.filter(item => zoneIds.includes(item.parentId));
      let regionsIds = [];
      location.targetRegion.map(x => regionsIds.push(x.id));
      location.targetCountry = location.targetCountry.filter(item => regionsIds.includes(item.parentId));
      location.targetCountryOption = location.country.filter(item => regionsIds.includes(item.parentId));
      setLocation({ ...location });
      setLocationRedux();
    }
    if (input === 'Distributor' || input === 'Retailer' || input === 'Reseller') {
      if (value.hasOwnProperty('isNew')) {
        state[input] = value;
      } else {
        value.isNew = '0';
        state[input] = value;
      }
      setState({ ...state });
      let temp = state[input];
      if (temp.channelId) {
        temp = state[input + 'List'].filter(x => x.channelId === temp.channelId);
        if (temp.length) {
          console.log('already entered');
        } else {
          console.log('no entry')
          state[input + 'List'].push(state[input]);
          state[input + 'ListUser'].push(state[input]);
          setState({ ...state });

          state[input + 'List'].forEach(selectitem => {
            if (selectitem.channelId !== null) {
              options['key' + input + 'Options'] = options['key' + input + 'Options'].filter(item => parseInt(item.channelId) !== parseInt(selectitem.channelId));
            }
          });
          setOptions({ ...options });
        }
      } else if (temp.newChannelId) {
        temp = state[input + 'List'].filter(x => x.newChannelId === temp.newChannelId);
        if (temp.length) {
          console.log('already entered');
        } else {
          console.log('no entry')
          state[input + 'List'].push(state[input]);
          state[input + 'ListUser'].push(state[input]);
          setState({ ...state });
          state[input + 'List'].forEach(selectitem => {
            if (selectitem.channelId !== null) {
              options['key' + input + 'Options'] = options['key' + input + 'Options'].filter(item => parseInt(item.newChannelId) !== parseInt(selectitem.newChannelId));
            }
          });
          setOptions({ ...options });
        }
      } else if (temp.oldchannelid) {
        temp = state[input + 'List'].filter(x => x.oldchannelid === temp.oldchannelid);
        if (temp.length) {
          console.log('already entered');
        } else {
          console.log('no entry')
          state[input + 'List'].push(state[input]);
          state[input + 'ListUser'].push(state[input]);
          setState({ ...state });
          state[input + 'List'].forEach(selectitem => {
            if (selectitem.channelId !== null) {
              options['key' + input + 'Options'] = options['key' + input + 'Options'].filter(item => parseInt(item.oldchannelid) !== parseInt(selectitem.oldchannelid));
            }
          });
          setOptions({ ...options });
        }
      } else {
        state[input + 'List'].push(state[input]);
        state[input + 'ListUser'].push(state[input]);
        setState({ ...state });
      }
      state[input] = [];
      setState({ ...state });
      input === 'Distributor' ? setKeyOptions(3) : input === 'Retailer' ? setKeyOptions(4) : setKeyOptions(5);
    }
    if (input === 'relevantDistributor' || input === 'relevantRetail' || input === 'relevantReseller') {
      state[input] = value;
      setState({ ...state });
    }
    setStateRedux();
  }

  console.log('state lists', state.ResellerList, state.RetailerList, state.DistributorList);

  const deleteBrand1 = (id) => {
    const filteredList = state.DistributorList.filter((item) => {
      if (item.id == id && item.keypartnerid) {
        let temp = state.deletedkeydistributor;
        temp.push(+item.keypartnerid);
        state.deletedkeydistributor = temp;
        setState({ ...state });
      }
      if (item.id == id && item.isNew == '1') {
        state.DistributorListUser = state.DistributorListUser.filter(x => x.id != item.id)
      }
      return item.id !== id
    });
    state.DistributorList = filteredList;
    setState({ ...state, DistributorList: state.DistributorList, DistributorListUser: state.DistributorListUser });
    if (state.DistributorList.length === 0) {
      state.Distributor = [];
      setState({ ...state });
      // errors.Distributor={message: 'Please select Distributor', type:'required'};
    }
    setKeyOptions(3);
    setStateRedux();
  }
  const deleteBrand2 = (id) => {
    const filteredList = state.RetailerList.filter((item) => {
      if (item.id == id && item.keypartnerid) {
        let temp = state.deletedkeyretailer;
        temp.push(+item.keypartnerid);
        state.deletedkeyretailer = temp;
        setState({ ...state });
      }
      if (item.id == id && item.isNew == '1') {
        state.RetailerListUser = state.RetailerListUser.filter(x => x.id != item.id);
      }
      return item.id !== id
    });
    state.RetailerList = filteredList;
    setState({ ...state, RetailerList: state.RetailerList, RetailerListUser: state.RetailerListUser });
    if (state.RetailerList.length === 0) {
      setState({ ...state, Retailer: [] });
      // errors.Retailers={message: 'Please select a Brand', type:'required'};
    }
    setKeyOptions(4);
    setStateRedux();
  }

  const deleteBrand3 = (id) => {
    const filteredList = state.ResellerList.filter((item) => {
      if (item.id == id && item.keypartnerid) {
        let temp = state.deletedkeyreseller;
        temp.push(+item.keypartnerid);
        state.deletedkeyreseller = temp;
        setState({ ...state });
      }
      if (item.id == id && item.isNew == '1') {
        state.ResellerListUser = state.ResellerListUser.filter(x => x.id != item.id)
      }
      return item.id !== id
    });
    state.ResellerList = filteredList;
    setState({ ...state, ResellerList: state.ResellerList, ResellerListUser: state.ResellerListUser });
    if (state.ResellerList.length === 0) {
      state.Reseller = [];
      setState({ ...state });
      // errors.Resellers={message: 'Please select Distributor', type:'required'};
    }
    setKeyOptions(5);
    setStateRedux();
  }

  const getChannelLocation = async () => {
    const res = await commonService.getServices('getChannelLocation/' + userData.channelId);
    if (res) {
      const locationCollection = res.data;
      if (locationCollection.currentLocation && locationCollection.currentLocation.TARGET.length > 0) {
        state.target = locationCollection.currentLocation.TARGET;
      } else if (locationCollection.oldLocation) {
        state.target = locationCollection.oldLocation.TARGET;
      }
      setState({ ...state });
      setLocationData();
    }
  };
  const setLocationData = () => {
    state.target.forEach(zoneItem => {
      location.targetZoneOption.push({
        'id': zoneItem.zoneId, 'name': zoneItem.zone
      });
      zoneItem.region.forEach(regionItem => {
        location.targetRegionOption.push({
          'parentId': zoneItem.zoneId, 'id': regionItem.regionId, 'name': regionItem.region
        });
        regionItem.country.forEach(countryItem => {
          location.targetCountryOption.push({
            'parentId': regionItem.regionId, 'id': countryItem.countryId,
            'name': countryItem.country,
            'regioncountryjctid': countryItem.RegionCountryJCT.regionCountryJCTId
          });
        });
      });
    });
    location.targetZone = location.targetZoneOption;
    location.targetRegion = location.targetRegionOption;
    location.targetCountry = location.targetCountryOption;
    location.zone = location.targetZoneOption;
    location.region = location.targetRegionOption;
    location.country = location.targetCountryOption;
    if (!isAdd) {
      let countryNames = productDetail?.locations ? productDetail?.locations?.TARGET : [];
      // if(countryNames.length>0)
      location.targetCountry = location.targetCountryOption.filter(x => countryNames.includes(x.name));
    } else {
      location.targetCountry = location.targetCountryOption;
    }
    setLocation({ ...location });
    setLocationRedux();
    console.log('location', location);
  }
  const getKeyPartners = async (type) => {
    const res = await commonService.getServiceWithParams({ partner_type: type }, 'KeyPartners/' + userData.channelId);

    if (res) {
      if (type === 3) {
        state.DistributorList = res.data.channelKeyRetailers;
        state.distributorDesc = res.data.keyretailerDescription;
        setKeys(3);
      }
      else if (type === 4) {
        state.RetailerList = res.data.channelKeyRetailers;
        state.RetailerDesc = res.data.keyretailerDescription;
        setKeys(4);
      }
      else if (type === 5) {
        state.ResellerList = res.data.channelKeyRetailers;
        state.ResellerDesc = res.data.keyresellerDescription;
        setKeys(5);
      }
      setState({ ...state });
      console.log('distributionFormValue', state);
      setStateRedux();
    }
  };

  console.log('state', state);

  const onClose = () => {
    closeDrawer();
  };
  const getCountry = async () => {
    const res = await commonService.getServices('common/country');
    country = res.data;
    setCountry(country);
  }
  const opneAdd1 = async (input, value) => {
    console.log('opneadd', opneadd);
    await setBrandName(input);
    setOpenDist(true);
  }
  const opneAdd2 = async (input, value) => {
    console.log('opneadd', opneadd);
    await setBrandName(input);
    setOpenRetailer(true);
  }
  const opneAdd3 = async (input, value) => {
    console.log('opneadd', opneadd);
    await setBrandName(input);
    setOpenReseller(true);
  }
  const closeModalPopup = () => {
    setBrandName('');
    opneAddnew(false);
    setOpenRetailer(false);
    setOpenDist(false);
    setOpenReseller(false);
  }

  const getPartnerChannelSearch = async (type) => {
    const res = await commonService.getServiceWithParams({ search: '', partner_type: type }, 'partnerChannelSearch/' + type);
    if (res) {
      if (type === 3)
        keyData.keyDistributor = res.data;
      else if (type === 4)
        keyData.keyRetailer = res.data;
      else if (type === 5)
        keyData.keyReseller = res.data;
      setKeyData({ ...keyData });
      setKeyOptions(type);
    };
  }
  const setKeyOptions = (type) => {
    let keyRetailerFilterData = [];
    let _keyRetailerListResponse = type === 3 ? keyData.keyDistributor : type === 4 ? keyData.keyRetailer : type === 5 ? keyData.keyReseller : [];

    if (_keyRetailerListResponse.channel) {
      _keyRetailerListResponse.channel.forEach(item => {
        keyRetailerFilterData.push({
          id: item.channelId ? item.channelId : item.oldChannelId ? item.oldChannelId : null,
          channelId: item.channelId ? item.channelId : null,
          oldchannelid: item.oldChannelId ? item.oldChannelId : null,
          name: item.companyName,
          documentpath: item.companylogo ? item.companylogo.documentUrl : null,
          documentUrl: item.companylogo ? item.companylogo.documentUrl : null,
          image: item.companylogo ? item.companylogo.documentpath : null,
          imageid: item.companylogo ? item.companylogo.documentId : null,
          countryid: item.regaddresscountry === undefined ? null : item.regaddresscountry?.countryId === undefined ? null : item.regaddresscountry?.countryId,
          existchannelid: item.channelId ? item.channelId : null,
          countryName: item.regaddresscountry === undefined ? null : item.regaddresscountry?.country === undefined ? null : item.regaddresscountry?.country
        });
      });
      if (type === 4) {
        state.RetailerList.forEach(selectitem => {
          if (selectitem.channelId !== null) {
            keyRetailerFilterData = keyRetailerFilterData.filter(item => parseInt(item.channelId) !== parseInt(selectitem.channelId));
          }
        });
      } else if (type === 3) {
        state.DistributorList.forEach(selectitem => {
          if (selectitem.channelId !== null) {
            keyRetailerFilterData = keyRetailerFilterData.filter(item => parseInt(item.channelId) !== parseInt(selectitem.channelId));
          }
        });
      }
      else if (type === 5) {
        state.ResellerList.forEach(selectitem => {
          if (selectitem.channelId !== null) {
            keyRetailerFilterData = keyRetailerFilterData.filter(item => parseInt(item.channelId) !== parseInt(selectitem.channelId));
          }
        });
      }
    }
    if (_keyRetailerListResponse.oldchannel) {
      _keyRetailerListResponse.oldchannel.forEach(item => {
        keyRetailerFilterData.push({
          id: item.oldChannelId ? item.oldChannelId : null,
          channelId: null,
          oldchannelid: item.oldChannelId ? item.oldChannelId : null,
          name: item.companyName,
          documentpath: item.oldchanneldetail ? item.oldchanneldetail.documentUrl : null,
          documentUrl: item.oldchanneldetail ? item.oldchanneldetail.documentUrl : null,
          countryid: (item.oldregaddresscountry === undefined || item.oldregaddresscountry === null) ? null : item.oldregaddresscountry.countryId === undefined ? null : item.oldregaddresscountry.countryId,
          existchannelid: null,
          image: item.companylogo ? item.companylogo.documentpath : null,
          imageid: item.companylogo ? item.companylogo.documentId : null,
          countryName: (item.oldregaddresscountry === undefined || item.oldregaddresscountry === null) ? null : item.oldregaddresscountry.country === undefined ? null : item.oldregaddresscountry.country
        });
      });
      if (type === 4) {
        state.RetailerList.forEach(selectitem => {
          if (selectitem.oldchannelid !== null) {
            keyRetailerFilterData = keyRetailerFilterData.filter(item => parseInt(item.oldchannelid) !== parseInt(selectitem.oldchannelid));
          }
        });
      } else if (type === 3) {
        state.DistributorList.forEach(selectitem => {
          if (selectitem.oldchannelid !== null) {
            keyRetailerFilterData = keyRetailerFilterData.filter(item => parseInt(item.oldchannelid) !== parseInt(selectitem.oldchannelid));
          }
        });
      }
      else if (type === 5) {
        state.ResellerList.forEach(selectitem => {
          if (selectitem.oldchannelid !== null) {
            keyRetailerFilterData = keyRetailerFilterData.filter(item => parseInt(item.oldchannelid) !== parseInt(selectitem.oldchannelid));
          }
        });
      }

    }

    if (_keyRetailerListResponse.keypartners) {
      _keyRetailerListResponse.keypartners.forEach(item => {
        keyRetailerFilterData.push({
          id: item.channelkeypartnerid,
          channelId: null,
          oldchannelid: null,
          name: item.partnername,
          documentpath: item.companylogo ? item.companylogo.documentUrl : null,
          documentUrl: item.companylogo ? item.companylogo.documentUrl : null,
          countryid: item.regaddresscountry === undefined ? null : item?.regaddresscountry?.countryId === undefined ? null : item?.regaddresscountry?.countryId,
          existchannelid: null,
          image: item.companylogo ? item.companylogo.documentpath : null,
          imageid: item.companylogo ? item.companylogo.documentId : null,
          countryName: item.regaddresscountry === undefined ? null : item?.regaddresscountry?.country === undefined ? null : item?.regaddresscountry?.country,
          keypartnerid: item.keypartnerid ? item.keypartnerid : null,
          channelkeypartnerid: item.channelkeypartnerid,
          newChannelId: item.channelkeypartnerid
        });
      });
      if (type === 4) {
        state.RetailerList.forEach(selectitem => {
          if (selectitem.newChannelId !== null) {
            keyRetailerFilterData = keyRetailerFilterData.filter(item => parseInt(item.newChannelId) !== parseInt(selectitem.newChannelId));
          }
        });
      } else if (type === 3) {
        state.DistributorList.forEach(selectitem => {
          if (selectitem.newChannelId !== null) {
            keyRetailerFilterData = keyRetailerFilterData.filter(item => parseInt(item.newChannelId) !== parseInt(selectitem.newChannelId));
          }
        });
      }
      else if (type === 5) {
        state.ResellerList.forEach(selectitem => {
          if (selectitem.newChannelId !== null) {
            keyRetailerFilterData = keyRetailerFilterData.filter(item => parseInt(item.newChannelId) !== parseInt(selectitem.newChannelId));
          }
        });
      }
    }
    console.log('keyRetailerFilterData', keyRetailerFilterData);
    if (type === 4) {
      options.keyRetailerOptions = keyRetailerFilterData;
    } else if (type === 3) {
      options.keyDistributorOptions = keyRetailerFilterData
    }
    else if (type === 5) {
      options.keyResellerOptions = keyRetailerFilterData
    }
    setOptions({ ...options });
  }
  const handleChange = input => e => {
    console.log("add", e.target.value);
    state[input] = e.target.value
    setState({ ...state });
    setStateRedux();
  }
  return (
    <div className="site-form-in-drawer-wrapper">
      <Drawer title={title} onClose={onClose} visible={visible}>
        {(!isAdd && isdata) ? '' : <Card>
          <CardHeader>
            <h3 className="text-center">Distributor Channels </h3>
            <p className="text-center"></p>
          </CardHeader>
          <CardBody style={{ padding: "0px" }}>
            {state?.relevantDistributorOptions?.length > 0 && <Row>
              <Col lg="12" className="mt-4">
                <h4 >Relevant Distribution Channel</h4>
                <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="relevantChannelProfiles" name="relevantChannelProfiles" ref={register} value={state?.ChannelProfileValue?.length ? state?.ChannelProfileValue[0]?.childname : 'broadliner'} />
                <div className="form-group">
                  <FormAutocomplete
                    id="relevantDistributor"
                    name="relevantDistributor"
                    label="Relevant Distribution"
                    options={state?.relevantDistributorOptions}
                    placeholder="Relevant Distribution"
                    multiple
                    disableCloseOnSelect
                    limitTags={2}
                    control={control}
                    input='relevantDistributor'
                    value={state?.relevantDistributor}
                    handleChangeSelect={handleChangeSelect}
                    default={state?.relevantDistributor}
                    register={register}
                    // open={true}
                    popupIcon=''
                    disablePortal={true}
                    size='small'
                    ListboxProps={{ style: { maxHeight: '16rem' } }}
                  />
                </div>
              </Col>
            </Row>}


            <Row>
              <Col lg="12">
                <div className="form-group mt-3">
                  {state?.DistributorList && <>
                    <h4 >Key Distributors </h4>
                    <BrandCard name="BMW" deleteBrand={deleteBrand1} brandList={state?.DistributorList} img={"http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />
                  </>}

                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="DistributorList" name="DistributorList" ref={register} value={state?.Distributor ? 'data' : ''} />
                  {/* Autocomplete with image */}
                  <AddFieldWithPopUpAutocomplete
                    limitTags={2}
                    name="Distributor"
                    id="Distributor"
                    options={options.keyDistributorOptions}
                    // defaultValue={state?.Distributor}
                    value={state?.Distributor}
                    getOptionLabel={option => option.name}
                    renderOption={option => (<><div style={{ display: '-webkit-inline-flex', padding: '1px 6px', width: '75px', margin: '10px 5px 0 0', float: 'left', border: '2px solid #e7e7e7', borderRadius: '5px', height: '38px' }}><img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{ maxWidth: '63px', backgroundColor: '#fff', maxHeight: '34px', height: 'auto', width: 'auto', margin: '0 auto', cursor: 'pointer' }} src={option.documentUrl ? option.documentUrl : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} /></div><span>{option.name}</span></>)}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Key Distributors" placeholder="Key Distributors" />
                    )}
                    label="Key Distributors"
                    placeholder="Key Distributors"
                    input="Distributor"
                    control={control}
                    register={register}
                    errMessage={'Please Select  Key Distributors'}
                    error={errors.Distributor && !state.DistributorList[0] ? errors.Distributor : (errors.DistributorList && !state.DistributorList[0]) ? true : false}
                    handleChangeSelect={handleChangeSelect}
                    opneAdd={opneAdd1}
                  />
                  {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openDist} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={3} input="Distributor" countryList={country}
                    userList={state.DistributorListUser} />}
                </div>
              </Col>
              <Col lg="12">
                <div className="form-group">
                  <form>
                    <textarea
                      class="qtytext buytext2"
                      id="exampleFormControlTextarea1"
                      onChange={handleChange('distributorDesc')}
                      rows="3"
                      value={state?.distributorDesc}
                      aria-label="empty textarea"
                      placeholder="Others Key Distributors Description">
                    </textarea>
                  </form>
                </div>
              </Col>
            </Row>

            {(businessType == '1' || businessType == '3') && <CardHeader>
              <h3 className="text-center">Retail Channels </h3>
              <p className="text-center"></p>
            </CardHeader>}

            {(businessType == '1' || businessType == '3') && state?.relevantRetailOptions?.length > 0 && <Row>
              <Col lg="12 mt-4">
                <h4 >Relevant Retail Channel</h4>
                <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="relevantChannelProfiles" name="relevantChannelProfiles" ref={register} value={state?.ChannelProfileValue?.length ? state?.ChannelProfileValue[0]?.childname : 'broadliner'} />
                <div className="form-group">
                  <FormAutocomplete
                    id="relevantRetail"
                    name="relevantRetail"
                    label="Relevant Retail"
                    options={state?.relevantRetailOptions}
                    placeholder="Relevant Retail"
                    getOptionSelected={(options, value) => options.name === value.name}
                    multiple
                    disableCloseOnSelect
                    limitTags={2}
                    control={control}
                    input='relevantRetail'
                    value={state?.relevantRetail}
                    default={state?.relevantRetail}
                    handleChangeSelect={handleChangeSelect}
                    register={register}
                    // open={true}
                    popupIcon=''
                    disablePortal={true}
                    size='small'
                    ListboxProps={{ style: { maxHeight: '16rem' } }}
                  />
                </div>
              </Col>
            </Row>}

            {(businessType == '1' || businessType == '3') && <Row>
              <Col lg="12">
                <div className="form-group mt-3">
                  {state?.RetailerList.length > 0 && <> <h4 >Key Retailers </h4>
                    <BrandCard name="BMW" deleteBrand={deleteBrand2} brandList={state?.RetailerList} img={"http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />
                  </>}

                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="retailerList" name="retailerList" ref={register} value={state?.Retailer ? 'data' : ''} />
                  {/* Autocomplete with image */}
                  <AddFieldWithPopUpAutocomplete
                    limitTags={2}
                    name="Retailers"
                    id="Retailers"
                    options={options.keyRetailerOptions}
                    // defaultValue={state.Retailer}
                    value={state?.Retailer}
                    getOptionLabel={option => option.name}
                    renderOption={option => (<><div style={{ display: '-webkit-inline-flex', padding: '1px 6px', width: '75px', margin: '10px 5px 0 0', float: 'left', border: '2px solid #e7e7e7', borderRadius: '5px', height: '38px' }}><img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{ maxWidth: '63px', backgroundColor: '#fff', maxHeight: '34px', height: 'auto', width: 'auto', margin: '0 auto', cursor: 'pointer' }} src={option.documentUrl ? option.documentUrl : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} /></div><span>{option.name}</span></>)}
                    renderInput={(params) => (<TextField {...params} variant="outlined" label="Retailers" placeholder="Retailers" />)}
                    label="Key Retailers"
                    placeholder="Key Retailers"
                    input="Retailer"
                    control={control}
                    register={register}
                    errMessage={'Please Select a Key Retailers'}
                    error={errors.Retailers && !state.RetailerList[0] ? errors.Retailers : (errors.retailerList && !state.RetailerList[0]) ? true : false}
                    handleChangeSelect={handleChangeSelect}
                    opneAdd={opneAdd2}
                  />
                  {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openRetailer} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={4} input="Retailer" countryList={country}
                    userList={state.RetailerListUser} />}

                </div>
              </Col>
              <Col lg="12">
                <div className="form-group">
                  <form>
                    <textarea
                      class="qtytext buytext2"
                      id="exampleFormControlTextarea1"
                      onChange={handleChange('RetailerDesc')}
                      rows="3"
                      value={state?.RetailerDesc}
                      aria-label="empty textarea"
                      placeholder="Others Key Retailers Description">
                    </textarea>
                  </form>
                </div>
              </Col>
            </Row>
            }
            {/* <Row>
                     <Col lg="12">
                     <h4>Selling Location *</h4>
                         <Row>
                     <Col lg="4">
                     <div>
                    <TextField className="comp-name mt-3" id="outlined-search" label="Zone" type="text" variant="outlined" />
                    <div style={{border:"1px solid #c4c4c4",padding:"15px",overflowY:"scroll",height:"10rem"}}>
                                {location.sellingZoneOption.length>0 && location.sellingZoneOption.map(zone=>{
                                return(
                                <div className="chck">
                                <FormCheckBox
                                    id={zone.id}
                                    type="checkbox"
                                    label={zone.name}
                                    label1={{textAlign:"justify"}}
                                    register={register}
                                />
                            </div>)
                                })
                                }
                      </div>
                                </div>
                     </Col>
                     <Col lg="4">
                     <div>
                    <TextField className="comp-name mt-3" id="outlined-search" label="Region" type="text" variant="outlined" />
                    <div style={{border:"1px solid #c4c4c4",padding:"15px",overflowY:"scroll",height:"10rem"}}>
                    {location.sellingRegionOption.length>0 && location.sellingRegionOption.map(rl=>{
                                return(<div className="chck">
                                <FormCheckBox
                                    id={rl.id}
                                    type="checkbox"
                                    label={rl.name}
                                    label1={{textAlign:"justify"}}
                                    register={register}
                                />
                          </div>)
                                })
                                }
                                </div>
                                </div>
                     </Col>
                     <Col lg="4">
                     <div>
                    <TextField className="comp-name mt-3" id="outlined-search" label="Country" type="text" variant="outlined" />
                    <div style={{border:"1px solid #c4c4c4",padding:"15px",overflowY:"scroll",height:"10rem"}}>
                    {location.sellingCountryOption.length>0 && location.sellingCountryOption.map(c=>{
                                return(
                                <div className="chck">
                                <FormCheckBox
                                    id={c.id}
                                    type="checkbox"
                                    label={c.name}
                                    label1={{textAlign:"justify"}}
                                    register={register}
                                />
                                </div>)
                                })
                                }
                                </div>
                                </div>
                     </Col>
                     </Row>
                     </Col>
                 </Row> */}


            {(businessType == '2' || businessType == '3') && <CardHeader>
              <h3 className="text-center">Reseller Channels </h3>
              <p className="text-center"></p>
            </CardHeader>}

            {(businessType == '2' || businessType == '3') && state?.relevantResellerOptions?.length > 0 && <Row>
              <Col lg="12 mt-4">
                <h4 >Relevant Reseller Channel</h4>
                <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="relevantChannelProfiles" name="relevantChannelProfiles" ref={register} value={state?.ChannelProfileValue?.length ? state?.ChannelProfileValue[0]?.childname : 'broadliner'} />
                <div className="form-group">
                  <FormAutocomplete
                    id="relevantRetail"
                    name="relevantRetail"
                    label="Reseller Channel"
                    options={state?.relevantResellerOptions}
                    placeholder="Reseller Channel"
                    getOptionSelected={(options, value) => options.name === value.name}
                    multiple
                    disableCloseOnSelect
                    limitTags={2}
                    control={control}
                    input='relevantReseller'
                    value={state?.relevantReseller}
                    default={state?.relevantReseller}
                    handleChangeSelect={handleChangeSelect}
                    register={register}
                    // open={true}
                    popupIcon=''
                    disablePortal={true}
                    size='small'
                    ListboxProps={{ style: { maxHeight: '16rem' } }}
                  />
                </div>
              </Col>
            </Row>}

            {(businessType == '2' || businessType == '3') && <Row>
              <Col lg="12">
                <div className="form-group mt-3">
                  {state?.ResellerList.length > 0 && <> <h4 >Key Resellers </h4>
                    <BrandCard name="BMW" deleteBrand={deleteBrand3} brandList={state?.ResellerList} img={"http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />
                  </>}

                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="resellerList" name="resellerList" ref={register} value={state?.Reseller ? 'data' : ''} />
                  {/* Autocomplete with image */}
                  <AddFieldWithPopUpAutocomplete
                    limitTags={2}
                    name="Resellers"
                    id="Resellers"
                    options={options.keyResellerOptions}
                    // defaultValue={state.Reseller}
                    value={state?.Reseller}
                    getOptionLabel={option => option.name}
                    renderOption={option => (<><div style={{ display: '-webkit-inline-flex', padding: '1px 6px', width: '75px', margin: '10px 5px 0 0', float: 'left', border: '2px solid #e7e7e7', borderRadius: '5px', height: '38px' }}><img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{ maxWidth: '63px', backgroundColor: '#fff', maxHeight: '34px', height: 'auto', width: 'auto', margin: '0 auto', cursor: 'pointer' }} src={option.documentUrl ? option.documentUrl : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} /></div><span>{option.name}</span></>)}
                    renderInput={(params) => (<TextField {...params} variant="outlined" label="Resellers" placeholder="Resellers" />)}
                    label="Key Resellers"
                    placeholder="Key Resellers"
                    input="Reseller"
                    control={control}
                    register={register}
                    errMessage={'Please Select a Key Resellers'}
                    error={errors.Resellers && !state.ResellerList[0] ? errors.Resellers : (errors.resellerList && !state.ResellerList[0]) ? true : false}
                    handleChangeSelect={handleChangeSelect}
                    opneAdd={opneAdd3}
                  />
                  {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openReseller} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={5} input="Reseller" countryList={country}
                    userList={state.ResellerListUser} />}

                </div>
              </Col>
              <Col lg="12">
                <div className="form-group">
                  <form>
                    <textarea
                      class="qtytext buytext2"
                      id="exampleFormControlTextarea1"
                      onChange={handleChange('ResellerDesc')}
                      rows="3"
                      value={state?.ResellerDesc}
                      aria-label="empty textarea"
                      placeholder="Others Key Reseller Description">
                    </textarea>
                  </form>
                </div>
              </Col>
            </Row>}

            <Row>
              <Col lg="12 mt-4">
                <h4>Target Location * <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                  <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                <Row>
                  <Col lg="4">
                    <div>
                      {/* <TextField className="comp-name mt-3" id="outlined-search" label="Zone" type="text" variant="outlined" /> */}
                      <div className=" text-center mt-2">
                        {/* {location.targetZoneOption.length>0 && location.targetZoneOption.map(zone=>{
                                return(
                                <div className="chck">
                                <FormCheckBox
                                    id={zone.id}
                                    type="checkbox"
                                    label={zone.name}
                                    checked ={zone.isChecked}
                                    label1={{textAlign:"justify"}}
                                    register={register}
                                    onClick={targetChange('zone',zone)}
                                />
                            </div>)
                                })
                                } */}
                        <div className="form-group buz-01">
                          <FormAutocomplete
                            id="targetZone"
                            name="targetZone"
                            label="targetZone"
                            options={location?.targetZoneOption}
                            placeholder="Select targetZone"
                            multiple
                            disableCloseOnSelect
                            limitTags={1}
                            control={control}
                            input='targetZone'
                            value={location?.targetZone}
                            handleChangeSelect={handleChangeSelect}
                            // open={true}
                            popupIcon=''
                            disablePortal={true}
                            size='small'
                            ListboxProps={{ style: { maxHeight: '16rem' } }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div>
                      {/* <TextField className="comp-name mt-3" id="outlined-search" label="Region" type="text" variant="outlined" />
                    <div style={{border:"1px solid #c4c4c4",padding:"15px",overflowY:"scroll",height:"10rem"}}> */}
                      {/* {location.targetRegionOption.length>0 && location.targetRegionOption.map(rl=>{
                                return(
                                <div className="chck">
                                <FormCheckBox
                                    id={rl.id}
                                    type="checkbox"
                                    label={rl.name}
                                    checked ={rl.isChecked}
                                    label1={{textAlign:"justify"}}
                                    register={register}
                                    onClick={targetChange('region',rl)}
                                />
                            </div>)
                                })
                                } */}
                      <div className=" text-center mt-2">
                        <div className="form-group buz-01">
                          <FormAutocomplete
                            id="targetRegion"
                            name="targetRegion"
                            label="targetRegion"
                            options={location?.targetRegionOption}
                            placeholder="Select targetRegion"
                            multiple
                            disableCloseOnSelect
                            limitTags={1}
                            control={control}
                            input='targetRegion'
                            value={location?.targetRegion}
                            handleChangeSelect={handleChangeSelect}
                            // open={true}
                            popupIcon=''
                            disablePortal={true}
                            size='small'
                            ListboxProps={{ style: { maxHeight: '16rem' } }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div>
                      {/* <TextField className="comp-name mt-3" id="outlined-search" label="Country" type="text" variant="outlined" />
                    <div style={{border:"1px solid #c4c4c4",padding:"15px",overflowY:"scroll",height:"10rem"}}> */}

                      {/* {location.targetCountryOption.length>0 && location.targetCountryOption.map(c=>{
                                return(
                                <div className="chck">
                                <FormCheckBox
                                    id={c.id}
                                    type="checkbox"
                                    label={c.name}
                                    checked ={c.isChecked}
                                    label1={{textAlign:"justify"}}
                                    register={register}
                                    onClick={targetChange('country',c)}
                                />
                            </div>)
                                })
                                } */}
                      <div className=" text-center mt-2">
                        <div className="form-group buz-01">
                          <FormAutocomplete
                            id="targetCountry"
                            name="targetCountry"
                            label="targetCountry"
                            options={location?.targetCountryOption}
                            placeholder="Select targetCountry"
                            multiple
                            disableCloseOnSelect
                            limitTags={1}
                            control={control}
                            input='targetCountry'
                            value={location?.targetCountry}
                            handleChangeSelect={handleChangeSelect}
                            // open={true}
                            popupIcon=''
                            disablePortal={true}
                            size='small'
                            ListboxProps={{ style: { maxHeight: '16rem' } }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>}
      </Drawer>
    </div>
  );
};

function mapState(state) {
  const general_data = state.distributionChannel;
  return general_data;
}

const actionCreators = {
  distributionChannelRedux: channelAction.distributionChannelRedux
};

export default connect(mapState, actionCreators)(DistributionChannel);