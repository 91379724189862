import React from 'react';
import YoutubeEmbed from './YoutubeEmbed';


const PicturesVideos = () => {
    return (
        <div className='picvideo-wrap'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='pic-wrap'>
                        <h4>Pictures</h4>
                    <a href='https://www.flickr.com/photos/67672940@N05/albums/with/72177720308306710' target='_blank'>
                        <div className='pic-img' style={{marginTop:'20px'}}>
                            <img src={"/images/pictureback.jpg"} />
                        </div>
                       </a>

                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='pic-wrap'>
                        <h4>Video</h4>
                        <YoutubeEmbed  embedId="TbEFm5MikAI"  />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PicturesVideos;