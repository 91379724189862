import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, CardHeader, Row, Col, CardImg, Container, Button } from "reactstrap";
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { Badge } from "reactstrap";
import { Categories, Profile, ExpandString } from "views/Hoc/text-tags/TextTags";
import { KeyBrands, ChannelProducts, BrandListing } from "views/Hoc/keyBrands/KeyBrands";
import { OfficialDocument } from "views/Hoc/officialDocuments/officialDocuments";
import CountryFlag from 'views/Hoc/countryFlag/countryFlag';
import Awardlist from 'views/Hoc/Dialog/Awardlist';
import B2blink from 'views/Hoc/Dialog/B2blink';
import Moment from 'moment';
import GuestNavBar from 'views/pages/GuestPage/GuestNavbar/GuestNavbar';
import { history } from '../../../_helpers/history';
import ProductSlider from "../GuestPage/GuestNew/productSlider";
import GuestReview from "../GuestPage/GuestNew/GuestReview";
import GuestViewContact from "../../Hoc/Dialog/GuestViewContact";
import MatchingVendor from "views/pages/GuestPage/GuestNew/GuestMatchingVendors";
import MatchingBrands from "views/pages/GuestPage/GuestNew/MatchingBrands";
import GuestContact from '../GuestPage/GuestContact';
import Footer from '../GuestPage/GuestNavbar/Footer';
import GuestFooter from "../GuestPage/GuestNavbar/GuestFooter";
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import { ContactListing } from "views/Hoc/contact-list/public-contactList";
import bannerPic from '../../../assets/img/channelDetail/Default Banner.png';

const PublicChannelDetailView = (props) => {
  const [imageSrc, setImageSrc] = useState('');

  let [ip, setIP] = useState(null);

  let [loading, setLoading] = useState(true);
  let [isEvent, setEvent] = useState(false);
  let [channelDetails, setChannelDetails] = useState(null);
  let [productList, setProductList] = useState([]);
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  let [awardPopupOpen, setAwardPopupOpen] = useState(false);
  let [b2bPopupOpen, setb2bPopupOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [token, setToken] = useState(new URLSearchParams(useLocation().search).get("token"));
  let [brandList, setBrandList] = useState([]);
  let [similarProductList, setSimilarProductList] = useState([]);
  let [businessType, setBusinessType] = useState(null);
  let [channelBasicInfo, setChannelBasicInfo] = useState({
    channelB2B: [],
    channelLogo: null,
    address: null,
    city: null,
    companyName: null,
    aboutUs: null,
    country: null,
    isoCode: null,
    phoneno: null,
    webSiteUrl: null,
    introvideo: null,
    socialLinks: [],
    postalCode: null,

    linkedIn_pre: 'https://www.linkedin.com/company/',
    insta_pre: 'https://www.instagram.com/',
    facebook_pre: 'https://www.facebook.com/',
    twitter_pre: 'https://twitter.com/',
  })
  let [tradeInformation, setTradeInformation] = useState({
    productCategories: [],
    speciality: [],
    ChannelProfileValue: [],
    RetailerProfileValue: [],
    ResellerProfileValue: [],
    DistributorProfileValue: [],
    SellingLocation: [],
    profileType: null
  });
  let [factInfo, setFactInfo] = useState({
    empCnt: null,
    empCntRange: null,
    estYear: null,
    no_of_skus: null,
    startup: null,
    storeCount: null,
    turnover: null,
    turnoverRange: null,
  });
  let [contactList, setContactList] = useState([]);
  useEffect(() => {
    callAuths();
  }, [props.location.pathname]);

  const getIp = async () => {
   // const res = await axios.get('https://geolocation-db.com/json/')
    
    const API_URL = `https://api.ipify.org?format=json`;
    const res = await axios.get(API_URL);
    //console.log("response : ",res);
    ip = res.data.ip.toString();
    //console.log("ip address  : ",ip);
    setIP(ip);
  }

  const callAuths = async () => {
     await getIp();
    setLoading(true);
    //console.log('token', token);
    //console.log('props', props);
    if (token) {
      isEvent = true;
      setEvent(isEvent);
      commonService.getServices('account/checkAuth/' + token).then(res => {
        if (res) {
          if (res.data.id == props.match.params.id) {

            getChannelDetails();
          } else {
            setLoading(false);
            ToastsStore.warning('Unauthorized request');
          }
        }
      })
        .catch(err => {
          setLoading(false);
          ToastsStore.warning('Unauthorized request');
          console.log('err', err)
        });
    } else if (props.match.path === '/channelDetailView/:id') {
      //await getIp();
      getChannelDetails();
    } else {
      setLoading(false);
      ToastsStore.warning('Unauthorized request');
    }
  }
  const getChannelBrand = async (id) => {
    let res = await commonService.postService('', 'publicChannel/channelBrands/' + id).catch(err => console.log('err', err));
    if (res) {
      brandList = res.data;
      setBrandList(brandList);
    } else {
      brandList = [];
      setBrandList(brandList);
    }
  }
  const getChannelDetails = async () => {
    let res = await commonService.getServices('publicChannel/channelDetailView/' + props.match.params.id + '/' + ip).catch(err => console.log('err', err));
    if (res) {
      channelDetails = res.data;
      setChannelDetails(channelDetails);
      setBusinessType(channelDetails.isOEM)
      if (channelDetails.channelTypeId.toString() !== '4')
        getChannelBrand(props.match.params.id);
      if (token)
        getChannelContact(props.match.params.id);
      channelBasicInfo.channelB2B = channelDetails?.channelB2B ? channelDetails?.channelB2B : [];
      channelBasicInfo.channelLogo = channelDetails?.channelLogo;
      channelBasicInfo.address = channelDetails?.regAddress?.address
      channelBasicInfo.city = channelDetails?.regAddress?.city;
      channelBasicInfo.zip = channelDetails?.regAddress?.postalCode;
      channelBasicInfo.companyName = channelDetails?.companyName;
      channelBasicInfo.aboutUs = channelDetails?.aboutUs;
      channelBasicInfo.country = channelDetails?.country;
      channelBasicInfo.isoCode = channelDetails?.isoCode;
      //text formatting for phone number
      channelBasicInfo.phoneno = channelDetails?.phoneno ? channelDetails?.phoneno.split('-').length > 1 ?
        channelDetails.phoneno : (channelDetails.phoneCode.substr(1,) == channelDetails.phoneno.substr(0, channelDetails.phoneCode.substr(1,).length)) ?
          channelDetails.phoneCode + '-' + channelDetails.phoneno.slice(channelDetails.phoneCode.substr(1,).length,) : channelDetails.phoneCode + '-' + channelDetails.phoneno : '';
      channelBasicInfo.webSiteUrl = channelDetails?.webSiteUrl;
      channelBasicInfo.introvideo = channelDetails?.introvideo;
      channelBasicInfo.socialLinks = channelDetails?.socialLinks;
      setChannelBasicInfo({ ...channelBasicInfo });
      // setImageSrc(channelDetails?.channelBanner);
      setImageSrc(channelDetails?.channelBanner ? channelDetails?.channelBanner : channelDetails.linkedInBanner ? process.env.REACT_APP_CDN_URL + channelDetails.linkedInBanner : null);

      tradeInformation.productCategories = channelDetails?.tradeInformation?.userProductCategories;
      tradeInformation.speciality = channelDetails.channelTypeId.toString() === '3' ? channelDetails?.tradeInformation?.speciality : [];


      tradeInformation.ChannelProfileValue = channelDetails?.tradeInformation?.customerProfile;
      tradeInformation.RetailerProfileValue = channelDetails?.tradeInformation?.retailerProfile;
      tradeInformation.ResellerProfileValue = channelDetails?.tradeInformation?.resellerProfile;

      tradeInformation.SellingLocation = channelDetails.channelTypeId==5?channelDetails?.locations?.RESELLING:channelDetails.channelTypeId==4?channelDetails?.locations?.RETAIL:channelDetails?.locations?.SELLING;

      tradeInformation.profileType = channelDetails.profileType;
      setTradeInformation({ ...tradeInformation });

      factInfo.empCnt = channelDetails?.empCnt;
      factInfo.empCntRange = channelDetails?.empCntRange;
      factInfo.estYear = channelDetails?.estYear;
      factInfo.no_of_skus = channelDetails?.no_of_skus;
      factInfo.startup = channelDetails?.startup;
      factInfo.storeCount = channelDetails?.storeCount;
      factInfo.turnover = channelDetails.turnover;
      factInfo.turnoverRange = channelDetails?.turnoverRange ? channelDetails?.turnoverRange.replace('M€', '') : '';

      setFactInfo({ ...factInfo });
      if (channelDetails.channelTypeId.toString() === '2') {
        getChannelProduct();
        // if(isEvent)
        setSimilarProduct();
        setLoading(false);
      }
      setLoading(false);
    }
  }
  const getChannelContact = async (id) => {
    setLoading(true);
    contactList = [];
    setContactList(contactList);
    let res = await commonService.postService('', 'publicChannel/eventChannelContacts/' + id).catch(err => console.log('err', err));
    if (res) {
      contactList = res.data;
      setContactList(contactList);
    }
    setLoading(false);
  }
  const getChannelProduct = async () => {
    let res = await commonService.postService({ isPublic: !isEvent }, 'public/channel-products/' + props.match.params.id).catch(err => console.log('err', err));
    if (res) {
      productList = res.data;
      setProductList(productList);
    } else {
      productList = [];
      setProductList(productList);
    }
  }
  const setSimilarProduct = async () => {
    let res = await commonService.getServiceWithParams({ isPublic: !isEvent }, 'public/channel-similarproducts/' + props.match.params.id).catch(err => console.log('err', err));
    if (res) {
      similarProductList = res.data;
      setSimilarProductList(similarProductList);
    } else {
      similarProductList = [];
      setSimilarProductList(similarProductList);
    }
  }
  const closeAwardPopUp = () => {
    setAwardPopupOpen(false);
    setb2bPopupOpen(false);
  }
  const openLogin = () => {
    localStorage.removeItem('guest-product-fav', props.match.params.id);
    localStorage.removeItem('guest-channel-fav', props.match.params.id);
    localStorage.setItem('guest-channel-fav', props.match.params.id);
    setLogin(true);
  }
  const handleClose = () => {
    setLogin(false);
  }

  const [hasMatchingVendors, setMatchingVendors] = useState(true);

  const handleMatching = (data) => {

    let hasData = data.map(x => {
      if (x.value)
        return true;
    })
    if (data.length && hasData) {
      setMatchingVendors(true);
    }
    else
      setMatchingVendors(false);
  }

  const [hasMatchingBrands, setMatchingBrands] = useState(true);

  const handleBrands = (data) => {

    let hasData = data.map(x => {
      if (x.value)
        return true;
    })
    if (data.length && hasData) {
      setMatchingBrands(true);
    }
    else
      setMatchingBrands(false);
  }

  const [play, setPlay] = useState(false);

  const [mute, setMute] = useState(true);

  const unmute = () => {
    //console.log('muted', mute)
    setMute(prev => !prev);
  }

  const playVideo = () => {
    var myVideo = document.getElementById('myIntroVideo');

    if (myVideo) {
      if (play)
        myVideo.play();
      else
        myVideo.pause();
    }

  }

  useEffect(() => {
    playVideo();
  }, [play])

  return (
    <div id='channel-detail' className="main-content">
      <GuestNavBar />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Container className="mt-4" fluid>
        {loading ? <Spinner /> :
          <>{channelDetails && <>
            <B2blink open={b2bPopupOpen} handleClose={closeAwardPopUp} value={channelBasicInfo?.channelB2B} />
            <Awardlist open={awardPopupOpen} handleClose={closeAwardPopUp} value={channelDetails?.awards} />
            <Row>
              <Col className="card-wrapper" lg="12" md="12" sm="12">
                <Card>
                  <CardImg
                    alt="..."
                    style={{ height: "350px", objectFit: "cover" }}
                    src={imageSrc ? imageSrc : bannerPic}
                    top
                  />
                  {(channelDetails.channelTypeId != 2 || channelDetails?.subscriptionPlan) && <div className="plan-badge">
                    <Row>
                      <Col lg="9" className="pad-less c-1 col-9">
                        <div className="plan-txt">
                          <h3>{parseInt(channelDetails.channelTypeId) !== 2 ? 'Member since ' + Moment(channelDetails?.createdAt).format('yyyy') : channelDetails?.subscriptionPlan}</h3>
                        </div>
                      </Col>
                      <Col lg="3" className="pad-less c-1 col">
                        <div className="plan-txt1"><i class="fas fa-bookmark"><i class="fas fa-star str-i"></i></i></div>
                      </Col>
                    </Row>
                  </div>}
                  <div className='cnect-brnd'>
                    <Button className='butn-f pt-2' onClick={openLogin}><i class="fas fa-user-plus mr-2"></i>Follow</Button>
                  </div>
                </Card>
              </Col>
            </Row>
            <div style={{ marginTop: '-2.5rem' }}>
              <Card className='rounded-0'>
                <CardBody className='pb-6'>
                  <Row className="align-items-center">
                    <Col className="col-lg-auto col-md-auto col-sm-12 complog">
                      <a
                        style={{ width: "150px", height: "150px", padding: "5px", border: "1px solid #b8b8b8", backgroundColor: "white" }}
                        className="avatar avatar-xl mb-2"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                          alt="..."
                          src={channelBasicInfo?.channelLogo ? channelBasicInfo?.channelLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}
                        />
                      </a>
                    </Col>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                      <h2 className='text-uppercase mobile-head mt-6'>{channelBasicInfo?.companyName}</h2>


                      <p className='text-uppercase mb-1'>since {channelDetails?.estYear} </p>
                      <div className='badges mb-3'>
                        <Badge>
                          {channelDetails?.channelType}
                        </Badge>
                        {/* {parseInt(factInfo?.startup)===1 && <> 
                            <Badge className='outer_badge1'>
                          {channelDetails?.channelTypeId ==='2'?'Startup':'Looking Startup'}
                          </Badge></>} */}
                      </div>

                      <div className='mb-3'>
                        <Badge className='outer_badge'>
                          {channelBasicInfo?.country}
                        </Badge>
                      </div>
                      <div className="mb-3">
                        {isEvent && <> {channelBasicInfo?.socialLinks?.map(x => {
                          return (<>

                            {x.social === 'Googleplus' && <a onClick={() => {
                              let url = x.link;
                              if (!url.match(/^https?:\/\//i) || !url.match(/^http?:\/\//i)) {
                                url = '//' + url;
                              }
                              window.open(url, '_blank')
                            }}>
                              <Button className="btn-icon-only mr-2" color="twitter" type="button">
                                <span className="btn-inner--icon">
                                  <i className="fab fa-twitter" />
                                </span>
                              </Button>
                            </a>}
                            {(x.social === 'Linkedin' && x?.link) && <a onClick={isEvent ? () => {
                              let url = channelBasicInfo.linkedIn_pre + x.link.replace(/\/$/, "").split('/').splice(-1, 1);
                              if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                                url = '//' + url;
                              }
                              window.open(url, '_blank')
                            } : openLogin}>
                              <Button className="btn-google-plus btn-icon-only mr-2" color="google" type="button">
                                <span className="btn-inner--icon">
                                  <i className="fab fa-linkedin" />
                                </span>
                              </Button>
                            </a>}
                            {(x.social === 'Instagram' && x?.link) && <a onClick={isEvent ? () => {
                              let url = channelBasicInfo.insta_pre + x.link.replace(/\/$/, "").split('/').splice(-1, 1);
                              if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                                url = '//' + url;
                              }
                              window.open(url, '_blank')
                            } : openLogin}>
                              <Button className="btn-icon-only mr-2" color="twitter" type="button">
                                <span className="btn-inner--icon">
                                  <i className="fab fa-instagram"></i>
                                </span>
                              </Button>
                            </a>}
                          </>
                          )
                        })}



                          {channelDetails?.awards?.length > 0 && <Button onClick={isEvent ? () => setAwardPopupOpen(true) : openLogin} className="btn-icon-only mr-2" color="warning" type="button">
                            <span className="btn-inner--icon">
                              <i class="fas fa-trophy"></i>
                            </span>
                          </Button>}

                          {/* <Badge style={{ color: 'white', backgroundColor: '#adb5bd' }} className='text-capitalize'>
                            Share
                          </Badge>  */}
                          </>}
                      </div>
                    </div>

                    <div className={channelBasicInfo?.introvideo ? 'col-xl-5 col-lg-7 col-md-8 col-sm-12 ml--3 p-0' : 'col-lg-8 col-md-7 col-sm-12 ml--3 p-0'}>
<div className='cust-pad'>
                      <p className='mb-1 font-weight-700'>About us </p>
                      <Row><p> {channelBasicInfo?.aboutUs?.replace(/&amp;/g, '&').replace(/<[^>]+>/g, '')} </p></Row>
                      <Row><Col lg="7" className="pad-less">
                        <h4 className="mb-1" style={{ color: '#b8b8b8' }}>Address</h4>
                        <Row><p className="mb-0"> {channelBasicInfo?.address} , </p></Row>
                        <Row><p className="mb-0"> {channelBasicInfo?.zip} , {channelBasicInfo?.city} , </p></Row>
                        <Row><p className="mb-0">{channelBasicInfo?.country}</p></Row>
                      </Col>
                        <Col lg="5">
                          <h4 style={{ color: '#b8b8b8' }}>Phone Number</h4>
                          <Row><p className='mt1-5'><CountryFlag code={channelBasicInfo.isoCode} /></p> <p className='ml-2 res-ph'> {channelBasicInfo?.phoneno} </p></Row>
                          <Row><p className='mt1-5'>
                            {/* <a  onClick={openLogin}>View Contact</a> */}
                          </p></Row></Col>
                      </Row>
                      <Row> <h4 className="mt-3 mb-1" style={{ color: '#b8b8b8' }}>Web Site</h4></Row>
                      {/* <Row><p>{channelBasicInfo?.webSiteUrl}</p></Row> */}
                      <Row>
                        {isEvent && <>
                          {!!channelBasicInfo?.channelB2B?.length && channelBasicInfo?.channelB2B.slice(0, 2).map(x =>
                            <p className='mb-1'><a href=''
                              onClick={(e) => {
                                e.preventDefault();
                                let url = x?.channelB2B;
                                if (!url.match(/^https?:\/\//i)) {
                                  if (!url.match(/^http?:\/\//i))
                                    url = '//' + url;
                                }
                                window.open(url, '_blank')
                              }} className="">{x?.channelB2B?.replace(/^\W+/, '')} </a></p>

                          )}

                          {channelBasicInfo?.channelB2B?.length > 2 && <h4 onClick={() => setb2bPopupOpen(true)} className=" mr-2" color="primary">
                            <span>
                              {/*<span className="btn-inner--icon"> <i class="fas fa-external-link-alt"></i> */}
                              View More
                            </span>
                          </h4>}
                        </>}
                        <p className='mb-1'>
                          <a href=''
                            onClick={(e) => {
                              e.preventDefault();
                              let url = channelBasicInfo?.webSiteUrl;
                              if (!url.match(/^https?:\/\//i)) {
                                if (!url.match(/^http?:\/\//i))
                                  url = '//' + url;
                              }
                              window.open(url, '_blank')
                            }} className="">{channelDetails?.webSiteUrl?.replace(/^\W+/, '')} </a>
                          {/* <a onClick={openLogin}>View Website</a> */}
                        </p>
                      </Row>
                    
                    </div>
                    </div>
                    {/* {(channelBasicInfo?.introvideo && isEvent) && <> */}
                    {/* <div style={{ flex: '0 0 2.66667%' }} className="col-lg-3 col-md-2 col-sm-12 text-center mr--4">
                    <h3>Watch Our Introduction Video</h3>
                  </div> */}
                    {/* <div className="col-lg-4 col-md-auto col-sm-12">  */}
                    {/* onClick={()=>previewVideo(channelBasicInfo?.introvideo)} */}
                    {/* {!play && <i style={{ fontSize: '3rem', position: 'absolute', top: '30%', left: '42%', color: '#FF6347' }} class="fa fa-play-circle" aria-hidden="true" onClick={() => { setPlay(true); }} ></i>}

                      <video id="myIntroVideo" src={channelBasicInfo?.introvideo}
                        autoPlay={play} muted={mute} onClick={() => setPlay(prev => !prev)}
                      >
                      </video>
                      <button id="unmuteBtn" onClick={unmute}>
                        <i class={mute ? 'fas fa-volume-mute mte' : 'fas fa-volume-up unmte'}></i>
                      </button>
                    </div></>} */}

                  </Row>
                </CardBody>
              </Card>
            </div>


            {channelBasicInfo?.introvideo && <Card>
              <CardBody>
                <div className="d-flex justify-content-center mt-2">
                  <div className='detailvideo-wrap'>
                    {/* {!play && <i style={{ fontSize: '3rem', position: 'absolute', top: '35%', left: '48%', color: '#FF6347' }} class="fa fa-play-circle" aria-hidden="true" onClick={() => { setPlay(true); }} ></i>} */}
                    <video id="myIntroVideo" controls src={channelBasicInfo.introvideo} autoPlay={play} muted={mute} onClick={() => setPlay(prev => !prev)} style={{ width: '100%', height: '100%', borderRadius: '20px' }}></video>

                    {/* <button id="unmuteBtn" onClick={unmute}>
                      <i class={mute ? 'fas fa-volume-mute mte' : 'fas fa-volume-up unmte'}></i>
                    </button> */}
                  </div>
                </div>

              </CardBody>
            </Card>}


            {isEvent && <Card>
              <CardHeader className="p-3">
                <h3 className="ml-2 mb-4">Facts & Figures</h3>
                <Row>
                  <Col>
                    <Row>
                      <Col lg='3' className='pad-less'>
                        <div className='fact-icon'>
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/establish-icon-png.png")} />
                        </div>
                      </Col>
                      <Col lg='9' className='pr-0'>
                        <h1 className="mb-0 fa-s">{2021 - parseInt(factInfo?.estYear)}</h1>
                        <h5 className="m-0 text-uppercase text-muted">Years In Business</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col lg='3' className='pad-less'>
                        <div className='fact-icon'>
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/turnover.png")} />
                        </div>
                      </Col>
                      <Col lg='9' className='pr-0'>
                        <h1 className="mb-0 fa-s">{factInfo?.turnoverRange ? factInfo?.turnoverRange + ' M€' : factInfo?.turnover + ' M€'}</h1>
                        <h5 className="m-0 text-uppercase text-muted">Annual Turnover</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col lg='3' className='pad-less'>
                        <div className='fact-icon'>
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/employess-icon.png")} />
                        </div>
                      </Col>
                      <Col lg='9' className='pr-0'>
                        <h1 className="mb-0 fa-s">{factInfo?.empCntRange ? factInfo?.empCntRange : factInfo?.empCnt}</h1>
                        <h5 className="m-0 text-uppercase text-muted">No. Of Employees</h5>
                      </Col>
                    </Row>
                  </Col>

                  {parseInt(channelDetails?.channelTypeId) === 2 && <Col>
                    <Row>
                      <Col lg='3' className='pad-less'>
                        <div className='fact-icon'>
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/SKU.png")} />
                        </div>
                      </Col>
                      <Col lg='9' className='pr-0'>
                        <h1 className="mb-0 fa-s">{factInfo?.no_of_skus}</h1>
                        <h5 className="m-0 text-uppercase text-muted">{channelDetails?.channelTypeId === '2' ? "Total No of Sku" : "No of Sku"}</h5>
                      </Col>
                    </Row>
                  </Col>}
                  {parseInt(channelDetails?.channelTypeId) === 2 && <Col>
                    <Row>
                      <Col lg='3' className='pad-less'>
                        <div className='fact-icon'>
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/distributor1.png")} />
                        </div>
                      </Col>
                      <Col lg='9' className='pr-0'>
                        <h1 className="mb-0 fa-s">{channelDetails?.no_of_distributors}</h1>
                        <h5 className="m-0 text-uppercase text-muted">No of Distribution Partners</h5>
                      </Col>
                    </Row>
                  </Col>}

                  {channelDetails?.channelTypeId === '4' && <Col>
                    <Row>
                      <Col lg='3' className='pad-less'>
                        <div className='fact-icon'>
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/store.png")} />
                        </div>
                      </Col>
                      <Col lg='9' className='pr-0'>
                        <h1 className="mb-0 fa-s">{factInfo?.storeCount}</h1>
                        <h5 className="m-0 text-uppercase text-muted">No of Stores</h5>
                      </Col>
                    </Row>
                  </Col>}
                </Row>
              </CardHeader>
            </Card>}
            <Row>
              {parseInt(channelDetails.channelTypeId) !== 2 && <Col lg="6">
                <Card>
                  <CardBody style={{ minHeight: '11rem' }}>
                    {tradeInformation?.profileType && <Col lg="6" style={{ padding : 0 }}>
                      <Profile id='1' data={[tradeInformation?.profileType]} title={parseInt(channelDetails.channelTypeId) === 3 ? 'Distribution Profile' : parseInt(channelDetails.channelTypeId) === 4? 'Retailer Profile':'Reseller Profile'} />
                    </Col>}
                  </CardBody>
                </Card>
              </Col>}

              {(tradeInformation?.productCategories && tradeInformation?.productCategories.length > 0) && <Col lg="6" className="pl-0">
                <Categories id='4' data={tradeInformation?.productCategories} title='Product Categories' /> </Col>}

              {((channelDetails.channelTypeId == 2 && channelDetails?.companyDesc && channelDetails?.companyDesc != null && isEvent) || (channelDetails.channelTypeId != 2 && ((channelDetails?.expectations && channelDetails?.expectations != null) || (channelDetails?.companyDesc && channelDetails?.companyDesc != null)))) && <Col lg={parseInt(channelDetails.channelTypeId) === 2 ? '6' : '12'} className="pr-0"><Card>
                <CardBody className='minheit'><h3>Company background</h3>
                  {
                    <ExpandString data={parseInt(channelDetails.channelTypeId) === 2 ? channelDetails?.companyDesc : (parseInt(channelDetails.channelTypeId) !== 2 && channelDetails?.companyDesc) ? channelDetails?.companyDesc : channelDetails?.expectations} title="Company background" id={1} />
                  }
                </CardBody>
              </Card></Col>}
              {(channelDetails?.tradeInformation?.interestedCategories && channelDetails?.tradeInformation?.interestedCategories.length > 0 && isEvent) && <Col lg="6">
                <Categories id='5' data={channelDetails?.tradeInformation?.interestedCategories} title='Interested Categories' /> </Col>}
            </Row>

            <Card>
              {(brandList?.channelBrand?.length > 0 && parseInt(channelDetails.channelTypeId) !== 4 && isEvent) && <CardHeader className="p-3">
                <h2 className="text-center m-0 text-uppercase">{parseInt(channelDetails.channelTypeId) === 2 ? "Our brands" : "Distribution brands"}</h2>
              </CardHeader>}
            </Card>
            {(brandList?.channelBrand?.length > 0 && parseInt(channelDetails.channelTypeId) !== 4 && isEvent) && <>
              <BrandListing sizeSM={true} data={brandList?.channelBrand} title={parseInt(channelDetails.channelTypeId) === 2 ? "Our brands" : "Distribution brands"} /></>}


            {productList.length > 0 && <Card>
              {isEvent ? <ChannelProducts data={productList} title="Key Products" channelId={props.match.params.id} from={'public'} />
                : <>
                  <CardHeader className='p-3'>
                    <h2 className='text-uppercase text-center'>View More related Products</h2>
                  </CardHeader>
                  <ProductSlider products={productList} />
                </>}
            </Card>}
            {(similarProductList?.length > 0 && isEvent) && <ChannelProducts data={similarProductList} title="Similar Products" channelId={props.match.params.id} from={'public'} />}


            {/* <Card>
              <CardHeader className='p-3'>
                <h2 className='text-uppercase text-center'>Similar Vendors</h2>
              </CardHeader>
              {hasMatchingVendors && <MatchingVendor title={"Similar Vendors"} channelId={props.match.params.id} handleMatching={handleMatching} />}
            </Card> */}

            {/* {hasMatchingVendors && <MatchingVendor title={"Similar Vendors"} channelId={props.match.params.id} handleMatching={handleMatching}/>} */}
            {contactList?.records?.length > 0 && <div id="connectWith">
              <ContactListing data={contactList?.records} title={"List of contacts"} />
            </div>}


            {(parseInt(channelDetails.channelTypeId) !== 4 && isEvent) && <Card>
              {(tradeInformation?.ChannelProfileValue?.length > 0 || tradeInformation?.RetailerProfileValue?.length > 0 || tradeInformation?.ResellerProfileValue?.length > 0 || channelDetails?.channelKeyDistributor?.length > 0 || (channelDetails?.channelKeyRetailer?.length > 0 && (businessType == 1 || businessType == 3)) || (channelDetails?.channelKeyReseller?.length > 0 && (businessType == 2 || businessType == 3))) && <CardHeader className="p-3">
                <h2 className="text-center m-0 text-uppercase">Channel Description</h2>
              </CardHeader>}
            </Card>}

            {(channelDetails?.channelKeyDistributor?.length > 0 && isEvent) && <Card><KeyBrands sizeSm={true} type={2} data={channelDetails?.channelKeyDistributor} no={channelDetails?.no_of_distributors} desc={channelDetails?.keydistributors} title="List of Key Distributors" count_title="Distributors" /></Card>}

            {((parseInt(channelDetails.channelTypeId) === 2 || parseInt(channelDetails.channelTypeId) === 3) && (businessType == 1 || businessType == 3) && isEvent) && <Card>
              {tradeInformation?.RetailerProfileValue?.length > 0 && <Categories id='6' data={tradeInformation?.RetailerProfileValue} title={channelDetails.channelTypeId == 2 ? "Relevant Retailer Channels" : "Retail Customers Profile"} />}
              {channelDetails?.channelKeyRetailer?.length > 0 && <KeyBrands sizeSm={true} type={2} data={channelDetails?.channelKeyRetailer} no={channelDetails?.no_of_retailers} desc={channelDetails?.keyretailers} title="List of Key Retailers" count_title="Retailers" />}
            </Card>
            }

            {((parseInt(channelDetails.channelTypeId) === 2 || parseInt(channelDetails.channelTypeId) === 3) && (businessType == 2 || businessType == 3) && isEvent) && <Card>
              {tradeInformation?.ResellerProfileValue?.length > 0 && <Categories id='6' data={tradeInformation?.ResellerProfileValue} title={channelDetails.channelTypeId == 2 ? "Relevant Reseller Channels" : "Resellers Customers Profile"} />}
              {channelDetails?.channelKeyReseller?.length > 0 && <KeyBrands sizeSm={true} type={2} data={channelDetails?.channelKeyReseller} no={channelDetails?.no_of_resellers} desc={channelDetails?.keyresellers} title="List of Key Resellers" count_title="Resellers" />}
            </Card>
            }

            {(tradeInformation?.ChannelProfileValue?.length > 0 && parseInt(channelDetails.channelTypeId) !== 4 && parseInt(channelDetails.channelTypeId) !== 2 && isEvent) &&
              <>
                <Categories id='6' data={tradeInformation?.ChannelProfileValue} title="Channel Profiles" />
                {channelDetails?.channelKeyRetailer?.length > 0 && <KeyBrands sizeSm={true} type={2} data={channelDetails?.channelKeyRetailer} no={channelDetails?.no_of_retailers} desc={channelDetails?.keyretailers} title="List of Key Retailers" count_title="Retailers" />}
              </>
            }

             {/* Countries of operation */}
          {((parseInt(channelDetails.channelTypeId) === 3 || parseInt(channelDetails.channelTypeId) === 4 || parseInt(channelDetails.channelTypeId) === 5) && tradeInformation?.SellingLocation?.length > 0) && <><Card>
            <CardHeader className="p-3">
              <h2 className="text-center m-0 text-uppercase">Countries Of Operation</h2>
            </CardHeader>
          </Card>
            <Card>
              {tradeInformation?.SellingLocation?.length > 0 && <Categories id='7' data={tradeInformation?.SellingLocation} />}
            </Card></>
          }


            {(parseInt(channelDetails.channelTypeId) !== 4 && isEvent) && <Card>
              {channelDetails?.channelDocuments?.length > 0 && <CardHeader className="p-3">
                <h2 className="text-center m-0 text-uppercase">Resources</h2>
              </CardHeader>}
            </Card>}
            <Card>
              {(channelDetails?.channelDocuments?.length > 0 && isEvent) && <OfficialDocument type='1' data={channelDetails?.channelDocuments} />}
            </Card>
          </>}
          </>
        }
        {!isEvent && <>
          {/* {hasMatchingBrands && <MatchingBrands title={"Similar Brands"} channelId={props.match.params.id} handleMatching={handleBrands} />} */}
          {/* <GuestReview type={1} channelId={props.match.params.id}/> */}
          <div className="mt--4">
            <GuestContact />
          </div>
          <div className="guestDividcontainer">
            <div className="GuestDivider">

            </div>
          </div>
          <GuestFooter />
        </>}
        <GuestViewContact type={channelDetails?.channelTypeId === '2' ? 'buyer' : 'vendor'} open={login} handleClose={handleClose} />
      </Container>
    </div>

  );
}
export default PublicChannelDetailView;