import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Container } from "reactstrap";
import emealogo from 'assets/img/guestpage/LOGO_CHANNEL_SUMMIT_EMEA.png'
import ifalogo from 'assets/img/guestpage/Logo_Global_Retail_IFA.png'
import apaclogo from 'assets/img/guestpage/Logo_ChannelConnect APAC.png'

import EventTabs1 from './EventTabs1'
// import RegisterEve from './RegisterEve';
import Vendorbuyerlist from './Vendorbuyerlist';
import { ManageUser } from "views/pages/Events/ManageUser";
import SimpleHeader from "components/Headers/SimpleHeader";
import Eventtravel from './Eventtravel';
import { useSelector } from 'react-redux';
import EventAgenda from './EventAgenda';
import { MeetingScheduler } from './MeetingScheduler';
import { BuyerSessionDialog } from './BuyerSessionDialog';
import { CodeOutlined, ContactlessOutlined } from '@material-ui/icons';
// import EventMeetings from './EventMeetings';
import MySchedule from './MySchedule';
// import EventAwards from './EventAwards';
import { Fallback } from '_components/ErrorElements/FallBack';
import axios from 'axios';
import { Spinner } from "views/Hoc/Spinner";
import { useDispatch } from "react-redux";
import { PlanFeatureDialog } from "views/Hoc/Dialog/PlanFeatureDialog";
import AgendaPdf from './AgendaPdf';


const BuyerSessionManagement = (props) => {

    const dispatch = useDispatch();

    console.log("buyersess:",props)

    const [message, setMessage] = useState(false);
    const [planUpgrade, setplanUpgrade] = useState(false);
    let [eventDetails, setEventDetails] = useState();
    let [loading, setLoading] = useState(true);
    const userData = JSON.parse(localStorage.getItem('CHuser'));

    // let eventDetails = useSelector(state => state.event)?.eventDetails;
    // let eventPackage = useSelector(state => state.event)?.eventPackage;
    const getEventDetails = async () => {
        await axios.get('/channelAdmin/getEventById/' + props.match['params'].id).then(res => {
            if (res.data.length) {
         

                eventDetails = { ...res.data[0], eventId: res.data[0].id };
                let pack = eventDetails.channel?.package;
                //code changed @16/8
                let packDetails = {};
                if (eventDetails.type == 1 && pack) {
                    packDetails = {
                        eventId: eventDetails.eventId,
                        packageId: pack.packageId,
                        price: pack.price,
                        additionalMembersCosting: pack.additionalMembersCosting,
                        membersAllowed: pack.no_of_access_badge,
                        packageName: pack.packageName,
                        additionalMembers: eventDetails.channel?.additionalMembers ? eventDetails.channel?.additionalMembers : 0,
                        marketingOptions: pack.marketingOptions,
                        packageOptions: pack.packageOptions
                    };
                }
                setEventDetails(eventDetails)
                dispatch({ type: 'EVENT_PACK', data: { ...packDetails } });
                dispatch({ type: 'EVENT', data: { ...eventDetails } })
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        getEventDetails();
    }, [])


    const getTabs = (id) => {

        switch (id) {
            case '1':
                return <ManageUser />;
            case '2':
                return <Eventtravel eventId={eventDetails?.eventId} />;
            case '3':
                //temp work for current event only
                return <Vendorbuyerlist id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} fromMeeting={false} />
            case '4':
                return <EventAgenda eventId={eventDetails?.eventId} contactId={userData.contactId} eventType={eventDetails?.type} hasPackage={eventDetails?.hasPackage} />
            // case '5':
            //     return <EventMeetings />
            // case '6':
            //     return <EventAwards />
            case '7':
                return <MeetingScheduler id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} eventType={eventDetails?.type} hasPackage={eventDetails?.hasPackage} />
            case '8':
                return <MySchedule id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} eventType={eventDetails?.type} hasPackage={eventDetails?.hasPackage} />//:<AgendaPdf eventId={eventDetails?.eventId} eventType={eventDetails?.type} hasPackage={eventDetails?.hasPackage} />
            default:
                return eventDetails?.eventStatus == 3 ? <Vendorbuyerlist id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} /> : <ManageUser />
        }
    }

    // const [activeTab, setActiveTab] = useState(props.match.params ? props.match.params.id : '3');
    const [activeTab, setActiveTab] = useState('4');

    const changeTab = (id) => {
        if (eventDetails.type == 2 && id == 7 && userData.channelTypeId == 2 && userData.planId == 1) {
            setplanUpgrade(true);
        }
        else if (eventDetails.type == 2 && id == 7 && userData.channelTypeId != 2 && window.location.hostname === 'channelhub.net') {
            setMessage(true);
        }
        else {
            setActiveTab(id);
            setplanUpgrade(false);
        }
    }


    const errorHandler = (error, errorInfo) => {
        console.log('Logging:', error, errorInfo);

    }

    const closeplanUpgrade = () => {
        setplanUpgrade(false);
    }

    const handleMessageClose = () => {
        setMessage(false);
      }



    return (
        <>
            {loading ? <Spinner /> : <>

                {planUpgrade && <PlanFeatureDialog closeUserView={closeplanUpgrade} UpgradeType='BuyerSession' />}

                {message && <BuyerSessionDialog handleClose={handleMessageClose} type='Scheduler' />}

                <SimpleHeader location={props.match} />
                <div id="listing" className="main-content">
                    {eventDetails?.eventId == 3 &&
                        <div className='text-center'>
                            <img src={emealogo} width={130} />
                            <h1 className='text-center mt-3 mb-3'>Channel Summit Details</h1>
                        </div>}

                    {eventDetails?.eventId == 4 &&
                        <div className='text-center'>
                            <img src={ifalogo} width={130} />
                            <h1 className='text-center mt-3 mb-3'>Global Retail Connect at IFA</h1>
                        </div>}
                    {eventDetails?.eventId == 5 &&
                        <div className='text-center'>
                            <img src={apaclogo} width={130} />
                            <h1 className='text-center mt-3 mb-3'>Channel Connect 1-to-1 EMEA-APAC</h1>
                        </div>}

                    {(eventDetails?.type == 2 && userData.channelTypeId != 2 &&  activeTab == 7) &&
                        <p className='text-center mt-3 mb-3' style={{ fontSize: '14px' }}><b>IMPORTANT:</b> In this section you can review the incoming requests sent by the vendors and curated by our team. The non-relevant request are under Cancelled / Declined list. <br/>Please review the remaining incoming and only keep the vendors that you want to meet. Once done we’ll contact the vendors and setup a Zoom or Teams call off-line in the next 2 weeks. </p>}

                    {(eventDetails?.type == 2 && userData.channelTypeId == 2 && userData.planId != 1 && activeTab == 7) &&
                        <p className='text-center mt-3 mb-3' style={{ fontSize: '14px' }}><b>IMPORTANT:</b> In this section you can send your meeting requests. Note that before your meeting requests are presented to the buyers, ChannelHub team will review their relevance.<br/> If they don’t qualify they be put under Cancelled/Declined list. <br/>If the meeting request is accepted ChannelHub team will contact you off-line to set-up a Zoom or Teams call with the buyer. </p>}
                    {/* <p className='text-center mt-3 mb-3' style={{ fontSize: '13px' }}><b>IMPORTANT:</b> The vendor list is for review only, not for sending meeting requests. The meeting scheduler will open on 29 August. </p> */}

                    {/* : <p className='text-center mt-3 mb-3' style={{ fontSize: '13px' }}><b>IMPORTANT:</b> The buyer list is for review only, not for sending meeting requests. The meeting scheduler will open on May 2nd. <br />In the meantime you can create various lists and save your selection using the "Favorite" icon</p> */}
                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                        <Container className="col-12 list-tab" fluid>


                            <EventTabs1 activeTab={activeTab} eventDetails={eventDetails} changeTab={changeTab} channelTypeId={userData.channelTypeId} planId={userData.planId} />


                            {getTabs(activeTab)}

                            {/* <RegisterEve/> */}
                            {/* <ManageUser/> */}
                            {/* <Vendorbuyerlist/> */}
                            {/* <Eventtravel/> */}
                        </Container>
                    </ErrorBoundary>
                </div></>}
        </>
    );
};

export default BuyerSessionManagement;