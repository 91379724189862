import React, { useState } from "react";
import { Card, CardBody, Container, Col, Row, Button } from "reactstrap";
import { history } from '../../../../_helpers';
import { connect, useSelector } from 'react-redux';
import { subscriptionAction } from '_actions/subscription.action';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { commonService } from '_services/common.service';

const PaymentError = (props) => {
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  //let wireTransfer = (userData.paymentMethodId == 2) ? true : false;
  // const redux_data = useSelector(state => state.subscription);
  // console.log(redux_data.pay_amt);
  if(userData.paymentMethodId != 2) {
    //  let res = commonService.postService({id: +userData.channelId}, 'mailerservice/subscriptionEmail').catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));
  }
    
  return (
    <div className="main-content" id="PaymentConfirm">
      {/* <Title desc={desc} /> */}
      <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>

        <Card className="p-3">
          <h1 className="ml-3 mt-3">Success</h1><hr className="m-0 ml-3 paycarhr" style={{ width: "14%" }} />
          <h1 className="text-center"><i style={{ fontSize: '3rem', color: '#2c9db7' }} class="fa fa-check-circle" aria-hidden="true"></i>
          </h1>
          <Row className="justify-content-center pb-5">

            <Col lg="8" >
              <CardBody>
                Oops, your last transaction has been failed, please contact channelHub.
              </CardBody>
            </Col>
          </Row>
          <div className="text-center">
            <Button color="primary" type="button" onClick={() => window.location.href = process.env.REACT_APP_LOCALSITE + "admin/dashboard"}>
              Continue
            </Button>
          </div>


        </Card>
      </Container>
    </div>


  );
};

function mapState(state) {
  console.log('state', state);
  const general_data = state.subscription;
  return general_data;
}

const actionCreators = {
  subscriptionRedux: subscriptionAction.subscriptionRedux
};

export default connect(mapState, actionCreators)(PaymentError);
