import React ,{useEffect} from 'react';

import {
  Button, Modal,
} from "reactstrap";
// import video from '../../../assets/sample.mp4'

export const ProductVideoPlayer=(props)=> {
  const { url, videoModalClose, isOpen } = props;

  return (
    <div>
      <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
         size="lg"
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={ videoModalClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center pt-0 pb-0">
          {/* <video src={url} autoPlay style={{width:'100%',height:'30rem'}}></video> */}
          <iframe className='pre-vid' src={url}
        frameborder='0'
        allow='autoplay'
        allowfullscreen
        title='video'
/>
        
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={videoModalClose}
            >
              Close
            </Button>
           
          </div>
        </Modal>
    </div>
  );
}