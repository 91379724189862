import React, { useState, useEffect } from 'react';
import { ListGroupItem, ListGroup } from 'reactstrap';
import { FormAutocomplete } from "_components/FormElements/FormInput";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from "react-hook-form";
import { commonService } from '_services/common.service';
import Popper from '@material-ui/core/Popper';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const ListingFilter = (props) => {
  const classes = useStyles();

  const { activeTab, handleChangeSelect, filterValueState, resetFilterValue, viewId } = props;
  const [filterOptionState, setFilterOption] = useState({ SpecialityOption: [], Brand_Option: [], Retailer_Profile_Option: [], Company_Origin_Option: [], channelTypeOption: [] });
  const user = JSON.parse(localStorage.getItem('CHuser'));
  const { control } = useForm({ mode: 'onBlur' });
  useEffect(() => {
    getSearchAggregation();
  }, [])

  const getSearchAggregation = async () => {
    resetFilterValue();
    resetFilterOptions();

    let data = {
      filter: {
        specialityId: null,
        brandId: null,
        countryId: null,
        locationId: null,
        retailerprofileId: null,
        productCategoryId: null,
        turnOver: null,
        connectionStatusId: null,
        typeId: null
      }
    }

    let channels = await commonService.filterPostService(data, '/channel/profileView/' + viewId);

    if (channels.contactList.length > 0) {
      let data = channels.contactList;
      // filterOptionState.SpecialityOption= options.specialIds;
      let url = 'channel/conSpecialFilter';
      filterOptionState.SpecialityOption = await commonService.filterPostService({
        data
      }, url);
      setFilterOption({ ...filterOptionState });

      filterOptionState.Brand_Option = await commonService.filterPostService({
        data
      }, '/channel/brandFilter');
      setFilterOption({ ...filterOptionState });

      let retailIds = await commonService.filterPostService({
        data
      }, '/channel/retailFilter');

      filterOptionState.Retailer_Profile_Option = retailIds.length > 0 ? retailIds.filter(x => x.name.trim() !== '' && x.name) : [];
      setFilterOption({ ...filterOptionState });

      url = 'channel/conCountryFilter';
      filterOptionState.Company_Origin_Option = await commonService.filterPostService({
        data
      }, '/channel/countryFilter');
      setFilterOption({ ...filterOptionState });
    }

    filterOptionState.channelTypeOption = user.channelTypeId == 2 ? channelTypeList.filter(x => x.id !== user.channelTypeId) : channelTypeList.filter(x => x.id == 2);//await commonService.filterGetService('/channel/channeltype');
    setFilterOption({ ...filterOptionState });
  }

  const resetFilterOptions = () => {
    filterOptionState.SpecialityOption = [];
    filterOptionState.Brand_Option = [];
    filterOptionState.Retailer_Profile_Option = [];
    filterOptionState.Company_Origin_Option = [];
    filterOptionState.channelTypeOption = user.channelTypeId == 2 ? channelTypeList.filter(x => x.id !== user.channelTypeId) : channelTypeList.filter(x => x.id == 2);
    setFilterOption({ ...filterOptionState });
  }

  const PopperMy = function (props) {
    return (<Popper {...props} style={{ width: '300px' }} placement='bottom-start' />)
  }

  const style = { height: '18rem' };

  return (
    <div className='fav-ser1 p-2'>
      <div className={classes.root}>
        {/* {activeTab !== '8' && <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Speciality</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.SpecialityOption.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Speciality"
                        name="Speciality"
                        label=""
                        options={filterOptionState.SpecialityOption}
                        placeholder="Type Speciality"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='Speciality'
                        value={filterValueState.Speciality}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>} */}
        {activeTab !== '10' && <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Brand</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Brand_Option.length > 0 ? style : {}} >
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Brand"
                        name="Brand"
                        label=""
                        options={filterOptionState.Brand_Option}
                        placeholder="Type Brand"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='Brand'
                        value={filterValueState.Brand}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>}
        {activeTab !== '10' && <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Retailer Customer Profile</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Retailer_Profile_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Retailer_Profile"
                        name="Retailer_Profile"
                        label=""
                        options={filterOptionState.Retailer_Profile_Option}
                        placeholder="Type Retailer Customer Profile"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='Retailer_Profile'
                        value={filterValueState.Retailer_Profile}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Company Origin</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Company_Origin_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Company_Origin"
                        name="Company_Origin"
                        label=""
                        options={filterOptionState.Company_Origin_Option}
                        placeholder="Type Company Origin"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='Company_Origin'
                        value={filterValueState.Company_Origin}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        style={{ height: '3rem' }}

                      />
                    </div>
                  </div>

                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {(activeTab !== '4' && activeTab !== '8' && activeTab !== '9' && activeTab !== '10' && user.channelTypeId == 2) && <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Channel Type</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.channelTypeOption.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="channel_type"
                        name="channel_type"
                        label=""
                        options={filterOptionState.channelTypeOption}
                        placeholder="Type Channel Type"
                        // multiple  
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        open={true}
                        input='channel_type'
                        value={filterValueState.typeId}
                        handleChangeSelect={handleChangeSelect}
                        popupIcon=''
                        disablePortal={true}
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        size='small'
                      />

                    </div>
                  </div>

                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Annual Turnover</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={AnnuvalTurnOver.length > 0 ? style : {}}>

                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Annual_Turnover"
                        name="Annual_Turnover"
                        label=""

                        options={AnnuvalTurnOver}
                        placeholder="Type Annual Turnover"
                        // multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='Annual_Turnover'
                        value={filterValueState.Annual_Turnover}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem', fontSize: '1rem', } }}
                        PopperComponent={PopperMy}
                        limitTags
                      />

                    </div>
                  </div>

                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}


const AnnuvalTurnOver = [
  { id: '<1M€', name: '<1M€' },
  { id: '1-10M€', name: '1-10M€' },
  { id: '10-100M€', name: '10-100M€' },
  { id: '>100M€', name: '>100M€' },
]

const channelTypeList = [
  {
    id: '2',
    type: 'Vendor',
    name: "VENDOR"
  },
  {
    id: '3',
    type: 'Distributor',
    name: "DISTRIBUTOR"
  },
  {
    id: '4',
    type: 'Retailer',
    name: "RETAILER"
  },
  {
    id: '5',
    type: 'Reseller',
    name: "RESELLER"
  }
];
export default ListingFilter;