import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { Row, Col, CardBody, Card, CardImg, CardHeader, Button, Modal, Table } from "reactstrap";
import axios from 'axios';
import { Spinner } from "views/Hoc/Spinner";
import { FormCheckBox } from "_components/FormElements/FormInput";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { PDFViewer } from '@react-pdf/renderer';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Image } from "@react-pdf/renderer";
import { Document, Page, Text, View, StyleSheet, Link, Font, PDFDownloadLink } from '@react-pdf/renderer';
import bannerPic from '../../../assets/img/channelDetail/grc_banner.png';


const styles = StyleSheet.create({
    page: {
        backgroundColor: "white",
        //   color: "white",
    },

    linkColumn: {
        flexDirection: 'column',
        flexGrow: 2,
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
    },

    link: {
        fontFamily: 'Lato',
        fontSize: 10,
        color: 'black',
        textDecoration: 'none',
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
    },
    section: {
        margin: 10,
        padding: 10,
        fontSize: 12,
    },
    logo: {
        width: 50,
        height: 50,
    },
    banner: {
        padding: 10,
        width: 600,
        height: 100,
    },
    agenda: {
        padding: 10
    },
    viewer: {
        width: window.innerWidth, //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
    },
});


const MySchedule = ({ eventId }) => {
    const [value, setValue] = React.useState(null);
    // const [rows, setRows] = React.useState([]);
    // let [agenda, setAgenda] = 



    const [eventReqId, setEventReqId] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const [openContact, setOpenContact] = React.useState(false);
    const [meetingContacts, setMeetingContacts] = React.useState([]);
    const [eventRequest, setEventRequest] = useState([]);
    const [eventData, setEventData] = useState();
    const userData = JSON.parse(localStorage.getItem('CHuser'));
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const [schedule, setSchedule] = React.useState(["Item1", "Item2", "Item3"]);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    };

    useEffect(() => {
        getMySchedule();
        getEventRequest();
        getEventData();
    }, [])

    const getEventData = async () => {      
        await axios.get('channelAdmin/getEventById/' + eventId)
        .then(res => {
           
            setEventData(res.data[0]);
            // setEventConfirm(res.data.confirm);
        });
    }

    const getEventRequest = async () => {
        await axios.get('channel/eventRequestContact/' + eventId)
            .then(res => {
                setEventRequest(res.data);
                // setEventConfirm(res.data.confirm);
            });
    }



    const getZoomLink = async () => {
        await axios.post('channel/getZoomMeetingUrl')
            .then(res => {
                window.open(res.data, '_blank')
            });

    }

    const handleClose = () => {
        setOpenContact(false);
    };

    const openRequest = async (participant) => {
        let contacts = await axios.post('channel/getMeetingContacts', { eventId: eventId, participants: participant })
            .then(res => {
                return res.data;
            }).catch(err => console.log("getMeetingContacts:", err));

        // meetingContacts = [...contacts];
        
        setMeetingContacts(contacts);
        setOpenContact(true);
    }

    const MyDocument = () => (
        <Document title='Las Vegas'>
            {/*render a single page*/}
            <Page size="A4" style={styles.page}>


                <Image style={styles.banner} src={bannerPic} />


                <View style={styles.section}>
                    <Text>Dear {userData.firstName},</Text><Text style={{ marginTop: 5 }}>
                        {`Please find below a summary of your agenda for ${eventData.name},${month[new Date(eventData.fromDate).getMonth()]} ${new Date(eventData.fromDate).getDate()}${new Date(eventData.fromDate).getDate()==1?'st':new Date(eventData.fromDate).getDate()==2?'nd':new Date(eventData.fromDate).getDate()==3?'rd':'th'} at
                        ${eventData.city},${eventData.country.country}. Make sure to review any meeting invitation you have received and give your response at the earliest.`} </Text>
                </View>

                <Text style={{ padding: 10 }}> Your Incoming Meeting requests </Text>

                {eventRequest.map(((req) =>
                    <View style={styles.section}>

                        <Image style={styles.logo} src={process.env.REACT_APP_CDN_URL + req.logo} />
                        {/* {bannerPic} */}

                        <Text>
                            {`${req.companyName} - ${req.channelType}`}
                        </Text>

                    </View>

                ))}




                <Text style={{ padding: 10 }}> Your event agenda </Text>

                {schedule.map(((x, index) => {

                    return x.list.map(comp =>

                        <View style={styles.section}>

                            {comp.isMeet == 1 && (comp.participants || comp.receivers) && (comp.partcomp || comp.reccomp) ?
                                <Text>{`${x.date}  ${comp.stime}            ${comp.function}  ${comp.partcomp || comp.reccomp} - Table[${comp.boothNo}] `}</Text>
                                : <Text>{`${x.date}  ${comp.stime}            ${comp.function}  `}</Text>}



                        </View>

                    )

                }

                ))}

                <Text style={{ padding: 5 }}> Venue Location </Text>

                <View style={styles.section}>
                    {eventData.address.split('\n').length ? eventData.address.split('\n').map(addr => 
                         <Text style={{ fontWeight: 800, marginTop: 2 }}>{addr}</Text>
                    ):
                    <Text style={{ fontWeight: 800, marginTop: 2 }}>{eventData.address}</Text> }
                    {/* <Text style={{ fontWeight: 800, marginTop: 2 }}>Schloßplatz in Humboldt Forum between Schlüterhof and Spree</Text> */}
                    {/* <Text style={{ marginTop: 3 }}>Follow the signs for ‘Guest Suite’. At security check, give the name for Business France with the suite number 736.</Text> */}
                    <Text style={{ marginTop: 5 }}>{`${eventData.zipcode} ${eventData.city} ${eventData.country.country}`}</Text>
                    <Link style={{ marginTop: 5 }}
                        src={eventData.eventMapUrl}
                        //style={imageContainerStyles}
                        target="_blank"
                    > Click here to view on Google map</Link>
                </View>





                <Text style={{ padding: 10 }}>Your login details </Text>

                <View style={styles.section}>
                    {/* <Text>URL: https://channelhub.net/Login</Text> */}

                    <Link
                        src="https://channelhub.net/Login"
                        //style={imageContainerStyles}
                        target="_blank"
                    > Click here to login on channelhub</Link>
                    <Text style={{ marginTop: 5 }}>Login: {userData.email}</Text>
                    <Text style={{ marginTop: 5 }}>Password: same as on ChannelHub</Text>
                </View>

                <View style={styles.section}>
                    <Text>Thanks,</Text>
                    <Text>{`${eventData.name} Team`}</Text>
                </View>
            </Page>
        </Document>
    )





    const getMySchedule = async () => {
        let agenda = [];
        await axios.get('channel/getmyschedule/' + eventId)
            .then(res => {


                var groupBy = function (xs, key) {
                    return xs.reduce(function (rv, x) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                        return rv;
                    }, {});
                };
                var groubedByTeam = groupBy(res.data, 'sdate')

                const arrayOfObj = Object.entries(groubedByTeam).map((e) => (e[1]));


                let myschedule = [];
                arrayOfObj.map(x => {
                    //temp work
                    //x.unshift({ sdate: '05-01-2023', function: 'Registration & Breakfast', stime: '08:00 - 08:30', utcFormat: 'Las Vegas Time' }, { sdate: '05-01-2023', function: 'Pitch Session', stime: '08:30 - 09:00', utcFormat: 'Las Vegas Time' })
                    //x.push({ sdate: '05-01-2023', function: 'Networking', stime: '10:00 - 10:30', utcFormat: 'Las Vegas Time' })
                    //end
                    myschedule.push({ date: x[0].sdate, list: x });
                })
                console.log("myschedule:", myschedule)

                setValue(myschedule[0].date);
                setSchedule(myschedule);
                setLoading(false)
                //changed @19/8
                // setAgenda(arrayOfObj[0]);


                //setRows(agenda);            
                // return agenda;
            }).catch(err => console.log("getsubject:", err));

    }

    const getAgenda = (list) => {
        return (<>

            {list.map(x => <>



                <Card>
                    <CardHeader>
                        <Row>
                            
                            <Col lg="2">
                                <p>{x.stime}</p>
                                {/* <p>Event time</p> */}
                            </Col>

                            <Col lg="10">
                                {x.isMeet == 1 && (x.participants || x.receivers) && (x.partcomp || x.reccomp) ?
                                    <p>{x.function} - {x.partcomp || x.reccomp} - {x.partcon?.toString() || x.reccon?.toString()}  {x.boothNo?"- Table" + " " + x.boothNo:''}</p>
                                    : <p>{x.function}</p>}
                                {/* <p>Event time</p> */}
                            </Col>


                            {/* <Col lg="3">
                                {openContact &&
                                    <Modal className="modal-dialog-centered" fade={false} isOpen={openContact} size="xg" style={{ minWidth: 900, width: '30%' }}>
                                        <div className="modal-header">
                                            Contact details

                                            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                                                <span aria-hidden={true}>×</span>
                                            </button>
                                        </div>

                                        <div className="modal-body">
                                            <div><strong>{x.function}</strong></div>
                                            <div><strong>{meetingContacts[0].companyName}</strong></div>
                                            <div><strong>{`Booth No:${meetingContacts[0].boothNo}`}</strong></div>

                                            <div className="container">

                                                {meetingContacts.length && <>

                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>NAME</th><th>JOB TITLE</th><th>EMAIL</th><th>PHONE</th>




                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {meetingContacts.map(item => {
                                                                return <tr>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.jobTitle}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.mobileNo}</td>
                                                                </tr>
                                                            })}


                                                        </tbody>



                                                    </Table>
                                                </>
                                                }

                                            </div>
                                        </div>
                                    </Modal>}
                                <div><strong>{x.function}</strong></div>
                                 {((x.isMeet == 1 && (x.participants || x.receivers)) || x.isMeet == 0) &&
                                    <div style={{ marginTop: "15px" }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {x.isMeet == 1 ? <>

                                                <Button onClick={() => { openRequest(x.participants||x.receivers) }}>Contact details</Button>
                                                 <i class="fas fa-video" onClick={() => getZoomLink()} style={{ fontSize: "28px", color: "#0e72ed", margin: "5px 30px 0 10px" }} ></i> 
                                            </> :
                                                <i class="fas fa-video" onClick={() => window.open(x.zoomUrl, '_blank')} style={{ fontSize: "28px", color: "#0e72ed", margin: "5px 30px 0 10px" }} ></i>}
                                            <FormCheckBox
                                                id=""
                                                type="checkbox"
                                                label="No Show"
                                            //label1={{textAlign:"justify"}}
                                            //register={register}

                                            /> 
                                        </div>
                                         <div style={{ marginTop: "10px" }}>
                                            Rate Your Meeting : * * * * *
                                        </div> 
                                    </div>}



                            </Col> */}
                            {/* <Col lg="3">
                        <img  alt="ch-logo" src={require("../../../assets/img/common/zoom.png")} />
                        </Col> */}

                            {/* <Col lg="2">

                        </Col> */}
                        </Row>
                    </CardHeader>


                </Card>
            </>


            )}
        </>)

    };

    return (
        // <Box sx={{ width: "100%", typography: "body1" }}>
        <div className="main-content">



            {loading ? <Spinner /> :
                <Card>
                    {/* <PDFDownloadLink document={<MyDocument />} fileName="schedule.pdf">
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Download now!'
                        }
                    </PDFDownloadLink> */}
                    <h3 className="m-3">Your Event Agenda</h3>
                    <CardHeader>
                        {/* Please rate your meeting from 1 to 4 stars(4 stars being the highest score)
                        The 'NO-SHOW' button allows you to inform us if your meeting was absent or unresponsive. */}

{eventData &&
                        <Button className="ml-2" color="secondary" type="button"> <PDFDownloadLink document={<MyDocument />} fileName="schedule.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading document...' : 'Download Agenda!'
                            }
                        </PDFDownloadLink></Button>}


                    </CardHeader>

                    {value &&
                        <TabContext value={value} >
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <TabList onChange={handleChange} textColor="primary" indicatorColor="primary">
                                    {schedule.map((x) => (
                                        <Tab label={x.date} value={x.date} />
                                    ))}
                                </TabList>
                            </Box>

                            {schedule.map((x) => (
                                <TabPanel value={x.date}>{getAgenda(x.list)}</TabPanel>
                            ))}

                        </TabContext>}
                </Card>}

        </div>
    );
}

export default MySchedule;
