import React,{useState,useEffect} from "react";

import { useForm} from "react-hook-form";

import { Search } from "./Search-Result";
import Title from "views/Hoc/Dialog/Title";
import { Container } from "reactstrap";
import ChannelWrapper from '../Channel-Listing/channelWrapper';

const SearchWrapper =(props)=> {
  //console.log("sid:",props.match.params.id);
  let sid=props.match.params.id;
  let [listing, setListing] = useState(props.location.pathname);
  //const {  Tabname, Type, channelId, openConnectPopUp, contactList } = props;  
  let [activeTab, setActiveTab] = useState(null);
  const [sort, setSort] = React.useState(true);
  let [desc, setDesc] = useState([]);
  let [tab, setTab] = useState();
  let [type, setType] = useState();
  let [val, setSelection] = useState(true);
  const [filterValueState, setFilterValue] = useState({Speciality:[], Brand:[], Retailer_Profile:[], Company_Origin:[], Annual_Turnover:null });
  let [searchState, setSearch] = useState(null);
  const { control } = useForm({ mode:'onBlur' });
  
  const resetFilterValue =()=>{
    filterValueState.Speciality=[];
    filterValueState.Brand=[];
    filterValueState.Company_Origin=[];
    filterValueState.Retailer_Profile=[];
    filterValueState.Annual_Turnover=null;
    setFilterValue({ ...filterValueState });
    searchState= null;
    setSearch(searchState);
  }

  useEffect(() => { 
    listing=props.location.pathname;  
    setListing(listing);
    console.log('listing', listing);
    if(listing === '/admin/searchResult/11')
    {
      activeTab=11;
    tab="Companies";
    type="Company";
    desc=["List of your various search"];
    console.log('listing', activeTab);
    }  
    else if(listing === '/admin/searchResult/12')
    {
    activeTab=12;
    tab="Companies";
    type="Company";
    desc=["List of your various search"];
    console.log('listing', activeTab);
    }  
    else if(listing === '/admin/searchResult/13')
    {

    activeTab=13;
    tab="Companies";
    type="Company";
    desc=["List of your various search"];
      console.log('listing', activeTab);
    }

    setActiveTab(activeTab);
    setTab(tab);
    setType(type);  
    setDesc(desc); 
    
},[props.location.pathname,props.match.params.id] )

  useEffect(() => {

  if(sid==11)
  {
    activeTab=11;
    tab="Companies";
    type="Company";
  }
  else if(sid==12)
  {
    activeTab=12;
    tab="Companies";
    type="Company";
  }
  else if(sid==13)
  {
    activeTab=13;
    tab="Companies";
    type="Company";
  }
  else if(sid==5)
  {
    tab="Products";
    type="Product";
  }
  else if(sid==6)
  {
    tab="Contacts";
    type="Contact";
  }
  setActiveTab(activeTab);
    setTab(tab);
    setType(type);   
}, [])

  

return (
 <>  
        {val ? <Search  Tabname={tab} Type={type} activeTab={activeTab} listing={listing} filterValueState={filterValueState}  search ={searchState} desc={desc}/>:
        <Search  Tabname={tab} Type={type} activeTab={activeTab} listing={listing} filterValueState={filterValueState}  search ={searchState}/>
        }      
   </>
);
}


export default SearchWrapper;

