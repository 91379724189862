import React, { useState, useEffect } from "react";
import { Card,CardBody,CardHeader, Container,Row} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader"

const Transaction = (props) => {
    return (
        <>
        <SimpleHeader location={props.match}/>
        <div className="main-content">
        <Container className="mt-8 col-lg-5 col-md-5 col-sm-12" fluid>
        <Card>
            <CardBody style={{padding:'1rem'}} >
                {/* <h3 className="mb-3 mt-4 text-center">Your Order Placed Successfully</h3> */}
                <div className="text-center">
                <i class="far fa-check-circle success-i"></i>
                </div>
                <h2 className="mt-3 text-center">Payment Success</h2>
                <p className="mb-0 text-center">hurrah, You have to STARTER for year, Now you can enjoy your Channelhub features.</p>
                <div className="text-center mt-4">            
            <button type="submit" className="btn btn-primary">Continue</button>
          </div>
            </CardBody>
            </Card>
            </Container>            
        </div>
        </>
    );
};

const Wiretransfer = (props) => {
    return (
        <>
        <SimpleHeader location={props.match}/>
        <div className="main-content">
        <Container className="mt-8 col-lg-5 col-md-5 col-sm-12" fluid>
        <Card>
            <CardBody style={{padding:'1rem'}} >
                {/* <h3 className="mb-3 mt-4 text-center">Your Order Placed Successfully</h3> */}
                <div className="text-center">
                <i class="far fa-check-circle success-i"></i>
                </div>
                <h2 className="mt-3 text-center">Payment Success</h2>
                <p className="mb-0 text-center">hurrah, You have to STARTER for year, Now you can enjoy your Channelhub features.</p>
                <div className="text-center mt-4">            
            <button type="submit" className="btn btn-primary">Continue</button>
          </div>
            </CardBody>
            </Card>
            </Container>            
        </div>
        </>
    );
};

const Failedtransaction = (props) => {
    return (
        <>
        <SimpleHeader location={props.match}/>
        <div className="main-content">
        <Container className="mt-8 col-lg-5 col-md-5 col-sm-12" fluid>
        <Card>
            <CardBody style={{padding:'1rem'}} >
                {/* <h3 className="mb-3 mt-4 text-center">Your Order Placed Successfully</h3> */}
                <div className="text-center">
                <i class="far fa-times-circle fail-i"></i>
                </div>
                <h2 className="mt-3 text-center">Payment Failed</h2>
                <p className="mb-0 text-center">hurrah, You have to STARTER for year, Now you can enjoy your Channelhub features.</p>
                <div className="text-center mt-4">            
            <button type="submit" className="btn btn-primary">Continue</button>
          </div>
            </CardBody>
            </Card>
            </Container>            
        </div>
        </>
    );
};

export {Transaction,Wiretransfer,Failedtransaction}
