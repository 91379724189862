import React, { useState, useEffect, useRef } from "react";
import { Card,CardBody,CardHeader, Container,Row,Col} from "reactstrap";
import { useForm} from "react-hook-form";
import {FormInput,FormRadio,FormCheckBox,FormTextArea,SingleAutocomplete,FormSelect,NewFieldAutocomplete} from "_components/FormElements/FormInput";
import DateFnsUtils from '@date-io/date-fns';
import {BrandCard} from "views/Hoc/BrandCard";
import {PdfCard} from "views/Hoc/PdfCard";
import MultiUpload from "views/Hoc/MultiUpload";
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {useBeforeFirstRender} from '_hooks/index'
import axios from 'axios';
import { history } from '../../../../_helpers';
import { dashboardActions } from '../../../../_actions';
import { ImageLibraryMultipleUploads } from 'views/Hoc/Dialog/ImageLibraryMultipleUploads';

import {Spinner} from 'views/Hoc/Spinner';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Badge } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddFirstProductSchema } from '../validation';
import FormHelperText from '@material-ui/core/FormHelperText';
import NotificationAlert from "react-notification-alert";
import { DocumentName } from 'views/Hoc/Dialog/DocumentName';
import { commonService } from '_services/common.service';
import {ToastsContainer, ToastsStore} from 'react-toasts';

import { Dialog, ConfirmDialog } from "views/Hoc/Dialog/GlobalDialog";

var _ = require('lodash');

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  formControl1: {
    minWidth:"100%",
  },
  formControl: {
    minWidth:"100%",
  },
  // margin: {
  //   margin: theme.spacing(1),
  // },
}));


const AddFirstProduct = (props) => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [group, setGroup] = useState([]);
  const [groupSelected, setGroupSelected] = useState(false);
  let [groupName, setGroupName] = useState(null);
  const [prodImage, setProdImage] = useState(false);
  let [loading, setLoading]  = useState(false);
  let [initLoad, setInitLoading] = useState(false);
  const classes = useStyles();
  const notificationAlert = useRef(null);
  let [namePopup, setNamePopup]  = useState(false);
  let [docName, setDocName]  = useState(null);
  let [docId, setDocId]  = useState(null);

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });

  const [state, setState] = useState({
    productName : '',
    tweetPitch : '',
    productshortdec: '',
    productDescription: '',
    productCategory : {},
    productCategoryOption : [],
    productBrand : {},
    productBrandOption : [],
    productCertification : '',
    awards : [],
    productWarranty : '2 Years',
    productLocalisation : '',
    videoUrl : '',
    productVisibility: true,
    productVisibility1: true,
    productVisibility2: true,
    productAttributes : '0',
    priceDetails : '',
    businessCurrency : '',
    msrp : '',
    unit: '',
    MOQ : '',
    moqUnit : '',
    no_of_items_in_box : '',
    max_qty_for_sample : '',
    no_limit : '',
    productPositioning : 'Entry',
    availability : 'Immediate',
    text_about_sample : '',
    variantDetails: '',
    keyTags:[],
    Keywords:null,
    businessType: null,

  });


  
  const { register, handleSubmit, control, errors, watch,formState} = useForm({      
    mode:'onBlur',  
    resolver: yupResolver(AddFirstProductSchema),
  });

  const [mediaState, setMediaState] = useState({
    productImages : [],
    productPos : [],
    packagingImages : [],
    productBrochures : []
  });

  const notify = (type, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      // icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };


  useEffect(() => {
    //localStorage
    setInitLoading(true);
    var userData = JSON.parse(localStorage.getItem('CHuser'));
    (async() =>{  
    await axios.get('setting/getProductProfile/'+userData?.channelId).then(res =>{      
    state.productCategoryOption=res.data.productCat;  
    state.productBrandOption=res.data.productBrand;
  
    setState({...state, productCategoryOption:state.productCategoryOption});    
    setState({...state, productBrandOption:state.productBrandOption});
  
    setInitLoading(false);
    //console.log("setting:",state.isVat);
  }).catch(err => {
    setInitLoading(false);
    commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/setting/getProductProfile'},'/error')
  });    
  })();
    
  }, [])

  // useEffect(() => {  
  //   if(!_.isEmpty(errors)&&formState.isSubmitted)
  //   {
  //     ToastsStore.error(JSON.stringify(errors));
  //     commonService.postService({err:JSON.stringify(errors),reqUrl:window.location.pathname,service:'validation_err'},'/error')    
  //   }
  // }, [errors])
  
  
  
//functions for image
const handleOnDrop = async(input, data, file_name) => {
  if(data){
    setLoading(true);
    console.log('datat', data);
    let file = data;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // console.log('file', file)
      // console.log('reader.result' ,reader.result)
      selectImg(input, reader.result, file_name)
      //setOpen(false);          
    };
    
  }
}

function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}


const selectImg = async(input, data, file_name)=>{
  if(data.length>0){
    
    //converting base64 string to file format.
    var random = Math.random(); 
    var fileName =random +'.png';
    var file = dataURLtoFile(data, fileName);    
    const formData = new FormData();
    formData.append('file', file);

    let aws_folder = input === 'productImages' ? 'image' : input === 'productPos' ? 'display' : input === 'packagingImages' ? 'packaging' : '' ;


    const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/'+aws_folder).catch(err => {
      commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/mediaupload/upload'},'/error')
    });

    if(resImagePath){
    if(input === 'productImages'){
      let temp = mediaState.productImages;
      temp.push({image: resImagePath.data.url, filename: file_name})
      setMediaState({...mediaState, [input]: temp});
    }else if(input === 'productPos'){
      let temp = mediaState.productPos;
      temp.push({image: resImagePath.data.url, filename: file_name})
      setMediaState({...mediaState, [input]: temp})
    }else if(input === 'packagingImages'){
      let temp = mediaState.packagingImages;
      temp.push({image: resImagePath.data.url, filename: file_name})
      setMediaState({...mediaState, [input]: temp})
    }
    setLoading(false);

  }else{
     loading=false;
     setLoading(loading);
  }
  
  }
  // setOpen(false);
}

  const handleDateChange = (date) => {
    errors.date=null;
    setSelectedDate(date);
  };
  

  const watchAll = watch();
  console.log('watchAll', watchAll);
  console.log('errors', errors);
  console.log('state', state);
  console.log('mediaState' ,mediaState);
  

  const handleChange = input => e => {
    console.log("add",e.target.value);  
    setState({...state, [input] : e.target.value});
    if(input === 'moq')
    {
        e.target.value = e.target.value.split('').filter(char => !isNaN(char)).join('');
    }

    if(input === 'productVisibility' || input === 'productVisibility1' || input === 'productVisibility2')
    {
      setState({...state, [input] : !state[input] });  
    } 
    else if (input == "productDescription") {
      setState({ ...state, [input]: e.target.value.slice(0, 2000) })
    }
    else if(input == 'tweetPitch'){
      setState({...state, [input]: e.target.value.slice(0, 180)});
    }
    else if(input == 'productshortdec'){
      setState({...state, [input]: e.target.value.slice(0, 140)});
    }
    else if(input == 'text_about_sample'){
      setState({...state, [input]: e.target.value.slice(0, 1000)});
    }
    else if (input == "productType") {
      var userData = JSON.parse(localStorage.getItem('CHuser'));
      errors.productType = null;
      if (userData.businessType != e.target.value && userData.businessType != 3) {
        changeBusinessType(userData.businessType, e.target.value);
      }
      else {
        state[input] = e.target.value;
        setState({ ...state });
      }

    }
    else{
      setState({...state, [input] : e.target.value});
    }
  }
  
  const handleChangeSelect = (input,value) => {
    if(input=='productCategory'){
      state.productCategory= value
      setState({...state, productCategory:state.productCategory});
    } else if(input=='productBrand'){
      state.productBrand= value
      setState({...state, productBrand:state.productBrand});
    } else if(input=='Keywords'){
      console.log('value', value);
      let name = value?.name;
      let tags={
        tag:value.name
      }
      state.keyTags.push(tags);
      state.Keywords=null;
      setState({...state });
    }
    if(input!='productCategory'&&input!='productBrand' && input!=='Keywords')
      setState({...state, [input]:[value]});
  }
  
  const handleChangeFileSelect = async(input, value, filename) => {
    if(input === 'productBrochures'){
      console.log('brochures');
      let temp = mediaState.productBrochures;
      let newFiles = value.filter(file => !mediaState.productBrochures.find(f => f.documentId === file.documentId));
      
      console.log('temp', temp, temp.length + newFiles.length)
      if(temp.length + newFiles.length <= 5){
        temp.push(...newFiles);
        setMediaState({...mediaState, [input]:temp});
      }
      else{
         notify('danger', 'Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb')
         console.log('Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb');
      }
    }
    else{
      let res = await handleOnDrop(input, value, filename);
     if(res){
        console.log('res', res);
        setMediaState({...mediaState, [input]:value})
     };
    }
  }

  const pdfDelete = (data) => {
    let filteredState = mediaState.productBrochures.filter(item => item.documentId != data.documentId);
    if(filteredState){
      setMediaState({...mediaState, productBrochures: filteredState});
      console.log('after delete', mediaState);
    }
  }

  const handleChangeFileDelete = async(input, fileToBeRemoved) => {
    let filteredState = await mediaState[input].filter(item => item.filename != fileToBeRemoved);
    if(filteredState){
      setMediaState({...mediaState, [input]: filteredState});
      console.log('after delete', mediaState);
    }
  }

  const handleChangeQuill1 = (content, delta, source, editor) => { 
      setState({...state, 'tweetPitch' : editor.getText()});
  }
  
  const handleChangeQuill2 = (content, delta, source, editor) => { 
    setState({...state, 'productshortdec' : editor.getText()});
  }
  const handleChangeQuill3 = (content, delta, source, editor) => { 
    setState({...state, 'productCertification' : editor.getText()});
  }
  
  const handleChangeQuill4 = (content, delta, source, editor) => { 
    setState({...state, 'productWarranty' : editor.getText()});
  }
  
  const handleChangeQuill5 = (content, delta, source, editor) => { 
    setState({...state, 'productLocalisation' : editor.getText()});
  }

  const addAwards = (data) => {
    let index = state.awards.findIndex((obj => obj.id == data.id));
    if(index != -1){
      state.awards[index] = data;
      setState({...state, awards: state.awards});
      console.log('updated', data);
    }
    else{
    let temp = state.awards;
    temp.push(data);
    setState({...state, awards: temp});
    console.log('added', data);
    }
  }

  const deleteAwards = (data) => {
    const filteredList = state.awards.filter((item) => {
      return item.id !== data.id;
    });
    state.awards = filteredList;
    setState({...state, awards: state.awards});
  }


  const popUpBeforeSave = async() => {
    if(mediaState.productImages.length == 0)
    {
      confirmInactive();
    }
    else{
      save();
    }
  }

  const save = async() => {
    try
    {
    setLoading(true);
    let visibility=[];

      if(state.productVisibility)
          visibility.push({sharetypeid:3});
      if(state.productVisibility1 && state.productType=='1')
          visibility.push({sharetypeid:4});
      if(state.productVisibility2 && state.productType=='2')
        visibility.push({sharetypeid:5});

    let productBasicInfo={
      productgroup : +state.productCategory.groupid,
		  productcategory : +state.productCategory.id,
			productbrand : +state.productBrand.id,
      productname : watchAll.proname,
      productstatus : watchAll.status ? '1' : '0',      
			productshortdec: state.productshortdec,
      productdesc: state.productDescription,
			//keyretailers : null,
      //keydistributors: null,
      productusp:state.tweetPitch,	
      // productshortdec : state.tweetPitch,
      producttag:state.keyTags,
      businessType: +state.productType
    }
    // producttagid

    if(productBasicInfo.producttag.length===0)
    delete productBasicInfo.producttag;

    let productArticleReview={
      review:[],//[{name:null,articleurl:null,logo:null}],
      productexistingrating:[]//[{existproductrating:null,ratingproofvalidationurl:null,ratingproofvalidationname:null,ratingproofvalidationlogo:null}],
    } 

    
    let productImages={
      videourl: watchAll.videoUrl,
      productimage:mediaState.productImages.length ? [].concat(mediaState.productImages.map((x, i) => {
        return {image: x.image, isdefault:i===0? '1':'0',sortorder:i+1}
      })) : [],//[{image:null,isdefault:'0',sortorder:null}],
      productdisplayimage:mediaState.productPos.length ? [].concat(mediaState.productPos.map(x => {
        return {image: x.image}
      })): [],//[{image:null}],
      productpackagingimage:mediaState.packagingImages.length ? [].concat(mediaState.packagingImages.map(x => {
        return {image: x.image}
      })): [],//[{image:null}],      
    }

    let productBrochure={
      productpdf:mediaState.productBrochures.length ? [].concat(mediaState.productBrochures.map(x => {
        return {imageid: +x.documentId, documentName: null, documentDescription: null, documentName:x.name};
      })) : []//{image:null,documentName:null,documentDescription:null}]
    }
    
    let productAvailability={
      isavaialbleimmediate: state.availability === 'Immediate' ? '1' : '0',
      avaialblefrom: state.availability != 'Immediate' ? selectedDate : null,
      avaialbilitycomment: state.availability != 'Immediate' ? state.text_about_sample : null,
    }

    let ProductInfo={
      productCertifi: state.productCertification,
			productWarranty: state.productWarranty,
			productLocal: state.productLocalisation,
    }

    let productPrice={
      currencyid:state.businessCurrency,
      retailerprice:+watchAll.msrp,
      retailerpriceunitid: state.unit,
			//sampleprice: null,
			//samplepriceunitid: null,
      //issamplefree: null,
      samplethreshold : +watchAll.max_qty_sample,
		  isunlimitsample: watchAll.no_limit? "1" : "0",
    }
    let productPackageInfo={
     minorderquantity: +watchAll.moq,
				orderunitid: state.moqUnit,
				packagesize : null,
				packageunit: null,
				noofpackageinbox : +watchAll.no_of_items_in_box,
				noofpackageinboxunitid: null,
				noofboxinpallet : null,
				noofboxinpalletunitid : null,
				approximateweightofpallet: null,
				approximateweightofpalletuniti: null,
				length : null,
				breadth : null,
				height: null,
				weight: null,
    }
    let productCompeting={
      productcompeting:[]//[{competingproduct:null,competitor:null,competitorproducturl:null}]
    }

    let productRelevantChannel={
      distributionchannel:[],//[{customerProfileId:null}],
      retailchannel:[]//[{customerProfileId:null}]
    }

    let productSellingLocation={
      sellinglocation:[]//[{regioncountryjctid:null}]
    }

    let productTargetLocation={
      sellinglocation:[]//[{regioncountryjctid:null}]
    }
    
    let productPreferences={
      preferences:visibility,
      isshowtofreesub : null,
    }

    let productCode={
      productCodeTypes:[],//[{productuniversalcodetype:null,code:null}],
      hasanyvariant : watchAll.productAttributes,
      variantdetails : watchAll.productAttributes === '1' ? watchAll.variantDetails : null,
    }

    let productPosition={      
      productqualitylevel:watchAll.productPositioning,
    }    

    let product={
      productBasicInfo:productBasicInfo,
      productArticleReview:productArticleReview,
      productImages:productImages,
      productBrochure:productBrochure,
      productAvailability:productAvailability,
      ProductInfo:ProductInfo,
      productPrice:productPrice,
      productPackageInfo:productPackageInfo,
      //productCompeting:productCompeting,
      productPosition:productPosition,
      productCode:productCode,
      productPreferences:productPreferences,
      //productTargetLocation:productTargetLocation,
      //productSellingLocation:productSellingLocation,
      //productRelevantChannel:productRelevantChannel
    }

    let award_details=state.awards ? [].concat(state.awards.map(x => {
      return {product_award_id : null, details : x.award_details, issued_by : x.awardName, valid_upto : x.date!=='1' ? x.date : null, isLifeTime : x.date==='1' ? '1' : '0', imageId: null, image: x.awardImg ? x.awardImg : null }
    })) : [];

    


    // let data = {
    // productName : watchAll.proname,
    // status : watchAll.status,
    // tweetPitch : state.tweetPitch,
    // productshortdec: state.productshortdec,
    // productCategory : state.productCategory.id,
    // productBrand : state.productBrand.id,
    // productCertification : state.productCertification,
    // awards : state.awards,
    // productWarranty : state.productWarranty,
    // productLocalisation : state.productLocalisation,
    // videoUrl : watchAll.videoUrl,
    // productVisibility: watchAll.productVisibility,
    // productAttributes : watchAll.productAttributes,
    // businessCurrency : state.businessCurrency,
    // msrp : watchAll.msrp,
    // unit: state.unit,
    // MOQ : watchAll.moq,
    // moqUnit : state.moqUnit,
    // no_of_items_in_box : watchAll.no_of_items_in_box,
    // max_qty_for_sample : watchAll.max_qty_sample,
    // no_limit : watchAll.no_limit? watchAll.no_limit : false,
    // productPositioning : watchAll.productPositioning,
    // availability : watchAll.availability,
    // text_about_sample : state.text_about_sample,
    // date: selectedDate
    // };
    // console.log('data', data);
    //console.log('product', product);
    let result=await dashboardActions.addFirstProduct({      
      productBasicInfo:productBasicInfo,
      //productArticleReview:productArticleReview,
      productImages:productImages,
      ...(productBrochure.productpdf.length && {productBrochure:productBrochure}),
      productAvailability:productAvailability,
      ProductInfo:ProductInfo,
      productPrice:productPrice,
      productPackageInfo:productPackageInfo,
      //productCompeting:productCompeting,
      productPosition:productPosition,
      productCode:productCode,
      productPreferences:productPreferences,
      //productTargetLocation:productTargetLocation,
      //productSellingLocation:productSellingLocation,
      //productRelevantChannel:productRelevantChannel,
      award_details:award_details},+props.ratingId).catch(err => {
        ToastsStore.error('Something Went Wrong!');
      commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'addFirstProduct'},'/error')
    });

    if(result)
    {
      ToastsStore.success('Saved Successfully!')
      setLoading(false);     
    }
    //console.log('saveresuult', result);    
    history.push('admin/dashboard');
  }
  catch(err)
  {        
    ToastsStore.error('Something Went Wrong!');
    setLoading(false);    
    commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'addFirstProduct'},'/error');       
  }
  }

  const deleteKeyTag=(data)=>{
    console.log('data', data);
    state.keyTags = state.keyTags.filter(x=>x.tag!==data.tag);
    if(data?.producttagid)
     delete state.deletedproducttag.push(parseInt(data.producttagid));
     setState({...state});
  }
  
const editName=(item)=>{
  docName=item.name;
setDocName(docName);
docId=item.documentId;
setDocId(docId);
setNamePopup(true);
}
const docNameChange= async(docId,name)=>{
  console.log('media', mediaState.productBrochures);
  console.log(docId,name);
  mediaState.productBrochures.map(x=>{
    if(parseInt(x.documentId) === parseInt(docId))
    x.name=name;
  })
  namePopupClose();
}
const namePopupClose = () => {
  setNamePopup(false);
};

const confirmPopup = (res) => {
  setModalPopup(false);
  state['productType'] = res.value;
  setState({ ...state });
}

const closePopup = () => {
  setModalPopup(false);
  setActiveProductPop(false);
};

const changeBusinessType = (ctype, value) => {
  ctype = ctype == 1 ? 'B2C' : 'B2B';
  let btype = value == 1 ? 'B2C' : 'B2B';
  modalPopValue.message = 'Your are an "#' + ctype + '" company, But you are trying to switch your product to "#' + btype + '". Do you wants to consider your company as Both B2B and B2C ?';
  modalPopValue.title = 'Product Type';
  modalPopValue.value = value;
  modalPopValue.popUpId = 1;
  setModalPopValue({ ...modalPopValue });
  setModalPopup(true);
}

const [activeProductPop, setActiveProductPop] = useState(false);

  const confirmSavePopup = (value) => {
    setActiveProductPop(false);
    save();
  }

  const confirmInactive = () => {
    modalPopValue.message = "You haven't added product images so, we are moving your product to inactive. You can add product image later and make it active."
    modalPopValue.title = 'Product Status';
    modalPopValue.value = 'Confirm';
    modalPopValue.popUpId = 2;
    setModalPopValue({...modalPopValue});
    setActiveProductPop(true);
  }

return (
<div className="main-content">
<ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
<Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
<ConfirmDialog open={activeProductPop} value={modalPopValue} handleSave={confirmSavePopup} handleClose={closePopup} />
<DocumentName isOpen={namePopup} handleClose={namePopupClose} isEdit={true} handleSave={docNameChange} name={docName}  docId={docId} />
  {initLoad ? <Spinner /> : 
        <Container className="mt-4 col-lg-9 col-md-9 col-sm-12" fluid>
          <NotificationAlert ref={notificationAlert}/>
        <form noValidate onSubmit={handleSubmit(popUpBeforeSave)}>
              <Card>                   
              <CardHeader>
                 <h3 className="text-center">Basic Info</h3>
                  <p className="text-center">Enter your general product info like product name, category, USP, info and Keywords / Tags</p>
                  </CardHeader>
                  <CardBody  >
                  <div className="row"> 
    <div className="col-lg-8 col-12">
            <div className="form-group">            
                    <FormInput 
                         id="proname"
                         type="text"
                         name="proname"
                         label="Product Name *"
                         //onChange={handleChange('productName')}
                         register={register}
                         defaultValue={state.productName}
                         value={watchAll.proname}
                         error={errors.proname}
                    />
             </div>           
    </div>
    <div className="col-lg-2 col-6 pt-3 text-right">
            <h3>Status</h3></div>
            <div className="col-lg-2 col-6 pt-3 ">
            <div className="form-group">            
            <label className="custom-toggle custom-toggle-success">
            <input defaultChecked type="checkbox" ref={register} name="status"/>
        <span className="custom-toggle-slider rounded-circle" data-label-off="Inactive" data-label-on="Active"></span>
      </label>
      </div>
             </div>           
    </div>

    <Row>
                  <div className="col-lg-9">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productType" name="productType" ref={register} value={state.productType} />
                    <h4 style={{ textAlign: 'left' }}>Product's Type * 
                    <span style={{ color: '#f44336', fontSize: '0.75rem' }}>{!!errors.productType && ' Please Select Product Type'}</span>
                    </h4>
                    <h5>B2B or B2C Products?</h5>
                  </div>
                  <div className="col-lg-3  ">
                    <div className="form-group">
                      <FormRadio
                        name="productType1"
                        id="productType"
                        className="text-sm mb-0"
                        label=""
                        options={businessTypeOptions}
                        control={control}
                        value={state.productType}
                        defaultValue={state.productType}
                        row
                        onChange={handleChange('productType')}
                      />

                    </div>
                  </div>
                </Row>

<div className="row"> 
   <div className="col-sm-12 ">
    <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="tweetPitch" name="tweetPitch" ref={register} value={state.tweetPitch} /> 
    {!errors.tweetPitch || (state.tweetPitch.length > 5 && state.tweetPitch.length < 180) ? <h4 >Add Your Tweet Pitch * (180 Characters Max)
      {state.tweetPitch?.length>0 && <span style={{color:state.tweetPitch?.length>180?"red":"#9ea2a5"}}> remaining characters  {180-state.tweetPitch.length}</span> }
    </h4> : 
    <h4 >Add Your Tweet Pitch * (180 Characters Max) <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
     state.tweetPitch.length <= 1? 'Tweet Pitch can\'t be empty.' : state.tweetPitch.length <= 5 ? 'TweetPitch must have min 5 characters' : state.tweetPitch.length > 180 ? 'TweetPitch must have max 180 characters' : ''
    }</span> </h4> }
                    
  <div className="form-group">
    <FormTextArea className="buytext2 w-100 h-auto" placeholder="Enter Product's Unique Specification or Why Customer Needs to buy your product"
    onChange={handleChange('tweetPitch')} value={state.tweetPitch}
    />
    </div>
    </div>
</div>

<div className="row">
                  <div className="col-sm-12 ">
                    {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productDescription" name="productDescription" ref={register} value={state.productDescription} />  */}
                    <h4 style={{ textAlign: 'left' }}>Full Description
                      {state.productDescription?.length > 0 && <span style={{ color: state.productDescription?.length > 2000 ? "red" : "#9ea2a5" }}> remaining characters  {2000 - state.productDescription.length}</span>}
                    </h4>
                    {/* {!errors.productDescription || (state.productDescription?.length > 5 && state.productDescription?.length <8000) ? <h4 style={{textAlign:'left'}}>Product Description *</h4> :
                  <h4 style={{textAlign:'left'}}>Product Description * <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
                  state.productDescription?.length <= 1? 'Product Description can\'t be empty.' : state.productDescription?.length <= 5 ? 'Product Description must have min 5 characters' : state.productDescription?.length > 8000 ? 'Product Description must have max 8000 characters' : ''
                  } </span></h4> } */}
                    <div className="form-group">
                      <FormTextArea className="buytext4" placeholder="Product full description" value={state.productDescription} onChange={handleChange('productDescription')} />
                    </div>
                  </div>
                </div>

<div className="row">    

                  <div className="col-sm-12 ">
                  <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productshortdec" name="productshortdec" ref={register} value={state.productshortdec} /> 
                  {!errors.productshortdec || (state.productshortdec.length > 5 && state.productshortdec.length < 140) ? <h4 style={{textAlign:'left'}}>Add Product Short Description *
                  {state.productshortdec?.length>0 && <span style={{color:state.productshortdec?.length>140?"red":"#9ea2a5"}}> remaining characters  {140-state.productshortdec.length}</span> }
                  </h4> :
                  <h4 style={{textAlign:'left'}}>Add Product Short Description * <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
                    state.productshortdec.length <= 1? 'Product Description can\'t be empty.' : state.productshortdec.length <= 5 ? 'Product Description must have min 5 characters' : state.productshortdec.length > 140 ? 'Product Description must have max 140 characters' : ''
                  } </span></h4> }
          <div className="form-group">
          <FormTextArea className="buytext2 w-100 h-auto" placeholder="2 or 3 words to describe your product*"
            onChange={handleChange('productshortdec')} value={state.productshortdec}
        />
        </div>
        </div>
</div>
<div className="row"> 
<div className="col-lg-6 ">
            <div className="form-group">               
                    <SingleAutocomplete
                        focused={errors.ProductCategory ? true : false}
                        id="ProductCategory"
                        name="ProductCategory"
                        control={control}
                        register={register}
                        input="productCategory"

                        //defaultValue={ state.productCategory[0] }
                        value = { state.productCategory }

                        disableCloseOnSelect
                        options={state.productCategoryOption}
                        getOptionLabel={(option) => option.name}
                        handleChangeSelect={handleChangeSelect}
                        label="Product Category *"
                        errMessage={'Choose Product\'s Category.'}
                        error={errors.ProductCategory}
                      />     
        </div>
        </div>
        <div className="col-lg-6 ">
            <div className="form-group">               
            <SingleAutocomplete
                        limitTags={2}
                        id="ProductBrand"
                        name="ProductBrand"
                        control={control}
                        register={register}
                        label="Product Brand *"
                        input="productBrand"

                        //defaultValue={ state.productBrand[0] }
                        value={ state.productBrand }

                        disableCloseOnSelect
                        options={state.productBrandOption}
                        getOptionLabel={(option) => option.name}
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Choose Product\'s Brand.'}
                        error={errors.ProductBrand}
                      />     
        </div>
        </div>
        </div>
       <div className="col-lg-12 ">
       <h4 style={{textAlign:'left'}}>Product Keywords/Tags</h4> 
       <div className="w-100 overall">
        <div className=" ">

        { state.keyTags.length>0 && 
    <div>
    <Row>
    {state.keyTags.map(x=>{
  return(
          <Col lg="2"className="p-0 m-0 ">
            <div style={{width:'80%'}}>
                <div className="fav-cl1 pointer" onClick={()=>deleteKeyTag(x)}>X</div>
            <Badge className="B-prod1  fav-list">{x.tag}</Badge>
            </div>
    </Col>
  )
})}
    </Row>
   </div>
}

<div className="col-lg-12 fav-ser mt-3">
            <div className="form-group">             
            <NewFieldAutocomplete
                id="Keywords"
                type="text"
                label="Product Keywords"
                name="Product Keywords"
                options={[]}
                placeholder="Product Keywords"
                // error={errors.postalcode}
                disableCloseOnSelect
                // limitTags={2}
                //defaultValue={{name:'raja', id:1}}
                control={control}
                input='Keywords'
                value={state.Keywords}
            //     errMessage={CompanyError ? 'Company name already taken' : companyName[0]? 'Company name must be minimum 2 characters' : 'Please enter company name'}
            //     error={errors.companyName ? errors.companyName : CompanyError}   
                 handleChangeSelect={handleChangeSelect}
            />            
        </div>
        </div>
</div>
</div>
</div>
      
</CardBody>
</Card>
         

{/* ---------------------------Product's Shelve------------------------------ */}

                <Card>
                <CardHeader>
                <h3 style={{textAlign:'center'}}>Product's Shelve</h3>
                  <p className="text-center">Enter Products awards, certification, product warranty, localization</p>
                  </CardHeader>
                  <CardBody>
                  


 
        
     
          <div className="col-sm-12 ">
          <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productCertification" name="productCertification" ref={register} value={state.productCertification} /> 
          {!errors.productCertification || (state.productCertification.length > 5 && state.productCertification.length < 180) ? <h4>Product Certifications</h4> :
          <h4>Product Certifications <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
            state.productCertification.length <= 1? 'Product Certification can\'t be empty.' : state.productCertification.length <= 5 ? 'Product Certification must have min 5 characters' : state.productCertification.length > 180 ? 'Product Certification must have max 180 characters' : ''
          }</span></h4> }
          <div className="form-group">
          <FormTextArea className="buytext2 w-100 h-auto" placeholder="Add any technical certification that your product has received, ex CE, FCC, UL, RoHS etc"
              onChange={handleChange('productCertification')}
        />
        </div>
        </div>

            
            <div className="col-lg-12 ">
            <h4 style={{textAlign:'left'}}>Awards</h4>
            <div className="form-group mt-3">        
           
<BrandCard award={true} addAwards={addAwards} deleteAwards={deleteAwards} awardList={state.awards} name="BMW" img="https://cdn.ces.tech/ces/media/events-experiences-images/innovation-awards/2020/honoree.png"/>
            
{/* Autocomplete with image */}

                 </div>           
            </div>
            <div className="col-sm-12 ">
            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productWarranty" name="productWarranty" ref={register} value={state.productWarranty} /> 
          {!errors.productWarranty || (state.productWarranty.length > 5 && state.productWarranty.length < 180) ? <h4>Product Warranty</h4> : 
          <h4>Product Warranty <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
            state.productWarranty.length <= 1? 'Product Warranty can\'t be empty.' : state.productWarranty.length <= 5 ? 'Product Warranty must have min 5 characters' : state.productWarranty.length > 180 ? 'Product Warranty must have max 180 characters' : ''
          }</span> </h4> }
          <div className="form-group">
          <FormTextArea className="buytext2 w-100 h-auto"
          placeholder="Enter Warranty Details"
            onChange={handleChange('productWarranty')}
        />
        </div>
        </div>

        <div className="col-sm-12 ">
          <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productLocalisation" name="productLocalisation" ref={register} value={state.productLocalisation} /> 
          {!errors.productLocalisation || (state.productLocalisation.length > 5 && state.productLocalisation.length < 180) ? <h4>Product Localisation</h4> : 
          <h4>Product Localisation <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
            state.productLocalisation.length <= 1? 'Product Localisation can\'t be empty.' : state.productLocalisation.length <= 5 ? 'Product Localisation must have min 5 characters' : state.productLocalisation.length > 180 ? 'Product Localisation must have max 180 characters' : ''
          }</span></h4>}
          <div className="form-group">
          <FormTextArea
           className="buytext2 w-100 h-auto"
          placeholder="If your product is delivered with an app, can you specify in which languages the app is available?"
            onChange={handleChange('productLocalisation')}
        />
        </div>
        </div>
   
      
    
        
          
        
          </CardBody>
    
              {/* </Card> */}
          </Card>


{/* ---------------------------Product's Reviews------------------------------ */}


          <Card>
                <CardHeader>
                <h3 style={{textAlign:'center'}}>Product's Images, Videos and Instruction Manuals</h3>
                  <p className="text-center">Upload your product related pictures and videos. The number of pictures in connected to your Subcription Plan</p>
                  </CardHeader>
                  <CardBody>
                  
                     
            <div className="col-lg-12 ">
            <h4 style={{textAlign:'left',display:'inline-block'}}>Product Images</h4>
            <h5 style={{float:'right'}}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
            <div className="form-group mt-3">        
                <MultiUpload multiple={true} limit={5} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="productImages"/>
                {loading && <div style={{position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: '1000',
    }}><Spinner /></div>}
                 </div>           
            </div>
             
            <div className="col-lg-12 ">
            <h4 style={{textAlign:'left',display:'inline-block'}}>Product POS Displays</h4>
            <h5 style={{float:'right'}}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
            <div className="form-group mt-3">        
           
            <MultiUpload multiple={true} limit={5} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="productPos"/>
                 </div>           
            </div>
             
            <div className="col-lg-12 ">
            <h4 style={{textAlign:'left',display:'inline-block'}}>Product Packaging Images</h4>
            <h5 style={{float:'right'}}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
            <div className="form-group mt-3">        
            <MultiUpload multiple={true} limit={5} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="packagingImages"/>

                 </div>           
            </div>
             
            <div className="col-sm-12">
              <h4 style={{textAlign:'left',display:'inline-block'}}>Product Videos</h4>
            <h5 style={{float:'right'}}>(Maximum 3 Videos)</h5>
            <div className="form-group">            
                    <FormInput 
                                      id="videourl"
                                      type="text"
                                      name="videoUrl"
                                      label="Video Url"
                                      register={register}
                                      defaultValue={state.videoUrl}
                                      value={watchAll.videoUrl}
                                      error={errors.videoUrl}
                                      //onChange={handleChange('videoUrl')}
                    />
             </div>           
    </div>
    <div className="col-lg-12 ">
            <h4 style={{textAlign:'left',display:'inline-block'}}>Product's Brochures and Technical spec sheets</h4>
            <h5 style={{float:'right'}}>(Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb)</h5>
            
                <span onClick={() => {
                  if(mediaState.productBrochures.length>=5){
                    ToastsStore.error('Maximum 5 Images');
                  }else{
                    setProdImage(true);
                  }
                  }}>
               {
               !mediaState.productBrochures.length ? 
               <div className="form-group mt-3">        
               <div tabindex="0" class="MuiDropzoneArea-root">
               <div class="MuiDropzoneArea-textContainer">
                 <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                 <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                </div></div></div> 
                : 
                <PdfCard pdfList={mediaState.productBrochures} deletePdf={pdfDelete} editName={editName}/>
                }
                </span>
                
            {prodImage && <ImageLibraryMultipleUploads id={1} isOpen={prodImage} multiple={true} limit={1} acceptedFiles={['application/pdf']} getBrochuresIds={handleChangeFileSelect} input="productBrochures" aws_folderName="broucher" handleClose={()=>setProdImage(false)}  documentIds={mediaState.productBrochures.length>0?mediaState.productBrochures.map(x=>parseInt(x.documentId)):[]}/>}
                            
            </div>
   
            </CardBody>
  
</Card>
    
        {/* ---------------------------Product's Reviews------------------------------ */}


        <Card>
                <CardHeader>
                <h3 style={{textAlign:'center'}}>Product's Visibility</h3>
                  <p className="text-center">Enter Products Experts Review and Users Review</p>
                  </CardHeader>
                  <CardBody>
          
            <div className="col-lg-12">
            <h4 style={{textAlign:'left'}}>Product's Visibility</h4>
            <h5>You can set your preferences for product visibility to different user profiles</h5>
</div>
<Row>
<div className="col-lg-6  ">
            <div className="form-group">        
            <FormCheckBox
                   id="productVisibility"
                   name="productVisibility"
                   label="Show to Distributor"
                   type="checkbox"
                   checked={state.productVisibility}
                   onClick={handleChange('productVisibility')}
                   value={state.productVisibility}
                     />

                 </div>           
            </div>
            {state.productType==1 && <div className="col-lg-6  "> 
            <div className="form-group">        
            <FormCheckBox  
                   id="productVisibility1"
                   name="productVisibility"
                   label="Show to Retailer"
                   type="checkbox"
                   checked={state.productVisibility1}
                  value={state.productVisibility1}
                   onClick={handleChange('productVisibility1')}
                     />

                 </div>           
            </div>}

            {state.productType==2 && <div className="col-lg-6  "> 
            <div className="form-group">        
            <FormCheckBox  
                   id="productVisibility2"
                   name="productVisibility"
                   label="Show to Reseller"
                   type="checkbox"
                   checked={state.productVisibility2}
                  value={state.productVisibility2}
                   onClick={handleChange('productVisibility2')}
                     />

                 </div>           
            </div>}
            </Row>
        
            </CardBody>
          </Card>
            {/* // ------------------------------------------------- Trade Details------------------------------------------------- */}
            <Card> 
            <CardHeader>
                <h3 style={{textAlign:'center'}}>Trade Details</h3>
                  <p className="text-center">Enter Products awards, certification, product warranty, localization</p>
                  </CardHeader>  
                  <CardBody>    
            <Row>
            <div className="col-lg-9">
            <h4 style={{textAlign:'left'}}>Product's Attributes</h4>
            <h5>Does the Product have Different Variants / Attributes</h5>
</div>
<div className="col-lg-3  ">
            <div className="form-group">        
            <FormRadio
           name="productAttributes"
           id="productAttributes"
           className="text-sm mb-0"
           label=""
           options={radioGroupOptions3}
           control={control}
           value={state.productAttributes}
           defaultValue={state.productAttributes}
           row
           onChange={handleChange('productAttributes')}
                     />

                 </div>           
            </div>
            </Row>
           { state.productAttributes === '1' ?    
        <div className="col-lg-12">
            <div className="form-group">        
                    <FormInput 
                        id="variantDetails"
                        type="text"
                        name="variantDetails"
                        label="Variant Details *"
                        placeholder="Add variant details like colors, size, type and other"
                        register={register}
                        value={watchAll.variantDetails}
                        error={errors.variantDetails}
                    />            
            </div>
            </div> : ''}
            
            <Row>
            <div class="col-lg-9">
            <h4 style={{textAlign:'left'}}>Price/Ordering Details</h4>
            <h5>Update your product prices or ordering details</h5>
            <Row>
                  <div className="col-lg-6 pad-les  mt-3">
        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="busCurrency" name="busCurrency" ref={register} value={state.businessCurrency} /> 
        <FormControl variant="outlined" className={classes.formControl1} error={!errors.busCurrency || state.businessCurrency ? false : true}>
        <InputLabel id={!errors.busCurrency || state.businessCurrency ? 'demo-simple-select-outlined-label' : 'demo-simple-select-error-label'}>Business Currency</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          onChange={handleChange('businessCurrency')}
          label="Business Currency *"
        >
          <MenuItem value="">
            <em>Select Business Currency</em>
          </MenuItem>
          <MenuItem value={1}>Euro</MenuItem>
          <MenuItem value={2}>Dollar</MenuItem>
        </Select>
        <FormHelperText style={{color: 'red'}}>{!errors.busCurrency || state.businessCurrency ?  '' : errors.busCurrency.message }</FormHelperText>
      </FormControl>
                  </div>
                  <div className="col-lg-3 pad-les  mt-3">
                  <div className="form-group">            
                    <FormInput 
              id="MSRP *"
              type="text"
              name="msrp"
              label="MSRP *"
              register={register}
              value={watchAll.msrp}
              defaultValue={state.msrp}
              error={errors.msrp}
                    />
             </div> 
                  </div>
                  <div className="col-lg-3 pad-les  mt-3">
        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="unit" name="unit" ref={register} value={state.unit} /> 
        <FormControl variant="outlined"  className={classes.formControl} error={!errors.unit || state.unit ? false : true }>
        <InputLabel id={!errors.unit || state.unit ? 'demo-simple-select-outlined-label' : 'demo-simple-select-error-label'}>Unit</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="unit"
          className="test"
          onChange={handleChange('unit')}
          label="Unit *"
          name="unit"
          value={state.unit}
        >
          <MenuItem value={1}>Pcs</MenuItem>
          <MenuItem value={2}>Pack</MenuItem>
          <MenuItem value={3}>Gram</MenuItem>
          <MenuItem value={4}>Kgs</MenuItem>
          <MenuItem value={5}>Box</MenuItem>
        </Select>
        <FormHelperText style={{color: 'red'}}>{!errors.unit || state.unit ?  '' : errors.unit.message }</FormHelperText>
      </FormControl>
                  
                  </div>
                </Row>
    </div>
    </Row>

    <Row>
            <div class="col-lg-11">
            {/* <h4 style={{textAlign:'left'}}>Price Details</h4> */}
            <h5>Quantity Info</h5>
            <Row>
                  <div className="col-lg-4 pad-les  mt-3">
                  <Row>
                  <div className='col-lg-6 pad-less'>
                  <div className="form-group">            
                    <FormInput 
                               id="MOQ *"
                               type="text"
                               name="moq"
                               label="MOQ *"
                               onChange={handleChange('moq')}
                               register={register}
                               defaultValue={state.MOQ}
                               value={watchAll.moq}
                               error={errors.moq}
                    />
             </div> 
                    </div> 
                    <div className='col-lg-6 pad-les'>        
                    <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="moqUnit" name="moqUnit" ref={register} value={state.moqUnit} /> 
        <FormControl variant="outlined" className={classes.formControl1} error={!errors.moqUnit || state.moqUnit ? false : true}>
        <InputLabel id={!errors.moqUnit || state.moqUnit ? 'demo-simple-select-outlined-label' : 'demo-simple-select-error-label'}>Unit</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          className="test"
          onChange={handleChange('moqUnit')}
          label="Moq Unit *"
        >
          <MenuItem value={1}>Pcs</MenuItem>
          <MenuItem value={2}>Pack</MenuItem>
          <MenuItem value={3}>Gram</MenuItem>
          <MenuItem value={4}>Kgs</MenuItem>
          <MenuItem value={5}>Box</MenuItem>
        </Select>
        <FormHelperText style={{color: 'red'}}>{!errors.moqUnit || state.moqUnit ?  '' : errors.moqUnit.message }</FormHelperText>
      </FormControl>
      
      
                    </div> 
                    </Row>
                  </div>
                  <div className="col-lg-3 pad-les  mt-3">
                  <div className="form-group">            
                    <FormInput 
                                 id="No of items in Box"
                                 type="text"
                                 name="no_of_items_in_box"
                                 label="No of items in Box"
                                 register={register}
                                 defaultValue={state.no_of_items_in_box}
                                 value={watchAll.no_of_items_in_box}
                    />
             </div> 
                  </div>
                  <div className="col-lg-3 pad-les  mt-3">
                  <div className="form-group">            
                    <FormInput 
         id="Max qty for Sample orders"
         type="text"
         name="max_qty_sample"
         label="Max qty for Sample orders*"
         register={register}
         control={control}
         defaultValue={state.max_qty_for_sample}
         value={watchAll.max_qty_for_sample}
         disabled={ watchAll.no_limit }
         error={(!errors.max_qty_sample || !watchAll.no_limit) ? errors.max_qty_sample : ''}
                    />
             </div> 
                  </div>
                  <div className="col-lg-2 pad-less  mt-3">
                  <div className="form-group">            
                  <FormCheckBox
                id="visible"
                type="checkbox"
                name="no_limit"
                label="No limit"
                label1={{textAlign:"left"}}
                register={register}
                value={watchAll.no_limit}
                />
             </div> 
                  </div>
                </Row>
    </div>

    </Row>

    <Row>
            <div className="col-lg-5">
            <h4 style={{textAlign:'left'}}>Product Positioning</h4>
            <h5>How do you position this product?</h5>
</div>
<div className="col-lg-7 ">
            <div className="form-group">        
            <FormRadio
                          name="productPositioning"
                          id="productPositioning"
                          className="text-sm mb-0"
                          label=""
                          options={radioGroupOptions1}
                          control={control}
                          defaultValue={state.productPositioning}
                          value={watchAll.productPositioning}
                          row
                          onChange={handleChange('productPositioning')}
                     />

                 </div>           
            </div>
            </Row>

            <Row>
            <div className="col-lg-5">
            <h4 style={{textAlign:'left'}}>Availability</h4>
            <h5>Tell us the product availability details. You can also add comments to detail the deployment schedule in different regions</h5>
</div>
<div className="col-lg-7 ">
            <div className="form-group">        
            <FormRadio
                       name="availability"
                       id="availability"
                       className="text-sm mb-0"
                       label=""
                       options={radioGroupOptions2}
                       control={control}
                       value={state.availability}
                       row
                       onChange={handleChange('availability')}
                     />

                 </div>           
            </div>
            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="date" name="date" ref={register} value={selectedDate && selectedDate!='Invalid Date' ? selectedDate : ''} /> 
            </Row>

           {
              state.availability === 'Available From' ? 
            <Row>
            <div className="col-lg-4 mt-2">
            <div className="awd-date">
            <FormControl className={classes.margin}> 
            <MuiPickersUtilsProvider  utils={DateFnsUtils}>            
            <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date1"
          name="date1"
          defaultValue={selectedDate}
          ref={register}
          control={control}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        </MuiPickersUtilsProvider>
        <FormHelperText style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}} >{errors.date?.message}</FormHelperText>
        </FormControl>
        </div>
</div>
<div className="col-lg-8">
<div className="form-group">            
<form>
<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="availableFromText" name="availableFromText" ref={register} value={state.text_about_sample} />
{/* {(!errors.availableFromText || state.text_about_sample?.length) ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem', float:'right' }}>please enter availablility comments</span>} */}
  <textarea 
  class=" qtytext" 
  id="exampleFormControlTextarea1" 
  onChange={handleChange('text_about_sample')}
  rows="3" 
  value={state.text_about_sample}
  aria-label="empty textarea"
  placeholder="Availability Comments"
  >
  </textarea>
</form>         

      {/* <TextareaAutosize  width = "100%" /> */}
             </div>           
            </div>
            </Row> : '' }

        <div className="text-center mt-4">
            <button type="submit"  className="btn btn-primary" >Save</button>
          </div>  

          
        
          </CardBody>
          </Card>
                  
              </form>
              </Container>
          }
      </div>
    
   
  );
};

const radioGroupOptions3 = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },
 
];
const radioGroupOptions1 = [
  {
    value: "Entry",
    label: "Entry Level",
  },
  {
    value: "Mid",
    label: "Mainstream ",
  },
  {
    value: "High",
    label: "Premium",
  }, 
];
const radioGroupOptions2 = [
  {
    value: "Immediate",
    label: "Immediate",
  },
  {
    value: "Available From",
    label: "Available From",
  },
 
];

const businessTypeOptions = [
  {
    value: "1",
    label: "B2C",
  },
  {
    value: "2",
    label: "B2B",
  },
  // {
  //   value: "3",
  //   label: "Both",
  // }
];


export default AddFirstProduct;
