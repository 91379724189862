import React, { useEffect, useState } from 'react';
import { Modal, Card, CardBody, CardHeader, Form, Container, Col, Row, Table, Button } from "reactstrap";
import { userConstants } from '../../../../src/_constants';
// import video from '../../../assets/sample.mp4'
import { history } from '../../../_helpers/history';
import { commonService } from '_services/common.service';

export const PlanFeatureDialog = (props) => {
  const { url, videoModalClose, isOpen, type, planId } = props;

  const [open, Setopen] = useState(true)
  const [productLimit, SetproductLimit] = useState(true);
  const [message, Setmessage] = useState(null)


  const closeOpen = (e) => {
    e.preventDefault();
    props.closeUserView();
    Setopen(false);
  }

  const subscriptionTurn = () => {
    history.push('/admin/Subscription');
  }

  useEffect(() => {
    setMessagesForDialog();
  }, []);

  const setMessagesForDialog = async () => {

    let userFeature = JSON.parse(localStorage.getItem('CHuser'));
    let message = null;
    let productLimit = userFeature.featureList[userConstants.NO_OF_PRODUCT];

    if (props.UpgradeType == 'productAdd') {
      if (userFeature.planId == 1) {

        await commonService.getServices('Product/getProductLimitForDialog').then(res => {
          if (res.data) {
            productLimit = res.data;
            console.log('productLimit =======> ', productLimit);
          }
        });

        message = "You have reached the maximum number of products sheets included in your plan. You can upgrade to our paid plan and increased the number of product sheets to " + productLimit;
      } else {
        message = "You have reached the maximum number of products sheets included in your plan. There is no options to increase this number of product sheet in the current site version.";
      }
    } else if (props.UpgradeType == 'topSearch') {
      message = 'You current plan does not give you access to this feature. Upgrading your plan will allow you to get buyers suggestions plus many more features. ';
    } else if (props.UpgradeType == 'dashboard') {
      message = 'You current plan does not give you access to the names of buyers who have viewed your profile or products. Upgrading your plan will allow discover the names of the buyers who have viewed or saved your products plus many more features. ';
    } else if (props.UpgradeType == 'favourites') {
      message = 'You current plan does not give you access to this feature. Upgrading your plan will allow you to save buyers profiles to various favourites lists plus many more features.';
    } else if (props.UpgradeType == 'BuyerSession') {
      message = `This feature is only for Premium Plan vendors,<a href="/admin/Subscription">click here</a>  to subscribe to your Premium plan.<br/> <br/> Subscribing to a premium plan will give you access to all ChannelHub benefits <a href="/admin/Subscription">listed here</a>  and also full access to all future Buyer Insider Sessions during the course of your subscription. <br/><br/> A pay-per-meeting option is also available for the cost of 200€ per accepted & attended meeting. Send a mail to Alain Godet, <a href="mailto:algodet@channelhub.net">algodet@channelhub.net</a> and provide the name of the buyer you wish to meet. We will come back to you if there is any interest for a meeting`;
    }
    else {
      message = 'You have reached the maximum limit on your subscription plan. You can either wait for next month or upgrade your plan. Click upgrade to view the subscription options';
    }
    Setmessage(message);
  }
  return (
    <div >

      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        size="sm"
        style={{ maxWidth: '420px' }}
      >

        <div className="modal-header pb-0">


          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeOpen}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body  d-flex justify-content-center pt-0 pb-0">
          <Card >

            <CardBody>
              <div> <h2>Upgrade</h2> </div>
              <div dangerouslySetInnerHTML={{__html: message}}>
                
                {/* {message} */}
              </div>
            </CardBody>
          </Card>

        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={closeOpen}
          >
            Close
          </Button>

          <Button
            color="btn-primary"
            data-dismiss="modal"
            type="button"
            onClick={subscriptionTurn}
          >
            Upgrade
          </Button>

        </div>
      </Modal>
    </div>
  );
}
export default PlanFeatureDialog;