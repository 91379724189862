import React from 'react';
//import './styles.css';
//import themes from 'devextreme/ui/themes';

import Scheduler, { Resource } from 'devextreme-react/scheduler';

import { employees, data } from './data.js';
import DataCell from './DataCell.js';
import ResourceCell from './ResourceCell.js';
import  GuestNavbar  from "views/pages/GuestPage/GuestNavbar/GuestNavbar";
const currentDate = new Date(2021, 7, 2, 11, 30);
const groups = ['employeeID'];
const views = ['month'];

const onAppointmentAdded = (e) => {
  console.log("appointment:",e.appointmentData);
}

const Schedule =()=> {
  // render() {
    return (
      <Scheduler
        timeZone="America/Los_Angeles"
        dataSource={data}
        dataCellComponent={DataCell}
        resourceCellComponent={ResourceCell}
        groups={groups}
        views={views}
        defaultCurrentView="month"
        defaultCurrentDate={currentDate}
        height={600}
        showAllDayPanel={true}
        firstDayOfWeek={1}
        startDayHour={8}
        endDayHour={18}
        onAppointmentAdded={onAppointmentAdded}
      >
        <Resource
          label="Employee"
          fieldExpr="employeeID"
          dataSource={employees}
          allowMultiple={false}
        />
      </Scheduler>
    );
 // }
}

export default Schedule;
