
export const channelAction = {
    channelRedux,
    distributionChannelRedux
};

function channelRedux(data,type) {
        console.log("data:",data,type);
       return dispatch => {dispatch(request(data));
        function request(data) { return { type: type,data } }
       }
}

function distributionChannelRedux(data,type) {
    console.log("data:",data,type);
   return dispatch => {dispatch(request(data));
    function request(data) { return { type: type,data } }
   }
}
