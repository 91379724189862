import React, { useState, useEffect } from 'react'
import Cropper from 'react-cropper';
import "cropperjs/dist/cropper.css";
import { Button, Modal } from "reactstrap";

const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

 const ModalImageCropper = (props) => {
  const [image, setImage] = useState(props.src);
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState(null);
  const [isOpen, setIsOpen] = useState(props.isOpen);

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  useEffect(() => {
      if(cropData && cropData != '#'){
        console.log('cropped',cropData);
       props.handleChange(cropData);
      }
  }, [cropData])

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    //   console.log('cropped', cropData);
    //   props.handleChange(cropData);
    }
  };

  return (
      <Modal isOpen={isOpen}   className="modal-dialog-centered">
   
      <div className="modal-body">
        <Cropper
          // style={{ height: 400, width: "100%" }}
          initialAspectRatio={1}
          // preview=".img-preview"
          aspectRatio={props.type=='banner' ? 5.14/1 : 5/5}
          cropBoxResizable={props.type=='banner' ? false : true}
          src={image}
          viewMode={2}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          zoomOnWheel={true}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
          {/* <Cropper
          style={{ height: 400, width: "100%" }}
          zoomTo={0.5}
          initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
        /> */}

{/* <div>
      <button style={{ float: "right" }} onClick={getCropData}>
              Crop Image
            </button>
      <div
            className="img-preview"
            style={{ width: "100%", float: "left", height: "300px" ,overflow: "hidden"}}
          />
        </div>
        <img style={{ width: "100%" }} src={cropData} alt="cropped" /> */}
       
      </div>



      {/* <div className="box" style={{ width: "50%", float: "center", display: 'inline-block', padding: '10px', boxSizing: 'border-box'}}>
          <h1>Preview</h1>
          <div
            className="img-preview"
            style={{ width: "100%", height: "300px", overflow: 'hidden' }}
          />
        </div> */}
    
  
            <div className="text-center mt-4 mb-4 modal-footer">
            <button className=" btn btn-primary btn-md ml-2"  onClick={getCropData}>
              Crop Image
            </button>
            
            <button  className=" btn btn-warning btn-md ml-2"  onClick={props.handleCropClose}>
              Cancel
            </button>
            </div>
    </Modal>
  );
};

export default ModalImageCropper;

