import React from "react"
 
export const NoData= ()=> {
    return (
<div className="no-records ">
          {/* <span>No Records</span> */}
          <div className="no-img text-center">
          <img    className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")}/></div>
        </div>
    )
}
