import { authHeader } from '../_helpers';
import { LocalStorageService } from '../_services';
import axios from 'axios';
import { history } from '_helpers/history';

export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete
};

function login(username, password, apptoken) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    
    return axios.post('auth/login', {
        username: username,//email:username,
        password: password + "-user",
    },
        {
            headers: {
                'Authorization': apptoken
            }
        }).then(async res => {
            //console.log("login:", res.data);

            //check profile completion here only to avoid unnecessary call of same api
             let profile_completion_count = await axios.get('/account/checkUserStatus/' + res.data.user.channelId+'/'+res.data.user.channelTypeId);
            
            let documentUrl = res.data.user.document?.documentPath ? process.env.REACT_APP_CDN_URL + res.data.user.document.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg';
            //  localStorage.setItem('channelTypeId', res.data.user.channel.channelTypeId);
            //  localStorage.setItem('channelId', res.data.user.channelId);
            //  localStorage.setItem('contactId', res.data.user.contactId);
            //  localStorage.setItem('userId', res.data.user.userId);
            //  localStorage.setItem('firstName',  res.data.user.contact.firstName);
            //  localStorage.setItem('lastName', res.data.user.contact.lastName);
            //  localStorage.setItem('documentUrl', documentUrl);
            let user = {
                channelTypeId: res.data.user.channelTypeId,
                eventRegisterAs: res.data.user.channel.eventRegisterAs,
                channelId: res.data.user.channelId,
                contactId: res.data.user.contactId,
                userId: res.data.user.userId,
                firstName: res.data.user.firstName,
                lastName: res.data.user.lastName,
                isEvent: res.data.user.isEvent,
                businessType: +res.data.user.businessType,
                documentUrl: documentUrl,
                planId: res.data.user.planId,
                email: res.data.user.email,
                //subscriptionId: res.data.user.channel?.channelSubscription?.subscriptionId,
                subscriptionId: res.data.user.channelSubscription?.subscriptionId,
                roleId: res.data.user.roleId,
                //featureList: res.data.user.channel?.channelSubscription?.keyFeature,
                featureList: res.data.user.channelSubscription?.keyFeature,
                isAdmin: false,
                // planExpireAt: res.data.user.channel.channelSubscription?.endAt,
                planExpireAt: res.data.user.channelSubscription?.endAt,
                isAlgorithm: +res.data.user.isAlgorithm,
                suggestion_comm: +res.data.user.suggestion_comm,
                companyName: res.data.user.channel?.companyName,
                lastLogin: res.data.user.lastLogin,
                hasTravel: res.data.user.hasTravel,
                profile_completion_count:profile_completion_count.data,
                isMaster:res.data.user.isMaster,
                isOtherUser: res.data.user.channel.isOtherUser
            }

            localStorage.setItem('CHuser', JSON.stringify(user));
            localStorage.setItem('selectCHUser', null);
            //console.log('logi')
            //  if(res.data.length>0)
            return res;
            //   else
            //   {
            //   console.log("abcd");
            //   return Promise.reject('Invalid Username or password');
            //   }

        }).catch(error => {
            //console.log("error:", error.response.data[0].errors[0].message);
            return Promise.reject('invalid');
        });


    // return fetch(`/users/authenticate`, requestOptions)
    //     .then(handleResponse)
    //     .then(user => {
    //         // store user details and jwt token in local storage to keep user logged in between page refreshes
    //         localStorage.setItem('user', JSON.stringify(user));

    //         return user;
    //     });
}

async function logout() {
    // remove user from local storage to log user out
    const localStorageService = LocalStorageService.getService();
    // window.location.assign('/');
    let userId = JSON.parse(localStorage.getItem('CHuser')).userId;
    await axios.get('user/setLoggedOut/'+userId, {  headers: {
                'Authorization': authHeader()
            }}).then(async res => {});

    history.push('/');
    localStorageService.clearToken();
    // localStorage.removeItem("startAddProd");
    // localStorage.removeItem("uploadedImages");
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    console.log("responose:", response);
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}