import React, { useEffect, useState } from 'react';
import e1 from 'assets/img/Events/IFASEP/1.png';
import e2 from 'assets/img/Events/IFASEP/2.png';
import e3 from 'assets/img/Events/IFASEP/3.png';
import e4 from 'assets/img/Events/IFASEP/4.png';
import e5 from 'assets/img/Events/IFASEP/5.png';
import e6 from 'assets/img/Events/IFASEP/6.png';
import e7 from 'assets/img/Events/IFASEP/7.png';
import e8 from 'assets/img/Events/IFASEP/8.png';
import e9 from 'assets/img/Events/IFASEP/9.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { Spinner } from "views/Hoc/Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import VisibilitySensor from "react-visibility-sensor";

import { Container, Row, Col, CardBody, Card, Nav, Button, NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import { autocompleteClasses } from '@mui/material';
import { Typography } from 'antd';
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";



const EventAgenda = ({ hasPackage, eventType, eventId, contactId }) => {

    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [play, setPlay] = useState(false);
    const [mute, setMute] = useState(true);
    const [selectedSlot, setSlot] = useState([]);
    let [agenda, setAgenda] = useState([]);
    const[parentChild, setParentChid] = useState([])
    const [eventAgendaData, setEventAgendaData] = useState(null)
    const[show, setShow] = useState(false)
    useEffect(() => {
        getAgenda();
    }, [])

    const getAgenda = async () => {
        let agenda = [];
    const res =     await axios.get('channel/agenda/' + eventId)
            .then(res => {
                if(res.data == null) {
                  setLoading(false)                    
                  return
                }

                if(res.data['agenda'] != null ){
                res.data['agenda'].map((x, index) => {
                    let isMeet = true;
                    let checked = [];
                    if (x.timeslot[0] != null) {
                        x.timeslot.map(t => {
                            let slot = t.split(',');
                            checked.push({
                                time: slot[0],
                                yesChecked: res.data['contactSlot'].slotPresent.includes(+slot[1]) ? true : false,
                                noChecked: res.data['contactSlot'].slotAbsent.includes(+slot[1]) ? true : false,
                                slotId: +slot[1]
                            })
                        })
                    }
                    else {
                        isMeet = false;
                    }

                    agenda.push({
                        id: index + 1,
                        agendaId: x.agendaId,
                        date: x.date,
                        desc: x.desc,
                        time: x.fromTime + ' - ' + x.toTime,
                        utcFormat: x.utcFormat,
                        name: x.name,
                        checked: isMeet ? checked : x.hasParticipant == '1' ? [{ slotId: x.agendaId, yesChecked: res.data['contactSlot'].agendaPresent.includes(+x.agendaId) ? true : false }] : [],
                        isAttend: isMeet ? false : x.hasParticipant == '1' ? true : false,
                        slot: isMeet ? true : false,
                        url: x.imageUrl,
                        zoomUrl: x.zoomUrl,
                        recUrl: x.recUrl
                    })
                })
            }
                setLoading(false)  
               
                if(agenda.length > 0){
                    setEventAgendaData('dataPresentInDB')
                }
                setParentChid(res.data.subFunction.subFunction)
           
                var groupBy = function (xs, key) {
                    return xs.reduce(function (rv, x) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                        return rv;
                    }, {});
                };
                var groubedByTeam = groupBy(agenda, 'date')

                const arrayOfObj = Object.entries(groubedByTeam).map((e) => (e[1]));

                setRows(arrayOfObj);

                //changed @19/8
                setAgenda(arrayOfObj[0]);

                setLoading(false)
                //setRows(agenda);            
                // return agenda;
            }).catch(err => console.log("getsubject:", err));

    }


    const handleChange = (id, slotIndex, checkBoxType) => (event) => {

        setAgenda((agenda) => {
            return agenda.map(row => {
                if (row.agendaId === id && row.slot) {
                    if (checkBoxType === 'yes') {
                        row.checked[slotIndex].yesChecked = true;
                        row.checked[slotIndex].noChecked = false;
                    } else if (checkBoxType === 'no') {
                        row.checked[slotIndex].yesChecked = false;
                        row.checked[slotIndex].noChecked = true;
                    }
                }
                else if (row.agendaId === id && row.isAttend)
                    row.checked[slotIndex].yesChecked = !row.checked[slotIndex].yesChecked;



                return row;
            })

        })

    }


    const data = {
        "day1": [
            { "name": "Registration", "time": "11:00 - 18:00", "desc": "Check in, go through your confirmed meetings and meet the Connect Summit team.", "url": "https://static.wixstatic.com/media/46f52af546d411e8aea23c3a93c0a4d1.jpg/v1/crop/x_95,y_0,w_470,h_470/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Ticket%20Graphic.jpg" },
            { "name": "Opening Lunch", "time": "12:30 - 13:30", "desc": "Meet some of the delegates and prepare for the day ahead.", "url": "https://static.wixstatic.com/media/37127851f7a04ce39f2732b477f7a929.jpg/v1/crop/x_333,y_0,w_1333,h_1333/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Fresh%20Green%20Salad%20with%20Feta.jpg" },
            { "name": "Workshops & Boardrooms", "time": "13:30 - 14:30", "desc": "Hear from our content partners or join an exhibitor boardroom presentation.", "url": "https://static.wixstatic.com/media/720901_6ee0f4c2a4004140a1bd8837af9dca29~mv2.jpg/v1/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/pexels-christina-morillo-1181396.jpg" },
            { "slot": true, "name": "Pre-Scheduled 1 to 1 Meetings (6 slots)", "time": "15:00 - 18:00", "desc": "20 minute face to face meetings at the exhibitor stand or online via the Channel Summit meeting system.", "url": "https://static.wixstatic.com/media/2fd079bb88774bbe8f9ae073791c9e01.jpg/v1/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Business%20Meeting.jpg" },
            { "name": "Welcome Dinner", "time": "20:00 - 22:00", "desc": "More opportunity to network and get to know delegates attending the event. More details coming soon.", "url": "https://static.wixstatic.com/media/55aac36c7e254c9c924de42fcd23fe85.jpg/v1/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Grilled%20Antipasti%20Canapes.jpg" },
        ],
        "day2": [
            { "name": "Breakfast", "time": "08:30 - 09:30", "desc": "Time for coffee and get set up for the day ahead. Breakfast is served in your hotel.", "url": "https://static.wixstatic.com/media/11062b_27259c8e64604aedbc3ac18c6c336837~mv2.jpg/v1/crop/x_834,y_0,w_3333,h_3333/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Espressos.jpg" },
            { "name": "Pre-Scheduled 1 to 1 Meetings (6 slots)", "time": "10:00 - 13:00", "desc": "20 minute face-to-face meetings at the exhibitor stand.", "url": "https://static.wixstatic.com/media/2fd079bb88774bbe8f9ae073791c9e01.jpg/v1/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Business%20Meeting.jpg" },
            { "name": "Lunch & Optional 1 to 1 Meetings (2 slots)", "time": "13:00 - 14:00", "desc": "Enjoy some down time or arrange a working lunch.", "url": "https://static.wixstatic.com/media/37127851f7a04ce39f2732b477f7a929.jpg/v1/crop/x_333,y_0,w_1333,h_1333/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Fresh%20Green%20Salad%20with%20Feta.jpg" },
            { "name": "Pre-Scheduled 1 to 1 Meetings (6 slots)", "time": "14:00 - 18:00", "desc": "20 minute face to face meetings at the exhibitor stand. ", "url": "https://static.wixstatic.com/media/2fd079bb88774bbe8f9ae073791c9e01.jpg/v1/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Business%20Meeting.jpg" },
            { "name": "Workshops & Boardrooms", "time": "14:00 - 15:00", "desc": "Join a workshop courtesy of our content partners or take part in a vendor lead boardroom presentation. Conference programme details tbc.", "url": "https://static.wixstatic.com/media/720901_6ee0f4c2a4004140a1bd8837af9dca29~mv2.jpg/v1/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/pexels-christina-morillo-1181396.jpg" },
            { "name": "Exhibition Area closes", "time": "18:00", "desc": "Time to pack up, wind down and get ready for an evening in Monaco.", "url": "https://static.wixstatic.com/media/e93b9520ebb64688930c54da92bde45b.jpg/v1/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Peaks%20Above%20Clouds.jpg" },
            { "name": "Award Dinner & Party", "time": "20:00 - 23:00", "desc": "Location to be announced. Get ready for a memorable evening and discover the winners of the Best of the Show Awards", "url": "https://static.wixstatic.com/media/11062b_7b712db97770482ab091653c1551d8f0~mv2_d_4592_3448_s_4_2.jpg/v1/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Compass%20Pointing%20North.jpg" },

        ],
        "day3": [
            { "name": "Breakfast", "time": "08:30 - 09:30", "desc": "Time for coffee and get set up for the day ahead. Breakfast is served in your hotel.", "url": "https://static.wixstatic.com/media/11062b_27259c8e64604aedbc3ac18c6c336837~mv2.jpg/v1/crop/x_834,y_0,w_3333,h_3333/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Espressos.jpg" },
            { "name": "Pre-Scheduled 1 to 1 Meetings (4 slots)", "time": "10:00 - 12:00", "desc": "20 minute face-to-face meetings at the exhibitor stand.", "url": "https://static.wixstatic.com/media/2fd079bb88774bbe8f9ae073791c9e01.jpg/v1/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Business%20Meeting.jpg" },
            { "name": "Event closes", "time": "12:00", "desc": "Thank you for joining us and see you next year!", "url": "https://static.wixstatic.com/media/11062b_82b60c28786445678c1e7bff196b1310~mv2.jpg/v1/fill/w_160,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Airplane%20Wing.jpg" },
        ],
    }

    const [activeTab, setActiveTab] = useState(1);

    const slotList = (agendaId, isSlot) => {
        let slot = agenda.filter(row => row.agendaId === agendaId)[0].checked;

        return (
            <div style={{ "margin-top": "20px" }}>
                <ToastsContainer position="top_right" autoClose={10000} store={ToastsStore} />
                {isSlot == 2 ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Event Time</TableCell>
                                    <TableCell align="right">Yes</TableCell>
                                    <TableCell align="right">No</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {slot.map((row, i) => (
                                    <TableRow
                                        key={row.time}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.time}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Checkbox

                                                checked={row.yesChecked}
                                                color="success"
                                                onChange={handleChange(agendaId, i, 'yes')}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <Checkbox

                                                checked={row.noChecked}
                                                color="warning"
                                                onChange={handleChange(agendaId, i, 'no')}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>) : (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">

                            <TableBody>
                                {slot.map((row, i) => (
                                    <TableRow
                                        key={row.time}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {'I Will Attend'}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Checkbox

                                                checked={row.yesChecked}
                                                color={row.yesChecked ? "success" : "warning"}
                                                onChange={handleChange(agendaId, i, '')}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}


            </div>
        );
    }

    const toggle = tab => {

        if (activeTab !== tab) {
            setActiveTab(tab);
            setAgenda(rows[tab - 1]);
        }
    }

    const saveslot = async () => {

        let selected = [];

        let meet = agenda.filter(x => x.isAttend === false && x.checked.length);

        if (meet.length)
            meet.map(m => {
                m.checked.map(x => {
                    if (x.yesChecked || x.noChecked)
                        selected.push({ present: x.yesChecked ? '1' : x.noChecked ? '0' : null, agendaId: m.agendaId, slotId: x.slotId, eventId: eventId, contactId: contactId, sdate: m.date, stime: x.time, isMeet: '1' })
                })
            })

        let workshop = agenda.filter(x => x.isAttend === true && x.checked.length);

        if (workshop.length)
            workshop.map(w => {
                w.checked.map(x => {
                    if (x.yesChecked)
                        selected.push({ present: x.yesChecked ? '1' : '0', agendaId: w.agendaId, slotId: x.slotId, eventId: eventId, contactId: contactId, sdate: w.date, stime: w.time, isMeet: '0' })
                })
            })


        await axios.post('channel/settimeslot', selected)
            .then(res => {
                ToastsStore.success('Your agenda is created successfully');
                return res.data;
            }).catch(err => console.log("getsubject:", err));

    }

    const playVideo = () => {
        var myVideo = document.getElementById('myVideo');

        if (myVideo) {
            if (play)
                myVideo.play();
            else
                myVideo.pause();
        }

    }

    useEffect(() => {
        playVideo();
    }, [play])

    const unmute = () => {
        console.log('muted', mute)
        setMute(!mute);
    }

    return (
        <>
            {loading ? <Spinner /> :
      eventAgendaData == null ?   
        <Card>
      <Card id='Events'>
          <CardBody>          
              <Container>
                  <div className='d-flex justify-content-center tab-agend'>
                    There is no function
             </div>
              </Container>
            </CardBody>
            </Card>
            </Card> 
              :( 
                <Card>
                    <Card id='Events'>
                        <CardBody>
                            <Container>
                                <div className='d-flex justify-content-center tab-agend'>

                                    {/* <div className={`curs-point ${activeTab === '0' ? "active" : ""}`} onClick={() => { toggle('0'); }}>Pre Event</div> */}

                                    {rows.map((x, index) =>
                                        <div className={`curs-point ${activeTab === index + 1 ? "active" : ""}`} onClick={() => { toggle(index + 1); }}>Day {index + 1}</div>)}

                                    {/* <div className={`curs-point ${activeTab === '3' ? "active" : ""}`} onClick={() => { toggle('3'); }}>Day 2</div>
                            <div className={`curs-point ${activeTab === '4' ? "active" : ""}`} onClick={() => { toggle('4'); }}>Day 3</div> */}
                                </div>
                                {/* {activeTab == 0 &&
                            <Row>
                                <Col lg="12">
                                    <h3 className='text-uppercase'>Pre Event</h3>
                                    <Container>
                                        <Card>
                                            <CardBody>
                                                <h1>Monday, 16 May (Digital Day)</h1>
                                                <span style={{ color: "blue" }}>Keynote & Pitch-your-Product Presentation</span>
                                                <h4>09:30 - 10:00 CET: Opening Keynote: Channel Insights - 2022 & Beyond</h4>
                                                <h4>10:00 - 13:00 CET: Pitch your Product presentations</h4>
                                                <ul>
                                                    <li>Mobile & Accessories</li>
                                                    <li>Smart Home</li>
                                                    <li>Health, Sport & Wellbeing</li>
                                                    <li>Urban mobility & smart tech </li>
                                                    <li>Gaming</li>
                                                    <li>Audio</li>
                                                    <li>Networking & Connectivity</li>
                                                    <li>IT Peripherals</li>
                                                    <li>IT Accessories</li>
                                                    <li>Audio Visual</li>
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Container>
                                </Col>
                            </Row>
                        } */}
                            </Container>
                            {(activeTab != 0 && agenda) &&
                                <Row>
                                    <Col lg="12" className="agend p-4">
                                        <Container>
                                             <Card>
                                            {agenda.map(((x, index) =>{
                                                if (x.agendaId !== 16) {
                                             return( 
                                                <CardBody>
                                                            <Row>
                                                                <Col lg="3" className="p-2">
                                                                    <img
                                                                        alt="..."
                                                                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                                                        src={x.url}
                                                                        style={{ width: "200px", height: "200px", objectFit: "cover" }}
                                                                    />
                                                                </Col>
                                                                <Col lg="9" className="mt-2">
                                                                    <h2>{x.name}</h2>
                                                                    <h4>{`${x.time} ${x.utcFormat ? x.utcFormat : 'CET (UTC +2)'}`}</h4>
                                                                    <span>{x.desc}</span>



                                                                    {(x.zoomUrl && !x.recUrl) &&
                                                                        <div className='d-flex mt-2' style={{ alignItems: 'center' }}>
                                                                            <span> <i class="fas fa-video" onClick={() => window.open(x.zoomUrl, '_blank')} style={{ fontSize: "28px", color: "#0e72ed" }} ></i>
                                                                            </span>
                                                                            <span style={{ margin: "0px 10px 0 10px" }}>:</span>
                                                                            <span style={{ margin: "0px 10px 0 10px" }}><a href="#pablo" onClick={() => window.open(x.zoomUrl, '_blank')}> {x.zoomUrl} </a></span>

                                                                        </div>
                                                                    }


                                                                    {x.recUrl &&
                                                                        <div>
                                                                            {/* <span style={{ margin: "0px 10px 0 10px" }}>
                                                                               
                                                                                    <div className="VideoSecRec">
                                                                                        <video id="myVideo" type="video/mp4" src={x.recUrl} autoPlay={play} muted={mute} onClick={() => setPlay(!play)} />
                                                                                        <button id="unmuteButton" onClick={unmute}><i class={mute ? 'fas fa-volume-mute mte' : 'fas fa-volume-up unmte'}></i></button>
                                                                                        {!play && <div className="playbtnrec">
                                                                                            <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require('assets/img/guestpage/playbtn.png')} alt="Video play button" width="94" height="94" loading="lazy" onClick={() => { setPlay(true); }} />
                                                                                        </div>}
                                                                                    </div>
                                                                                
                                                                            </span> */}

                                                                            <span style={{ margin: "0px 10px 0 10px" }}>

                                                                                <div>
                                                                                    <iframe className="VideoSecRec" src={x.recUrl} allowFullScreen="true" frameborder="0" seamless="" webkitallowfullscreen="true" mozallowfullscreen="true" allow="autoplay"></iframe>
                                                                                </div>

                                                                            </span>


                                                                        </div>}



                                                                    {((x.slot == true || x.isAttend == true) && eventType == 2) && slotList(x.agendaId, x.isAttend ? 1 : 2)}
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                        <Container> 

                                            <Card>

         {parentChild.map(((y, index) => {
         if(x.agendaId != y.parentAgendaId) return
                      return( 
                        <>
                        { index == 0 &&   <Typography variant='h3'  style={{textAlign:"center",fontSize:"1.1rem", marginBottom:"1.8rem",marginTop:"1rem", color:"black"}}> Sub Function (  {   (parentChild || 0).length  } )  {show == true ? <FaAngleDown style={{cursor:"pointer"}} onClick={()=>setShow(false)}/> : <FaAngleUp style={{cursor:"pointer"}} onClick={()=>setShow(true)}/>}</Typography>}
                  { show &&
                      <Row>
                            <Col lg="3" className="p-2">
                                <img
                                    alt="..."
                                    className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                    src={y.url}
                                    style={{ width: "200px", height: "200px", objectFit: "cover" }}
                                />
                            </Col>
                            <Col lg="9" className="mt-2">
                                <h2>{y.name}</h2>
                                <h4>{`${y.time} ${y.utcFormat ? y.utcFormat : 'CET (UTC +2)'}`}</h4>
                                <span>{y.desc}</span> 
                                {(y.zoomUrl && !y.recUrl) &&
                                    <div className='d-flex mt-2' style={{ alignItems: 'center' }}>
                                        <span> <i class="fas fa-video" onClick={() => window.open(x.zoomUrl, '_blank')} style={{ fontSize: "28px", color: "#0e72ed" }} ></i>
                                        </span>
                                        <span style={{ margin: "0px 10px 0 10px" }}>:</span>
                                        <span style={{ margin: "0px 10px 0 10px" }}><a href="#pablo" onClick={() => window.open(y.zoomUrl, '_blank')}> {y.zoomUrl} </a></span>

                                    </div>
                                }


                                {y.recUrl &&
                                    <div>
                                       

                                        <span style={{ margin: "0px 10px 0 10px" }}>

                                            <div>
                                                <iframe className="VideoSecRec" src={y.recUrl} allowFullScreen="true" frameborder="0" seamless="" webkitallowfullscreen="true" mozallowfullscreen="true" allow="autoplay"></iframe>
                                            </div>

                                        </span>


                                    </div>}



                                {((y.slot == true || y.isAttend == true) && eventType == 2) && slotList(y.agendaId, y.isAttend ? 1 : 2)}
                            </Col>

                        </Row>
         }
       </>
                       )}))}
           </Card>
       </Container> 
    </Row>
 </CardBody>                                              )                                                                      } return null
          }                                                 
           ))}
</Card>
        </Container>
  
  </Col> 
</Row>
}
                            {/* {activeTab == 3 &&
                        <Row>
                            <Col lg="12" className="p-4 agend" style={{ backgroundColor: "#f5f7fc" }}>
                                <Container>
                                    <h3 className='text-uppercase' style={{ color: "#ffff" }}>DAY 2</h3>
                                    <h3 className='text-uppercase' style={{ color: "#ffff" }}>Friday, 20 May (onsite)</h3>
                                    {data.day2.map((x =>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col lg="3" className="p-2">
                                                        <img
                                                            alt="..."
                                                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                                            src={x.url}
                                                            style={{ width: "180px" }}
                                                        />
                                                    </Col>
                                                    <Col lg="9">
                                                        <h2>{x.name}</h2>
                                                        <h4>{x.time}</h4>
                                                        <span>{x.desc}</span>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    ))}
                                </Container>
                            </Col>
                        </Row>
                    }
                    {activeTab == 4 &&
                        <Row>
                            <Col lg="12" className="agend p-4">
                                <Container>
                                    <h3 className='text-uppercase' style={{ color: "#ffff" }}>DAY 3</h3>
                                    <h3 className='text-uppercase' style={{ color: "#ffff" }}>Saturday, 21 May (onsite)</h3>
                                    {data.day3.map((x =>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col lg="3" className="p-2">
                                                        <img
                                                            alt="..."
                                                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                                            src={x.url}
                                                            style={{ width: "180px" }}
                                                        />
                                                    </Col>
                                                    <Col lg="9">
                                                        <h2>{x.name}</h2>
                                                        <h4>{x.time}</h4>
                                                        <span>{x.desc}</span>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    ))}
                                </Container>
                            </Col>
                        </Row>
                    } */}

                            <Row className="justify-content-center">
                                <Button className="mt-4" onClick={() => saveslot()} color="primary" type="button">
                                    Save
                                </Button>

                            </Row>

                        </CardBody>

                    </Card>

                </Card>)}

        </>
    );
};

export default EventAgenda;