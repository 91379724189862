import React from 'react';
import YoutubeEmbed from './YoutubeEmbed';
import { Card, Col, Row } from "reactstrap";


const EventPresentationMENA= () => {
    
    return (
        <div>
            <Card>
                
                  <div className='Keynote-wrapper'>
                    <div className='Keynote'>
                <h2>Keynote: Evolution or REvolution- What retailers can expect in 2024?</h2>
                <div className='row'>
                    <div className='col-6'>
                    <img style={{width:'100%',marginBottom:'60px'}} src={"/images/Keynote.jpg"} />
                    </div>
                    <div className='col-6'>
                    <div className='Keynote side'>
    <img className='key-img' src={"/images/gf.png"} /><br/>
    <h5 style={{padding:0,fontSize:'30px',fontWeight:'600'}}>"Presented by Nicolet Piennar, MENA Customer Success Senior Manager, GfK" 
</h5>
<a className='btn btn-primary'  href={"https://insights.gfk.com/gfk-at-channel-summit-2024_mea"}  target='_blank' title='Evolution or Revolution - 2024 Expectations pdf' > Download  PDF</a>

    </div> 
                    </div>
                </div>
               
                <div>
                
                </div>
            
                </div>
                <div className='Keynote'>
                    <h3>Omnichannel Retail strategies: Why one size does not fit all?</h3>
                    <h5>Presented by Hakim Ammar, Head of Retail EEMEA, GfK</h5>
                    <p>Our global research shows that shoppers behave very differently by country so a one-size-fits-all multichannel approach simply won't deliver for you or your customers.</p>
                    <a className='btn btn-primary' href={"https://insights.gfk.com/gfk-at-channel-summit-2024_mea"} target='_blank'  title='Omnichannel Trends and Strategies 2024 pdf' > Download  PDF</a>
            
                </div>
                <div className='Keynote'>
                    <h3>Smart Home Appliances Market: Simplifying consumers’ lives and catering for their needs</h3>
                    <h5>Presented by Nicolet Pienaar, MENA Customer Success Senior Manager, GfK</h5>
                    <p>The technology in the homes has experienced an unprecedented rate of change over the past decades, and it is not over. Penetration of smart devices ranges in households from smart TVs, coffee machines, washing machines,, etc. While the promise of a smart home resonates well with consumers in general, the present reality is a collection of isolated smart devices in our homes.</p>
                    <a className='btn btn-primary'  href={"https://insights.gfk.com/gfk-at-channel-summit-2024_mea"} target='_blank'  title='Smart Home 2024 pdf' > Download  PDF</a>
            
                    
                </div>
                <div className='Keynote'>
                    <h3>KSA and Oasis of growth - Unpacking the Saudi consumer</h3>
                    <h5>Presented by Prachi Satoskar, Commercial Director KSA, GfK</h5>
                    <p>Advice on building your retail brand to support long-term success and cater to consumer needs, powered by consumer insights.</p>
                    <p>A deep understanding of consumer mindsets is critical for retailers to maximize sales in increasingly volatile, saturated markets.

</p>
<a className='btn btn-primary'  href={"https://insights.gfk.com/gfk-at-channel-summit-2024_mea"} target='_blank'  title='KSA - an oasis of growth pdf' > Download  PDF</a>
            
                </div>
                <div>
                <h3 style={{fontSize: '40px',textAlign:'center',float:'left',width:'100%'}}>Workshop sessions</h3>
<div className='Keynote side'>
    <img className='key-img' src={"/images/context.jpg"} />
    <h5>Workshop "Unveiling the Influence of AI" presentation</h5><br/>
    <a className='btn btn-primary' download="Context pdf" href={"/pdf/context.pdf"} id="downloadClick" title='Context pdf' > Download  PDF</a>
    </div>                
<div className='Keynote side'>
    <img className='key-img' src={"/images/pasca.png"} />
    <h5>Circular Economy & Retail: the Role and Value of BuyBack Programmes - Pascal Charbet</h5><br/>
    <a className='btn btn-primary' download="Pascal Charbet pdf" href={"/pdf/pascal.pdf"} id="downloadClick" title='Pascal Charbet pdf' > Download  PDF</a>
    </div>                
<div className='Keynote side'>
    <img className='key-img' src={"/images/reach.png"} />
    <h5>The Disconnect That's Holding Your Channel Sales Back - Elias Boughosn</h5><br/>
    <a className='btn btn-primary' download="Reach pdf" href={"/pdf/reach.pdf"} id="downloadClick" title='Reach pdf' > Download  PDF</a>
    </div>     
<div className='Keynote side'>
    <img className='key-img' src={"/images/university.png"} />
    <h5>Strategies for Capturing and Retaining Today's Dynamic Shopper</h5><br/>
    <a className='btn btn-primary' download="ECSF pdf" href={"/pdf/ECSF.pdf"} id="downloadClick" title='ECSF pdf' > Download  PDF</a>
    </div>     
    </div>  
    <div>
    <h3 style={{fontSize: '30px',textAlign:'center',margin: '40px',float:'left',width:'100%'}}>Retail Solutions Showcase in Partnership with Business France

​</h3>
<div className='Keynote side'>
    <img className='key-img' src={"/images/refet.png"} />
    <h5>Bleu Reflet presented by Mikaël Buffet, Co-Founder & CSO</h5><br/>
    <a className='btn btn-primary' download="Bleu Reflet pdf" href={"/pdf/Bleu.pdf"} id="downloadClick" title='Bleu Reflet pdf' > Download  PDF</a>
    </div>
<div className='Keynote side'>
    <img className='key-img' src={"/images/quividi.png"} />
    <h5><a href='https://quividi.com/'>Quividi</a> presented by Ivan Del Luro, Head of Business EMEA & Latam</h5><br/>
    <a className='btn btn-primary' download="Quividi pdf" href={"/pdf/Quividi.pdf"} id="downloadClick" title='Quividi pdf' > Download  PDF</a>
    </div>
<div className='Keynote side'>
    <img className='key-img' src={"/images/techis.png"} />
    <h5><a href='https://technis.com/'> Technis </a>  presented by Jean-Baptiste Biolay, Global Head of Marketing</h5><br/> 
   
    <a className='btn btn-primary' download="Techis pdf" href={"/pdf/Technis.pdf"} id="downloadClick" title='Techis pdf' > Download  PDF</a>
    </div>
<div className='Keynote side'>
    <img className='key-img' src={"/images/user.png"} />
    <h5><a href='https://usersloveit.com/wp/en/accueil-english/'> ULIT </a> presented by Regis Nadelwais</h5><br/>
      
    <a className='btn btn-primary' download="ULIT pdf" href={"/pdf/ulit.pdf"} id="downloadClick" title='ULIT pdf' > Download  PDF</a>
    </div>
<div className='Keynote side'>
    <img className='key-img' src={"/images/webib.png"} />
    <h5><a href='https://weblib.com/'> Weblib </a>  presented by Asad Munir, Presales Consultant</h5><br/>
     
    <a className='btn btn-primary' download="Weblib pdf" href={"/pdf/Weblib.pdf"} id="downloadClick" title='Weblib pdf' > Download  PDF</a>
    </div>
<div className='Keynote side'>
    <img className='key-img' src={"/images/epson.png"} />
    <h5>
    Epson 2023 Climate Reality Barometer Survey Findings</h5><br/>
    <a className='btn btn-primary' download="Epson pdf" href={"/pdf/epson.pdf"} id="downloadClick" title='Epson pdf' > Download  PDF</a>
    </div>
</div>         
                </div>
            </Card>
        </div>
    )
}
// YoutubeEmbed.propTypes = {
//   embedId: PropTypes.string.isRequired
// };
export default EventPresentationMENA;
