import React, { useState, useEffect } from "react";
import { Card,CardBody,CardHeader, Container,FormGroup ,Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,Col,Row,UncontrolledTooltip } from "reactstrap";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {QuillEditor,FormTextArea,FormInput,FormAutocomplete, SingleAutocompleteGrouping,FormRadio,SingleAutocomplete,FormAutocompleteGrouping,AddFieldWithPopUpAutocomplete} from "_components/FormElements/FormInput";
import MediaLibrary from "views/Hoc/Dialog/MediaLibrary";
import {TextField, FormControl} from '@material-ui/core';
import {BrandCard,ProfileCard} from "views/Hoc/BrandCard";
import { history } from '../../../../_helpers';
import { dashboardActions } from '../../../../_actions';
import { InitialprofileSchema } from '../validation';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import {useBeforeFirstRender} from '_hooks/index'
import axios from 'axios';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import {Spinner} from 'views/Hoc/Spinner';
import { commonService } from '_services/common.service';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import MultiUpload from "views/Hoc/MultiUpload";
import {PdfCard, ImageCard } from "views/Hoc/PdfCard";
import {MultiSelectImages} from 'views/Hoc/Dialog/MultiSelectImages';
import { ImageLibraryMultipleUploads } from 'views/Hoc/Dialog/ImageLibraryMultipleUploads';

var _ = require('lodash');

const Initialprofile = (props) => {
  
  let maturitylevel=false;
  const [opneadd, opneAddnew] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors, watch,formState } = useForm({      
    mode:'onBlur',  
    resolver: yupResolver(InitialprofileSchema),
  });

  const [state, setState] = useState({
    yearEstablished : '',
    areYouStartUp : '0',
    no_of_staffs : '',    
    annualTurnOver : '',
    companyRegNumber : '',
    productBrand : [],
    vatNumber : '',
    companyLogo : null,
    tweetPitch : '',
    introVideo : null,
    documentId : '',
    companyDescription : '',    
    brandValue : [],
    brandList:[],
    brandOption : [],
    productGroup:[],
    productValue : [],
    productFamilies : [],
    DistributorProfileOption:[],
    DistributorProfileValue: [],
    ChannelProfileValue:[],
    ChannelProfileOption : [],
    company_regid: null,
    isVat: null,
    groupCount:0,
    no_of_skus : null,

    linkedIn: '',
    insta: '',
    facebook: '',
    twitter: '',
    
    linkedIn_pre: 'https://www.linkedin.com/company/',
    insta_pre: 'https://www.instagram.com/',
    facebook_pre: 'https://www.facebook.com/',
    twitter_pre: 'https://twitter.com/',

    social_linkedin_id: null,
    social_instagram_id: null,
    social_twitter_id: null,
    social_facebook_id: null,

    awards: [],
    b2bList: [{id: Math.random(), value: ''}],
    specialityOptions: [],
    speciality: [],
  })

  const [mediaState, setMediaState] = useState({
    productBrochures : [],
    bannerImages : [],
    companyImages : []
  });

  const [prodImage, setProdImage] = useState(false);
  let [comImage, setComImage] = useState(false);
  let [namePopup, setNamePopup]  = useState(false);
  let [docName, setDocName]  = useState(null);
  let [docId, setDocId]  = useState(null);

  const deleteBrand = (id) =>{    
    const filteredList = state.brandList.filter((item) => item.id !== id);    
    state.brandList=filteredList;
    setState({...state, brandList:state.brandList});
   if(state.brandList.length==0)
    {
      setState({...state, brandValue: []});
      errors.Brands={message: 'Please select a Brand', type:'required'};  
    }
  }

    //localStorage
    var userData = JSON.parse(localStorage.getItem('CHuser'));

  useEffect(() => {
    (async() =>{  
    setLoading(true);

    await axios.get('setting/getInitialProfile').then(res =>{      
    state.productFamilies=res.data.productGroup;
    state.company_regid=res.data.country[0].registrationcertificate;
    state.isVat=res.data.country[0].isVat;
    if(userData?.channelTypeId==2)
      state.ChannelProfileOption=res.data.profileList;
    if(userData?.channelTypeId==4 || userData?.channelTypeId==3)
    {
    let key=['Retailer'];
    state.ChannelProfileOption = res.data.profileList.filter( i => key.includes( i.groupname ) );
    }
    if(userData?.channelTypeId==3)
    {
      let key=['Distributor'];
      state.DistributorProfileOption = res.data.distProfile.filter( i => key.includes( i.groupname ) );
    }
    if(userData?.channelTypeId==5)
    {
      let key=['Reseller'];
      state.ChannelProfileOption = res.data.profileList.filter( i => key.includes( i.groupname ) );
    }
      

    state.brandOption=res.data.productBrand;
    state.brandOption.push({name: 'Add Brand', id: 0, doc_id: '0', documentpath: 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'})
    // setState({...state, brandOption:state.brandOption});
    // setState({...state, productFamilies:state.productFamilies});
    state.specialityOptions = state.productFamilies.map(x => ({id: x.groupid, name: x.groupname}));
    state.specialityOptions = _.uniqBy(state.specialityOptions, 'id');
    // state.specialityOptions= state.specialityOptions;
    // state.ChannelProfileOption=state.ChannelProfileOption
    // state.DistributorProfileOption=state.DistributorProfileOption;
    // setState({...state, company_regid:state.company_regid});
    // setState({...state, isVat:state.isVat});
    setState({...state });

    
    
    
  }).catch(err => {
    setLoading(false);
    commonService.postService({err:err.response.data.message,reqUrl:window.location.pathname,service:'/setting/getInitialProfile'},'/error')
  });

  await axios.get('/setting/getChannelProduct').then(res =>{          
    if(res.data.productGroup.length && state.productFamilies.length){
      state.productFamilies = state.productFamilies.map(x => {
        res.data.productGroup.map(y => {
          if(x.groupid == y.groupid && x.childid == y.childid){
            x.status = true;
          }
        })
        return x;
      })
    }

    state.productValue=res.data.productGroup;
    setState({...state, productFamilies: state.productFamilies, productValue: state.productValue});
    
  }).catch(err => {
    setLoading(false);
    commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/setting/getChannelProduct'},'/error')                
  });

  setLoading(false);

    })();
    
}, []);

// useEffect(() => {  
//   if(!_.isEmpty(errors)&&formState.isSubmitted)
//   {
//     ToastsStore.error(JSON.stringify(errors));
//     commonService.postService({err:JSON.stringify(errors),reqUrl:window.location.pathname,service:'validation_err'},'/error')    
//   }
// }, [errors])


const selectedItem=(gname,check,optionName,optionValue) => {    
  let opt=[];    
  console.log("check:",check,gname);
  if(check==true)
  {    
    state[optionName].map((item) => {      

          if(item.groupname==gname||item.status==true)
          {
            item.status=check;      
            opt.push(item);
          }     
    });
  }
  if(check==false)
  {  
    console.log("check:",check);
    state[optionName].map((item) => {      
        if(item.groupname==gname&&state[optionValue].length>0)
            item.status=check;                          
        // if(state.productValue.length==0)
        //     item.status=false;
    });

    opt=state[optionValue].filter((item) => item.groupname!=gname);      
    opt.map(option => option.status=true);
  }
  
  state[optionValue]=opt;
  setState({...state, [optionValue]:state[optionValue]});    
  setState({...state, [optionName]:state[optionName]});    

  if(optionValue='ChannelProfileValue' && state.ChannelProfileValue.length==0)
        errors.relevantChannelProfiles=true;
  else if(optionValue='productValue' && state.productValue.length==0)
        errors.productCat=true;
}
  
const introVideoId = async(data)=>{
  console.log('introVideoId',data.documentId);
  setState({...state, introVideo: data.documentId});
  setLoading(true)
  let res = await commonService.postService({id:data.documentId}, '/setting/updateIntroVideo')
    .catch(err=> {
      ToastsStore.error('Something Went Wrong!');
      commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/setting/updateIntroVideo'},'/error')
    });
    if(res){
      ToastsStore.success('Intro video Saved Successfully!');
    }
    setLoading(false);
}
  
const getCompanyLogo = (resImagePath) => {
  
  setState({...state, companyLogo: resImagePath.document.documentUrl, documentId: resImagePath.documentId});

};
  

  const [stateForLogo, setStateForLogo] = useState({
    preview: null,
    src: ''
  });

  console.log('preview', stateForLogo.preview)

  const watchAll = watch(['Yearestablishment','CRN','VATN','no_of_skus', 'productVal', 'tradeUrls', 'insta', 'linkedIn', 'facebook', 'twitter', 'turnOver', 'staffCnt']);
  console.log('watchall', watchAll)


  const save = async() => {
    try
    {
    setLoading(true);
    let mLevel=[];
      console.log("state.productBrand", state.productBrand);
    if(typeId==2 && state.areYouStartUp === '1')
      mLevel.push(state.productBrand.value || state.productBrand[0].value);
    else if((typeId==3 || typeId==4 || typeId==5) && state.areYouStartUp === '1')
      state.productBrand.map(x => {
        mLevel.push(x.value);
      });
      console.log("state.productBrand", state.productBrand);
      // saving additional fields added from company profile

      let award_details=state.awards ? [].concat(state.awards.map(x => {
        if(x.isOld)
        {
          let isLifeTime = x.lifeTime==='1' || x.lifeTime==true ? '1' : '0';
          let valid_upto = x.date!=='1' ? x.date : null;
          if(!valid_upto && isLifeTime == '0')
            valid_upto = x.date;
          return {channel_award_id : x.id, details : x.award_details, issued_by : x.awardName, valid_upto : valid_upto, isLifeTime : x.lifeTime==='1' || x.lifeTime==true ? '1' : '0', image: x.awardImg ? x.awardImg : null, documentId: x.documentId ? x.documentId : null, }
        }
          return { details : x.award_details, issued_by : x.awardName, valid_upto : x.date!=='1' ? x.date : null, isLifeTime : x.lifeTime==='1' || x.lifeTime==true ? '1' : '0', image: x.awardImg ? x.awardImg : null }
      })) : [{}];
    

      let productBrochure={
        productpdf:mediaState.productBrochures.length ? [].concat(mediaState.productBrochures.map(x => {
          return {channelDocumentId: x.channelDocumentId?+x.channelDocumentId:null,documentId: +x.documentId,document: x.document.documentUrl, documentName:x.name};
        })) : [{document: 'null'}]//{image:null,documentName:null,documentDescription:null}]
      }

    let productFamilies = [];
    let uniq = _.uniq(state.productValue.map(x => +x.groupid));
    uniq.map(x => {
      productFamilies.push({id: +x});
    })
    let productCategories = [];
    state.productValue.map(x => {
      productCategories.push({
        productFamilId: +x.groupid,
        productCategoriesId: +x.childid
      })
    })

    let specialityValue = state.speciality.map(x => ({specialityId: +x.id}));

      //data to be send    

      let data = {
        yearEstablished : +watchAll.Yearestablishment,
        areYouStartUp : state.areYouStartUp,
        no_of_staffs : state.no_of_staffs,
        annualTurnOver : state.annualTurnOver,
        certificateName: state.company_regid,
        companyRegNumber : watchAll.CRN,
        maturitylevel : state.areYouStartUp=="1"?mLevel:[],        
        vatNumber : state.isVat=='1'?watchAll.VATN:null,
        companyLogo : state.documentId,
        tweetPitch : state.tweetPitch,
        introVideo : state.introVideo,
        companyDescription : state.companyDescription,
        brands : state.brandList,
        // productGroup : state.productValue.map(x => +x.groupid),
        // productCat: state.productValue.map(x => +x.childid),
        relevantChannelProfile : (typeId==4 || typeId==5)?[{profileTypeId:+state.ChannelProfileValue.childid,profileTypeClassificationId:+state.ChannelProfileValue.profiletypeclassificationid}]:state.ChannelProfileValue.map(x => {return {profileTypeId:+x.childid,profileTypeClassificationId:+x.profiletypeclassificationid} }),
        //relevantDistributorProfile : [+state.DistributorProfileValue.childid],
        no_of_skus : +watchAll.no_of_skus,
        ratingId: +props.ratingId,
        channelId:userData?.channelId,
        typeId:typeId,

        //newer fields
        award_details: award_details,
        social_linkedin_url: state.linkedIn,
        social_instagram_url: state.insta,
        social_linkedin_id: state.social_linkedin_id,
        social_googleplus_id: null,
        social_instagram_id: state.social_instagram_id,
        contact_linkedin_id: null,
        social_twitter_url: state.twitter,
        social_twitter_id: state.social_twitter_id,
        social_facebook_url: state.facebook,
        social_facebook_id: state.social_facebook_id,
        channel_document: productBrochure.productpdf,
        companyImages:mediaState.companyImages,
        productFamilies: productFamilies,
        productCategories: productCategories,
      }

      if(state.b2bList.length){
        state.b2bList = [].concat(state.b2bList.map(x => {
         
           if(x.channel_B2B_id && x.value)
             return {B2B_url: x.value, channel_B2B_id: x.channel_B2B_id}
           else if(x.channel_B2B_id && !x.value)
           {
             state.deleted_B2B.push(x.channel_B2B_id);
             return null;
           }else if(x.value && !x.channel_B2B_id)
           {
             return {B2B_url: x.value}
           }          
         
       }));
       state.b2bList = state.b2bList.filter((el)=> el!=null);
      //  data = Object.assign(data, {deleted_B2B: state.deleted_B2B.length ? [... new Set(state.deleted_B2B)] : [null]});
       if(state.b2bList.length)
       data = Object.assign(data, {B2B_url: state.b2bList});
     }

      if(typeId==3 && state.DistributorProfileValue.childname!='broadliner'){
        Object.assign(data,{relevantDistributorProfile : [{profileTypeId:+state.DistributorProfileValue.childid,profileTypeClassificationId:+state.DistributorProfileValue.profiletypeclassificationid}],
          // productGroup : state.productValue.map(x => +x.groupid),
          // productCat: state.productValue.map(x => +x.childid),
          ...specialityValue && {distributorSpeciality : specialityValue},         
        });
        
      }      
        
        

     let result=await dashboardActions.companyInfo({data}).catch(err=> {
      ToastsStore.error('Something Went Wrong!');
      console.log('initial profile error',err);
      commonService.postService({err:err.response.data.message,reqUrl:window.location.pathname,service:'updateInitialProfile'},'/error')
      setLoading(false)
    });   
     //console.log('saveresuult', result);
     if(result) 
     {
      ToastsStore.success('Saved Successfully!')
      setLoading(false);     
     }
     history.push('admin/dashboard');
    }
    catch(err)
    {        
      ToastsStore.error('Something Went Wrong!');
      console.log('initial profile error',err);
      setLoading(false);    
      commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'updateInitialProfile'},'/error');       
    }
      //console.log('save', data);
  }
  
  const handleChange = input => e => {
    console.log("add",e.target.value);  
    // if(input=='areYouStartUp'&&value=='0'){
    //   state.maturitylevel=null;
    //   setState({...state, maturitylevel:state.maturitylevel});
    // }
    // else    
    if(input == 'tradeUrls'){
      let b2b = [...state.b2bList];
      console.log('b2b', b2b, e.target.value, e.target);
      b2b[e.target.id].value = e.target.value;
      setState({...state, b2bList: b2b})
    }
    else if(input == "tweetPitch"){
      setState({...state, [input]: e.target.value.slice(0, 280)});
    }
    else if(input == "companyDescription"){
      setState({...state, [input]: e.target.value.slice(0, 2000)});
    }
    else if(input == "linkedIn" || input == "twitter" || input == "facebook" || input == "insta"){
      setState({...state, [input]: e.target.value.slice(state[input+'_pre'].length, )});
      errors[input] = false;
    }
    else if(input == 'annualTurnOver' || input == 'no_of_staffs'){
      let temp = input=='annualTurnOver' ? 'turnOver' : 'staffCnt';
      document.getElementById(temp).value=null;
      errors[temp]=null;
      setState({...state, [input]: e.target.value});
    }else if(input=='turnOver' || input == 'staffCnt'){
      let temp = input=='turnOver' ? 'annualTurnOver' : 'no_of_staffs';
      setState({...state, [temp]: e.target.value});
    }
    else{
      setState({...state, [input] : e.target.value});
    }
      
  }
  

  const handleChangeQuill1 = (content, delta , source, editor) => { 
    setState({...state, 'tweetPitch' : editor.getText()});
  }

  const handleChangeQuill2 = (content, delta , source, editor) => { 
    setState({...state, 'companyDescription' : editor.getText()});
  }

  const handleChangeSelect = (input,value) => {

    if(typeId==3&&input=='productFamily'&&state.groupCount<=3){        
      state.productFamilies.map((item) => {      
        if(value.length==0)
          item.status=false;
        else
        {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       
            
          // });      
          if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;
        }
      });
  
      value.map(option => option.status=true);
      state.productValue= value
      setState({...state, productFamilies:state.productFamilies});
      setState({...state, productValue:state.productValue});

      // state.productValue= value
      // setState({...state, productValue:state.productValue});
      if(state.productValue.length==0)
        errors.productCat=true;

      let Group=_.uniq(state.productValue.map(x => x.groupid));
      state.groupCount=Group.length;
      setState({...state,groupCount:state.groupCount});     


      
    }
    else if(typeId!=3&&input=='productFamily'){        
      state.productFamilies.map((item) => {      
        if(value.length==0)
          item.status=false;
        else
        {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       
            
          // });      
          if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;
        }
      });
  
      value.map(option => option.status=true);
      state.productValue= value
      setState({...state, productFamilies:state.productFamilies});
      setState({...state, productValue:state.productValue});

      // state.productValue= value
      // setState({...state, productValue:state.productValue});
      if(state.productValue.length==0)
        errors.productCat=true;

      // let Group=_.uniq(state.productValue.map(x => x.groupid));
      // state.groupCount=Group.length;
      setState({...state});     


      
    }
    else if(input=='relevantChannelProfile'){
      state.ChannelProfileOption.map((item) => {      
        if(value.length==0)
          item.status=false;
        else
        {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       
            
          // });      
          if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;
        }
      });

      value.map(option => option.status=true);
      state.ChannelProfileValue= value
      setState({...state, ChannelProfileOption:state.ChannelProfileOption});
      setState({...state, ChannelProfileValue:state.ChannelProfileValue});

      if(state.ChannelProfileValue.length==0)
        errors.relevantChannelProfiles=true;
      // state.ChannelProfileValue= value
      // setState({...state, ChannelProfileValue:state.ChannelProfileValue});
    }
    else if(input=='relevantRetailerProfile'){
      state.ChannelProfileValue= value
      setState({...state, ChannelProfileValue:state.ChannelProfileValue});
    }
    else if(input=='DistributorProfileValue'){
      state.DistributorProfileValue= value
      setState({...state, DistributorProfileValue:state.DistributorProfileValue});
    }   
    

    else if(input=='brands'){
      if(value.name === 'Add Brand' && value.id === 0){
        setBrandName('');
        opneAdd(true);
        return <></>;
      }
      if(value.hasOwnProperty('isNew')){
        state.brandValue=value;
      }else{
        value.isNew='0';
        state.brandValue=value;
      }
      
      setState({...state, brandValue:state.brandValue});
      let temp = state.brandValue;
      if(state.brandList.includes(temp)){
        console.log('already entered');
      }else{
        state.brandList.push(state.brandValue);
      setState({...state, brandList:state.brandList});
      }
      
      console.log("brandList:",state.brandList);
    }
    else{
      if(input=="speciality"&&value.length<=3)      
      setState({...state, [input]:value});
      else if(input!="speciality")
      setState({...state, [input]:value});

    }
    // if(input!='productFamily'&&input!='relevantChannelProfile'&& input!='relevantRetailerProfile'&&input!='brands'&&input!='DistributorProfileValue')
    //   setState({...state, [input]:[value]});
  }

  const addNewB2B = () => {
    setState({...state,  b2bList:[...state.b2bList, {id: Math.random(), value: ''}]});
  }
  
  const delB2B = (id) => {
    console.log('id', id);
    let del = state.deleted_B2B;
    state.b2bList.map((x,i) => {
      if(id == i && x.channel_B2B_id)
        del.push(x.channel_B2B_id);
    })
    let temp = state.b2bList.filter((r,i) => i !== id);
    setState({...state, b2bList: temp, deleted_B2B: del});
  }
  
  
  const opneAdd = async(input,value) => {   
  console.log('opneadd',opneadd);
  await setBrandName(input);
  opneAddnew(true);
  }  
  const closeModalPopup=()=>{
    setBrandName('');
    opneAddnew(false);
  }
  
console.log('satet', state);
//console.log('watchAll', watchAll)

const onSubmit = data => {    
if (data) {        
    console.log("data:",data);
}    
}
console.log('errrors', errors);

//maturity level single or multiple decision making code
let typeId=userData?.channelTypeId;
if(typeId==3||typeId==4||typeId==5)
  maturitylevel=true;

//company pictures

const handleChangeFileSelect = async(input, value, filename) => {
  if(input === 'productBrochures'){
    console.log('brochures');
    let temp = mediaState.productBrochures;
    let newFiles = value.filter(file => !mediaState.productBrochures.find(f => f.documentId === file.documentId));
    
    console.log('temp', temp, temp.length + newFiles.length)
    if(temp.length + newFiles.length <= 5){
      temp.push(...newFiles);
      setMediaState({...mediaState, [input]:temp});
    }
    else{
       console.log('Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb');
    }
  }else if(input === 'companyImages'){
    console.log('companyImages');
    let temp = mediaState.companyImages;
    let files=[];
    value.map(x=>{
      files.push({ channelDocumentId:parseInt(x.channelDocumentId), channelId:x.channelId, documentUrl:x.document.documentUrl, documentPath:x.document.documentPath, documentId:x.documentId })
    })
    let newFiles = files.filter(file => !mediaState.companyImages.find(f => f.documentId === file.documentId));
    
    console.log('temp', temp, temp.length + newFiles.length)
    if(temp.length + newFiles.length <= 5){
      temp.push(...newFiles);
      setMediaState({...mediaState, [input]:temp});
    }
    else{
       console.log('Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb');
    }
    console.log('mediaState',mediaState);
  }
  else{
  let res = await handleOnDrop(input, value, filename);
   if(res){
      console.log('res', res);
      setMediaState({...mediaState, [input]:value})
   };
  }
}

const handleChangeFileDelete = async(input, fileToBeRemoved) => {
  let filteredState = await mediaState[input].filter(item => item.filename != fileToBeRemoved);
  if(filteredState){
    setMediaState({...mediaState, [input]: filteredState});
    console.log('after delete', mediaState);
  }
}

//functions for image
const handleOnDrop = async(input, data, file_name) => {
  if(data){
    setLoading(true);
    console.log('datat', data);
    let file = data;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      console.log('file', file)
      console.log('reader.result' ,reader.result)
      selectImg(input, reader.result, file_name)
      //setOpen(false);          
    };
    
  }
}

const selectImg = async(input, data, file_name)=>{
  if(data.length>0){
    
    //converting base64 string to file format.
    var random = Math.random(); 
    var fileName =random +'.png';
    var file = dataURLtoFile(data, fileName);    
    const formData = new FormData();
    formData.append('file', file);

    let aws_folder = input === 'bannerImages' ? 'banner' : '' ;


    const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/'+aws_folder);
    if(resImagePath){
    if(input === 'bannerImages'){
      let temp = mediaState.bannerImages;
      temp.push({image: resImagePath.data.url, filename: file_name})
      setMediaState({...mediaState, [input]: temp});
    }
    setLoading(false);

  }else{
     loading=false;
     setLoading(loading);
  }
  
  }
  // setOpen(false);
}

console.log('errors', errors)

function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}

const openMediaLib=()=>{
  console.log('image lib');
  if(mediaState.companyImages.length>=5){
    ToastsStore.error('Maximum 5 Images');
  }else{
    setComImage(true);
  }
  }

  const deleteImg=(e)=>{
    console.log('e',e);
      // error.productPos=null;
      mediaState.companyImages=mediaState.companyImages.filter(x=>x.documentId !== e.documentId);
      if(e?.companyMediaId)
      state.deleted_companyImages.push(parseInt(e.companyMediaId));

      setMediaState({...mediaState});
      setState({...state});
      // setError({...error});
  }

  //official documents
  const pdfDelete = (data) => {
    let filteredState = mediaState.productBrochures.filter(item => {
      if(item.documentId == data.documentId && item.channelDocumentId)
      { 
        let temp = state.deletedDocuments;
        temp.push(+item.channelDocumentId);
        setState({...state, deletedDocuments: temp});
      }
      return item.documentId != data.documentId
    });
    if(filteredState){
      setMediaState({...mediaState, productBrochures: filteredState});
      console.log('after delete', mediaState);
    }
  }

  const editName=(item)=>{
    docName=item.name;
  setDocName(docName);
  docId=item.documentId;
  setDocId(docId);
  setNamePopup(true);
  }
  const docNameChange= async(docId,name)=>{
    console.log('media', mediaState.productBrochures);
    console.log(docId,name);
    mediaState.productBrochures.map(x=>{
      if(parseInt(x.documentId) === parseInt(docId))
      x.name=name;
    })
    namePopupClose();
  }
  const namePopupClose = () => {
    setNamePopup(false);
  };

  //Award
const addAwards = (data) => {
  console.log('data',data);
  let index = state.awards.findIndex((obj => obj.id == data.id));
  if(index != -1){
    if(state.awards[index].isOld)
      state.awards[index] = Object.assign(data, {documentId:state.awards[index].documentId, isOld: true});
    else
    state.awards[index] = Object.assign(data, {documentId:state.awards[index].documentId});
    setState({...state, awards: state.awards});
    console.log('updated', data);
  }
  else{
  let temp = state.awards;
  temp.push(Object.assign(data, {documentId: null}));
  setState({...state, awards: temp});
  console.log('added', data);
  }
}

const deleteAwards = (data) => {
  const filteredList = state.awards.filter((item) => {
    if(data.id == item.id && item.isOld)
      state.deletedAwards.push(item.id);
    return item.id !== data.id;
  });
  state.awards = filteredList;
  setState({...state, awards: state.awards, deletedAwards: state.deletedAwards});
}


return (
<div className="main-content">
<ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
        {loading ? <Spinner /> : 
        <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>
                        {comImage && 
              <MultiSelectImages 
                id={1} 
                isOpen={comImage}
                multiple={true}
                limit={5}
                acceptedFiles={['image/jpeg','image/png','image/gif']}
                getBrochuresIds={handleChangeFileSelect}
                input="companyImages" aws_folderName="companyImages"
                handleClose={()=>{
                  comImage=false;
                  setComImage(comImage)}}
                documentIds={mediaState?.companyImages.length>0?mediaState.companyImages.map(x=>parseInt(x.documentId)):[]} 
              />
              }
        <form noValidate onSubmit={handleSubmit(save)}>
        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="typeId" ref={register} name="typeId" value={typeId} control={control} />

              <Card    >
            
              {/* <Card> */}
                   
              <CardHeader>
                 <h3 className="text-center">Facts and Figures</h3>
                  <p className="text-center">Tell us about your company legal informations</p>
                  </CardHeader>
                  <CardBody  >
                     
              <div className="row">
           <div className="col-lg-3 col-md-4 col-sm-12 ">
            <div className="form-group">        
                    <FormInput 
                       id="Yearestablishment"
                       type="number"
                       name="Yearestablishment"
                       label="Year Established *"
                       register={register}
                       error={errors.Yearestablishment}
                       defaultValue={state.yearEstablished}
                       value={watchAll.yearEstablished} 
                       onChange={handleChange('yearEstablished')}
                    /> 
             </div>           
    </div>
    

     <div className="col-lg-6 col-md-6 col-sm-12  row ">
            <div className="form-group text-center text-sm mb-0">
             <Row>
             <div className="col-lg-6 col-md-6 col-sm-12">
            <h4>{typeId==2?"Are you a startup *":"Are you looking for start-ups?"} <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                   <UncontrolledTooltip className="handshak" delay={0} delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h4> </div>
            <div className="col-lg-6 col-md-6 col-sm-12">   
           <FormRadio
                    name="startup"
                    className="text-sm mb-0"
            
                    options={radioGroupOptions}
                    control={control}
                    row
                    
                    value={state.areYouStartUp} 
                    onChange={handleChange('areYouStartUp')}
                     /></div>   
                  </Row></div>            
            </div>

            <div className="col-lg-3 col-md-2 col-sm-12  ">
            <div className="form-group text-center text-sm mb-0">        
            {state.areYouStartUp === '1' ? (
                      <SingleAutocomplete
                      id="productBrand"
                      name={typeId==2?"productBrand":"multiProductBrand"}
                      label="Maturity Level *"
                      options={startUp}
                      placeholder="Select Maturity Level"                      
                      multiple={maturitylevel}
                      disableCloseOnSelect
                      BlurOnSelect
                      limitTags={2}                      
                      getOptionSelected={(options, value) => options.name === value.name}
                      defaultValue={state.productBrand}
                      control={control}                         
                      input='productBrand'
                      value={state.productBrand}
                      register={register}
                      errMessage={'Please Select Maturity Level'}                      
                      error={errors.productBrand }
                      handleChangeSelect={handleChangeSelect}
                      
                      />
          ) : ''}

          
                 </div>           
            </div>
     </div>
   

<div className="row" > 
 <div className="col-lg-8">
 <h4  className="text-sm mb-0" >No of Staffs *{!errors.no_of_staffs || state.no_of_staffs ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select or Enter No Of Employees</span>}</h4>
 <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="no_of_staffs" ref={register} name="no_of_staffs" value={state.no_of_staffs} control={control} />
 <div className="row" > 
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm" >
           <input className={state.no_of_staffs==='1-10' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" }
                  type="button" id="emprange" name="emprange"  
                  value="1-10" onClick={handleChange('no_of_staffs')} />
    </div>
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm"  >
        <input className={state.no_of_staffs==='11-15' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" } 
                  type="button" id="emprange" name="emprange" 
                  value="11-15" onClick={handleChange('no_of_staffs')} />
    </div> 
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm" >
        <input className={state.no_of_staffs==='51-100' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" } 
                  type="button" id="emprange" name="emprange" 
                  value="51-100" onClick={handleChange('no_of_staffs')} />
    </div>
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm" >
         <input className={state.no_of_staffs==='100-Above' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" }
                  type="button" id="emprange" name="emprange" 
                  value="100-Above" onClick={handleChange('no_of_staffs')} />
    </div>
    </div> 
      </div>
    <div className="col-lg-4 col-sm-12 mt-4 "> 
    <div className="form-group  mt-2"> 
                     
                     <FormInput 
                         id="staffCnt"
                         type="number"
                         name="staffCnt"
                         label="Enter Custom Value *"
                         placeholder="Enter Your Value"
                         InputProps={{
                          startAdornment: <InputAdornment position="start"></InputAdornment>
                        }}
                        defaultValue={state.no_of_staffs}
                        value={watchAll.staffCnt}
                        onChange={handleChange('staffCnt')}
                        control={control}
                        register={register}
                        error={errors.staffCnt}
                     />
              </div>
              </div>
          </div>

<div className="row ">
<div className="col-lg-8 col-md-8">
<h4 className="text-sm mb-0">Annual Turnover *{!errors.annualTurnOver || state.annualTurnOver ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select or Enter Annual Turnover</span>}</h4>   
<input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="annualTurnOver" name="annualTurnOver" ref={register} value={state.annualTurnOver} /> 
<div className="row "> 
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
        <input className={state.annualTurnOver==='<1M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}                   type="button" id="emprange" name="emprange"
                  value="<1M€"  onClick={handleChange('annualTurnOver')}/></div>
  <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
        <input className={state.annualTurnOver==='1-10M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} 
                  type="button" id="emprange" name="emprange"
                  value="1-10M€"  onClick={handleChange('annualTurnOver')}/></div>
  <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm  ">
        <input className={state.annualTurnOver==='10-100M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} 
                  type="button" id="emprange" name="emprange"
                  value="10-100M€" onClick={handleChange('annualTurnOver')}/></div>
 <div className="col-lg-3 col-md-3 col-xs-3 mt-3 pl-4 text-center res-sm  ">
        <input className={state.annualTurnOver==='>100M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} 
                  type="button" id="emprange" name="emprange"
                  value=">100M€" onClick={handleChange('annualTurnOver')}/></div>
   </div>
    </div>

  
  
                <div className="col-lg-4 col-md-4 col-xs-3 mt-4 ">
         <div className="form-group "> 
                     
                     <FormInput 
                         id="turnOver"
                         type="number"
                         name="turnOver"
                         label="Enter Value in M€ *"
                         InputProps={{
                           startAdornment: <InputAdornment position="start">M€</InputAdornment>
                         }}
                         value={watchAll.turnOver}
                         defaultValue={state.annualTurnOver}
                         onChange={handleChange('turnOver')}
                         error={errors.turnOver}
                         register={register}
                         control={control}
                     />
              </div>
              </div>
                </div>
            

<div className="row mt-3"> 
    <div className="col-sm-6 mt-3">
            <div className="form-group">            

           <label className="form-control-label" htmlFor="">
          
           </label>
           <InputGroup className="reg" >
             <InputGroupAddon addonType="prepend">
             <InputGroupText>{state.company_regid}</InputGroupText>
             </InputGroupAddon>
             <FormInput  className="reg1"
                   id="CRN"
                   type="text"
                   name="CRN"
                   label="Company registration number *"
                   register={register}
                   error={errors.CRN}
                   defaultValue={state.companyRegNumber}
                   value={watchAll.CRN}
                   onChange={handleChange('companyRegNumber')}
             />
           </InputGroup>
       
             </div>  

                
  
               </div>
    <div className="col-sm-6 mt-3">
    <div className="form-group">            

<label className="form-control-label" htmlFor="">
<input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="isVat" ref={register} name="isVat" value={state.isVat} control={control} />
</label>
{state.isVat=='1' && (
<InputGroup  className="reg">
  <InputGroupAddon addonType="prepend">
    <InputGroupText>VAT</InputGroupText>
  </InputGroupAddon>
  <FormInput
  className="reg2"
  id="VATN"
  type="text"
  placeholder="Only for EU companies"
  name="VATN"
  label="VAT number"
  register={register}
  error={errors.VATN}
  defaultValue={state.vatNumber}
  value={watchAll.VATN}
  onChange={handleChange('vatNumber')}
  />
</InputGroup> )}

  </div>  
    </div>
</div>
</CardBody>
</Card>
         


      {/* ---------------------------Marketing information------------------------------           */}
      <Card>
      

      <CardHeader className="col-lg-12 justify-content-center">
                  <h3 className="text-center">Marketing Information</h3>
                  <p className="text-center">Introduce you to your audience</p>
                  </CardHeader>
          {/* <CardBody className="Profad"> */}
                 
          <CardBody >      
<div className="row"> 
    <div className="col-sm-12">
    <div className="row">
    <div className="col-sm-6">
    <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} /> 
    <h4>Company Logo *{!errors.companyLogo || state.companyLogo ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select Company Logo</span>}</h4>
        <div>
       
       <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='CompanyLogo' id={1} size={1}
               img={state.companyLogo ? state.companyLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}/>
   </div>
      
        

        

    </div>
    <div className="col-sm-6 mt-4">
        <span>Accepts Jpeg / Jpg / Png Only</span>
        <br></br>
        <span>(Max size allowed 200 Kb)</span>
        <span className="d-block">(* Image resolution should be 200 * 200)</span>
        </div> 
         </div>
                  
    </div>
     
</div>
<div className="row"> 
                <div className="col-sm-12 mt-4 ">
                <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="tweetPitch" name="tweetPitch" ref={register} value={state.tweetPitch} /> 
                {!errors.tweetPitch || (state.tweetPitch.length > 5 && state.tweetPitch.length < 280) ? <h4 style={{fontSize:'1rem'}}>Your Company - Tweet Pitch *
                {state.tweetPitch?.length>0 && <span style={{color:state.tweetPitch?.length>280?"red":"#9ea2a5"}}> remaining characters  {280-state.tweetPitch.length}</span> }
                </h4> :
    <h4>Your Company - Tweet Pitch * <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
                  state.tweetPitch.length <= 1? 'TweetPitch can\'t be empty.' : state.tweetPitch.length <= 5 ? 'TweetPitch must have min 5 characters' : state.tweetPitch.length > 280 ? 'TweetPitch must have max 280 characters' : ''
                  }</span></h4> 
                }
          <div className="form-group">
          <FormTextArea  className="buytext2 w-100 h-auto" placeholder="Tell us about your company background and vision -max 280 characters *" onChange={handleChange('tweetPitch')} value={state.tweetPitch} />
        </div>
        </div>
</div>
<div className="row"> 
         <div className="col-sm-12 ">
         <h4 style={{fontSize:'1rem'}}>Add an introduction video (90 seconds)</h4>
          <div className="form-group">
          <form action="/file-upload" className="dropzone">
  <div className="fallback">
  <MediaLibrary text="Upload Intro Video" introVideoId={introVideoId} id='1' size={2}/>
  </div>
</form>
        </div>
        </div>
</div>

<div className="row"> 
       
                  <div className="col-sm-12 ">
          <div className="form-group">
            <h4>Add your company description (2000 characters)
            {state.companyDescription?.length>0 && <span style={{color:state.companyDescription?.length>2000?"red":"#9ea2a5"}}> remaining characters  {2000-state.companyDescription.length}</span> }
            </h4>
          <FormTextArea  className="buytext2 w-100"
          placeholder="Tell more about your company" onChange={handleChange('companyDescription')}
          register={register} name="quill" id="quill" value={state.companyDescription}
        />
        </div>
        </div>
</div>

<div className="col-lg-12">
            <h4 style={{textAlign:'left',display:'inline-block'}}>Add Company Pictures</h4>
            <h5 style={{float:'right'}}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png, File Size Should Not Exceed 5 mb)</h5>
            {/* This below div is just used to inherit mui-dropzone css classes */}
            <div style={{display: 'none'}}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="companyImages"/></div>
            <span onClick={ openMediaLib }>
               {
               !mediaState.companyImages.length ?
               <div className="form-group mt-3">
               <div tabindex="0" class="MuiDropzoneArea-root">
               <div class="MuiDropzoneArea-textContainer">
                 <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                 <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                </div></div></div> 
                :
                <ImageCard list={mediaState.companyImages} deleteImg={deleteImg} />
                }
                </span>     
</div>


<div className="col-lg-12">
            <h4 style={{textAlign:'left',display:'inline-block'}}>Add your Corporate Documents</h4>
            <h5 style={{float:'right'}}>(Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb)</h5>
            {/* This below div is just used to inherit mui-dropzone css classes */}
            <div style={{display: 'none'}}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="bannerImages"/></div>
            <span onClick={() => {
                  if(mediaState.productBrochures.length>=5){
                    ToastsStore.error('Maximum 5 Images');
                  }else{
                    setProdImage(true);
                  }
                  }}>
               {
               !mediaState.productBrochures.length ?
               <div className="form-group mt-3">
               <div tabindex="0" class="MuiDropzoneArea-root">
               <div class="MuiDropzoneArea-textContainer">
                 <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                 <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                </div></div></div> 
                : 
                <PdfCard pdfList={mediaState.productBrochures} deletePdf={pdfDelete} editName={editName} />
                }
                </span>
                
            {prodImage && <ImageLibraryMultipleUploads id={1} isOpen={prodImage} multiple={true} limit={1} acceptedFiles={['application/pdf']} getBrochuresIds={handleChangeFileSelect} input="productBrochures" aws_folderName="broucher" handleClose={()=>setProdImage(false)} documentIds={mediaState.productBrochures.length>0?mediaState.productBrochures.map(x=>parseInt(x.documentId)):[]} />}
                            
</div>



  <h3 className="text-center">Awards</h3>
  <p className="text-center">Raise your profile by adding your Awards.</p>


  <div className="col-lg-12 ">          
  <BrandCard award={true} addAwards={addAwards} deleteAwards={deleteAwards} awardList={state.awards} name="BMW" img="https://cdn.ces.tech/ces/media/events-experiences-images/innovation-awards/2020/honoree.png"/>
</div>


  <h3 className="text-center">Social Links</h3>
  <p className="text-center">Add your company’s social profiles like Company Website URL, linkedin, Instragram Profile Pages.</p>

    <Row>
      <Col lg="12">
      <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="linkedIn" ref={register} name="linkedIn" value={state.linkedIn} defaultValue={state.linkedIn} control={control} />

      <FormInput className="comp-name mt-4" id="outlined-search" name="linkedIn1" label="Linkedin URL" type="text" variant="outlined" value={state.linkedIn_pre+state.linkedIn}
      onChange={handleChange('linkedIn')}
      // register={register}
      error={!(/^(?=[a-zA-Z0-9._-]{0,100}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.linkedIn)) && state.linkedIn ? {message:'LinkedIn url can have 0-100 characters.', required: true} : errors.linkedIn}
      defaultValue={state.linkedIn_pre+state.linkedIn}
      />
      </Col>
    </Row>

    <Row>
      <Col lg="12">
      <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="twitter" ref={register} name="twitter" value={state.twitter} defaultValue={state.twitter} control={control} />

      <FormInput className="comp-name mt-4" id="outlined-search" name="twitter1" label="Twitter URL" type="text" variant="outlined" value={state.twitter_pre+state.twitter}
      onChange={handleChange('twitter')}
      // register={register}
      error={!(/^(?=[a-zA-Z0-9._-]{0,15}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.twitter)) && state.twitter ? {message:'Twitter url can have 0-15 characters.', required: true} : errors.twitter}
      defaultValue={state.twitter}
      />
      </Col>
    </Row>

    <Row>
      <Col lg="12">
      <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="insta" ref={register} name="insta" value={state.insta} defaultValue={state.insta} control={control} />

      <FormInput className="comp-name mt-4" id="outlined-search" name="insta1" label="Instagram URL" type="text" variant="outlined" value={state.insta_pre+state.insta}
      onChange={handleChange('insta')}
      // register={register}
      error={!(/^(?=[a-zA-Z0-9._-]{0,30}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.insta)) && state.insta ? {message:'Instagram url can have 0-30 characters.', required: true} : errors.insta}
      defaultValue={state.insta}
      />
      </Col>
    </Row>

    <Row>
      <Col lg="12">
      <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="facebook" ref={register} name="facebook" value={state.facebook} defaultValue={state.facebook} control={control} />

      <FormInput className="comp-name mt-4" id="outlined-search" name="facebook1" label="Facebook URL" type="text" variant="outlined" value={state.facebook_pre+state.facebook}
      onChange={handleChange('facebook')}
      // register={register}
      error={!(/^(?=[a-zA-Z0-9._-]{0,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.facebook)) && state.facebook ? {message:'Facebook url can have 0-50 characters.', required: true} : errors.facebook}
      defaultValue={state.facebook}
      />
      </Col>
    </Row>
    {/* <Row>
      <Col lg="11">
      <FormInput className="comp-name mt-4" id="outlined-search" name="tradeUrls" label="Other B2B or Trade Portals Website URLs" type="text" variant="outlined" value={state.tradeUrls}
      onChange={handleChange('tradeUrls')}
      register={register}
      error={errors.tradeUrls}
      defaultValue={state.tradeUrls}
      id='1'
      />
      </Col>
      <Col lg="1">
        <div className="faplus">
      <i  class="fa fa-plus-circle" onClick={addNewB2B}></i>
      </div>
      </Col>
    </Row> */}

    {state.b2bList && state.b2bList.map((x,i) => <Row>
      <Col lg="11">
      <FormInput className="comp-name mt-4" id={i} name={`tradeUrls[${i}].value`} label="Other B2B or Trade Portals Website URLs" type="text" variant="outlined" 
      value={watchAll[`tradeUrls[${i}].value`]}
      onChange={handleChange('tradeUrls')}
      register={register}
      error={errors.tradeUrls ? errors.tradeUrls[i] ? errors.tradeUrls[i].value : '' : ''}
      defaultValue={x.value}
      />
      </Col>
      {i == 0 ? <Col lg="1">
        <div className="faplus">
      <i  class="fa fa-plus-circle" onClick={addNewB2B}></i>
      </div>
      </Col> : 
      <Col lg="1">
        <div className="faminus">
        <i class="fa fa-minus-circle " onClick={() => delB2B(i)}></i>
      </div>
      </Col>}
    </Row>)}
  

  </CardBody>
</Card>


                {/* ---------------------------Trade information information------------------------------           */}
                <Card>
                <CardHeader>
                <h3 className="text-center">Trade Information</h3>
                  <p className="text-center">Key informations for showing matching</p>
                  </CardHeader>
                  <CardBody>

{typeId==3 && (
<div className="col-lg-12 ">
                  <h4 >{typeId==3 && 'Distributor Profile and speciality *'} <span id='algorithm1'><i class="far fa-handshake didy"></i></span>
                   <UncontrolledTooltip className="handshak" delay={0} delay={0} placement="bottom" target="algorithm1">This field is included in the matching algorithm</UncontrolledTooltip></h4>
            <div className="form-group">        
            <SingleAutocompleteGrouping
              id="DistributorProfiles"
              name="DistributorProfiles"                        
              label="Distributor Profiles * "                            
              multiple={false}
              Options={state.DistributorProfileOption}
              placeholder="Channel Profiles"                        
              input='DistributorProfileValue'              
              errMessage={'Please Select Distributor Profile'}
              error={errors.DistributorProfiles }
              // value={state.DistributorProfileValue}
              // defaultValue={state.DistributorProfileValue}
              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
              />
            
                 </div>           
            </div>                      
        )}

<input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productVal" name="productVal" ref={register} value={state.DistributorProfileValue.childname} />         

{(typeId==3 && Object.keys(state.DistributorProfileValue).length != 0 && state.DistributorProfileValue.childname!='broadliner') && (
 <div className="col-lg-12 ">
                 <h4 >Speciality * <span id='algorithm2'><i class="far fa-handshake didy"></i></span>
                   <UncontrolledTooltip className="handshak" delay={0} delay={0} placement="bottom" target="algorithm2">This field is included in the matching algorithm</UncontrolledTooltip></h4>
           <div className="form-group">        

         <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="speciality" name="speciality" ref={register} value={state.speciality.length?state.speciality[0].name:''} /> 
         <FormControl variant="outlined" style={{width:"100%"}}>
                 <FormAutocomplete
                       id="speciality1"
                       name="speciality1"
                       label="Search Speciality *"
                       options={state.specialityOptions}
                       placeholder="Select Speciality"
                       input='speciality'
                       errMessage={'Please Select a Speciality'}
                       error={!errors.speciality || state.speciality.length ?false:true}
                       value={state.speciality}
                       defaultValue={state.speciality}
                       handleChangeSelect={handleChangeSelect}
                       control={control}                 
                       multiple
                       disableCloseOnSelect
                       blurOnSelect
                       getOptionSelected={(options, value) => options.name === value.name}
                       />
           </FormControl>
           </div>           
           </div>                      
        )} 



<div className="col-lg-12 ">
                  <h4 >Select Product Families * <span id='algorithm3'><i class="far fa-handshake didy"></i></span>
                   <UncontrolledTooltip className="handshak" delay={0} delay={0} placement="bottom" target="algorithm3">This field is included in the matching algorithm</UncontrolledTooltip></h4>
            <div className="form-group">                  
          <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productCat" name="productCat" ref={register} value={state.productValue.length?state.productValue[0].childname:''} /> 
                  <FormAutocompleteGrouping
                        id="ProductGroup"
                        name="ProductGroup"
                        label="Product Families are you selling *"
                        Options={state.productFamilies}
                        placeholder="Select Product Families"
                        input='productFamily'
                        errMessage={'Please Select a Product'}
                        error={!errors.productCat || state.productValue.length ?false:true}
                        //error={errors.ProductGroup}
                        //defaultValue={productFamilies}
                        value={state.productValue}

                        optionName='productFamilies'
                        optionValue='productValue'
                        defaultValue={state.productValue}
                        selectedItem={selectedItem}

                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}                        
                        />
            </div>           
            </div>                      
        

     
          {(typeId==2 || typeId==3) && (
            
            <div className="col-lg-12 ">
            <h4 >Brands *</h4>
            <div className="form-group mt-3">        
           
            {state.brandList[0] && <BrandCard  name="BMW" deleteBrand={deleteBrand} brandList={state.brandList}  img={"http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"}/>}

            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="brandList" name="brandList" ref={register} value={state.brandList[0] ? 'data' : ''} /> 
{/* Autocomplete with image */}
<AddFieldWithPopUpAutocomplete
        limitTags={2}
        name="Brands"
        id="Brands"
        options={state.brandOption}
        defaultValue={state.brandValue}
        value={state.brandValue}
        getOptionLabel={option => option.name}
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src={option.documentpath} />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Brands" placeholder="Brands" />
        )}
        label="Brands *"
        placeholder="Brands"
        input="brands"
        control={control}
        register={register}
        errMessage={'Please Select a Brand'}
        error={errors.Brands&&!state.brandList[0]? errors.Brands : (errors.brandList && !state.brandList[0]) ? true : false}
        handleChangeSelect={handleChangeSelect}
        opneAdd={opneAdd}
      />
      {brandName && <AddBrand aws_folderName="brandlogo" opneadd={opneadd} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect}/>}

                 </div>           
            </div>

            )}

        
            {(typeId==2 || typeId==3) && (
                  <div className="col-lg-12 ">
                  <h4 >Relevant Channel Profiles * </h4>
            <div className="form-group">        
            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="relevantChannelProfiles" name="relevantChannelProfiles" ref={register} value={state.ChannelProfileValue.length?state.ChannelProfileValue[0].childname:''} /> 
            <FormAutocompleteGrouping
              id="relevantProfiles"
              name="relevantProfiles"
              label="Channel Profiles * "
              multiple={true}
              Options={state.ChannelProfileOption}
              placeholder="Channel Profiles"                        
              input='relevantChannelProfile'              
              errMessage={'Please Select Channel Profile'}
              error={!errors.relevantChannelProfiles || state.ChannelProfileValue.length ?false:true}
              value={state.ChannelProfileValue}                            

              optionName='ChannelProfileOption'
              optionValue='ChannelProfileValue'
              defaultValue={state.ChannelProfileValue}
              selectedItem={selectedItem}

              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
              //defaultValue={state.ChannelProfileValue}
              />
            
                 </div>           
            </div>
            )}


            {(typeId==4 || typeId==5) && (
            <div className="col-lg-12 ">

          <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="relevantChannelProfiles" name="relevantChannelProfiles" ref={register} value={state.ChannelProfileValue.length?state.ChannelProfileValue[0].childname:''} /> 

                  <h4 >{typeId==4 ? "Retailer Profiles" : "Reseller Profiles"} * <span id='algorithm4'><i class="far fa-handshake didy"></i></span>
                   <UncontrolledTooltip className="handshak" delay={0} delay={0} placement="bottom" target="algorithm4">This field is included in the matching algorithm</UncontrolledTooltip></h4>

            <div className="form-group">        
            <SingleAutocompleteGrouping
              id="relevantProfiles"
              name="relevantRetailerProfiles"
              label={typeId==4 ? "Retailer Profiles *" : "Reseller Profiles *"}
              multiple={false}
              Options={state.ChannelProfileOption}
              placeholder="Channel Profiles"                        
              input='relevantRetailerProfile'              
              errMessage={typeId==4  ? "Please Select Retailer Profiles" : "Please Select Reseller Profiles"}
              error={errors.relevantRetailerProfiles }
              // value={state.ChannelProfileValue}                    
              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
              //defaultValue={state.ChannelProfileValue}
              />
            
                 </div>           
            </div>

            )}

                

            {(typeId==2 || typeId==4) && (
            <div className="col-lg-12">
            <h4>{typeId==2?"No of SKU's":"No of Store's"} *</h4>
                  <h5  >{typeId==2?'Enter the number of products that you handle':'Enter the number of stores that you handle'}</h5>
            <div className="form-group">    
          <FormInput
              id="No of SKU's"
              type="number"
              name="no_of_skus"
              label={typeId==2?"No of SKU's *":"No of Store's *"}
              InputProps={{
                inputProps: { 
                    max: 100, min: 10 
                }
                }}
              value={state.no_of_skus}
              onChange={handleChange('no_of_skus')}
              register={register}
              defaultValue={state.no_of_skus}
              value={watchAll.no_of_skus}
              error={errors.no_of_skus}
          /> 
        </div>
        </div>

)}

   
        <div className="text-center">
        <button type="submit" className="btn btn-primary" >Save</button> {/*onClick={save}*/}
          </div>  
 
    
        
          
        
          </CardBody>
    
              {/* </Card> */}
          </Card>
          </form>
              </Container>
              }
      </div>
    
   
  );
};

const startUp = [
  {name : 'Prototype', id: 1, value: 'PROTOTYPE'},
  {name : 'Shipping to first backers', id: 2, value: 'S_BACKER'},
  {name : 'Shipping in volume', id: 3, value: 'S_VOLUME'},
];

const radioGroupOptions = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },
 
];


export default Initialprofile;

