import React, { useState, Component } from "react";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import axios from 'axios';
let confirmaionPrompt = '';
class PaymentConfirmation extends Component {
  
  constructor(props) {
    super(props);
    let confirmaionPrompt = 'Complated Successfully'

    if(this.props.match.params.id == 1){
       this.confirmaionPrompt = '<div>Complated Successfully<div>'
    } else {
      this.confirmaionPrompt = 'Error In subscription process'
    }
   }
    render() {
        return (
            <div className="main-content">
              {this.confirmaionPrompt}
            </div>
        );
    }

  
};



export default PaymentConfirmation;