import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import {Card,CardBody,Row,Col,Container,UncontrolledTooltip,CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { commonService } from '_services/common.service';
import { history } from '../../../../_helpers';
import axios from 'axios';
const MatchingBrands = (props) => {

    const [slide, setSlide] = useState(0);
    const [dataLength, setDataLength ] = useState(0);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: current => setSlide(current)
      };

    const {title, channelId, handleMatching} = props;

      const [compData, setCompData] = useState([]);
      const [loading, setLoading] = useState(false);

      let [page, setPage] = useState(0);
      let [rowsPerPage, setRowsPerPage] = useState(12);
      let [total, setTotal] = useState(0);

      useEffect(() => {
        getMatchingVendors();
      },[]);

      useEffect(() => {
        if(slide == compData.length-1)
          getMatchingVendors();
      }, [slide])
  
      // console.log('slide', slide, compData.length);

      const channelDetailView = (id) => {
        const win = window.open('/channelDetailView/'+id);
        // history.push('productDetailView/'+x.productSKU)
        win.focus();
      }

      const getMatchingVendors = async() => {
        setLoading(true);
        let postData={
        //   filter:{family: familyId},
          // order:sort?sort:'asc',
          // search:search?search.name:null,
          channelId: channelId,
          offset: dataLength,//compData.length, //page * rowsPerPage,
          // // offset:1,
        //   orderby:'product',
          limit:rowsPerPage,
        }
    
        let res = await commonService.postService(postData, 'guest/matchingBrands/').catch(err => setLoading(false) );
        if(res.data.result.length){
          console.log('aggre', res.data.result.map(x => x));
          if(res.data.result.length%4==0)
          setCompData(prevState => ([...prevState, ...res.data.result]));
          else{
            setCompData(prevState => ([...prevState, {value: res.data.result}]));
          }
          // setCompData(res.data.result);
          let val = [];
          setDataLength(prev => prev+res.data.result.length);

        //   res.data.result.map(x => console.log('xxx', x));

          res.data.result.map((x,i)=>{
            console.log('loop')
            val.push(x);
            console.log((i+1));
            if((i+1)%6===0){
              compData.push({value:val});
              setCompData(compData);
              val=[];
            }
          })
          
          if(compData.length)
          handleMatching(compData);
          setLoading(false);
        }else{
          handleMatching(compData);
          setLoading(false);
        }

        



      }

    // console.log('matchingBrands', compData)

    return (
            <div>
              <Card className="mb-0 p-3">
                <CardBody>
                <div className="p-1 d-flex justify-content-center">
  <h2 className="d-inline-block">{title}</h2>
  {/* <h2 className="ProductCount midle text-muted ml-5"> See more Products (25890)</h2> */}
  </div>
        <Slider {...settings}>
        {!!compData.length>0 && compData.map(item=>(
      <div className='prod-slide bgBack p-4'>
    
        <div className="d-flex">
          {item.value?.map(x=>(
                    <div className="wid33 mr-2">
                    <div className="ListContainerForSlider heightfix">
                    {/* <Link onClick={(e) => {e.preventDefault();history.push('/vendorList')}} class="nav-item nav-link"> */}
                    {/* <Link to="/productList"> */}

                    <div className='prodheight' onClick={() => {channelDetailView(x.id)}}>

                      {/* it is for matching brands redirection to product list <div className='prodheight' onClick={() => {history.push({pathname: '/productList', state: {brandId : {id: x.id, name: x.name}}})}}> */}
                      {/* <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={x?.image?x.image:"https://d35w8j22j5uly8.cloudfront.net/document/product/packing/0fz2iZqt_1614278863657_1614278899685.png"}/> */}
                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={x.logo ? process.env.REACT_APP_CDN_URL+x.logo : process.env.REACT_APP_CDN_URL+'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
          onError={e=>e.target.src=process.env.REACT_APP_CDN_URL+'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                      </div>
                      <h2 className="d-inline-block"> {x?.name} </h2>
                      {/* <p className="d-inline-block ml-2">({x?.total})</p> */}
                      {/* </Link> */}
                    </div>
                  </div>
          ))}
        </div>
      </div>
    ))}  
        </Slider>
        
        </CardBody>
              </Card>
        </div>
    );
};

export default MatchingBrands;