
export const subscriptionAction = {
    subscriptionRedux
};

function subscriptionRedux(data,type) {
        console.log("data:",data,type);
       return dispatch => {dispatch(request(data));
        function request(data) { return { type: type,data } }
       }
}
