import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { Row, Col } from "reactstrap";
import Contact from './Contact-List';
import CompanyListingFilters from "./CompanyListingFilter";
import ListingFilter from "./Listingfilter";
const ContactWrapper = (props) => {
  const { activeTab, search } = props;
  const [filterValueState, setFilterValue] = useState({Speciality:[], Brand:[], Retailer_Profile:[], Company_Origin:[], Annual_Turnover:null });
  const [sort, setSort] = React.useState(true);
  let [searchState, setSearch] = useState(null);

  useEffect(() => {
    if(activeTab)
    handleChangeSelect('search', search);
  }, [search]);
  
  const handleChangeSelect = (input, value)=>{
    console.log('input, value', input, value);
    if(input==='search'){
      searchState=value;
      setSearch(searchState);
      console.log('searchState',searchState);
    }
    if(input==='Speciality'){
      filterValueState.Speciality= value;
    }
    if(input==='Brand'){
      filterValueState.Brand= value;
    }
    if(input==='Retailer_Profile'){
      filterValueState.Retailer_Profile= value;
    }
    if(input==='Company_Origin'){
      filterValueState.Company_Origin= value;
    }
    if(input==='Annual_Turnover'){
      filterValueState.Annual_Turnover= value;
    }
    if(input==='channel_type'){
      filterValueState.typeId= value;
    }
    setFilterValue({ ...filterValueState })
    console.log('filterValueState', filterValueState);
  }
  const resetFilterValue =()=>{
    filterValueState.Speciality=[];
    filterValueState.Brand=[];
    filterValueState.Company_Origin=[];
    filterValueState.Retailer_Profile=[];
    filterValueState.Annual_Turnover=null;
    setFilterValue({ ...filterValueState });
    searchState= null;
    setSearch(searchState);
  }
  return (
        <Card>
          <CardBody>
          <h4 className="text-muted mb-0">FILTERS</h4>
            <Row className="pt-2">
              {/* Left Filter */}
              <Col id="leftFilter" lg="3" className="p-0 ml--4">
                {/* <CompanyListingFilters activeTab={activeTab} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} /> */}
                <ListingFilter activeTab={activeTab} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} />
              </Col>

              <Col style={{ backgroundColor: "#f8f9fe" }} lg="9">
                {/* Body */}
                {/* {props.selection ? */}
              <Contact activeTab={activeTab} filterValueState={filterValueState} search ={searchState}/>
               {/* : <Product/>} */}
              
              </Col>
            </Row>
          </CardBody>
        </Card>)
};
export default ContactWrapper;
