import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import {Card,CardBody,Row,Col,Container,UncontrolledTooltip,CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { commonService } from '_services/common.service';
import { history } from '../../../../_helpers';
import axios from 'axios';
const Guestslider = (props) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    const {title, familyId, type, value} = props;
    let [catList, setCatList] = useState([]);
    let [total, setTotal] = useState(0);

    useEffect(() => {
      getCategoryDetails();
    }, []);

    const getCategoryDetails = async() => {
      let filter ={
        groupId:familyId[0],
        type:type
      }
      let res = await commonService.postService(filter, 'guest/getProductByGroup').catch(err =>console.log('err', err));
      if(res){
        let data=res.data;
        let total = 0;
        if(data.length>0){
          let cat=[];
          let res=[];
          data.map((x,i)=>{
            res.push(x);
            if((i+1)%8===0 || data.length === i+1){
              cat.push(res);
              res=[];
            }
          });

          data.map(x => total+= +x.total);
          setTotal(total);

          catList = cat;
        }else
        catList = [];
        setCatList(catList);
      }
    };

    return (
            <div>
              <Card className="mb-0 p-3">
                <CardBody>
                <div className="p-3 mt-3 ml-3 mb--3">
  <h1 className="ProductTitle d-inline-block mr-3">{title}</h1>
  <h2 className="ProductCount midle text-muted ml-5"> See more Products ({total})</h2>
  </div>
        <Slider {...settings}>
        {catList.length>0 && catList.map(item=>(
      <div className='prod-slide borderCat'>
     <div className="d-flex justify-content-left p-4">
     {item.map(x=>(
        <Link onClick={(e) => {e.preventDefault();history.push({pathname: '/productList', state:{productCategorie: [{name:x?.productCategorie, id:x.productCategorieId}], familyId: [{id: familyId[0], name: title}], fromCat: 2, value: value[0], type:type }})}}>
       <div className="catitem">
       <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="catImg" src={x?.image?process.env.REACT_APP_CDN_URL + x.image:"https://d35w8j22j5uly8.cloudfront.net/document/product/packing/0fz2iZqt_1614278863657_1614278899685.png"}/>
      <h3 className="catname">{x?.productCategorie}</h3>
      <span>({x?.total})</span>
       </div>
       </Link>
               ))}
       </div>

        {/* <div className="d-flex bg-white">
          {item.map(x=>(
                    <div className="wid33">
                    <div className="ListContainerForSlider ">
                    <Link onClick={(e) => {e.preventDefault();history.push({pathname: '/productList', state:{productCategorie: [{name:x?.productCategorie, id:x.productCategorieId}] }})}} class="nav-item nav-link">
                      <div className='prod-list'>
                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={x?.image?process.env.REACT_APP_CDN_URL + x.image:"https://d35w8j22j5uly8.cloudfront.net/document/product/packing/0fz2iZqt_1614278863657_1614278899685.png"}/>
                      </div>
                      <h2 className="mt-2 d-inline-block"> {x?.productCategorie} </h2><p className="d-inline-block ml-2">({x?.total})</p>
                      </Link>
                    </div>
                  </div>
          ))}
        </div> */}
      </div>
    ))}  
        </Slider>
        
        </CardBody>
              </Card>
        </div>
    );
};

export default Guestslider;