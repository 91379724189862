import React, { useState,useEffect } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker } from 'antd';
import SignupForm from "./SignupForm"
import DistributionChannel from 'views/pages/product/DistributionChannel'
import ForgetPassword from 'views/Hoc/Dialog/ForgetPassword'

const SignupDrawer = (props) => {
    const { closeDrawer, visible, title } = props;
    const { Option } = Select;

    // const [state,setState] =  useState(visible);

    useEffect(()=>{
      console.log('visible', visible);
    }, [visible])

  // const showDrawer = () => {
  //   setState(true);
  // };

  const onClose = () => {
    // setState(false);
    closeDrawer();
  };

  const SwitchPage = () =>{
    switch(title){
      case "Distribution Channel":
        return <DistributionChannel/>
      case "Forgot Password":
        return <ForgetPassword visible={visible} />
        default:
          return <></>
    }
  }
    
    return (
        <div className="site-form-in-drawer-wrapper">
        {/* <button class="btn btn-primary" type="button" onClick={showDrawer}>{props.text}</button> */}
        <Drawer
          title={title}
          // title="Create Your Account"
          // width={720}
          onClose={onClose}
          visible={visible}
          //bodyStyle={{ paddingBottom: 80 }}
          // footer={
          //   <div
          //     style={{
          //       textAlign: 'right',
          //     }}
          //   >
          //     <Button  className="btn btn-light" onClick={onClose} style={{ marginRight: 8 }}>
          //       Cancel
          //     </Button>
          //     <Button className="btn btn-primary" onClick={onClose} type="primary">
          //       Submit
          //     </Button>
          //   </div>
          // }
        >
        {SwitchPage()}
        </Drawer>
        </div>
    );
};

export default SignupDrawer;