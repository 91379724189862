import React, { useEffect, useMemo, useState } from "react";
import AwardVideoItemMENA from "./AwardVideoItem_MENA24";
import axios from 'axios';
import { Spinner } from "views/Hoc/Spinner";
// import defaultSports from "./AwardsData";
//Filter list by category in React JS
export default function Event_Video_Template({ eventId }) {
    // Default Value
    const Awards = [
        { companyname: "Bang & Olufsen", productname: "BANG & OLUFSEN BEOLAB 8", embedId: "2t6rfI3fwZ8", category: "Audio", awardlogo: "/images/Audio_winner2024.png" },
        { companyname: "NutriCook", productname: "Smart Air Fryer Grill XL", embedId: "qAyaBMrmx7c", category: "Electronics and IoT", awardlogo: "/images/Electronicsiot_winner2024.png" },
        { companyname: "GAC TECHNOLOGY GROUP", productname: "HP BP900 - UST Interactive Projector", embedId: "9Gb3KnH0f_o", category: "Entertainment", awardlogo: "/images/Entertainment_winner2024.png" },
        { companyname: "Onsor Technologies", productname: "AMAD", embedId: "6E6fAcADJNk", category: "Gaming & Computing", awardlogo: "/images/GamingComputing_winner2024.png" },
        { companyname: "Manhattan", productname: "Aluminium Gas Spring Dual Monitor", embedId: "sS5vvAErNtM", category: "IT Peripherals & Accessories", awardlogo: "/images/IT Peripherals& Accessories_winner2024.png" },
        { companyname: "Riley Scooters", productname: "ULTRA-FOLDING & Battery Detachable", embedId: "1EO_4zTjFO4", category: "Lifestyle", awardlogo: "/images/Lifestyle_winner2024.png" },
        { companyname: "Belkin  International Inc.", productname: "Auto-Tracking Stand Pro with Dockkit", embedId: "SIagGepUgLY", category: "Mobile Products", awardlogo: "/images/Mobile_winner2024.png" },

    ]
    const [catList, setCatList] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState();

    const [loading, setLoading] = useState(false);

    const TemplateData = async () => {
        await axios.get('/channelAdmin/Video_Template/' + eventId).then(res => {
            if (res.data.length) {
                setCatList(res.data);
            }
        });
    }

    useEffect(() => {

        (async () => {
            setLoading(true);
            await TemplateData();
            setLoading(false);
        })();


    }, [])

    // Function to get filtered list
    function getFilteredList() {
        // Avoid filter when selectedCategory is null
        if (!selectedCategory) {
            return catList;
        }
        return catList.filter((item) => item.category === selectedCategory);
    }

    // Avoid duplicate function calls with useMemo
    var filteredList = useMemo(getFilteredList, [selectedCategory, catList]);

    function handleCategoryChange(event) {
        setSelectedCategory(event.target.value);
    }

    return (
        <>
            {(loading && catList.length > 0) ? <Spinner /> :
                <div className="awards-wrapper">
                    <div className="filter-container">
                        <span><b>Filter by Category:</b></span>
                        <select
                            name="category-list"
                            id="category-list"
                            defaultValue=""
                            onChange={handleCategoryChange}
                        >
                            <option value="">All</option>
                            <option value="Audio" >Audio</option>
                            <option value="Electronics and IoT">Electronics and IoT</option>
                            <option value="Entertainment" selectedCategory>Entertainment</option>
                            <option value="Gaming & Computing">Gaming & Computing</option>
                            <option value="IT Peripherals & Accessories">IT Peripherals & Accessories</option>
                            <option value="Lifestyle">Lifestyle</option>
                            <option value="Mobile Products">Mobile Products</option>
                        </select>
                    </div>
                    <div className="cat-list">
                        <div className="row">

                            {filteredList.map((element, index) => (
                                <AwardVideoItemMENA {...element} key={index} />

                            ))}
                        </div>

                    </div>
                </div>
            }</>
    );
}