import React, { useEffect, useState} from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { commonService } from '_services/common.service';
import axios from 'axios';
import { Card, CardBody, Container, CardHeader, ListGroupItem, ListGroup, Row, Col, Button } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import { ConfirmDialog } from 'views/Hoc/Dialog/GlobalDialog';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '_helpers';
import { ToastsStore } from 'react-toasts';
import { Spinner } from "views/Hoc/Spinner";
import { eventService } from '_services';
import { FormCheckBox } from '_components/FormElements/FormInput';

 //css end------------------------------
  const EventUsers = (props) => {
    const [value, setValue] = useState("");

    const dispatch = useDispatch();

    const userData = JSON.parse(localStorage.getItem('CHuser'));

    let eventDetails = useSelector(state => state.event)?.eventDetails;
    let eventPackage = useSelector(state => state.event)?.eventPackage;

    let [total, setTotal] = useState(eventPackage?.price);
    const [loading, setLoading] = useState(false);

    let defaultState = {
        channelId: userData?.channelId,
        eventId: eventDetails?.eventId,
        cType: userData?.channelTypeId,
        pType: userData?.channelTypeId=='2' ? '1' : '2',
        channelContacts: [],
        selectedContacts: [],
        additionalMember: 0,
        marketingOptions: [],
        packageOptions: [],
        marketingOptionsPrice: 0,
        packageOptionsPrice: 0
      };
      
    const [state, setState] = useState(defaultState);
      
  
    const proceed = () => {
      if(state.selectedContacts.length){
            let users = state.selectedContacts.map(x => x.id);
            dispatch({type: 'EVENT_USERS', data: {contacts: users, additionalMember: state.additionalMember, marketingOptions: state.marketingOptions, packageOptions: state.packageOptions, marketingOptionsPrice: state.marketingOptionsPrice, 
            packageOptionsPrice: state.packageOptionsPrice}});
            let overall = (+total) + (+state.marketingOptionsPrice) + (+state.packageOptionsPrice);
            dispatch({type: 'EVENT_AMT', data: overall});
            
            // if(total > 0){
            //   history.push('/admin/payment/event');
            // }
            // else
            // {
              setLoading(true);
              let payment = {
                paymentStatus: '1',
                paymentMethod: null,
                paymentTransactionId: null,
              };

              eventService.eventRegistration(payment);
            // }

      }else{
          ToastsStore.error('Select Atleast one contact');
      }
    };
  
    useEffect(() => {

        if(!eventDetails || !eventPackage){
            history.push('/admin/events');        }

        (async() => {
            await getContacts();
        })();

    }, []);

    const getContacts = async() => {
      setLoading(true);
        await commonService.getServices('/channel/getEventChannelDetails/'+state.eventId).then(res => {
            state.channelContacts = res.data.contacts;
            state.selectedContacts = res.data.participants;

            state.channelContacts = state.channelContacts.map(x => {
              if(state.selectedContacts.filter(y => y.id == x.id).length){

              }
              else return x;
            });

            state.channelContacts = state.channelContacts.filter(x => x);
            setState({...state});
        });
      setLoading(false);
    };

      console.log('state', state);

      const addContact = (value) => {
          
          if(!state.selectedContacts.filter(x => x.id==value.id).length){

              // if(state.selectedContacts.length+1 > eventPackage?.membersAllowed){
              //   confirmInactive(value);
              // }else{
              let temp = state.selectedContacts;
              temp.push(value);
              state.channelContacts = state.channelContacts.filter(x => x.id!=value.id);
              setState({...state, selectedContacts: temp});
              // }

          }

      }

      const removeContact = (value) => {
          state.selectedContacts = state.selectedContacts.filter(x => x.id!=value.id);
          state.channelContacts.push(value);

          // if(state.additionalMember>0){
          //     state.additionalMember -= 1;
          //     setTotal(+eventPackage?.price + state.additionalMember*eventPackage?.additionalMembersCosting);
          // }

          setState({...state});
      }

      let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
      const [activeProductPop, setActiveProductPop] = useState(false);
      const closePopup = () => {
        setActiveProductPop(false);
      };

  const confirmSavePopup = (data) => {
    let temp = state.selectedContacts;
    temp.push(data.value);
    state.channelContacts = state.channelContacts.filter(x => x.id!=data.value.id);
    state.additionalMember = state.additionalMember+1;
    setTotal(+eventPackage?.price + state.additionalMember*eventPackage?.additionalMembersCosting);
    setState({...state, selectedContacts: temp});
    closePopup();
  }

  const confirmInactive = (value) => {
    modalPopValue.message = `Note: You can also add additional users @ ${eventPackage?.additionalMembersCosting} /- per user`;
    modalPopValue.title = 'Event Users';
    modalPopValue.value = value;
    modalPopValue.popUpId = 1;
    setModalPopValue({...modalPopValue});
    setActiveProductPop(true);
  }

  const handleChangeSelect = (input, value) => {
    if(input == 'marketingOptions' || input == "packageOptions"){
      if(state[input].includes(value)){
        state[input] = state[input].filter(x => x.optionId != value.optionId);
      }else{
        state[input].push(value);
      }
      state[ input+'Price' ] = state[input].reduce((a,b) => a + +b.value, 0);
      setState({...state});
    }
  }

    return (
        <>
<SimpleHeader location={props.match}/>
{loading ? <Spinner /> : <Container>
<ConfirmDialog open={activeProductPop} value={modalPopValue} handleSave={confirmSavePopup} handleClose={closePopup} />
<Card className="mt-4">
              <CardHeader>
            <h4>Based on your plan you can add {eventPackage?.membersAllowed} Users.
            {userData.channelTypeId==2 && <span style={{float:'right'}}>Additional members @{eventPackage?.additionalMembersCosting}$</span>}</h4>

              {/* //for contacts autocomplete */}
        <div>
      <form>
   <Autocomplete
        id="country-select-demo"
     style={{ width: "100%" }}
        options={state.channelContacts}
        // onChange={e => setValue(e.target.value)}
        onChange={(event, newValue) => {
          addContact(newValue);
        }}
        autoHighlight
        getOptionLabel={(option) => option.name}
        renderOption={(option) => (
          <React.Fragment>
            <span>
            <a
                            className="avatar rounded-circle listImg"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >

<img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={option.documentPath ? process.env.REACT_APP_CDN_URL+option.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>

                          </a>
              </span>
            {option.name}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a Contacts"
            freeSolo
             variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        disableClearable={true}
      />
      </form>
      </div>
              </CardHeader>
              <CardBody className="overflow-auto" style={{"maxHeight":"280px"}}>
                <ListGroup className="list my--3" flush>
                {state.selectedContacts.map((task, index) => (
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <a
                          className="avatar rounded-circle"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
       <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={task.documentPath ? process.env.REACT_APP_CDN_URL+task.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>
                        </a>
                      </Col>
                      <div className="col ml--2">
                        <h4 className="mb-0">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                          {task.name}
                          </a>
                        </h4>
                      </div>
                      <Col className="col-auto">
                        <Button onClick={() => removeContact(task)} color="primary" size="sm" type="button">
                        <i class="fas fa-trash-alt"></i>
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>  ))}
                </ListGroup>
              </CardBody>
            </Card>
            {!!eventPackage?.marketingOptions?.length && userData.channelTypeId==2 && <Card>
              <CardHeader>
                Marketing Options
              </CardHeader>
              <CardBody>
                {
                  
                  eventPackage.marketingOptions.map(x => (
                    <FormCheckBox
                    id={x.optionId}
                    type="checkbox"
                    label={x.text + ' - ' + x.value}
                    //label1={{textAlign:"justify"}}
                    //register={register}
                    checked={state.marketingOptions.includes(x)}
                    onChange={() => handleChangeSelect('marketingOptions', x)}
                    />
            
                  ))
                  
                }
              </CardBody>
            </Card>}

            {/* {!!eventPackage?.packageOptions?.length && <Card>
              <CardHeader>
                {userData.channelTypeId==2 ? 'Vendor Options' : 'Buyer Options'}
              </CardHeader>
              <CardBody>
                {
                  
                  eventPackage.packageOptions.map(x => (
                    <FormCheckBox
                    id={x.optionId}
                    type="checkbox"
                    label={x.text + ' - ' + x.value}
                    //label1={{textAlign:"justify"}}
                    //register={register}
                    checked={state.packageOptions.includes(x)}
                    onChange={() => handleChangeSelect('packageOptions', x)}
                    />
            
                  ))
                  
                } 
              </CardBody>
            </Card>}
            */}

            <p>Price {eventPackage?.price} {userData.channelTypeId==2 && <> + Additional Members Cost {state.additionalMember*eventPackage?.additionalMembersCosting}  {state.marketingOptionsPrice ? ` + Marketing Options ${state.marketingOptionsPrice}` : ''}  {state.packageOptionsPrice ? ` + Package Options ${state.packageOptionsPrice}` : ''}</>
            } = {+total + (+state.marketingOptionsPrice) + (+state.packageOptionsPrice) }
            
            </p>
            <Button onClick={proceed}>Proceed</Button>
            </Container>}
</>
        
    );
  }
  
  
  
  
export default EventUsers;