
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem, Button, Container, Row, Col } from "reactstrap";
import { history } from '_helpers';
import { SelectAutocomplete } from '_components/FormElements/FormInput';
import { useForm } from "react-hook-form";


import axios from 'axios';

let RouteName = {
  productDetail: 'Product Detail',
  channelDetail: 'Channel Detail',
  EditProduct: 'Edit Product',
  CloneProduct: 'Clone Product',
  channel: 'Suggestions',
  product: 'Product',
  favourites: 'Favourites',
  mynetwork: 'My Network',
  completeProfile: 'Company Profile',
  Myproductlist: 'My ProductList',
  notification: 'Notification',
  userform: 'User Form',
  Payment: 'Payment',
  Subscription: 'Subscription',
  Addcart: 'Add to Cart',
  checkout: 'Check Out',
  ordersuccess: 'Order Success',
  transaction: 'Transaction',
  profileform: 'Profile Form',
  edituser: 'Edit User',
  vendorDashboard: 'Dashboard',
  profileView: 'Profile View',
  FollowerView: 'Follower View',
  userManage: 'User Manage',
  settings: 'Settings',
  profileSettings: 'Company Profile',
  companyprofileretres: 'Company Profile',
  companyprofiledist: 'Company Profile',
  PublicProfile: 'Public Profile',
  BuyerDashboard: 'Dashboard'
}

const localStorageWrapper =  {
  getItem: (key) => {
    let user = localStorage.getItem(key);

    if(key == "CHuser" && window.location.host.indexOf("inspect.") < 0)  {
      //page
      let selectuser = localStorage.getItem("selectCHUser");
      if(selectuser != "null" && selectuser != null){
        return selectuser;
      }
    }
    return user;

  },
  setItem: (key , value) => {
    localStorage.setItem(key, value)
  }
}


const TimelineHeader = (props) => {
  let userData = JSON.parse(localStorageWrapper.getItem('CHuser'));
  let userLogin = JSON.parse(localStorage.getItem('CHuser'));
  let userSelect = JSON.parse(localStorage.getItem('selectCHUser'));
  let [name, setName] = useState(null);
  let [breadcrumbList, setBreadcrumbList] = useState([]);
  let [userStatus, setUserStatus] = useState(false);
  const [searchState, setSearch] = useState({ search: {name: userSelect ? userSelect.companyName : null}, dropdownList: [], userSearch: {name: userSelect ? userSelect.firstName + " " + userSelect.lastName : null}, userDropdownList:[] });
  const { control } = useForm({ mode: 'onBlur' });
  let [listing, setListing] = useState(props.location.path);
  // const [filterValueState, setFilterValue] = useState({ Position: null, Brand: [], Family: [], Category: [], search: null, sort: true });
  // const [user, setUser] = useState({});


  console.log("props", props)
  useEffect(() => {
    checkStatus();
    // getUser();
    getChannelOnlyIsMaster();
    // localStorage.setItem('selecteduser', null);

    let params = props?.location?.params;
    if (params) {
      let url = props.location.url;
      url = url.replace("/admin", "");
      if (params?.id) {
        url = url.replace("/" + params?.id, "-" + params.id);
      }
      if (params?.channelId) {
        url = url.replace("/" + params.channelId, "-" + params.channelId);
      }
      if (params?.productId) {
        url = url.replace("/" + params.productId, "-" + params.productId)
      }
      if (params?.eventId) {
        url = url.replace("/" + params.eventId, "-" + params.eventId)
      }
      console.log('path', url);
      let path = url.split("/");
      path = path.filter(x => x)
      name = path.pop();
      breadcrumbList = path;
      setName(name);
      setBreadcrumbList(breadcrumbList);
      console.log('path', path);
    }
  }, [props.location]);

  const getChannelOnlyIsMaster = async() => {
    await axios.get(`/channel/getChannelDataListOnlyIsMaster`).then(res=>{
      const companiesName = res.data.map(e=>({name: e.companyName, channelId:e.channelId, channelTypeid:e.channelTypeId, buisnessType:e.isOEM}))
      searchState.dropdownList  = companiesName;
      setSearch({ ...searchState });
    });
  }

  // const getUser = async () => {
  //   await axios.get(`/channel/getUserData/${userData.userId}`).then(res=>{
  //     setUser(res.data[0]);
  //   });
  // }

  const getChannelUsers = async (id) => {
    await axios.get(`/channel/getChannelUsers/${id}`).then(res=>{
     
      const users = res.data.map(e=>({name: e.firstName + ' ' + e.lastName, email: e.email}))
      searchState.userDropdownList  = users;
      setSearch({ ...searchState });
      if(res.data.length < 1){
        localStorage.setItem('selectCompanyData', null);  
      }
    });
  }

  const getSelectedUserData = async (email) => {
    try {
      const body = {
        email: email
      }
      const postResponse = await axios.post('channel/getSelectedUserdata', body);
      const { data } = postResponse;
      const profileCompletionResponse = await axios.get(`/account/checkUserStatus/${data.channelId}/${data.channelTypeId}`);
      const profileCompletionCount = profileCompletionResponse.data;
  
      const documentUrl = data.document?.documentPath ? `${process.env.REACT_APP_CDN_URL}${data.document.documentPath}` : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg';
  
      const user = {
        channelTypeId: data.channelTypeId,
        channelId: data.channelId,
        contactId: data.contactId,
        userId: data.userId,
        firstName: data.firstName,
        lastName: data.lastName,
        isEvent: data.isEvent,
        businessType: +data.businessType,
        documentUrl,
        planId: data.planId,
        email: data.email,
        subscriptionId: data.channelSubscription?.subscriptionId,
        roleId: data.roleId,
        featureList: data.channelSubscription?.keyFeature,
        isAdmin: false,
        planExpireAt: data.channelSubscription?.endAt,
        isAlgorithm: +data.isAlgorithm,
        suggestion_comm: +data.suggestion_comm,
        companyName: data.channel?.companyName,
        lastLogin: data.lastLogin,
        hasTravel: data.hasTravel,
        profile_completion_count: profileCompletionCount,
        isMaster: data.isMaster,
      };
  
      localStorage.setItem('selectCHUser', JSON.stringify(user));
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Handle the error appropriately
    }
  }

  const Openfilter = () => {
    if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
      document.getElementById('filter').classList.remove("g-sidenav-pinned");
      document.getElementById('filter').classList.add("g-sidenav-hidden");
      document.getElementById('filter-hide').classList.add("filter-hide-width");
    } else {
      document.getElementById('filter').classList.add("g-sidenav-pinned");
      document.getElementById('filter').classList.remove("g-sidenav-hidden");
      //document.getElementById('filter-hide').classList.add("filter-show-class");
      document.getElementById('filter-hide').classList.remove("filter-hide-width");
      //document.getElementById('filter-hide').classList.remove("filter-hide-class");


      //document.getElementById('filter-hide').classList.remove("filter-hide-width");
    }
  }
  // render() {
  const goTo = (string) => {
    console.log('string', string)
    string = string.replace('-', '/');
    let url = props.location.url.split(string);
    console.log('url', url);
    let from = '';
    let current = breadcrumbList.indexOf(string);
    if (current >= 0 && breadcrumbList.length > current + 1)
      (breadcrumbList[current + 1].includes('productDetail') && userData?.channelTypeId != 2) ? from = 'product' : from = 'channel';
    else
      (name.includes('productDetail') && userData?.channelTypeId != 2) ? from = 'product' : from = 'channel';
    console.log('from path', from, string, breadcrumbList[current], breadcrumbList[current + 1], breadcrumbList.length, current);
    if (string == 'channel')
      history.push({ pathname: url[0] + string, state: { from: from } });
    else
      history.push(url[0] + string);
  }

  const checkStatus = async () => {
    let profile_completion_count = userData?.profile_completion_count;
    //let profile_completion_count = await axios.get('/setting/checkUserStatus/' + userData?.channelId);
    if ((userData.channelTypeId == 2 && profile_completion_count == 4) || (userData.channelTypeId != 2 && profile_completion_count == 2)) {
      //console.log("profile_completion_counts:", profile_completion_count.data);
      userStatus = true;
      setUserStatus(userStatus);
    }
  }
      let selectCompanyData = localStorage.getItem('selectCompanyData', searchState);
      if(selectCompanyData != null){
        selectCompanyData = JSON.parse(selectCompanyData);
      }
  const handleChangeSelect = async( button , value) => {

        if(button=="company" && value==null ){ 
            if (listing == '/admin/vendorDashboard') {            
          localStorage.setItem('selectCHUser',null);
          localStorage.removeItem('selectCompanyData');
          localStorage.removeItem('selectEmail'); 
              props.getDashBoard(); 
            }else if(listing == '/admin/channel'){
              return
              props.callTabCount(); 
            }
           
        }
    
        if(button == "user" && searchState.userDropdownList.length == 0){
          searchState.userSearch = "";
          setSearch({ ...searchState });
        }
    
        if(searchState.userDropdownList.length > 0){
          localStorage.setItem('selectCompanyData', JSON.stringify(searchState));
        }
        if (button == "company" && value != null) {
          searchState.search = value;
          setSearch({ ...searchState });
          getChannelUsers(value.channelId);
        }if(button == "user" && value != null){
          searchState.userSearch = value;
          localStorage.setItem('selectEmail', searchState?.userSearch?.email);
          
          if(value != null){
            getSelectedUserData(searchState?.userSearch?.email);
          }
    
          if (value) {
            if (listing == '/admin/vendorDashboard') {
              props.getDashBoard();
            }else if(listing == '/admin/channel'){ 
                getSelectedUserData(searchState?.userSearch?.email); 
              props.callTabCount();
            }
          }
          setSearch({ ...searchState });
        }
       
      };

  return (
    <>
      {/* pb-6 bg-info*/}
      <div className="header header-dark drk-blue content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                {/* <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    {props.name}
                  </h6>{" "} */}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem className="pointer">
                    <a onClick={e => history.push('/admin/dashboard')}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>

                  {breadcrumbList.length > 0 && breadcrumbList.map(item => (
                    <BreadcrumbItem className="pointer">
                      <a onClick={() => goTo(item)}>
                        {
                          RouteName[item.split('-')[0].replace(/[-_]/g, ' ') == "channel" ? "Suggestions" : item.split('-')[0].replace(/[-_]/g, ' ')] ? RouteName[item.split('-')[0].replace(/[-_]/g, ' ') == "channel" ? "Suggestions" : item.split('-')[0].replace(/[-_]/g, ' ')] : item.split('-')[0].replace(/[-_]/g, ' ') == "channel" ? "Suggestions" : item.split('-')[0].replace(/[-_]/g, ' ')
                        }
                        {/* {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == "channel" && "Suggestions"}
                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'channeldetail' && 'Channel Detail'}
                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'productdetail' && 'Product Detail'}

                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'myproductlist' && 'My Products'}
                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'mynetwork' && 'My Network'}
                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'favourites' && 'Favourites'}
                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'editproduct' && 'Edit Product'}
                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'cloneproduct' && 'Clone Product'} */}

                      </a>
                    </BreadcrumbItem>
                  ))
                  }

                  {name &&
                    <BreadcrumbItem aria-current="page" className="active">
                      {/* {name.split('-')[0].replace(/[-_]/g,' ') == "channel" ? "Suggestions" : name.split('-')[0].replace(/[-_]/g,' ')} */}

                      {
                        RouteName[name.split('-')[0].replace(/[-_]/g, ' ') == "channel" ? "Suggestions" : name.split('-')[0].replace(/[-_]/g, ' ')] ? RouteName[name.split('-')[0].replace(/[-_]/g, ' ') == "channel" ? "Suggestions" : name.split('-')[0].replace(/[-_]/g, ' ')] : name.split('-')[0].replace(/[-_]/g, ' ') == "channel" ? "Suggestions" : name.split('-')[0].replace(/[-_]/g, ' ')
                      }

                      {/* {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == "channel" && "Suggestions"}
                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'channeldetail' && 'Channel Detail'}
                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'productdetail' && 'Product Detail'}

                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'myproductlist' && 'My Products'}
                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'mynetwork' && 'My Network'}
                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'favourites' && 'Favourites'}

                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'editproduct' && 'Edit Product'}
                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'cloneproduct' && 'Clone Product'} */}
                    </BreadcrumbItem>}

                </Breadcrumb>
              </Col>
              {props.filter &&
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                  <Button className="btn-neutral" color="default" size="sm">
                    New
                  </Button>
                  <Button onClick={Openfilter} className="btn-neutral" color="default" size="sm">
                    Filters
                  </Button>
                </Col>
              }
              <Col className="mt-3 mt-md-0 text-md-right viewBtn" lg="6" xs="5">
                <div className="d-flex dashboard_search">
                  {userLogin.isMaster ? (
                    <div className="d-flex dashboard_search">
                      <SelectAutocomplete
                        id="company"
                        type="text"
                        label="Company"
                        name="company"
                        style={{ width:"13.7rem" }}
                        options={searchState.dropdownList}
                        placeholder="search..."
                        disableCloseOnSelect
                        // defaultValue={searchState.search?searchState.search[0]:null}
                        control={control}
                        input='search'
                        value={searchState.search}
                        handleChangeSelect={(button, value) => handleChangeSelect("company", value)}
                        />
                      <SelectAutocomplete
                        id="user"
                        type="text"
                        label="User"
                        name="user"
                        style={{ width:"12.2rem" }}
                        options={
                          searchState.userDropdownList.length > 0 
                              ? searchState.userDropdownList
                              : (selectCompanyData != null && selectCompanyData.userDropdownList != null) 
                                  ? selectCompanyData.userDropdownList 
                                  : []
                      }
                      
                        placeholder="search..."
                        disableCloseOnSelect
                        // defaultValue={searchState.search?searchState.search[0]:null}
                        control={control}
                        input='search'
                        value={searchState.userSearch}
                        disabled={searchState.search.name == null ? true : false}
                        handleChangeSelect={(button, value) => handleChangeSelect("user", value)}
                        />
                    </div>
                  ) : (
                    <></>
                  )

                  }
                  {userStatus && 
                  <Button onClick={() => {userData.isOtherUser == null?history.push('/admin/PublicProfile/' + userData.channelId):history.push('/admin/OtherUserPublicProfile/' + userData.channelId)}} className="btn-neutral" color="default" size="sm">
                    View Your Public Profile
                  </Button>}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
  // }
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};

export default TimelineHeader;
