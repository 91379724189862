import * as yup from 'yup';

const EventTravelSchema = yup.object().shape({

    arrivalDate: yup.date().required('Please Enter Arrival Date.').typeError('Please Enter Valid Date.'),
    departureDate: yup.date().required('Please Enter Departure Date.').min(yup.ref('arrivalDate'), 'Departure Date can\'t be before arrival Date.').typeError('Please Enter Valid Date.'),
    arrivalTime: yup.date().required('Please Enter Arrival Time.').typeError('Please Enter Valid Time.'),
    departureTime: yup.date().required('Please Enter Departure Time.').typeError('Please Enter Valid Time.'),

    arrivalType: yup.string().required('Please Select Arrival Method'),
    departureType: yup.string().required('Please Select Arrival Method'),

    arrivalFlightNumber: yup.string().when('arrivalType', {is: '3', then: yup.string().required('Please Enter Flight Number')}),
    departureFlightNumber: yup.string().when('departureType', {is: '3', then: yup.string().required('Please Enter Flight Number')}),
    
  });


  export { EventTravelSchema }
