import React, { useState } from 'react';
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
import { history } from '_helpers';
import axios from 'axios';
var _ = require('lodash');
export const KeyBrands = (props) => {
    const { data, title, type, sizeSm, count_title } = props;
    const [showMore, setMore] = useState(false);
    const more_click = () => {
        if (!showMore) setMore(true);
        else setMore(false);
    }
    const brands = (data) => {
        return (<>
            {data.length > 0 && data.map((item) => (
                <Col xl="2" lg="3" md="6" sm="6">
                    <div className={"key-card " + (sizeSm ? 'keyProductSmall' : '')}>
                        <div className={"set-image " + (sizeSm ? 'keyheight' : '')} >
                            <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}  alt={title} src={item.active && item.active.logo && item.active.logo.documentPath ? item.active.logo.documentUrl : item.new && item.new.logo && item.new.logo.logo_name ? item.new.logo.documentUrl : item.old && item.old.logo && item.old.logo.documentPath ? item.old.logo.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                        </div>
                        <div class='set-headline mt-2 text-center'>
                            <h3> {item.active ? item.active.company_name : item.new ? item.new.company_name : item.old.company_name} </h3>
                            <h4 className="text-muted m-0 text_elips">{item.active ? item.active.country.country_name : item.new ? item.new.country.country_name : item.old.country.country_name}</h4>
                        </div>
                        {/* <div class='ribbon-wrapper-3'>
                           {item.active? <div class='ribbon-3 bg-green'> Member </div> : <div class='ribbon-3 bg-gray'> Mainstream </div>}
                           </div> */}
                    </div>
                </Col>
            ))}
        </>
        )
    }
    if (data.length > 0) {
        let _remain = [];
        let brandData = _.orderBy(data, [item => {
            let company_name = item.active ? item.active.company_name : item.new ? item.new.company_name : item.old.company_name;
            return company_name.toLowerCase();
        }])
        const _elements = brandData.slice(0, 6); // clone array     

        _remain = brandData.slice(6, data.length);
        return (
            <Card className='m-0'>
                <CardBody>
                    {type === 2 && <h3> Total No.of {count_title + ":"} {props.no} </h3>}
                    <h4>{title}</h4>
                    <Row className="align-items-center">{brands(_elements)}</Row>
                    {showMore && <Row>{brands(_remain)}</Row>}
                    {data.length > 6 && <h3 onClick={more_click} className='float-right text-primary' style={{ cursor: 'pointer' }}>{!showMore ? 'Show More' : 'Show Less'}</h3>}
                    {(type === 2 && props.desc) && <><hr /> <h4> Additional Info : </h4><p>{props.desc}</p></>}
                </CardBody>
            </Card>
        )
    }
}


export const BrandListing = (props) => {
    const { data, title, sizeSM } = props;
    const [showMore, setMore] = useState(false);
    const more_click = () => {
        if (!showMore) setMore(true);
        else setMore(false);
    }
    let brandData = [];
    if (data.length > 0) {
        brandData = _.orderBy(data, [brand => brand.brandName.toLowerCase()]);
        let _remain = [];
        const _elements = brandData.slice(0, 6); // clone array

        _remain = brandData.slice(6, brandData.length);

        const brands = (brandData) => {
            return (<>
                {brandData.length > 0 && brandData.map((item) => (
                    <Col xl="2" lg="3" md="6" sm="6">
                        <div className={"key-card " + (sizeSM ? 'keyProductSmall' : '')}>
                            <div class='set-image'>
                                <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt={title} src={item.document.documentUrl ? item.document.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/product/image/t8VTnIWi_1599217538036_1599217555775.png'} />
                            </div>
                            <div class='set-headline mt-2 text-center'>
                                <h3 id={'brandname' + item.id}> {item.brandName} </h3>

                                <UncontrolledTooltip target={'brandname' + item.id} placement='bottom'>{item.brandName}</UncontrolledTooltip>

                            </div>
                        </div>
                    </Col>
                ))}
            </>
            )
        }
        return (
            <Card>
                <CardBody>
                    <h3>{title} : {brandData?.length}</h3>
                    <Row className="align-items-center"> {brands(_elements)} </Row>
                    {showMore && <Row> {brands(_remain)} </Row>}
                    {brandData.length > 6 && <h3 onClick={more_click} className='float-right text-primary' style={{ cursor: 'pointer' }}>{!showMore ? 'Show More' : 'Show Less'}</h3>}
                </CardBody>
            </Card>
        )
    }
}

export const KeyProducts = (props) => {
    const { data, title, clickKeyRetailer } = props;
    // const [showMore, setMore] = useState(false);
    // const more_click=()=>{
    //   if(!showMore) setMore(true);
    //   else setMore(false);
    //   }
    if (data.length > 0) {
        const _elements = data.slice(0, 6);

        console.log('award', data)
        return (
            <Card>
                <CardHeader className="p-1">
                    <h4 className="mb-0">{title}</h4>
                </CardHeader>
                <CardBody className="p-1 d-flex">
                    {_elements.map((item) => {
                        return (
                            <div className="">
                                <div className="flex-column bd-highlight p-2" style={{ backgroundColor: '#f7f7f7', }}>
                                    <div className="key d-inline-block mr-3">
                                        {(title == "Key Retailers" || title == "Key Resellers") ? <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt={title} src={item.active && item.active.logo && item.active.logo.documentPath ? item.active.logo.documentUrl : item.new && item.new.logo && item.new.logo.logo_name ? item.new.logo.documentUrl : item.old && item.old.logo && item.old.logo.documentPath ? item.old.logo.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/product/image/t8VTnIWi_1599217538036_1599217555775.png'} /> : ''}
                                        {title == "Awards" ? <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt={item.awardDetail} src={item.document && item.document.documentUrl ? item.document.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} /> : ''}
                                        {title == "Product Certifications" ? <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt={title} src={item.active && item.active.logo && item.active.logo.documentPath ? item.active.logo.documentUrl : item.new && item.new.logo && item.new.logo.logo_name ? item.new.logo.documentUrl : item.old && item.old.logo && item.old.logo.documentPath ? item.old.logo.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/product/image/t8VTnIWi_1599217538036_1599217555775.png'} /> : ''}
                                    </div>
                                </div>
                            </div>)
                    })}
                </CardBody>
                {data.length > 6 && <strong><p className="more pointer text-primary" onClick={clickKeyRetailer}><strong> More</strong></p></strong>}
            </Card>
        )
    }
}

export const ChannelProducts = (props) => {
    const { data, title, from } = props;
    const [showMore, setMore] = useState(false);
    const userData = JSON.parse(localStorage.getItem('CHuser'));
    const more_click = () => {
        if (!showMore) setMore(true);
        else setMore(false);
    }
    if (data.length > 0) {
        let _remain = [];
        const _elements = data.slice(0, 4); // clone array     

        _remain = data.slice(4, data.length);

        const productView = (data) => {
            let channelId = props.channelId
            let to = from;
            let path = '';
            if (to?.toLowerCase() == 'publicprofile') {
                channelId = userData?.channelId;
                path = '/admin/Myproductlist/productDetail/';
            }
            else {
                path = '/admin/' + to + '/channelDetail/' + channelId + '/productDetail/';
            }

            return (<>
                {data.length > 0 && data.map((item) => (
                    <Col lg="3" md="6" sm="6">
                        <div class='products-card'>
                            <div className="brandImg">
                                <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={item.brandDocumentUrl} />
                            </div>
                            <div class='set-image'>
                                <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="pointer" target="_blank" alt="..." src={item?.documentUrl ? item?.documentUrl : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} onClick={() => from === 'public' ? history.push(item.externalURL) : history.push(channelId ? path + item.productSKU : '/admin/' + to + '/productDetail/' + item.productSKU, "_blank")} />
                            </div>
                            {/* <div class={`${item.productAwards.length>0 ? 'set-headline' : 'set-headline1'} mt-2 p-2`}> */}
                            <div class='set-headline mt-2 p-2'>
                                <h3>{item.product}</h3>
                                {/* <div class='d-flex justify-content-end awards'> */}
                                {/* {item.productAwards.length>0 && item.productAwards.map((award,index) => (                
                                <div>
                                ̥
                                    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt="..." src={award?.document?.documentPath? process.env.REACT_APP_CDN_URL +award.document.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>
                                </div>
                                ))} */}
                                <div className="d-flex justify-content-between">
                                    <div className="mt-3">
                                        <h4>Retail Price :</h4>
                                    </div>
                                    <div className="mt-2">
                                        <strong style={{ fontSize: '1.4rem' }}>{parseInt(item?.businesscurrencyid) === 1 ? '€' : '$'} {item?.retailerprice}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class='ribbon-wrapper-8'>
                        <div class='ribbon-8'>Mainstream</div>
                        </div> */}
                    </Col>
                ))}
            </>
            )
        }

        return (
            <div id="Product" className="main-content mt-3">
                <Card>
                    <CardBody>
                        <h3>{title} </h3>
                        {/* : {data?.length} */}
                        <Row className="align-items-center">{productView(_elements)}</Row>
                        {showMore && <Row>{productView(_remain)}</Row>}
                        {data.length > 3 && <h3 onClick={more_click} className='float-right text-primary' style={{ cursor: 'pointer' }}>{!showMore ? 'Show More' : 'Show Less'}</h3>}
                    </CardBody>
                </Card>
            </div>
        )
    }
}
const CurrencyType = {
    1: { type: 'Eur.', symbol: '€' },
    2: { type: 'Dollar', symbol: '$' }
};