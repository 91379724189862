
export function userData(state = {}, action) {

    switch (action.type) {
        case 'FIRSTNAME':
            {
              const FIRSTNAME = action.data;
              return {
                ...state, firstName: FIRSTNAME
              };
            }
          case 'LASTNAME':
            {
              const LASTNAME = action.data;
              return {
                ...state, lastName: LASTNAME
              };
            }
            case 'IMAGE':
                {
                const IMAGE = action.data;
                return {
                    ...state, image: IMAGE
                };
                }
            default:
                return state
    }
  }