import React, { useState, useEffect } from "react";
import { ListItem } from "_components/Listing/ListItem";
import { Card, CardBody, Button,Row, Col } from "reactstrap";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { Progress } from "reactstrap";
import Favourities from 'views/Hoc/Dialog/Favourities'
import CountryFlag from "views/Hoc/countryFlag/countryFlag";
import { history } from '../../../_helpers/history';
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { FormTextArea, FormInput } from "_components/FormElements/FormInput";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Badge, Modal, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import { subMinutes } from "date-fns";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { BuyerSessionDialog } from './BuyerSessionDialog';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@material-ui/core/Typography';
import { ConsoleView } from "react-device-detect";


const messageList = [{ id: 1, name: 'My agenda is full, I will be pleased to meet you on your stand' },
{ id: 2, name: "Thank you for your request, but I'm not interested at the moment" }];



// const agendaList = [
//     {
//         date: "2022-10-19", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-10-20", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-10-21", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-10-24", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-10-25", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-10-26", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-10-27", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-10-28", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-10-31", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-11-01", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-11-02", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-12-03", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-11-04", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-11-07", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-11-08", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     },
//     {
//         date: "2022-11-09", slotList: [{ slot: "10.00 - 10.30", available: false },
//         { slot: "10.30 - 11.00", available: false },
//         { slot: "11.00 - 11.30", available: false },
//         { slot: "11.30 - 12.00", available: false },
//         { slot: "12.00 - 12.30", available: false },
//         { slot: "12.30 - 13.00", available: false },
//         { slot: "13.00 - 13.30", available: false },
//         { slot: "13.30 - 14.00", available: false },
//         { slot: "14.00 - 14.30", available: false },
//         { slot: "14.30 - 15.00", available: false },
//         { slot: "15.00 - 15.30", available: false },
//         { slot: "15.30 - 16.00", available: false }]
//     }

// ]

export const EventListing = (props) => {
    const { type, eventId, eventType, status, filterValueState, flag, resetFilterValue, setChannelFlag, getCount } = props;
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [total, setTotal] = useState(0);
    let [isConfidential, setConfidential] = useState(0);
    let [isLoading, setLoading] = useState(true);
    let [data, setData] = useState([]);
    const [decline, setDecline] = useState(false);
    const [confRequest, setConfirmRequest] = useState(false);
    const [requestId, setRequestId] = useState();
    const [message, setMessage] = useState();
    const [subject, setSubject] = useState();
    const [messageHistory, setMsgHistory] = useState([]);
    const [company, setCompany] = useState();
    const [participant, setParticipant] = useState({ sender: [], receiver: [] });
    const [accessMessage, setAccessMessage] = useState(false);

    const [agendaList, setAgendaList] = useState([]);

    //BIS
    const [slot, setSlot] = useState();
    const [date, setDate] = useState();
    const [eventRequestId, setEventRequestId] = useState();
    const [slotList, setSlotList] = useState();

    let [agenda, setAgenda] = useState([]);
    const [state, setState] = useState([]);
    const [master, setMaster] = useState([]);
    /////

    let [matched, setMatch] = useState([]);

    let [confParticipant, setConfirmedParticipant] = useState({ sender: [], receiver: [] });



    useEffect(() => {
        console.log('type', type);
        getSlotDetails()
        if (type) {
            callChannelList();
            if (eventType == 2)
                getEventDetails();
        }
    }, [flag]);

    const getSlotDetails = async () => {

        // if (eventType == 2) {
            await axios.get('channel/slotlist/' + eventId)
                .then(res => {
                    setAgendaList(res.data);
                })
        
        // else if (eventType == 1) {
        //     await axios.get('channel/agendaslot/' + eventId)
        //         .then(res => {
        //             setAgendaList(res.data);
        //         })
        // }

    }

    const getMatchedSlot = async () => {

        if (confParticipant.sender.length && confParticipant.receiver.length) {
            matched = [];
            let timeslot = await axios.post('channel/timeslot', { eventId: eventId, sender: confParticipant.sender, receiver: confParticipant.receiver })
                .then(res => {
                    return res.data;
                }).catch(err => console.log("getsubject:", err));

            timeslot.senderTimeSlot.map(s => {
                timeslot.receiverTimeSlot.map(r => {
                    if (s.stime === r.stime && s.sdate === r.sdate && !matched.includes(r.stime)) {
                        matched.push({ slot: s.stime, checked: false });
                    }
                })
            })
            console.log("matched:", matched);

            setMatch(matched);
        }

    }

    const getEventDetails = async () => {
        setLoading(true);
        let data = {
            eventId: eventId,
        }

        await axios.post('/channelAdmin/connectionRequest/', data).then(res => {
            console.log('response Data', res.data);

            let result = [];
            if (res) {

                res.data.map(x => {
                    let obj = {
                        eventId: eventId,
                        eventName: x.eventName,
                        senderContact: x.sender[0].name,
                        senderCompany: x.sender[0].companyName,
                        senderJobTitle: x.sender[0].jobTitle,
                        receiverContact: x.receiver[0].name,
                        receiverCompany: x.receiver[0].companyName,
                        receiverJobTitle: x.receiver[0].jobTitle,

                        senderContactId: x.sender[0].contactId,
                        receiverContactId: x.receiver[0].contactId,

                        eventRequestId: x.eventRequestId,
                        status: x.status,
                        custom_slot: x.custom_slot,
                        custom_date: x.custom_date
                    }
                    result.push(obj);

                })

                setMaster(result);

                setState(result);

            }

            setLoading(false);
        }).catch(err => setLoading(false));

    };

    const callChannelList = () => {
        page = 0;
        setPage(page);
        getChannelList();
    }

    const handleMessageClose = () => {
        setAccessMessage(false);
    }

    const getFilterData = () => {
        let filter = {
            specialityId: null,
            brandId: null,
            countryId: null,
            productId: null,
            intProductId: null,
            locationId: null,
            retailerprofileId: null,
            resellerprofileId: null,
            productCategoryId: null,
            turnOver: null,
            connectionStatusId: null,
            typeId: null,
            storeCount: null,
            eventId: eventId
        };
        let flag = 0;

        if (filterValueState.Current_Product && filterValueState.Current_Product.length > 0) {
            filter.productCategoryId = filterValueState.Current_Product.map(x => parseInt(x.childid));
            flag = 1;
        }


        if (filterValueState.Company_Origin && filterValueState.Company_Origin.length > 0) {
            filter.countryId = filterValueState.Company_Origin.map(x => parseInt(x.id));
            flag = 1;
        }

        if (filterValueState.typeId && filterValueState.typeId.length > 0) {
            filter.typeId = filterValueState.typeId.map(x => parseInt(x.id));
            flag = 1;
        }
        if (flag == 0) {
            resetFilterValue();
            setChannelFlag(true);
        }
        return filter;
    }

    const getChannelList = async () => {
        setLoading(true);
        let filter = {};//getFilterData()
        let sendData = {
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            filter: filter
        };

        let res = await axios.post('channel/channelList/' + type + '/' + eventId + '/' + status, sendData).catch(err => setLoading(false)); //for public page url => publicChannel/channelList
        if (res) {
            data = res.data.result;
            setData(data);
            total = res.data.total;
            setTotal(total);
            setConfidential(res.data.isConfidential);
            console.log('data', data);
            setLoading(false);
        } else {
            data = [];
            setData(data);
            total = 0;
            setTotal(total);
            setLoading(false);
            ToastsStore.warning('Something went wrong');
        }
    }

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(page);
        getChannelList();
    };

    const handleChangeRowsPerPage = (event) => {
        rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(rowsPerPage);
        callChannelList();
    };

    const detailView = async (id) => {
        let tokenData = await commonService.getServices('account/getPublicToken/' + id);
        if (tokenData) {
            // history.push('channelView/'+ id+'?token='+tokenData.data.token);
            const win = window.open(process.env.REACT_APP_LOCALSITE + 'channelView/' + id + '?token=' + tokenData.data.token, '_blank');
            win.focus();
        }
        else
            ToastsStore.warning('Something went wrong');
    }

    const handleClose = () => {
        setDecline(false);
        setConfirmRequest(false);
    };

    const handleChangeMessage = (input, value) => {
        if (value)
            setMessage(value.name)
    }

    const saveDecline = async () => {
        const userData = JSON.parse(localStorage.getItem('CHuser'));
        setLoading(true);

        let data = {
            eventRequestId: requestId,
            status: 5,
            participants: confParticipant.sender.length ? confParticipant.sender.toString() : null,
            receivers: confParticipant.receiver.length ? confParticipant.receiver.toString() : null,
            slot: matched.find(x => x.checked === true).slot,
            message: message,
            subject: subject,
            from: `${userData.firstName}' '${userData.lastName}`
        }

        await axios.post('channel/udateEventRequest', data)
            .then(res => {
                setLoading(false);

                getChannelList();
                getCount();
            })
            .catch(err => setLoading(false));

        setDecline(false);
    }

    const saveConfirm = async () => {
        const userData = JSON.parse(localStorage.getItem('CHuser'));
        setLoading(true);



        let data = {
            eventRequestId: requestId,
            status: 4,
            participants: confParticipant.sender.length ? confParticipant.sender.toString() : null,
            receivers: confParticipant.receiver.length ? confParticipant.receiver.toString() : null,
            message: message,
            slot: matched.find(x => x.checked === true).slot,
            subject: subject,
            from: `${userData.firstName}' '${userData.lastName}`
        }

        await axios.post('channel/udateEventRequest', data)
            .then(res => {
                setLoading(false);

                getChannelList();
                getCount();
            })
            .catch(err => setLoading(false));


        setConfirmRequest(false);
    }

    const DeclineRequest = () => {


        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={decline} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>MEETING REQUEST CANCELLATION</strong>

                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">


                        <div className="container">

                            {/* <div className="row"> */}

                             <strong>MESSAGE</strong>
                            <br />

                            <strong>Subject of the meeting</strong>
                            <br />
                            {subject}
                            <br />
                            <strong>Message</strong>
                            {/* <div className="row"> */}
                            {/* <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                input='message'
                                multiple={false}
                                options={messageList}
                                getOptionLabel={(option) => option.name}
                                // value={regAddress.country ? regAddress.country[0] : null}
                                onChange={handleChangeMessage}

                                renderInput={(params) => <TextField {...params} label="Use a suggested answer" />}
                            /> */}
                            {/* </div> */}

                            <div className="row">

                                <FormTextArea
                                    className="formtxtarea"
                                    name="message"
                                    id="message"
                                    rows="3"
                                    type="textarea"
                                    label=""
                                    placeholder="Message (max 1000 characters)"
                                    // register={register}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />

                            </div>




                        </div>
                    </div>



                    <div className="modal-footer">
                        <Button className="mr-2" style={{color: "#24869d"}}  type="button" onClick={() => saveDecline()}>Send Request</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }

    const confirmRequest = () => {


        return (
            <div>
                <Modal className="modal-dialog-centered meeting-dialog" fade={false} isOpen={confRequest} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        If you wish to transfer a meeting to another person of your team,please uncheck your name and check the staff concerned then, SAVE.

                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">


                        <div className="container">

                            {/* <div className="row"> */}

                            <strong>{matched.length ? `Available Time Slots are: ` : 'Thare are no common time slot'}
                            </strong>
                            {matched.map((t) =>
                                <FormControlLabel control={
                                    <Checkbox onChange={confirmSlot} name={t.slot} checked={t.checked} />}
                                    label={t.slot}

                                />)}

                            <br />

                            <strong>MESSAGE</strong>
                            <br />

                            <strong>Subject of the meeting</strong>
                            <br />
                            {subject}
                            <br />
                            <strong>Message</strong>

                            <div className="row">

                                <FormTextArea
                                    className="formtxtarea"
                                    name="message"
                                    id="message"
                                    rows="3"
                                    type="textarea"
                                    label=""
                                    placeholder="Message (max 1000 characters)"
                                    // register={register}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </div>

                            <br />
                            <strong>PARTICIPANTS</strong>

                            <Row style={{ backgroundColor: "#f8f9fe" }}>
                                <strong>{company}</strong>
                            </Row>
                            <FormGroup>
                                {participant.sender.map((row, index) =>
                                    <FormControlLabel control={
                                        index == 0 ?
                                            <Checkbox onChange={confirmSender} name={row.contactId} checked={true} disabled={true} /> :
                                            <Checkbox onChange={confirmSender} name={row.contactId} checked={row.checked} />} label={row.firstName + " " + row.lastName + "," + " " + row.jobTitle}

                                    />
                                    // <FormControlLabel control={<Checkbox onChange={confirmSender} name={row.contactId} checked={index===0 ? true : false} disabled={index===0 ? true : false} />} label={row.firstName + " " + row.lastName + "," + row.jobTitle} />
                                )}

                            </FormGroup>

                            <Row style={{ backgroundColor: "#f8f9fe" }}>
                                <strong>ADD A MEMBER OF MY COMPANY IN THIS MEETING</strong>
                            </Row>
                            <FormGroup>
                                {participant.receiver.map((row, index) =>

                                    <FormControlLabel control={
                                        index == 0 ?
                                            <Checkbox onChange={confirmReceiver} name={row.contactId} checked={true} disabled={true} /> :
                                            <Checkbox onChange={confirmReceiver} name={row.contactId} checked={row.checked} />} label={row.firstName + " " + row.lastName + "," + " " + row.jobTitle}
                                    />
                                    // <FormControlLabel control={<Checkbox onChange={confirmReceiver} name={row.contactId} checked={index === 0 ? true : false} disabled={index === 0 ? true : false} />} label={row.firstName + " " + row.lastName + "," + row.jobTitle} />
                                )}

                            </FormGroup>

                        </div>
                    </div>



                    <div className="modal-footer">
                        <Button color="primary" type="button" onClick={() => saveConfirm()}>Confirm Request</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }


    const confirmSender = async (e) => {

        if (e.target.checked) {
            let sender = participant.sender.map(x => {
                if (x.contactId == e.target.name) {
                    x.checked = true;
                }
                return x;
            })

            setParticipant({ ...participant, sender: sender });

            confParticipant.sender = [...confParticipant.sender, +e.target.name];
            setConfirmedParticipant({ ...confParticipant, sender: confParticipant.sender });
        }
        else {
            let sender = participant.sender.map(x => {
                if (x.contactId == e.target.name) {
                    x.checked = false;
                }
                return x;
            })

            setParticipant({ ...participant, sender: sender });

            confParticipant.sender = [...confParticipant.sender.filter(x => x != +e.target.name)];
            setConfirmedParticipant({ ...confParticipant, sender: confParticipant.sender });
        }

        getMatchedSlot();


    }

    const confirmSlot = async (e) => {


        if (e.target.checked) {

            setMatch((matched) => {
                return matched.map(x => {
                    if (x.slot === e.target.name)
                        x.checked = true;
                    else
                        x.checked = false;
                    return x;
                })
            })


        }
        // else {
        //     setMatch((matched) => {
        //         return matched.map(x => {
        //             if (x.slot === e.target.name)
        //                 x.checked = false;
        //             else
        //                 x.checked = false;
        //             return x;
        //         })
        //     })
        // }


    }

    const confirmReceiver = (e) => {

        if (e.target.checked) {

            let receiver = participant.receiver.map(x => {
                if (x.contactId == e.target.name) {
                    x.checked = true;
                }
                return x;
            })

            setParticipant({ ...participant, receiver: receiver });

            setConfirmedParticipant({ ...confParticipant, receiver: [...confParticipant.receiver, +e.target.name] });
        }
        else {
            let receiver = participant.receiver.map(x => {
                if (x.contactId == e.target.name) {
                    x.checked = false;
                }
                return x;
            })

            setParticipant({ ...participant, receiver: receiver });

            setConfirmedParticipant({ ...confParticipant, receiver: [...confParticipant.receiver.filter(x => x != +e.target.name)] });

            // receiverTimeSlot.map(s => {
            //     senderTimeSlot.map(r => {
            //         if (s === r) {
            //             matched.push(s);
            //         }
            //     })
            // })

            // setMatch(matched);
        }
        getMatchedSlot();
    }

    const openDecline = async (id) => {

        let sub = await axios.get('channel/getEventSubject/' + id)
            .then(res => {
                return res.data;
            }).catch(err => console.log("getsubject:", err));


        setSubject(sub.eventChat[0].subject);

        setRequestId(id);
        setDecline(true);
    }

    const openConfirm = async (id, companyName) => {

        const userData = JSON.parse(localStorage.getItem('CHuser'));

        // if (userData.channelTypeId != 2)
        //     setAccessMessage(true)
        // else {
        let sub = await axios.get('channel/getEventSubject/' + id)
            .then(res => {
                console.log("openConfirm:", res.data);
                return res.data;
            }).catch(err => console.log("getsubject:", err));

        let data = {
            participants: sub.participants.split(",")
        }

        let participants = await axios.post('channel/eventParticipants', data)
            .then(res => {
                let sender = []; let receiver = [];
                res.data['sender'].map(x => {
                    sender.push({ ...x, checked: false })
                })
                res.data['receiver'].map(x => {
                    receiver.push({ ...x, checked: false })
                })
                return { ...res.data, sender: sender, receiver: receiver };
                // return res.data;
            }).catch(err => console.log("getsubject:", err));


        confParticipant.sender = [...confParticipant.sender, ...data.participants];
        confParticipant.receiver = [...confParticipant.receiver, userData.contactId];
        setConfirmedParticipant({ ...confParticipant, sender: confParticipant.sender, receiver: confParticipant.receiver });

        setParticipant({ ...participant, sender: participants.sender, receiver: participants.receiver });

        //check initially weather users are matched or not
        getMatchedSlot();

        setSubject(sub.eventChat[0].subject);

        setCompany(companyName);

        setRequestId(id);
        setConfirmRequest(true);
        // }

    }

    //BIS Function    

    const bisOpenConfirm = (eventRequestId) => {

        let selectedUser = state.find(user => user.eventRequestId == eventRequestId);

        let user = master.filter(mas => ((mas.eventRequestId != selectedUser.eventRequestId && mas.custom_date != null && mas.custom_slot != null) && (mas.senderContactId == selectedUser.senderContactId || mas.receiverContactId == selectedUser.receiverContactId)));




        // console.log("user:",user);  
        
            for (var i = 0; i < user.length; i++) {

                // console.log("diff:", user[i].custom_slot.split('-')[0].trim(), user[i].custom_slot.split('-')[1].trim())


                agendaList.map(a => {
                    let slist = a.slotList.map(x => x.slot);
                    // console.log("slist:",slist,slist.includes(user.custom_slot));
                    if (a.date == user[i].custom_date && slist.includes(user[i].custom_slot)) {
                        a.slotList.map(s => {
                            if (s.slot == user[i].custom_slot)
                                s.available = true
                        })
                    }
                    else {
                        a.slotList.map(s => {
                            s.available = false
                        })
                    }
                })

            }
     

        console.log("slotuser:", user)
        if (user.length == 0) {
            agendaList.map(a => {
                a.slotList.map(s => {
                    s.available = false
                })
            })
        }

        agendaList.map(a => {
            var date = new Date(a.date);
            var currentyear = date.getFullYear();
            var currentdate = date.getDate();
            var currentmonth = date.getMonth() + 1;
            var currenthour = date.getHours();
            var currentmin = date.getMinutes();

            if ((parseInt(a.date.split('-')[0]) == currentyear && parseInt(a.date.split('-')[1]) == currentmonth && parseInt(a.date.split('-')[2]) < currentdate) ||
                (parseInt(a.date.split('-')[0]) == currentyear && parseInt(a.date.split('-')[1]) < currentmonth))
                a.available = true
            else
                a.slotList.map(s => {
                    if ((parseInt(a.date.split('-')[0]) == currentyear && parseInt(a.date.split('-')[1]) == currentmonth && parseInt(a.date.split('-')[2]) == currentdate && parseInt(s.slot.split('-')[0].trim()) == currenthour && parseInt(s.slot.split('-')[1].trim()) < currentmin) ||
                        (parseInt(a.date.split('-')[0]) == currentyear && parseInt(a.date.split('-')[1]) == currentmonth && parseInt(a.date.split('-')[2]) == currentdate && parseInt(s.slot.split('-')[0].trim()) < currenthour))
                        s.available = true
                })
        })


        setAgenda(agendaList);
        setEventRequestId(eventRequestId);
        setConfirmRequest(true)
    }

    const bisSaveConfirm = async () => {

        setLoading(true);
        let startTime = slot.split('-')[0].trim();
        // console.log("startTime:", startTime)
        let stime = startTime.split('.')[0] + ':' + startTime.split('.')[1] + ':00'

        let duration = (parseFloat(slot.split('-')[1].trim()) - parseFloat(slot.split('-')[0].trim())).toFixed(2);
        let start_time = date + 'T' + stime

        duration = duration < 1 ? parseInt(duration.toString().split('.')[1]) : parseFloat(duration)
          


            let data = await axios.post('channel/getZoomMeetingUrl', { duration: duration, start_time: start_time })
                .then(res => {
                    let result = {
                        status: 4,
                        eventRequestId: eventRequestId,
                        custom_date: date,
                        custom_slot: slot,
                        zoomLink: res.data,
                        isAdmin: true
                    }
                    return result;
                });

            if (data.zoomLink) {

                await axios.post('channel/udateEventRequest', data)
                    .then(res => {
                        let req = state.find(x => x.eventRequestId == eventRequestId);
                        axios.post('mailerservice/eventRequestConfirmMail', { ...req, status: 4, zoomLink: data.zoomLink, custom_date: data.custom_date, custom_slot: data.custom_slot })
                        getEventDetails();
                    })
                    .catch(err => setLoading(false));

                window.location.reload();
            }
        

            await axios.post('channel/udateEventRequest', data)
                .then(res => {
                    let req = state.find(x => x.eventRequestId == eventRequestId);
                    // axios.post('mailerservice/eventRequestConfirmMail', { ...req, status: 4, custom_date: data.custom_date, custom_slot: data.custom_slot })
                    getEventDetails();
                })
                .catch(err => setLoading(false));

            window.location.reload();
        



        setConfirmRequest(false);
    }

    const bisOpenDecline = (eventRequestId) => {
        setEventRequestId(eventRequestId);
        setDecline(true)
    }

    const bisConfirmRequest = () => {


        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={confRequest} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        Confirm Meeting
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <div className="container">

                            <div className="row">

                                <FormControl sx={{ m: 1, minWidth: 320 }} size="small">

                                    <InputLabel id="demo-select-small">Select Meeting Date</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={date}
                                        label="Date"
                                        onChange={(e) => { setSlotList(agenda.find(x => x.date == e.target.value).slotList); setDate(e.target.value) }}
                                    >
                                        {agenda.map(x =>
                                            <MenuItem key={x.date} value={x.date} disabled={x.available}>
                                                <Typography>{x.date}</Typography>
                                            </MenuItem>
                                        )}

                                    </Select>
                                </FormControl>

                                <FormControl sx={{ m: 1, minWidth: 320 }} size="small">
                                    <InputLabel id="demo-select-small">Select Meeting Slot</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={slot}
                                        label="Slot"
                                        onChange={(e) => setSlot(e.target.value)}
                                    >
                                        {slotList?.map(x =>
                                            <MenuItem key={x.slot} value={x.slot} disabled={x.available}>
                                                <Typography>{x.slot}</Typography>
                                            </MenuItem>
                                        )}

                                    </Select>
                                </FormControl>



                            </div>

                        </div>
                    </div>

                    <div className="modal-footer">
                        <Button color="primary" type="button" onClick={() => bisSaveConfirm()}>Confirm Request</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }

    const bisSaveDecline = async () => {

        let data = {
            status: 5,
            eventRequestId: eventRequestId,
            isAdmin: true
        }
        await axios.post('channel/udateEventRequest', data)
            .then(res => {
                //          let req = state.find(x => x.eventRequestId == eventRequestId);
                //   axios.post('mailerservice/eventRequestConfirmMail', { ...req, status: 5 })
                getEventDetails();
            })
            .catch(err => setLoading(false));

        // window.location.reload();
    }

    const bisDeclineRequest = () => {


        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={decline} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>MEETING REQUEST CANCELLATION</strong>

                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">


                        <div className="container">
                            <strong>You want to decline this meeting request</strong>
                        </div>
                    </div>



                    <div className="modal-footer">
                        <Button color="primary" type="button" onClick={() => bisSaveDecline()}>Confirm</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }
    ////


    const openMessage = async (eventRequestId, channelId, showMessage) => {

        let subject; let message = [];

        if (!showMessage) {
            await axios.get('channel/getEventMessage/' + eventRequestId)
                .then(res => {
                    subject = res.data[0].subject;
                    res.data.map(x => {
                        let date = new Date(x.createdAt).getDate() + "/" + new Date(x.createdAt).getMonth() + "/" + new Date(x.createdAt).getFullYear();
                        message.push({ From: x.from, date: date, message: x.message });
                    })
                    setMsgHistory([{ subject: subject, message: message }])
                }).catch(err => console.log("getsubject:", err));
        }

        setData((data) => {
            return data.map(x => {
                if (x.cid == channelId && status != null) {
                    x.showMessage = !x.showMessage;
                }
                else
                    x.showRequest = false;
                return x;
            })
        })


    }

    return (
        <div id="iner" className="main-content mt-3">
            {decline &&
                (eventType == 2) ? bisDeclineRequest() : DeclineRequest()}

            {confRequest &&
                (eventType == 2) ? bisConfirmRequest() : confirmRequest()}

            {/* {accessMessage && <BuyerSessionDialog handleClose={handleMessageClose} type='Scheduler' />} */}

            <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
            {(data.length <= 0 && !isLoading) && <div className="no-records ">
                <div className="no-img text-center">
                    <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} />
                </div>
            </div>}
            {isLoading ? <Spinner /> : <>
                {data.length > 0 && data.map((item, index) => {
                    let logo = "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg";
                    return (
                        <Row className="justify-content-center">
                            <Col className="card-wrapper ct-example" lg="12" md="12" sm="12" >
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-center justify-content-center">
                                            <Col className="col-lg-2 col-md-2 col-sm-12 mobi-res">
                                                <a
                                                    style={{ width: "110px", height: "110px", border: "1px solid #b8b8b8", backgroundColor: "white", cursor: isConfidential == 1 ? 'none' : 'pointer' }}
                                                    className={"avatar avatar-xl mb-2"}
                                                    href="#pablo"
                                                    onClick={() => { isConfidential != 1 && detailView(item.cid) }}
                                                >
                                                    <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                                                        alt="..."
                                                        src={item.logo ? process.env.REACT_APP_CDN_URL + item.logo : logo}
                                                    />
                                                </a>
                                                <div style={{ cursor: isConfidential == 1 ? 'none' : 'pointer' }} onClick={() => { isConfidential != 1 && detailView(item.cid) }}>
                                                    <Badge style={{ backgroundColor: "#2c9db7", color: "white", fontSize: "0.8000rem", textTransform: "capitalize", width: "100%", }}>
                                                        +details
                                                    </Badge>
                                                </div>
                                                {item.webSiteUrl && <div className="text-center">

                                                    <a style={{ cursor: isConfidential == 1 ? 'none' : 'pointer' }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            let url = item.webSiteUrl;
                                                            if (!url.match(/^https?:\/\//i)) {
                                                                if (!url.match(/^http?:\/\//i))
                                                                    url = '//' + url;
                                                            }
                                                            if (isConfidential != 1)

                                                                window.open(url, '_blank')
                                                        }}
                                                    >
                                                        <i className="fas fa-globe-asia mr-1"></i>Website
                                                    </a>

                                                </div>}
                                            </Col>
                                            <div className="col-lg-9 col-md-9 col-sm-12 mobi-res3">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-12 mobi-res1  res-padr1">
                                                        <Badge
                                                            style={{
                                                                backgroundColor: "#FF6347",
                                                                color: "white",
                                                                fontSize: "0.9000rem",
                                                            }}
                                                        >
                                                            {item.channelType}
                                                        </Badge>
                                                    </div>
                                                    {/* flag */}
                                                    <div className="col-auto col mobi-res2">
                                                        <a className="avatar avatar-xs rounded-circle">
                                                            <CountryFlag code={item.isoCode} />
                                                        </a>
                                                    </div>

                                                    <div style={{ paddingLeft: "0px" }} className={"col-lg-4 col-md-3 col-sm-12 mobi-res pad-01"}>
                                                        <h3 className="mb-0">{item.country}</h3>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-12 pr-0 mobi-res4">


                                                        {!!item.contacts?.length && <>
                                                            <span>
                                                                {isConfidential == 1 ? 'XXXXX' + "-" + item.contacts[0]?.jobTitle : item.contacts[0]?.firstName + " " + item.contacts[0]?.lastName}

                                                            </span>
                                                            <br />
                                                            <span>
                                                                {item.contacts[0]?.jobTitle}

                                                            </span>
                                                        </>}
                                                    </div>
                                                </div>
                                                <Row>
                                                    <Col className="col-lg-auto col-md-auto col-sm-12 mobi-res2">
                                                        <h3 className={"mb-0 text-uppercase"}>
                                                            <a style={{ cursor: isConfidential == 1 ? 'none' : 'pointer' }}
                                                                href="#pablo"
                                                                onClick={() => { isConfidential != 1 && detailView(item.cid) }}
                                                            >
                                                                {item.companyName}
                                                            </a>
                                                        </h3>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-lg-7 col-md-6 col-sm-12">
                                                        <h4>
                                                            Specialty in/Categories:
                                                            <p className="ml-2" style={{ display: "inline" }}>
                                                                {item.company_cat && <ListItem elements={item.company_cat} index={'cat' + index} limit={4} />}
                                                            </p>
                                                        </h4>
                                                        {item.channelTypeId !== '4' && <h4>
                                                            Channel Customer Profiles: &nbsp;
                                                            <p style={{ display: "inline" }}>
                                                                {item.retail_customer_profile && <ListItem elements={item.retail_customer_profile} index={'pro' + index} limit={4} />}
                                                            </p>
                                                        </h4>}
                                                        {item.channelTypeId === '4' && <h4>
                                                            Interest In Start-Up : &nbsp;
                                                            <p style={{ display: "inline" }}>
                                                                {item.looking_startups && item.looking_startups[0].item_name && item.looking_startups[0].item_name.replace('S_VOLUME', ' Shipping in volume').replace('S_BACKER', ' Shipping to first backers').replace('PROTOTYPE', ' Prototype')}
                                                            </p>
                                                        </h4>}
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col className="col-auto">
                                                        <h3 className="mb-0 text-uppercase">
                                                            <span
                                                                href="#pablo"
                                                                onClick={(e) => e.preventDefault()}
                                                                style={{ color: "#80807F" }}

                                                            >
                                                                Tweet Pitch
                                                            </span>
                                                        </h3>
                                                        <p>
                                                            {!!item.detailDesc ? item.detailDesc.replace(/&amp;/g, '&').replace(/<[^>]+>/g, '') : ''}
                                                        </p>
                                                        {status == 4 && <>

                                                            <div className='d-flex mt-2' style={{ alignItems: 'center' }}>
                                                                <span> <i class="fas fa-calendar-alt" style={{ fontSize: "24px", color: "#0e72ed" }} ></i>
                                                                </span>
                                                                <span style={{ margin: "0px 10px 0 10px" }}>:</span>
                                                                <span style={{ margin: "0px 10px 0 10px" }}>{`${item.contacts[0].custom_date}  |  ${item.contacts[0].custom_slot}`}</span>

                                                            </div>

                                                            
                                                                <div className='d-flex mt-2' style={{ alignItems: 'center' }}>
                                                                    <span> <i class="fas fa-video" onClick={() => window.open(item.contacts[0].zoomLink, '_blank')} style={{ fontSize: "24px", color: "#0e72ed" }} ></i>
                                                                    </span>
                                                                    <span style={{ margin: "0px 10px 0 10px" }}>:</span>
                                                                    <span style={{ margin: "0px 10px 0 10px" }}><a href="#pablo" onClick={() => window.open(item.contacts[0].zoomLink, '_blank')}> {item.contacts[0].zoomLink} </a></span>

                                                                </div>

                                                        </>}
                                                    </Col>

                                                </Row>
                                            </div>

                                            <Col className="col-lg-1 col-md-1 col-sm-12 mobi-res5">
                                                {/* <i class="far fa-heart heart-o"  aria-hidden="true"></i> */}
                                                {/* <a href="#" onClick={() => openFavourite(!Favorite)} ><i class="fas fa-heart heart"  aria-hidden="true"></i></a>
                      { Favourite && <Favourities/>} */}
                                                {/* {(isConfidential == false || activeTab == '15') ? '' : */}
                                                {(status == 3) && <>
                                                    <i className="fas fa-check" style={{margin: "0px 8px 0 0px", 'font-size': '28px', 'color': 'green'}} onClick={() => (eventType == 2) ? bisOpenConfirm(item.contacts[0].eventRequestId) : openConfirm(item.contacts[0].eventRequestId, item.companyName)}></i>
                                                    <i className="fas fa-times" style={{margin: "0px 8px 0 0px", 'font-size': '28px', 'color': 'red'}} onClick={() => (eventType == 2) ? bisOpenDecline(item.contacts[0].eventRequestId) : openDecline(item.contacts[0].eventRequestId)}></i>
                                                </>}

                                                {(status == 4) && <>
                                                    <i className="fas fa-pencil-alt" style={{ margin: "0px 8px 0 0px", 'font-size': '28px', 'color': 'green' }} onClick={() => (eventType == 2) ? bisOpenConfirm(item.contacts[0].eventRequestId) : openConfirm(item.contacts[0].eventRequestId, item.companyName)}></i>
                                                    <i className="fas fa-times" style={{ margin: "0px 0px 0 8px", 'font-size': '28px', 'color': 'red' }} onClick={() => (eventType == 2) ? bisOpenDecline(item.contacts[0].eventRequestId) : openDecline(item.contacts[0].eventRequestId)}></i>
                                                </>}

                                                {(status == 5) && <>
                                                    <i className={item.showMessage ? "fas fa-times" : "fas fa-envelope"} style={{ 'font-size': '28px' }} onClick={() => openMessage(item.contacts[0].eventRequestId, item.cid)}></i>
                                                </>}

                                                {/* } */}
                                                {/* <UncontrolledTooltip placement='bottom'>
                        Add Favourite
                      </UncontrolledTooltip> */}
                                                {/* {activeTab != '15' && <div id={'view' + item.channelId} className="pointer" onClick={isConfidential == false ? openUpgradePopup : () => { activeTab > 6 ? history.push('/admin/' + toBreadCrumb + '/channelDetail/' + item.cid) : history.push('/admin/mynetwork/channelDetail/' + item.cid) }}>
                          <i
                            style={{ fontSize: "2em", cursor: "pointer" }}
                            className="far fa-eye float-right"
                          ></i>
                          <UncontrolledTooltip target={'view' + item.channelId} placement='bottom'>
                            View Channel
                          </UncontrolledTooltip>
                        </div>} */}
                                            </Col>

                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                            {item.showMessage &&
                                <Col className="card-wrapper ct-example" lg="12" md="12" sm="12" >

                                    {messageHistory.map((list) => <>
                                        <h4>Subject of the meeting:</h4>
<p>{list.subject}</p>
                                        <br />
                                        <i className="fas fa-envelope"></i><strong> Message History</strong>
                                        <br />
                                        {list.message.map((msg) =>
                                            <Row>
                                                 <div className="card-wrapper ct-example col-12">
                                                    {msg.message}
                                                </div>
                                                <div className="card-wrapper ct-example col-12" style={{float:"right",widt:"100%",textAlign:'right',borderBottom:"1px solid #eee"}}>
                                                    From: {`${msg.From},${msg.date}`}
                                                </div>
                                               
                                            </Row>
                                        )}
                                    </>
                                    )}

                                </Col>
                            }

                        </Row>)
                })}
                {total > 10 &&
                    <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 20, 30, 40]}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                }
            </>}
        </div >

    );
};
export default EventListing;
