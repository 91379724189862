import React, { useState } from "react";
import { Card,CardBody,CardHeader, Container,Collapse,Input } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import {BrandCard} from "views/Hoc/BrandCard";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
const ExportMentor = (props) => {
  const [target, settarget] = React.useState('');


  const [openedCollapses, collapsesToggle ] = useState([]);
  const collapsesToggler = collapse => {
   setState({...state, tar : ''});
    let openedCollapse = openedCollapses;
    if (openedCollapse.includes(collapse)) {
    collapsesToggle(
      []
      );
    } else {
      collapsesToggle(
        [collapse]
      );
    }
  };
  const [state, setState] = useState({
    ret : '',
    dist : '',
    sales:'',
    tar:'',
    checkedA: false,
    checkedB: false,
    checkedC: false
  });
  //const toggle = () => setIsOpen(!isOpen);
  const handleChange = (event) => {
    settarget(event.target.value);
  };
   
  const handleChangeEve = input => e => {
    console.log('inp',typeof input==Boolean)
    
    if(typeof input=='string'){

      setState({...state, [input] : e.target.value});
      console.log('state',state);
    }
    else{
      setState({...state, [e.target.name] : e.target.checked });
    }
    
  }
  
  const handleClick = () => {
    
  };
  
return (
<div className="main-content" id='exportVen'>
        <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>
              <Card>
              <CardHeader>
                 <h3 className="text-center">Export Mentor</h3>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12  ">
            <div className="form-group ">        
           
                      <Autocomplete
                      multiple
                      name='tar'
                      limitTags={2}
                    
                      id="multiple-limit-tags"
                      options={top100Films}
                      getOptionLabel={(option) => option.name}
                      defaultValue={[top100Films[1]]}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Country" placeholder="Country" />
                      )}
                    />
                 </div>           
            </div>
            </div>
            <div className='exportWrapper'>
          
            <div className="ct-example">
              {/* Selling */}
            <div className="accordion"> 
          <Card className="card-plain mb-0">
            <CardHeader  role="tab"
              aria-expanded={openedCollapses.includes(
                "collapseOne"
              )} className="p-1">
            <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group mt-3 text-left">        
            <Chip
        size="medium"
        label="India"
        onDelete={handleClick}
        deleteIcon={<DoneIcon />}
        onClick={handleClick}
      />
                     
                 </div>           
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="form-group mb-0 mt-1 target">        
            <FormControl variant="outlined">
        <InputLabel id="demo-simple-select-outlined-label">Is</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.target}
          onChange={handleChangeEve('tar')}
          label="Is"
          style={{width:'100px'}}
        >
          <MenuItem value='tar'>Target</MenuItem>
          <MenuItem value='tar1'>Selling</MenuItem>
        </Select>
      </FormControl>
                 </div>           
            </div>
            <div style={{zIndex:'99'}}  onClick={() => collapsesToggler("collapseOne")} className="col-lg-2 col-md-2 col-sm-2">
    
              </div>
            </div>
            </CardHeader>
          
            <Collapse  role="tabpanel"
              isOpen={openedCollapses.includes("collapseOne") || state.tar=='tar'}>
     
              <CardBody>
            
              <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4">
               <h3>What are your target customers</h3>
              </div>
                  <div className="col-lg-8 col-md-8 col-sm-8">
                  <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedA}
            onChange={handleChangeEve(true)}
            name="checkedA"
            color="primary"
          />
        }
        label="Distributors"
      />
         <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedB}
            onChange={handleChangeEve(true)}
            name="checkedB"
            color="primary"
          />
        }
        label="Retailers"
      />
         <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedC}
            onChange={handleChangeEve(true)}
            name="checkedC"
            color="primary"
          />
        }
        label="Marketplace"
      />
      </div>
      </div>
      {state.checkedA ? (
      <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 className='mt-2' style={{display:'inline-block'}} >Do you have a list of target Distributors</h3>
                      <FormControl component="fieldset" >
    <RadioGroup aria-label="Distributors" name="Distributors"   row value={state.dist} onChange={handleChangeEve('dist')}>
      <FormControlLabel  labelPlacement="start" value="Yes" control={<Radio />} label="Yes" />
      <FormControlLabel  labelPlacement="start" value="No" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>
              </div>
</div> 
      ):''}  
{state.dist === 'Yes' ? (
<div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <BrandCard  name="BMW" img="http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"/>
              <Autocomplete
        limitTags={2}
        id="Distributors"
        options={top100Films}
        getOptionLabel={option => option.name}
        
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src="https://cdn.auth0.com/blog/react-js/react.png" />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Distributors" placeholder="Distributors" />
        )}
      />
     
</div>
<div className="dropdown-divider mb-5 mt-3"></div>
</div>

):''}

{state.checkedB ? (
<div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 className='mt-2' style={{display:'inline-block'}} >Do you have a list of target Retailers</h3>
                      <FormControl component="fieldset" >
    <RadioGroup aria-label="Retailers" name="Retailers"   row value={state.ret} onChange={handleChangeEve('ret')}>
      <FormControlLabel  labelPlacement="start" value="Yes" control={<Radio />} label="Yes" />
      <FormControlLabel  labelPlacement="start" value="No" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>
              </div>
</div> 
):''}  
{state.ret === 'Yes' ? (
<div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <BrandCard  name="BMW" img="http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"/>
              <Autocomplete
        limitTags={2}
        id="Retailers"
        options={top100Films}
        getOptionLabel={option => option.name}
        
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src="https://cdn.auth0.com/blog/react-js/react.png" />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Retailers" placeholder="Retailers" />
        )}
      />
     
</div>
<div className="dropdown-divider mb-5 mt-3"></div>
</div>
):''}

{state.checkedC ? (
<div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 className='mt-2' style={{display:'inline-block'}} >Do you have sales agent covering</h3>
                      <FormControl component="fieldset" >
    <RadioGroup aria-label="sales" name="sales"   row value={state.sales} onChange={handleChangeEve('sales')}>
      <FormControlLabel  labelPlacement="start" value="Yes" control={<Radio />} label="Yes" />
      <FormControlLabel  labelPlacement="start" value="No" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>
              </div>
</div>
):''}   
{state.sales === 'Yes' ? (
<div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <BrandCard  name="BMW" img="http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"/>
              <Autocomplete
        limitTags={2}
        id="sales agent"
        options={top100Films}
        getOptionLabel={option => option.name}
        
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src="https://cdn.auth0.com/blog/react-js/react.png" />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="sales agent" placeholder="sales agent" />
        )}
      />
     
</div>
</div>
):''}


              </CardBody>
           
              </Collapse>
              
          </Card>
       </div>
       
        </div>
         
      {/* Selling */}


      <div className="dashed-divider"></div>
        <div className="ct-example">

              {/* Selling */}
            <div className="accordion"> 
          <Card className="card-plain mb-0">
            <CardHeader  role="tab"
              aria-expanded={openedCollapses.includes(
                "collapseTwo"
              )} className="p-1">
            <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group mt-3 text-left">        
            <Chip
        size="medium"
        label="France"
        onDelete={handleClick}
        deleteIcon={<DoneIcon />}
        onClick={handleClick}
      />
                     
                 </div>           
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="form-group mb-0 mt-1 target">        
            <FormControl variant="outlined">
        <InputLabel id="demo-simple-select-outlined-label">Is</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.tar}
          onChange={handleChangeEve('tar')}
          label="Is"
          name='tar'
          style={{width:'100px'}}
        >
          <MenuItem value={1}>Target</MenuItem>
          <MenuItem value={2}>Selling</MenuItem>
        </Select>
      </FormControl>
                 </div>           
            </div>
            <div style={{zIndex:'99'}}  onClick={() => collapsesToggler("collapseTwo")} className="col-lg-2 col-md-2 col-sm-2">
    
              </div>
            </div>
            </CardHeader>
            <Collapse  role="tabpanel"
              isOpen={openedCollapses.includes("collapseTwo")}>
              <CardBody>
              <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4">
               <h3>You are already present in [France] can you indicate your existing customers?</h3>
              </div>
                  <div className="col-lg-8 col-md-8 col-sm-8">
                  <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedA}
            onChange={handleChangeEve}
            name="checkedA"
            style={{color:'#2c9db7'}}
          />
        }
        label="Distributors"
      />
         <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedA}
            onChange={handleChangeEve}
            name="checkedA"
            color="primary"
            style={{color:'#2c9db7'}}
          />
        }
        label="Retailers"
      />
         <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedA}
            onChange={handleChangeEve}
            name="checkedA"
            color="primary"
            style={{color:'#2c9db7'}}
          />
        }
        label="Marketplace"
      />
      </div>
      </div>
      <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 style={{display:'inline-block'}} >Current distributors:</h3> <label className="custom-toggle"><input defaultChecked type="checkbox" />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="No"
            data-label-on="Yes"
          />
          </label>
              <BrandCard  name="BMW" img="http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"/>
              <Autocomplete
        limitTags={2}
        id="Distributors"
        options={top100Films}
        getOptionLabel={option => option.name}
        
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src="https://cdn.auth0.com/blog/react-js/react.png" />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Distributors" placeholder="Distributors" />
        )}
      />
     
</div>
</div>
<div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 style={{display:'inline-block'}} >Are you looking for more distributors ?</h3> <label className="custom-toggle"><input defaultChecked type="checkbox" />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="No"
            data-label-on="Yes"
          />
          </label>
              <BrandCard  name="BMW" img="http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"/>
              <Autocomplete
        limitTags={2}
        id="Retailers"
        options={top100Films}
        getOptionLabel={option => option.name}
        
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src="https://cdn.auth0.com/blog/react-js/react.png" />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Search or Add New distributors" placeholder="Search or Add New distributors" />
        )}
      />
     
</div>
</div>


<div className="dropdown-divider mb-5 mt-3"></div>

<div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 style={{display:'inline-block'}} >Current distributors:</h3> <label className="custom-toggle"><input defaultChecked type="checkbox" />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="No"
            data-label-on="Yes"
          />
          </label>
              <BrandCard  name="BMW" img="http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"/>
              <Autocomplete
        limitTags={2}
        id="Distributors"
        options={top100Films}
        getOptionLabel={option => option.name}
        
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src="https://cdn.auth0.com/blog/react-js/react.png" />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Retailer" placeholder="Retailer" />
        )}
      />
     
</div>
</div>
<div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 style={{display:'inline-block'}} >Are you looking for more Retailer ?</h3> <label className="custom-toggle"><input defaultChecked type="checkbox" />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="No"
            data-label-on="Yes"
          />
          </label>
              <BrandCard  name="BMW" img="http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"/>
              <Autocomplete
        limitTags={2}
        id="Retailers"
        options={top100Films}
        getOptionLabel={option => option.name}
        
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src="https://cdn.auth0.com/blog/react-js/react.png" />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Search or Add New Retailer" placeholder="Search or Add New Retailer" />
        )}
      />
     
</div>
</div>


<div className="dropdown-divider mb-5 mt-3"></div>

<div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 style={{display:'inline-block'}} >Current distributors:</h3> <label className="custom-toggle"><input defaultChecked type="checkbox" />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="No"
            data-label-on="Yes"
          />
          </label>
              <BrandCard  name="BMW" img="http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"/>
              <Autocomplete
        limitTags={2}
        id="Distributors"
        options={top100Films}
        getOptionLabel={option => option.name}
        
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src="https://cdn.auth0.com/blog/react-js/react.png" />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Marketplace" placeholder="Marketplace" />
        )}
      />
     
</div>
</div>
<div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 style={{display:'inline-block'}} >Are you looking for more marketplace ?</h3> <label className="custom-toggle"><input defaultChecked type="checkbox" />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="No"
            data-label-on="Yes"
          />
          </label>
              <BrandCard  name="BMW" img="http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"/>
              <Autocomplete
        limitTags={2}
        id="Retailers"
        options={top100Films}
        getOptionLabel={option => option.name}
        
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src="https://cdn.auth0.com/blog/react-js/react.png" />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Search or Add New marketplace" placeholder="Search or Add New Marketplace" />
        )}
      />
     
</div>
</div>


              </CardBody>
              </Collapse>
          </Card>
       </div>
        </div>
      
        </div>
          </CardBody>
          </Card>
         
              </Container>
      </div>
    
   
  );
};
const top100Films = [
  { name: 'India', id: 1994 },
  { name: 'France', id: 1972 },
  { name: 'The Godfather: Part II', id: 1974 },
  { name: 'The Dark Knight', id: 2008 },
  { name: '12 Angry Men', id: 1957 },
  { name: "Schindler's List", id: 1993 },
  { name: 'Pulp Fiction', id: 1994 },
  { name: 'The Lord of the Rings: The Return of the King', id: 2003 },
  { name: 'The Good, the Bad and the Ugly', id: 1966 },
  { name: 'Fight Club', id: 1999 },
  { name: 'The Lord of the Rings: The Fellowship of the Ring', id: 2001 },
  { name: 'Star Wars: Episode V - The Empire Strikes Back', id: 1980 },
  { name: 'Forrest Gump', id: 1994 },
  { name: 'Inception', id: 2010 },
  { name: 'The Lord of the Rings: The Two Towers', id: 2002 },
  { name: "One Flew Over the Cuckoo's Nest", id: 1975 },
  { name: 'Goodfellas', id: 1990 },
  { name: 'The Matrix', id: 1999 },
  { name: 'Seven Samurai', id: 1954 },
  { name: 'Star Wars: Episode IV - A New Hope', id: 1977 },
  { name: 'City of God', id: 2002 },
  { name: 'Se7en', id: 1995 },
  { name: 'The Silence of the Lambs', id: 1991 },
  { name: "It's a Wonderful Life", id: 1946 },
  { name: 'Life Is Beautiful', id: 1997 },
  { name: 'The Usual Suspects', id: 1995 },
  { name: 'Léon: The Professional', id: 1994 },
  { name: 'Spirited Away', id: 2001 },
  { name: 'Saving Private Ryan', id: 1998 },
  { name: 'Once Upon a Time in the West', id: 1968 },
  { name: 'American History X', id: 1998 },
  { name: 'Interstellar', id: 2014 },
];

const startUp = [
  {name : 'Prototype', id: 1},
  {name : 'Shipping to first backers', id: 2},
  {name : 'Shipping in volume', id: 3},
];

const radioGroupOptions = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
 
];

export default ExportMentor;

