import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink, Row, Col, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown,} from "reactstrap";
import { commonService } from '_services/common.service';
import { SelectAutocomplete } from  '_components/FormElements/FormInput';
import { useForm} from "react-hook-form";




const ProfileListingTabs = (props) => {
  const { userStatus, ActiveTabs, activeTab, listing, ActivePage, handleChangeSearch, viewId,profileViews,unknownProfileViews } = props;
  const { control } = useForm({ mode:'onBlur' });
  const [searchState, setSearch] = useState({search:null, dropdownList:[]});
  const [sort, setSort] = useState('asc');

  useEffect(() => {
    
  }, [activeTab]);
  
const getProductSearchList= async()=>{
  let res = await commonService.getServiceWithParams({
    channelId:userStatus.channelId,
    contactId:userStatus.contactId,
    channelTypeId:userStatus.channelTypeId
  },'product/productSearch/'+activeTab);
  if(res){searchState.dropdownList = res.data
  setSearch({ ...searchState });
}
}

const getContactNameList= async()=>{
  let res = await commonService.getServices('channel/conNameListFilter');
  if(res){
    searchState.dropdownList = res.data
  setSearch({ ...searchState });
}
}

const getChannelSearchList= async()=>{
  let res = await commonService.getServiceWithParams({
    channelId:userStatus.channelId,
    contactId:userStatus.contactId,
    channelTypeId:userStatus.channelTypeId
  },'channel/getFoCompanyList/'+activeTab+'/'+userStatus.planId);
  if(res){
    searchState.dropdownList = res.data.results
    setSearch({ ...searchState });
}
}
  
  
  const setTabs = item => {
    resetSearch();
    ActiveTabs(item);
  }
  
  const resetSearch=()=>{
    searchState.dropdownList= [];
    searchState.search= null;
    setSearch({ ...searchState });
    handleChangeSearch('search', null);
  }
  const handleChangeSelect = (input, value)=>{
    searchState.search= value;
    setSearch({ ...searchState });
    handleChangeSearch(input, value);
  }
  return (
    <Nav tabs className="list-tab">

{
  viewId=='1' ?
    
    <NavItem className="list-ven" style={listing === '/admin/mynetwork'? {paddingTop : '0rem'}:{paddingTop : '2rem'}}>
    <NavLink className={classnames({ active: viewId == "1" })} >
      <Col xs="6" className=" mt-2 sub-a">
    <h4>Profile View</h4>
    <span>{ "Direct: "+(profileViews-unknownProfileViews)}</span> <span className="ml-2">{ "Unknown: "+unknownProfileViews}</span>
    {/* <span>{ tabCount?tabCount.outbound_count:0 }</span> */}
      </Col>
    </NavLink>
    </NavItem>

:
viewId=='1' ?
    <NavItem className="list-ven" style={listing === '/admin/mynetwork'? {paddingTop : '0rem'}:{paddingTop : '2rem'}}>
    <NavLink className={classnames({ active: viewId == "2" })} >
    <h4>Product View</h4>
    {/* <span>{ tabCount?tabCount.confirm_count:0 }</span> */}
    </NavLink>
    </NavItem>
    :
    <NavItem className="list-ven" style={listing === '/admin/mynetwork'? {paddingTop : '0rem'}:{paddingTop : '2rem'}}>
    <NavLink className={classnames({ active: viewId == "3" })} >
    <h4>Follower View</h4>
    {/* <span>{ tabCount?tabCount.confirm_count:0 }</span> */}
    </NavLink>
    </NavItem>

    }

    <NavItem className="pl-2 right-search" style={listing === '/admin/mynetwork'? {top : '0.2rem'}:{top : '3.5rem'}}>
    <SelectAutocomplete
                            id="search"
                            type="text"                
                            label="search"
                            name="search"
                            options={searchState.dropdownList}
                            placeholder="search..."
                            disableCloseOnSelect
                            // defaultValue={searchState.search?searchState.search[0]:null}
                            control={control}                         
                            input='search'
                            value={searchState.search}
                            handleChangeSelect={handleChangeSelect}
                        /> 
            </NavItem>
            <NavItem className="right-sort" style={listing === '/admin/mynetwork'? {top : '0.2rem'}:{top : '3.8rem'}}>
                <UncontrolledDropdown className="p-0" group>
                  <DropdownToggle caret  className="p-2">
                    <h3 style={{ display: "inline-block" }}className="font-weight-bold m-0">
                      <i className="fa fa-bars  mr-2" aria-hidden="true"></i>
                     {sort==='asc'?'A-Z':'Z-A'}
                    </h3>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => setSort('asc')}>A-Z</DropdownItem>
                    <DropdownItem onClick={() => setSort('desc')}>Z-A</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
            </NavItem>
    </Nav>
  );
};


export default ProfileListingTabs;