import React, { useState, useEffect } from "react";
import { FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import { Container, Card, CardBody, Row, Col, Button, CardImg } from "reactstrap";
import GuestFooter from "./GuestNavbar/GuestFooter";
import GuestNavBar from "./GuestNavbar/GuestNavbar";
import { Link } from "react-router-dom";
import GuestProductlist from "./GuestNew/GuestProductlist";
import { commonService } from "_services/common.service";
import GuestViewContact from "../../Hoc/Dialog/GuestViewContact";
import axios from 'axios';
import firstImg from 'assets/img/guestpage/business.jpg';
import secondImg from 'assets/img/guestpage/closeup.jpg';
import thiredImg from 'assets/img/guestpage/desk-gadgets.jpg';
import fourth from 'assets/img/guestpage/smartphone-with-earphones.jpg';
import { useForm } from "react-hook-form";
import { history } from "_helpers/history";
import { browserName, browserVersion } from "react-device-detect";
const Guestbuyer = (props) => {
  const options = top100Films.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });
  const { register, control } = useForm({ mode: 'onBlur' });
  const [login, setLogin] = useState(false);
  const [categoriesOption, setCategoriesOption] = useState([]);
  const [categoriesValue, setCategoriesValue] = useState([]);
  const [parent, setParent] = useState(null);

  const getCategories = async () => {
    let data = {
      countryId: countriesValue.length ? countriesValue.map(x => +x.childId) : null
    }
    await commonService.postService(data, 'guest/getCategories').then(res => {
      setCategoriesOption(res.data.productCat);
    });
  }



  const [countriesOption, setCountriesOption] = useState([]);
  const [countriesValue, setCountriesValue] = useState([]);
  const [flag, setFlag] = useState(false);

  const getCountries = async () => {
    let data = {
      categoryId: categoriesValue.length ? categoriesValue.map(x => +x.childid) : null
    }
    await commonService.postService(data, 'guest/country/').then(res => {
      setCountriesOption(res.data.countries);
    });
  }


  useEffect(() => {
    getCategories();
    getCountries();
    updatenavigation();
  }, [])

  const updatenavigation = async () => {
    let navigationData = {}
    navigationData.browser = browserName + ' , Version :' + browserVersion;;
    navigationData.navigatedPage = window.location;
    axios.post('/guest/updateNavigationHistory', navigationData);
  }

  useEffect(() => {
    if (flag) {
      setViewResult(false);
      if (parent !== 'category')
        getCategories();
      else if (parent !== 'country')
        getCountries();
      getFilterData();
    }
    setFlag(true)
  }, [categoriesValue, countriesValue])

  const [search, setSearch] = useState({});
  const [viewResult, setViewResult] = useState(false);

  const getFilterData = async () => {
    let data = {
      filter: {
        categoryId: categoriesValue.length ? categoriesValue.map(x => +x.childid) : null,
        countryId: countriesValue.length ? countriesValue.map(x => +x.childId) : null,
      }
    }

    await commonService.postService(data, 'guest/buyerSearchCount').then(res => {
      setSearch(res.data);
      if (categoriesValue.length || countriesValue.length)
        setViewResult(true);
    })

  }

  const [video, setVideo] = useState({
    video1: false,
    video2: false,
    video3: false
  });



  const playVideo = (video1) => {
    video[video1] = !video[video1];
    setVideo({ ...video });
    var myVideo = document.getElementById(video1);
    if (myVideo) {
      if (video[video1])
        myVideo.play();
      else
        myVideo.pause();
    }
  }
  const openLogin = () => {
    localStorage.removeItem('guest-product-fav', props.match.params.id);
    localStorage.removeItem('guest-channel-fav', props.match.params.id);
    setLogin(true);
  }
  const handleClose = () => {
    setLogin(false);
  }

  const handleChangeSelect = (input, value) => {
    console.log('input, value', input, value);
    if (input === 'Category') {
      if (countriesValue.length == 0)
        setParent('category');
      setCategoriesValue(value);
    }
    if (input === 'Country') {
      if (categoriesValue.length == 0)
        setParent('country');
      setCountriesValue(value);
    }
  }

  const [ImgURL, setImgURL] = useState({});

  useEffect(() => {
    const images = [
      { url: secondImg },
      { url: firstImg },
      { url: thiredImg },
      { url: fourth }
    ];
    let x = Math.floor((Math.random() * 4) + 0);
    const ImgURL1 = {
      background: "linear-gradient(to right,#2ca5cdc7, #e8870194), url(" + images[x].url + ") no-repeat top center"
    };
    setImgURL(ImgURL1);
  }, [])
  return (
    <div className="main-content">
      <GuestNavBar />
      <GuestViewContact type="buyer" open={login} handleClose={handleClose} />
      <div className="heroContainer">
        <div className="hero-section" style={ImgURL}>
          <div style={{ width: '100%' }} class="hero-image1 about-img1">
          </div>
          <div className="searchSection mb-0">
            <h1 className="text-center  ml-3 mt-5 sertitl">Make your own product search in our database</h1>
            <CardBody className="d-flex justify-content-center">
              <div className="firstFiltrer mr-4">
                <FormAutocompleteGrouping
                  id="Category"
                  name="Category"
                  multiple={true}
                  Options={categoriesOption}
                  //placeholder="Select Product Families"
                  input='Category'
                  label="With Categories"
                  //renderInput={(params) => <TextField {...params} label="With Categories" variant="outlined" />}
                  //errMessage={'Please Select a Product Family'}
                  //error={!errors.productCat || productFamilies.length ?false:true}
                  defaultValue={categoriesValue}
                  value={categoriesValue}


                  getOptionSelected={(options, value) => options.name === value.name}
                  handleChangeSelect={handleChangeSelect}
                  //open={true}
                  control={control}
                //register={register}

                />
                {/* <Autocomplete
                  id="Category"
                  options={categoriesOption} //{categoriesOption.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  // groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="By Product Categories" variant="outlined" />}
                  onChange={(e, v) => setCategoriesValue(v)}
                  value={categoriesValue}
                  multiple={true}
                  limitTags={2}
                /> */}
              </div>
              <div className="secondfiltrer mr-4">
                <FormAutocompleteGrouping
                  id="Country"
                  name="Country"
                  multiple={true}
                  Options={countriesOption}
                  //placeholder="Select Product Families"
                  input='Country'
                  label="With Countries"
                  //renderInput={(params) => <TextField {...params} label="With Categories" variant="outlined" />}
                  //errMessage={'Please Select a Product Family'}
                  //error={!errors.productCat || productFamilies.length ?false:true}
                  defaultValue={countriesValue}
                  value={countriesValue}
                  isGroup='0'


                  getOptionSelected={(options, value) => options.name === value.name}
                  handleChangeSelect={handleChangeSelect}
                  //open={true}
                  control={control}
                //register={register}

                />
                {/* <Autocomplete
                  id="Category"
                  className="secfil"
                  options={countriesOption} //{options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  // groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="By Countries" variant="outlined" />}
                  onChange={(e, v) => setCountriesValue(v)}
                  value={countriesValue}
                  multiple={true}
                  limitTags={2}
                /> */}
              </div>
              <div className="ditt">
                <Button className="btn-icon btn-3 serbtn mr-4 invisible" type="button" onClick={() => getFilterData()}>
                  <span className="btn-inner--icon">
                    <i className="fa fa-search" />
                  </span>
                  <span className="btn-inner--text">Search</span>
                </Button>
              </div>
            </CardBody>
            {/* <CardBody className="mt-5 countSection">
    <h1 className="text-muted res text-center">MATCHING RESULTS</h1>
    <div className="text-center">
    <div className="countBox d-inline-block">
<h1>3</h1>
    </div>
    <div className="countBox d-inline-block">
    <h1>9</h1>
    </div>
    <div className="countBox d-inline-block">
    <h1>9</h1>
    </div>
    <h1 className="text-uppercase mt-5">companies</h1>
    </div>
  </CardBody> */}
          </div>
          <div className="heroButtons gsbuyer">
            <Link>  <button className="btn btn-md mr-2 free fs1" onClick={openLogin}>Try for Free </button> </Link>
            <a onClick={() => history.push('/Scheduler')}><button className="btn btn-primary btn-md ml-2 fs1"> Book a Demo</button></a>
            {/* href="https://marketing.channelhub.net/meetings/farouk1" target="_blank"   */}
          </div>
        </div>
      </div>
      {viewResult && <Card>
        <CardBody>
          <div className='countshow mt-2'>
            <Container>
              <Row><Col lg='12' className='text-center'>
                <div>
                  <h2>
                    Numbers Matching your search criteria
                  </h2>
                </div>
              </Col></Row>

              <div className='mt-2'> <Row>
                <Col lg='6' className='text-center'>
                  <div>
                    <h1>{search?.product?.count ? search?.product?.count : 0} </h1>
                    <p>
                      New products
                    </p>
                  </div>
                </Col>
                {/* <Col lg='4' className='text-center bter'>
                    <div>
                        <h1>{search?.result?.distributor} +</h1>
                        <p>
                            Distributors connects with Retailes and Sales rep
                        </p>
                    </div>
                    </Col> */}
                <Col lg='6' className='text-center'>
                  <div>
                    <h1>{search?.result?.vendor ? search?.result?.vendor : 0} </h1>
                    <p>
                      Vendors showcasing their brands </p>
                  </div>
                </Col>
              </Row></div>

              <Row><Col lg='12' className='text-center'>
                <div>
                  <p>
                    <button onClick={openLogin} class="btn btn-primary btn-md ml-2">View More Details</button>
                  </p>
                </div>
              </Col></Row>
            </Container>
          </div>
        </CardBody>
      </Card>}
      <Card>
        <CardBody>
          <div class="hero-text-about">
            <h1 className='text-center mt-5'>Discover new products and vendors from around the world and improve your assortment</h1>
            <div className="container"><p className="text-muted">Tell us what you like or what you are looking for and we’ll show you matching products and connect you with the brand in just a few clicks.
            </p></div>
            <Container>
              <div className="container"><h2>Hundreds of selected vendors are listed on ChannelHub. Each vendor is carefully evaluated before being listed. All registered vendor have a complete company profile including corporate video and a detailed product catalog. Based on your preferences, we’ll suggest you both products and vendors and you’ll be able to contact them or request product samples.</h2></div>
            </Container>
          </div>
          {/* 
  <div className='countshow mt-8'>
                <Container>
                <Row>
                    <Col lg='4' className='text-center'>
                        <div>
                            <h1>10000 +</h1>
                            <p>
                            Retail buyers discovering new products
                            </p>
                        </div>
                    </Col>
                    <Col lg='4' className='text-center bter'>
                    <div>
                        <h1>10000 +</h1>
                        <p>
                            Retail buyers discovering new products
                        </p>
                    </div>
                    </Col>
                    <Col lg='4' className='text-center'>
                    <div>
                        <h1>10000 +</h1>
                        <p>
                            Retail buyers discovering new products
                        </p>
                    </div>
                    </Col>
                </Row>
                </Container>
            </div> */}
        </CardBody>
      </Card>

      <div className='bg-white pt-4 pb-4 plrl'>
        <div className='vendor'>
          <Container fluid>
            <div className="cypgri1 p-5">
              <Row>
                <Col lg='6'>
                  <div className="videotag">
                    <video id="video1" type="video/mp4" src="https://d2maj8ifjo1lti.cloudfront.net/videos/Guest/demo-comp.webm" onClick={() => playVideo('video1')} />
                    {!video.video1 && <div className="playbtn2">
                      <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require('assets/img/guestpage/playbtn.png')} alt="Video play button" width="94" height="94" loading="lazy" onClick={() => playVideo('video1')} />
                    </div>}
                  </div>
                </Col>
                <Col lg='6'>
                  <h2 className="content-block__heading blue-text word-reveal ipad">
                    <i class="far fa-hand-point-right mr-3"></i>PRODUCTS THAT MATCH YOUR PROFILE</h2>
                  <div className=''>
                    <ul className="content-block__list list-group">
                      <li className="content-block__list-item list-group-item pb-0">
                        <h4 className="content-block__subheading dark-text" >
                          <i class="fas fa-angle-double-right mr-2"></i>Filtering</h4>
                        <p >Filter brand and product discover by geography, product category, certifications, distribution, and more.</p>
                      </li>
                      <li className="content-block__list-item list-group-item pb-0">
                        <h4 class="content-block__subheading dark-text" ><i class="fas fa-angle-double-right mr-2"></i>Favorites</h4>
                        <p >Save the search of the product and review it later with your team.</p>
                      </li>
                      <li class="content-block__list-item list-group-item pb-0">
                        <h4 className="content-block__subheading dark-text" ><i class="fas fa-angle-double-right mr-2"></i>Notifications</h4>
                        <p >Never miss a new product release from the brands that you follow.</p>
                      </li>
                    </ul>
                  </div>
                </Col>

              </Row>
            </div>
          </Container>
        </div>
      </div>

      <div className='pt-4 pb-4 plrl mt-4'>
        <div className='vendor clrvari'>
          <Container fluid>
            <div className="cypgri1 p-5">
              <Row>
                <Col lg='6'>
                  <h2 className="content-block__heading blue-text word-reveal ipad"><i class="far fa-hand-point-right mr-3"></i>SUGGESTIONS</h2>
                  <div className=''>
                    <ul className="content-block__list list-group">
                      <li className="content-block__list-item list-group-item pb-0">
                        <h4 className="content-block__subheading dark-text" ><i class="fas fa-angle-double-right mr-2"></i>AI-driven Suggestions</h4>
                        <p >Receive highly personalized products across the channels.</p>
                      </li>
                      <li className="content-block__list-item list-group-item pb-0">
                        <h4 class="content-block__subheading dark-text" ><i class="fas fa-angle-double-right mr-2"></i>Trends</h4>
                        <p >View the products that are trending in terms of Sales and growth</p>
                      </li>
                      <li class="content-block__list-item list-group-item pb-0">
                        <h4 className="content-block__subheading dark-text" ><i class="fas fa-angle-double-right mr-2"></i>Featured Picks</h4>
                        <p >Review Brands and products that curated by Experts to diverse.</p>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg='6'>
                  <div className="videotag ipad">
                    <video id="video2" type="video/mp4" src="https://d2maj8ifjo1lti.cloudfront.net/videos/Guest/demo-product.webm" onClick={() => playVideo('video2')} />
                    {!video.video2 && <div className="playbtn2">
                      <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require('assets/img/guestpage/playbtn.png')} alt="Video play button" width="94" height="94" loading="lazy" onClick={() => playVideo('video2')} />
                    </div>}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>

      <div className='bg-white pt-4 pb-4 plrl mt-4'>
        <div className='vendor'>
          <Container fluid>
            <div className="cypgri1 p-5">
              <Row>
                <Col lg='6'>
                  <div className="videotag">
                    <video id="video3" type="video/mp4" src="https://d2maj8ifjo1lti.cloudfront.net/videos/Guest/demo-cart.webm" onClick={() => playVideo('video3')} />
                    {!video.video3 && <div className="playbtn2">
                      <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require('assets/img/guestpage/playbtn.png')} alt="Video play button" width="94" height="94" loading="lazy" onClick={() => playVideo('video3')} />
                    </div>}
                  </div>
                </Col>
                <Col lg='6'>
                  <h2 className="content-block__heading blue-text word-reveal ipad"><i class="far fa-hand-point-right mr-3"></i>PRIVATE & EFFICIENT</h2>
                  <div className=''>
                    <ul className="content-block__list list-group">
                      <li className="content-block__list-item list-group-item pb-0">
                        <h4 className="content-block__subheading dark-text" ><i class="fas fa-angle-double-right mr-2"></i>Secure</h4>
                        <p >We don’t sell any details with 3rd parties. Only vetted vendors are accepted on the platform.</p>
                      </li>
                      <li className="content-block__list-item list-group-item pb-0">
                        <h4 class="content-block__subheading dark-text" ><i class="fas fa-angle-double-right mr-2"></i>Sampling</h4>
                        <p >Request samples of the products that you are interested in</p>
                      </li>
                      <li class="content-block__list-item list-group-item pb-0">
                        <h4 className="content-block__subheading dark-text" ><i class="fas fa-angle-double-right mr-2"></i>Contacting the right interlocutor</h4>
                        <p >Not only ChannelHub connects you with matching brand, but we’ll also recommend you the right person to talk.</p>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>

      {/* <div className='bg-white pt-4 pb-4 plrl mt-4'>
  <div className='vendor'>
             <Container fluid>
                <div className="cypgri111 p-5">
                    <Row>
                        <Col lg='6'>
                        <h2 className="content-block__heading blue-text word-reveal"><i class="far fa-hand-point-right mr-3"></i>INTEGRATION WITH OUR EVENTS</h2>
                        <div className=''>
                        <ul className="content-block__list list-group">
                       <li className="content-block__list-item list-group-item pb-0">
                    <p >In addition to product and brand discovery on ChannelHub, all buyers and also attend our business-matching events. </p>
                </li>
                <li className="content-block__list-item list-group-item pb-0">
                    <p >Our AI based suggestion will be also used during the event preparation and after each event, your meetings will be saved on the ChannelHub account for future review, contact, sample ordering etc…</p>
                </li>
            </ul>
                        </div>
                        </Col>
                    <Col lg='6'>
                        <div className="videotag">
                        <video type="video/mp4" src="https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_480_1_5MG.mp4" />
                        <div className="playbtn2">
                        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={require('assets/img/guestpage/playbtn.png')} alt="Video play button" width="94" height="94" loading="lazy"/>
                        </div>  
                        </div>
                        </Col>
                    </Row>
                </div>
              </Container>
            </div>
            </div> */}

      <div className='vdist mt-7'>
        <Container fluid>
          <div className="">
            <Row>
              <Col lg='6' className='pad_gue'>
                <div className="mt-4">
                  <div className="mb-2  text-start " style={{ marginTop: "5rem" }}>
                    <h2 className='blue-text' style={{ lineHeight: "50px", lineBreak: "3px", color: '#27859a' }}><i class="far fa-hand-point-right mr-3"></i>INTEGRATION WITH OUR EVENTS</h2>
                  </div>
                  <div className="mb-2 text-justify">
                    <p>In addition to product and brand discovery on ChannelHub, all buyers and also attend our business-matching events.</p>
                    <p >Our AI based suggestion will be also used during the event preparation and after each event, your meetings will be saved on the ChannelHub account for future review, contact, sample ordering etc…</p>
                  </div>
                </div>
              </Col>

              <Col lg='6'>

                <div className="vendist">
                  <Row>
                    <Col lg="1"></Col>

                    <Col lg="8" className='pad-dat'>
                      <div class="service-list-bx bx-shadow">
                        {/* <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://infiway.kinsta.cloud/backend/public/images/uploads/2019/Dec/1575543776_our-experts.png" alt="our-experts.png">  */}
                        {/* <h6>Our Experts</h6> */}
                        <div className="geimg text-center">
                          <CardImg
                            alt="..."
                            src={require("../../../assets/img/guestpage/IT_CHANNEL.png").default}


                            top
                            onClick={() => {
                              window.open("https://www.channelconnect1to1.com", '_blank');


                            }} className="ccconect"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg="2">
                    </Col>
                  </Row>
                  <Row className="desSection">


                    <Col lg="6">
                      <div class="service-list-bx1 bx-shadow1">
                        <div className="geimg text-center">
                          <CardImg
                            alt="ch-logo" src={require("../../../assets/img/guestpage/RETAIL.png").default}

                            className="navImg ccconect"

                            onClick={() => {
                              window.open("https://www.retailconnect1to1.com", '_blank');
                            }}
                          // className="ccconect"
                          />
                        </div>

                      </div> </Col>
                    <Col lg="6">
                      <div class="service-list-bx1 bx-shadow1">
                        <div className="geimg text-center">
                          <CardImg className="navImg ccconect"
                            onClick={() => {
                              window.open("https://www.channel-summit.com", '_blank');
                            }}
                            // className="ccconect"
                            alt="ch-logo" src={require("../../../assets/img/guestpage/LOGO_CHANNEL_SUMMIT_EMEA.png").default} />
                        </div>

                      </div> </Col>

                  </Row>
                  <div className="justify-content-between mobileSection">
                    <div style={{ marginRight: "1rem" }}>
                      <CardImg className="navImg ccconect"
                        onClick={() => {
                          window.open("https://www.retailconnect1to1.com", '_blank');
                        }}
                        // className="ccconect"
                        alt="ch-logo" src={require("../../../assets/img/guestpage/RETAIL.png")} />
                    </div>
                    <div>
                      <CardImg className="navImg ccconect"
                        onClick={() => {
                          window.open("https://www.channel-summit.com", '_blank');
                        }}
                        // className="ccconect"
                        alt="ch-logo" src={require("../../../assets/img/guestpage/LOGO_CHANNEL_SUMMIT_EMEA.png")} />
                    </div>
                  </div>

                  <div className="round2-bg">

                  </div>

                  <div className="round5-bg">

                  </div>
                  <div className="">
                    <Card>

                    </Card>
                  </div>
                </div>

              </Col>


            </Row>
          </div>
        </Container>
      </div>

      <div className='bg-white pt-4 pb-0 plrl mt-4'>
        <div className='vendor'>
          <Container fluid>
            <div className="cypgri1 p-2 hero-text-about1">
              <h1 className='text-center'>Discover our featured products</h1>
              {/* <p className='text-muted text-center'>STEPS OF THE REGISTRATION GRAPHICS WITH SHORT DESCIPTION</p> */}
            </div>
            {/* <div class="text-center mb-5">
               <button class="btn btn-primary btn-md ml-2">Get Started</button>
               </div> */}
          </Container>
        </div>
      </div>

      <GuestProductlist btype={3} />

      <GuestFooter />

    </div>
  );
};

export default Guestbuyer;
const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 }];