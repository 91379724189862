
export function distributionChannel(state = {}, action) {

    switch (action.type) {
      case 'STATE':
      {
        const stateValue = action.data;
        return {          
            ...state,stateValue:stateValue
        };
      }
      case 'LOCATION':
          {
              const location = action.data;      
              return {              
                ...state,location:location
              };
            }
        
      default:
        return state
    }
  }