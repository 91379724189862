import React, { useState, useEffect } from "react";
import {
  Card, CardBody, CardHeader, Container, FormGroup, Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup, Col, Row, UncontrolledTooltip
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { QuillEditor, FormInput, FormAutocomplete, SingleAutocompleteGrouping, FormTextArea, FormAutocompleteGrouping, AddFieldWithPopUpAutocomplete } from "_components/FormElements/FormInput";
import MediaLibrary from "views/Hoc/Dialog/MediaLibrary";
import { BrandCard, ProfileCard } from "views/Hoc/BrandCard";
import { PdfCard, ImageCard } from "views/Hoc/PdfCard";
import { history } from '../../../../_helpers';
import { dashboardActions } from '../../../../_actions';
import { companyProfileSchema } from '../validation';
import { InputLabel, Select, TextField, FormControl, InputAdornment } from '@material-ui/core';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import { useBeforeFirstRender } from '_hooks/index'
import axios from 'axios';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import { ModalSpinner, Spinner } from 'views/Hoc/Spinner';
import MultiUpload from "views/Hoc/MultiUpload";
import { commonService } from '_services/common.service';
import { ImageLibraryMultipleUploads } from 'views/Hoc/Dialog/ImageLibraryMultipleUploads';
import { MultiSelectImages } from 'views/Hoc/Dialog/MultiSelectImages';
import VideoPreview from 'views/Hoc/Dialog/VideoPreview';
import { BannerUploader } from 'views/Hoc/ProfileUpload';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { DocumentName } from 'views/Hoc/Dialog/DocumentName';
var _ = require('lodash');


const Initialprofile = (props) => {

  const [prodImage, setProdImage] = useState(false);
  let [comImage, setComImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors, watch, setValue, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(companyProfileSchema),
  });

  const [brandName, setBrandName] = useState('');
  const [openadd, openAddnew] = useState(false);
  const [openDist, setOpenDist] = useState(false);
  const [openRetailer, setOpenRetailer] = useState(false);

  const [initLoad, setInitLoad] = useState(true);
  const [initialVideo, setInitialVideo] = useState('');
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [bannerId, setBannerId] = useState('');
  let [namePopup, setNamePopup] = useState(false);
  let [docName, setDocName] = useState(null);
  let [docId, setDocId] = useState(null);
  const [state, setState] = useState({
    yearOfEstablishment: '',
    companyRegNumber: '',
    companyLogo: null,
    tweetPitch: '',
    introVideo: null,
    documentId: '',
    sourcing: '',
    awards: [],
    companyName: '',
    legalAddress: '',
    country: '',
    countryList: [],
    zipCode: '',
    city: '',
    phoneNumber1: '',
    phoneNumber2: '',
    mobile: '',
    email: '',
    websiteUrl: '',
    linkedIn: '',
    insta: '',
    tradeUrls: '',
    cerificateName: '',
    isVat: '',
    regAddressId: '',
    billAddressId: '',
    b2bList: [{ id: Math.random(), value: '' }],
    deleted_B2B: [],
    certificateId: '',
    vatId: '',
    social_linkedin_id: null,
    social_instagram_id: null,
    deletedAwards: [],
    deletedDocuments: [],
    brand: [],
    brandList: [],
    brandOption: [],
    brandListUser: [], //dont worry about it. it's dummy but dont remove it.,
    productValue: [{ childid: "4", childname: "AV accessories", groupid: "1", groupname: "AV", status: true }],
    productFamilies: [],
    no_of_skus: null,
    storeCount: null,
    domesticRevenue: null,
    internationalRev: null,
    no_of_staffs: '',
    annualTurnOver: '',
    deleted_companyImages: [],

    DistributorProfileOption: [],
    DistributorProfileValue: [],

    speciality: [],
    specialityOptions: [],

    ChannelProfileValue: [{ childid: "3", childname: "broadliner", status: true }],
    ChannelProfileOption: [],
    listOfServicesOption: [],
    listOfServiceValue: [],
  })

  //Multiupload
  const [mediaState, setMediaState] = useState({
    productBrochures: [],
    bannerImages: [],
    companyImages: []
  });

  //console.log("comstate:",state);

  const watchAll = watch(['companyName', 'CRN', 'VATN', 'websiteUrl', 'city', 'insta', 'linkedIn', 'zipCode', 'Yearestablishment', 'phoneNumber1', 'phoneNumber2', 'mobile', 'email', 'tradeUrls', 'no_of_skus', 'storeCount']);//'tradeUrls',

  //localStorage
  var userData = JSON.parse(localStorage.getItem('CHuser'));

  useEffect(() => {
    getCompanyData();
  }, [])

  const getCompanyData = async () => {

    const countryUrl = '/common/getSignup/' + userData.channelTypeId;
    const apiUrl = '/setting/company';

    await axios.get('/setting/getInitialProfile').then(async res => {

      await getChannelProfile();
      state.isVat = res.data.country[0].isVat;
      state.brandOption = res.data.productBrand;
      state.productFamilies = res.data.productGroup;
      state.brandOption.push({ name: 'Add Brand', id: 0, doc_id: '0', documentpath: 'https://d35w8j22j5uly8.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg' })

      if (userData?.channelTypeId == 2)
        state.ChannelProfileOption = res.data.profileList;
      if (userData?.channelTypeId == 4 || userData?.channelTypeId == 3) {
        let key = ['Retailer'];
        state.ChannelProfileOption = res.data.profileList.filter(i => key.includes(i.groupname));
      }
      if (userData?.channelTypeId == 3) {
        let key = ['Distributor'];
        state.DistributorProfileOption = res.data.distProfile.filter(i => key.includes(i.groupname));
      }

      setState({ ...state, ChannelProfileOption: state.ChannelProfileOption });
      setState({ ...state, DistributorProfileOption: state.DistributorProfileOption });

      await axios.get('/setting/getChannelDetail').then(res => {
        state.storeCount = res.data.storeCount;
        state.no_of_skus = res.data.no_of_skus;
        state.domesticRevenue = typeId == '4' ? res.data.turnoverOnStore : res.data.revD;
        state.internationalRev = typeId == '4' ? res.data.onlineStore : res.data.revI;
        state.no_of_staffs = res.data.empCnt ? res.data.empCnt : res.data.empCntRange.replace(/\s/g, "").trim();
        state.annualTurnOver = res.data.turnover ? res.data.turnover : res.data.turnoverRange.replace(/\s/g, "").trim();
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelProduct' }, '/error')
      });
      //setState({...state, brandOption:state.brandOption});
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getInitialProfile' }, '/error')
    })

    await axios.get('/setting/profileAndSpeciality').then(res => {
      state.specialityOptions = res.data.specialityOptions.map(x => ({ id: x.productGroupId, name: x.productGroup }));
      state.speciality = res.data.channelSpeciality.map(x => ({ id: x.productGroup.id, name: x.productGroup.name }));
      state.frenchTech = res.data.isFrenchTech == '1' ? 'Yes' : 'No';
      if (res.data.profileType.length) {
        state.DistributorProfileValue = Object.assign(res.data.profileType[0], { firstLetter: res.data.profileType[0].groupname });
        state.DistributorProfileOption = state.DistributorProfileOption.map(x => {
          if (x.childid == state.DistributorProfileValue.childid)
            return { ...x, status: true }
          else
            return x;
        })
      }

    })



    await axios.get(apiUrl).then(res => {

      state.companyLogo = res.data.channel.channelDetail.channelLogo.documentPath;
      state.companyName = res.data.channel.companyName;
      state.documentId = res.data.channel.channelDetail.channelLogo?.documentId;
      state.introVideo = res.data.channel.channelDetail.welcomevideo?.documentId;
      if (res.data.channel.channelDetail.welcomevideo)
        setInitialVideo(process.env.REACT_APP_CDN_URL + res.data.channel.channelDetail.welcomevideo.documentUrl)
      state.yearOfEstablishment = res.data.channel.channelDetail.estYear;
      state.legalAddress = res.data.channel.regAddress ? res.data.channel.regAddress.address : '';
      state.regAddressId = res.data.channel.regAddressId;
      state.billAddressId = res.data.channel.billAddressId;
      //setValue('city', res.data.channel.regAddress.city)
      //setValue('zipCode', res.data.channel.regAddress.postalCode)       
      state.zipCode = res.data.channel.regAddress ? res.data.channel.regAddress.postalCode : '';
      state.city = res.data.channel.regAddress ? res.data.channel.regAddress.city : '';
      if (res.data.channel.channelCertificate) {
        res.data.channel.channelCertificate.map(x => {
          if (x.cerificateTypeId == '1') {
            state.companyRegNumber = x.cerificateNumber;
            state.certificateName = x.cerificateName;
            state.certificateId = x.channelCerificateId;
          }
          if (x.cerificateTypeId == '2') {
            state.vatNumber = x.cerificateNumber;
            state.vatId = x.channelCerificateId;
          }
        })
      }
      mediaState.productBrochures = [];
      if (res.data.channelPdf?.length) {
        res.data.channelPdf.map(x => {
          mediaState.productBrochures.push(x);
        })
      }
      mediaState.companyImages = [];
      if (res.data.channelImages?.length) {
        res.data.channelImages.map(x => {
          mediaState.companyImages.push(x);
        })
      }

      // state.vatNumber=res.data.channel.channelCertificate[1].cerificateNumber;

      state.phoneNumber1 = res.data.channel.channelDetail.phone1 ? res.data.channel.channelDetail.phone1 : ''; //.substring(res.data.channel.channelDetail.phone1.indexOf('-')+1,res.data.channel.channelDetail.phone1.length);
      state.phoneNumber2 = res.data.channel.channelDetail.phone2 ? res.data.channel.channelDetail.phone2 : '';
      state.mobile = res.data.channel.channelDetail.mobileNo;
      if (res.data.channel.channelDetail.webSiteUrl)
        state.websiteUrl = res.data.channel.channelDetail.webSiteUrl.substring(0, 2) == '//' ? res.data.channel.channelDetail.webSiteUrl.substring(2, res.data.channel.channelDetail.webSiteUrl.length) : res.data.channel.channelDetail.webSiteUrl;
      state.email = res.data.channel.companyMail;
      state.awards = res.data.channel.channelAward ? res.data.channel.channelAward.map(x => {
        if (x.documentId)
          x.documentUrl = process.env.REACT_APP_CDN_URL + x.awardImg;
        return x;
      }) : [];

      if (res.data.channel?.channelSocial?.length) {
        res.data.channel.channelSocial.map(x => {
          if (x.socialSite.socialSiteId == 1) {
            state.social_linkedin_id = x.channelSocialLinkId;
            state.linkedIn = x.channelSocialLink;
          }
          if (x.socialSite.socialSiteId == 3) {
            state.social_instagram_id = x.channelSocialLinkId;
            state.insta = x.channelSocialLink;
          }

          if (x.socialSite.socialSiteId == 2)
            state.tradeUrls = x.channelSocialLink
        })
      }

      if (res.data.channel?.channelB2B?.length) {
        state.b2bList = (res.data.channel.channelB2B.map(x =>
          ({ channel_B2B_id: x.channelB2BId, value: x.channelB2B })
        ))
      }

      state.tweetPitch = res.data.channel.channelDetail.detailDesc.replace(/<[^>]+>/g, '');
      state.sourcing = res.data.channel.channelDetail.expectations;
      state.country = { name: res.data.channel.country, id: res.data.channel.countryId };
      // state.insta=res.data.channel.channelSocial[0].channelSocialLink;
      // state.linkedIn=res.data.channel.channelSocial[1].channelSocialLink;
      if (res.data.bannerImg?.documentPath) {
        setImageSrc(res.data.bannerImg?.documentPath);
        setBannerId(res.data.bannerImg?.documentId);
        setMediaState({ ...mediaState, bannerImages: [{ image: res.data.bannerImg?.documentPath, id: res.data.bannerImg?.documentId }] });
      }
      setState({ ...state });
      setInitLoad(false);
      //setState({...state, countryList:res.data.country});
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/company' }, '/error')
      setInitLoad(false)
    });


    await axios.get('/setting/getChannelProduct').then(res => {
      if (res.data.productGroup.length && state.productFamilies.length) {
        state.productFamilies = state.productFamilies.map(x => {
          res.data.productGroup.map(y => {
            if (x.groupid == y.groupid && x.childid == y.childid) {
              x.status = true;
            }
          })
          return x;
        })
      }

      state.productValue = res.data.productGroup;
      setState({ ...state, productFamilies: state.productFamilies, productValue: state.productValue });

    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelProduct' }, '/error')
    });




    await axios.get('/setting/getChannelBrand').then(res => {
      state.brandList = res.data.brandList;
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelBrand' }, '/error')
    });

    await axios.get(countryUrl).then(res => {
      state.countryList = res.data.country;
      setState({ ...state });
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/common/getSignup/' }, '/error')
    });


  }
  const pdfDelete = (data) => {
    let filteredState = mediaState.productBrochures.filter(item => {
      if (item.documentId == data.documentId && item.channelDocumentId) {
        let temp = state.deletedDocuments;
        temp.push(+item.channelDocumentId);
        setState({ ...state, deletedDocuments: temp });
      }
      return item.documentId != data.documentId
    });
    if (filteredState) {
      setMediaState({ ...mediaState, productBrochures: filteredState });
      console.log('after delete', mediaState);
    }
  }

  const deleteImg = (e) => {
    console.log('e', e);
    // error.productPos=null;
    mediaState.companyImages = mediaState.companyImages.filter(x => x.documentId !== e.documentId);
    if (e?.companyMediaId)
      state.deleted_companyImages.push(parseInt(e.companyMediaId));

    setMediaState({ ...mediaState });
    setState({ ...state });
    // setError({...error});
  }

  const getChannelProfile = async () => {
    const apiUrl = '/setting/tradeinfo';
    await axios.get(apiUrl).then(res => {
      //console.log("profiledata:",res.data);          
      //state.countryList=res.data.country;

      state.ChannelProfileValue = userData.channelTypeId == '4' ? res.data.profile[0] : res.data.profile;
      state.listOfServicesOption = res.data.serviceListOptions;
      state.listOfServiceValue = res.data.serviceListValues;

      //brandList:res.data.brand,productValue:res.data.product,storeCount:res.data.storeCount,no_of_skus:res.data.no_of_skus,no_of_staffs:res.data.empCnt.replace(/\s/g, "").trim(),annualTurnOver:res.data.turnoverRange.replace(/\s/g, "").trim(),
      //sales: res.data.sales?+res.data.sales:'', marketing: res.data.marketing?+res.data.marketing:'', technical: res.data.technical?+res.data.technical:'',
      //purchase: res.data.purchase?+res.data.purchase:'',domesticRevenue:typeId=='4'?res.data.turnoverOnStore:res.data.revD,internationalRev:typeId=='4'?res.data.onlineStore:res.data.revI,
      setState({
        ...state, ChannelProfileValue: state.ChannelProfileValue,
        profileTypeId: +res.data.profileTypeId,
      });
      //console.log('tradestate',state);
      // getTradeLoc();
      // setLoading(false);


    }).catch(err => {
      //ToastsStore.error('Company Not Found!')
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/tradeinfo' }, '/error')
    });
  }

  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    //console.log("check:",check,gname);
    if (check == true) {
      state[optionName].map((item) => {

        if (item.groupname == gname || item.status == true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check == false) {
      console.log("check:", check);
      state[optionName].map((item) => {
        if (item.groupname == gname && state[optionValue].length > 0)
          item.status = check;
        // if(state.productValue.length==0)
        //     item.status=false;
      });

      opt = state[optionValue].filter((item) => item.groupname != gname);
      opt.map(option => option.status = true);
    }

    state[optionValue] = opt;
    setState({ ...state, [optionValue]: state[optionValue] });
    setState({ ...state, [optionName]: state[optionName] });

    // if(optionValue='ChannelProfileValue' && state.ChannelProfileValue.length==0)
    //       errors.relevantChannelProfiles=true;
    // else 
    if (optionValue = 'productValue' && state.productValue.length == 0)
      errors.productCat = true;
  }

  const deleteBrand = (id) => {
    const filteredList = state.brandList.filter((item) => item.id !== id);
    state.brandList = filteredList;
    setState({ ...state, brandList: state.brandList });
    if (state.brandList.length == 0) {
      setState({ ...state, brandValue: [] });
      errors.Brands = { message: 'Please select a Brand', type: 'required' };
    }
  }

  const openAdd = async (input, value) => {
    //console.log('opneadd',opneadd);
    await setBrandName(input);
    openAddnew(true);
  }

  const closeModalPopup = () => {
    setBrandName('');
    openAddnew(false);
    setOpenRetailer(false);
    setOpenDist(false);
  }

  const introVideoId = async (data) => {
    console.log('introVideoId', data.documentId);
    setInitialVideo('');
    setState({ ...state, introVideo: data.documentId });
    setLoading(true)
    let res = await commonService.postService({ id: data.documentId }, '/setting/updateIntroVideo')
      .catch(err => {
        ToastsStore.error('Something Went Wrong!');
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/updateIntroVideo' }, '/error')
      });
    if (res) {
      ToastsStore.success('Intro video Saved Successfully!');
    }
    setLoading(false);
  }

  const getCompanyLogo = (resImagePath) => {
    setState({ ...state, companyLogo: resImagePath.document.documentPath, documentId: resImagePath.documentId });
  };

  const save = async () => {
    try {
      setLoading(true);
      // let award_details=state.awards ? [].concat(state.awards.map(x => {
      //   return {channelAwardId : x.id, details : x.award_details, issued_by : x.awardName, valid_upto : x.date!=='1' ? x.date : '0', isLifeTime : x.date==='1' ? '1' : '0', imageId: x.documentId ? x.documentId : null, image: x.awardImg ? x.awardImg : null }
      // })) : [];

      let award_details = state.awards ? [].concat(state.awards.map(x => {
        if (x.isOld) {
          let isLifeTime = x.lifeTime === '1' || x.lifeTime == true ? '1' : '0';
          let valid_upto = x.date !== '1' ? x.date : null;
          if (!valid_upto && isLifeTime == '0')
            valid_upto = x.date;
          return { channel_award_id: x.id, details: x.award_details, issued_by: x.awardName, valid_upto: valid_upto, isLifeTime: x.lifeTime === '1' || x.lifeTime == true ? '1' : '0', image: x.awardImg ? x.awardImg : null, documentId: x.documentId ? x.documentId : null, }
        }
        return { details: x.award_details, issued_by: x.awardName, valid_upto: x.date !== '1' ? x.date : null, isLifeTime: x.lifeTime === '1' || x.lifeTime == true ? '1' : '0', image: x.awardImg ? x.awardImg : null }
      })) : [{}];

      let deleted_award_details = state.deletedAwards.length ? state.deletedAwards : [null];

      let bannerImage = mediaState.bannerImages.length ? [].concat(mediaState.bannerImages.map(x => {
        return { image: x.image, id: x.id ? x.id : '' }
      })) : [];//[{image:null}],

      // let productBrochure={
      //   productpdf:mediaState.productBrochures.length ? [].concat(mediaState.productBrochures.map(x => {
      //     return {channelDocumentId: x.channelDocumentId?x.channelDocumentId:null,documentId: x.documentId, image: x.document.documentUrl, documentName: null, documentDescription: null};
      //   })) : []//{image:null,documentName:null,documentDescription:null}]
      // }

      let productBrochure = {
        productpdf: mediaState.productBrochures.length ? [].concat(mediaState.productBrochures.map(x => {
          return { channelDocumentId: x.channelDocumentId ? +x.channelDocumentId : null, documentId: +x.documentId, document: x.document.documentUrl, documentName: x.name };
        })) : [{ document: 'null' }]//{image:null,documentName:null,documentDescription:null}]
      }
      // let data = {
      //   companyRegNumber : watchAll.CRN,
      //   certificateName: state.cerificateName,
      //   vatNumber : state.isVat=='1'?watchAll.VATN:null,
      //   companyLogo : state.documentId,
      //   tweetPitch : state.tweetPitch,
      //   introVideo : state.introVideo,
      //   sourcing : state.sourcing,
      //   country: state.country.id,
      //   companyLegalName: watchAll.companyName,
      //   legalAddress: state.legalAddress,
      //   zipCode: watchAll.zipCode,
      //   city: watchAll.city,
      //   phoneNumber1: state.phoneNumber1,
      //   phoneNumber2: state.phoneNumber2,
      //   mobile: state.mobile,
      //   email: state.email,
      //   websiteUrl: watchAll.websiteUrl,
      //   award_details: award_details,
      //   linkedIn: watchAll.linkedIn,
      //   insta: watchAll.insta,
      //   tradeUrls: watchAll.tradeUrls,
      //   productBrochure: productBrochure,
      //   bannerImage: bannerImage
      // }
      let tax_cert = [];
      let reg_cert = [];
      reg_cert.push({ name: state.certificateName, id: watchAll.CRN, channel_certificate_id: parseInt(state.certificateId) });
      if (state.isVat == '1')
        tax_cert.push({ name: 'VAT', id: watchAll.VATN, channel_certificate_id: parseInt(state.vatId) });

      let data = {
        companyRegNumber: watchAll.CRN,
        certificateName: state.certificateName,
        vatNumber: state.isVat == '1' ? parseInt(watchAll.VATN) : null,
        registration_certificate: reg_cert,
        // company_logo_url: state.companyLogo,
        // companyLogo : state.documentId,
        company_logo_url: state.documentId,
        year_of_estabilishment: +watchAll.Yearestablishment,
        company_name: watchAll.companyName,
        company_about: state.tweetPitch,
        company_introvideo: state.introVideo,
        company_expectation: state.sourcing ? state.sourcing : null,
        is_same_address: 0,
        reg_address_id: +state.regAddressId,
        reg_country_id: +state.country.id,
        reg_address: state.legalAddress,
        reg_postal_code: watchAll.zipCode,
        reg_city: watchAll.city,
        // bill_address_id: +state.billAddressId,
        // bill_country_id: state.country.id,
        // bill_address: state.legalAddress,
        // bill_postal_code: watchAll.zipCode,
        // bill_city: watchAll.city,
        company_phone1: watchAll.phoneNumber1 + '',
        company_phone2: watchAll.phoneNumber2 + '',
        company_mobile_no: watchAll.mobile,
        company_email: watchAll.email,
        company_website_url: watchAll.websiteUrl,
        award_details: award_details,
        social_linkedin_url: watchAll.linkedIn,
        social_instagram_url: watchAll.insta,
        social_linkedin_id: state.social_linkedin_id,
        social_googleplus_id: null,
        social_instagram_id: state.social_instagram_id,
        contact_linkedin_id: null,
        deleted_B2B: state.deleted_B2B.length ? state.deleted_B2B : [null],
        tradeUrls: watchAll.tradeUrls,
        channel_document: productBrochure.productpdf,
        bannerImage: bannerImage,
        deleted_award_details: deleted_award_details,
        companyImages: mediaState.companyImages,
        deleted_companyImages: state.deleted_companyImages
      }

      if (data.companyImages.length === 0)
        delete data.companyImages

      if (data.deleted_companyImages.length === 0)
        delete data.deleted_companyImages

      if (state.b2bList.length) {
        state.b2bList = [].concat(state.b2bList.map(x => {

          if (x.channel_B2B_id && x.value)
            return { B2B_url: x.value, channel_B2B_id: x.channel_B2B_id }
          else if (x.channel_B2B_id && !x.value) {
            state.deleted_B2B.push(x.channel_B2B_id);
            return null;
          } else if (x.value && !x.channel_B2B_id) {
            return { B2B_url: x.value }
          }

        }));
        state.b2bList = state.b2bList.filter((el) => el != null);
        data = Object.assign(data, { deleted_B2B: state.deleted_B2B.length ? [... new Set(state.deleted_B2B)] : [null] });
        if (state.b2bList.length)
          data = Object.assign(data, { B2B_url: state.b2bList });
      }

      if (tax_cert.length)
        data = Object.assign(data, { tax_certificate: tax_cert });

      if (state.deletedDocuments.length)
        data = Object.assign(data, { deleted_channel_documents: state.deletedDocuments });
      console.log('data', data);
      let result = await dashboardActions.updateCompanyInfo({ data }).catch(err => {
        ToastsStore.error('Something Went Wrong!');
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/updateCompany' }, '/error')
        setLoading(false)
      });
      if (result) {
        await saveTrade();
        //  let res1 = saveTrade();
        //  if(res1){
        //   ToastsStore.success('Saved Successfully!')
        //   setLoading(false);
        //   setInitLoad(true)
        //   getCompanyData();
        //  }else{
        //   ToastsStore.error('Something Went Wrong!');
        //   setLoading(false);       
        //   setInitLoad(false);
        //  }
      }
    }
    catch (err) {
      console.log('err', err);
      ToastsStore.error('Something Went Wrong!');
      setLoading(false);
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'updateCompany' }, '/error');
    }


    // history.push('admin/dashboard');  
    //console.log('save', data);
  }

  //saving tradeinfo form merged fields data
  const saveTrade = async () => {
    try {
      setLoading(true);

      let productFamilies = [];
      let uniq = _.uniq(state.productValue.map(x => +x.groupid));
      uniq.map(x => {
        productFamilies.push({ id: +x });
      })
      let productCategories = [];
      state.productValue.map(x => {
        productCategories.push({
          productFamilId: +x.groupid,
          productCategoriesId: +x.childid
        })
      })

      let specialityValue = state.speciality.map(x => ({ specialityId: +x.id }));

      let data = {};
      if (typeId == '2') {
        data = {
          // profileTypeId : +state.profileTypeId,
          brand: [].concat(state.brandList.map(x => ({ id: +x.id, name: x.name, documentPath: x.documentpath, isNew: x.isNew }))),
          no_of_skus: +watchAll.no_of_skus,
          // domesticSales: state.sales?+state.sales:null,
          // internationalSales: state.marketing?+state.marketing:null,
          // domesticMarketing: state.technical?+state.technical:null,
          // internationalMarketing: state.purchase?+state.purchase:null,
          domesticRevenue: state.domesticRevenue ? +state.domesticRevenue : null,
          internationalRevenue: state.internationalRev ? +state.internationalRev : null,
        }
      } else if (typeId == '3') {
        data = {
          profileTypeId: +state.DistributorProfileValue.childid,
          brand: [].concat(state.brandList.map(x => ({ id: +x.id, name: x.name, documentPath: x.documentpath, isNew: x.isNew }))),
          // domesticSales: state.sales?+state.sales:null,
          // internationalSales: state.marketing?+state.marketing:null,
          // domesticMarketing: state.technical?+state.technical:null,
          // internationalMarketing: state.purchase?+state.purchase:null,
          domesticRevenue: state.domesticRevenue ? +state.domesticRevenue : null,
          internationalRevenue: state.internationalRev ? +state.internationalRev : null,
          listOfServices: [].concat(state.listOfServiceValue.map(x => ({ id: +x.id }))),
          ...specialityValue && { distributorSpeciality: specialityValue },
        }
      } else if (typeId == '4') {
        data = {
          // profileTypeId: +state.ChannelProfileValue.childid,
          physicalStore: state.domesticRevenue ? +state.domesticRevenue : null,
          OnlineStore: state.internationalRev ? +state.internationalRev : null,
          storeCount: +state.storeCount,
        }
      }

      if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(state.no_of_staffs))
        data['employeesRange'] = state.no_of_staffs
      else
        data['employeesCount'] = +state.no_of_staffs

      if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(state.annualTurnOver))
        data['annualTurnoverRange'] = state.annualTurnOver
      else
        data['annualTurnover'] = +state.annualTurnOver

      let finalData = {
        channelType: +typeId,
        productFamilies: productFamilies,
        productCategories: productCategories,
        customerProfiles: (typeId == 4) ? [{ profileTypeId: +state.ChannelProfileValue.childid, profileTypeClassificationId: +state.ChannelProfileValue.profiletypeclassificationid }] : [].concat(state.ChannelProfileValue.map(x => { return { profileTypeId: +x.childid, profileTypeClassificationId: +x.profiletypeclassificationid } })),
        is_french_tech: state.frenchTech == "Yes" ? '1' : '0',
      }

      finalData = typeId == '2' ? Object.assign(finalData, { tradeInformationVendor: data }) : typeId == '3' ? Object.assign(finalData, { tradeInformationDistributor: data }) : Object.assign(finalData, { tradeInformationRetailer: data })

      let result = await dashboardActions.updateTradeInfo(finalData).catch(err => {
        ToastsStore.error('Something Went Wrong!');
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/updateTradeInfo' }, '/error')
        setLoading(false)
      });
      //console.log('saveresuult', result);
      if (result) {
        ToastsStore.success('Saved Successfully!')
        setLoading(false);
        setInitLoad(true)
        getCompanyData();
        // getTradeInfo();
        // ToastsStore.success('Saved Successfully!');
        // return result;
      }

    }
    catch (err) {
      ToastsStore.error('Something Went Wrong!');
      setLoading(false);
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'updateTradeInfo' }, '/error');
    }

  }




  const handleChange = input => e => {
    if (input == 'tradeUrls') {
      let b2b = [...state.b2bList];
      console.log('b2b', b2b, e.target.value, e.target);
      b2b[e.target.id].value = e.target.value;
      setState({ ...state, b2bList: b2b })
    } else {
      setState({ ...state, [input]: e.target.value });
    }
    console.log("add", e.target.value, e.target);

  }


  const handleChangeQuill1 = (content, delta, source, editor) => {
    setState({ ...state, 'tweetPitch': editor.getText().trim().replace(/[\r\n]+/gm, "") });
  }

  const handleChangeQuill2 = (content, delta, source, editor) => {
    setState({ ...state, 'sourcing': editor.getText().trim().replace(/[\r\n]+/gm, "") });
  }

  const handleChangeSelect = async (input, value) => {
    if (input == 'country') {
      await axios.get('/setting/getVat/' + value.id).then(res => {
        state.isVat = res.data.country[0].isVat;
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getVat' }, '/error')
      })
    }
    else if (input == 'productFamily') {
      state.productFamilies.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          value.map(v => {
            // if(v.childname!=item.childname)
            //   item.status=false;                 
            if (v.childname == item.childname)
              item.status = true;

          });
        }
      });

      value.map(option => option.status = true);
      state.productValue = value
      setState({ ...state, productFamilies: state.productFamilies });
      setState({ ...state, productValue: state.productValue });

      // state.productValue= value
      // setState({...state, productValue:state.productValue});
      if (state.productValue.length == 0)
        errors.productValue = true;

      let Group = _.uniq(state.productValue.map(x => x.groupid));
      state.groupCount = Group.length;
      setState({ ...state, groupCount: state.groupCount });



    }
    else if (input == 'brand' || input == 'brands') {
      if (input == 'brands')
        input = 'brand';
      if (value.name === 'Add Brand' && value.id === 0) {
        setBrandName('');
        openAdd(true);
        return <></>;
      }
      if (value.hasOwnProperty('isNew')) {
        state[input] = value;
      } else {
        value.isNew = '0';
        state[input] = value;
      }

      setState({ ...state, [input]: state[input] });
      let temp = state[input];
      temp = state[input + 'List'].filter(x => x.id == temp.id);
      if (temp.length) {
        console.log('already entered');
      } else {
        console.log('no entry')
        if (value.isNew == '1')
          state[input + 'ListUser'].push(state[input]);
        state[input + 'List'].push(state[input]);
        setState({ ...state, [input + 'List']: state[input + 'List'], [input + 'ListUser']: state[input + 'ListUser'] });
      }

      console.log("brandList:", state[input + 'list']);
    }
    else if (input == 'relevantChannelProfile') {
      state.ChannelProfileOption.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          value.map(v => {
            // if(v.childname!=item.childname)
            //   item.status=false;                 
            if (v.childname == item.childname)
              item.status = true;

          });
        }
      });

      value.map(option => option.status = true);
      state.ChannelProfileValue = value
      setState({ ...state, ChannelProfileOption: state.ChannelProfileOption });
      setState({ ...state, ChannelProfileValue: state.ChannelProfileValue });

      if (state.ChannelProfileValue.length == 0)
        errors.relevantChannelProfiles = true;
      // state.ChannelProfileValue= value
      // setState({...state, ChannelProfileValue:state.ChannelProfileValue});
    }
    else if (input == 'relevantRetailerProfile') {
      state.ChannelProfileValue = value
      setState({ ...state, ChannelProfileValue: state.ChannelProfileValue });
    }
    else {
      if (input == "speciality" && value.length <= 3)
        setState({ ...state, [input]: value });
      else if (input != "speciality")
        setState({ ...state, [input]: value });

    }

  }

  console.log('satet', state);
  //console.log('watchAll', watchAll);
  //console.log('media', mediaState);

  const onSubmit = data => {
    if (data) {
      console.log("data:", data);
    }
  }

  useEffect(() => {
    if (!_.isEmpty(errors) && formState.isSubmitted) {
      let error = Object.assign({}, errors);
      if (errors.tradeUrls) {
        error.tradeUrls = error.tradeUrls.filter(x => x ? true : false);
      }

      ToastsStore.error(JSON.stringify(error));
      commonService.postService({ err: JSON.stringify(error), reqUrl: window.location.pathname, service: 'validation_err' }, '/error')
    }
  }, [errors])

  //maturity level single or multiple decision making code
  let typeId = userData?.channelTypeId;
  //Award
  const addAwards = (data) => {
    console.log('data', data);
    let index = state.awards.findIndex((obj => obj.id == data.id));
    if (index != -1) {
      if (state.awards[index].isOld)
        state.awards[index] = Object.assign(data, { documentId: state.awards[index].documentId, isOld: true });
      else
        state.awards[index] = Object.assign(data, { documentId: state.awards[index].documentId });
      setState({ ...state, awards: state.awards });
      console.log('updated', data);
    }
    else {
      let temp = state.awards;
      temp.push(Object.assign(data, { documentId: null }));
      setState({ ...state, awards: temp });
      console.log('added', data);
    }
  }

  const deleteAwards = (data) => {
    const filteredList = state.awards.filter((item) => {
      if (data.id == item.id && item.isOld)
        state.deletedAwards.push(item.id);
      return item.id !== data.id;
    });
    state.awards = filteredList;
    setState({ ...state, awards: state.awards, deletedAwards: state.deletedAwards });
  }

  const handleChangeFileSelect = async (input, value, filename) => {
    if (input === 'productBrochures') {
      console.log('brochures');
      let temp = mediaState.productBrochures;
      let newFiles = value.filter(file => !mediaState.productBrochures.find(f => f.documentId === file.documentId));

      console.log('temp', temp, temp.length + newFiles.length)
      if (temp.length + newFiles.length <= 5) {
        temp.push(...newFiles);
        setMediaState({ ...mediaState, [input]: temp });
      }
      else {
        console.log('Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb');
      }
    } else if (input === 'companyImages') {
      console.log('companyImages');
      let temp = mediaState.companyImages;
      let files = [];
      value.map(x => {
        files.push({ channelDocumentId: parseInt(x.channelDocumentId), channelId: x.channelId, documentUrl: x.document.documentUrl, documentPath: x.document.documentPath, documentId: x.documentId })
      })
      let newFiles = files.filter(file => !mediaState.companyImages.find(f => f.documentId === file.documentId));

      console.log('temp', temp, temp.length + newFiles.length)
      if (temp.length + newFiles.length <= 5) {
        temp.push(...newFiles);
        setMediaState({ ...mediaState, [input]: temp });
      }
      else {
        console.log('Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb');
      }
      console.log('mediaState', mediaState);
    }
    else {
      let res = await handleOnDrop(input, value, filename);
      if (res) {
        console.log('res', res);
        setMediaState({ ...mediaState, [input]: value })
      };
    }
  }

  const handleChangeFileDelete = async (input, fileToBeRemoved) => {
    let filteredState = await mediaState[input].filter(item => item.filename != fileToBeRemoved);
    if (filteredState) {
      setMediaState({ ...mediaState, [input]: filteredState });
      console.log('after delete', mediaState);
    }
  }

  //functions for image
  const handleOnDrop = async (input, data, file_name) => {
    if (data) {
      setLoading(true);
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        selectImg(input, reader.result, file_name)
        //setOpen(false);          
      };

    }
  }

  const handleChangeBanner = event => {
    const data = event.target.files[0];
    console.log('files', data)

    if (data && data['type'].split('/')[0] === 'image') {
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        setImage(
          reader.result
        );
        console.log('image', image)
        //setOpen(false);          
      };

    }

  };

  const selectBannerImg = async (data) => {
    if (data) {
      setImage('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + 'banner');
      console.log('resImagePath', resImagePath);
      if (resImagePath) {
        setImageSrc(resImagePath.data.url);
        setBannerId('');
        setMediaState({ ...mediaState, 'bannerImages': [{ image: resImagePath.data.url }] });
        setLoading(false);
      }

    }

  }

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const addNewB2B = () => {
    setState({ ...state, b2bList: [...state.b2bList, { id: Math.random(), value: '' }] });
  }

  const delB2B = (id) => {
    console.log('id', id);
    let del = state.deleted_B2B;
    state.b2bList.map((x, i) => {
      if (id == i && x.channel_B2B_id)
        del.push(x.channel_B2B_id);
    })
    let temp = state.b2bList.filter((r, i) => i !== id);
    setState({ ...state, b2bList: temp, deleted_B2B: del });
  }

  const selectImg = async (input, data, file_name) => {
    if (data.length > 0) {

      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);

      let aws_folder = input === 'bannerImages' ? 'banner' : '';


      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + aws_folder);
      if (resImagePath) {
        if (input === 'bannerImages') {
          let temp = mediaState.bannerImages;
          temp.push({ image: resImagePath.data.url, filename: file_name })
          setMediaState({ ...mediaState, [input]: temp });
        }
        setLoading(false);

      } else {
        loading = false;
        setLoading(loading);
      }

    }
    // setOpen(false);
  }
  const editName = (item) => {
    docName = item.name;
    setDocName(docName);
    docId = item.documentId;
    setDocId(docId);
    setNamePopup(true);
  }
  const docNameChange = async (docId, name) => {
    console.log('media', mediaState.productBrochures);
    console.log(docId, name);
    mediaState.productBrochures.map(x => {
      if (parseInt(x.documentId) === parseInt(docId))
        x.name = name;
    })
    namePopupClose();
  }
  const namePopupClose = () => {
    setNamePopup(false);
  };
  const openMediaLib = () => {
    if (mediaState.companyImages.length >= 5) {
      ToastsStore.error('Maximum 5 Images');
    } else {
      setComImage(true);
    }
  }
  return (
    <div className="main-content">
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />

      <DocumentName isOpen={namePopup} handleClose={namePopupClose} isEdit={true} handleSave={docNameChange} name={docName} docId={docId} />
      {initLoad ? <Spinner /> : <Container className="mt-4 col-lg-10 col-md-9 col-sm-12  " fluid>
        {comImage &&
          <MultiSelectImages
            id={1}
            isOpen={comImage}
            multiple={true}
            limit={5}
            acceptedFiles={['image/jpeg', 'image/png', 'image/gif']}
            getBrochuresIds={handleChangeFileSelect}
            input="companyImages" aws_folderName="companyImages"
            handleClose={() => {
              comImage = false;
              setComImage(comImage)
            }}
            documentIds={mediaState?.companyImages.length > 0 ? mediaState.companyImages.map(x => parseInt(x.documentId)) : []}
          />
        }
        <form noValidate onSubmit={handleSubmit(save)}>
          {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000', }}><Spinner /></div>}
          <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="typeId" value={typeId} control={control} />
          <Card>
            <CardHeader className="col-lg-12 justify-content-center">
              <h3 className="text-center">Company Registration</h3>
              <p className="text-center">Enter Company legal details.</p>
            </CardHeader>
            {/* <CardBody className="Profad"> */}

            <CardBody >
              <div id="previe">
                <VideoPreview videoModalClose={() => setOpen(false)} isOpen={open} url={initialVideo} />
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-2">
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} />
                      <h4>Company Logo *{!errors.companyLogo || state.companyLogo ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Company Logo</span>}</h4>
                      <div>

                        <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='CompanyLogo' id={1}
                          img={state.companyLogo ? process.env.REACT_APP_CDN_URL + state.companyLogo : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />
                      </div>





                    </div>
                    <div className="col-sm-2 mt-6">
                      <span>Accepts Jpeg / Jpg / Png Only</span>
                      <br></br>
                      <span>(Max size allowed 200 Kb)</span>
                      <span className="d-block">(* Image resolution should be 200 * 200)</span>
                    </div>

                    <div className="col-sm-2 mt-2">

                    </div>

                    <div className="col-sm-6 mt-2">
                      <FormInput
                        className="comp-name mt-2"
                        id="Yearestablishment"
                        name="Yearestablishment"
                        label="Year of Establishment"
                        placeholder="Year of Establishment"
                        type="text"
                        variant="outlined"
                        value={watchAll.Yearestablishment} defaultValue={state.yearOfEstablishment} error={errors.Yearestablishment}
                        register={register}


                      />
                    </div>
                  </div>

                </div>

              </div>
              <div className="row">
                <div className="col-lg-12 mt-2">
                  {/* <h4 >Company Legal Name *</h4> */}
                  <FormInput className="comp-name mt-2" id="companyName" name="companyName" label="Company Legal Name *" type="text" variant="outlined" value={state.companyName} defaultValue={state.companyName} error={errors.companyName}
                    register={register}
                    value={watchAll.companyName}

                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 mt-3">
                  {/* <h4 >Registered/Legal Address</h4> */}
                  <FormInput className="comp-name mt-2 addrs" id="legalAddress" name="legalAddress" label="Registered/Legal Address *" type="text" variant="outlined" value={state.legalAddress} error={errors.legalAddress}
                    onChange={handleChange('legalAddress')} register={register}
                  />

                </div>

                <div className="col-lg-6 mt-4 ">
                  <div className="row">
                    {/* <h4 >Country *</h4> */}
                    {/* <FormInput className="comp-name mt-2" id="outlined-search" label="Country *" type="text" variant="outlined" /> */}
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <FormAutocomplete
                        id="country"
                        name="country"
                        label="Country"
                        options={state.countryList}
                        placeholder="Select Country"
                        // multiple
                        disableCloseOnSelect
                        blurOnSelect
                        limitTags={1}
                        control={control}
                        input='country'
                        value={state.country}
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Please Select Country'}
                        error={errors.country}
                        defaultValue={state.country}
                      />
                    </FormControl>
                  </div>
                  <div className="row">
                    {/* <h4 >Country *</h4> */}
                    <FormInput className="comp-name mt-4" id="zipCode" name="zipCode" placeholder='' label="Zip/Pincode *" type="text" variant="outlined"
                      value={watchAll.zipCode}
                      defaultValue={state.zipCode}
                      onChange={handleChange('zipCode')}
                      register={register}
                      error={errors.zipCode}
                    />
                  </div>
                  <div className="row">
                    {/* <h4 >Country *</h4> */}
                    <FormInput className="comp-name mt-4" id="city" name="city" label="City *" type="text" variant="outlined" value={watchAll.city}
                      defaultValue={state.city}
                      onChange={handleChange('city')}
                      error={errors.city}
                      register={register}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-sm-6 mt-3">
                  <div className="form-group">

                    <label className="form-control-label" htmlFor="">

                    </label>
                    <InputGroup className="" >
                      <InputGroupAddon style={{ width: '30%' }} addonType="prepend">
                        <InputGroupText id="certificateName" className="justify-content-center ellipsisText" style={{ width: '100%' }} >{state.certificateName}</InputGroupText>
                      </InputGroupAddon>
                      <UncontrolledTooltip target="certificateName" placement='bottom'>
                        {state.certificateName}
                      </UncontrolledTooltip>
                      <FormInput className="" style={{ width: '70%' }}
                        id="CRN"
                        type="text"
                        name="CRN"
                        label="Company registration number*"
                        register={register}
                        error={errors.CRN}
                        defaultValue={state.companyRegNumber}
                        value={watchAll.CRN}
                        onChange={handleChange('companyRegNumber')}
                      />
                    </InputGroup>

                  </div>



                </div>
                <div className="col-sm-6 mt-3">
                  <div className="form-group">

                    <label className="form-control-label" htmlFor="">
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="isVat" ref={register} name="isVat" value={state.isVat} control={control} />
                    </label>
                    {state.isVat == '1' && (
                      <InputGroup className="">
                        <InputGroupAddon style={{ width: '20%' }} addonType="prepend">
                          <InputGroupText id="Vat" className="justify-content-center" style={{ width: '100%' }}>VAT</InputGroupText>
                        </InputGroupAddon>
                        <FormInput style={{ width: '80%' }}
                          className=""
                          id="VATN"
                          type="text"
                          name="VATN"
                          label="VAT number"
                          register={register}
                          error={errors.VATN}
                          defaultValue={state.vatNumber}
                          value={watchAll.VATN}
                          onChange={handleChange('vatNumber')}
                        />
                      </InputGroup>)}

                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h3 className="text-center">Communication</h3>
              <p className="text-center">Enter your general communication information: switchboard number, web site, general email</p>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg="6" className="code">
                  <div className="form-group">

                    {/* <label className="form-control-label" htmlFor="">
<input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="phoneNumber1" ref={register} name="phoneNumber1" />
</label> */}
                    <FormInput className="comp-name1" id="phoneNumber1" name="phoneNumber1" label="PhoneNumber1 *" type="text" variant="outlined" value={watchAll.phoneNumber1}
                      onChange={handleChange('phoneNumber1')}
                      defaultValue={state.phoneNumber1}
                      error={errors.phoneNumber1}
                      register={register}
                    />
                    {/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon>
  
           </InputGroup> */}
                  </div>

                </Col>
                <Col lg="6" className="code">
                  <div className="form-group">

                    <FormInput className="comp-name1" id="phoneNumber2" name="phoneNumber2" label="PhoneNumber2" type="text" variant="outlined" value={watchAll.phoneNumber2}
                      onChange={handleChange('phoneNumber2')}
                      defaultValue={state.phoneNumber2}
                      error={errors.phoneNumber2}
                      register={register}
                    />

                    {/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon>
  
           </InputGroup> */}
                  </div>

                </Col>
              </Row>

              <Row>
                <Col lg="6" className="code">
                  <div className="form-group">

                    <FormInput className="comp-name1" id="mobile" name="mobile" label="mobile" type="text" variant="outlined" value={watchAll.mobile}
                      onChange={handleChange('mobile')}
                      defaultValue={state.mobile}
                      error={errors.mobile}
                      register={register}
                    />

                    {/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon> */}

                    {/* </InputGroup> */}
                  </div>

                </Col>
                <Col lg="6">
                  {/* <FormInput className="comp-name mt-3" id="outlined-search" label="EmailID" type="text" variant="outlined" /> */}
                  <div className="form-group">
                    <FormInput
                      id="companyemail"
                      type="email"
                      label="Enter your Email"
                      name="companyemail"
                      register={register}
                      error={errors.companyemail}
                      value={state.email}
                      defaultValue={watchAll.email}
                      onChange={handleChange('email')}
                    //error={errors.companyemail ? errors.companyemail : checkMailId ? {message:"Email Id already taken", required: true} : ''}

                    //defaultValue={email}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormInput className="comp-name mt-2" id="outlined-search" name="websiteUrl" label="Website URL *" type="text" variant="outlined" value={watchAll.websiteUrl}
                    defaultValue={state.websiteUrl}
                    register={register}
                    error={errors.websiteUrl}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>


          <Card>
            <CardHeader>
              <h3 className="text-center">Awards</h3>
              <p className="text-center">Raise your profile by adding your Awards.</p>
            </CardHeader>
            <CardBody>
              <div className="col-lg-12 ">
                <BrandCard award={true} addAwards={addAwards} deleteAwards={deleteAwards} awardList={state.awards} name="BMW" img="https://cdn.ces.tech/ces/media/events-experiences-images/innovation-awards/2020/honoree.png" />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h3 className="text-center" style={{ paddingLeft: '15px' }}>Social Profiles</h3>
              <p className="text-center">Add your company’s social profiles like Company Website URL, linkedin, Instragram Profile Pages.</p>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg="12">
                  <FormInput className="comp-name mt-4" id="outlined-search" name="linkedIn" label="Linkedin URL" type="text" variant="outlined" value={watchAll.linkedIn}
                    onChange={handleChange('linkedIn')}
                    register={register}
                    error={errors.linkedIn}
                    defaultValue={state.linkedIn}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormInput className="comp-name mt-4" id="outlined-search" name="insta" label="Instagram URL" type="text" variant="outlined" value={watchAll.insta}
                    onChange={handleChange('insta')}
                    register={register}
                    error={errors.insta}
                    defaultValue={state.insta}
                  />
                </Col>
              </Row>
              {/* <Row>
      <Col lg="11">
      <FormInput className="comp-name mt-4" id="outlined-search" name="tradeUrls" label="Other B2B or Trade Portals Website URLs" type="text" variant="outlined" value={state.tradeUrls}
      onChange={handleChange('tradeUrls')}
      register={register}
      error={errors.tradeUrls}
      defaultValue={state.tradeUrls}
      id='1'
      />
      </Col>
      <Col lg="1">
        <div className="faplus">
      <i  class="fa fa-plus-circle" onClick={addNewB2B}></i>
      </div>
      </Col>
    </Row> */}

              {state.b2bList && state.b2bList.map((x, i) => <Row>
                <Col lg="11">
                  <FormInput className="comp-name mt-4" id={i} name={`tradeUrls[${i}].value`} label="Other B2B or Trade Portals Website URLs" type="text" variant="outlined"
                    value={watchAll[`tradeUrls[${i}].value`]}
                    onChange={handleChange('tradeUrls')}
                    register={register}
                    error={errors.tradeUrls ? errors.tradeUrls[i] ? errors.tradeUrls[i].value : '' : ''}
                    defaultValue={x.value}
                  />
                </Col>
                {i == 0 ? <Col lg="1">
                  <div className="faplus">
                    <i class="fa fa-plus-circle" onClick={addNewB2B}></i>
                  </div>
                </Col> :
                  <Col lg="1">
                    <div className="faminus">
                      <i class="fa fa-minus-circle " onClick={() => delB2B(i)}></i>
                    </div>
                  </Col>}
              </Row>)}
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h3 className="text-center">Welcome Video</h3>
              <p className="text-center">Add an introduction video that buyers will view on your company profile (max 90 seconds).</p>
            </CardHeader>
            <CardBody>
              <div className="form-group">
                <form action="/file-upload" className="dropzone">
                  <div className="fallback">
                    <div style={{ flex: 1, display: 'flex' }}><MediaLibrary text="Upload Intro Video" introVideoId={introVideoId} id='1' size={2} />
                      {initialVideo && <span style={{ color: 'orange', display: 'flex', alignItems: 'center', marginLeft: '2rem', cursor: "pointer" }} onClick={() => setOpen(true)}>Preview Video</span>}</div>

                  </div>
                </form>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-sm-12 mt-4 ">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="tweetPitch" name="tweetPitch" ref={register} value={state.tweetPitch} maxLength={280} />
                  {!errors.tweetPitch || (state.tweetPitch.length > 5 && state.tweetPitch.length < 280) ? <h3 style={{ fontSize: '1rem' }}>Your Company - Tweet Pitch *</h3> :
                    <h4>Your Company - Tweet Pitch * <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                      state.tweetPitch.length <= 1 ? 'TweetPitch can\'t be empty.' : state.tweetPitch.length <= 5 ? 'TweetPitch must have min 5 characters' : state.tweetPitch.length > 280 ? 'TweetPitch must have max 280 characters' : ''
                    }</span></h4>
                  }
                  <div className="form-group">
                    <FormTextArea
                      className="buytext2 w-100 h-auto"
                      name="note"
                      id="note"
                      rows="3"
                      type="textarea"
                      label=" "
                      maxLength={280}
                      placeholder="Tell us about your company background and vision -max 280 characters *"
                      // handleChange={handleChangeQuill1} 
                      value={state.tweetPitch}
                      onChange={handleChange('tweetPitch')}
                    // register={register}
                    //   value={note}
                    //   onChange={handleChange}
                    />

                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 mt-4 ">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="sourcing" name="sourcing" ref={register} value={state.sourcing} maxLength={5000} />
                  {!errors?.sourcing || (state.sourcing?.length >= 5 && state.sourcing?.length <= 5000) ? <h3 style={{ fontSize: '1rem' }}>Sourcing Priority & Strategy</h3> :
                    <h4>Sourcing Priority & Strategy <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                      state.sourcing?.length <= 5 ? 'Sourcing Priority & Strategy must have min 5 characters' : state.tweetPitch.length > 5000 ? 'Sourcing Priority & Strategy must have max 5000 characters' : ''
                    }</span></h4>
                  }
                  <div className="form-group">
                    <FormTextArea
                      className="buytext2 w-100" placeholder="Tell us about your company background and vision -max 5000 characters *" onChange={handleChange('sourcing')} value={state.sourcing} />
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Company Images</h4>
                <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png, File Size Should Not Exceed 5 mb)</h5>
                {/* This below div is just used to inherit mui-dropzone css classes */}
                <div style={{ display: 'none' }}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="companyImages" /></div>
                <span onClick={openMediaLib}>
                  {
                    !mediaState.companyImages.length ?
                      <div className="form-group mt-3">
                        <div tabindex="0" class="MuiDropzoneArea-root">
                          <div class="MuiDropzoneArea-textContainer">
                            <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                            <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                          </div></div></div>
                      :
                      <ImageCard list={mediaState.companyImages} deleteImg={deleteImg} />
                  }
                </span>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>

              <div className="col-lg-12 ">
                <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Banner Image</h4>
                <h5 style={{ float: 'right' }}>(Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
                <div className="form-group mt-3" style={{ height: 'auto' }}>
                  {/* <MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="bannerImages"/> */}
                  <BannerUploader img={bannerId ? process.env.REACT_APP_CDN_URL + imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP + imageSrc : '/static/media/Default Banner.92e53fb9.png'}
                    handleChange={handleChangeBanner} selectImg={selectBannerImg} image={image} handleCropClose={e => setImage('')}
                  />
                  {loading && <div style={{
                    position: 'relative', display: 'block',
                    top: '50%',
                    left: '50%',
                    zIndex: '1000',
                  }}><Spinner /></div>}
                </div>
                {/* <div className="ban-preview mt-3">
                     <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={bannerId ? process.env.REACT_APP_CDN_URL+imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP+imageSrc : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>
                    </div>       */}
                <div className="text-right"><span><i>* 2048 X 400 (Use a resolution of 5:1) </i></span></div>

              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <div className="col-lg-12">
                <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Official Documents</h4>
                <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb)</h5>
                {/* This below div is just used to inherit mui-dropzone css classes */}
                <div style={{ display: 'none' }}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="bannerImages" /></div>
                <span onClick={() => {
                  if (mediaState.productBrochures.length >= 5) {
                    ToastsStore.error('Maximum 5 Images');
                  } else {
                    setProdImage(true);
                  }
                }}>
                  {
                    !mediaState.productBrochures.length ?
                      <div className="form-group mt-3">
                        <div tabindex="0" class="MuiDropzoneArea-root">
                          <div class="MuiDropzoneArea-textContainer">
                            <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                            <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                          </div></div></div>
                      :
                      <PdfCard pdfList={mediaState.productBrochures} deletePdf={pdfDelete} editName={editName} />
                  }
                </span>

                {prodImage && <ImageLibraryMultipleUploads id={1} isOpen={prodImage} multiple={true} limit={1} acceptedFiles={['application/pdf']} getBrochuresIds={handleChangeFileSelect} input="productBrochures" aws_folderName="broucher" handleClose={() => setProdImage(false)} documentIds={mediaState.productBrochures.length > 0 ? mediaState.productBrochures.map(x => parseInt(x.documentId)) : []} />}

              </div>

            </CardBody>
          </Card>

          <Card>
            <CardBody>

              {(typeId == 4) && (
                <div className="col-lg-12 ">
                  <h4 >Retailer Profiles *</h4>
                  <div className="form-group">
                    <SingleAutocompleteGrouping
                      id="relevantProfiles"
                      name="relevantRetailerProfiles"
                      label="Retailer Profiles * "
                      multiple={false}
                      Options={state.ChannelProfileOption}
                      placeholder="Channel Profiles"
                      input='relevantRetailerProfile'
                      errMessage={'Please Select Retailer Profiles'}
                      error={errors.relevantRetailerProfiles}
                      value={state.ChannelProfileValue}
                      handleChangeSelect={handleChangeSelect}
                      control={control}
                      register={register}
                      defaultValue={state.ChannelProfileValue}
                    />

                  </div>
                </div>

              )}

              {(typeId == '2' || typeId == '3') &&
                <div className="col-lg-12 ">
                  <h4 >Retail Customer Profile * <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                    <UncontrolledTooltip className="handshak" delay={0} delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="relevantChannelProfiles" name="relevantChannelProfiles" ref={register} value={state.ChannelProfileValue.length ? state.ChannelProfileValue[0].childname : ''} />
                  <div className="form-group">
                    <FormAutocompleteGrouping
                      id="relevantProfiles"
                      name="relevantProfiles"
                      label="Channel Profiles * "
                      multiple={true}
                      Options={state.ChannelProfileOption}
                      placeholder="Channel Profiles"
                      input='relevantChannelProfile'
                      errMessage={'Please Select Channel Profile'}
                      error={!errors.relevantChannelProfiles || state.ChannelProfileValue.length ? false : true}
                      value={state.ChannelProfileValue}

                      optionName='ChannelProfileOption'
                      optionValue='ChannelProfileValue'
                      defaultValue={state.ChannelProfileValue}
                      selectedItem={selectedItem}

                      handleChangeSelect={handleChangeSelect}
                      control={control}
                      register={register}
                    //defaultValue={state.ChannelProfileValue}
                    />

                  </div>
                </div>
              }

              {typeId == 3 &&

                <div className="col-lg-12 ">
                  <h4 >{typeId == 3 && 'Distributor Profile and speciality *'} <span id='algorithm1'><i class="far fa-handshake didy"></i></span>
                    <UncontrolledTooltip className="handshak" delay={0} delay={0} placement="bottom" target="algorithm1">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="profile" name="profile" ref={register} value={Object.keys(state.DistributorProfileValue).length ? state.DistributorProfileValue.childname != 'broadliner' ? '1' : '0' : ''} />
                  <div className="form-group">
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <SingleAutocompleteGrouping
                        id="DistributorProfiles"
                        name="DistributorProfiles"
                        label="Distributor Profiles * "
                        multiple={false}
                        Options={state.DistributorProfileOption}
                        placeholder="Channel Profiles"
                        input='DistributorProfileValue'
                        errMessage={'Please Select Distributor Profile'}
                        errMessage='Please Select Distributor Profile'
                        error={(!errors.profile || Object.keys(state.DistributorProfileValue).length) ? false : true}
                        value={state.DistributorProfileValue}
                        defaultValue={state.DistributorProfileValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}
                      />
                    </FormControl>
                  </div>
                </div>
              }

              {(typeId == 3 && Object.keys(state.DistributorProfileValue).length != 0 && state.DistributorProfileValue.childname != 'broadliner') && (
                <div className="col-lg-12 ">
                  <h4 >Speciality *</h4>
                  <div className="form-group">

                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="speciality" name="speciality" ref={register} value={state.speciality.length ? state.speciality[0].name : ''} />
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <FormAutocomplete
                        id="speciality1"
                        name="speciality1"
                        label="Search Speciality"
                        options={state.specialityOptions}
                        placeholder="Select Speciality"
                        input='speciality'
                        errMessage={'Please Select a Speciality'}
                        error={!errors.speciality || state.speciality.length ? false : true}
                        value={state.speciality}
                        defaultValue={state.speciality}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                      />
                    </FormControl>
                  </div>
                </div>
              )}


              {typeId == '3' && <div className="col-lg-12 ">
                <h4 >List of Services</h4>
                <div className="form-group">
                  <FormAutocomplete
                    id="ListofServices"
                    name="ListofServices"
                    label="List of Services"
                    multiple={true}
                    options={state.listOfServicesOption}
                    placeholder="List of Services"
                    input='listOfServiceValue'
                    errMessage={'Please Select List of Services'}
                    error={errors.ListOfServices}
                    value={state.listOfServiceValue}
                    handleChangeSelect={handleChangeSelect}
                    control={control}
                    register={register}
                  // defaultValue={state.listOfServiceValue}
                  />

                </div>
              </div>}

              {(typeId == 2 || typeId == 3) && (

                <div className="col-lg-12 ">
                  <h4 >Brands *</h4>
                  <div className="form-group mt-3">

                    {state.brandList[0] && <BrandCard name="BMW" deleteBrand={deleteBrand} brandList={state.brandList} img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />}

                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="brandList" name="brandList" ref={register} value={state.brandList[0] ? 'data' : ''} />
                    {/* Autocomplete with image */}
                    <AddFieldWithPopUpAutocomplete
                      limitTags={2}
                      name="Brands"
                      id="Brands"
                      options={state.brandOption}
                      defaultValue={state.brandValue}
                      value={state.brandValue}
                      getOptionLabel={option => option.name}
                      renderOption={option => (
                        <>
                          <div style={{
                            display: '-webkit-inline-flex',
                            padding: '1px 6px',
                            width: '75px',
                            margin: '10px 5px 0 0',
                            float: 'left',
                            border: '2px solid #e7e7e7',
                            borderRadius: '5px',
                            height: '38px'
                          }}>

                            <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{
                              maxWidth: '63px', backgroundColor: '#fff',
                              maxHeight: '34px',
                              height: 'auto',
                              width: 'auto',
                              margin: '0 auto',
                              cursor: 'pointer'
                            }} src={option.documentpath} />    </div>
                          <span>
                            {option.name}
                          </span>
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Brands" placeholder="Brands" />
                      )}
                      label="Brands *"
                      placeholder="Brands"
                      input="brand"
                      control={control}
                      register={register}
                      errMessage={'Please Select a Brand'}
                      error={errors.Brands && !state.brandList[0] ? errors.Brands : (errors.brandList && !state.brandList[0]) ? true : false}
                      handleChangeSelect={handleChangeSelect}
                      opneAdd={openAdd}
                    />
                    {brandName && <AddBrand aws_folderName="brandlogo" opneadd={openadd} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} />}

                  </div>
                </div>
              )}

              <div className="col-lg-12 ">
                <h4 >Select Product Families *</h4>
                <div className="form-group">

                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productCat" name="productCat" ref={register} value={state.productValue.length ? state.productValue[0].childname : ''} />
                  <FormAutocompleteGrouping
                    id="ProductGroup"
                    name="ProductGroup"
                    label="Product Families are you selling"
                    Options={state.productFamilies}
                    placeholder="Select Product Families"
                    input='productFamily'
                    errMessage={'Please Select a Product'}
                    error={!errors.productCat || state.productValue.length ? false : true}
                    //error={errors.ProductGroup}
                    //defaultValue={productFamilies}
                    value={state.productValue}

                    optionName='productFamilies'
                    optionValue='productValue'
                    defaultValue={state.productValue}
                    selectedItem={selectedItem}

                    handleChangeSelect={handleChangeSelect}
                    control={control}
                    register={register}
                  />
                </div>
              </div>


              {(typeId == 2) && (
                <div className="col-lg-12">
                  <h4>{typeId == 2 ? "No of SKU's" : "No of Store's"} *</h4>
                  <h5  >{typeId == 2 ? 'Enter the number of products that you handle' : 'Enter the number of stores that you handle'}</h5>
                  <div className="form-group">
                    <FormInput
                      id="No of SKU's"
                      type="number"
                      name="no_of_skus"
                      label={typeId == 2 ? "No of SKU's *" : "No of Store's *"}
                      InputProps={{
                        inputProps: {
                          max: 100, min: 10
                        }
                      }}
                      value={state.no_of_skus}
                      onChange={handleChange('no_of_skus')}
                      register={register}
                      defaultValue={state.no_of_skus}
                      value={watchAll.no_of_skus}
                      error={errors.no_of_skus}
                    />
                  </div>
                </div>

              )}

            </CardBody>
          </Card>


          <Card>
            <CardBody>
              <div className="row" >
                <div className="col-lg-8">
                  <h4 className="text-sm mb-0" >No of Staffs *{!errors.no_of_staffs || state.no_of_staffs ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select or Enter No Of Employees</span>}</h4>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="no_of_staffs" ref={register} name="no_of_staffs" value={state.no_of_staffs} control={control} />
                  <div className="row" >
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm" >
                      <input className={state.no_of_staffs == '1-10' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="1-10" onClick={handleChange('no_of_staffs')} />
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm"  >
                      <input className={state.no_of_staffs === '11-15' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="11-15" onClick={handleChange('no_of_staffs')} />
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm" >
                      <input className={state.no_of_staffs === '51-100' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="51-100" onClick={handleChange('no_of_staffs')} />
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm" >
                      <input className={state.no_of_staffs === '100-Above' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="100-Above" onClick={handleChange('no_of_staffs')} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 mt-4 ">
                  <div className="form-group  mt-2">

                    <FormInput
                      id="empcnt"
                      type="number"
                      name="empcnt"
                      label="Enter Custom Value *"
                      placeholder="Enter Your Value"
                      InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                      }}
                      value={state.no_of_staffs}
                      onChange={handleChange('no_of_staffs')}
                    />
                  </div>
                </div>
              </div>
              {/* { (typeId == '2' || typeId == '3') && <> 
          <p className="ml-3">Please specify the breakdown of your employees in the below categories.</p>
          <div style={{padding:"15px 15px 35px",backgroundColor:"#f7fafc"}}>
          <div className="row">
              <div className="col-lg-3">
                    <h4>Sales</h4>
                    <FormInput className="comp-name" name="sales"  variant="outlined" label="Sales" placeholder="" value={watchAll.sales}
                    defaultValue={state.sales}
                    register={register}
                    error={errors.sales}
                    onChange={handleChange('sales')}
                    type="number"
                    />
              </div>
              <div className="col-lg-3">
                    <h4>Marketing</h4>
                    <FormInput className="comp-name" name="marketing" variant="outlined" label="Marketing" placeholder="" value={watchAll.marketing}
                    defaultValue={state.marketing}
                    register={register}
                    error={errors.marketing}
                    onChange={handleChange('marketing')}
                    type="number"
                    />
              </div>
              <div className="col-lg-3">
                    <h4>Technical</h4>
                    <FormInput className="comp-name" name="technical"  variant="outlined" label="Technical" placeholder="" value={watchAll.technical}
                    onChange={handleChange('technical')}
                    defaultValue={state.technical}
                    register={register}
                    error={errors.technical}
                    type="number"
                    />
              </div>
              <div className="col-lg-3">
                    <h4>Purchase</h4>
                    <FormInput className="comp-name" name="purchase"  variant="outlined" label="Purchase" placeholder="" value={watchAll.purchase}
                    defaultValue={state.purchase}
                    register={register}
                    error={errors.purchase}
                    onChange={handleChange('purchase')}
                    type="number"
                    />
              </div>
          </div>
          </div></>} */}
              <div className="row ">
                <div className="col-lg-8 col-md-8 mt-3">
                  <h4 className="text-sm mb-0">Annual Turnover *{!errors.annualTurnOver || state.annualTurnOver ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select or Enter Annual Turnover</span>}</h4>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="annualTurnOver" name="annualTurnOver" ref={register} value={state.annualTurnOver} />
                  <div className="row ">
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
                      <input className={state.annualTurnOver === '<1M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} type="button" id="emprange" name="emprange"
                        value="<1M€" onClick={handleChange('annualTurnOver')} /></div>
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
                      <input className={state.annualTurnOver === '1-10M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="1-10M€" onClick={handleChange('annualTurnOver')} /></div>
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm  ">
                      <input className={state.annualTurnOver === '10-100M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="10-100M€" onClick={handleChange('annualTurnOver')} /></div>
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 pl-4 text-center res-sm  ">
                      <input className={state.annualTurnOver === '>100M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value=">100M€" onClick={handleChange('annualTurnOver')} /></div>
                  </div>
                </div>



                <div className="col-lg-4 col-md-4 col-xs-3 mt-5 ">
                  <div className="form-group ">

                    <FormInput
                      id="empcnt"
                      type="number"
                      name="empcnt"
                      label="Enter Value in M€"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">M€</InputAdornment>
                      }}
                      value={state.annualTurnOver}
                      onChange={handleChange('annualTurnOver')}
                    />
                  </div>
                </div>
              </div>
              <input type="number" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="revenue" name="revenue" ref={register} value={(+state.domesticRevenue) + (+state.internationalRev)} />
              <p className="ml-3">Please specify the breakdown of your revenue in the below categories.<span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                errors.revenue ? typeId == '4' ? 'The total of Physical Store and Online Store should not exceed 100 %' : errors.revenue.message : ''

              }</span></p>
              <div style={{ padding: "15px 15px 35px", backgroundColor: "#f7fafc" }}>
                <div className="row">
                  <div className="col-lg-4">
                    <h4>{typeId == '2' || typeId == '3' ? 'Domestic Revenue in %' : 'Physical Store in %'}</h4>
                    <FormInput className="comp-name" name="domestic" variant="outlined" label="Enter Value" placeholder="" value={watchAll.domestic}
                      onChange={handleChange('domesticRevenue')}
                      defaultValue={state.domesticRevenue}
                      register={register}
                      inputProps={{ min: "0", max: "100", step: "1", maxlength: 3, pattern: '^[1-9][0-9]?$|^100$', inputMode: 'numeric' }}
                      error={errors.domestic}
                      type="number"
                    />
                  </div>
                  <div className="col-lg-4">
                    <h4>{typeId == '2' || typeId == '3' ? 'International Revenue in %' : 'Online Store in %'}</h4>
                    <FormInput className="comp-name" name="interRev" variant="outlined" label="Enter Value" placeholder=""
                      onChange={handleChange('internationalRev')}
                      inputProps={{ min: "0", max: "100", step: "1", maxlength: 3, pattern: '^[1-9][0-9]?$|^100$', inputMode: 'numeric' }}
                      value={watchAll.interRev}
                      defaultValue={state.internationalRev}
                      error={errors.interRev}
                      register={register}
                      type="number"

                    />
                  </div>

                  {typeId == '4' &&
                    <div className="col-lg-4">
                      <h4>No. of Stores</h4>
                      <FormInput
                        id="storeCount"
                        type="number"
                        name="storeCount"
                        label={"No of Store's *"}
                        InputProps={{
                          inputProps: {
                            max: 100, min: 10
                          }
                        }}
                        //value={+state.no_of_skus}
                        onChange={handleChange('storeCount')}
                        register={register}
                        defaultValue={state.storeCount}
                        value={watchAll.storeCount}
                        error={errors.storeCount}
                      />
                    </div>}
                </div>
              </div>
            </CardBody>
          </Card>

          {typeId == '2' || typeId == '3' && <Card>
            <CardBody>
              <div className="row ">
                <div className="col-lg-8">
                  <h4>French Tech label</h4>
                  <div className="row ">
                    <div className="col-lg-2" style={{ textAlign: "-webkit-center" }}>
                      <div className="ftl-img">
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src="https://d35w8j22j5uly8.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg" />
                      </div>
                    </div>
                    <div className="col-lg-5" style={{ padding: "1.5rem 0px" }}>
                      <h4>Are you part of La French Tech community?</h4>
                    </div>
                    <div className="col-lg-5" style={{ padding: "1rem" }}>
                      <div className="text-center">
                        <input type='button' className={state.frenchTech === 'Yes' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} value='Yes' onClick={handleChange('frenchTech')} />
                        <input type='button' className={state.frenchTech === 'No' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} value='No' onClick={handleChange('frenchTech')} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </CardBody>
          </Card>}
          <div className="text-center mt-4 mb-4">
            <button type="submit" className="btn btn-primary" >Save</button>
          </div>

        </form>
      </Container>}
    </div>


  );
};

export default Initialprofile;

