import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, Row, Col, UncontrolledTooltip } from "reactstrap";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { history } from '../../../_helpers/history';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import { channelAction } from '_actions/channel.action';
import { connect, useSelector, useDispatch } from 'react-redux';
import { dashboardConstants } from '_constants';
import { dashboardService } from '_services';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Product = (props) => {
  const { filterValueState, activeTab, search, from, productFlag } = props;
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(12);
  let [total, setTotal] = useState(0);
  let [isLoading, setLoading] = useState(false);
  let user = JSON.parse(localStorage.getItem('CHuser'));
  let [data, setData] = useState([]);
  let [sort, setSort] = useState('asc');
  let planId = user?.planId;
  const dispatch = useDispatch();

  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  let [isModalPopup, setModalPopup] = useState(false);

  const notificationAlert = useRef(null);
  const redux_data = useSelector(state => state.channelListing);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };


  //url for product detail redirecting
  let productDetailUrl = '/admin/channel/productDetail/';
  if (from == 'favourites') {
    productDetailUrl = '/admin/favourites/productDetail/';
  }

  useEffect(() => {
    if (redux_data.sorting) {
      if (sort !== redux_data.sorting) {
        // setSort(redux_data.sorting);
        sort = redux_data.sorting;
        setSort(sort);
        callProductList();
      }
    }
  }, [redux_data.sorting]);

  useEffect(() => {
    if (activeTab)
      callProductList();
  }, [filterValueState, search]);

  const callProductList = () => {
    console.log("activeTabss:", activeTab);
    page = 0;
    setPage(page);
    if (activeTab === '100') {
      getProductWishlist();
    } else {
      getProductList();
    }
  }
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 220,
    },
  });

  const getProductList = async () => {

    let postData = {
      filter: getFilterData(),
      order: sort ? sort : 'asc', //:'desc',
      search: filterValueState.search ? filterValueState.search.id == '0' ? filterValueState.search.name : +filterValueState.search.id : null,
      offset: page * rowsPerPage,
      // offset:1,
      limit: rowsPerPage,
    }
    setLoading(productFlag);

    let res = await commonService.postService(postData, 'product/suggestion/' + activeTab).catch(err => setLoading(false));
    if (res) {
      //console.log(res.data);  
      data = res.data.product.map(v => ({ ...v, isFavorite: v?.wishList?.length > 0 ? true : false }));
      setData(data);
      total = res.data.total;
      setTotal(total);
      props.channelRedux(res.data.master, 'PRODUCTS');
      if (redux_data?.tab_count) {
        let count = redux_data?.tab_count;
        if (activeTab === '22') {
          count.product_suggestion_count = total;
        }
        if (activeTab === '31') {
          count.all_product_count = total;
        }
        else if (activeTab === '21') {
          count.product_others_count = total;
        }
        props.channelRedux(count, 'TABCOUNT');
      }
      setLoading(false);
    } else {
      data = [];
      setData(data);
      total = 0;
      setTotal(total);
      setLoading(false);
    }
  }

  const getProductWishlist = async () => {

    let postData = {
      // filter:getFilterData(),
      type: "WISHLIST",
      // order:filterValueState.sort?'asc':'desc',
      // search:filterValueState.search?filterValueState.search.id:null,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    }
    setLoading(true);
    let res = await commonService.postService(postData, 'favorite').catch(err => setLoading(false));
    if (res) {
      //console.log(res.data);  
      data = res.data.results.map(v => ({ ...v, isFavorite: v.wishList?.length > 0 ? true : false }));
      setData(data);
      total = res.data.total;
      setTotal(total);
      props.setProductCount(total);
      setLoading(false);
    } else {
      data = [];
      setData(data);
      total = 0;
      setTotal(total);
      setLoading(false);
    }
  }



  const getFilterData = () => {
    let filter = {
      position: null,
      brand: null,
      //family: null,
      country: null,
      location: null,
      profile: null,
      resellerprofile: null,
      category: null,
      fromPrice: null,
      toPrice: null
    };
    console.log("filterValueState:", filterValueState);
    if (filterValueState.Position && filterValueState.Position.length > 0) {
      filter.position = filterValueState.Position.map(x => parseInt(x.id));
    }
    if (filterValueState.Brand && filterValueState.Brand.length > 0) {
      filter.brand = filterValueState.Brand.map(x => parseInt(x.id));
    }
    if (filterValueState.Location && filterValueState.Location.length > 0) {
      filter.country = filterValueState.Location.map(x => parseInt(x.id));
    }
    // if (filterValueState.Family && filterValueState.Family.length > 0) {
    //   filter.family = filterValueState.Family.map(x => parseInt(x.id));
    // }
    if (filterValueState.Current_Product && filterValueState.Current_Product.length > 0) {
      filter.category = filterValueState.Current_Product.map(x => parseInt(x.childid));
    }
    if (filterValueState.Profile && filterValueState.Profile.length > 0) {
      filter.profile = filterValueState.Profile.map(x => parseInt(x.id));
    }
    if (filterValueState.Reseller_Profile && filterValueState.Reseller_Profile.length > 0) {
      filter.resellerprofile = filterValueState.Reseller_Profile.map(x => parseInt(x.id));
    }
    if (filterValueState.Price && filterValueState.Price.length > 0) {
      filter.fromPrice = filterValueState.Price[0];
      filter.toPrice = filterValueState.Price[1];
    }

    console.log('prices', filter.fromPrice, filter.toPrice);
    return filter;
  }

  const notify = (type, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {/* {" "}
            {type} */}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      // icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const add_or_Remove_Favorite = (productId) => {
    let item = data.find(i => i.productId == productId);
    console.log('item', item);
    if (item.isFavorite) {
      axios.delete('favorite', {
        params: {
          id: +productId,
        }
      }).then(res => {
        // data=data.find(i => i.productId === productId).isFavorite = false;
        if (activeTab === '100') {
          page = 0;
          setPage(page);
          getProductWishlist();
        } else {
          data = [].concat(data.map(x => {
            if (x.productId === productId) {
              x.isFavorite = false;
              return x;
            } else
              return x;
          }));
          setData(data);
        }
        //console.log('data', data)
        dashboardService.dashboard_count().then(dasboard_count => {
          //console.log("dashboard_count:",dasboard_count);
          dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
        });
      });
      notify('warning', '  Favourite Removed Successfully');
    } else {
      axios.post('favorite/add', {
        type: "WISHLIST",
        id: +productId,
      }).then(res => {
        // data=data.find(i => i.productId === productId).isFavorite = true;
        data = [].concat(data.map(x => {
          if (x.productId === productId) {
            x.isFavorite = true;
            return x;
          } else
            return x;

        }));
        setData(data);
        console.log('data', data)
        dashboardService.dashboard_count().then(dasboard_count => {
          //console.log("dashboard_count:",dasboard_count);
          dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
        });
        notify('success', '  Favourite Added Successfully');
        // axios.post('mailerservice/Buyer_Favorites_Product', { productId: +productId });

      });
    }
  }
  const openChannelDetail = (id) => {
    let win = window.open('/channelDetailView/' + id);
    win.focus();
  }

  //product dismiss

  const [dismissProductId, setDismissProductId] = useState(null);

  const OpenProductDismissDialog = (productId) => {
    modalPopValue.message = 'Do you want Dismiss this product?';
    modalPopValue.title = 'Dismiss';
    modalPopValue.value = '';
    modalPopValue.popUpId = 1;
    setDismissProductId(productId);
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }
  const confirmProductDismissDialog = async () => {
    setModalPopup(false);
    let dismissProduct = {
      'productId': parseInt(dismissProductId),
      'rejectreasonId': 1,
      'comment': ''
    };
    setLoading(true);
    let res = await commonService.postService(dismissProduct, 'Product/reject').catch(err => console.log('err', notify('danger', 'Soming went wrong')));
    setLoading(false);
    if (res) {
      notify('success', 'Product dismissed from your list');
      history.push('/admin/channel')
    }
    setDismissProductId(null);
  }

  const closePopup = () => {
    setDismissProductId(null);
    setModalPopup(false);
  };



  // useEffect(() => { setData(data);  }, [data]);

  console.log('dataafter', data)

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    if (activeTab === '100') {
      getProductWishlist();
    } else {
      getProductList();
    }
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callProductList();
  };

  const getImage = (e, item) => {
    let link = e.image.documentUrl;
    let imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];
    let lowerCaseLink = link.toLowerCase();
    let isImageLink = imageExtensions.some(extension => lowerCaseLink.endsWith(extension));
    if (isImageLink) {
      return (
        <img sx={{ m: 1 }} onError={(event) => { event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="pointer gallery_img" alt="..." src={e.image.documentUrl} onClick={() => history.push(productDetailUrl + item.productSKU)} />
      )
    } else {
      return null;
    }
  }


  // useBeforeFirstRender(() => {
  //   (async() => {
  //     await axios.get('product/suggestion').then(res =>{  
  //       console.log("product:",res);
  //     })

  //   })()

  // })

  return (

    <div id="Product" className="main-content mt-3">
      <NotificationAlert ref={notificationAlert} />
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmProductDismissDialog} handleClose={closePopup} outboundRemaind={null} />
      {(data.length <= 0 && !isLoading) && <div className="no-records ">
        {/* <span>No Records</span> */}
        <div className="no-img text-center">
          <img onError={(event) => { event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} /></div>
        {(activeTab == '22') &&
          'Unfortunately, based on your product categories and target countries there is no matching product at the moment. '
          + 'You can use our advanced search features to find other results based on your own filters or modify your target countries in your Personal Profile.'
          + 'note: Target countries are user-based and not company-based.'}
      </div>}
      <Card>
        <CardBody>
          <Row className="align-items-center">
            {isLoading ? <Spinner /> : <>
              {data.length > 0 && data.map((item, index) => (

                <Col lg="4" md="4" sm="12" className="hund-height">

                  <div class="gallery">
                    <div className="brandImg">
                      {item.brand ? <img onError={(event) => { event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={(item.brand.document?.documentPath) ? process.env.REACT_APP_CDN_URL + item.brand.document.documentPath : 'https://d35w8j22j5uly8.cloudfront.net/account-s3/Company_Logo_Default.jpg'} /> :
                        <></>}
                      {!item.brand && (
                        // Render the default image if item.brand doesn't exist
                        <img
                          className="img-fluid"
                          alt="ch-logo"
                          src="https://d35w8j22j5uly8.cloudfront.net/account-s3/Company_Logo_Default.jpg"
                        />
                      )}
                    </div>

                    {/* <i class="far fa-heart outline" style={{ color: item.isFavorite ? 'red' : 'gray' }} key={item.isFavorite} aria-hidden="true" onClick={() => { add_or_Remove_Favorite(item.productId) }}></i> */}


                    <div class="gallery_content col-height">

                      <div class="justify-content-end productIcon1 row">
                        <div data-placement="top" id={'tooltipthumbs' + item.productId} class="Icon1 pr-2">
                          <i class="fas fa-thumbs-up heart-r" style={{ color: item.isFavorite ? 'red' : '#525f7f' }} key={item.isFavorite} aria-hidden="true" onClick={() => { add_or_Remove_Favorite(item.productId) }} ></i>
                          <UncontrolledTooltip target={'tooltipthumbs' + item.productId} placement='top'>
                            Add to wishlist
                          </UncontrolledTooltip></div>
                        <div data-placement="top" id={'tooltipthumbsdown' + item.productId} class="Icon3">
                          <i class="fas fa-thumbs-down" onClick={() => OpenProductDismissDialog(item.productId)}></i>
                          <UncontrolledTooltip target={'tooltipthumbsdown' + item.productId} placement='top'>
                            Dismiss
                          </UncontrolledTooltip></div>
                      </div>

                      <div>
                        {/* <CustomWidthTooltip title={item.productDetail?.usp.replace(/<[^>]+>/g, ' ')} placement="bottom">        */}
                        <Carousel responsive={responsive}>
                          {item?.productImage.map((e) => {
                            return (
                              // <img  onError={(event) => { event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="pointer gallery_img" alt="..." src={e.image.documentUrl} onClick={() => history.push(productDetailUrl + item.productSKU)} />
                              getImage(e, item)
                            );
                          }
                          )}
                        </Carousel>
                        {/* <img sx={{ m: 1 }} onError={(event) => { event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="pointer gallery_img" alt="..." src={process.env.REACT_APP_CDN_URL + (item.defaultImage ? item.defaultImage.image.documentPath : 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg')} onClick={() => history.push(productDetailUrl + item.productSKU)}/> */}
                        {/* </CustomWidthTooltip> */}
                      </div>
                      <h3 className="text-left mt-1">{item.product}</h3>
                      <div style={{ display: 'flex', justifyContent: "space-between" }} className="text-left ml-2 gestcomptitle vendsize pts pmb">
                        <p style={{ cursor: 'pointer', fontSize: "12px" }} onClick={() => { openChannelDetail(item?.channelId) }}>{item.channel?.companyName.length > 13 ? item.channel?.companyName.slice(0, 12) + "..." : item.channel?.companyName}</p>
                        <div className="n-ppost"><p className="amr">{item.productAwards.length > 0 ? <EmojiEventsIcon style={{ color: "rgb(82, 95, 127)" }} /> : <></>}{item.productAwards.length > 0 ? item.productAwards.length : <></>}</p></div>
                        <div className="n-ppost-name">{item.productAwards.length > 0 && item.productAwards.map((award, index) => (
                          <div className={`${item.productAwards.length > 0 ? 'awards_border' : ''} awards_thumb mr-2`}>
                            <img onError={(event) => { event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt="..." src={award?.document?.documentPath ? process.env.REACT_APP_CDN_URL + award.document.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                          </div>
                        ))}</div>
                      </div>
                      {item.productDetail?.productshortdec && <><h3 className="text-left mt-1"></h3>
                        <p className="text-left ml-2 parprod-des mt-1">{item.productDetail?.productshortdec.length > 100 ? item.productDetail?.productshortdec.slice(0, 50) : item.productDetail?.productshortdec}</p></>}
                    </div>
                  </div>




                  {/* <div class='products-card'>
                    <div className="brandImg">
                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={"http://d2maj8ifjo1lti.cloudfront.net/" + item.brand.document.documentPath} />
                    </div>
                    <div class='set-image'>
                      <div data-placement="top" id="Dismiss" className="Icon3 down-dismiss" onClick={() => OpenProductDismissDialog(item.productId)}>
                        <i class="far fa-thumbs-down"></i>
                      </div>
                      <i class="far fa-thumbs-up outline" style={{ color: item.isFavorite ? 'red' : '#525f7f' }} key={item.isFavorite} aria-hidden="true" onClick={() => { add_or_Remove_Favorite(item.productId) }}></i>

                      <UncontrolledTooltip delay={0} placement="top" target="Dismiss">Dismiss</UncontrolledTooltip>

                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="pointer" alt="..." src={process.env.REACT_APP_CDN_URL + (item.defaultImage ? item.defaultImage.image.documentPath : 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg')} onClick={() => history.push('/admin/channel/productDetail/' + item.productSKU)} />


                    </div>

                    <div class={`${item.productAwards.length > 0 ? 'set-headline' : 'set-headline1'} mt-2 p-2`}>
                      <h3>{item.product}</h3>
                      <div class='d-flex justify-content-end awards'>
                        {item.productAwards.length > 0 && item.productAwards.map((award, index) => (
                          <div>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt="..." src={award?.document?.documentPath ? process.env.REACT_APP_CDN_URL + award.document.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                          </div>

                        ))}


                      </div>
                    </div>

                  </div> */}

                </Col>

              ))}

            </>}
          </Row>

        </CardBody>
      </Card>


      {(data.length > 0 && data.length > 11) &&
        <TablePagination
          component="div"
          count={total}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[12, 24, 36, 48]}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      }

    </div>

  );
};
function mapState(state) {
  const general_data = state.channelListing;
  return general_data;
}

const actionCreators = {
  channelRedux: channelAction.channelRedux
};

export default connect(mapState, actionCreators)(Product);