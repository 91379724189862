import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LocalStorageService } from '../_services';
import axios from 'axios';
import { history } from '_helpers/history';
import { useBeforeFirstRender } from '_hooks/useBeforeFirstRender ';
import { Spinner } from "views/Hoc/Spinner";

const localStorageService = LocalStorageService.getService();



export const PrivateRoute = ({ component: Component, ...rest }) => {
  const [authenticate, setAuthenticate] = useState(null);
  const LoggedIn = async () => {
    let res = '';
    let userData = JSON.parse(localStorage.getItem('CHuser'));
    if (userData) {
      var cuurentDate = new Date();
      var planExpireAt = new Date(userData.planExpireAt);
      var expire = +cuurentDate.getTime() > +planExpireAt.getTime();
      
// rest.path != '/admin/Subscription/Payment' || rest.path != '/Subscription/Payment')
      if ((expire === true && userData.channelTypeId == 2 && userData.planId != '1') && (rest.path != '/admin/Subscription/Payment' && rest.path != '/admin/stripepayment' && rest.path != '/admin/alipaypayment'  && rest.path != '/admin/Paypal' && rest.path != '/admin/Paymentsuccess')) {
        history.push('/admin/Subscription');
      }
    }
    let token = localStorage.getItem('fo-auth-token');
    if (token) {
      res = await axios.get('auth/token').then(res => {
        if (res.status == 200) {
          // return <Route {...rest} exact render={props => ( <Component {...props} /> )} />;
          return 200;
        }
        else {
          // history.push('/');

          localStorage.clear();
          // return <Route {...rest} exact render={props => (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)} />;
          return 401;
        }
      }).catch(err => {
        // history.push('/');

        localStorage.clear();
        // return <Route {...rest} exact render={props => (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)} />;
        return 401;

      })
    }
    else {
      if (rest.path == '/admin/channel/channelDetail/:id')
        localStorage.setItem('channelView', rest.location.pathname);

      // return <Route exact {...rest} render={props => (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)} />
      return 401;
    }
    if (res)
      return res;
  }


  useBeforeFirstRender(async () => {
    let value = await LoggedIn();
    setAuthenticate(value);
  });


  return (
    authenticate ? <Route {...rest} exact render={props => (
      authenticate == 200 ? <Component {...props} />
        : <Redirect to={{ pathname: '/' }} />
    )} /> : <Spinner />


    // <Route {...rest} render={props => ( <Component {...props} /> )} />
  )
}