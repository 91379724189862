import React, { useState, useEffect } from 'react';
import Gallery from "react-photo-gallery";
import { Card, CardBody, Row, Col, Container, NavItem, NavLink, Nav, TabContent, TabPane } from "reactstrap";

//import Photo from "./Photo";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import axios from 'axios';
var _ = require('lodash');
const photos = [
    {
        src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
        // width: 50,
        // height: 50,
        id: 1
    },
    {
        src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
        // width: 50,
        // height: 50,
        id: 2
    },
    // {
    //     src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
    //     width: 1,
    //     height: 1,
    //     id: 3
    // },
    // {
    //     src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
    //     width: 1,
    //     height: 1,
    //     id: 4
    // },
    // {
    //     src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
    //     width: 1,
    //     height: 1,
    //     id: 5
    // },
    // {
    //     src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
    //     width: 4,
    //     height: 3
    // },
    // {
    //     src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
    //     width: 3,
    //     height: 4
    // },
    // {
    //     src: "https://source.unsplash.com/PpOHJezOalU/800x599",
    //     width: 4,
    //     height: 3
    // },
    // {
    //     src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
    //     width: 4,
    //     height: 3
    // }
];
const imgWithClick = { cursor: "pointer" };
const SortablePhoto = SortableElement(item => <Photo {...item} />);
const SortableGallery = SortableContainer(({ items }) => (
    <Gallery photos={items} renderImage={props => <SortablePhoto {...props} />} />
));

const Reordernew = (props) => {
    const { productList, updateProductRank } = props;
    let [items, setItems] = useState([]);

    useEffect(() => {
        let arr = [];
        // console.log("pritems:", productList);
        productList.map(x => {
            let obj = {
                src: x.documentUrl,
                productId: +x.productId
            }
            arr.push(obj);
        })
        setItems(arr);

    }, []);


    const onSortEnd = ({ oldIndex, newIndex }) => {
        items = arrayMove(items, oldIndex, newIndex)
        setItems(items);
        //console.log("pritems:", items);
        updateProductRank(items);
    };

    return (
        <div>
            {/* <h2>Sortable Gallery</h2>
            <h3>Drag photo to rearrange</h3> */}
            <SortableGallery items={items} onSortEnd={onSortEnd} axis={"xy"} />
        </div>
    );
}

export const Photo = ({ index, onClick, photo, margin, direction, top, left }) => {
    const imgStyle = { margin: margin, width: 250, height: 250 };

    if (direction === "column") {
        imgStyle.position = "absolute";
        imgStyle.left = left;
        imgStyle.top = top;
    }

    const handleClick = event => {
        onClick(event, { photo, index });
    };

    return (
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
            className="row"
            style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
            {...photo}
            //src={photo.documentUrl}

            onClick={onClick ? handleClick : null}
            alt="img"
        />
    );
};


export default Reordernew;