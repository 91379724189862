import React, { useEffect, useState, useMemo } from "react";
import { Card,CardBody,FormGroup,Form, Container,Col,Row,Table,Button } from "reactstrap";
import { commonService } from '_services/common.service';
import { history } from '../../../../_helpers';
import {ToastsContainer, ToastsStore} from 'react-toasts';

const Wallet = (props) => {

    let [userWalletAmount, setuserWalletAmount] = useState(0);
    let [userbalanceAmt, setuserbalanceAmt] = useState(null);
    let [req_channelId, setrequestChannel] = useState(0);
    let [channelJctId, setchannelJctId] = useState(0);
    let [connectioncost, setConnectioncost] = useState(10.00);
    useEffect(() => {
          
        getWalletData();
          }, [])

    const getWalletData = async () => {
        var url_string =  window.location.href
        var url = new URL(url_string);
        let getparams = url_string.split('/'); 
        
        setrequestChannel(getparams[getparams.length - 2]);
        setchannelJctId(getparams[getparams.length - 1]); 
        let userdata = localStorage.getItem('CHuser');   
        let res = await commonService.getServices('walletRecharge/getWallet').catch(err =>console.log('err'));
        if(res){
            let walletAmt = 0;
            if(res.data.wallet != null){
                walletAmt = res.data.wallet.amount;
            }
            setuserWalletAmount(walletAmt);
            let userbalanceAmt = 0;

            if(+walletAmt >= +connectioncost){
               
                userbalanceAmt = parseFloat(walletAmt) - connectioncost;
            } else {  
                userbalanceAmt = 'Insufficent balance for this purchase, please recharge to proceed further';
            }
            setuserbalanceAmt(userbalanceAmt);
        }
        /*********************************** */
    }

    const handleProceed = async() => {
        if(userbalanceAmt >= 0){
            let res = await commonService.postService({  
                req_cid:req_channelId,
                jctId:channelJctId
            }, 'walletRecharge/processWalletPayment').catch(err =>console.log('err', ToastsStore.error('Error in purchase')));

            if(res){
                history.push('/admin/channel'); 
            }
        } else {
            history.push('/admin/payment/wallet'); 
        }
    }
   
return (
        <div className="main-content" id="WalletPurchase">
<ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
<Container className="mt-4 col-lg-11 col-md-11 col-sm-12" fluid>
      <Row className="justify-content-center pb-5">
        <Col lg="12"> <Card>
            <CardBody className="p-3">

            <div className="justify-content-center pb-5" > <h1>e-Wallet Purchase  </h1><hr className="m-0 ml-3 paycarhr" style={{    width: "18%"}}/> </div>


            <div className="mt-2 h3">
            Current balance : <span>{userWalletAmount}</span>
            </div>

            <div className="mt-2 h3">
            Since record view + connectioncost : <span>{connectioncost} eur</span>
            </div>

            <div className="mt-2 h3">
            Remaining balance : <span>{userbalanceAmt}</span>
            </div>

            <div className="justify-content-center d-flex">
                <button className="btn btn-primary" onClick = {handleProceed}>Proceed</button>
            </div>
            
            </CardBody>
        </Card></Col>
      </Row>
      </Container>
      </div>
        
    );
};



export default Wallet;