import React, { useState } from "react";
import { Card, CardBody, Container, Col, Row, Button } from "reactstrap";
import { history } from '../../../../_helpers';
import { connect, useSelector } from 'react-redux';
import { subscriptionAction } from '_actions/subscription.action';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { commonService } from '_services/common.service';
import { userActions } from '_actions/user.actions';

const PaymentSuccess = (props) => {
  const redux_data = useSelector(state => state.event);

  let userData = JSON.parse(localStorage.getItem('CHuser'));
  let wireTransfer = (userData.paymentMethodId == 2) ? true : false;
  let cardpayment = (userData.paymentMethodId == 1) ? true : false;
  let eventName = redux_data?.eventDetails?.name;
  const paymentDetails = redux_data.eventPayment;
  // const redux_data = useSelector(state => state.subscription);
  // console.log(redux_data.pay_amt);
  // if(userData.paymentMethodId != 2) {
  //     let res = commonService.postService({id: +userData.channelId, userId: +userData.userId}, 'mailerservice/subscriptionEmail').catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));
  // }
  
  //console.log(' userData ==== >  :', userData);
  //console.log('eventname  :', redux_data?.eventDetails,redux_data?.eventPayment);
  if(userData?.profile_completion_count == "" && !paymentDetails ){
    
  //console.log(eventName,' userData?.profile_completion_count ==== >  :', userData?.profile_completion_count);
    setTimeout(() => {
      userActions.logout();
    }, 500);
  }

  return (
    <div className="main-content" id="PaymentConfirm">
      {/* <Title desc={desc} /> */}
      <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>

        <Card className="p-3">
          <h1 className="ml-3 mt-3">Success</h1><hr className="m-0 ml-3 paycarhr" style={{ width: "14%" }} />
          <h1 className="text-center"><i style={{ fontSize: '3rem', color: '#2c9db7' }} class="fa fa-check-circle" aria-hidden="true"></i>
          </h1>
          <Row className="justify-content-center pb-2">

            <Col lg="8" >
              <CardBody style={{textalign:"center"}}>
                   {wireTransfer ?
                  <p>Thank you for choosing subscription. An email has been sent to you <span class='bold-text'>{userData.email}</span> with details for wire transfer.<br /> Your account will be activated and the subscription period will be calculated from the day the full payment is received in our bank account.</p>
                  :
                  (cardpayment && typeof eventName == "undefined") ? <p>Hurrah, You have changed to  {userData.planName}, Now you can enjoy your ChannelHub features.</p>
                  :
                  <p>{`${paymentDetails.isAddon?`You are successfully registered addon for the event ${eventName}`:` You are successfully registered for the event ${eventName? eventName : ''} `}. Our team will send confirmation details to your email.`}</p>
                  }
              </CardBody>
            </Col>
          </Row>
          <div className="text-center">
          {(!eventName)?
            <Button color="primary" type="button" onClick={() => window.location.href = process.env.REACT_APP_LOCALSITE + "admin/dashboard"}>
              Continue
            </Button> 
              :
            <button  className="btn btn-primary text-center" variant="contained" color="primary" onClick = {() => paymentDetails.isAddon?history.push('/admin/events/eventManagement/' + paymentDetails.eventId):history.push('/admin/events')}>
            Click Here to Event Page
          </button>
          }
  
          </div>


        </Card>
      </Container>
    </div>


  );
};

function mapState(state) {
  console.log('state', state);
  const general_data = state.subscription;
  return general_data;
}

const actionCreators = {
  subscriptionRedux: subscriptionAction.subscriptionRedux
};

export default connect(mapState, actionCreators)(PaymentSuccess);
