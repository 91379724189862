import React, { useState, useEffect } from "react";
import { Container, Row, Button, Col, CardBody, CardHeader, Card, Nav, NavItem, NavLink, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Badge } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader"
import Title from "views/Hoc/Dialog/Title";
import { SelectAutocomplete, FormAutocomplete } from '_components/FormElements/FormInput';
import { useForm } from "react-hook-form";
import { history } from '_helpers'
import Myprodfilter from './Myprodfilter';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PlanFeatureDialog } from "views/Hoc/Dialog/PlanFeatureDialog";
import { userConstants } from '../../../../src/_constants';
import { UncontrolledTooltip } from "reactstrap";
import Tooltip from '@mui/material/Tooltip';
import ReOrderDialog from "views/Hoc/Dialog/ReOrderDialog";
import SortIcon from '@mui/icons-material/Sort';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Globaleditdrawer from "views/pages/Channel-Listing/Globaleditdrawer";
import axios from 'axios';
import { channelAction } from '_actions/channel.action';
import { connect, useSelector, useDispatch } from 'react-redux';

var _ = require('lodash');

const Myproductlist = (props) => {
  let [tabs, setTab] = useState('1');
  let [pendingData, setPendingData] = useState('List of your various products');
  
  const [opneadd, opneAddnew] = useState(false);
  let [tabCount, setTabCount] = useState({ active: 0, inActive: 0, approval: 0,partActive:0 });
  let [sort, setSort] = useState('asc');
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [total, setTotal] = useState(0);
  let [isLoading, setLoading] = useState(true);
  let [data, setData] = useState([]);
  const [previlege, setPrevilege] = useState(null);
  const [productLimit, setProductLimit] = useState(true);
  const { control } = useForm({ mode: 'onBlur' });
  const [filterValueState, setFilterValue] = useState({ Position: null, Brand: [], Family: [], Category: [], search: null, sort: true });
  const [searchState, setSearch] = useState({ search: null, dropdownList: [] });
  const [planUpgrade, setplanUpgrade] = useState(false);
  const [upgradeType, setUpgradeType] = useState('productAdd');
  const [reordermodal, setReOrderModal] = useState(false);
  const [productIdOrder,setProductIdOrder] = useState("");
  const [newOrder,setNewOrder] = useState("");
  const [currentOrder,setCurrentOrder] = useState("");
  const [channelId,setChannelId] = useState("");
  let logo = "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg";

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });

  let user = JSON.parse(localStorage.getItem('CHuser'));

  useEffect(() => {
    getMyProductList();
   // getProductLimit();
    getAccessPrevilege();

    // return () => {
    //   clean operation
    // }

  }, []);

  const toggle = (current,productId,channelId) => {
    console.log(data)
    setCurrentOrder(current)
    setProductIdOrder(productId)
    setChannelId(channelId)
    setReOrderModal(!reordermodal)
  };

 const finalReOrdering = async ()=>{
  if(newOrder == 0 || newOrder>data.length){
    return ToastsStore.error(`Please enter value between 1 and ${data.length}`);
  }
  setLoading(true);
  let res=await axios.post("/product/updateSortOrder",{
  newOrder:newOrder,
  currentOrder:currentOrder,
  productId:productIdOrder,
  channelId:channelId
})
setLoading(false);

  getMyProductList();
 ToastsStore.success('Successfuly set the new order');
 setReOrderModal(!reordermodal)
}

const handleChangeOrder = (e)=>{
    setNewOrder(e.target.value)
}

  const getAccessPrevilege = () => {
    (async () => {
      await axios.get('user/userActionPrevilege/' + 50).then(res => {
        setPrevilege(res.data.length > 0 ? res.data[0] : 0);
      }).catch(err => {
        ToastsStore.error('No Access Found!');
      });
    })();
  }

  const getMyProductList = () => {
    setPage(0);
    getMyProductListing();
  }
  const setActiveTab = (tab) => {
    tabs = tab;
    setTab(tab);
    if(tab == 2) {
      setPendingData('Your New products will only be visible by satisfying :  At least 1 image is added for each product. And product is approved by our back-office team')
    } else {
      setPendingData('List of your various products')
    }
    getMyProductList();
  }
  const setSorting = (data) => {
    if (data != sort) {
      sort = data;
      setSort(sort);
      getMyProductList();
    }

  }
  const getMyProductListing = async () => {
    let postData = {
      filter: getFilterData(),
      order: sort ? sort : 'asc', //:'desc',
      search: filterValueState.search ? filterValueState.search.id : null,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      isactive: tabs == 2 || tabs=="3"? 0 : tabs,
      isApproved: tabs == 2 || tabs=="3"? '0' : null,
    }
    setLoading(true);

    let res = await commonService.postService(postData, 'product/my-products').catch(err => setLoading(false));
    if (res) {
      data = res.data;
      setData(data.result);
      total = res.data.total;
      setTotal(total);
      props.channelRedux(res.data.master, 'PRODUCTS');
      tabCount.active = res.data.tabCount.active
      tabCount.inActive = res.data.tabCount.inActive
      tabCount.approval = res.data.tabCount.approval
      tabCount.partActive = res.data.tabCount.partActive
      if (tabs === '1') {
        tabCount.active = total;
      } else if (tabs == '0') {
        tabCount.inActive = total;
      } else if (tabs == "2"){
        tabCount.approval = total;
      } else{
        tabCount.partActive = total;
      }
      setTabCount(tabCount)
      setLoading(false);
    } else {
      data = [];
      setData(data);
      total = 0;
      setTotal(total);
      setLoading(false);
    }
  }
  const [showDrawer, setshowDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState(null);

  const getFilterData = () => {
    let filter = {
      position: null,
      brand: null,
      family: null,
      country: null,
      location: null,
      profile: null,
      category: null,
      partActive: tabs == "3" ? '1' : null,
      isPending: tabs == "2" ? '1':null
    };
    console.log("filterValueState:", filterValueState);
    if (filterValueState.Position) {
      filter.position = filterValueState.Position.id;
    }
    if (filterValueState.Brand && filterValueState.Brand.length > 0) {
      filter.brand = filterValueState.Brand.map(x => parseInt(x.id));
    }
    if (filterValueState.Family && filterValueState.Family.length > 0) {
      filter.family = filterValueState.Family.map(x => parseInt(x.id));
    }
    if (filterValueState.Category && filterValueState.Category.length > 0) {
      filter.category = filterValueState.Category.map(x => parseInt(x.id));
    }

    console.log('filter', filter);
    return filter;
  }
  const handleChangeSelect = (input, value) => {
    console.log('input, value', input, value);
    if (input === 'search') {
      filterValueState.search = value;
    }
    if (input === 'sort') {
      filterValueState.sort = value;
    }
    if (input === 'position') {
      filterValueState.Position = value;
    }
    if (input === 'brand') {
      filterValueState.Brand = value;
    }
    if (input === 'family') {
      filterValueState.Family = value;
    }
    if (input === 'category') {
      filterValueState.Category = value;
    }
    setFilterValue({ ...filterValueState })
    console.log('filterValueState', filterValueState);
    getMyProductList();
  }
  const resetFilterValue = () => {
    filterValueState.Position = [];
    filterValueState.Brand = [];
    filterValueState.Family = [];
    filterValueState.Category = null;
    filterValueState.search = null;
    filterValueState.sort = null;
    setFilterValue({ ...filterValueState });
  }
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getMyProductListing();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    getMyProductListing();
  };
  const searchOption = (option) => {
    searchState.dropdownList = option;
    setSearch({ ...searchState });
  }
  const productEdit = (sku, id) => {
    if (id == 1)
      history.push('/admin/Myproductlist/EditProduct?sku=' + sku + '&action=' + id);
    if (id == 2)
      history.push('/admin/Myproductlist/CloneProduct?sku=' + sku + '&action=' + id);
  }
  const productDelete = async (sku) => {
    setLoading(true);
    let res = await commonService.deleteService('product/' + sku).catch(err => ToastsStore.error('Something went wrong!...'));
    setLoading(false);
    if (res) {
      ToastsStore.success('Product deleted successfully!...');
      getMyProductList();
    }
  }
  const confirmPopup = (res) => {
    setModalPopup(false);
    productDelete(res.value);
  }
  const closePopup = () => {
    setModalPopup(false);
  };
  const deletePopup = (sku) => {
    modalPopValue.message = 'Do you want to delete the product?';
    modalPopValue.title = 'Product';
    modalPopValue.value = sku;
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }

  //Move product towards Up
  const moveUp = async (sku) => {
    let newdata = [];
    let prevItem = {};
    let flag = 0;
    data.map((x, index) => {
      if (x.productSKU == sku) {
        let p = data.slice(0, (index - 1));
        newdata.push(...p);
        newdata.push(x);
        newdata.push(prevItem);
        flag = 1;
      } else if (flag == 1) {
        newdata.push(x);
      }
      else if (flag == 0) {
        prevItem = x;
      }

    })

    let rorder = 0;
    newdata.map(x => {
      rorder = rorder + 1;
      Object.assign(x, { order: rorder })
    })

    setData(newdata);
    commonService.putService(data, 'product/setProductOrder');
  }


  //Move product towards Down
  const moveDown = async (sku) => {
    let newdata = [];
    let nextItem = {};
    let flag = 0;
    data.map((x, index) => {
      if (x.productSKU == sku) {
        let p = data.slice(0, index);
        newdata.push(...p);
        nextItem = x;
        flag = 1;
      } else if (flag == 1) {
        newdata.push(x);
        newdata.push(nextItem);
        flag = 2;
      }
      else if (flag == 2) {
        newdata.push(x);
      }

    })

    let rorder = 0;
    newdata.map(x => {
      rorder = rorder + 1;
      Object.assign(x, { order: rorder })
    })

    setData(newdata);
    commonService.putService(data, 'product/setProductOrder');
  }

  const setPublicView = async (productId, isPublic) => {
    let item = {
      productId: +productId,
      isPublic: isPublic ? 1 : 0
    }
    data = data.map(x => {
      if (x.productId == item.productId)
        x.isPublic = item.isPublic;
      else
        x.isPublic = x.isPublic;
      return x;
    })
    setData(data);

    await commonService.putService(item, 'product/setProductPublicView');

  }

  const opneAdd = () => {
    // setProductList(data[indexValue].product);
    // setIndex(indexValue);
    opneAddnew(true);
  }
  const closeDrawer = () => {
    setshowDrawer(false);
  }
  const closeModalPopup = (items) => {
    opneAddnew(false);
    let updateList = [];
    let sorted = _.sortBy(data, x => _.findIndex(items, y => +x.productId === +y.productId))
    data = sorted;
    setData(data);

    items.map((x, index) => {
      let list = {
        productId: +x.productId,
        order: index + 1
      }
      updateList.push(list);
    })

    commonService.putService(updateList, 'product/setProductOrder');

  }


  const getProductLimit = async () => {

    let featureList = user.featureList
    console.log('my feature lists  ======== ');
    // console.log(featureList[29]);
    if (featureList && featureList[userConstants.NO_OF_PRODUCT]) {
      setProductLimit(featureList[userConstants.NO_OF_PRODUCT]);
    }
    // await commonService.getServices('product/productlimit').then(res =>{ 
    //   if(res.data.allowed){
    //     setProductLimit(res.data.allowed);
    //   }     
    // });      

  }
  //history.push({pathname:'/admin/import', state: {type: 2}})
  const openUpgradeDialog = (action) => {

    let productCount = parseInt(tabCount.active);//+ parseInt(tabCount.inActive);
    //if (productLimit > productCount || productLimit === 'Unlimited') {
      if (action == 'productImport') {
        history.push({ pathname: '/admin/startupprodimport', state: { type: 2 } })
      } else {
       // history.push({ pathname: '/admin/Myproductlist/Productform', state: { type: 2 } });
          history.push({ pathname: '/admin/Myproductlist/AddProduct', state: { type: 2 } });
       
      }
    // } else {

    //   setplanUpgrade(true);
    // }
  }

  const closeplanUpgrade = () => {
    setplanUpgrade(false);
  }
  const changedData = () => {

  }
  return (

    <>
      {isLoading && <Spinner />}
      <SimpleHeader location={props.match} />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <ReOrderDialog opneadd={opneadd} closeModalPopup={closeModalPopup} productList={data} />
      <Globaleditdrawer title="Product Visibility" visible={showDrawer} closeDrawer={closeDrawer} changedData={changedData} type={'4'} />

      {planUpgrade && <PlanFeatureDialog closeUserView={closeplanUpgrade} UpgradeType={upgradeType} />}
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      <div id='MyProduct' className="main-content">
        <Title title="My Product" desc={[ pendingData]} />
        
        <Container fluid className="mt-4">
          <Nav tabs className="list-tab">
            <NavItem className="list-main">
              <NavLink className="active">

                <Row className="text-uppercase">
                  <Col onClick={() => setActiveTab('1')} xs="3" className={" mt-2 sub-a p-2 " + (tabs == 1 && "hilight")}>
                    <a style={{ cursor: 'pointer' }}>
                      <h3 className="mb-0">Active</h3>
                      <p class="count m-0">{tabCount.active} </p>
                    </a>
                  </Col>
                  <Col onClick={() => setActiveTab('0')} xs="3" className={" icon-tag2 mt-2 sub-a p-2 " + (tabs == 0 && "hilight")}>
                    <a style={{ cursor: 'pointer' }} >
                      <h3 className="mb-0">In Active</h3>
                      <p class="count m-0">{tabCount.inActive}</p>
                    </a>
                  </Col>
                  <Col onClick={() => setActiveTab('2')} xs="3" className={" icon-tag2 mt-2 sub-a p-2 " + (tabs == 2 && "hilight")}>
                    <a style={{ cursor: 'pointer' }} >
                      <h3 className="mb-0">Pending</h3>
                      <p class="count m-0">{tabCount.approval}</p>
                    </a>
                  </Col>
                  <Col onClick={() => setActiveTab('3')} xs="3" className={" icon-tag2 mt-2 sub-a p-2 " + (tabs == 3 && "hilight")}>
                    <a style={{ cursor: 'pointer' }} >
                      <h3 className="mb-0">Incomplete</h3>
                      <p class="count m-0">{tabCount.partActive}</p>
                    </a>
                  </Col>
                </Row>
              </NavLink>
            </NavItem>

            <NavItem>
              <div className="prodlsact" style={{ textAlign: '-webkit-right', paddingBottom: "2rem" }}>{(user.roleId == 1 || (user.roleId != 1 && (previlege == 1 || previlege == 0))) && <Button color="primary" onClick={() => openUpgradeDialog('addProduct')} ><i class="fas fa-user-plus"></i>  Add Single Product</Button>}
                {/* <Button color="primary" onClick={() => setshowDrawer(true)} ><i class="fas fa-user-plus"></i>product visibility</Button>
                
                 */}
                <Button color="primary" onClick={() => openUpgradeDialog('productImport')} ><i class="fas fa-user-plus"></i>  Add Bulk Products</Button></div>

            </NavItem>
            <NavItem className="pl-2 right-search1">
              <SelectAutocomplete
                id="search"
                type="text"
                label="search"
                name="search"
                options={searchState.dropdownList}
                placeholder="search..."
                disableCloseOnSelect
                // defaultValue={searchState.search?searchState.search[0]:null}
                control={control}
                input='search'
                value={filterValueState.search}
                handleChangeSelect={handleChangeSelect}
              />
            </NavItem>
            <NavItem className="right-sort1">
              <UncontrolledDropdown className="p-0" group>
                <DropdownToggle caret className="p-2">
                  <h3
                    style={{ display: "inline-block" }}
                    className="font-weight-bold m-0"
                  >
                    <i className="fa fa-bars  mr-2" aria-hidden="true"></i>
                    {sort == 'asc' ? 'A-Z' : 'Z-A'}
                  </h3>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem

                    onClick={() => setSorting('asc')}
                  >
                    A-Z
                  </DropdownItem>
                  <DropdownItem

                    onClick={() => setSorting('desc')}
                  >
                    Z-A
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>

          </Nav>

          <Card>
            <CardBody>
              <Row>
                {/* <Col lg="3">
                  <Myprodfilter activeTab={tabs} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} searchOption={searchOption} />
                </Col> */}
                <Col lg="12">
                  {/* <Row>
                    <Col lg="6"></Col>
                    <Col lg="6"><div style={{ textAlign: '-webkit-right', paddingBottom: "2rem" }}><Button color="primary" onClick={() => openUpgradeDialog('addProduct')} ><i class="fas fa-user-plus"></i>  Add Product</Button>
                      <Button color="primary" onClick={() => openUpgradeDialog('productImport')} ><i class="fas fa-user-plus"></i>  Import/Export</Button></div></Col>
                  </Row> */}
                  <Card>
                    {/* <CardHeader style={{ backgroundColor: "#f8f9fe" }}>
                      <Row>
                        <Col lg="4"><h3>Details</h3></Col>
                        <Col lg="8" className="pad-less">
                          <Row>
                            <Col lg="2"><h3>SKU</h3></Col>
                            <Col lg="3"><h3>PRICE</h3></Col>
                            <Col lg="2"><h3>Status</h3></Col>
                            <Col lg="3"><h3>Action</h3></Col>
                            <Col lg="2"><h3>Public View</h3></Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardHeader> */}

                    <CardBody className="pad-less mt-1 MyProduct">
                      <div className="d-flex justify-content-end pt-4 pb-4 bgwhite">
                        {/* <Button color="primary">Re Order</Button> */}
                        {/* <Button onClick={() => opneAdd()} className="btn btn-secondary"> <i class="fa fa-cog mr-2" aria-hidden="true"></i>
                          Re Order Rank</Button> */}
                      </div>

                      {data.length > 0 && data.map((item, index) => (
                        <Row key={index} className="pending_row" style={{ padding: "1rem", borderTop: "1px solid #ebecf1" }}>                     
                          <Col lg="12" md="12" sm="12" xl="4" className="pad-less user-card">
                            <Row className="pt-4 row">
                              <Col lg="1">
                              {tabs == "1" ? <div>
                               <p>{item.sortorder}</p>
                                <Tooltip title="Update Order">
                                <SortIcon onClick={()=>toggle(item.sortorder,item.productId,item.channelId)} cursor="pointer"/>
                                </Tooltip>  
                                </div>:<></>} 
                              </Col>
                              <Col lg="4" md="4" style={{ textAlign: "-webkit-center" }}>
                                <div className="ftl-img12">
                                  <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} alt="..." src={item?.documentUrl ? item?.documentUrl : logo} />
                                </div>
                              </Col>
                              <Col lg="6" md="6" className="pad-less res-md">
                                <h3>{item?.product}</h3>
                                <p style={{ marginBottom: "0px" }}> Brand </p>
                                <Badge className='outer_badge mr-2 outer_badge_xl' style={{ height: "2rem", padding: "0.6rem", backgroundColor: "#2c9db7", color: "#fff", border: "1px solid #2c9db7" }}>
                                  {item?.brandName}
                                </Badge>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg="12" sm="12" md="12" xl="8" className="pad-less">
                            <Row>
                              <Col lg="2" md="6" className="user-card pt4"><h3>{item?.productSKU}</h3></Col>
                              <Col lg="2" md="6" className="user-card pt-4">
                                <h3>Retail Price</h3>
                                <Badge className='outer_badge' style={{ width: "75%", height: "2rem", padding: "0.6rem", border: "1px solid #2c9db7", color: "#2c9db7" }}>
                                  {item?.businesscurrencyid === '1' ? <i class="fas fa-euro-sign"></i> : <i class="fas fa-dollar-sign"></i>} {item?.retailerprice}
                                </Badge>
                              </Col>
                              <Col lg="2" md="6" className="user-card pt4 pr-0">
                                <Row className="outer-badge_row">
                                  <Badge className='outer_badge res-spa'>
                                    {tabs === '1' ? 'Active' : 'InActive'}
                                    <div className="tip"></div>
                                  </Badge>
                                </Row>
                                {item.isApproved == 0 && item.approvalType &&
                                  <Badge className='outer_badge res-spa1 pl-1 pr-1 pt-2 pb-2 mt-2'>
                                    {item.approvalType == 1 ? 'not approved' : 'waiting for activation'}
                                    {/* <div className="tip"></div> */}
                                  </Badge>
                                }
                              </Col>
                              <Col lg="3" md="6" className="user-card pt4 pr-0">
                                <div>
                                  {(user.roleId == 1 || (user.roleId != 1 && (previlege == 1 || previlege == 0))) && <span href="#" id={'copy' + index} onClick={() => productEdit(item.productSKU, 2)}><i class="far fa-copy" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span>}
                                  {(user.roleId == 1 || (user.roleId != 1 && (previlege == 1 || previlege == 0))) && <span href="#" id={'Edit' + index} onClick={() => productEdit(item.productSKU, 1)}><i class="fas fa-pencil-alt ml-3" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span>}
                                  <span href="#" id={'View' + index} onClick={() => history.push('/admin/Myproductlist/productDetail/' + item.productSKU)}><i class="far fa-eye ml-3" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span>
                                  {(user.roleId == 1 || (user.roleId != 1 && (previlege == 1 || previlege == 0))) && <span href="#" id={'Delete' + index} onClick={() => deletePopup(item.productSKU)}><i class="far fa-trash-alt ml-3" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span>}
                                  {(user.roleId == 1 || (user.roleId != 1 && (previlege == 1 || previlege == 0))) && <UncontrolledTooltip target={'copy' + index} placement='bottom'>
                                    Copy
                                  </UncontrolledTooltip>}
                                  {(user.roleId == 1 || (user.roleId != 1 && (previlege == 1 || previlege == 0))) && <UncontrolledTooltip target={'Edit' + index} placement='bottom'>
                                    Edit
                                  </UncontrolledTooltip>}
                                  <UncontrolledTooltip target={'View' + index} placement='bottom'>
                                    View
                                  </UncontrolledTooltip>
                                  {(user.roleId == 1 || (user.roleId != 1 && (previlege == 1 || previlege == 0))) && <UncontrolledTooltip target={'Delete' + index} placement='bottom'>
                                    Delete
                                  </UncontrolledTooltip>}
                                </div>
                              </Col>
                              <Col lg="2" md="6" className="user-card pt-4 pr-0">
                                <div>
                                  <h3>Public View</h3>
                                  <label id={'Pubview' + index} className="custom-toggle custom-toggle-success">
                                    <input type="checkbox" checked={+item.isPublic} name="status" onClick={() => setPublicView(item.productId, !(+item.isPublic))} />
                                    <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                                  </label>
                                  <UncontrolledTooltip target={'Pubview' + index} placement='bottom'>
                                    If public view is set to YES your product will be visible on our public page
                                  </UncontrolledTooltip>
                                  {data.length > 1 && <>
                                    {/* <span href="#" onClick={() => moveUp(item.productSKU)}><i class="fas fa-angle-up" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span>
                                    <span href="#" onClick={() => moveDown(item.productSKU)}><i class="fas fa-angle-down" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span> */}
                                  </>}
                                </div>
                              </Col>

                            </Row>
                          </Col>
                        </Row>

                      ))}
                    </CardBody>

                  </Card>
                  {(data.length > 0 && total > 10) &&
                    <TablePagination
                      component="div"
                      count={total}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[10, 20, 30, 40]}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      <div>
      <Modal isOpen={reordermodal} toggle={()=>toggle("","","")}>
        <ModalHeader>
          <p><b>Update Order</b></p>
        </ModalHeader>
        <ModalBody>
          <p>Current Order - {currentOrder}</p>
          <Box
      sx={{
        width: 500,
        maxWidth: '100%',
      }}
    >
      <h3>Update New Order</h3>
      <TextField fullWidth label="New Sort Order" id="fullWidth" type="number" value = {newOrder} onChange={handleChangeOrder}/>
    </Box>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={finalReOrdering}>
            Submit Order
          </Button>
        </ModalFooter>
      </Modal>
    </div>
    </>
  );
}

//export default Myproductlist;
function mapState(state) {
  const general_data = state.channelListing;
  return general_data;
}

const actionCreators = {
  channelRedux: channelAction.channelRedux
};

export default connect(mapState, actionCreators)(Myproductlist);