import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Container, Col, Row, Badge, UncontrolledTooltip } from "reactstrap";
import { FormRadio, FormCheckBox } from "_components/FormElements/FormInput";
import { InputLabel, Select, TextField, FormControl } from '@material-ui/core';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { TradeLocationSchema } from './validation';
import { FormAutocompleteGrouping, SingleAutocomplete, FormAutocompleteGroupingRegion } from '_components/FormElements/FormInput';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import { Spinner } from 'views/Hoc/Spinner';
import { adduserActions } from "_actions/adduser.actions";
import { commonService } from "_services/common.service";

const Addtradelocation = (props) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(TradeLocationSchema),
  });


  const [state, setState] = useState({
    sellingCountries: [],
    sellingCountriesOption: [],
    targetCountries: [],
    targetCountriesOption: [],
  });

  //localStorage
  var userData = JSON.parse(localStorage.getItem('CHuser'));
  var typeId = userData.channelTypeId;

  useEffect(() => {

    let target = []; let selling = [];
    setLoading(true);

    (async () => {

      await axios.get('setting/getUserProfile/' + userData?.channelId).then(res => {

        state.sellingCountriesOption = res.data.regions1;
        selling = res.data.regions1;
        state.targetCountriesOption = res.data.regions;
        target = res.data.regions;

        setState({ ...state, targetCountriesOption: state.targetCountriesOption });
        setState({ ...state, sellingCountriesOption: state.sellingCountriesOption });

      }).catch(err => {
        setLoading(false);
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getUserProfile' }, '/error')
      })

      // await axios.get('setting/tradelocation').then(res =>{                
      //   target=res.data.targetLocation;selling=res.data.sellingLocation;
      //   selling.map(x => {
      //     x.status=false;
      //   })
      //   target.map(x => {
      //     x.status=false;
      //   })
      //   setState({...state, targetCountriesOption:res.data.targetLocation, sellingCountriesOption:res.data.sellingLocation,sellingCountries:res.data.sellingLocation,targetCountries:res.data.targetLocation});
      //   //sellingCountries:res.data.sellingLocation,targetCountries:res.data.targetLocation});        
      // })

      await axios.get('setting/tradelocation').then(res => {
        target = target.map(x => {
          res.data.targetLocation.map(y => {
            if (x.groupid == y.groupid && x.childId == y.childId)
              x.status = true;
          })
          return x;
        });

        selling = selling.map(x => {
          res.data.sellingLocation.map(y => {
            if (x.groupid == y.groupid && x.childId == y.childId)
              x.status = true;
          })
          return x;
        });
        state.targetCountriesOption = target;
        state.targetCountries = res.data.targetLocation.length === 0 ? [] : res.data.targetLocation;
        state.sellingCountries = res.data.sellingLocation.length === 0 ? [] : res.data.sellingLocation;
        state.sellingCountriesOption = selling
        setState({ ...state });
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getContactLocation' }, '/error');
      });

      if (props.contactId) {
        selling.map(x => {
          x.status = false;
        })
        target.map(x => {
          x.status = false;
        })

        await axios.get('setting/getContactLocation/' + props.contactId).then(res => {
          //console.log('contactlocation', res.data); 

          target = target.map(x => {
            res.data.targetArray.map(y => {
              if (x.groupid == y.groupid && x.childId == y.childId)
                x.status = true;
            })
            return x;
          });

          selling = selling.map(x => {
            res.data.sellingArray.map(y => {
              if (x.groupid == y.groupid && x.childId == y.childId)
                x.status = true;
            })
            return x;
          });

          setState({
            ...state, targetCountriesOption: target, targetCountries: res.data.targetArray.length == 0 ? [] : res.data.targetArray,
            sellingCountries: res.data.sellingArray.length == 0 ? [] : res.data.sellingArray, sellingCountriesOption: selling
          });
        })
      }
      setLoading(false);

    })();

  }, []);


  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    console.log("check:", check, gname, optionName, optionValue);
    if (check == true) {
      state[optionName].map((item) => {
        console.log("groupname:", item.groupname);
        if (item.groupname == gname || item.status == true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check == false) {
      console.log("check:", check);
      state[optionName].map((item) => {
        if (item.groupname == gname && state[optionValue].length > 0)
          item.status = check;
        // if(state.productValue.length==0)
        //     item.status=false;
      });

      opt = state[optionValue].filter((item) => item.groupname != gname);
      opt.map(option => option.status = true);
    }

    state[optionValue] = opt;
    setState({ ...state, [optionValue]: state[optionValue] });
    setState({ ...state, [optionName]: state[optionName] });

    if (optionValue = 'sellingCountries' && state.sellingCountries.length == 0)
      errors.selling = true;
    else if (optionValue = 'targetCountries' && state.targetCountries.length == 0)
      errors.target = true;



  }



  let statedata = useSelector(state => state.adduser);


  const save = async () => {
    //  setLoading(true);

    let sellingLocation = []; let targetLocation = [];

    if (typeId == 4 || typeId == 3)
      sellingLocation = state.sellingCountries.map(x => +x.regionCountryJCTId);
    if (typeId == 2)
      targetLocation = state.targetCountries.map(x => +x.regionCountryJCTId);


    let data = {
      sellingLocation: sellingLocation,
      targetLocation: targetLocation
    }

    props.adduser(data, 3);
    console.log('save', data);
    props.toggleNavs("5");
  }

  console.log('error', errors);

  const onSubmit = data => {
    if (data) {
      console.log("data:", data);
    }
  }

  const handleChangeSelect = (input, value) => {

    if (input == 'sellingCountries') {
      state.sellingCountriesOption.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!=item.childname)
          //     item.status=false;                 
          //   else if(v.childname==item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.sellingCountries = value
      setState({ ...state, sellingCountries: state.sellingCountriesOption });
      setState({ ...state, sellingCountries: state.sellingCountries });

      if (state.sellingCountries.length == 0)
        errors.selling = true;
    }
    else if (input == 'targetCountries') {
      state.targetCountriesOption.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!=item.childname)
          //     item.status=false;                 
          //   else if(v.childname==item.childname)            
          //     item.status=true;                       

          // });
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.targetCountries = value
      setState({ ...state, targetCountriesOption: state.targetCountriesOption });
      setState({ ...state, targetCountries: state.targetCountries });

      if (state.targetCountries.length == 0)
        errors.target = true;
    }

  }

  return (
    <div className="main-content">
      {loading ? <Spinner /> : (
        <Container className="mt-4 col-lg-12 col-md-9 col-sm-12  " fluid>
          <form noValidate onSubmit={handleSubmit(save)}>
            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="typeId" value={typeId} control={control} />
            <Card>
              <CardHeader>
                <h3 className="text-center">{typeId == 3 ? 'Countries of operation' : 'Trade Locations'}</h3>
                <p className="text-center">{typeId == 3 ? 'Add the list of countries you are in charge of' : 'Add countries that the user is covering.'}</p>

              </CardHeader>
              <CardBody>
                {/* <Row>
                        <Col lg="12">
                            <h4 className="mt-4">2 - Choose {state.title} Regions</h4>
                        <div className="form-group">               
                        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="target" name="target" ref={register} value={state.targetCountries.length?state.targetCountries[0].childname:''} /> 
                        <FormAutocompleteGrouping
                            input="targetCountries"
                            id="Countries"
                            label="Countries"
                            name="Countries"
                            Options={state.targetCountriesOption}
                            placeholder="Search"              
                            errMessage={'Please choose your Countries'}
                            error={!errors.target || state.targetCountries.length ?false:true}
                            optionName='targetCountriesOption'
                            optionValue='targetCountries'
                            defaultValue={state.targetCountries}
                            value={state.targetCountries}         
                            loading={true}
                            selectedItem={selectedItem}
                            handleChangeSelect={handleChangeSelect}
                            control={control}
                            register={register}
                             />
                        </div>
                        </Col>
                    </Row> */}

                {(typeId == '2') && <Row>
                  <Col lg="12">
                    <h4> Choose Target Countries <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                      <UncontrolledTooltip className="handshak" delay={0} delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                    <div className="form-group">
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="target" name="target" ref={register} value={state.targetCountries.length ? state.targetCountries[0].childname : ''} />
                      <FormAutocompleteGroupingRegion
                        input="targetCountries"
                        id="targetCountries"
                        label="Target Countries *"
                        name="targetCountries"
                        Options={state.targetCountriesOption}
                        placeholder="Search"
                        errMessage={'Please choose your Countries'}
                        error={!errors.target || state.targetCountries.length ? false : true}
                        optionName='targetCountriesOption'
                        optionValue='targetCountries'
                        defaultValue={state.targetCountries}
                        value={state.targetCountries}
                        loading={true}
                        selectedItem={selectedItem}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}
                        isGroup='0'
                      />
                    </div>
                  </Col>
                </Row>}
                {/* <Row>
                        <Col lg="12">
                            <h4 className="mt-4">2 - Choose Selling Regions</h4>
                        <div className="form-group">               
                        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="target" name="target" ref={register} value={state.targetCountries.length?state.targetCountries[0].childname:''} /> 
                        <FormAutocompleteGrouping
                            input="Countries"
                            id="Countries"
                            label="Countries"
                            name="Countries"
                            Options={state.targetCountriesOption}
                            placeholder="Search"              
                            errMessage={'Please choose your Countries'}
                            error={!errors.target || state.targetCountries.length ?false:true}
                            optionName='targetCountriesOption'
                            optionValue='targetCountries'
                            defaultValue={state.targetCountries}
                            value={state.targetCountries}         
                            loading={true}
                            selectedItem={selectedItem}
                            handleChangeSelect={handleChangeSelect}
                            control={control}
                            register={register}
                             />
                        </div>
                        </Col>
                    </Row>
                     */}
                {(typeId == '3' || typeId == '4' || typeId == '5') && <Row>
                  <Col lg="12">
                    <h4>Choose Selling Countries <span id='algorithm1'><i class="far fa-handshake didy"></i></span>
                      <UncontrolledTooltip className="handshak" delay={0} delay={0} placement="bottom" target="algorithm1">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                    <div className="form-group">
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="selling" name="selling" ref={register} value={state.sellingCountries.length ? state.sellingCountries[0].childname : ''} />
                      <FormAutocompleteGrouping
                        input="sellingCountries"
                        id="Selling Countries"
                        label="Selling Countries *"
                        name="Selling Countries"
                        Options={state.sellingCountriesOption}
                        placeholder="Search"
                        errMessage={'Please choose your Countries'}
                        error={!errors.selling || state.sellingCountries.length ? false : true}
                        optionName='sellingCountriesOption'
                        optionValue='sellingCountries'
                        defaultValue={state.sellingCountries}
                        value={state.sellingCountries}
                        loading={true}
                        selectedItem={selectedItem}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}
                        isGroup='0'
                      />
                    </div>
                  </Col>
                </Row>}

                <div className="text-center mt-4">
                  <button onClick={e => { e.preventDefault(); props.toggleNavs("3") }} className="btn btn-primary" >Back</button>
                  <button type="submit" className="btn btn-primary" >Next</button>
                </div>

              </CardBody>
            </Card>

          </form>
        </Container>
      )}
    </div>
  );
};

function mapState(state) {
  const { trade_loc } = state.adduser;
  return { trade_loc };
}

const actionCreators = {
  adduser: adduserActions.adduser
};



export default connect(mapState, actionCreators)(Addtradelocation);

