import React, { useState } from 'react';
import { PhoneInput2, OtpInput } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { signupschema3 } from './Validation';
import { Col, Row } from "reactstrap";
import parsePhoneNumber from 'libphonenumber-js';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Button} from 'reactstrap';
//import { Grid } from '@material-ui/core';
import axios from 'axios';

export default function Step4(props) {

    const { register, handleSubmit, control, errors } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(signupschema3)
    });

    console.log('moberr', errors);

    const onSubmit = data => {
        //handlePhone()    
    }

    let countryCode = '';
    const { handleChangeSelect, signUp } = props;
    const { country, countryId, email, companyName, firstName, lastName } = props.values;
    props.values.mobile = props?.values?.mobile[0] ? props.values.mobile : props.values.switchPhone
    const mobile = props?.values?.mobile[0] ? props.values.mobile : props.values.switchPhone
    const { countryList } = props.staticValues;
    const [invalidNumber , setInvalidNumber] = useState(false)
    const [isPhone, setIsPhone] = useState(0);
    const [otp, setOtp] = useState(0);
    const [phone, setPhone] = useState(false);
    const [emailid, setEmail] = useState(false);
    let [verifyMethod, setVerifyMethod] = useState(1);

    const reset = () => {
        setIsPhone(0);
        handleChangeSelect('verified', 0);
    }

    if (Array.isArray(country) && country.length > 0) {
        countryCode = countryList.find(x => x.id === country[0].id).countryCode;
    } else {
        countryCode = countryId ? countryId : '';
    }
    console.log('ccc', countryCode)

    const valid = () => {
        if (mobile[2].length < 4) return 'Mobile No Must Have Min 4 Numbers';
        return true;
    }

    const handlePhone = async () => {
        if (verifyMethod == 1) {
            let validate = await validatePhoneNumber(mobile[1])
            if(!validate)
            {
                setInvalidNumber(true)
                setTimeout(()=> setInvalidNumber(false),2000)
                return 
            }
            axios.post('/notification/message', {
                phoneno: mobile[0]
            }).then(res => {
                setOtp(res.data);
                setIsPhone(1);
            });
        } else {
            axios.post('/mailerservice/Email_Otp_Verification', {
                email: email,
                companyName: firstName, //companyName?.length ? companyName[0].name : '',
            }).then(res => {
                setOtp(res.data);
                setIsPhone(1);
            });
        }

        setTimeout(() => {
            setOtp('$$$$$$');
        }, verifyMethod == 1 ? 100000 : 300000)
    }

    const validatePhoneNumber = function(phoneNumber) {
        try {
            const parsedNumber = parsePhoneNumber(phoneNumber, null, {
              v2: true, 
            });
        
            // Check if it's a valid number
            if (parsedNumber.isValid()) {
              return true;
            } else {
              return false;
            }
          } catch (error) {
            return false;
          }
      }

    const reSendOTP = () => {
        setOtp('$$$$$$');
        handlePhone();
        handleChangeSelect('verified', 0);
    }

    const matchOTP = (param) => {

        console.log("param:", param);
        console.log("settops:", otp);
        if (param == otp) {
            signUp(true);
            console.log("enable finish");
        }
        // else 
        // {
        //     setPhone(0);        
        // }
    }

    const handleChangeVerificationMethod = (type) => {
        verifyMethod = type;
        setVerifyMethod(type);
        // handleChangeSelect('verificationMethod', type);
        reset();
        reSendOTP();

    }

    const handleEmailToggle = () => {
            setVerifyMethod(0)
            if(phone)
            setPhone(false)

            setEmail(!emailid)
        
    }

    const handlePhoneToggle = () => {
        setVerifyMethod(1)
        if(emailid)
        setEmail(false)

        setPhone(!phone)
    }

    const labelButtonStyle = {
        backgroundColor: '#2c9db7',
        color: 'white',
        padding: '8px 16px', 
        borderRadius: '4px', 
        cursor: 'pointer', 
      };

    return (

        <div className="main-content">
            <div className="container Fullotp" style={{padding:'0'}}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">

                        {isPhone == 0 ? (
                            <div className="otp_get justify-content-md-center w-100" >
                                <Col lg="12" className="wek-cent">
                                    <div className="form-group otpphone mt-5">
                                    <h5 className='mob-signhead' style={{display:'none'}}>verification Code</h5>

                                        <p style={{ color: "#777879", textAlign: "center", fontSize: '1.2rem', fontWeight: 600, marginTop: '-1rem' }}>Select mode to receive your verification code</p>
                                        {/* <PhoneInput1 
                id="mobile"
                
                label="Enter your mobile number for verification"
                name="mobile"
                mobile={mobile}
                error={errors.mobile}
                register={register}
                Class="mobile_verify"
                country_code={countryId}
                handleChangeSelect={handleChangeSelect}
            />             */}
                                        <FormControlLabel control={
                                                <Checkbox name={"Email"} label={"Email"} checked={emailid} onChange={handleEmailToggle} /> 
                                            } 
                                            label={
                                                <Button style={labelButtonStyle} variant="contained">
                                                Email
                                              </Button>
                                              }   
                                                style={{ color: 'black' }}

                                            />
                                        <FormControlLabel control={
                                                <Checkbox name={"Phone"} label={"Phone"} checked={phone}  onChange={handlePhoneToggle}/> 
                                            } 
                                            label={
                                                <Button style={labelButtonStyle} variant="contained">
                                                Phone
                                              </Button>
                                              }   
                                                style={{ color: 'black' }}

                                            />
                                            <div>
                                           {phone? <PhoneInput2
                                            id="mobile"
                                            label="Enter your mobile number to receive your verification code *"
                                            name="mobile"
                                            placeholder={''}
                                            mobile={mobile[0]}
                                            error={errors.mobile}
                                            register={register}
                                            control={control}
                                            country_code={countryCode}
                                            handleChangeSelect={handleChangeSelect}
                                            regex={/^(\+[1-9]{1,4}[ \\-]*|\([0-9]{2,3}\)\s*[ \\-]*|[0-9]{2,4})[ \\-]*[0-9]{3,4}[ \\-]*[0-9]{3,4}(?!(\d)\1{8})/}
                                            defaultErrorMessage={errors.mobile || mobile[2].length < 4 ? 'Mobile No Must Have Min 4 Numbers' : ''}
                                        // isValid={valid}
                                        /> : <></>}
                                            </div>
                                   

                                     <div style={{color:'#f44336',fontSize:'0.75rem',minHeight: "30px"}}><span className={`${!invalidNumber?'hidden':''}`}>Please Enter Valid Number</span></div>
                                    </div>
                                       <button
                                        type="submit"
                                        className={`btn btn-primary ${
                                            !phone && !emailid ? "hidden" : ""
                                        }`}
                                           onClick={handlePhone}
                                           >
                                        Get your code now
                                       </button>
                                </Col>
                            </div>

                        ) : (
                            <div className="otp-verify" >
                                <div className="verify-box">
                                    <h3>Enter Code</h3>
                                    {verifyMethod == 1 ?
                                        <>
                                            <span>We have sent you SMS with 6 digit verification code on</span>
                                            <p>{mobile[1]}</p>
                                        </> :
                                        <>
                                            <span>We have sent you Email with 6 digit verification code on</span>
                                            <p>{email}</p>
                                        </>
                                    }
                                    <OtpInput
                                        matchOTP={matchOTP}
                                        reSendOTP={reSendOTP}
                                        handleChangeSelect={handleChangeSelect}
                                        maxTime={verifyMethod == 1 ? 100 : 300}
                                    />
                                    {verifyMethod == 1 ?
                                        <>
                                            <p>If you don't receive the verification code by sms, <span style={{ color: "blue", cursor: "pointer" }} onClick={() => handleChangeVerificationMethod(2)}>click here to receive it by email.</span>
                                            </p>
                                        </> :
                                        <>
                                            <p>If you don't receive the verification code by email, <span style={{ color: "blue", cursor: "pointer" }} onClick={() => handleChangeVerificationMethod(1)}>click here to receive it by sms.</span>
                                            </p>
                                        </>
                                    }
                                </div>
                            </div>
                        )
                        }






                        {/* <FormInput
                id="mobile" 
                type="Phone number" 
                label="Enter your mobile number for verification" 
                name="mobile" 
                register={register} 
                error={errors.mobile}
                value={mobile}
                onChange={handleChange('mobile')}
             /> */}




                    </div>
                    {/* <button  className="btn btn-primary ch-btn-blue right-but" variant="contained" color="primary" >
            Next
            </button>  */}

                </form>
            </div>
            {/* {isPhone ? <button
                onClick={reset}
                className="btn btn-primary back-but1"
            >Back</button> : <></>} */}
        </div>

    )
}
