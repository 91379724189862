import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { history } from '../../../_helpers/history';
import { Button, Modal } from "reactstrap";
import { LaptopWindows } from '@material-ui/icons';
export const ErrDialog = (props) => {

    const { open, handleClose, value } = props;
    console.log("errd:", props);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Modal className="modal-dialog-centered" isOpen={open}>
                <div className="modal-header">
                    <strong>{value.title}</strong>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {value.message}
                    {/* {value?.message && <p className="mt-2">{value?.second_message}</p>} */}
                </div>
                <div className="modal-footer">
                    {/* <Button color="primary" type="button">Ok</Button> */}
                    <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                </div>
            </Modal>
        </div>
    );
}