import React, { useState, useEffect, useRef } from 'react';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Card, CardBody, Row, Col, Container, UncontrolledTooltip, CardHeader } from "reactstrap";
import { commonService } from '_services/common.service';
import { Badge } from "reactstrap";
import { Connect } from 'views/Hoc/Dialog/Connect';
import Slider from "react-slick";
import { Spinner } from "views/Hoc/Spinner";
import { ArrayToString } from "views/Hoc/text-tags/TextTags";
import { KeyProducts } from "views/Hoc/keyBrands/KeyBrands";
import { OfficialDocument } from "views/Hoc/officialDocuments/officialDocuments";
import { KeyBrands, ChannelProducts } from "views/Hoc/keyBrands/KeyBrands";
import { Awards, AwardsReviews, KeyRetailReviews } from 'views/Hoc/Reviews';
import { ChannelConnect } from "views/Hoc/Dialog/ChannelConnect";
import NotificationAlert from "react-notification-alert";
import { history } from '../../../_helpers/history';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import { SocialShareDialog } from "views/Hoc/Dialog/SocialShareDialog";
import axios from 'axios';
import { NoData } from 'views/Hoc/no-data/no-data';
import { dashboardConstants } from '_constants';
import { dashboardService } from '_services';
import { Categories } from "views/Hoc/text-tags/TextTags";
import { useDispatch, connect, useSelector } from 'react-redux'
import { channelAction } from '_actions/channel.action';
//import {  SideBySideMagnifier} from "react-image-magnifiers";
//import ReactImageMagnify from 'react-image-magnify';
import ReactImageZoom from 'react-image-zoom';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import ReactImageMagnify from 'react-image-magnify';
import Moment from 'moment';
import { ProductVideoPlayer } from 'views/Hoc/Dialog/ProductVideoPlayer';
import { ProductDetailImg } from 'views/Hoc/Dialog/ProductDetailImg';
import { ToastsContainer, ToastsStore } from 'react-toasts';
var _ = require('lodash');



const ProductDetail = (props) => {

  const player = useRef(null);
  const dispatch = useDispatch();
  let [loading, setLoading] = useState(true);
  const [image, setImage] = useState();
  const [isVid, setIsVideo] = useState();
  let [productDetails, setChannelDetails] = useState(null);
  let [productDetailsInfo, setChannelDetailsInfo] = useState(null);
  let [contactList, setContactList] = useState([]);
  let [connect, setconnect] = useState(false);
  let [connectPopUp, setConnectPopUp] = useState(false);
  const [tabs, toggleNav] = useState("1");
  const [isAddCart, setAddCart] = useState(false);
  let [isModalPopup, setModalPopup] = useState(false);
  let [isDeleteModalPopup, setDeteleModalPopup] = useState(false);

  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  let [UpdateData, setUpdateData] = useState(null);
  let [imageGallery, setImageGallery] = useState([]);
  let [selectedImg, setSelectedImg] = useState({ cat: 'Product', index: 1 });
  const [isFavorite, setIsFavorite] = useState(false);
  let [productList, setProductList] = useState([]);
  let [isVideo, setVideoOpen] = useState(false);
  let [videoUrl, setVideoUrl] = useState(null);
  let [similarProductList, setSimilarProductList] = useState([]);
  let [productImg, setproductImg] = useState(false);
  let [ImageIndex, setindex] = useState(1);
  let [isSocialShare, setSocialShareOpen] = useState(false);
  let [shareData, setShareData] = useState([]);
  let [id, setId] = useState(props.match.params.id ? props.match.params.id : props.match.params.productId);
  let user = JSON.parse(localStorage.getItem('CHuser'));
  const notificationAlert = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    vertical: true,
    verticalSwiping: true,

  };

  let [switchValue, setSwitchValue] = useState(0);
  const redux_data = useSelector(state => state.channelListing);
  let Product_List = redux_data?.product;


  // useEffect(() => {
  //   getProductDetails();
  // }, []);

  useEffect(() => {

    console.log("pList:", props.match.params.id);
    id = props.match.params.id ? props.match.params.id : props.match.params.productId;
    setId(id);
    if (!props.match.params.topsearch) {
      switchValue = Product_List?.indexOf(id);
      setSwitchValue(switchValue);
    }
    setLoading(true);
    getProductDetails(id);
    setTimeout(() => {
      imageZoom("myimage", "myresult");
    }, 7000);

  }, [props.location.pathname])

  //redirection decision for breadcrumbs
  let to = props.location?.pathname?.split('/')[2] ? props.location?.pathname?.split('/')[2] : 'channel';

  //for product description

  const productDescRef = useRef(null);
  const uspRef = useRef(null);

  useEffect(() => {
    if (productDescRef.current) {

      // let desc = productDetails?.productDetail?.productdesc ? productDetails?.productDetail?.productdesc.replace(/<[^>]+>/g, ' ') : '';
      // productDescRef.current.innerHTML = '<p>'+desc?.replace(/\.\s/g, '.</p><p>')+'</p>';

      let desc = productDetails?.productDetail?.productdesc ? productDetails?.productDetail?.productdesc : '';
      // productDescRef.current.innerHTML=desc;
      desc = desc?.split('\n');

      // desc = desc?.splice(0, desc?.length-1);
      let temp = '';
      desc.map(x => {
        if (x?.trim()?.length > 1)
          temp += '<p>' + x + '</p>'
      });
      // console.log('prod desc', desc, temp);
      productDescRef.current.innerHTML = temp;//'<ul>' + temp + '</ul>';
    }

    if (uspRef.current) {
      let temp = productDetails?.productDetail?.usp.replace(/<[^>]+>/g, '');

      uspRef.current.innerHTML = temp;
    }


  }, [productDescRef.current, uspRef.current])


  //end product description

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    OpenSocialShareDialog();
    setOpen(true);
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {

      maxWidth: "100%",
      borderRadius: "0px",
      backgroundColor: "white",
      color: "black",
      padding: "0px 0px",
      boxShadow: "0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 17%)",
      border: "0 solid rgba(0,0,0,.2)",
      borderRadius: ".4375rem",
      outline: "0",
    },
  }))(Tooltip);

  const add_or_Remove_Favorite = () => {
    if (isFavorite) {
      axios.delete('favorite', {
        params: {
          id: +productDetails.productId,
        }
      }).then(res => {
        notify('warning', '  Favourite Removed Successfully');
        dashboardService.dashboard_count().then(dasboard_count => {
          //console.log("dashboard_count:",dasboard_count);
          dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
        });
        setIsFavorite(false);
      });
    } else {
      axios.post('favorite/add', {
        type: "WISHLIST",
        id: +productDetails.productId,
      }).then(res => {
        notify('success', '  Favourite Added Successfully');
        // axios.post('mailerservice/Buyer_Favorites_Product', { productId: +productDetails.productId });
        dashboardService.dashboard_count().then(dasboard_count => {
          //console.log("dashboard_count:",dasboard_count);
          dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
        });
        setIsFavorite(true);
      });
    }
  }

  //let skuId = props.match.params.id ? props.match.params.id : props.match.params.productId;

  let [productType, setProductType] = useState('3');

  const getProductDetails = async (id) => {
    let res = await commonService.getServices('product/' + id).catch(err => console.log('err', err));
    if (res) {
      productDetails = res.data;
      if (productDetails.businessType)
        setProductType(productDetails.businessType);
      setChannelDetails(productDetails);
      setGalleryImage();
      setImage(productDetails?.defaultImage?.image?.documentUrl);
      getChannelContact();
      getProductDetailInfo();
      getChannelProduct(productDetails.channelId);
      if (user.channelTypeId != 2)
        setSimilarProduct(productDetails.channelId);
      setLoading(false);
    }
  }
  const getChannelProduct = async (channelId) => {
    let res = await commonService.postService({ excludedProductSku: id }, 'product/channel-products/' + channelId).catch(err => console.log('err', err));
    if (res) {
      productList = res.data;
      setProductList(productList);
    } else {
      productList = [];
      setProductList(productList);
    }
  }
  const setGalleryImage = () => {
    imageGallery = [];
    if (productDetails?.images?.PRODUCT) {
      imageGallery.push(...productDetails?.images?.PRODUCT);
      imageGallery = _.sortBy(imageGallery, "sortorder");
    }
    if (productDetails?.images?.DISPLAY?.length > 0)
      imageGallery.push(...productDetails?.images?.DISPLAY);
    if (productDetails?.images?.PACKING?.length > 0)
      imageGallery.push(...productDetails?.images?.PACKING);
    if (productDetails?.productDetail?.videourl && productDetails?.productDetail?.videourl !== '')
      imageGallery.push({ imageid: 0, image: { documentUrl: productDetails?.productDetail?.videourl.replace("watch?v=", "embed/"), documentTypeId: 3 } });
    setImageGallery(imageGallery);
  }
  const getChannelContact = async () => {
    contactList = [];
    setContactList(contactList);
    let res = await commonService.postService('', 'channel/channelContacts/' + productDetails?.channelId).catch(err => console.log('err', err));
    if (res) {
      contactList = res.data;
      setContactList(contactList);
    }
  }
  const getProductDetailInfo = async () => {
    let res = await commonService.getServices('product/productActionInfo/' + id).catch(err => console.log('err', err));
    if (res) {
      productDetailsInfo = res.data;
      setChannelDetailsInfo(productDetailsInfo);
      setIsFavorite(productDetailsInfo.wishlist);
      // setLoading(false)
    }
  }
  const setSimilarProduct = async (channelId) => {
    let res = await commonService.getServices('product/channel-similarproducts/' + channelId).catch(err => console.log('err', err));
    if (res) {
      similarProductList = res.data;
      setSimilarProductList(similarProductList);
    } else {
      similarProductList = [];
      setSimilarProductList(similarProductList);
    }
  }
  const clickKeyRetailer = () => {
    toggleNav('3');
  }
  const productConnect = () => {

    if (contactList.records.length > 0) {
      let user = [];
      if (contactList.defaultContact && contactList.records.length > 0) {
        user = contactList.records.filter(x => parseInt(x.record.contactId) === parseInt(contactList.defaultContact));
        console.log("user_1", user);
        if (user.length === []) {
          user = contactList.records[0];
        } else {
          user = user[0];
        }
      } else if (!contactList.defaultContact && contactList.records.length > 0) {
        user = contactList.records[0];
      }
      if ((parseInt(user.relationId) == null || parseInt(user.relationId) === 0 || parseInt(user.relationId) === 1)) {
        setAddCart(true);
        // openConnectPopUp(user, 7, 'Connect');
        setconnect(true);
      } else {
        addToCart();
      }
    } else {
      addToCart();
    }
  }

  const addToCart = async () => {
    setAddCart(false);
    setLoading(true);
    const productid = parseInt(productDetails.productId, 10);
    const addCartData = {
      'id': productid,
      'quantity': 1
    };
    let res = await commonService.postService(addCartData, 'cart/item').catch(err => console.log('err', notify('warning', ' This product reached the limit')));
    setLoading(false);
    if (res) {
      //console.log("addcart:",res);
      dashboardService.dashboard_count().then(dasboard_count => {
        //console.log("dashboard_count:",dasboard_count);
        dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
      });

      let url = props.match?.url ? props.match?.url : '/admin';

      history.push({ pathname: url + "/Addcart", state: { addToCart: true } });
    }
  }

  const closeConnect = () => {
    connect = !connect;
    setconnect(connect);
  }
  const SetImages = (e, val, cat, i) => {
    e.preventDefault();
    setImage(val);
    selectedImg.cat = cat === 'Image' ? 'Product' : cat === 'Packing' ? 'Packaging' : 'Display';
    selectedImg.index = i;
    setSelectedImg(selectedImg);
  }
  const connectPopUpClose = () => {
    setConnectPopUp(false);
  }

  const openConnectPopUp = (contact, status, title) => {
    UpdateChannelData.connectionStatusTypeId = status;
    UpdateChannelData.channelJCTId = parseInt(contact.channelJCTId);
    UpdateChannelData.contactid = contact.record.contactId;
    UpdateData = UpdateChannelData;
    setUpdateData(UpdateData);
    setConnectPopUp(true);
  }
  const updateChannel = async (value) => {
    console.log('value', value);
    setLoading(true);
    setConnectPopUp(false);
    setconnect(false);
    let res = await commonService.postService(value, 'channel/updateChannel/' + productDetails.channelId).catch(err => console.log('err', notify('danger', 'Something went wrong')));
    setLoading(false);
    if (res) {
      notify('success', 'Successfully connected');
      if (isAddCart)
        addToCart();
      getChannelContact();
      console.log(res);
    }
  }
  const notify = (type, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {/* {" "}
            {type} */}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      // icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const OpenSocialShareDialog = () => {
    let productData = { 'title': productDetails?.product, 'url': window.location.href }
    setShareData(productData);
  }
  const OpenProductDismissDialog = () => {
    modalPopValue.message = 'Do you want Dismiss this product?';
    modalPopValue.title = 'Dismiss';
    modalPopValue.value = '';
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }
  const confirmProductDismissDialog = async () => {
    setModalPopup(false);
    let dismissProduct = {
      'productId': parseInt(productDetails.productId),
      'rejectreasonId': 1,
      'comment': ''
    };
    setLoading(true);
    let res = await commonService.postService(dismissProduct, 'Product/reject').catch(err => console.log('err', notify('danger', 'Soming went wrong')));
    setLoading(false);
    if (res) {
      notify('success', 'Product dismissed from your list');
      history.push('/admin/channel')
    }
  }
  const previewVideo = (urllink) => {
    const samp = urllink.match(/^https?:\/\/([\w-]+\.\w{2})\/([\w-]{11})?/);
    console.log('samp', samp);
    if (samp) {
      if (samp[1] === 'youtu.be') {
        let embedLink = 'https://www.youtube.com/embed/';
        urllink = embedLink + samp[2];
      }
      console.log(samp);
    }

    videoUrl = urllink;
    setVideoUrl(videoUrl);
    setVideoOpen(true);
  }

  const getLink = (urllink) => {
    const samp = urllink.match(/^https?:\/\/([\w-]+\.\w{2})\/([\w-]{11})?/);
    console.log('samp', samp);
    // if (samp) {
    //   if (samp[1] === 'youtu.be') {
    //     let embedLink = 'https://www.youtube.com/embed/';
    //     urllink = embedLink + samp[2];
    //   }
    //   console.log(samp);
    // }

    console.log("urllink:", samp[2])
    return samp[2];

  }


  const videoModalClose = () => {
    setVideoOpen(false);
  };


  const socialShareModalClose = () => {
    setSocialShareOpen(false);
  };
  const imageZoom = (imgID, resultID) => {
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    result = document.getElementById(resultID);
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    if (!img) {
      setTimeout(() => {
        imageZoom("myimage", "myresult");
      }, 5000);

    } else {


      img.parentElement.insertBefore(lens, img);
      cx = result.offsetWidth / lens.offsetWidth;
      cy = result.offsetHeight / lens.offsetHeight;
      result.style.backgroundImage = "url('" + img.src + "')";
      result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
      const moveLens = (e) => {
        var pos, x, y;
        e.preventDefault();
        pos = getCursorPos(e);
        x = pos.x - (lens.offsetWidth / 2);
        y = pos.y - (lens.offsetHeight / 2);
        if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
        if (x < 0) { x = 0; }
        if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
        if (y < 0) { y = 0; }
        lens.style.left = x + "px";
        lens.style.top = y + "px";
        result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
      }
      lens.addEventListener("mousemove", moveLens);
      img.addEventListener("mousemove", moveLens);
      lens.addEventListener("touchmove", moveLens);
      img.addEventListener("touchmove", moveLens);

      const getCursorPos = (e) => {
        var a, x = 0, y = 0;
        e = e || window.event;
        a = img.getBoundingClientRect();
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return { x: x, y: y };
      }
    }
  }
  useEffect(() => {

  }, [])
  const productimg = { zoomWidth: 500, img: image, };
  const imageProps = {
    smallImage: {
      alt: 'Phasellus laoreet',
      isFluidWidth: true,
      src: image,
      width: '100%',
      height: '100%'
    },
    largeImage: {
      src: image,
      width: 1200,
      height: 1800
    },
    enlargedImageContainerDimensions: {
      width: '100%',
      height: '100%'
    },
    enlargedImageContainerStyle: { background: '#fff', zIndex: 9 },
  };
  const closeImg = () => {
    console.log('productImg', productImg, 'img', image)
    productImg = !productImg;
    setproductImg(productImg)
  }
  const OpenImg = () => {
    // if(isVid)
    // {
    //   previewVideo(isVid);
    // }else
    setproductImg(true)
  }
  const preImages = (e) => {
    setIsVideo('');
    if (imageGallery.length > ImageIndex && ImageIndex > 0) {
      let val = ImageIndex - 1;
      setindex(val);
      if (imageGallery[ImageIndex].image?.documentTypeId != 3)
        //   const samp = imageGallery[ImageIndex].image?.documentUrl.match(/^https?:\/\/([\w-]+\.\w{2})\/([\w-]{11})?/);
        //   console.log("linkurls:",samp[2]);
        //   setImage("http://i1.ytimg.com/vi/"+samp[2]+"/default.jpg");
        //   setIsVideo(imageGallery[ImageIndex].image?.documentUrl);
        // }else
        setImage(imageGallery[ImageIndex].image?.documentUrl);
    } else {
      setindex(0);
      if (imageGallery[0].image?.documentTypeId != 3)
        //   const samp = imageGallery[0].image?.documentUrl.match(/^https?:\/\/([\w-]+\.\w{2})\/([\w-]{11})?/);
        //   console.log("linkurls:",samp[2]);
        //   setImage("http://i1.ytimg.com/vi/"+samp[2]+"/default.jpg");
        //   setIsVideo(imageGallery[0].image?.documentUrl);
        // }else
        setImage(imageGallery[0].image?.documentUrl);
    }
  }
  const nextImages = (e) => {
    setIsVideo('');
    if (imageGallery.length - 1 >= ImageIndex) {
      let val = ImageIndex + 1;

      setindex(val);
      if (imageGallery[ImageIndex].image?.documentTypeId != 3)
        //   const samp = imageGallery[ImageIndex].image?.documentUrl.match(/^https?:\/\/([\w-]+\.\w{2})\/([\w-]{11})?/);
        //   console.log("linkurls:",samp[2]);
        //   setImage("http://i1.ytimg.com/vi/"+samp[2]+"/default.jpg");
        //   setIsVideo(imageGallery[ImageIndex].image?.documentUrl);
        // }else
        setImage(imageGallery[ImageIndex].image?.documentUrl);

    } else {
      setindex(0);
      if (imageGallery[0].image?.documentTypeId != 3)
        //   const samp = imageGallery[0].image?.documentUrl.match(/^https?:\/\/([\w-]+\.\w{2})\/([\w-]{11})?/);
        //   console.log("linkurls:",samp[2]);
        //   setImage("http://i1.ytimg.com/vi/"+samp[2]+"/default.jpg");
        //   setIsVideo(imageGallery[0].image?.documentUrl);
        // }else
        setImage(imageGallery[0].image?.documentUrl);
    }
  }
  const productEdit = (sku, id) => {
    // history.push('/admin/productmanage?sku='+sku+'&action='+id);
    let url = props.location?.pathname ? props.location?.pathname : '/admin/Myproductlist/';

    if (id == 1)
      history.push(url + '/EditProduct?sku=' + sku + '&action=' + id);
    if (id == 2)
      history.push(url + '/CloneProduct?sku=' + sku + '&action=' + id);
  }
  const productDelete = async (sku) => {
    setLoading(true);
    let res = await commonService.deleteService('product/' + sku).catch(err => ToastsStore.error('Something went wrong!...'));
    setLoading(false);
    if (res) {
      ToastsStore.success('Product deleted successfully!...');
      history.push('/admin/Myproductlist');
      // getMyProductList();
    }
  }
  const confirmPopup = (res) => {
    closePopup();
    productDelete(res.value);
  }
  const closePopup = () => {
    setModalPopup(false);
    setDeteleModalPopup(false);
  };
  const deletePopup = (sku) => {
    modalPopValue.message = 'Do you want to delete the product?';
    modalPopValue.title = 'Product';
    modalPopValue.value = sku;
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setDeteleModalPopup(true);
  }

  const nextData = async () => {
    let prev = switchValue;
    switchValue = switchValue != Product_List?.length - 1 ? switchValue + 1 : switchValue;
    if (switchValue != Product_List?.length - 1 || prev == Product_List?.length - 2) {
      setLoading(true);
      getProductDetails(Product_List[switchValue]);
      window.history.replaceState({}, null, '/admin/' + to + '/productDetail/' + Product_List[switchValue]);

    }
    setSwitchValue(switchValue);
  }

  const prevData = async () => {
    let prev = switchValue;
    switchValue = switchValue != 0 ? switchValue - 1 : switchValue;
    if (switchValue != 0 || prev == 1) {
      setLoading(true);
      getProductDetails(Product_List[switchValue]);
      window.history.replaceState({}, null, '/admin/' + to + '/productDetail/' + Product_List[switchValue]);
    }
    setSwitchValue(switchValue);
  }

  return (
    <div id='product-detail' className="main-content">
      <NotificationAlert ref={notificationAlert} />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Dialog open={isDeleteModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      <ProductDetailImg handleClose={closeImg} isOpen={productImg} ProductImgs={image} />
      <ChannelConnect isOpen={connectPopUp} handleClose={connectPopUpClose} value={UpdateData} updateChannel={updateChannel} outboundRemaind={null} />
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmProductDismissDialog} handleClose={closePopup} outboundRemaind={null} />
      <SimpleHeader location={props.match} />
      <ProductVideoPlayer videoModalClose={videoModalClose} isOpen={isVideo} url={videoUrl} />
      <Connect handleClose={closeConnect} isOpen={connect} openConnectPopUp={openConnectPopUp} contactList={contactList} />
      {loading ? <Spinner /> :
        <Container className="mt-6" fluid>

          <Row className='product'>

            {(!props.match.params.topsearch && Product_List?.length > 1) && <>
              <div className='left-arrow'>
                <i class="fas fa-angle-left" onClick={prevData}></i>
              </div>
              <div className='right-arrow'>
                <i class="fas fa-angle-right" onClick={nextData}></i>
              </div>
            </>}
            <Col className="card-wrapper" xl="1" lg="4" md="4" sm="4" xs='12'>
              <Card style={{ height: "97%" }}>
                <CardBody className="p-1">
                  <div class="d-flex flex-column bd-highlight  mb--3  pb-5 pt-5" style={{ overflow: "hidden" }}>
                    <Slider {...settings}>
                      {imageGallery?.length > 0 && imageGallery?.map((item, i) => {
                        return (
                          <div className="product-imgs mb-3 active-img">
                            {item?.image?.documentTypeId === 3 ?
                              <div onClick={() => previewVideo(item?.image?.documentUrl)}>
                                <i style={{ fontSize: '2rem', position: 'absolute', top: '20%', left: '38%', color: '#FF6347' }} class="fa fa-play-circle" aria-hidden="true"></i>
                                {/* <video src={item?.image?.documentUrl} style={{ width: '100%', height: 'auto' }}></video>     */}
                                <img src={"http://i1.ytimg.com/vi/" + getLink(item?.image?.documentUrl) + "/default.jpg"} />
                              </div>
                              :
                              <img onError={(event) => { event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt="...." onClick={e => SetImages(e, item?.image?.documentUrl, item?.productimagetype, i)} src={item?.image?.documentUrl} />}
                          </div>
                        )
                      })}

                    </Slider>
                  </div>
                </CardBody>
              </Card>

            </Col>

            <Col xl="6" lg="8" md="8" sm="12" xs='12'>
              <Card className="mb-2" style={{ height: "97%" }}>
                <CardBody className="p-1 prod-img productdetImg" style={{ textAlign: "-webkit-center" }}>
                  {/* <ReactImageMagnify {...imageProps} /> */}
                  <i class="fa fa-arrow-left leftarr pointer" onClick={e => preImages(e)} aria-hidden="true"></i>
                  {/* <ReactImageMagnify {...imageProps} /> */}

                  <img onError={(event) => { event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} onClick={() => { OpenImg() }} src={image} />
                  <i class="fa fa-arrow-right rightar pointer" aria-hidden="true" onClick={e => nextImages(e)}></i>

                  {/* <div className="center-img"> */}
                  {/* <ReactImageZoom {...productimg} /> */}
                  {/* <div className="img-zoom-container">
    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")}  id="myimage" src={image}/>
    
    <div id="myresult" class="img-zoom-result"></div>
    </div> */}
                  {/* <Magnifier src={image} />
    <p className="img-title mb-0">{selectedImg.index}/{imageGallery?.length}  { selectedImg.cat }</p> */}
                  {/* </div>
     */}
                </CardBody>
              </Card>

              {/* <Card >
          <CardBody className="p-1 d-flex justify-content-around">
           
    <ButtonGroup className="btn-group-toggle w-100" data-toggle="buttons">
           <Button className={classnames({ active: active === 0 })} color="secondary" onClick={() => setImageToDisplay(0)}>
             <input
               autoComplete="off"
               name="options"
               type="radio"
               value={active === 0}
             />
             Product
           </Button>
           {productDetails?.images?.DISPLAY?.length>0 && <Button className={classnames({ active:active === 1 })} color="secondary" onClick={() =>  setImageToDisplay(1)}>
             <input
               autoComplete="off"
               name="options"
               type="radio"
               value={active === 1}
             />
            Retail Display
           </Button>}
           {productDetails?.images?.PACKING?.length>0 && <Button className={classnames({ active: active === 2})} color="secondary" onClick={() =>  setImageToDisplay(2)}>
             <input
               autoComplete="off"
               name="options"
               type="radio"
               value={active === 2}
             />
             Packaging
           </Button>}
         </ButtonGroup>
         </CardBody>
            </Card> */}

            </Col>

            <Col className="pl-0" xl="5" lg="12" md="12" sm="12" >
              <Card style={{ height: "97%" }}>
                <CardBody className="p-5">
                  <div>
                    <h1 className="mb-2 text-center font-weight-500 ">{productDetails?.product}</h1>
                    <h3 style={{ cursor: 'pointer' }} onClick={() => user.channelId == productDetails.channelId ? history.push('/admin/PublicProfile/' + user.channelId) : history.push('/admin/' + to + '/productDetail/' + productDetails.productSKU + '/channelDetail/' + productDetails.channelId)}>Vendor : <span className="gestcomptitle">{productDetails?.companyName}</span></h3>
                    {productDetails?.productDetail?.productshortdec && <h3>Short Description : <span className="gestcomptitle">{productDetails?.productDetail?.productshortdec.replace(/<[^>]+>/g, '')}</span> </h3>}
                    {productDetails?.productDetail?.usp && <h3>Tweet Pitch :  <span ref={uspRef} className="gestcomptitle"></span> </h3>}
                    <h3 className="mt-4">
                      Suggested Retail Price :<span className="gestcomptitle prodheadfontsize"> {productDetails?.productPriceDetail?.businesscurrencyid === '1' ? ' €' : ' $'} {productDetails?.productPriceDetail?.retailerprice}</span>
                    </h3>

                  </div>

                  <div className="d-flex justify-content-between mt-2">
                    <div>
                      {/* style={{color:'#747475'}} */}
                      <h3> Category :<h3 className="ml-2 mr-4 d-inline gestcomptitle">
                        {productDetails?.productCategory?.productCategorie}
                      </h3>
                        {/* Product Position:<h3 className="ml-2 d-inline" style={{color:'#747475'}}>
   {productDetails?.productqualitylevel === 'Entry' && 'Entry Level'}
   {productDetails?.productqualitylevel === 'Mid' && 'Mainstream'}
   {productDetails?.productqualitylevel === 'High' && 'Premium'}
 </h3> */}
                      </h3>
                      {productDetails?.productTag.length > 0 && <h3>Product Tags : <span className="gestcomptitle">{productDetails?.productTag.map((prodTag) => prodTag?.tag).join(", ")}</span> </h3>}

                    </div>


                  </div>
                  {/* {productDetails?.productDetail?.productCertifi &&<> <h5>Product Certifications</h5>
        <h3 className="mt-3" style={{textAlign:'justify'}}>{productDetails?.productDetail?.productCertifi}</h3></>} */}
                  {productDetails?.productAwards?.length > 0 && <div style={{ overflow: 'hidden' }}> <KeyProducts title="Awards" data={productDetails?.productAwards} /></div>}
                  {/* {productDetails?.productKeyRetailer?.length > 0 && <div style={{ overflow: 'hidden' }}>
                    <KeyProducts title="Key Retailers" data={productDetails?.productKeyRetailer} clickKeyRetailer={clickKeyRetailer} /></div>} */}
                  {parseInt(productDetails?.channelId) !== parseInt(user?.channelId) ?
                    <div class='d-flex justify-content-between productIcon mt-4'>
                      <div data-placement="top" id="tooltip611234743" className="Icon1">
                        <i class="fas fa-thumbs-up heart-r" style={{ color: isFavorite ? 'red' : '#525f7f' }} onClick={() => add_or_Remove_Favorite()}></i>
                      </div>
                      <UncontrolledTooltip delay={0} placement="top" target="tooltip611234743">{isFavorite ? 'Remove from Wishlist' : 'Add to Wishlist'}</UncontrolledTooltip>
                      <div data-placement="top" id="Dismiss" className="Icon3" onClick={OpenProductDismissDialog}>
                        <i class="fas fa-thumbs-down"></i>
                      </div>
                      <UncontrolledTooltip delay={0} placement="top" target="Dismiss">Dismiss</UncontrolledTooltip>
                      {/* <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div className="socialboxed">
                          <HtmlTooltip arrow TransitionComponent={Zoom}
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener

                            title={
                              <React.Fragment>
                                <SocialShareDialog data={shareData} />
                              </React.Fragment>
                            }
                          >
                            <div data-placement="top" id="Share" className="Icon2" onClick={handleTooltipOpen}>
                              <i class="fas fa-share-alt"></i>
                            </div>

                          </HtmlTooltip>
                        </div>
                      </ClickAwayListener> */}

                      {/* <UncontrolledTooltip delay={0} placement="top" target="Share">Share with a Colleague</UncontrolledTooltip> */}

                      {/* <div data-placement="top" onClick={productConnect} id="Request" className="Icon4">
                      <i class="ni ni-cart"></i>
                    </div> */}
                      {/* <UncontrolledTooltip delay={0} placement="top" target="Request">I am Interested and Request Sample</UncontrolledTooltip> */}
                      <div onClick={() => { closeConnect() }} data-placement="top" id="Connect" className="Icon1">
                        <i class="fas fa-user-plus"></i>
                      </div>
                      <UncontrolledTooltip delay={0} placement="top" target="Connect">Connect</UncontrolledTooltip>
                    </div> :
                    <div class='d-flex justify-content-between productIcon mt-6' style={{ maxWidth: "40%" }}>
                      <div data-placement="top" id="Copy" className="Icon1">
                        <i class="far fa-copy heart-r" style={{ color: "#525f7f" }} onClick={() => productEdit(productDetails.productSKU, 2)}></i>
                      </div>
                      <UncontrolledTooltip delay={0} placement="top" target="Copy">Copy</UncontrolledTooltip>
                      <div data-placement="top" id="Edit" className="Icon3" onClick={() => productEdit(productDetails.productSKU, 1)}>
                        <i class="fas fa-pencil-alt"></i>
                      </div>
                      <UncontrolledTooltip delay={0} placement="top" target="Edit">Edit</UncontrolledTooltip>
                      <div data-placement="top" id="Delete" className="Icon2" onClick={() => deletePopup(productDetails.productSKU)}>
                        <i class="far fa-trash-alt"></i>
                      </div>
                      <UncontrolledTooltip delay={0} placement="top" target="Delete">Delete</UncontrolledTooltip>
                      {/* <span href="#" ><i class="far fa-copy" style={{color:"#525f7f", cursor:'pointer'}}></i></span>
                  <span href="#" ><i class="fas fa-pencil-alt ml-3" style={{color:"#525f7f", cursor:'pointer'}}></i></span>
                  <span href="#"  ><i class="far fa-trash-alt ml-3" style={{color:"#525f7f", cursor:'pointer'}}></i></span> */}
                    </div>}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="p-3">
                  <h2 className="text-center m-0 text-uppercase">Product Details</h2>
                </CardHeader>
              </Card></Col>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <h3 className="mb-4">Product Details</h3> */}
                  <h3 className="prodcompanytitle">Product Description</h3>
                  <p ref={productDescRef} className="description text-justify">
                    {/* {productDetails?.productDetail?.productdesc&&productDetails?.productDetail?.productdesc.replace(/<[^>]+>/g, '')} */}
                  </p>
                  {productDetails?.variantdetails && <><h3 className="prodcompanytitle">Variants & Attributes</h3>
                    <p className="description">
                      {productDetails?.variantdetails}
                    </p></>}
                  <h3 className="prodcompanytitle">Logistics & Import information</h3>
                  <CardBody className="Cardborder p-2">
                    <Row>
                      <Col sm="6">
                        <h4 className="mt-2">Product Certification</h4>
                      </Col>
                      <Col sm="6" className="mb-3 mt-2">
                        <Badge className="badge-lg even-larger-badge w-auto">
                          {productDetails?.productDetail?.productCertifi ? productDetails?.productDetail?.productCertifi : 'N/A'}
                        </Badge>
                      </Col>
                      <Col sm="6">
                        <h4 className="mt-2">Product Warranty</h4>
                      </Col>
                      <Col sm="6" className="mb-3 mt-2">
                        <Badge className="badge-lg even-larger-badge w-auto">
                          {productDetails?.productDetail?.productWarranty ? productDetails?.productDetail?.productWarranty : 'N/A'}
                        </Badge>
                      </Col>
                      {productDetails?.productDetail?.productLocal && <><Col lg="6">
                        <h4>Localisation</h4>
                      </Col>
                        <Col sm="6" className="mb-3">
                          <h5 className="badge-lg even-larger-badge w-auto">
                            {productDetails?.productDetail?.productLocal ? productDetails?.productDetail?.productLocal : 'N?A'}
                          </h5>
                        </Col></>}
                      <Col sm="6">
                        <h4>Product Availability</h4>
                      </Col>
                      <Col sm="6" className="mb-3">
                        <Badge style={{ backgroundColor: "#8b96a2", color: 'white' }}
                          className="badge-lg even-larger-badge w-auto"
                          color="light"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          {productDetails?.productDetail?.isavaialbleimmediate === '1' ? 'Immediate' : Moment(productDetails?.productDetail?.avaialblefrom).format('ll')}
                        </Badge>
                      </Col>
                      <Col sm="6">
                        <h4>MOQ</h4>
                      </Col>
                      <Col sm="6" className="mb-3">
                        <Badge className="badge-lg even-larger-badge w-auto">
                          {productDetails?.productPackageInfo?.minorderquantity} {productDetails?.productPackageInfo?.minorderquantity ? productDetails?.productPackageInfo?.orderunitdetail?.unit : 'N/A'}
                        </Badge>
                      </Col>
                      <Col sm="6">
                        <h4>No. of item in box</h4>
                      </Col>
                      <Col sm="6" className="mb-3">
                        <Badge className="badge-lg even-larger-badge w-auto">
                          {productDetails?.productPackageInfo?.noofpackageinbox} {productDetails?.productPackageInfo?.noofpackageinbox ? productDetails?.productPackageInfo?.noofpackageinboxunitdetail?.unit : 'N/A'}
                        </Badge>
                      </Col>
                    </Row>

                  </CardBody>
                </CardBody>
              </Card>

              {similarProductList?.length > 0 && <ChannelProducts data={similarProductList} title="Similar Products" from={to} />}
              {(productDetails?.productExistRating?.length > 0 || productDetails?.productArticleReview?.length > 0) && <Row>
                <Col lg='12' className='pad-less'><Card>
                  <CardHeader className="p-3">
                    <h2 className="text-center m-0 text-uppercase">Reviews</h2>
                  </CardHeader>
                </Card></Col>
                {productDetails?.productArticleReview?.length > 0 && <>

                  <Col lg='6' className='pl-0'><Card>
                    <CardBody>
                      <h3 className="mb-4">Media Reviews</h3>
                      <AwardsReviews data={productDetails?.productArticleReview} title="" />

                    </CardBody>
                  </Card></Col> </>}
                {productDetails?.productExistRating?.length > 0 && <Col lg={productDetails?.productArticleReview?.length > 0 ? '6' : '12'} className='pr-0'><Card>
                  <CardBody>
                    <h3 className="mb-4">End-User Reviews</h3>
                    {<KeyRetailReviews data={productDetails?.productExistRating} />}
                  </CardBody>
                </Card></Col>}
              </Row>}
              {(productDetails?.productKeyDistributor?.length > 0 || (productDetails?.productKeyRetailer?.length > 0 && (productType == '1' || productType == '3')) || (productDetails?.productKeyReseller?.length > 0 && (productType == '2' || productType == '3'))) && <>
                <Card>
                  <CardHeader className="p-3">
                    <h2 className="text-center m-0 text-uppercase">Channel Description</h2>
                  </CardHeader>
                </Card>
                <Card>
                  <CardHeader className="p-3">

                    {productDetails?.productKeyDistributor?.length > 0 &&
                      <>
                        {productDetails?.productKeyDistributor?.length > 0 ? <KeyBrands type={1} data={productDetails?.productKeyDistributor} title="Current Distributors" /> : <><h3 className="mb-4">Current Distributors</h3><NoData /></>}
                      </>
                    }
                    {productDetails?.productKeyRetailer?.length > 0 && (productType == '1' || productType == '3') && <KeyBrands type={1} data={productDetails?.productKeyRetailer} title="Current Retail Partners" />}

                    {productDetails?.productKeyReseller?.length > 0 && (productType == '2' || productType == '3') && <KeyBrands type={1} data={productDetails?.productKeyReseller} title="Current Reseller Partners" />}

                  </CardHeader>
                </Card></>}

              {productDetails?.images?.BROUCHER.length > 0 && <>
                <Col lg='12' className='pad-less'><Card>
                  <CardHeader className="p-3">
                    <h2 className="text-center m-0 text-uppercase">Product BROCHURE & Documents</h2>
                  </CardHeader>
                  <OfficialDocument Isprodet={true} type='2' data={productDetails?.images?.BROUCHER} title="" />
                </Card>
                </Col>
              </>}
            </Col>
          </Row>

          {productList?.length > 0 && <ChannelProducts data={productList} title="Other Products from this Vendor" from={to} />}
        </Container>
      }
    </div>
  );
}

const UpdateChannelData = {
  connectionStatusTypeId: null,
  channelJCTId: null,
  dismissreasonid: null,
  otherreasons: null,
  leadManagementTag: null,
  partnerLeadManagementTag: null,
  note: null,
  contactid: null,
  videoUrl: null
}

//export default ProductDetail;
function mapState(state) {
  const general_data = state.productListing;
  return general_data;
}

const actionCreators = {
  channelRedux: channelAction.channelRedux
};

export default connect(mapState, actionCreators)(ProductDetail);
