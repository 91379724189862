import React, { useState, useEffect } from 'react';
import { Card, CardBody } from "reactstrap";
import { FormAutocomplete } from "_components/FormElements/FormInput";
import { useForm} from "react-hook-form";
import { ListGroupItem, ListGroup } from 'reactstrap';
import { commonService } from '_services/common.service';
import Popper from '@material-ui/core/Popper';


const UserManageFilter = (props) => {
  const { activeTab, handleChangeSelect, filterValueState, resetFilterValue } = props;
  const [ filterOptionState, setFilterOption] = useState({userTypeOption:[], tradeLocationOption:[]});
  const user = JSON.parse(localStorage.getItem('CHuser'));
  console.log('activeTab', activeTab);
  console.log('filterValueState', filterValueState);
  const { control } = useForm({ mode:'onBlur' });
    useEffect(() => {
      if(activeTab)
      getSearchAggregation();
  }, [activeTab])
  const getSearchAggregation=async()=>{
    resetFilterValue();
    resetFilterOptions();
    let data = await commonService.getServiceWithParams({
      channelId:user.channelId,
      contactId:user.contactId,
      channelTypeId:user.channelTypeId
    },'/channel/getSearchAggregation/'+activeTab);
    if(data){
      let options = data.data;
      if(options){
     filterOptionState.SpecialityOption=options.specialIds;
     filterOptionState.Brand_Option=options.brandIds;
     filterOptionState.Retailer_Profile_Option=options.retailIds;
     filterOptionState.Company_Origin_Option=options.countryIds;
     setFilterOption({...filterOptionState});
     }
     }
    console.log('filterOptionState', filterOptionState);
  }

  

  const resetFilterOptions=()=>{
    filterOptionState.SpecialityOption=[];
    filterOptionState.Brand_Option=[];
    filterOptionState.Retailer_Profile_Option=[];
    filterOptionState.Company_Origin_Option=[];
    filterOptionState.channelTypeOption=liveStatusOptions.filter(x=>x.id !==user.channelTypeId);
    setFilterOption({...filterOptionState});
  }

  const PopperMy = function (props) {
    return (<Popper {...props} style={{ width: '300px' }} placement='bottom-start' />)
  }

  const style = { height: '20rem' };
  

  return (

    <Card>
    
      <CardBody className='fav-ser'>
        <ListGroup>
        <ListGroupItem style = {style}>
        <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="userType"
                        name="userType"
                        label="User Type"
                        options={filterOptionState.userTypeOption}
                        placeholder="Select Type"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='userType'
                        value={filterValueState.userType}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        />
                    </div>
                  </div>
                  </ListGroupItem>

                  <ListGroupItem style = {style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="tradeLocation"
                        name="tradeLocation"
                        label="Trade Location"
                        options={filterOptionState.tradeLocationOption}
                        placeholder="Select Location"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='tradeLocation'
                        value={filterValueState.tradeLocation}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        />
                    </div>
                  </div>
                  </ListGroupItem>

                  
                  <ListGroupItem style = {style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="liveStatus"
                        name="liveStatus"
                        label="Live Status"
                        options={liveStatusOptions}
                        placeholder="Select Status"
                        multiple={false}
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='liveStatus'
                        value={filterValueState.liveStatus}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        style = {{ height: '3rem' }}
                        
                        />
                    </div>
                  </div>

                  </ListGroupItem>


                
        </ListGroup>
      </CardBody>
      
      
    </Card>
  );
};



const AnnuvalTurnOver = [
  { id: '<1M€', name: '<1M€' },
  { id: '1-10M€', name: '1-10M€' },
  { id: '10-100M€', name: '10-100M€' },
  { id: '>100M€', name: '>100M€' },
]
const liveStatusOptions = [
  {
    id: '1',
    type: 'ACTIVE',
    name: "Active"
  },
  {
    id: '0',
    type: 'INACTIVE',
    name: "InActive"
  },
];
export default UserManageFilter;