import React, { useEffect, useState } from 'react';
import { Modal, Card, CardBody, CardHeader, Form, Container, Col, Row, Table, Button } from "reactstrap";
import { userConstants } from '../../../_constants';
// import video from '../../../assets/sample.mp4'
import { history } from '../../../_helpers/history';
import { commonService } from '_services/common.service';

export const BuyerSessionDialog = ({type,handleClose}) => {

  const [open, Setopen] = useState(true)  
  const [message, Setmessage] = useState(null)


  const closeOpen = (e) => {
    e.preventDefault();
    handleClose();
    Setopen(false);
  }

 
  useEffect(() => {
    setMessagesForDialog();
  }, []);

  const setMessagesForDialog = async () => {
  
    let message = null;
    if (type == 'Scheduler') {    
        message = "Our team is currently reviewing the meeting requests sent by the vendors in order to select the most relevant for you. Once completed, we'll send you an email to verify the final list and select the meetings your wish to confirm";      
    } 
    Setmessage(message);
  }
  return (
    <div >

      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        size="sm"
        style={{ maxWidth: '420px' }}
      >

        <div className="modal-header pb-0">


          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeOpen}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body  d-flex justify-content-center pt-0 pb-0">
          <Card >

            <CardBody>
              <div> <h2>Buyer Session</h2> </div>
              <div>
                {message}
              </div>
            </CardBody>
          </Card>

        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={closeOpen}
          >
            Close
          </Button>

          

        </div>
      </Modal>
    </div>
  );
}
export default BuyerSessionDialog;