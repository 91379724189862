import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';
//import { history } from '../_helpers';


export const adduserActions = {
    adduser,
   
};


function adduser(data,type) {

    
    if(type==1)
    {
        console.log("data:",data,type);
       return dispatch => {
            dispatch(request(data));        
        
        function request(data) { return { type: 'GENERAL_DATA',data } }        

       }
    }

    if(type==2)
    {
        console.log("data:",data,type);
       return dispatch => {
            dispatch(request(data));
        
        function request(data) { return { type: 'TRADE_INFO',data } }        

       }
    }

    if(type==3)
    {
        console.log("data:",data,type);
       return dispatch => {
            dispatch(request(data));
        
        function request(data) { return { type: 'TRADE_LOC',data } }        

       }
    }

    if(type===4)
    {
        console.log("data:",data,type);
       return dispatch => {
            dispatch(request(data));
        
        function request(data) { return { type: 'PREFERENCE',data } }        

       }
    }

    if(type==5)
    {
        console.log("data:",data,type);
       return dispatch => {
            dispatch(request(data));
        
        function request(data) { return { type: 'CONTACT',data } }        

       }
    }


//    return dispatch => {
//         dispatch(request());

//       dashboardService.dashboard_count()
//             .then(               
//                    // console.log("result:",count.data),
//                     count => dispatch(success(count)),
//                 error => dispatch(failure(error.toString()))
              
//             );
//     };

//     function request() { return { type: dashboardConstants.COUNT_REQUEST } }
//     function success(count) { return { type: dashboardConstants.COUNT_SUCCESS, count } }
//     function failure(error) { return { type: dashboardConstants.COUNT_FAILURE, error } }
}

