import React from 'react';
import axios from 'axios';
import {FormButton, } from "_components/FormElements/FormInput";
import { history } from '../../../_helpers';
// reactstrap components
import Logo from 'views/pages/LoginPage/login-logo.js';
export const Verification = () => {

  const dashboard = () => {
    history.push('/admin/');
  }

  return (
    <div className="main-content">
      <Logo/>
    <div className="header signup-header-bg py-4 py-lg-6 pt-lg-7">
      <div className="container">
        <div className="header-body text-center mb-7">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-6 col-md-12 px-5">
              {/* <h1 className="text-white">Approved Successfully!</h1> */}
              {/* <p className="text-lead text-white">
              Your verification is pending. Kindly verify or else click resend email to resend verification link to your e-mail <span className='bold-text'>channel@email.com</span>
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>

    <div className="container mt--8 pb-5">
    <div className="row justify-content-center">
    <div className="col-lg-10 col-md-12">
    <div className="card bg-secondary border-0 mb-0">  
  
    <div className="card-body px-lg-5 py-lg-5">     

    <div className="header-body text-center mb-5">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-10 col-md-12 px-5">
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={require("assets/img/brand/verified.png")}/> 
              <h1 className="text-black text-lead text-marg">Your account has been Approved successfully.</h1>
              <FormButton
                  className="btn-sin btn btn-primary dash"
                 id="Dashboardbutton"
                 name="Dashboardbutton"
                 value="Go to Dashboard" 
                 onClick={dashboard}
      />
            </div>
          </div>
        </div>
                 </div>
             
              </div>
              </div>
              </div>
              </div>
              </div> 
              
  );
}
export default Verification;