import React, { useState, useEffect } from 'react';
import { Container, Row, Button, Col, CardBody, CardHeader, Card } from "reactstrap";
import {FormInput,FormRadio,FormCheckBox} from "_components/FormElements/FormInput";
import { useForm} from "react-hook-form";
import { InputAdornment } from '@material-ui/core';
import { commonService } from '_services/common.service';
import { Spinner } from 'views/Hoc/Spinner';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { yupResolver } from '@hookform/resolvers/yup';
const ProductVisib = (props) => {
  const { changedData, closeDrawer }=props;
  const [style, setStyle] = useState("galler_img_visib galler");

  const changeStyle = () => {
    console.log("you just clicked");
  
    setStyle(style=="galler_img_visib_click galler_click"?"galler_img_visib galler":"galler_img_visib_click galler_click");
  }

        
              
     
    return (
      
      <div>
        <div>a.“Select up to 3 products that will be displayed on ChannelHub home page” show small images of the products with product name and check box below each</div>
        <Card>
          <CardHeader>
            
          </CardHeader>
          <CardBody>
          <Row className="pt-4">
          <Col lg="4" className="mb-4" md="3">
    <div class="galler">


                    <div class="galler_content">
                    <img className={style} onClick={changeStyle} src='https://d2maj8ifjo1lti.cloudfront.net/document/product/image/0vSWpwFL_1558084332178_1558084362894.png' />
                      <div className="prodcontent">
                      <h5 className="text-left">brandname</h5>
                      
                    </div>
                    </div>
                  </div></Col>
                                                                
                              </Row>
         
          </CardBody>
        </Card>
        
        
        
        <div>b.“Select 1 product to be promoted in ChannelHub social media</div>
        <div>c.“Select up to 3 products that will be included in email suggestion to buyers</div>
        <div>d.Is any of your product eligible for the below hot topics listed on our home page</div>
    

      </div> 
    );
};

export default ProductVisib;