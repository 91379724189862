import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { history } from '../../../_helpers/history';
import { Button, Modal } from "reactstrap";
import { LaptopWindows } from '@material-ui/icons';

//forguest
import TermsConditions from 'views/pages/User-SubscriptionPage/HelpReport/TermsConditions';
import PrivacyPolicy from 'views/pages/User-SubscriptionPage/HelpReport/PrivacyPolicy';
import LegalInformation from 'views/pages/User-SubscriptionPage/HelpReport/LegalInformation';

export  const Dialog=(props)=> {
  
  const {open, handleClose, handleSave, value } = props;
  //console.log(props)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={open}>
          <div className="modal-header">
           <strong>{value.title}</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={ handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {value.message}
            {value?.second_message && <p className="mt-2">{value?.second_message}</p>}
          </div>
          <div className="modal-footer">
          <Button color="primary" type="button" onClick={()=>handleSave(value)}>Ok</Button>
          <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
          </div>
        </Modal>
    </div>
  );
}


export  const UpgradeModal=(props)=> {
 // const {open, handleClose,  subscriptionTurn, value } = props;
 const {open, handleClose,   value } = props;
 let channelType = JSON.parse(localStorage.getItem('CHuser')).channelTypeId;


 let typeId = (channelType === 2)?true:false;
  const gotoWallet = (event) =>{ 
    let cid= props.value.cid;
    if(cid){
        history.push('/admin/ewallet/' + cid +'/'+props.value.JctId);
    }
  }

  const subscriptionTurn = (event) =>{ 
    history.push('/admin/Subscription');
  }

 
  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={open}>
          <div className="modal-header">
           <strong>Upgrade</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={ handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
        <p>
        Your current plan is restricted. If you wish you unveil this company’s details you have 2 options: 
        </p>
       
        { (channelType==2)?  <p>-	Upgrade your plan with our premium annual plan at 49€/month (*Billed Annually). <span style={{cursor: 'pointer', color: 'blue'}}  onClick={subscriptionTurn}>Click here </span>to view the details of this plan
        </p>:''}
        <p>
        -	Purchase a single record view with your e-Wallet. Simply recharge your e-Wallet and use it at your convenience. Each record view is priced at 10€. 
        </p>
        <p>
           <span id={props.value.cid} style={{cursor: 'pointer', color: 'blue'}} onClick={gotoWallet}>Click here</span> to use your e-Wallet.
        </p>
          </div>
          <div className="modal-footer">
          { (channelType==2)?<Button color="primary"  type="button" onClick={subscriptionTurn}>Upgrade</Button>:''}
          <Button color="secondary" data-dismiss="modal" type="button" onClick={gotoWallet}>e-Wallet</Button>
          </div>
        </Modal>
    </div>
  );
}

export  const ConfirmDialog=(props)=> {
  
  const {open, handleClose, handleSave, value } = props;
  //console.log(props)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={open}>
          <div className="modal-header">
           <strong>{value.title}</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={ handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {value.message}
            {value?.second_message && <p className="mt-2">{value?.second_message}</p>}
          </div>
          <div className="modal-footer">
          <Button color="primary" type="button" onClick={()=>handleSave(value)}>Confirm</Button>
          <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Cancel</Button>
          </div>
        </Modal>
    </div>
  );
}

export  const GuestPopup=(props)=> {
  
  const {open, handleClose, handleSave, value } = props;
  //console.log(props)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Modal className="modal-dialog-centered col-lg-8 col-md-8 col-sm-6 mt-2 container-fluid " isOpen={open} size="xl">
          <div className="">
           {/* <strong>{value.title}</strong> */}
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={ handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="">
            {/* {value.message} */}
            {value?.second_message && <p className="mt-2">{value?.second_message}</p>}
            {
              value.popUpId=='1' ? <LegalInformation /> : value.popUpId == '2' ? <PrivacyPolicy /> : <TermsConditions />
            }
          </div>
          <div className="modal-footer">
          <Button color="primary" type="button" onClick={()=>handleSave(value)}>Ok</Button>
          <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
          </div>
        </Modal>
    </div>
  );
}


export  const DialogToShowMessage=(props)=> {
  
  const {open, handleClose, handleSave, value } = props;
  //console.log(props)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={open}>
          <div className="modal-header">
           <strong>{value.title}</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={ handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {value.message}
            {value?.second_message && <p className="mt-2">{value?.second_message}</p>}
          </div>
          <div className="modal-footer">
          {/* <Button color="primary" type="button" onClick={()=>handleSave(value)}>Ok</Button> */}
          <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
          </div>
        </Modal>
    </div>
  );
}

export default  Dialog;