import React from 'react';
import axios from 'axios';
class ResourceCell extends React.PureComponent {
  render() {
    const { data: { color, text, data: { avatar, age, discipline } } } = this.props;
    return (
      <div className="dx-template-wrapper">
        <div className="name" style={{ background: color }}>
          <h2>{text}</h2>
        </div>
        <div className="avatar">
          <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={avatar} />
        </div>
        <div className="info" style={{ color: color }}>
          
          <br />
          <b>{discipline}</b>
        </div>
      </div>
    );
  }
}

export default ResourceCell;
