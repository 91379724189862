import React from "react";
import { Card } from "reactstrap";
import YoutubeEmbed from "./YoutubeEmbed";
import PropTypes from "prop-types";

// React Component to display individual item
const AwardVideoItem = ({ companyname, productname, embedId, awardlogo }) => (
    
    <div className="col-lg-4">
  <Card className="item-container">
    <div>
      <span className="item-label">Company Name:</span>
      <p>{companyname}</p>
    </div>
    <div className="row">
      <div className="col-lg-4" style={{padding:"0"}}>
      <span className="item-label" style={{display:"inline-block", marginBottom:"20px"}}>Product Name:</span>
      </div>
      <div className="col-lg-5" style={{padding:"0"}}>
      <p>{productname}</p>
      </div>
    </div>
    <div className="award-logo">
      <img  src={awardlogo} />
    </div>
    <div>
    <div className="video-responsive" style={{padding:'20px'}}>
    <iframe
      width="700"
      height="400"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
    </div>
  </Card>
  </div>
);
YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default AwardVideoItem;