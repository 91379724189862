import React, { useEffect }  from 'react';
import { Button, Modal } from "reactstrap";
import {FormInput} from "_components/FormElements/FormInput";
import { documentNameSchema } from '../../pages/SignupPage/Validation';
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
export const DocumentName=(props)=> {
  const { isOpen, handleClose, isEdit, handleSave, name, docId } = props;
  console.log('name', name);
  let [docName, setDocName]  = React.useState(name);
  const { register, handleSubmit, errors  } = useForm({      
    mode:'onBlur',  
    resolver: yupResolver(documentNameSchema),
  });
  useEffect(() => {
    docName=name;
    setDocName(docName);
  }, [name]);
  const onHandleChange=(e)=>{
    docName=e.target.value;
    setDocName(docName);
  }
  const save=()=>{
    handleSave(docId,docName);
  }
  return (
    <div>
      <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
         size="sm"
        >
          <div className="modal-header">
           <h3>Official Document Name</h3>
            {isEdit && <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              <span aria-hidden={true}>×</span>
            </button>}
          </div>
              <div className="col-lg-12">
                      <FormInput 
                         id="name"
                         type="text"
                         name="name"
                         onChange={onHandleChange}
                         register={register}
                         defaultValue={docName}
                         value={docName}
                         error={errors.name}
                    />
               </div>
          <div className="modal-footer">
                  <Button
                    className='btn btn-primary'
                    data-dismiss="modal"
                    type="button"
                    onClick={handleSubmit(save)}
                    >
                  Submit
                  </Button>
          </div>
        </Modal>
    </div>
  );
}
