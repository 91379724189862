import React ,{useEffect,useState} from 'react';
import { Modal,Card,CardBody,CardHeader,Form, Container,Col,Row,Table,Button } from "reactstrap";
import { history } from '../../../_helpers/history';
// import video from '../../../assets/sample.mp4'

export  const PlanUpgradeDialog=(props)=> {
  const { url, videoModalClose, isOpen } = props;
const [open, Setopen] = useState(true)


const closeOpen=(e)=>{
  e.preventDefault();
  props.closeUserView();
  Setopen(false);
}

const subscriptionTurn = (event) =>{ 
  history.push('/admin/Subscription');
}
  return (
    <div >
       
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Modal
          className="modal-dialog-centered"
          isOpen={open}
         size="sm"
         style={{maxWidth:'420px'}}
        >
           
          <div className="modal-header pb-0">
          
          
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
           onClick={closeOpen}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center pt-0 pb-0">
          <Card >
     
                <CardBody>
                  <div> <h2>Upgrade</h2> </div>
                  <div>You have reached the maximum limit of add user based on your subscription plan. You can either wait for next month or upgrade your plan. Click upgrade to view the subscription options</div>
                </CardBody>
              </Card>
        
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={closeOpen}
            >
              Close
            </Button>

            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={subscriptionTurn}
            >
              Upgrade
            </Button>
           
          </div>
        </Modal>
    </div>
  );
}
export default  PlanUpgradeDialog;